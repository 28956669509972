import { RAILS_HOST } from "config";

export const SASS_VARIABLES_PATH = 'src/styles/base/variables.scss';
export const SASS_MIXINS_PATH = 'src/styles/base/mixins.scss';

export const SASS_IMPORTS = `
@import 'src/styles/base/variables.scss';
@import 'src/styles/base/mixins.scss';`

export const PAGE = {
  HOME : '/',
  LOGIN : '/user/login',
  PRODUCT_CHARGES : '/services/product-charges',
  NETWORK_PROVISION : '/services/network-provision',
  CHANGE_PASSWORD : '/user/change-password',
  EMAIL_SENT : '/user/email-sent',
  NEW_PASSWORD : '/user/new-password',
  ADD_VEHICLE : '/vehicle/add',
  NEW_VEHICLE : '/vehicle/new',
  VEHICLE_EDIT : '/vehicle/edit',
  MY_VEHICLES : '/my-vehicles',
  VEHICLE : '/vehicle',
  DOCUMENTS : '/documents',
  AGREEMENT_SCANS : '/agreement_scans',
  TRANSLATION : '/translation',
  TRANSLATION_STEP_METHOD : '/translation/add/step-method',
  TRANSLATION_STEP_VIN : '/translation/add/step-vin',
  TRANSLATION_STEP_DATA : '/translation/add/step-data',
  TRANSLATION_STEP_LIST : '/translation/add/step-list',
  NEW_INQUIRY : '/insurances/inquiries/new',
  INQUIRY_STEP_VIN : '/insurances/inquiries/step-vin',
  INQUIRY_STEP_DATA : '/insurances/inquiries/step-data',
  INQUIRY_STEP_LIST : '/insurances/inquiries/step-list',
  INQUIRY_ADD : '/insurances/inquiries/add',
  TRANSLATION_ADD : '/translation/add',
  TRANSLATION_EDIT : '/translation/edit',
  TRANSLATIONS_PREPARED : '/translation/lists/prepared',
  TRANSLATIONS_SENT : '/translation/lists/sent',
  TRANSLATIONS_UNPAID : '/translation/lists/unpaid-items',
  TRANSLATIONS_PENDING : '/translation/lists/pending',
  PACKAGE_CHECKOUT : '/translation/checkout',
  PROMISES_OC_30 : '/insurances/oc-30-promises',
  POLICY_OC_30 : '/insurances/oc-30-policies',
  POLICIES_OC_LONG_TERM : '/insurances/oc-long-term',
  INQUIRIES : '/insurances/inquiries',
  INQUIRY_OFFER : '/insurances/inquiries/offer',
  POLICY_OC_30_NEW : '/insurances/oc-30-policies/new',
  POLICY_OC_30_STEP_VIN : '/insurances/oc-30-policies/step-vin',
  POLICY_OC_30_STEP_DATA : '/insurances/oc-30-policies/step-data',
  POLICY_OC_30_STEP_LIST : '/insurances/oc-30-policies/step-list',
  POLICY_OC_30_ACTIVATION_METHOD : '/insurances/oc-30-policies/activation-method',
  POLICY_OC_30_OFFER : '/insurances/oc-30-policies/offer',
  PROMISE_OC_30_OFFER : '/insurances/oc-30-promises/offer',
  POLICY_OC_30_ADD : '/insurances/oc-30-policies/add',
  POLICY_OC_30_EDIT : '/insurances/oc-30-policies/edit',
  PROMISE_OC_30_ADD : '/insurances/oc-30-promises/add',
  
  OTHER_POLICIES : '/insurances/other/policies',
  PROPERTY_INQUIRIES : '/insurances/other/inquiries/property',
  TRAVEL_INQUIRIES : '/insurances/other/inquiries/travel',
  OTHER_INQUIRIES_ADD : '/insurances/other/inquiries/add',
  OTHER_POLICIES_OFFER : '/insurances/other/inquiries/add',
  COMPANY_PROFILE_USERS : '/company-profiles/users',
  ACCOUNT_SYNC : '/users/account-sync',
  //* waste paths *//
  WASTE : '/waste',
  WASTE_CARD_EDIT : '/waste/edit',
  WASTE_CARD_CREATE : '/waste/create',
  WASTE_DETAILS : '/waste-details',
  WASTE_REGISTER : '/waste-register',
  WASTE_REGISTER_SUCCESS : '/waste-register-success',
  WASTE_RECORD_FORM : '/waste-form',
  WASTE_FORWARD : '/forward-waste',
  SETTLEMENTS : '/settlements',
  BDO_KEY : '/bdo-key',
  BDO_REGISTRY : '/bdo-registry',
  OS_OP1_REPORT : '/os-op1-report',
  OS_OP1 : '/os-op1',
  BDO_REPORT : '/bdo-report',
  MASS_REGISTRY : '/mass-registry',
  BDO_INFO : '/bdo-report-info',  
  API_KEY_INSTRUTION : '/instruction',  
  STEP_1 : '/bdo-report-form/step1',  
  STEP_2 : '/bdo-report-form/step2',  
  STEP_3 : '/bdo-report-form/step3',  
  BDO_YEAR_REPORT : '/bdo-year-report-form',
  BDO_YEAR_REPORT_SUBMIT : '/bdo-report-submit',   
  BDO_REPORT_STEP_1 : '/bdo-year-report-form/step1',  
  BDO_REPORT_CAMPAIGN_LEAFLET : '/bdo-year-report-form/campaign-leaflet',  
  BDO_REPORT_STEP_2 : '/bdo-year-report-form/step2',
  BDO_REPORT_STEP_2_SUMMARY : '/bdo-year-report-form/step2/payment-summary',
  BDO_REPORT_STEP_2_PAYMENT_STATUS : '/bdo-year-report-form/step2/payment-status',    
  BDO_REPORT_STEP_3 : '/bdo-year-report-form/step3', 
  BDO_REPORT_STEP_4 : '/bdo-year-report-form/step4',
  BDO_REPORT_STEP_5 : '/bdo-year-report-form/step5',   
  BDO_REPORT_FORM : '/bdo-report-form',  
  BDO_REPORT_SUCCESS : '/bdo-report-success',
  OS_OP1_STEP_1 : '/os-op1-form/step1',  
  OS_OP1_STEP_2 : '/os-op1-form/step2',  
  OS_OP1_STEP_3 : '/os-op1-form/step3',  
  BDO_REGISTRY_FORM : '/bdo-registry-form',
  BDO_REGISTER_STEP_1 : '/bdo-registry-form/step1',  
  BDO_REGISTER_STEP_2 : '/bdo-registry-form/step2',  
  BDO_REGISTER_STEP_3 : '/bdo-registry-form/step3',  
  OS_OP1_PAYMENT_STATUS : '/os-op1-form/payment-status', 
  SO_ORDER_DECLARATION : '/so-order/declaration-form',
  SO_ORDER_APPROVAL : '/so-order/approval-form',
  SO_ORDER_INFO : '/so-order-info',
    
  OS_OP1_FORM : '/os-op1-form',  
  DOCUMENT_PAYMENT_SUMMARY : '/document-payment-summary',  
  DOCUMENT_TRANSFER_SUMMARY : '/document-transfer-summary',  
  DOCUMENT_PAYMENT_STATUS : '/document-payment-status',  
  ADMIN_OO_ORDERS : '/admin/oo-orders',
  ADMIN_OO_SUMMARY : '/admin/oo-summary',
  ADMIN_OO_SUBORDERS : '/admin/oo-suborders',
  ADMIN_OO_ORDERS_FORM : '/admin/oo-orders_form',
  ADMIN_BDO_KEYS : '/admin/bdo-keys',
  ADMIN_OS_OP1_LIST : '/admin/os-op1-list',
  ADMIN_OS_OP1_DETAILS : '/admin/os-op1-details',
  ADMIN_BDO_WS_LIST : '/admin/bdo-ws-list',
  ADMIN_DOCUMENTS : '/admin/documents',
  ADMIN_BDO_PAYMENTS : '/admin/bdo_payments',
  ADMIN_BDO_PAYMENT_DETAILS : '/admin/bdo-payment-details',
  ADMIN_DOCUMENTS_GROUP_EDIT : '/admin/documents-group-edit',
  ADMIN_VEHICLES : '/admin/vehicles',
  ADMIN_BDO_REPORTS : '/admin/bdo-reports-list',
  ELECTRONICS_FORM : '/products/electronics_form',
  ELECTRONICS_FORM_STEP_1 : '/products/electronics_form/step1',
  ELECTRONICS_FORM_STEP_2 : '/products/electronics_form/step2',
  ELECTRONICS_FORM_STEP_3 : '/products/electronics_form/step3',
  AGREEMENT_SUCCESS : '/products/agreement-success',

 ADMIN_INVOICES: `${RAILS_HOST}/invoices`
}
