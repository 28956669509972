import React, { useEffect, useState, useRef } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { Formik, FormikProps } from "formik";
import TextField from "components/form-fields/formik-text-field";
import HookTextField from "components/form-fields/hook-form-fields/hook-text-field";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import FormButton from "components/buttons/form-button";
import { PAGE } from "paths";
import { Link, useHistory } from "react-router-dom";
import FileDropzone from "components/admin/file-dropzone-simple";
import Button from "antd/lib/button";
import * as Yup from "yup";
import { yupRequired, isMinCorrect, required } from "validators";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import Form from "antd/lib/form";
import { RcFile } from "antd/lib/upload";
import { Upload, Icon } from "antd";
import { IExistingFile } from "types/translation-types";
import {
  IOSOP1FormValues,
  IOSOP1FormData,
  IOSOP1,
} from "types/BDO-report-types";
import { IActionState } from "types/async-types";
import {
  fetchOSOP1FormData,
  saveOSOP1,
  fetchOSOP1Report,
  deleteOSOP1Attachment,
} from "actions/BDO-reports";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { setHookFormErrors } from "utils/helpers";
import FormButtons from 'components/common/form-buttons';

const BDONumber = (props: any) => {
  const history = useHistory();
  const formRef: any = useRef();
  const [id, setId] = useState<string | null>(null);
  const [initialValues, setInitialValues] = useState<
    IOSOP1FormValues | undefined
  >({ notice_files: [] });

  let validationSchema = Yup.object().shape({});

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  // const { formState, control, reset } = formMethods;

  const [formDataState, execFetchFormData]: readonly [
    IActionState<IOSOP1FormData>,
    typeof fetchOSOP1FormData
  ] = useAsyncAction(fetchOSOP1FormData);

  const [OSOP1ReportState, execFetchOSOP1Report]: readonly [
    IActionState<IOSOP1>,
    typeof fetchOSOP1Report
  ] = useAsyncAction(fetchOSOP1Report);

  const [saveOSOP1State, execSaveOSOP1]: readonly [
    IActionState<IOSOP1FormValues>,
    typeof saveOSOP1
  ] = useAsyncAction(saveOSOP1);

  const [deleteState, execDeleteFile]: readonly [
    IActionState<IOSOP1FormData>,
    typeof deleteOSOP1Attachment
  ] = useAsyncAction(deleteOSOP1Attachment);

  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const [existingFiles, setExistingFiles] = useState<IExistingFile[]>([]);

  const [existingFileIdsToDelete, setExistingFileIdsToDelete] = useState<
    number[]
  >([]);

  const submitForm = async (values: IOSOP1FormValues) => {
    const submitData: IOSOP1FormValues = {
      ...values,
      step: 2,
      notice_files: newFiles,
    };

    if (id) {
      execSaveOSOP1(submitData, id);
    }
  };

  useEffect(() => {
    setHookFormErrors(formMethods, saveOSOP1State.response);
  }, [saveOSOP1State.response]);

  useEffect(() => {
    if (saveOSOP1State.data) {
      history.push(`${PAGE.OS_OP1_STEP_3}?id=${saveOSOP1State.data.id}`);
    }
  }, [saveOSOP1State.data]);

  useEffect(() => {
    if (id) {
      execFetchOSOP1Report(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setId(id);
    execFetchFormData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formDataState.data) {
      setInitialValues({
        ...formDataState.data.os_op1_report,
      });
    }

    if (OSOP1ReportState.data) {
      if (OSOP1ReportState.data.notice_files) {
        setExistingFiles(OSOP1ReportState.data.notice_files);
      }

      setInitialValues({
        raport_due_date: OSOP1ReportState.data.raport_due_date,
        year1: OSOP1ReportState.data.year1,
        year2: OSOP1ReportState.data.year2,
        year3: OSOP1ReportState.data.year3,
        year4: OSOP1ReportState.data.year4,
        year5: OSOP1ReportState.data.year5,
        year6: OSOP1ReportState.data.year6,
        year7: OSOP1ReportState.data.year7,
        notice_files: [],
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataState.data, OSOP1ReportState.data]);

  useEffect(() => {
    if (initialValues) {
      formMethods.reset(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  const renderYearFields = () => {
    const date = new Date();
    const result = [];

    const firstYear = date.getFullYear() - 8;

    for (let i = 1; i <= 7; i++) {
      result.push(
        <Col md={3}>
          <HookTextField
            formMethods={formMethods}
            label={`w ${firstYear + i} roku`}
            name={`year${i}`}
            type="number"
          />
        </Col>
      );
    }

    return <>{result}</>;
  };

  return (
    <div className="">
      {(formDataState.loading ||
        saveOSOP1State.loading ||
        OSOP1ReportState.loading) && <Spinner size="large" fixed={true} />}

      <>
        <div className="block">
          <h2 className="h-lg">Wezwanie do rozliczenia</h2>
          <p className="md-t flex-top-left">
            <span className="warning-label">
              <WarningOutlinedIcon />
            </span>
            Jeśli otrzymałeś z Urzędu wezwanie do rozliczenia za poprzednie lata
            podaj termin rozliczenia i wgraj skan wezwania
          </p>
          <section>
            <Row gutter={15}>
              <Col md={4}>
                <HookTextField
                  formMethods={formMethods}
                  label="Termin OŚ-OP1"
                  name="raport_due_date"
                  type="date"
                  className="mb-20"
                />
                <div className="sm-t">
                  <label>Podaj termin do którego musisz złożyć OŚ-OP1</label>
                </div>
              </Col>
              <Col md={20}>
                <FileDropzone
                  formMethods={formMethods}
                  files={newFiles}
                  setFiles={setNewFiles}
                  existingFiles={existingFiles}
                  execDeleteFile={async (attachmentId: string) => {
                    if (id) {
                      await execDeleteFile(id, attachmentId);
                      execFetchOSOP1Report(id);
                    }
                  }}
                />
              </Col>
            </Row>
          </section>
        </div>
        <div className="block">
          <h2 className="h-lg">Ilość pojazdów</h2>
          <p className="md-t flex-top-left">
            <span className="warning-label">
              <WarningOutlinedIcon />
            </span>
            <span>
              Wprowadź ilość pojazdów w latach za które chcesz się rozliczyć.
              Jeżeli w danym roku nie wprowadziłeś pojazdów wpisz "0".{" "}
            </span>
          </p>
          <Row gutter={10}>{renderYearFields()}</Row>
        </div>
        <FormButtons>
            <FormButton
              width={220}
              color="primary"
              onClick={formMethods.handleSubmit(submitForm)}
              title="Zapisz i kontynuuj"
            />
        </FormButtons>
     
      </>
    </div>
  );
};

export default BDONumber;
