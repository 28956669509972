import React, { useState, useEffect } from "react";
import Dropdown from "antd/lib/dropdown/dropdown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PAGE } from "paths";
import { Link, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ISimpleDocument, IDocumentFormValues } from "types/document-types";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import FileDropzone from "components/admin/file-dropzone-simple";
import { IExistingFile } from "types/translation-types";
import Button from "components/buttons/button";
import { IActionState } from "types/async-types";
import {
  saveDocument,
  deleteDocumentAttachment,
  createDocument,
  deleteDocument,
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
interface IProps {
  document?: ISimpleDocument;
  execFetchGroup: (id: string) => any;
}
const DocumentsEditForm = (props: IProps) => {
  const { document, execFetchGroup } = props;
  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const { id }: { id: string } = useParams();

  const [submitState, execSubmit]: readonly [
    IActionState<any>,
    typeof saveDocument
  ] = useAsyncAction(saveDocument);

  const [deleteState, execDelete]: readonly [
    IActionState<any>,
    typeof deleteDocument
  ] = useAsyncAction(deleteDocument);

  const [createState, execCreate]: readonly [
    IActionState<any>,
    typeof createDocument
  ] = useAsyncAction(createDocument);

  const [deleteFileState, execDeleteFile]: readonly [
    IActionState<any>,
    typeof deleteDocumentAttachment
  ] = useAsyncAction(deleteDocumentAttachment);

  const [existingFiles, setExistingFiles] = useState<IExistingFile[]>([]);
  const [existingFileIdsToDelete, setExistingFileIdsToDelete] = useState<
    number[]
  >([]);

  const formMethods = useForm<IDocumentFormValues>({});

  useEffect(() => {
    if (deleteFileState.data) {
      execFetchGroup(id);
    }
  }, [deleteFileState.data]);

  useEffect(() => {
    if (createState.data) {
      setNewFiles([]);
      execFetchGroup(id);
    }
  }, [createState.data]);

  const submitForm = (values: IDocumentFormValues) => {
    
    if (document && document.id) {
      execSubmit(id, document.id, values);
    } else {
      execCreate(id, values);
    }

  };
  useEffect(() => {
    formMethods.setValue("document_attachments", newFiles);
  }, [newFiles]);

  useEffect(() => {
    if (document) {
      formMethods.setValue("name", document.name || "");
      formMethods.setValue("description", document.description || "");
    }
  }, [document]);

  return (
    <Row gutter={15}>
      {submitState.loading && <Spinner />}
      <Col md={12}>
        <FileDropzone
          formMethods={formMethods}
          files={newFiles}
          setFiles={setNewFiles}
          existingFiles={document?.files || []}
          filesPropName="document_attachments"
          execDeleteFile={
            document?.id
              ? (fileId: string) => {
                  if (document.id) {
                    execDeleteFile(id, document.id, fileId);
                  }
                }
              : undefined
          }
        />
      </Col>
      <Col md={12}>
        <div className="mb-20">
          <TextField name="name" label="Nazwa" formMethods={formMethods} />
          <TextField
            name="description"
            label="Opis"
            formMethods={formMethods}
          />
        </div>
        <div className="t-right">
          <Button
            title="Zapisz"
            width={150}
            className="mr-5"
            inline={true}
            color="primary"
            onClick={formMethods.handleSubmit(submitForm)}
          />
          {document && document.id && (
            <Button
              title="Usuń"
              width={150}
              inline={true}
              color="white"
              onClick={async () => {
                await execDelete(id, document?.id || "");
                execFetchGroup(id);
              }}
            />
          )}
        </div>
      </Col>
    </Row>
  );
};

export default DocumentsEditForm;
