import React, { useState, useEffect, useMemo, useRef } from "react";
import ErrorMessage from "components/common/error-message";
import { ICodesState, ICode } from "types/waste-types";
import { IWasteCardFormValues } from "types/waste-card-types";
import TextField from "./text-field";
import SelectField from "./select-field";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ExpandLess from "@material-ui/icons/ExpandLess";

import style from "./waste-card-form.module.scss";
import _ from "lodash";
import { isNumber } from "util";
import Row from "antd/lib/row";
import Col from 'antd/lib/col';

const typicalWasteKey = "Typowe odpady powstające w warszatcie samochodowym";

const Step3 = (props: any) => {
  const [codesList, setCodesList] = useState<any>(null);

  //** these variables are used for optimization purpose ( to avoid rerenders every time user type in numeric inputs) */
  const [codeUnits, setCodeUnits] = useState<{
    [code: string]: string | undefined;
  }>({});
  const [codeValues, setCodeValues] = useState<{
    [code: string]: number | undefined;
  }>({});
  const valuesRef = useRef<{ [code: string]: number | undefined }>();
  valuesRef.current = codeValues;
  const unitRef = useRef<{ [code: string]: string | undefined }>();
  unitRef.current = codeUnits;

  const [visibleSections, setVisibleSections] = useState<{
    [key: string]: boolean;
  }>({
    [typicalWasteKey]: true,
  });

  const {
    setFieldValue,
    wasteCardMode,
    setIsLoading,
    validateForm,
    setFieldTouched,
    errors,
  } = props;

  function validateCode(amount: number, code: string) {
    let unit: string | undefined = "";
    if (unitRef.current) {
      unit = unitRef.current[code] ? unitRef.current[code] : "kg";
    }
    let error;

    if (
      wasteCardMode &&
      (amount === 0 ||
        (unit === "kg" && amount < 1) ||
        (unit === "mg" && amount < 0.001))
    ) {
      error = "Wartość musi być większa niż 1 kg (0.001 Mg)!";
    }
    return error;
  }

  const { values }: { values: IWasteCardFormValues } = props;
  const { codesState }: { codesState: ICodesState } = props;

  const unitOptions = [
    { value: "kg", label: "kg", default: true },
    { value: "mg", label: "Mg" },
  ];
  const onAmountChange = (e: { target: { value: string } }, code: string) => {
    const val = parseFloat(e.target.value);
    let unit: string | undefined = "";

    if (unitRef.current) {
      unit = unitRef.current[code] ? unitRef.current[code] : "kg";
    }

    if (val && typeof val === "number") {
      setFieldValue(`codes[${code}]`, {
        amount: val,
        unit: unit,
      });

      // if (
      //   wasteCardMode &&
      //   ((unit == "kg" && val < 1) || (unit == "mg" && val < 0.001))
      // ) {
      //   setCodeValues((prevObj) => {
      //
      //     let newData = { ...prevObj };
      //     delete newData[code];
      //     return newData;
      //   });
      // }
      // else {
      setCodeValues((prevObj) => ({ ...prevObj, [code]: val }));
      // }
    } else {
      setFieldValue(`codes[${code}]`, undefined);
      setCodeValues((prevObj) => {
        let newData = { ...prevObj };
        delete newData[code];
        return newData;
      });
    }
  };

  const onUnitChange = (
    e: { target: { value: string } },
    code: string,
    values: IWasteCardFormValues
  ) => {
    const val = e.target.value;

    if (!valuesRef.current) {
      return;
    }

    let amount = valuesRef.current[code];

    if (!amount) {
      //removed code
      // setFieldValue(`codes[${code}]`, {
      //   // amount: undefined,
      //   unit: val,
      // });
      setCodeUnits((prevObj) => ({ ...prevObj, [code]: val }));
      // removed code
      // setCodeValues((prevObj) => ({ ...prevObj, [code]: undefined }));

      setTimeout(() => setFieldTouched(`codes[${code}]`, true, true));
      //
      return;
    }

    if (val === "kg") {
      amount = amount * 1000;
    } else if (val === "mg") {
      amount = amount / 1000;
    }

    setFieldValue(`codes[${code}]`, {
      amount: amount,
      unit: val,
    });
    setCodeUnits((prevObj) => ({ ...prevObj, [code]: val }));
    setCodeValues((prevObj) => ({ ...prevObj, [code]: amount }));

    setFieldTouched(`codes[${code}]`, true, true);
  };

  /** functions ******************* */
  const toggleSection = (key: string, visible: boolean) => {
    setVisibleSections((prevObj) => ({ ...prevObj, [key]: visible }));
  };

  const renderCodes = (codes: Array<ICode>) => {
    return codes.map((item, index: number) => {
      if (valuesRef.current) {
      }

      let disabled = false;
      if (valuesRef.current && !valuesRef.current[item.normalized_code]) {
        disabled = true;
      }

      return (
        <tr
          key={`code_${item.code}`}
          id="record-2"
          className="odd record-2-row first"
        >
          <td >
            <div className="no-wrap t-500" id="code">{item.code}</div>
          </td>{" "}
          <td className="_name">
            <div id="_name">
              {item.name}
              <div className="info">{item.info}</div>
            </div>
          </td>{" "}
          <td style={{width: '150px'}}  className="_check">
            
              <Row gutter={5}>
                <Col md={15}>
                <TextField
                  name={`codes['${item.normalized_code}'].amount`}
                  type="number"
                  validate={(amount: number) =>
                    validateCode(amount, item.normalized_code)
                  }
                  min="0"
                  disableErrorMessage={true}
                  onChange={(e: { target: { value: string } }) =>
                    onAmountChange(e, item.normalized_code)
                  }
                />

                {errors.codes && errors.codes[item.normalized_code] && (
                  <label className="validation_error">
                    {errors.codes[item.normalized_code].amount}
                  </label>
                )}
             </Col>
              <Col  md={9}>
                <SelectField
                  name={`codes['${item.normalized_code}'].unit`}
                  type="select"
                  options={unitOptions}
                  disableDefault={true}
                  // disabled={disabled}
                  initialValue="kg"
                  onChange={(e: { target: { value: string } }) =>
                    onUnitChange(e, item.normalized_code, values)
                  }
                />
              </Col>
            </Row>
            
          </td>
        </tr>
      );
    });
  };

  const renderCodeTypes = () => {
    let result : any[] = [];
    if(!codesState.data){
      return result;
    }
    result = Object.keys(codesState.data).map((key, index) => {
      return (
        <div
          key={`type_${key}`}
          className={`${style.waste_group_header} ${
            visibleSections[key] ? "" : style.hidden
          }`}
        >
          {/* {key}{" "} */}

          <div className={`fields-group-body`} id="cg-00-group-body">
            <table className="custom-ant-table ant-table waste-table" id="table-w6">
              <thead id="thead-w6" className="ant-table-thead">
                <tr>
                  <th colSpan={3}>
                    <div className="flex">
                      
                      {visibleSections[key] ? (
                          <div role="button"      onClick={() => toggleSection(key, false)} className="ant-table-row-expand-icon ant-table-row-expanded mr-5" aria-label="Collapse row"></div>
                        // <ExpandLess
                        //   className={style["arrow"]}
                        //   onClick={() => toggleSection(key, false)} 
                        // />
                      ) : (
                        <div role="button"      onClick={() => toggleSection(key, true)} className="ant-table-row-expand-icon ant-table-row-collapsed mr-5" aria-label="Collapse row"></div>
                      )}
                      <span >{key} </span>
                    </div>
                  </th>
                  
                  {/* <th className="code">Nr i kod odpadu</th>
                  <th className="_name">Rodzaj odpadu</th>
                  <th className="_check">Ilość odpadów</th> */}
                </tr>
              </thead>
              {visibleSections[key] &&
                  <tbody id="tbody-w6">{renderCodes(codesState.data[key])}</tbody>
              }
            </table>
          </div>
        </div>
      );
    });
    return result;
  };

  //** side effects **************************  */

  // useEffect(() => {
  //   /* this simulates ajax loading (codes data is already downloaded by GET,
  //    in this step we asign returned JSX to variable (codesList) and show loading indicator in meantime ) */
  //   setIsLoading(true);
  //   setTimeout(function () {
  //     setCodesList(renderCodeTypes());
  //   });
  // }, [Object.keys(values.codes).length]);

  useEffect(() => {
    validateForm();
    // setFieldTouched("codes", true, true);
  }, []);

  useEffect(() => {
    /* if codesList is loaded with JSX, we hide loading spinner */
    if (codesList) {
      setIsLoading(false);
    }
  }, [codesList]);

  //** for optimization - to avoid rerender every time user type in numeric inpuit */
  // const codeTypes = React.useMemo(() => renderCodeTypes(), [
  //   codeUnits,
  //   Object.keys(codeValues).length,
  //   visibleSections,
  // ]);

  return (
    <div id="step-3">
      {/* <WasteModal {...modalProps} /> */}
      <h4 className="primary mb-10">Rodzaje i ilość odpadów</h4>
      <div className="mb-30">
        {/* <Alert message="Należy dodać masę dla przynajmniej jednego kodu odpadu" /> */}
        {/* <TableWrapper children={renderCodeTypes()} /> */}
        {renderCodeTypes()}
        {/* {codeTypes} */}
      </div>
      {/* <ErrorMessage name="codes" style="global" /> */}
    </div>
  );
};

export default Step3;
