import axios from "axios";
import { API_BASE_URL, WORKSHOP_API_URL } from "config";
import { workshopRequestHeaders } from "utils/auth";

export default axios.create({
    baseURL: `${WORKSHOP_API_URL}`,
    headers: {
      Authorization: workshopRequestHeaders(),
      Accept: "application/json",
      "Content-Type" : 'application/json',
    },
  });
  


