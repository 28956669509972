import React, { useState } from "react";
import SimpleHeader from "components/layout/simple-header";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { Link } from "react-router-dom";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import { PAGE } from "paths";
// import "antd/dist/antd.min.css";
//@ts-ignore
import "styles/pages/login.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

interface IProps {}

function ChangePassword(props: IProps) {
  const userState = useSelector((state: RootState) => state.user);
  // props & hooks

  const urlParams = new URLSearchParams(window.location.search);
  // const email = urlParams.get("email");
  // const email = 'test@email.com'
  return (
    <div data-test="login-page" className="login-page">
      <main>
        <Link to={PAGE.LOGIN} className="close-link">
          <CloseIcon className="close" />
        </Link>
        <SimpleHeader />
        <Row className="ant-row-flex login" itemType="flex">
          <Col className="store-info"  xs={24} lg={24}>
            <section className="">
              <div className="center mb-15">
                <CheckCircleOutlineIcon className="check-circle green" />
              </div>
              <h1 className="green center">E-mail resetu hasła wysłany</h1>
              <p className="lg-t mb-20 center">
              Na podany adres e-mail wysłaliśmy Ci link, za pomocą którego możesz ustawić nowe hasło.
              </p>

              <p className="lg-t mb-20 center">{userState?.message}</p>
              {userState && userState.debug_token_preview && (
                <Link to={`${userState.debug_token_preview}`}>
                  {`${userState.debug_token_preview}`}
                </Link>
              )}
            </section>
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default ChangePassword;
