import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import {requestHeaders } from "utils/auth";
import { IFetchPaymentsAction, FETCH_PAYMENTS } from "types/redux-types";
import { ActionResponse } from "types/async-types";


const DASHBOARD_URL = `${API_BASE_URL}/dashboard`

export const fetchDashboardVehicleStats = async () => {
  const options: AxiosRequestConfig = {
    url: `${DASHBOARD_URL}/vehicle_stats`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if(res){
    return res;
  }
  
};


export const fetchRecyclingServices = async () => {
  const options: AxiosRequestConfig = {
    url: `${DASHBOARD_URL}/recycling_services`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if(res){
    return res;
  }
  
};

export const fetchWasteAdministration = async () => {
    const options: AxiosRequestConfig = {
      url: `${DASHBOARD_URL}/waste_administration`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const fetchBDOServices = async () => {
    const options: AxiosRequestConfig = {
      url: `${DASHBOARD_URL}/bdo_services`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const fetchNetworkServices = async () => {
    const options: AxiosRequestConfig = {
      url: `${DASHBOARD_URL}/network_services`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  // export const setBreakTime = (state: "start" | "end") => async (
  //   dispatch: any
  // ) => {
  //   const options: AxiosRequestConfig = {
  //     url: `${API_BASE_URL}/users/break_time`,
  //     method: "POST",
  //     headers: requestHeaders().headers,
  //     data: {
  //       state,
  //     },
  //   };
  //   let res: ActionResponse<any> = await axios(options);
    
  //     const action: ISetBreakAction = {
  //       type: SET_BREAK,
  //       payload: res.data,
  //     };
  //     dispatch(action);
  //     return res;
    
  // };

  export const fetchPayments =  () => async (dispatch: any) =>  {
    const options: AxiosRequestConfig = {
      url: `${DASHBOARD_URL}/payments`,
      headers: requestHeaders().headers,
      method: "GET",
    };

    let res: ActionResponse<any> = await axios(options);

    const action : IFetchPaymentsAction = { type: FETCH_PAYMENTS, payload: res.data  };
    dispatch(action);
    if(res){
      return res;
    }
    
  };