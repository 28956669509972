import React from 'react';
import Logo from 'components/layout/logo'
import { Layout } from 'antd';
import 'styles/components/common/page-header.scss'
import { PAGE } from 'paths';
const {Header} = Layout;




const Navbar = (props: any) => (
  <Header className="simple-header">
    <Logo link={PAGE.LOGIN} />
  
    {/* <style jsx global> {`
      @import 'src/styles/base/variables.scss';
      @import 'src/styles/base/mixins.scss';
      .simple-header{
        background-color: $primary-blue;
        display: flex;
    align-items: center;
        
        position:fixed;
         z-index:9999;
        width:100%;

        span{
          font-size: 10px;
          font-family: 'sofia-pro';
          color : $white;
          max-width: 180px;
              line-height: 1.2;
              display:inline-block;
              margin-left:12px;

        }
      }

      `}</style> */}
  </Header>

);

export default Navbar;
//import  React, {component} from 'react';
