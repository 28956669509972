import React, { useState, useEffect, Suspense, lazy, useRef } from "react";
import { Formik, Form, FormikProps } from "formik";

// import style from "./waste-card-form.module.scss";
import { stepsValidationSchema } from "config/form-config/waste-card-config";
import { useAsyncAction } from "utils/async-action";
import { fetchCodes, createWasteCard } from "actions/waste";
import { IWasteCardCreateBody } from "types/waste-card-types";
import { IWasteCardFormValues } from "types/waste-card-types";
import { ICodesState } from "types/waste-types";
import { IActionState } from "types/async-types";
import SpinnerPopup from "components/core/spinner";
// import Step1 from "./step-1";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";
import { withAuthSync } from "utils/auth";
import _ from "lodash";
import { IAddressesState, IWasteAddress } from "types/address-types";
import { fetchAddresses } from "actions/company";
import RadioGroup from 'components/form-fields/radio-group'
import Step1 from "./step1";
import Step2 from "./step2";
import {wasteCodes} from '../mock-waste-codes'
import Button from "components/buttons/form-button";

// const Step3 = lazy(() => import("./step-3"));
type RadioGroupProps = React.ComponentProps<typeof RadioGroup>;

interface IProps  {isCard : boolean | undefined, toggleSider : any, execFetchCardsHistory : any}

const WasteCardForm = (props : IProps) => {
  const {isCard, toggleSider, execFetchCardsHistory} = props;
  
  const have_card : RadioGroupProps  = {
    name : "is_card",
    label : "Czy posiadasz kartę przekazania odpadów KPO ?",
    orientation : "horizontal",
    
    options : [{
      label : "Dodaj z kartą",
      value : true,
      checked : isCard ? true : false,

    },
    {
      label : "Dodaj bez karty",
      value : false,
      checked : isCard ? false : true,

    }
  ]
  }
  

  const formikRef: any = useRef();
  const formRef: FormikProps<IWasteCardFormValues> | undefined = formikRef.current;
  const values = formRef?.values;
  const [wasteCardMode, setWasteCardMode] = useState(false);
  const [stepNo, setStepNo] = useState<number>(1);
  const history = useHistory();
  const [wasteRecords, setWasteRecords] = useState({});
  const [header, setHeader] = useState<string | null>(null);
  const [cardNr, setCardNr] = useState<string | null>(null);
  const [cardDate, setCardDate] = useState<string | null>(null);
  const [addressesState, execFetchAddresses]: readonly [
    IAddressesState,
    any
  ] = useAsyncAction(fetchAddresses);
  const [codesState, execFetchCodes]: readonly [
    ICodesState,
    any
  ] = useAsyncAction(fetchCodes);
  // const codesState = wasteCodes;

  const [wasteCardState, execCreateWasteCard]: readonly [
    IActionState<any>,
    any
  ] = useAsyncAction(createWasteCard);




  const [isLoading, setIsLoading] = useState(false);

  const [selectedAddress, setSelectedAddress] = useState<IWasteAddress | null>(
    null
  );

  const schema: {
    [key: number]: any;
  } = stepsValidationSchema(values?.is_card || false);

  let street, locum_number, building_number, city, postal_code;
  if (selectedAddress != null) {
    ({
      street,
      locum_number,
      building_number,
      city,
      postal_code,
    } = selectedAddress);
  }

  const initialValues: IWasteCardFormValues = {
    is_card: isCard,
    codes: {},
    no_card_number: [],
  };

  const onSubmit = (values: IWasteCardFormValues) => {
    let data: IWasteCardCreateBody = {
      waste_card: _.clone(values),
    };
    data.waste_card.no_card_number =
      values.no_card_number && values.no_card_number[0] === "true";
    let codesArray = [];
    for (const [key, item] of Object.entries(values.codes)) {
      const typeditem: any = item;
      if (item) {
        codesArray.push({
          normalized_code: key,
          amount: typeditem.amount,
          unit: typeditem.unit,
        });
      }
    }
    data.waste_card.codes = codesArray;
    data.waste_card.card_type = values.is_card ? "import_export" : "import";
    execCreateWasteCard(data);
  };

  useEffect(() => {
    if (wasteCardState.data) {
      // history.push(`${PAGE.WASTE}?history=true`);
      
      
      toggleSider(undefined);
      execFetchCardsHistory();
      
    }
  }, [wasteCardState.data]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    // let isCard: boolean | string | null = urlParams.get("wastecard");
    // isCard = isCard === "true";
    // setWasteCardMode(isCard);

    execFetchAddresses();
    execFetchCodes();
  }, []);

  const renderButtons = (
    isValid: boolean,
    validateForm: (values?: any) => Promise<any>,
    submitForm: (() => Promise<void>) & (() => Promise<any>)
  ) => {
    return (
      <button
        disabled={!isValid}
        type="submit"
        onClick={submitForm}
        className="button button-link"
      >
        Zapisz odpady
      </button>
    );
  };

  const handleIsCardChange = (value : boolean) => {
    
      setWasteCardMode(value);
  }

  // const schema = stepsValidationSchema({isCard});

  return (
    <div>
      {/* <WasteAddress /> */}
      {/* {(stepNo == 1 || !wasteCardMode) && <PageHeader title={header} />} */}
      {(addressesState.loading || wasteCardState.loading || isLoading) && (
        <SpinnerPopup size="default" />
      )}
      <Formik
        initialValues={initialValues}
        isInitialValid={false}
        enableReinitialize={true}
        validationSchema={schema[stepNo]}
        onSubmit={onSubmit}
        innerRef={formikRef}
      >
        {({
          isSubmitting,
          submitForm,
          values,
          setFieldValue,
          errors,
          isValid,
          validateForm,
          setFieldTouched,
        }) => {
          const stepProps = {
            execFetchAddresses,
            setStepNo: setStepNo,
            // renderFields: renderFields,
            wasteRecords,
            setWasteRecords,
            setFieldValue,
            values,
            validateForm,
            setFieldTouched,
            wasteCardMode,
            setHeader,
            setCardDate,
            setCardNr,
            addressesState,
            setSelectedAddress,
            codesState,
            setIsLoading,
            isLoading,
            selectedAddress,
            errors,
            isCard
          };
          const lastStepProps = {
            ...stepProps,
            submitForm: submitForm,
            isSubmitting: isSubmitting,
          };
          console.log('wasteCardMode: ',wasteCardMode);
      return (<>
            <div className="sider-body">
              <Form >
                <RadioGroup  onChange={(e: boolean) => handleIsCardChange(e) }  {...have_card}  />
                <div className="mb-15">
                <Step1 {...stepProps} />
                </div>
                
                <Step2 {...stepProps} />
                {/* <div className="mb-15 i-b">
               
                </div> */}

              </Form>
              {/* {renderButtons(isValid, validateForm, submitForm)} */}
            </div>
            <div className="sider-footer t-right">
                   <Button disabled={!isValid} title={values.is_card ? "Dodaj z kartą" : "Dodaj bez karty" }color="primary" width={150} className="f-right" inline={true} onClick={submitForm} />
            </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default WasteCardForm;
