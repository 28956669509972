//@ts-nocheck
import { AUTH_USER, ISignInAction } from "types/redux-types";
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import { login } from "utils/auth";
import { API_BASE_URL } from "config";
import {ISignIn, ISendVerificationCode, ILoginRequest} from 'types/login-types';


const LOGIN_URL = `${API_BASE_URL}/login`;
const VERIFICATION_CODE_URL = `${API_BASE_URL}/send_verification_code`;


export const signIn : ISignIn = (data : ILoginRequest, redirectUrl? : string | null) => async (dispatch: any) => {
  
  let res = await axios.post(LOGIN_URL, data, { withCredentials : true });
  const action : ISignInAction = { type: AUTH_USER, payload: { user: res.data } };
  dispatch(action);
  
  login(res.data.token, redirectUrl);
  return res;
};

export const sendVerificationCode   = async (phone: string) =>  {
  let data ;
  let result;
  const options: AxiosRequestConfig = {
    url: VERIFICATION_CODE_URL ,
    method: "POST",
    data: { phone_number: phone },
  };
  
  try{
    
   data = await axios(options);
    // data = await data;
    alert("Kod sms: " + data.data.debug_code_preview);
    return {customResult : true, data: data.data};
    // return data;
  }
  catch(e){
    const errorData = {errorMessage: true,  messageConfig : {
      description : 'test content',
      position : 'top-right'
    }, ...e as Object};
    
    throw errorData;
  }
 
};

