import "../../wdyr";
import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import CheckFormField from "components/form-fields/hook-form-fields/hook-check-field";
import TextFormField from "components/form-fields/hook-form-fields/hook-text-field";
import { IRadioGroupProps } from "components/form-fields/hook-form-fields/hook-radio-group";
import FormButtons from 'components/common/form-buttons';
import {
  ICampaignLeafletFormData, ICampaignLeafletValues,
} from "types/BDO-report-types";
import Spinner from "components/core/spinner";
import FormButton from "components/buttons/form-button";
import {
  useForm,
  FormProvider
} from "react-hook-form";
import { useGraphForm } from "./graph-form-utils";
import HookFormAddressSection from "components/common/hook-form-address-section";
import { leaflets } from "./leaflet-images";
import HookRadioGroup from "components/form-fields/hook-form-fields/hook-radio-button";
import { numToCurrency } from "utils/helpers";
import { ConfirmModal } from "components/common/confirm-modal";

const BDOYearReportCampaignLeaflet = (props: { setNavState: any }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { setNavState } = props;
  const id = urlParams.get("id");
  const {
    state,
    initializeStep,
    saveStep,
    goToStep,
    formData,
    stepNavData
  } = useGraphForm<ICampaignLeafletFormData>(id);

  const initialValues = {
  };

  const [sendAddressDisabled, setSendAddressDisabled] = useState(true);

  const [leafletCount, setLeafletCount] = useState(0)
  const [leafletTemplate, setLeafletTemplate] = useState('')
  const [leafletsPrice, setLeafletsPrice] = useState(0)
  const [summaryBiling, setSummaryBiling] = useState('')
  const [summarySendAddress, setSummarySendAddress] = useState('')

  const formMethods = useForm<ICampaignLeafletFormData>({
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { control, watch, reset, getValues } = formMethods;

  useEffect(() => {
    setNavState({
      goToStep,
      stepNavData,
    });
  }, [stepNavData]);

  useEffect(() => {
    initializeStep("campaign_leaflet");
  }, []);

  useEffect(() => {
    if (formData.data) {
      reset({
        ...formData.data,
      });

      setLeafletCount(formData.data.leaflet_count)
      setLeafletTemplate(formData.data.leaflet_template)
      setSendAddressDisabled(!(formData.data.leaflet_send_address_different || false));
      setLeafletsPrice(Number(formData.data?.campaign_netto || 0))
      setSummaryBiling(addressFormatterBilling())
      setSummarySendAddress(addressFormatterSendAddress())
    }
  }, [formData.data]);

  const toggleSendAddress = () => {
    setSendAddressDisabled(!sendAddressDisabled);
    setSummarySendAddress((sendAddressDisabled) ? addressFormatterSendAddress : addressFormatterBilling)
  }

  const submitForm = (values: ICampaignLeafletValues) => {
    saveStep("campaign_leaflet", values);
  };

  const leafletOptions = leaflets.map(leaflet => {
    return (
      {
        value: leaflet.key,
        label: (<div><img src={leaflet.file} width={275} /><p className='t-center'>{`Wzór nr ${leaflet.key}`}</p></div>)
      }
    );
  });

  const leafletTemplates: IRadioGroupProps<string> = {
    formMethods: formMethods,
    orientation: "horizontal",
    name: "leaflet_template",
    label: "",
    options: leafletOptions,
    onChange: (() => { setLeafletTemplate(formMethods.getValues('leaflet_template')) }),
    className: "leaflet-wrapper"
  };

  const addressFormatterBilling = () => {
    return (
      [
        formMethods.getValues(`billing.company_name`),
        formMethods.getValues(`billing.postal_code`),
        formMethods.getValues(`billing.post_office`),
        formMethods.getValues(`billing.aup_type`),
        formMethods.getValues(`billing.street`),
        [formMethods.getValues(`billing.building_number`),
        formMethods.getValues(`billing.locum_number`)].filter(elm => elm).join('/')
      ]
        .filter(elm => elm).join(' ')
    )
  }

  const addressFormatterSendAddress = () => {
    return (
      [
        formMethods.getValues(`address_attributes.company_name`),
        formMethods.getValues(`address_attributes.postal_code`),
        formMethods.getValues(`address_attributes.post_office`),
        formMethods.getValues(`address_attributes.aup_type`),
        formMethods.getValues(`address_attributes.street`),
        [formMethods.getValues(`address_attributes.building_number`),
        formMethods.getValues(`address_attributes.locum_number`)].filter(elm => elm).join('/')
      ]
        .filter(elm => elm).join(' ')
    )
  }

  const skipCampaignModal = () => {
    ConfirmModal({
      action: () => skipCampaign(),
      header: "Pominiecie kampanii",
      text: (<span ><p className='center'>Nie masz obowiązku przeprowadzenia Kampanii edukacyjnych jeśli wartość kampanii nie przekracza 10 zł.</p><p className='center'>Upewnij się czy Twój Urząd nie wymaga złożenia zerowego sprawozdania.</p></span>),
      yesText: "TAK, rezygnuję",
      noText: "NIE, powrót do kampanii",
      btnColor: "primary",
    });
  }

  const skipCampaign = () => {
    submitForm({ is_leaflet_resign: true })
  }

  return (
    <FormProvider {...formMethods}>
      <div>
        {(state.isLoading) && (
          <Spinner size="large" fixed={true} />
        )}
        <div className="block">
          <h2 className="h-lg">
            Kampania edukacyjna
          </h2>
          <Row gutter={10}>
            <Col>
              <p className="bold large mb-10 justify">
                Masz podpisaną umowę z Organizacją Odzysku. Zgodnie z ustawą z dnia 24 kwietnia 2009 r. o bateriach i akumulatorach jesteś obowiązany do złożenia sprawozdania z przeprowadzonej kampanii edukacyjnej. Zamów ulotki i przeprowadź kampanię. Będzie to odnotowane w złożonym Sprawozdaniu BDO. Możesz ten krok pominąć i samodzielnie złożyć sprawozdanie oraz przeprowadzić kampanię edukacyjną. Pamietaj, ze nie wypełniając tego obowiązku narażasz się na wysokie kary z BDO.
              </p>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col>
              <p className="large mb-0">
                Minimalna wartość środków, którą jako wprowadzający powinieneś przeznaczyć na publiczne kampanie edukacyjne.
              </p>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col lg={2}>
              <TextFormField
                name="campaign_netto"
                label="netto"
                type="text"
                disabled={true}
                formMethods={formMethods}
              />
            </Col>
            <Col lg={2}>
              <TextFormField
                name="campaign_brutto"
                label="brutto"
                type="text"
                disabled={true}
                formMethods={formMethods}
              />
            </Col>
          </Row>
          <Row gutter={10}>
            <h2 className="black mb-10">
              Zamówienie
            </h2>
            <p className="large mb-10">
              Liczba ulotek dostępna w ramach minimalnej wartości kampanii.
            </p>
            <p className="grey-2 mb-10">
              Format każdej ulotki to A6. Wydruk full kolor na papierze kredowym o gramaturze 250 mg. Cena jednej ulotki to {numToCurrency(formData.data?.leaflet_netto)} netto.<br />
              Liczba ulotek w komplecie wyliczona na podstawie kwoty na kampanie edukacyjne zgodnie z Regulaminem dot. sprzedaży ulotek
            </p>
          </Row>
          <Row gutter={10}>
            <Col lg={2}>
              <TextFormField
                name="leaflet_count"
                label='szt.'
                formMethods={formMethods}
                onChange={() => {
                  setLeafletCount(formMethods.getValues('leaflet_count'))
                }}
                disabled={true}
              />
            </Col>
          </Row>
          <Row gutter={10} className='mt-20'>
            <h2 className="black">
              Dane do faktury
            </h2>
            <section>
              <HookFormAddressSection
                formMethods={formMethods}
                extended={true}
                parentName="billing"
                disabled={true}
                companyName={true}
                disableAddressPreload={true}
              />
            </section>
          </Row>
          <Row gutter={10}>
            <Col lg={5}>
              <h2 className="black">
                Dane dostawy
              </h2>
            </Col>
            <Col lg={7} className='mt-5'>
              <CheckFormField
                formMethods={formMethods}
                cbxLabel="adres dostawy inny niż adres do faktury "
                name="leaflet_send_address_different"
                value={true}
                isFlag={true}
                onCheckChange={toggleSendAddress}
              />
            </Col>
          </Row>
          <Row>
            <div onMouseLeave={(e) => { if (!sendAddressDisabled) { setSummarySendAddress(addressFormatterSendAddress()) } }}>
              <section>
                <HookFormAddressSection
                  formMethods={formMethods}
                  extended={true}
                  parentName="address_attributes"
                  disabled={sendAddressDisabled}
                  companyName={true}
                  disableAddressPreload={true}
                />
              </section>
            </div>
          </Row>
          <section>
            <h2 className="black mb-10">
              Wzory ulotek
            </h2>
            <p>
              Wybierz ulotkę spośród niżej prezentowanych wzorów
            </p>
            <HookRadioGroup {...leafletTemplates} />
          </section>

          <h2 className="black mb-10">
            Podsumowanie zamówienia
          </h2>
          <div>
            <Row>
              <Col span={6} >Liczba zamówionych ulotek: </Col>
              <Col span={6} className="bold" >{leafletCount}</Col>
            </Row>
            <Row>
              <Col span={6} >Wybrany wzór: </Col>
              <Col span={6} className="bold">{leafletTemplate}</Col>
            </Row>
            <Row>
              <Col span={6} >Cena netto za komplet: </Col>
              <Col span={6} className="bold">{numToCurrency(leafletsPrice)}</Col>
            </Row>
            <Row>
              <Col span={6} >Dane do faktury: </Col>
              <Col span={18} className="bold">{summaryBiling}</Col>
            </Row>
            <Row>
              <Col span={6} >Adres dostawy: </Col>
              <Col span={18} className="bold">{summarySendAddress}</Col>
            </Row>
            <Row>
              <Col span={6} >Sposób dostawy:</Col>
              <Col span={6} className="bold">zgodnie z regulaminem dot. sprzedaży ulotek</Col>
            </Row>
          </div>
          <Row gutter={10} className="mt-5">
            <Col>
              <CheckFormField
                formMethods={formMethods}
                cbxLabel={<span className='t-large'>Akceptuję postanowienia <a href='https://integrator.org.pl/files/regulamin_ulotki_kampania_edukacyjna_v1.pdf' target='_blank'>Regulaminu dot. sprzedaży ulotek</a> oraz oświadczam, że niniejszym zostałem poinformowany i akceptuję, że Integrator Sp z o.o. od 2022r. nie przyjmuje i nie realizuje zamówień dot. kampanii edukacyjnej według zasad sprzed 2022r. *</span>}
                name="is_campaign_terms_accepted"
                value={true}
                isFlag={true}
                rules={{
                  required: "Pole jest wymagane",
                }}
              />
            </Col>
          </Row>
        </div>
      </div>
      <FormButtons>
        <FormButton className="mr-5"
          width={300}
          color="primary"
          onClick={formMethods.handleSubmit(submitForm)}
          title="Zamawiam z obowiązkiem zapłaty"
          inline={true}
        />
        {formData.data?.show_resign_button &&
          <FormButton
            width={300}
            color="primary"
            onClick={skipCampaignModal}
            title="Rezygnuję i przechodzę dalej"
            inline={true}
            disabled={!formMethods.getValues(`can_resign`)}
          />
        }
      </FormButtons>
    </FormProvider>
  );
};

export default BDOYearReportCampaignLeaflet;