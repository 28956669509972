import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { Formik, FormikProps } from "formik";
import TextField from "components/form-fields/formik-text-field";
import SelectField from "components/form-fields/select-field";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import Button from "components/buttons/form-button";
import LinkButton from "components/buttons/button";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { PAGE } from "paths";
import { Link, useHistory } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import * as Yup from "yup";
import { yupRequired } from "validators";
import APIKeyLoading from "./api-key-loading";
import { IActionState } from "types/async-types";
import { IBDOKey, IBDOKeyTypes } from "types/BDO-keys-types";
import {
  fetchBDOKeys,
  fetchBDOKeyFormData,
  createBDOKey,
  updateBDOKey,
} from "actions/BDO-key";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { IBDOWSFormData } from "types/BDO-report-types";
import { renderJsonOptions } from "utils/select-options";
import { fetchBDOWSFormData } from "actions/BDO-reports";

interface IProps {
  formData?: IBDOWSFormData;
}

const BDOAPIKey = (props: IProps) => {
  const { formData } = props;
  const history = useHistory();
  const [currentKey, setCurrentKey] = useState<IBDOKey | undefined>(undefined);
  const submitForm = async (values: any) => {
    const vals = { ...values, key_type: "INT" };

    let res

    if (currentKey) {
      res = await execUpdateBDOKey(currentKey.uuid, vals);

    } else {
      res = await execCreateBDOKey(vals);
    }

    if (res.status === 200 || res.status === 201) {
      history.push(PAGE.BDO_REPORT_SUCCESS);
    }
  };

  const [createBDOKeyState, execCreateBDOKey]: readonly [
    IActionState<any>,
    typeof createBDOKey
  ] = useAsyncAction(createBDOKey, { customError: true });

  const [updateBDOKeyState, execUpdateBDOKey]: readonly [
    IActionState<any>,
    typeof updateBDOKey
  ] = useAsyncAction(updateBDOKey);


  const [BDOKeysState, execFetchBDOKeys]: readonly [
    IActionState<{ data: IBDOKey[], list: IBDOKey[] }>,
    typeof fetchBDOKeys
  ] = useAsyncAction(fetchBDOKeys);

  const [formDataState, execFetchBDOKeysFormData]: readonly [
    IActionState<IBDOKeyTypes>,
    typeof fetchBDOKeyFormData
  ] = useAsyncAction(fetchBDOKeyFormData);


  useEffect(() => {
    if (BDOKeysState.data) {
      if (BDOKeysState.data.list) {
        const result = BDOKeysState.data.list.find((item, index) => {
          return item.key_type === "INT";
        });
        if (result) {
          setCurrentKey(result);
        }
      }
    }
  }, [BDOKeysState.data]);

  useEffect(() => {
    execFetchBDOKeys();
    execFetchBDOKeysFormData();
  }, []);

  useEffect(() => {
    if (formDataState.response?.status === 422) {

      history.push(PAGE.HOME);
    }

  }, [formDataState.response]);

  const validationSchema = Yup.object().shape({
    client_id: Yup.string().concat(yupRequired),
    client_secret: Yup.string().concat(yupRequired),
  });

  return (
    <div className="">
      <Formik
        isInitialValid={false}
        enableReinitialize={true}
        validationSchema={validationSchema}
        initialValues={{
          client_id: currentKey?.client_id || "",
          client_secret: currentKey?.client_secret || "",
          key_type: "INT",
        }}
        onSubmit={submitForm}
      >
        {(formikProps: FormikProps<any>) => {
          // if (createBDOKeyState.loading) {
          //   return <APIKeyLoading />;
          // }

          return (
            <div className="block">
              {createBDOKeyState.loading && <Spinner />}
              {(formDataState.loading || !formDataState.data) && <Spinner noTransparent={true} />}
              {currentKey ? (
                currentKey.is_correct ? (
                  <h1 className="green flex">
                    <CheckCircle className="status-icon" /> Klucz API (BDO) zapisany
                  </h1>
                ) : (
                  <h1 className="red flex"><BlockIcon className="status-icon" />Klucz API (BDO) jest niepoprawny</h1>
                )

              ) : (
                <h1 className="red flex">
                  <BlockIcon className="status-icon" /> Brak klucza API (BDO)
                </h1>
              )}
              <Row gutter={30}>
                <Col md={15}>
                  {createBDOKeyState.error && (
                    <div className="error-message flex-left">
                      <div className="icon red">
                        <BlockIcon fontSize="large" />
                      </div>
                      <div>
                        <div className="lg-t bold red">Klucz niepoprawny</div>
                        <p className="mb-0">
                          Ponownie skopiuj i wklej klucz. Nie przepisuj ręcznie
                        </p>
                      </div>
                    </div>
                  )}
                  <p className="md-t">
                    Klucz API służy wyłącznie firmie Integrator do przekazywania danych do systemu BDO. Nie przekazuj tego klucza osobom postronnym, ani innym podmiotom. Jeżeli inna firma np. Organizacja Odzysku, z którą masz umowę również potrzebuje klucza API wygeneruj dla niej nowy klucz.
                  </p>

                  <TextField
                    name="client_id"
                    required={true}
                    label="Client ID"
                  />

                  <TextField
                    className="mb-20"
                    name="client_secret"
                    required={true}
                    label="Client Secret"
                    paste={true}
                  />

                  <SelectField
                    name="key_type"
                    disabled={true}
                    label="Typ klucza"
                    options={renderJsonOptions(
                      formDataState.data?.key_types || []
                    )}
                  />
                  <p className="sm-l-t">W BDO możesz generować wiele kluczy dla różnych firm. Typ klucza to pomocnicza nazwa dla Ciebie, abyś zapamiętał, której firmie, jaki klucz udostępniłeś. </p>

                  <Button
                    onClick={formikProps.submitForm}
                    color="primary"
                    disabled={!formikProps.isValid && !currentKey}
                    title="Zapisz i kontynuuj"
                    width={250}
                  />
                </Col>
                <Col md={9}>
                  <h2>Jak wygenerować klucz API?</h2>
                  <ol className="request-order">
                    <li>
                      <div>
                        <label>
                          Otwórz serwis BDO{" "}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href="https://rejestr-bdo.mos.gov.pl"
                          >
                            https://rejestr-bdo.mos.gov.pl
                          </a>
                        </label>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label>Zaloguj się używając profilu zaufanego.</label>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label>
                          Wybierz podmiot (swoją firmę) dla którego chcesz
                          wygenerować klucz.
                        </label>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label>Wygeneruj nowy klucz.</label>
                      </div>
                    </li>
                    <li>
                      <div>
                        <label>
                          Skopiuj wygenerowany klucz i wklej w oknie po lewej
                          stronie.
                        </label>
                      </div>
                    </li>
                  </ol>
                  <p>
                    Zobacz dokładną instrukcję krok po kroku jak wygenerować
                    klucz.{" "}
                  </p>
                  <LinkButton
                    width={190}
                    link={PAGE.API_KEY_INSTRUTION}
                    newCard={true}
                    color="white"
                    title="Otwórz instrukcję"
                    Icon={OpenInNewIcon}
                  />
                </Col>
              </Row>
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default BDOAPIKey;
