import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import SimpleLayout from "components/layout/simple-layout";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import Button from 'components/buttons/button';
import "styles/components/bdo-report/bdo-report-popup.scss";
import { PAGE } from "paths";
import { useHistory} from 'react-router-dom';
import { disablePopup } from "utils/helpers";
import ReactGA from "react-ga";


const BDOReportPopup = (props: any) => {
  const history = useHistory();
  const redirectWithPopupDisable = () => {

    ReactGA.initialize("UA-212968889-1");
    // Send a custom event
        ReactGA.event({
          category: "Zamknięcia WS",
          action: "Zamknięcie WS na ekranie powitalnym",
        });

     
        
      disablePopup();
      history.push(PAGE.HOME);
  }
  return (
    <div className="bdo-report-popup">
      <div className="close-page"  >
          <CloseIcon onClick={redirectWithPopupDisable} className="close-button" id="ws_close"/>
      </div>
      <Row className="bdo-info">
        <Col md={10} className="block">
          <div className="md-t bold red flex-left mb-8">
            <WarningIcon className="mr-10" />
            Usługa wycofywana z oferty
          </div>
          <h2 className="primary mb-8">Sprawozdanie BDO</h2>
          <p>Generowanie sprawozdań do pliku PDF.</p>
          <ul className="md-t black t-500">
            <li>
              <CloseIcon />
              Plik do wydrukowania
            </li>
            <li>
              <CloseIcon />
              Ręczne przepisywanie z pliku do BDO
            </li>
            <li>
              <CloseIcon />
              Logowanie do BDO - skomplikowana obsługa modułu sprawozdawczego
            </li>
            <li>
              <CloseIcon />
              Konieczność pilnowania terminów i zmian w przepisach
            </li>
            <li>
              <CloseIcon />
              Brak powiadomień
            </li>
          </ul>
        </Col>
        <Col md={14} className="block new-service">
        <div className="md-t bold green flex-left mb-8">
            <InfoIcon className="mr-10" />
            Nowa forma usługi
          </div>
          <h2 className="primary mb-8">Elektroniczne sprawozdanie roczne BDO</h2>
          <p>W pełni zautomatyzowane przekazywanie sprawozdań BDO za pomocą Klucza API.</p>
          <ul className="md-t black t-500">
            <li>
              <CheckIcon />
              Rozliczenie sieci, rozliczanie produktów, rozliczenie kampanii edukacyjnej - wszystko w jednym miejscu
            </li>
            <li>
              <CheckIcon />
              Automatyczne przekazanie danych z systemu Integrator do systemu BDO. 
            </li>
            <li>
              <CheckIcon />
              Możliwość składania sprawozdania częściowego dla klientów, którzy mają część usług poza Integratorem. 
            </li>
            <li>
              <CheckIcon />
              Intuicyjne menu, szybki i sprawny proces od utworzenia do zatwierdzenia sprawozdania 
            </li>
            <li>
              <CheckIcon />
              System powiadomień o terminach związanych ze sprawozdaniem 
            </li>
         
          </ul>
          <div>
            <Button link={PAGE.STEP_1} title="Uruchom teraz" color="green" width={250} className="mb-20" />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SimpleLayout(BDOReportPopup);
