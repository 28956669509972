import React, { useState, useEffect } from "react";
import { Formik, Field, ErrorMessage } from "formik";
// import InputMask from "react-input-mask";
import Modal from "react-modal";
import * as Yup from "yup";
import {
  FIELD,
  aupTypeOpts,
  addressFields,
} from "config/form-config/address-config";
import { addressValidation } from "validators/address-schema";
import TextField from "components/form-fields/formik-text-field";
import SelectField from "components/form-fields/select-field";

import { address1Fields } from "config/form-config/waste-service-config";
import { IAddressState } from "types/address-types";
import { useAsyncAction } from "utils/async-action";
import { createAddress } from "actions/company";
import { zipCodeRegExp } from "validators/index";
import { checkPostalCode } from "actions/company";

import _ from "lodash";
import SpinnerPopup from "components/core/spinner";
import "styles/components/common/modal.scss";

// import style from "./waste-address.module.scss";
import { Row, Col } from "antd";
import Button from "components/buttons/form-button";
import AddressSection from "components/common/address-section";

type ITextProps = React.ComponentProps<typeof TextField>;

const customStyles = { 
  content: {
    width: "700px", 
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function WasteAddress(props: any) {
  const {
    modalIsOpen,
    setModalIsOpen,
    selectedWasteId,
    setFieldValue,
    editedWaste,
    values,
    errors,
    submitForm,
    isValid,
    setFieldTouched,
    setIsSubmitting,
    wasteCardMode,
    execFetchAddresses,
    setSelectedAddress,
    
  } = props;
  const addressState: IAddressState = props.addressState;

  const [streetDisabled, setStreetDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cityOpts, setCityOpts] = useState<Array<any>>([]);
  const [streetOpts, setStreetOpts] = useState<Array<any>>([]);
  const [postalOfficeOpts, setPostalOfficeOpts] = useState<Array<any>>([]);
  const [postalCodeData, execCheckPostalCode]: readonly [
    any,
    typeof checkPostalCode
  ] = useAsyncAction(checkPostalCode);

  /* functions */

  const preloadAddress = (event: { target: { value: string } }) => {
    const text = event.target.value;
    setFieldValue(FIELD.ZIP_CODE, event.target.value);
    setFieldTouched(FIELD.ZIP_CODE, true, false);
    debugger;
    if (zipCodeRegExp.test(text)) {
      setTimeout(async function () {
        setIsLoading(true);
        setStreetOpts([]);
        setCityOpts([]);
        const res = await execCheckPostalCode(text);
        if (res && res.data.length) {
          res.data.map((item: any, index: number) => {
            setStreetOpts((oldArray: Array<any>) => [
              ...oldArray,
              { value: item.street, label: item.street },
            ]);

            setCityOpts((oldArray: Array<any>) => [
              ...oldArray,
              { value: item.city, label: item.city },
            ]);

            setPostalOfficeOpts((oldArray: Array<any>) => [
              ...oldArray,
              { value: item.post_office, label: item.post_office },
            ]);
          });
          debugger;
          setCityOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));
          setPostalOfficeOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));
          setFieldValue("voivodeship", res.data[0].voivodeship);

          setFieldValue(`post_office`, res.data[0].post_office);

          setFieldValue(`city`, res.data[0].city);
          setFieldValue(`street`, res.data[0].street);
          
          // if (extended) {
          //   formMethods.setValue(`${namePrefix}poviat`, res.data[0].poviat);
          //   formMethods.setValue(`${namePrefix}commune`, res.data[0].commune);
          // }
        }
        // else{
        //   errorMessage('Brak danych adresowych dla podanego kodu')
        // }
        setIsLoading(false);
      }, 500);
    }
  };
  function closeModal() {
    setModalIsOpen(false);
  }

  /**  side effects  */
  useEffect(() => {
    if (addressState.data) {
      setSelectedAddress(addressState.data);
    }
  }, [addressState.data]);

  useEffect(() => {
    if (values.address.aup_type === "-") {
      setStreetDisabled(true);
      setFieldValue(FIELD.STREET, "");
    } else {
      setStreetDisabled(false);
    }
  }, [values.address.aup_type]);

  useEffect(() => {
    if (setIsSubmitting) {
      setIsSubmitting(addressState.loading);
    }
  }, [addressState.loading]);

  useEffect(() => {
    if (addressState.response) {
      setModalIsOpen(false);

      execFetchAddresses();
    }
  }, [addressState.response]);

  /** form fields */

  const formFields1 = [
    {
      name: FIELD.ZIP_CODE,
      type: "text",
      label: "Kod pocztowy",
      required: true,
      // onChange: preloadAddress,
      maxlength: 6,
    },
    {
      name: FIELD.VOIVODESHIP,
      type: "text",
      label: "Województwo",
      required: true,
    },
    {
      name: FIELD.CITY,
      type: "text",
      label: "Miejscowość",
      required: true,
    },
    {
      name: FIELD.POST_OFFICE,
      type: "text",
      label: "Poczta",
      required: true,
    },
  ];

  const formFields2 = [
    {
      name: FIELD.AUP_TYPE,
      type: "select",
      label: "Typ adresu",
      options: aupTypeOpts,
      required: true,
    },
    {
      name: FIELD.STREET,
      type: "text",
      label: "Ulica",
      required: !streetDisabled,
      disabled: streetDisabled,
    },
    {
      name: FIELD.BUILDING_NR,
      type: "text",
      label: "Numer domu",
      required: true,
    },
    { name: FIELD.LOCUM_NR, type: "text", label: "Numer lokalu" },
  ];

  const addressFieldsData = address1Fields({
    streetDisabled,
    streetOpts,
    cityOpts,
    postalOfficeOpts,
    preloadAddress,
    setFieldValue,
    values,
  });

  const zipCodeField = {
    name: FIELD.ZIP_CODE,
    type: "text",
    label: "Kod pocztowy",
    required: true,
    onChange: preloadAddress,
  } as ITextProps;

  console.log('form values: ',values)
  console.log('form street opts: ',streetOpts)

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      className="modal"
      contentLabel="Example Modal"
    >
      {(isLoading || addressState.loading)  && <SpinnerPopup />}

      <div>
        <header>
          <h2 className="primary">Nowy adres</h2>
        </header>
        <p>Wprowadź adres miejsca gdzie generowane będą odpady</p>
        {/* <div className="row"> */}
        {/* <div className="half-col"> */}

        <div>
         
          {/* <ErrorMessage name={zipCodeField.name} render={ErrorMsg} /> */}
        </div>
       
        <AddressSection {...props} parentName="address"  /> 

        {/* </div> */}
        {/* <div className="half-col">
            <table className="details" cellSpacing="1">
              <tbody>
                <TableFields
                  //   setFieldValue={setFieldValue}
                  fields={formFields2}
                />
              </tbody>
            </table>
          </div> */}
        {/* </div> */}
        <div className="buttons">
          <Button
            title="Zapisz"
            color="primary"
            onClick={submitForm}
            disabled={!isValid}
          />
        </div>
      </div>
    </Modal>
  );
}

const WasteAddressForm = (props: any) => {
  const [addressState, execCreateAddress]: readonly [
    IAddressState,
    any
  ] = useAsyncAction(createAddress);
  const [test, setTest] = useState(false);

  const onSubmit = (formValues: any) => {
    execCreateAddress(formValues);
  };
  const initialValues = { address: {} };
  const validationSchema = Yup.object().shape({
    address: Yup.object().shape(addressValidation),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      isInitialValid={false}
    >
      {(formik) => {
        const formikProps = {
          ...formik,
          addressState,
        };
        
        return <>
        <WasteAddress {...props} {...formikProps} />
          {/* <div className="buttons">
          <Button
            title="Zapisz"
            color="primary"
            onClick={formik.submitForm}
            disabled={!formik.isValid}
          />
        </div> */}
        </>
        // return 
        
      }}
    </Formik>
  );
};

export default WasteAddressForm;
