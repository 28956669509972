import React, { useState, useEffect } from "react";
import Table from "antd/lib/table";
import Modal from "react-modal";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import TableWrapper from "components/common/table-wrapper";
import {
  ISimpleDocument,
  IDocuments,
  
} from "types/document-types";
import Button from "components/buttons/button";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import {
  fetchDocuments,
  deleteDocumentGroup,
  addDocumentGroup,
} from "actions/accounting-documents";
import Spinner from "components/core/spinner";
import Menu from "components/admin/documents-group-menu";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { useForm } from "react-hook-form";
import { renderBreadcrumbs } from "utils/helpers";

// interface IFormValues {
//   invoice_number: string;
//   issue_date: string;
// }

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
  },
};

const Documents = (props: any) => {
  const [category, setCategory] = useState<string>("ALL");
  const [tableData, setTableData] = useState<any>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [documentsState, execFetchDocuments]: readonly [
    IActionState<IDocuments>,
    typeof fetchDocuments
  ] = useAsyncAction(fetchDocuments);

  const [deleteState, execDelete]: readonly [
    IActionState<any>,
    typeof deleteDocumentGroup
  ] = useAsyncAction(deleteDocumentGroup);

  const [addGroupState, execAddGroup]: readonly [
    IActionState<any>,
    typeof addDocumentGroup
  ] = useAsyncAction(addDocumentGroup);

  useEffect(() => {
    if (deleteState.data) {
      execFetchDocuments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteState.data]);

  useEffect(() => {
    if (addGroupState.data) {
      setModalIsOpen(false);
      execFetchDocuments();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addGroupState.data]);
  useEffect(() => {
    execFetchDocuments();
    setTableData(getTableData());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteGroup = async (id?: string) => {
    if (id) {
      await execDelete(id);
      execFetchDocuments();
    }
  };

  const AddGroupModal = () => {
    const formMethods = useForm({
      defaultValues: { name: "" },
    });
    const submitGroup = (values: any) => {
      execAddGroup({ document_group: values });
    };

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        style={customStyles}
        className="modal"
      >
        {addGroupState.loading && <Spinner />}

        <div>
          <header className="mb-10">
            <h2 className="primary">Nowa grupa</h2>
          </header>
          <TextField
            name="name"
            className="mb-20"
            formMethods={formMethods}
            label="Nazwa"
          />
          <div className="t-center">
            <Button
              inline={true}
              title="Dodaj"
              color="primary"
              width={150}
              onClick={formMethods.handleSubmit(submitGroup)}
            />
          </div>
        </div>
      </Modal>
    );
  };

  const filterDocuments = (category: string) => {
    setCategory(category);
  };

  const renderCategorySections = () => {
    if (documentsState.data && documentsState.data.list) {
      const groups = documentsState.data.list;
      if (category === "ALL") {
        const categorySections = groups
          .sort((first, second) => first.position - second.position)
          .map((group, index) => {
            const documents = group.documents;

            return (
              <div className="mb-20">
                <h2 className="primary h-lg mb-10">
                  {group.name}
                  <div className="f-right">
                    <Menu
                      execFetchGroup={execFetchDocuments}
                      deleteGroup={deleteGroup}
                      groupID={group?.id}
                    />
                  </div>
                </h2>
                <Table
                  size="middle"
                  locale={{ emptyText: "Brak dokumentów" }}
                  rowKey="id"
                  columns={tableData.columns}
                  bordered={false}
                  dataSource={documents}
                  pagination={false}
                  loading={tableData.loading}
                  className="custom-ant-table"
                />
              </div>
            );
          });
        return categorySections;
      } else {
        const group = groups.find((item) => {
          return item.id === category;
        });
        return (
          <div className="mb-20">
            <h2 className="primary h-lg mb-10">
              {group?.name}
              <div className="f-right">
                <Menu
                  execFetchGroup={execFetchDocuments}
                  deleteGroup={deleteGroup}
                  groupID={group?.id}
                />
              </div>
            </h2>
            <Table
              size="middle"
              locale={{ emptyText: "Brak dokumentów" }}
              rowKey="uuid"
              columns={tableData.columns}
              bordered={false}
              dataSource={group?.documents}
              pagination={false}
              loading={tableData.loading}
              className="custom-ant-table"
            />
          </div>
        );
      }
    }
  };

  const renderFilterButtons = () => {
    const groups = documentsState.data?.document_groups;
    if (groups) {
      const result = groups
        .sort((first, second) => first.position - second.position)
        .map((key) => {
          return (
            <Button
              color={key.key === category ? "primary" : "white"}
              title={key.name}
              inline={true}
              onClick={() => filterDocuments(key.key)}
              className="mr-10"
            />
          );
        });
      result.unshift(
        <Button
          color={category === "ALL" ? "primary" : "white"}
          title="Wszystkie"
          inline={true}
          onClick={() => filterDocuments("ALL")}
          className="mr-10"
        />
      );
      return result;
    }
  };

  const renderColumns = () => {
    return [
      {
        title: "Dokument",
        align: "left",
        width: "45%",
        render: (field: any, row: ISimpleDocument) => (
          <div className="t-500 black">{row.name}</div>
        ),
      },
      {
        title: "Opis dokumentu",
        align: "left",
        width: "30%",
        render: (field: any, row: ISimpleDocument) => (
          <div className="">{row.description}</div>
        ),
      },
      {
        align: "left",
        render: (field: any, row: ISimpleDocument) => {
          return row.files?.map((item, index) => {
            return (
              <div key={index} className="flex-left">
                <InsertDriveFileOutlinedIcon className="grey mr-5" />
                <span className="black mr-10 attachment-file-name">
                  {item.file_name}
                </span>{" "}
                <a
                  className="t-500 grow-1 t-right"
                  target="_blank"
                  href={`${item.url}`}
                  rel="noreferrer"
                >
                  Pobierz
                </a>
              </div>
            );
          });
        },
      },
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };

  return (
    <div className="page-content">
      <AddGroupModal />
      {(documentsState.loading ||
        deleteState.loading ||
        addGroupState.loading) && <Spinner size="large" />}
      <div className="breadcrumbs">
        {renderBreadcrumbs([
          "Administrator",
          'Warunki szczególne usługi "Sprawozdanie roczne BDO"',
        ])}
      </div>

      <header className="page-header flex">
        <h1 className="grow-1">Dokumenty</h1>
        <Button
          inline={true}
          title="Dodaj nowy typ (grupę)"
          color="primary"
          onClick={() => {
            setModalIsOpen(true);
          }}
        />
      </header>
      <div className="mb-20">
        <span>Wyświetl: </span>
        {renderFilterButtons()}
      </div>
      <TableWrapper>{renderCategorySections()}</TableWrapper>
    </div>
  );
};

export default withAuthSync(withLayout(Documents));
