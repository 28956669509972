import React from "react";

export const WSText = () => (
  <div className="ws-text">
    <h2 className="mb-30 black t-center">
      WARUNKI SZCZEGÓLNE USŁUGI „SPRAWOZDANIE ROCZNE BDO” (dalej WS){" "}
    </h2>
    <div className="t-center">§1</div>
    <p>
      W sprawach nieuregulowanych w WS zastosowanie mają Regulamin Serwisu Internetowego, Regulamin Systemu Integrator, Cennik
      i definicje tam zawarte. WS określają zasady uruchomienia i świadczenia dotyczące dodatkowej usługi
      „SPRAWOZDANIE ROCZNE BDO” (dalej Usługa).
    </p>
    <div className="t-center">§2</div>
    <p>
      1) Usługa ta jako usługa dodatkowa jest dostępna dla klientów, którzy w ramach usług dostępnych w Systemie Integrator dodają
      w Systemie Integrator pojazdy z jednoczesnym zapisem niezbędnych danych wykorzystywanych w Rejestrze BDO – baza zapisanych
      danych jest prowadzona w ramach w/w usług dostępnych w Systemie Integrator. Klient, który zaakceptował WS (dalej Klient)
      zobowiązuje się do bieżącego dodawania w Systemie Integrator wszystkich wprowadzanych pojazdów.
    </p>
    <p>
      2) Akceptacja WS przez wprowadzającego pojazd stanowi:
      <br />
      - zawarcie pomiędzy Klientem a Integratorem – stron WS, w formie dokumentowej porozumienia o treści WS, obejmującego
      w szczególności ustalenia warunków Usługi i postanowienia dotyczące zawierania umowy na Usługę
      <br />- wyrażenie zgody na przekazywanie informacji pochodzących z Systemu Integrator o Usłudze w formie sms oraz email –
      informacje są przekazywane w trakcie obowiązywania WS, jako zawartego porozumienia zgodnie z niniejszym pkt 2/ §
      2WS.
    </p>
    <p>
      3) W skład Usługi wchodzi:- umożliwienie złożenia w ramach sprawozdania (w wersji ostatecznej, jeżeli klient zapisał wszystkie dane
      w Systemie Integrator, ale też w wersji roboczej, częściowej jeżeli klient chce dołączyć dane dodatkowe poza tymi zapisanymi
      w Systemie Integrator) o produktach, opakowaniach i gospodarowaniu odpadami z nich powstającymi, w zakresie informacji
      o wprowadzonych pojazdach oraz sieci zbierania pojazdów i informacji o przeprowadzonych publicznych kampaniach edukacyjnych
      w Rejestrze BDO (z zastrzeżeniem, że informacja o przeprowadzonych kampaniach edukacyjnych jest możliwa do złożenia pod
      warunkiem zakupu przez Klienta ulotek tego dotyczących zgodnie z treścią Regulaminu dot. sprzedaży ulotek za pośrednictwem
      Systemu Integrator, zgodnie z Regulaminem ich sprzedaży) – złożenie sprawozdania jest realizowane jedynie w przypadku
      skutecznego i poprawnego udostępnienia Integratorowi kluczy API oraz skutecznego zawarcia umowy o Usługę (dalej: Umowa)
      zgodnie z dalszymi postanowieniami WS (instrukcja wygenerowania klucza API znajduje się w Systemie Integrator).
    </p>
    <p>
      4) Zaakceptowanie WS jest równoznaczne z akceptacją warunków świadczenia Usługi i zawierania postanowień Umowy z Integratorem
      przez Klienta zgodnie z treścią WS. Jednocześnie strony Umowy oświadczają, że w przypadku obowiązywania pomiędzy stronami
      umowy - Zamówienie dodatkowe do umowy o zapewnienie sieci zbierania pojazdów umowa ta z dniem zaakceptowania WS ulega
      rozwiązaniu za porozumieniem stron. W przypadku, w którym Klient otrzymał fakturę z tytułu umowy – Zamówienie dodatkowe do
      umowy o zapewnienie sieci zbierania pojazdów obejmującą wynagrodzenie Integratora w ramach roku rozliczeniowego, w którym
      naliczany jest roczny koszt usługi zgodnie z §3 WS, Klient jest zobowiązany do zapłaty faktury z tytułu umowy - Zamówienie
      dodatkowe do umowy
      o zapewnienie sieci zbierania pojazdów, a roczny koszt usługi zgodnie z §3 WS pomniejszany jest o wysokość należności wynikającej
      z faktury z tytułu umowy - Zamówienie dodatkowe do umowy o zapewnienie sieci zbierania pojazdów w ramach roku
      rozliczeniowego, w którym naliczany jest roczny koszt usługi zgodnie z §3 WS.
    </p>
    <p>
      5) Integrator zobowiązuje się do realizacji Usługi, w ramach skutecznie zawartej Umowy, o ile zostaną spełnione wszystkie warunki przez
      Klienta, umożliwiające jej świadczenie.
    </p>
    <div className="t-center">§3</div>
    <p>
      1) Na podstawie zaakceptowanych WS Klient może zawrzeć Umowę obejmującą usługę z §2 ust. 3 WS z zastosowaniem postanowień
      WS, z tym zastrzeżeniem, że warunkiem koniecznym dla zawarcia Umowy jest brak zaległości w zapłacie jakichkolwiek świadczeń
      obciążających Klienta na rzecz Integratora, Stacji Demontażu i Organizacji Odzysku (w tym Stacji Demontażu i Organizacji Odzysku jako
      stron umów, których zawarcie lub obsługa jest oferowana lub realizowana przez Integratora lub w ramach Systemu Integratora)
      z tytułu:
      <br />
      - jakichkolwiek umów, których stronami są Klient i którykolwiek z podmiotów wymienionych w niniejszym postanowieniu WS,
      w tym umów dotyczących sprzedaży ulotek
      <br />
      - świadczenia usług przez Integratora na rzecz Klienta, niezależnie od przedmiotu umowy lub usługi. <br />
      Niniejszy warunek jest zastrzeżony, gdyż Usługa jest dedykowana dla Klientów, którzy w ramach zawartych umów i zamówionych
      usług są obsługiwani przez System Integrator i stanowi usługę dodatkową do zawartych już przez Klientów umów, rzetelnie realizujących
      wobec podmiotów jak wyżej swoje zobowiązania.
    </p>
    <p>
      2) Roczny Koszt Usługi to 3 zł netto za każdy wprowadzony pojazd, którego wprowadzenie nastąpiło w roku objętym sprawozdaniem,
      data dodania pojazdu do Systemu Integrator nie może przekroczyć daty zakończenia wprowadzania danych sprawozdawczych celem
      zrealizowania Usługi – Usługa dostępna po opłaceniu Rocznego Kosztu Usługi, zgodnie z treścią § 4 WS. Data zakończenia
      wprowadzania danych sprawozdawczych do Systemu Integrator (dodania pojazdu do Systemu Integrator) celem zrealizowania Usługi
      to 31 grudnia roku objętego sprawozdaniem o ile Integrator nie ogłosi w Systemie Integrator, iż za wskazany w ogłoszeniu rok
      możliwe jest wprowadzanie danych w późniejszym terminie, szczegółowo wskazanym w ogłoszeniu. Umowa obejmuje sprawozdanie
      roczne za jeden rok. Z chwilą otrzymania przez Integratora płatności, Umowa jest uważana za zawartą. Jeżeli Klient chce zawrzeć
      Umowę na Usługę obejmującą kolejny rok, rozpoczyna procedurę jej zawarcia na nowo z tym, że jeżeli zawarte WS nie zostały wypowiedziane,
      a więc nadal obowiązują, ich ponowna akceptacja nie jest konieczna.
    </p>
    <div className="t-center">§4</div>
    <p>
      {" "}
      1) Po zaakceptowaniu WS, zgodnie z §2 WS do zawarcia Umowy zgodnie z §5 WS, konieczne jest wprowadzenie w Systemie
      numeru BDO i klucza API oraz akceptacja danych w Systemie Integrator.{" "}
    </p>
    <p>
      {" "}
      2) WS zawarte są na czas nieokreślony i obowiązują od dnia ich zawarcia. Okresem rozliczeniowym jest rok kalendarzowy.{" "}
    </p>
    <p>
      {" "}
      3) Rozwiązanie WS przez Klienta możliwe jest z 3 miesięcznym okresem wypowiedzenia ze skutkiem na koniec roku kalendarzowego.
      Rozwiązanie umowy przez Integratora możliwe jest z 3 miesięcznym okresem wypowiedzenia ze skutkiem na koniec miesiąca
      kalendarzowego. W przypadku rozwiązania WS, Klient, ma możliwość zawarcia Umowy zgodnie z §5 WS za okres, w którym WS
      obowiązywały, co oznacza, że zawarcie Umowy następuje na warunkach obowiązujących WS i zgodnie z ich treścią.
    </p>
    <div className="t-center"> §5</div>
    <p>
      {" "}
      1) Po aktywowaniu w Systemie Integrator możliwości zawarcia Umowy za wskazany w Systemie Integrator rok, Klient ma możliwość
      potwierdzenia w Systemie Integrator decyzji o chęci uruchomienia Usługi, a to zawarcia Umowy (warunkiem jest wcześniejsza
      akceptacja WS) obejmującej rok wskazany w Systemie Integrator. System Integrator weryfikuje spełnienie warunku, o którym mowa
      w §3 WS – dalszy etap prowadzący do zawarcia Umowy jest dostępny dla klientów nie posiadających zaległości w płatnościach,
      o których mowa jest w §3 WS. Po spełnieniu tego warunku Klient akceptuje przycisk zamawiam z obowiązkiem zapłaty.{" "}
    </p>
    <p>
      {" "}
      2) Umowa zostaje zawarta z chwilą opłacenia przez Klienta Rocznego Kosztu Usługi – dniem zapłaty jest dzień potwierdzenia
      zaksięgowania płatności na koncie Przelewów 24 lub na koncie Integratora. Po zawarciu Umowy Usługa zostanie uruchomiona na
      koncie Klienta w Systemie Integrator niezwłocznie od otrzymania przez Integratora płatności.{" "}
    </p>
    <p>
      {" "}
      3) W Systemie Integrator wyświetlone zostają Klientowi dane zgromadzone w Systemie Integrator konieczne do złożenia sprawozdania
      celem ich akceptacji przez Klienta. Wszelkie zastrzeżenia Klienta do danych, ma on obowiązek zgłosić Integratorowi zgodnie
      z obowiązującymi regulaminami. Po akceptacji danych przez Klienta potwierdza on decyzję o złożeniu sprawozdania.{" "}
    </p>
    <p> 4) Umowa zawarta jest na czas realizacji Usługi. </p>
    <p>
      {" "}
      5) Strony WS wyłączają możliwość wypowiedzenia Umowy, z tym, że zastrzeżenie to nie dotyczy ważnych powodów. Rozwiązanie
      Umowy przez Klienta możliwe jest o ile Usługa nie została już wykonana, z 1-tygodniowym okresem wypowiedzenia ze skutkiem na
      koniec tygodnia kalendarzowego. Rozwiązanie umowy przez Integratora możliwe jest z 1-tygodniowym wypowiedzeniem - w takim
      wypadku Klient zobowiązany jest uiścić Integratorowi odpowiednią część umówionego wynagrodzenia.{" "}
    </p>
    <p>
      {" "}
      6) Integrator po wprowadzeniu klucza API do Systemu Integrator podejmuje czynności celem weryfikacji poprawności wprowadzonego
      klucza. Klient zobowiązuje się wprowadzić klucz API odpowiednio wcześniej, aby System Integrator pozytywnie go zweryfikował.
      Integrator nie ponosi żadnej odpowiedzialności za opóźnienie w świadczeniu Usługi, w tym złożenie sprawozdania w systemie BDO,
      jeżeli Klient naruszył to zobowiązanie. Za poprawność wprowadzonego klucza API odpowiedzialność ponosi Klient (odpowiedzialność
      Integratora jest wyłączona). Integrator nie ponosi żadnej odpowiedzialności za terminowość złożenia sprawozdania przez Klienta
      w ramach Usługi (termin uregulowany przepisami prawa powszechnie obowiązującego do złożenia sprawozdania rocznego za
      poprzedni rok) jeżeli:
      <br />
      - Klient zawarł Umowę w czasie, który z uwagi na zbliżający się termin złożenia sprawozdania BDO, konieczność pozytywnej
      weryfikacji klucza API lub inne uwarunkowania Systemu Integrator spowodował, że termin złożenia sprawozdania nie mógł zostać
      zachowany
      <br />- Klient zawarł Umowę po terminie składania sprawozdań.
    </p>
    <div className="t-center">§6</div>
    <p>
      {" "}
      System Integrator generuje roczne sprawozdanie na podstawie danych pochodzących z Systemu Integrator, a zebranych na podstawie
      wprowadzonych do Systemu Integrator pojazdów przez Klienta. Korzystanie Klienta z Systemu Integratora odbywa się na warunkach
      wskazanych w Systemie Integrator.{" "}
    </p>
    <div className="t-center">§7</div>
    <p>
      Integrator poinformuje Klienta o wszelkich zmianach WS poprzez umieszczenie WS wraz ze zmianami w Systemie, w sposób umożliwiający
      ich śledzenie i zapoznanie się z datą ich wejścia w życie (nie wcześniej niż z dniem ogłoszenia). Informacja o zmianach zostanie
      przekazana Klientowi oraz zostanie podana do wiadomości w Systemie prowadzonym przez Integratora przed datą ich wejścia w życie.
      Klient w związku z otrzymaną informacją o zmianach może wypowiedzieć WS z 30-dniowym okresem wypowiedzenia. W takim wypadku
      Klient obowiązany jest uiścić Integratorowi odpowiednią część umówionego wynagrodzenia ale §4 ust. 3) ostanie zdanie WS nie ma
      zastosowania, gdy WS nie obowiązywały w trakcie pełnego roku kalendarzowego.
    </p>
  </div>
);
