import React, { useEffect, useState, ComponentProps } from "react";
import TextField from "components/form-fields/formik-text-field";
import SelectField from "components/form-fields/select-field";
import Button from "components/buttons/form-button";
import Btn from "components/buttons/button";
import { Formik } from "formik";
import { formatDateTime } from "utils/date";
import { useAsyncAction } from "utils/async-action";
import {
  
  deleteTransferCard,
  deleteWasteCard,
} from "actions/waste";
import { ICardSearchForm, ICardItem, IHistory } from "types/waste-types";
import Spinner from "components/core/spinner";
import { ConfirmModal as ConfirmAlert } from "components/common/confirm-modal";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import styles from "./waste-tabs.module.scss";
import { RootState } from "redux/reducers";
import { useSelector } from "react-redux";
import { IActionState } from "types/async-types";
import { ColumnProps } from "antd/lib/table";
import AntdTableWrapper from "components/common/antd-table-wrapper";

const operations = {
  export: "Przekazanie",
  import: "Przyjęcie",
  import_export: "Przekazanie",
};

type TextProps = ComponentProps<typeof TextField>;
type SelectProps = ComponentProps<typeof SelectField>;

function WasteList(props: any) {
  //** hooks ,props & variables */
  const {
    values,
    submitForm,
    cardsHistory,
    execFetchCardsHistory,
    setFieldValue,
    resetForm,
  }: {
    cardsHistory: IActionState<any>;
    values: any;
    submitForm: any;
    execFetchCardsHistory: any;
    setFieldValue: any;
    resetForm: any;
  } = props;

  const wasteState = useSelector((state: RootState) => state.waste);
  const [pageNo, setPageNo] = useState(1);
  const [transferCardState, execDeleteTransferCard]: readonly [
    any,
    (card: string) => any
  ] = useAsyncAction(deleteTransferCard);
  const [wasteCardState, execDeleteWasteCard]: readonly [
    any,
    typeof deleteWasteCard
  ] = useAsyncAction(deleteWasteCard);

  const FIELD = {
    OPERATION_TYPE: "operation_type",
    CARD_ID: "card_number",
  };

  const refreshResult = (e: any, fieldName: string) => {
    if (e.key === "Enter") {
      submitForm();
    }
  };

  const onOperationChange = (e: any, fieldName: string) => {
    setFieldValue(fieldName, e);
    submitForm();
  };
  const operation_type = {
    name: FIELD.OPERATION_TYPE,
    type: "select",
    label: "Pokaż operacje",
    disableDefault: true,
    onChange: (e: any) => onOperationChange(e, FIELD.OPERATION_TYPE),
    // onKeyPress: (e: any) => refreshResult(e, FIELD.OPERATION_TYPE),
    options: [
      { value: "all", label: "Wszystkie" },
      { value: "export", label: "Przekazanie" },
      { value: "import", label: "Przyjęcie" },
    ],
  } as SelectProps;
  //** Form fields */
  const formFields = {
    card_number: {
      name: FIELD.CARD_ID,

      onKeyPress: (e: any) => refreshResult(e, FIELD.OPERATION_TYPE),
      type: "text",
      label: "Wyszukaj wpis",
      placeholder: "Nr karty / identyfikator przekazania",
    } as TextProps,
  };

  const renderColumns = (): ColumnProps<ICardItem>[] => {
    return [
      {
        title: "Data wpisu",
        align: "left",

        render: (field: any, row: ICardItem) => (
          <div className="no-wrap">{row.created_at}</div>
        ),
      },
      {
        title: "Nr karty / identyfikator przekazania",
        align: "left",

        render: (field: any, row: ICardItem) => {
          let appendix = "";
          if (row.operation_type === "export" ) {
            appendix = "POF: ";
          } else if (row.operation_type === "import_export" || row.operation_type === "import" ) {
            appendix = "KPO: ";
          }

          return (
            <div className="no-wrap">
              {row.card_number !== "" && `${appendix}${row.card_number || "brak"}`}
            </div>
          );
        },
      },
      {
        title: "Data wytworzenia",
        align: "left",

        render: (field: any, row: ICardItem) => (
          <div className="no-wrap">{formatDateTime(row.manufacture_date)}</div>
        ),
      },
      {
        title: "Data przekazania",
        align: "left",

        render: (field: any, row: ICardItem) => (
          <div className="no-wrap">{formatDateTime(row.transfer_date)}</div>
        ),
      },
      {
        title: "Miejsce wytworzenia",
        align: "left",

        render: (field: any, row: ICardItem) => (
          <div className="no-wrap">{row.address}</div>
        ),
      },
      {
        title: "Rodzaj operacji",
        align: "left",

        render: (field: any, row: ICardItem) => (
          <div className="no-wrap">
            {" "}
            <span
              className={`${
                row.operation_type === "import_export" ||
                row.operation_type === "export"
                  ? "green"
                  : ""
              }`}
            >
              {" "}
              {row.operation_type && operations[row.operation_type]}
            </span>
          </div>
        ),
      },
      {
        align: "left",

        render: (field: any, row: ICardItem) => (
          <div className="no-wrap">
            {" "}
            <div className="t-right">
              {!row.read_only && (
                <>
                  {/* <Link to={`${PAGE.WASTE_CARD_EDIT}/${item.id}`}> */}
                  {wasteState.toggleWasteEdit !== undefined && (
                    <Btn
                      className="mr-5"
                      inline={true}
                      color="white"
                      onClick={
                        wasteState.toggleWasteEdit
                          ? () => wasteState.toggleWasteEdit(row.id)
                          : undefined
                      }
                      title="edytuj"
                    />
                  )}{" "}
                  {/* </Link> */}
                  <Btn
                    inline={true}
                    color="white"
                    onClick={() => deleteItem(row.operation_type, row.id)}
                    title="usuń"
                  />
                </>
              )}
            </div>
          </div>
        ),
      },
    ];
  };

  //** side effects ********************************/
  useEffect(() => {
    if (wasteCardState.data) {
      execFetchCardsHistory();
    }
  }, [wasteCardState.data]);

  useEffect(() => {
    if (transferCardState.data) {
      execFetchCardsHistory();
    }
  }, [transferCardState.data]);

  useEffect(() => {
    execFetchCardsHistory();
  }, []);

  const fetchItems = (pageNo: number) => {
    setPageNo(pageNo);
    const formValues = { ...values, page: pageNo };
    execFetchCardsHistory(formValues);
  };

  /** functions  *********************************/

  const deleteItem = (operationType: string, cardNumber: string) => {
    debugger;
    switch (operationType) {
      case "import":
        ConfirmAlert({
          action: () => execDeleteWasteCard(cardNumber),
          header: "Usuwanie odpadu",
          text: "Czy chcesz usunąć odpad?",
          yesText: "Tak",
          noText: "Nie",
          btnColor: "primary",
        });
        break;
      case "import_export":
        ConfirmAlert({
          action: () => execDeleteWasteCard(cardNumber),
          header: "Usuwanie odpadu",
          text: "Czy chcesz usunąć odpad?",
          yesText: "Tak",
          noText: "Nie",
          btnColor: "primary",
        });
        break;
      case "export":
        ConfirmAlert({
          action: () => execDeleteTransferCard(cardNumber),
          header: "Usuwanie odpadu",
          text: "Czy chcesz usunąć odpad?",
          yesText: "Tak",
          noText: "Nie",
          btnColor: "primary",
        });
        break;
    }
  };

  if (
    cardsHistory.loading ||
    wasteCardState.loading ||
    transferCardState.loading ||
    !cardsHistory.response
  ) {
    return <Spinner size="default" />;
  }

  let history: IHistory | null = null;
  if (cardsHistory.data) {
    history = cardsHistory.data.history;
  }

  return (
    <>
      <div className="list-filters mb-20">
        <Row className="flex" align="middle" gutter={15}>
          <Col md={4}>
            {" "}
            <TextField {...formFields.card_number} />
          </Col>
          <Col md={4}>
            {" "}
            <SelectField {...operation_type} />
          </Col>
          <Col md={16} className="t-right">
            <Button
              title="Filtruj"
              color="primary"
              inline={true}
              width={150}
              onClick={submitForm}
              className="mr-5"
            />
            <Button
              title="Wyczyść"
              color="white"
              inline={true}
              width={150}
              onClick={() => {
                resetForm();
                execFetchCardsHistory(null);
              }}
            />
          </Col>
        </Row>
      </div>
      <AntdTableWrapper
        setPage={fetchItems}
        data={history}
        columns={renderColumns()}
        tableProps={{
          rowKey: "uuid",
          className: "custom-ant-table mb-10",
        }}
      />
    </>
  );
}

const FormWrapper = (props: {
  cardsHistory: any;
  execFetchCardsHistory: any;
}) => {
  const { execFetchCardsHistory, cardsHistory } = props;
  // {cardsHistory : any, execFetchCardsHistory : (val : ICardSearchForm) => void }

  const onSubmit = (values: ICardSearchForm) => {
    execFetchCardsHistory(values);
  };
  const initialValues: ICardSearchForm = {
    operation_type: null,
    card_number: null,
    page: 1,
  };
  const formConfig = {
    onSubmit: onSubmit,
    initialValues: initialValues,
  };
  const passedProps = {
    ...props,
    cardsHistory,
    execFetchCardsHistory,
  };
  return (
    <Formik {...formConfig}>
      {(formikProps) => {
        return (
          <>
            <WasteList {...passedProps} {...formikProps} />
          </>
        );
      }}
    </Formik>
  );
};

// function SideEffects() {
//   const { values } = useFormikContext<ICardSearchForm>();
//   useEffect(() => {}, []);
//   return null;
// }
// function WasteSummary() {
//   return (
//     <div>
//       <WasteList />
//     </div>
//   );
// }

export default FormWrapper;
