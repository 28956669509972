import {yupRequired, minLength, zipCode} from 'validators'
import * as Yup from "yup";

export const addressValidation = {
  postal_code: Yup.string().concat(yupRequired),
    aup_type: Yup.string().concat(yupRequired),
    street: Yup.string().when("aup_type", {
      is: "-",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().concat(yupRequired),
    }),
    post_office : Yup.string().concat(yupRequired),
    city: Yup.string().concat(yupRequired).nullable(),
    voivodeship: Yup.string().concat(yupRequired),
    building_number: Yup.string().concat(yupRequired),
};