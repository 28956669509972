import React, { useEffect, useState } from "react";
import FormStatusScreen from "components/common/form-status-screen-simple";
import Button from "components/buttons/button";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import { PAGE } from "paths";
import { useHistory } from "react-router-dom";
import { useTimeout } from "utils/hooks/useTimeout";
import withSimpleLayout from "components/layout/simple-layout";
import withFormLayout from "components/layout/form-layout";
import { checkBDOReportStatus, retryBDOReport } from "actions/BDO-reports";
import { useGraphForm } from "./graph-form-utils";


const PaymentStatus = (props: any) => {
  const history = useHistory();

  const [submitStatus, setSubmitStatus] = useState<"PEN" | "FIN" | "ERR">(
    "PEN"
  );
  const urlParams = new URLSearchParams(window.location.search);
  const Id = urlParams.get("id");

  const { execCheckGraph, goToNext, graphState,  } = useGraphForm<any>(Id);

  const { clear, reset } = useTimeout(
    () => {
      if (Id) {
        execCheckPaymentStatus(Id);
      }
    },
    5000,
    true
  );

  const [paymentStatusState, execCheckPaymentStatus]: readonly [
    IActionState<{
      bdo_response: string;
      error_code: string;
      error_message: string;
      send_step: string;
      send_step_string: string;
      status: string;
    }>,
    typeof checkBDOReportStatus
  ] = useAsyncAction(checkBDOReportStatus);

  const [retryPaymentState, execRetry]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof retryBDOReport
  ] = useAsyncAction(retryBDOReport);

  useEffect(() => {
    if (paymentStatusState.data?.status === "send") {
      setTimeout(() => {
        history.push(PAGE.BDO_REPORT);
      }, 2000);
      setSubmitStatus("FIN");
    }
  }, [paymentStatusState.data]);

  useEffect(() => {
    if (paymentStatusState.response?.status === 422) {
      setSubmitStatus("ERR");
    }
  }, [paymentStatusState.response]);

  useEffect(() => {
    if (submitStatus === "PEN") {
      if (Id) {
        reset();
      }
    } else {
      clear();
    }

    return function cleanUp() {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitStatus, Id]);

  //    ------- ENDREGION paymentStatus  -------------

  // ---------- REGION RetryPayment ------------------
  const retryPayment = async () => {
    if (Id) {
      const graphRes = await execCheckGraph(Id);

      if (graphRes?.status === 200) {
        goToNext({
          graph_data: graphRes.data?.graph_data,
        });
      }
    }
  };

  useEffect(() => {
    if (retryPaymentState.data) {
      setSubmitStatus("PEN");
    }
  }, [retryPaymentState.data]);

  // ---------- ENDREGION RetryPayment ------------------

  

  switch (submitStatus) {
    case "PEN":
      return (
        <FormStatusScreen
          title="Wysyłanie sprawozdania"
          description="Trwa wysyłka sprawozdania BDO"
          type="loading"
   
        />
      );
    case "FIN":
      return (
        <FormStatusScreen
          title="Sprawozdanie zostało wysłane."
          type="success"
          aditionalContent={
            <div className="t-center">
              <Button
                link={PAGE.BDO_REPORT}
                width={300}
                className="i-b"
                title="Powrót do sprawozdań"
                color="white"
              />{" "}
            </div>
          }
        />
      );
    case "ERR":
      return (
        <FormStatusScreen
          title={
            paymentStatusState.data?.error_message ||
            "Wysyłka sprawozdania nie powiodła się"
          }
          type="error"
          loading={graphState.loading}
          aditionalContent={
            <div className="t-center">
              <Button
                onClick={() => retryPayment()}
                width={200}
                className="i-b"
                title="Ponów"
                color="primary"
              />{" "}
              <Button
                link={PAGE.BDO_REPORT}
                width={200}
                className="i-b"
                title="Powrót do sprawozdań"
                color="white"
              />{" "}
            </div>
          }
        />
      );
  }
};

export default withSimpleLayout(withFormLayout(PaymentStatus));
