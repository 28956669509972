import React from "react";
import Header from "components/layout/header";
import { useState, useEffect } from "react";
import Layout from "antd/lib/layout";
import Spinner from "components/core/spinner";
//@ts-ignore
import "styles/layout/layout.scss";

const withFormLayout = (Page: any) => {
  const Layout = (props: any) => {
    return (
      <div className="bdo-report-form">
        <div className="form-content min-h-100vh flex-column sider-page">
          <Page {...props} />
        </div>
      </div>
    );
  };

  if (Page.getInitialProps) {
    Layout.getInitialProps = Page.getInitialProps;
  }

  return Layout;
};

export default withFormLayout;
