import React, { useState, useEffect } from "react";

import SelectField from "components/form-fields/select-field";

import TextField from "components/form-fields/formik-text-field";
import { IAddressesState } from "types/address-types";
import { FIELD_NAMES } from "config/form-config/waste-card-config";
import { IWasteAddress } from "types/address-types";
// import WasteAddress from "./waste-address/waste-address";
// import AddressModal from "./waste-address/waste-address-modal";
import CheckFormField from "components/form-fields/check-form-field";
import SpinnerPopup from "components/core/spinner";
import AddressModal from 'components/waste/waste-create-form/waste-address/waste-address-modal'
import { ErrorMessage } from "formik";
import Modal from 'components/common/modal';

import _ from "lodash";
import { Row, Col } from "antd";
import Button from "components/buttons/button";

type TextFieldProps = React.ComponentProps<typeof TextField>;

type SelectProps = React.ComponentProps<typeof SelectField>;

function Step1(props: any) {
  // *** props & hook variables *************************************** //
  const {
    setFieldValue,
    values,
    wasteCardMode,
    setCardNr,
    setCardDate,
    setSelectedAddress,
    validateForm,
    isLoading,
    execFetchAddresses,
    setFieldTouched,
    isCard
  } = props;
  const addressesState: IAddressesState = props.addressesState;
  const selectedAddress: IWasteAddress = props.selectedAddress;

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [noCard, setNoCard] = useState(false);
  const [addressOpts, setAddressOpts] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [isSubmitting, setIsSubmitting] = useState([]);

  const addressModalProps = {
    modalIsOpen: addressModalIsOpen,
    setModalIsOpen: setAddressModalIsOpen,
    setFieldValue,
    values,
    setIsSubmitting,
    setSelectedAddress,
    wasteCardMode,
    execFetchAddresses,
  };

  const wasteAddressProps = {
    setFieldValue,
    values,
    setIsSubmitting,
    setSelectedAddress,
    wasteCardMode,
    addressesState,
  };

  // *** side effects *************************************** //

  useEffect(() => {
    const noCard: Array<string> = values[FIELD_NAMES.NO_CARD];
    // setTimeout(() => validateForm());
    if (noCard.length > 0) {
      setFieldValue(FIELD_NAMES.CARD_NR, "");
      setNoCard(true);
    } else {
      setNoCard(false);
    }

    // setTimeout(() => validateForm());
  }, [values[FIELD_NAMES.NO_CARD]]);

  useEffect(() => {
    // setTimeout(() => validateForm());
    if (values[FIELD_NAMES.DATE]) {
      setTimeout(() => setFieldTouched(FIELD_NAMES.DATE, true));
    }

    // setTimeout(() => validateForm());
  }, [values[FIELD_NAMES.DATE]]);

  /** refresh props for card header texts */
  useEffect(() => {
    if (wasteCardMode) {
      setCardNr(values[FIELD_NAMES.CARD_NR]);
    }

    setCardDate(values[FIELD_NAMES.DATE]);
    let selectedAddress = _.find(addressesState.data, (item) => {
      return item.id === values.waste_address;
    });
    if (selectedAddress) {
      setSelectedAddress(selectedAddress);
    }
  }, [values]);

  //** set selected address_id in dropdown (selectedAddress is changed after adding new address) */
  useEffect(() => {
    if (selectedAddress) {
      setFieldValue("address_id", selectedAddress.id);
    }
  }, [selectedAddress]);

  /** set selectedAddress OBJECT prop on every address dropdown (id) change */
  useEffect(() => {
    const addressId = values[FIELD_NAMES.WASTE_ADDRESS];
    if (addressId) {
      const selectedAddress = _.find(addressesState.data, (item) => {
        return item.id === addressId;
      });
      setSelectedAddress(selectedAddress);
    }
  }, [values[FIELD_NAMES.WASTE_ADDRESS]]);

  useEffect(() => {
    validateForm();
  }, []);

  useEffect(() => {
    const addresses = addressesState.data;
    if (addresses) {
      if (!addresses.length) {
        setAddressOpts([]);
      } else {
        const opts = addresses.map((item: IWasteAddress, index: number) => {
          return {
            label: `${item.city} ${
              item.aup_type !== "-" ? item.aup_type : ""
            } ${item.street} ${item.building_number}${
              item.locum_number ? `/${item.locum_number}` : ""
            } ${item.postal_code} ${item.post_office}`,
            value: item.id,
          };
        });

        setAddressOpts(opts);
      }
    }
  }, [addressesState.data]);

  // *** form fields *************************************** //
  const formFields = {
    card_nr: {
      name: FIELD_NAMES.CARD_NR,
      type: "text",
      label: "Numer karty",
      required: !noCard,
      disabled: noCard,
    } as TextFieldProps,

    no_card_number: {
      id: `no_card_number`,
      name: FIELD_NAMES.NO_CARD,
      cbxLabel: "Nie posiadam numeru karty",
      value: "true",
    },

    manufacture_date: {
      name: FIELD_NAMES.DATE,
      type: "date",
      label: values.is_card
        ? "Data przekazania odpadu"
        : "Data wytworzenia odpadu",
      required: true,
    } as TextFieldProps,
    address_id: {
      name: FIELD_NAMES.WASTE_ADDRESS,
      type: "select",
      label: "Miejsce generowania odpadu",
      required: true,
      options: addressOpts,
    } as SelectProps,
  };

  return (
    <div id="step-1">
      {/* {(isSubmitting || isLoading) && <SpinnerPopup />} */}
      <Modal modalIsOpen={addressModalIsOpen} closeModal={() => setAddressModalIsOpen}>
        <AddressModal {...addressModalProps} />
      </Modal>
      
   
          {values.is_card && (
            <Row gutter={10} type="flex">
              <Col md={14}>
              <TextField {...formFields.card_nr} />
            
              </Col>
              <Col md={8}>
                <div className="mt-20">
                <CheckFormField {...formFields.no_card_number} />
                </div>
              </Col>
            </Row>
          )}
          <Row className="flex" gutter={10}>
            <Col md={14}>
            <SelectField {...formFields.address_id} />
            
            </Col>
            <Col md={7}>
                <Button
                    color="white"
                    title="Nowy adres"
                    onClick={() => setAddressModalIsOpen(true)} />
                  
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={8}>
            <TextField {...formFields.manufacture_date} />
            </Col>
          </Row>
          
         
     
      <div className="footnotes">
        {/* <div>
          <b class="required">*</b> - pola oznaczone gwiazdką są obowiązkowe
        </div> */}
      </div>
      {/* <div className="buttons">
        <a className="button button-link" onClick={() => setStep(2)}>
          Następny krok
        </a>
      </div> */}
    </div>
  );
}

export default Step1;
