import { FETCH_DOCUMENTS, SEARCH_DOCUMENTS, LIKE_DOCUMENT, DISLIKE_DOCUMENT, GET_DOCUMENT_FILE} from 'types/redux-types'

export default function(state = {}, action: any){

  switch(action.type){
      case FETCH_DOCUMENTS:
      
        return {...state, document_templates:   action.payload.documents};
      case SEARCH_DOCUMENTS:

        return {...state, search_result:   action.payload.documents};
      // case LIKE_DOCUMENT:
      //   return {...state, document_templates:   action.payload.documents};
      // case DISLIKE_DOCUMENT:
      //   return {...state, document_templates:   action.payload.documents};
      case GET_DOCUMENT_FILE:
      
        return {...state, file_data:   action.payload.file_data};
      default:
        return state;
  }
}
