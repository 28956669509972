import React, { useState } from "react";
import LoginOptions from "components/login/login-options";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import SimpleHeader from "components/layout/simple-header";
import PhoneForm from "components/login/login-form";
import StandardForm from "components/login/standard-form";

// import "antd/dist/antd.min.css";
import "styles/pages/login.scss";
import { message } from "utils/toaster";

interface ILoginProps {}

function Login(props: ILoginProps) {
  // props & hooks
  const [mobileClosed, setMobileClosed] = useState(false);
  const [loginOption, setLoginOption] = useState<"standard" | "phone">(
    "standard"
  );

  //functions
  const handleClose = () => {
    setMobileClosed(true);
  };

//  const test = () => {
//     message({ title: "to jest test", type: "positive" });
//   }

  return (
    <div data-test="login-page" className="login-page">
        
      <main>
        <SimpleHeader />
        <Row className="ant-row-flex login" itemType="flex">
          {/* <Col className="store-info" md={24} lg={12} xl={12}>
            <section className="b-right">
              <div className="welcome-h mb-20">
              Wypełniaj wszystkie obowiązki związane z importem pojazdów łatwo i szybko.
              </div>
              <h2 className="mb-20">
              Integrator to narzędzie dla importerów pojazdów, które pomaga załatwiać sprawy związane z recyklingiem i opłatami produktowymi oraz w generowaniu dokumentów dla urzędów.
              </h2>
             
            </section>
          </Col> */}
          <Col xs={24} lg={24}>
            <section>
              <StandardForm />
            </section>
            
          </Col>
        </Row>
      </main>
    </div>
  );
}

Login.getInitialProps = async ({ store }: any) => {
  // await  store.dispatch(change('loginForm', 'password', '112121211'));
};

export default Login;
