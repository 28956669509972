import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import RadioGroup from "components/form-fields/hook-form-fields/hook-radio-group";
import Checkbox from "components/form-fields/hook-form-fields/hook-check-field";
import ImportedVehiclesSection from "./imported-vehicles";
import Button from "components/buttons/button";
import FormButton from "components/buttons/form-button";
import { companyFields } from "config/company-fields";
import AddressSection from "components/common/hook-form-address-section";
import { userFields } from "config/user-fields";
import { personFields } from "config/person-fields";
import { IActionState } from "types/async-types";
import {useHistory} from 'react-router-dom'
import {
  IElectronicsThirdStepFormData as IFormData,
  IElectronicsThirdStepFormValues as IFormValues,
  IElectronicDeclaration,
  IElectronicsThirdStepFormValues,
} from "types/products/electronics-types";
import {
  fetchElectronicsThirdStepFormData,
  fetchEelectronicsDeclaration,
  saveElectronicsThirdStep,
} from "actions/product-groups";
import { useAsyncAction } from "utils/async-action";
import { PAGE } from "paths";
import Spinner from "components/core/spinner";

const ElectronicsStep2 = (props: any) => {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const formMethods = useForm<IFormValues>({
    mode: "onChange",
    // defaultValues: {},
  });

  const [formDataState, execFetchFormData]: readonly [
    IActionState<IFormData>,
    typeof fetchElectronicsThirdStepFormData
  ] = useAsyncAction(fetchElectronicsThirdStepFormData);

  const [showDataState, execFetchShowData]: readonly [
    IActionState<IElectronicDeclaration>,
    typeof fetchEelectronicsDeclaration
  ] = useAsyncAction(fetchEelectronicsDeclaration);

  const [saveStepData, execSaveStep]: readonly [
    IActionState<{ id: string }>,
    typeof saveElectronicsThirdStep
  ] = useAsyncAction(saveElectronicsThirdStep);

  useEffect(() => {
    const func = async () => {
      if (id) {
        await execFetchFormData(id);
        await execFetchShowData(id);
      }
    };
    func();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const renderRepresentantOpts = () => {
    return formDataState.data?.representants.map((item, index) => {
      return {
        label: item.name,
        value: item.id.toString(),
      };
    });
  };

  useEffect(() => {
    const reset = formMethods.reset;
    if (formDataState.data) {
      reset({
        ...formDataState.data,
      });
    }
  }, [formDataState.data]);

  useEffect(() => {
    const reset = formMethods.reset;
    if (showDataState.data && showDataState.data.step === 3) {
      reset({
        ...showDataState.data,
      });
    }
  }, [showDataState.data]);

  const onSubmit = async (values: IElectronicsThirdStepFormValues) => {
    if (id) {
     const res = await execSaveStep(id, values);
     if(res.status === 200 || res.status === 201 ){
      history.push(`${PAGE.AGREEMENT_SUCCESS}?id=${res.data.id}`);
     }
    }
  };

  const onRepresentantChange = (id: string) => {
    
    const person = formDataState.data?.representants.find((item) => {
      return item.id === parseInt(id);
    })

    const setValue = formMethods.setValue;
    if(person){
      setValue('contact_person_name', person?.name)
      setValue('contact_person_surname', person?.surname)
      // setValue('contact_person_occupation', person?.occupation)
      // setValue('contact_person_pesel', person?.pesel)
    }
    

  }

  return (
    <>
          {(formDataState.loading || saveStepData.loading || showDataState.loading) && <Spinner />} 
      <div className="block">
        <h1 className="mb-30">Dane do umowy</h1>
        <p>
          W tej sekcji wypełnij podstawowe dane twojej działalności
          gospodarczej.
        </p>
        <Row gutter={10} className="mb-20">
          <Col md={7}>
            <TextField
              type="date"
              name="date"
              label="Data zawarcia umowy"
              formMethods={formMethods}
              disabled={true}
            />
          </Col>
          <Col md={7}>
            <TextField
              type="number"
              name="netto"
              label="Wartość netto umowy"
              formMethods={formMethods}
              disabled={true}
            />
          </Col>
          <Col md={7}>
            <TextField
              type="number"
              name="mass"
              label="Waga wprowadzonych na rynek odpadów"
              formMethods={formMethods}
              disabled={true}
            />
          </Col>
          <Col md={7}>
            <TextField
              type="number"
              name="planed_vehicle_count"
              label="Planowana liczba pojazdów na [XXXX] rok"
              formMethods={formMethods}
              disabled={true}
            />
          </Col>
        </Row>
        <RadioGroup
          name="is_agreement"
          orientation="horizontal"
          required={true}
          rules={{
            required: "Pole jest wymagane",
          }}
          options={[
            { value: true, label: "Tak" },
            { value: false, label: "Nie" },
          ]}
          label="Czy w [XXXX] roku firma miała podpisaną umowę z Organizacją Odzysku?"
          formMethods={formMethods}
        />
      </div>
      <div className="block">
        <h1 className="mb-30">Osoba do kontaktu</h1>
        <p>W tej sekcji wypełnij podstawowe dane osobowe.</p>
        <Row>
          <Col>
            <SelectField
              formMethods={formMethods}
              onChange={onRepresentantChange}
              name="representant"
              options={renderRepresentantOpts() || []}
              label="Wybierz z listy lub wprowadź poniżej"
            />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col md={6}>
            {" "}
            <TextField
              name="contact_person_name"
              label="Imię"
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            {" "}
            <TextField
              name="contact_person_surname"
              label="Nazwisko"
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            {" "}
            <TextField
              name="contact_person_phone_number"
              label="Telefon kontaktowy"
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            <TextField
            {...personFields.email}
              name="contact_person_email"
              label="Adres email"
              formMethods={formMethods}
            />
          </Col>
        </Row>
      </div>
      <div className="block">
        <h1 className="mb-30">Liczba sprowadzonych pojazdów</h1>
        <p>
          Podaj liczbę pojazdów sprowadzonych od początku bieżącego roku do dnia
          zawarcia umowy
        </p>
        <ImportedVehiclesSection
          vehicle_types={formDataState.data?.vehicle_types}
          formMethods={formMethods}
        />
      </div>
      <div className="block">
        <h1 className="mb-30">Zgody</h1>
        <div className={`check-form-fields vertical`}>
          <Checkbox
            name="is_rodo_accepted"
            className="mb-10 ml-0"
            required={true}
            value={true}
            isFlag={true}
            cbxLabel="Zgoda na przechowywanie i przetwarzanie danych dla CTS24"
            formMethods={formMethods}
            rules={{
              required: "Pole jest wymagane",
            }}
          />
          <Checkbox
            name="is_marketing_accepted"
            className="mb-10"
            value={true}
            isFlag={true}
            formMethods={formMethods}
            cbxLabel="Zgoda na przetwarzanie danych w celach marketingowych"
          />
          <Checkbox
            name="is_service_terms_accepted"
            className="mb-10"
            required={true}
            value={true}
            isFlag={true}
            formMethods={formMethods}
            cbxLabel="Akceptuję Regulamin Serwisu Internetowego"
            rules={{
              required: "Pole jest wymagane",
            }}
          />
          <Checkbox
            name="is_integrator_terms_accepted"
            className="mb-10"
            required={true}
            value={true}
            isFlag={true}
            formMethods={formMethods}
            cbxLabel="Akceptuję Regulamin Systemu Integrator"
            rules={{
              required: "Pole jest wymagane",
            }}
          />
        </div>
      </div>
      <div className="buttons-panel">
        <div className="form-content">
          <FormButton
            width={220}
            color="primary"
            onClick={formMethods.handleSubmit(onSubmit)}
            title="Zapisz i kontynuuj"
          />
        </div>
      </div>
    </>
  );
};

export default ElectronicsStep2;
