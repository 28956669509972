import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";
import { requestHeaders } from "utils/auth";
import { myVehiclesFormValues } from "pages/my-vehicles";
import { IVehicle } from "types/vehicle-types";
import { IOSOP1FiltersFormValues } from "pages/admin/os-op1-list";
import { ActionResponse } from "types/async-types";


export const ADMIN_URL = `${API_BASE_URL}/admin`;
const DASHBOARD_URL = `${ADMIN_URL}/dashboard`;

// POST {{int_api_url}}/v1/admin/bdo_reports/payments/confirm





// {

// "bdo_report_id":"369f7b62-d4cf-4df8-8f5c-993022e00184",

// "seller_id":"005332"

// }

export const confirmBDOReportPayment = async (reportId : string, sellerId : string) => {
  const options: AxiosRequestConfig = { 
    url: `${ADMIN_URL}/bdo_reports/payments/confirm`,
    method: "POST",
    headers: requestHeaders().headers,
    data : {
      bdo_report_id : reportId,
      seller_id : sellerId
    }
  };

  let res: AxiosResponse<any> = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

export const rollbackBDOReportPayment = async (reportId : string, sellerId : string) => {
  const options: AxiosRequestConfig = { 
    url: `${ADMIN_URL}/bdo_reports/payments/rollback`,
    method: "POST",
    headers: requestHeaders().headers,
    data : {
      bdo_report_id : reportId,
      seller_id : sellerId
    }
  };

  const res = await axios(options);
  debugger
  const result : ActionResponse<any> = {
    ...res,
    successMessage : res.data.message
  }
  return result;
};

export const fetchAdminBDOReports = async (params? : any) => {
  const options: AxiosRequestConfig = { 
    url: `${ADMIN_URL}/bdo_reports`,
    method: "GET",
    headers: requestHeaders().headers,
    params
  };

  let res: AxiosResponse<any> = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

export const fetchAdminDashboardVehicleStats = async () => {
    const options: AxiosRequestConfig = {
      url: `${DASHBOARD_URL}/vehicle_stats`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const filterAdminVehicles = async (params?: myVehiclesFormValues | null) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/vehicles/`,
      params,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<IVehicle[]> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchAdminBDOKeys = async (params?: IOSOP1FiltersFormValues | null) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/bdo_keys`,
      params, 
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<IVehicle[]> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchAdminOSOP1Reports = async (params?: IOSOP1FiltersFormValues | null) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/os_op1_reports`,
      params, 
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchAdminOSOP1 = async (id : string) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/os_op1_reports/${id}`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const deleteAdminOSOP1Report = async (id : string) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/os_op1_reports/${id}`,
      method: "DELETE",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const deleteVehicle = async (id : string) => {
    const options: AxiosRequestConfig = { 
      url: `${ADMIN_URL}/vehicles/${id}`,
      method: "DELETE",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<IVehicle[]> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchAdminBDOReportPayments = async (params? : any) => {
    const options: AxiosRequestConfig = { 
      url: `${ADMIN_URL}/payments`,
      method: "GET",
      headers: requestHeaders().headers,
      params
    };
  
    let res: AxiosResponse<any> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchAdminBDOReportPayment = async (id : string) => {
    const options: AxiosRequestConfig = { 
      url: `${ADMIN_URL}/bdo_reports/payments/${id}`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  // export const confirmBDOReportPayment = async (id : string) => {
  //   const options: AxiosRequestConfig = { 
  //     url: `${ADMIN_URL}/bdo_reports/payments/${id}/confirm`,
  //     method: "POST",
  //     headers: requestHeaders().headers,
  //   };
  
  //   let res: AxiosResponse<any> = await axios(options);
  //   if (!res) {
  //     return undefined;
  //   }
  //   return res;
  // }; 

