import React from "react";
import { loginRedirect } from "utils/auth";
import { message, IMessageProps } from "utils/toaster";
import { PAGE } from "paths";

export function handleError(
  error: any,
  errorParams?: IMessageProps,
  ctx = null
) {
  
  if (!error || !error.response) {
    message({ title: `błąd: Nieznany błąd`, type: "error", ...errorParams });
  } else if (error.response.data.error_object) {
    
    const errorObj = error.response.data.error_object;

    const errorList = Object.keys(errorObj).map((key, index) => {
      return (
        <div className="mb-5">
          <span className="t-500">{errorObj[key].label} </span>: {errorObj[key].errors[0]}{" "}
        </div>
      );

    });

    message({
      title: `błąd formularza:`,
      type: "error",
      description : <div>{errorList} </div>,
      ...errorParams,
    });
  
  } else if (error.response.data.error_message) {
    message({
      title: `błąd: ${error.response.data.error_message}`,
      type: "error",
      ...errorParams,
    });
 
  } else {
    switch (error.response.status) {
      case 401:
        if (window.location.pathname === PAGE.LOGIN) {
 
          message({
            title: "Błąd: " + "nieprawidłowy login lub hasło",
            type: "error",
            ...errorParams,
          });
        }

        break;
      case 404:
        message({
          title: "Błąd: " + "Podany adres nie istnieje",
          type: "error",
          ...errorParams,
        });
        break;
      case 422:
        message({
          title: "Błąd 422: " + "Niepoprawne dane",
          type: "error",
          ...errorParams,
        });
        break;
      default:
        message({
          title: "Błąd: " + error.response.data.error,
          type: "error",
          ...errorParams,
        });
    }
  }
  if(error.response.status === 401 && window.location.pathname !== PAGE.LOGIN){
    loginRedirect();
  }
}


