import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import { PAGE } from "paths";
import WasteListTabs from "components/waste/waste-tabs/waste-tabs";
// import ReportModal from './report-modal';
import style from "components/waste/waste.module.scss";
import "styles/components/waste/waste.scss";
import { withAuthSync } from "utils/auth";
import withLayout from "components/layout/MyLayout";
import Button from "components/buttons/button";
import CloseIcon from "@material-ui/icons/Close";
import WasteCreateForm from "components/waste/waste-create-form";
import WasteCardEditForm from "components/waste/waste-card/waste-card-edit-form";
import { useDispatch } from "react-redux";
import { WasteActionTypes } from "types/redux-types";
import { fetchCardsHistory, fetchAddedCodes } from "actions/waste";
import { useAsyncAction } from "utils/async-action";
import { ICardSearchForm, addedCodesType } from "types/waste-types";
import WasteForwardForm from "components/waste/waste-forward-form";
import { IActionState } from "types/async-types";
import ReportModal from "components/waste/report-modal";
import { renderBreadcrumbs } from "utils/helpers";
import ReportListModal from "components/waste/report-list-modal";

function Waste() {
  const [createSiderCollapsed, setCreateSiderCollapsed] = useState(true);
  const [editSiderCollapsed, setEditSiderCollapsed] = useState(true);
  const [forwardSiderCollapsed, setForwardSiderCollapsed] = useState(true);
  const [reportVisible, setReportVisible] = useState<boolean>(false);
  const [reportListVisible, setReportListVisible] = useState<boolean>(false);
  const [reportYear, setReportYear] = useState<string>("");
  const dispatch = useDispatch();
  const [isCard, setIsCard] = useState<boolean | undefined>(undefined);
  const [editedCardId, setEditedCardId] = useState<string | undefined>(
    undefined
  );

  const history = useHistory();
  const [cardsHistory, execFetchCardsHistory]: readonly [
    any,
    typeof fetchCardsHistory
  ] = useAsyncAction(fetchCardsHistory);

  const [wasteCardsState, execfetchAddedCodes]: readonly [
    IActionState<addedCodesType>,
    any
  ] = useAsyncAction(fetchAddedCodes);

  useEffect(() => {
    // const urlParams = new URLSearchParams(window.location.search);
    // const isHistory = urlParams.get('history');
    // history.push({
    //   pathname: '/',
    //   search: `?${isHistory ? 'history=true' : ''}`,
    // });
    const action: WasteActionTypes = {
      type: "SET_TOGGLE_WASTE_EDIT",
      payload: {
        toggleWasteEdit: showEditSider,
      },
    };
    dispatch(action);
  }, []);

  const toggleSider = (isCard: boolean | undefined) => {
    setCreateSiderCollapsed(!createSiderCollapsed);

    setIsCard(isCard);
  };
  const showEditSider = (cardId: string) => {
    setEditSiderCollapsed(false);
    history.push({ pathname: "/waste", search: `id=${cardId}` });
  };
  const hideEditSider = () => {
    setEditSiderCollapsed(true);
    history.push({ pathname: "/waste", search: "" });
  };

  // const showForwardSider = (type : "export" | "company_export") => {
  //   setForwardSiderCollapsed(false);
  //   history.push({pathname : `${PAGE.WASTE}`,
  //   search : `waste_codes[]=${waste_code.normalized_code}&type=export` });

  // };

  return (
    <div className={` sider-page waste`}>
      <main className="page-content">
        <ReportListModal 
          modalIsOpen = { reportListVisible }
          closeModal={() => setReportListVisible(false)}
          selectReport = { (year) => {
            // setReportListVisible(false)
            setReportYear(year)
            setReportVisible(true)
          } }
          />

        <ReportModal
          modalIsOpen={reportVisible}
          closeModal={() => setReportVisible(false)}
          reportYear = { reportYear }
        />
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Administracja odpadów", "Magazyn odpadów"])}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Magazyn odpadów</h1> 
          <Button
            title="Dodaj odpad z kartą"
            color="white"
            className="mr-10"
            inline={true}
            onClick={() => toggleSider(true)}
          />
          <Button
            title="Dodaj odpad bez karty"
            color="white"
            className="mr-10"
            inline={true}
            onClick={() => toggleSider(false)}
          />
          <Button
            // disabled
          
            color="white"
            inline={true}
            title="Zamów sprawozdanie BDO"
            onClick={() => {
              setReportListVisible(true);
            }}
          />
       
        </header>
        <div className="mb-30 mt-30">
          <p className="md-t">
            <span className="md-t i-b mb-10">
              Magazyn – lista zapisanych odpadów – pokazuje sumę wytworzonych
              kilogramów, w tym ile jest przekazanych, a ile pozostaje na
              magazynie (nieprzekazanych). Kliknięcie na nazwę odpadu otwiera
              okno szczegółowego podglądu odpadu.
            </span>

            <span className="md-t t-500 black">Dostępne funkcjonalności:</span>
          </p>
          <ul className="info-list md-t">
            <li>
              Jeżeli masz Kartę Przekazania skorzystaj z opcji{" "}
              <span className="md-t t-500 black">Dodaj Odpad z Kartą</span>.
              Jeżeli nie masz to{" "}
              <span className="md-t t-500 black">Dodaj odpad bez karty</span>
            </li>
            <li>
              <div className="mb-5">Przekazanie odpadu osobie fizycznej: </div>
              <ol>
                <li>
                  Zaznacz checkbox obok odpadu, który chcesz przekazać.
                  Zaznaczenie jest niedostępne jeżeli odpad jest już w całości
                  przekazany lub jest to odpad niebezpieczny (oznaczony gwiazdką
                  przy kodzie odpadu)
                </li>
                <li>
                  Kliknij przycisk na dole listy „
                  <span className="md-t t-500 black">
                    Przekaż odpad osobie fizycznej
                  </span>
                  ”. Jeżeli masz już dodane wszystkie odpady i wszystkie
                  przekazania za {new Date().getFullYear() - 1} rok to{" "}
                  <span className="md-t t-500 black">
                    Zamów sprawozdanie BDO{" "}
                  </span>
                </li>
              </ol>
            </li>
            <li>
              <div className="mb-5">Przekazanie odpadu firmie: </div>
              <ol>
                <li>
                  Zaznacz checkbox obok odpadu, który chcesz przekazać.
                  Zaznaczenie jest niedostępne jeżeli odpad jest już w całości
                  przekazany.
                </li>
                <li>
                  Kliknij przycisk na dole listy „
                  <span className="md-t t-500 black">Przekaż odpad firmie</span>
                  ”.
                </li>
              </ol>
            </li>
          </ul>
        </div>

        <div className={style["report-btn-container"]}></div>
        <div className="pb-10">
          <WasteListTabs
            setForwardSiderCollapsed={setForwardSiderCollapsed}
            cardsHistory={cardsHistory}
            execFetchCardsHistory={execFetchCardsHistory}
            
            wasteCardsState={wasteCardsState}
            execFetchAddedCodes={execfetchAddedCodes}
          />
        </div>
        {/* <WasteSearchForm /> */}
      </main>
      {!createSiderCollapsed && (
        <div className="sider fixed">
          <header className="sider-header">
            <h2 className="primary mb-15">Dodaj odpad</h2>
          </header>

          <WasteCreateForm execFetchCardsHistory={execFetchCardsHistory} toggleSider={toggleSider} isCard={isCard} />
          <CloseIcon
            className="sider-close"
            onClick={() => toggleSider(undefined)}
          />
        </div>
      )}
      {!editSiderCollapsed && (
        <div className="sider fixed">
          <header className="sider-header">
            <h2 className="primary mb-15">Edytuj odpad</h2>
          </header>

          <WasteCardEditForm
            closeSider={hideEditSider}
            execFetchCardsHistory={execFetchCardsHistory}
          />
          <CloseIcon className="sider-close" onClick={() => hideEditSider()} />
        </div>
      )}
      {!forwardSiderCollapsed && (
        <div style={{ width: "1000px" }} className="sider fixed">
          <WasteForwardForm
            closeSider={() => {
              setForwardSiderCollapsed(true);
              execfetchAddedCodes();
            }}
            execFetchAddedCodes={execfetchAddedCodes}
          />
          <CloseIcon
            className="sider-close"
            onClick={() => setForwardSiderCollapsed(true)}
          />
        </div>
      )}
    </div>
  );
}

export default withAuthSync(withLayout(Waste));
