import CloseIcon from "@material-ui/icons/Close";
import { IOrderStatusForm, orderStatus } from "actions/so-orders";
import Col from "antd/lib/col";
import Row from "antd/lib/row";
import Button from 'components/buttons/button';
import SimpleLayout from "components/layout/simple-layout";
import { PAGE } from "paths";
import React, { useEffect } from "react";
import { useHistory } from 'react-router-dom';
import "styles/components/bdo-report/bdo-report-popup.scss";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import { disablePopup } from "utils/helpers";



const OOOrderPopup = (props: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");

  const history = useHistory();
  const redirectWithPopupDisable = () => {
    disablePopup('so_order');
    history.push(PAGE.HOME);
  }

  const goToSoOrderForm = () => {
    history.push({
      pathname: PAGE.SO_ORDER_APPROVAL,
      search: `?year=${year}`,
    }
    );
  };

  const [orderStatusState, execOrderStatus]: readonly [
    IActionState<IOrderStatusForm>,
    typeof orderStatus
  ] = useAsyncAction(orderStatus);

  useEffect(() => {
    execOrderStatus();
  }, []);  

  return (
    <div className="bdo-report-popup">
      <div className="close-page"  >
        <CloseIcon onClick={redirectWithPopupDisable} className="close-button" id="ws_close" />
      </div>

      <Row className="bdo-info" style={{marginTop: '60px'}}>
        <Col className="block" style={{paddingBottom: '24px'}}>
          <h2 className="primary">Zatwierdź deklarację mas rzeczywistych za {year}</h2>
          <p className="mb-50">Po dodaniu pojazdów za {year} system wyświetla deklarację mas rzeczywistych wraz z symulacją kosztów. Nowa funkcjonalność pozwala z tego poziomu dokonać korekty zmniejszając lub zwiększając ilość kg, a to ma wpływ na wysokość FV wystawionej przez Organizacje Odzysku.</p>
          <div className="mb-50">
            <p>Jeśli nie dodałeś jeszcze wszystkich importowanych pojazdów w {year} kliknij: “Dodaj pojazd”.</p>
            <Button link={`${PAGE.MY_VEHICLES}?show_form=true`} title="Dodaj pojazd" color="primary" width={250} className="mb-20" />
          </div>
          <div className="">
            <p>Jeśli dodałeś już wszystkie importowane pojazdy w {year} roku kliknij: “Zatwierdź ” i wyślij masy do Organizacji Odzysku.</p>
            <Button onClick={goToSoOrderForm} title={orderStatusState.data?.button_string || 'Zatwierdź'} color="primary" width={250} className="" disabled={!orderStatusState.data?.order_button_enabled} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SimpleLayout(OOOrderPopup);
