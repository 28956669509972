import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";
import { UseFormGetValues } from "react-hook-form";
import { ActionResponse } from "types/async-types";
import { requestHeaders } from "utils/auth";

export interface ISoOrderDeclarationForm {
  year: number;
  vehicle_types: Map<string, string>;
  extra_mass: { id: string; name: string }[];
  mass_sum: { id: string; name: string; sum: number }[];
  tool_tips: { [key: string]: string[] };
}

export interface ISoOrderDeclarationValues {
  vehicle_types: { [key: string]: string };
  extra_mass: { [key: string]: string };
}

export const declarationForm = async (
  token?: string | null,
  data?: ISoOrderDeclarationValues
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/so_orders/declaration_form`,
    headers: requestHeaders().headers,
    method: "POST",
    params: {
      token,
    },
    data: data,
  };
  const res = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

export const declaration = async (
  token?: string | null,
  data?: ISoOrderDeclarationValues
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/so_orders/declaration`,
    headers: requestHeaders().headers,
    method: "POST",
    params: {
      token,
    },
    data: data,
  };
  const res = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

export interface IMassDeclaration {
  id: string;
  name: string;
  weight: number;
  unmodified_weight: number;
  act_salvage_level: number;
  price_netto: number;
  sum_netto: number;
}

export interface ISoOrdersApprovalForm {
  year: number;
  mass_declaration: IMassDeclaration[];
  fee: { name: string; sum: number }[];
  administrative_fee: { [key: string]: number };
}

export interface ISoOrdersApprovalValues {
  mass_approved: {[key: string]: number};
  is_vehicles_add_finished: boolean;
}

export interface IOrderStatusForm {
  enabled_order_year: number;
  order_button_enabled: boolean;
  button_string?: string;
}

export const orderStatus = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/so_orders/status`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  const res = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

export const approvalForm = async (
  year?: string | null,
  token?: string | null,
  data?: ISoOrdersApprovalValues
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/so_orders/approval_form`,
    headers: requestHeaders().headers,
    method: "POST",
    params: {
      year,
      token,
    },
    data: data,
  };
  const res = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

const successMessage = (year?: string | null) => {
  if (year){
    return `Deklaracja mas za ${year} rok została zatwierdzona`
  } else {
    return 'Deklaracja mas została zatwierdzona'
  }
};

export const approval = async (
  year?: string | null,
  token?: string | null,
  data?: ISoOrdersApprovalValues
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/so_orders/approval`,
    headers: requestHeaders().headers,
    method: "POST",
    params: {
      year,
      token,
    },
    data: data,
  };
  const res = await axios(options);
  const result : ActionResponse<any> = {
    ...res,
    successMessage : successMessage(year)
  }
  return result;
};