import React, { useEffect, useState } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import Table, { ColumnProps } from "antd/lib/table";
import TableWrapper from "components/common/table-wrapper";
import { IActionState } from "types/async-types";
import Spinner from "components/core/spinner";

import { useAsyncAction } from "utils/async-action";

import Button from "components/buttons/button";

import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { useForm } from "react-hook-form";
import {  fetchBDOWSReports } from "actions/BDO-reports";
import {  IBDOWS, BDOWSListing } from "types/BDO-report-types";
import {  renderBreadcrumbs } from "utils/helpers";


import { IOSOP1FiltersFormValues } from "./os-op1-list";
import { LPColumn } from "utils/columns";
// import { Pagination } from 'antd';

const BDOKeys = () => {
  // const [pageNo, setPageNo] = useState(1);
  // const [pageCount, setPageCount] = useState(1);
  const [reportsState, execFetchReports]: readonly [
    IActionState<BDOWSListing>,
    typeof fetchBDOWSReports
  ] = useAsyncAction(fetchBDOWSReports);

  const formMethods = useForm<IOSOP1FiltersFormValues>({
    mode: "onChange",
  });

  const setPage = (page: number) => {
    execFetchReports({ ...formMethods.getValues(), page: page });
    // setPageNo(page);
  };

  useEffect(() => {
    execFetchReports();
  }, []);

  useEffect(() => {
    if (reportsState.data) {
      // setPageCount(reportsState.data.total_pages);
    }
  }, [reportsState.data]);

  const tableData = {
    filtersCollapsed: true,
    // pagination: false,
    loading: false,
    showHeader: false,

    columns: [
      LPColumn(reportsState.data?.items_from),
      {
        width: 200,
        title: "Utworzono",

        render: (field: any, row: IBDOWS) => row.created_at,
      },

      {
        width: 150,
        title: "CBK ID",

        render: (field: any, row: IBDOWS) => row.ext_cbk_id,
      },

      {
        
        title: "Nazwa firmy",

        render: (field: any, row: IBDOWS) => row.company_name,
      },
    
      {
        width: 200,
        title: "Imię",

        render: (field: any, row: IBDOWS) => row.name,
      },
      {
        width: 200,
        title: "Nazwisko",

        render: (field: any, row: IBDOWS) => row.surname,
      },


    ] as ColumnProps<IBDOWS>[] | undefined,
  };

  const onSubmit = (values: IOSOP1FiltersFormValues) => {
    execFetchReports({ ...values, page: 1 });
  };

  return (
    <div className="sider-page">
      {reportsState.loading && <Spinner size="large" />}
      <main className="page-content">
      <div className="breadcrumbs">
          {renderBreadcrumbs(["Administrator", 'Warunki szczególne usługi "Sprawozdanie roczne BDO"'])}   
        </div>
        <header className="page-header">
          <h1 className="grow-1">Warunki szczególne usługi "Sprawozdanie roczne BDO"</h1>
        </header>
        <div className="list-filters mb-20">
          <Row type="flex" align="middle" gutter={15}>
            <Col md={6}>
              <TextField
                name="cbk_id"
                formMethods={formMethods}
                label="CBK ID"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="date_from"
                type="date"
                formMethods={formMethods}
                label="Data utworzenia (od)"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="date_to"
                type="date"
                formMethods={formMethods}
                label="Data utworzenia (do)"
              />
            </Col>
            <Col md={12} className="t-right">
            <Button
                    title="Filtruj"
                    color="primary"
                    width={150}
                    onClick={formMethods.handleSubmit(onSubmit)}
                    className="mr-5"
                    inline={true}
                  />
                       <Button
                    title="Wyczyść"
                    color="white"
                    width={150}
                    inline={true}
                    onClick={() => {formMethods.reset(); execFetchReports(); }}
                  />
            
            </Col>
          </Row>
        </div>
        <TableWrapper
        data={reportsState.data}
          className="grow-1"
          fetchData={
            reportsState.data && {
              current_page: reportsState.data?.current_page,
              total_pages: reportsState.data?.total_pages,
            }
          }
          setPage={setPage}
          addPagination={true}
        >
          <Table
            size="middle"
            locale={{ emptyText: "Brak rekordów" }}
            rowKey="uuid"
            columns={tableData.columns}
            bordered={false}
            dataSource={reportsState.data?.list}
            pagination={false}
            className="custom-ant-table mb-10"
          />
        </TableWrapper>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(BDOKeys));
