import React, { useState } from "react";
import { useEffect } from "react";
import Router from "my-history";
// import nextCookie from "next-cookies";
import cookie from "js-cookie";
import { connect, useSelector } from "react-redux";
import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import {
  fetchSessionData,
  loginBySIDToken,
  getWorkshopToken,
} from "actions/user";
import { PAGE } from "paths";
import { useReduxAction, useAsyncAction } from "./async-action";
import { IReduxActionState } from "types/async-types";
import Spinner from "components/core/spinner";
import { RootState } from "redux/reducers";
// import { NextPageContext } from "next";
// import Cookies from 'cookies'

export const loginRedirect = () => {
  const path =
    window.location.pathname === "/"
      ? "/user/login"
      : `/user/login?redirect=${window.location.pathname}${window.location.search}`;
  Router.push(path);
};

export const login = async (token: any, redirectUrl?: string | null) => {
  cookie.remove("token");

  cookie.set("token", token);
  try {
    const res = await getWorkshopToken();
  } catch {
  } finally {
    if (redirectUrl) {
      Router.push(`${redirectUrl}`);
    } else {
      // Router.push("/");
      Router.push("/");
    }
  }
};

export const logout = () => {
  cookie.remove("token");
  cookie.remove("war_token");
  cookie.remove("disable_popup");
  // to support logging out from all windows
  window.localStorage.setItem("logout", Date.now().toString());
  Router.push("/user/login");
};

export const withAuthSync = (WrappedComponent: any) => {
  const Wrapper = (props: any) => {
    const [authDone, setAuthDone] = useState(false);
    const user = useSelector((state: RootState) => state.user);
    const [session, execFetchSession]: readonly [
      IReduxActionState,
      () => any
    ] = useReduxAction(fetchSessionData, "user");

    const [SIDTokenState, execLoginBySIDToken]: readonly [
      any,
      typeof loginBySIDToken
    ] = useAsyncAction(loginBySIDToken);

    useEffect(() => {
      auth();
    }, []);

    useEffect(() => {
      if (user.session?.api_token) {
        setAuthDone(true);
      }
    }, [user.session?.api_token]);

    // useEffect(() => {
    //   session;
    // }, []);

    const auth = async (ctx: any = {}) => {
      const urlParams = new URLSearchParams(window.location.search);
      let SID = urlParams.get("sid");
      let token: string | undefined = undefined;

      if (SID) {
        const res = await execLoginBySIDToken(SID);
        token = res.data.api_token;
      } else {
        token = cookie.get("token");
      }

      if (!token) {
        loginRedirect();
      } else {
        await execFetchSession();
      }
    };

    if (props.error_code === "401") {
      Router.push("/user/login");
    }
    if (session.error === 401 || !authDone) {
      return <Spinner size="large" />;
    } else {
      return <WrappedComponent {...props} />;
    }
  };

  // Wrapper.getInitialProps = async (ctx: any) => {
  //   if (ctx) {
  //     const token = await auth(ctx);
  //     if (token) {
  //       await ctx.store.dispatch(fetchSessionData(ctx));
  //       const componentProps =
  //         WrappedComponent.getInitialProps &&
  //         (await WrappedComponent.getInitialProps(ctx));
  //       // return { ...componentProps, token };
  //       return { ...componentProps };
  //     }
  //   }
  // };

  const mapStateToProps = (state: any) => {
    return {
      error_code: state.error.error_code,
    };
  };

  return connect(mapStateToProps, null)(Wrapper);
};

export const requestHeaders = (ctx?: any) => {
  const token = cookie.get("token");

  if (token) {
    return {
      headers: {
        authorization: `Bearer ${token}`,
        // authorization: `Bearer fake_session_009348`,
      },
    };
  } else {
    return {};
  }
};

export const workshopRequestHeaders = () => {
  const token = cookie.get("war_token");
  if (token) {
    return `Token ${token}`;
  } else {
    return {};
  }
};
