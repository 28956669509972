import React, { useCallback } from "react";
import { FormikProps, useFormikContext } from "formik";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useDropzone } from "react-dropzone";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import CloudIcon from "@material-ui/icons/CloudDownload";
import { IExistingFile } from "types/translation-types";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import "styles/components/common/file-dropzone.scss";
import { IOSOP1FormValues } from "types/BDO-report-types";
import { UseFormReturn } from "react-hook-form";

interface IProps {
  files: Array<File>;
  setFiles: (a1: (a: Array<File>) => any) => void;
  existingFiles: IExistingFile[];
  formMethods: UseFormReturn<any>;
  readOnly?: boolean;
  filesPropName?: string;
  execDeleteFile?: (id: string) => any;
}

function MyDropzone(props: IProps) {
  const {
    files,
    setFiles,
    existingFiles,
    formMethods,
    readOnly,
    filesPropName,
    execDeleteFile,
  } = props;

  const { getValues, setValue } = formMethods;

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const result = acceptedFiles.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles((prevFiles: Array<File>) => [...prevFiles, result[0]]);
  }, []);

  const removeFile = (key: string) => {
    setFiles((prevFiles: any[]) => {
      var newFiles = _.filter(prevFiles, function (item: any) {
        return item.name !== key;
      });
      return [...newFiles];
    });
  };

  const removeExistingFile = (id: string) => {
    if (execDeleteFile) {
      execDeleteFile(id);
    }
  };

  const renderExistingThumbs = () => {
    return _.map(existingFiles, (file, index) => {
      // if (existingFileIdsToDelete?.includes(file.id)) {
      //   return <> </>;
      // }
      return (
        <li
          key={index}
          data-testid={`thumbnail_${index}`}
          className="thumbnail"
        >
          <InsertDriveFileOutlinedIcon className="file-icon" />
          <a href={file.url} target="_blank" className="grow-1 lg-t t-500 black file-name" rel="noreferrer">
            {file.file_name} 
          </a>
          <div>
            {!readOnly && (
              <div
                data-testid="remove-file"
                onClick={() => {
                  window.confirm("Czy napewno chcesz usunąć załącznik?");
                  removeExistingFile(file.id);
                }}
                className="remove-file"
              >
                <DeleteOutlineOutlinedIcon />
              </div>
            )}
          </div>
        </li>
      );
    });
  };

  const renderThumbs = () => {
    
    return _.map(files, (file: any, index) => {
      return (
        <li
          key={index}
          data-testid={`thumbnail_${index}`}
          className="thumbnail"
        >
          <InsertDriveFileOutlinedIcon className="file-icon" />
          <div className="grow-1 lg-t t-500 black file-name">{file.name}</div>

          {!props.readOnly && (
            <div
              data-testid="remove-file"
              onClick={() => removeFile(file.name)}
              className="remove-file"
            >
              <DeleteOutlineOutlinedIcon />
            </div>
          )}
        </li>
      );
    });
  };

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept:
      "image/*, application/doc, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf",
    onDrop,
  });

  return (
    <div className="file_dropzone_uploader">
      <input data-testid="dropzone-input" {...getInputProps()} />
      <Row gutter={20}>
        {!readOnly && (
          <Col span={12}>
            <div className="ant-form-item-label mb-10">
              <label>Załaduj pliki</label>
            </div>
            <div className="file_dropzone" {...getRootProps()}>
              <div className="dropzone_text">
                <CloudIcon />
                <p className="primary">Wezwanie do rozliczenia</p>
                <p className="small">
                  Przeciągnij i upuść tutaj lub{" "}
                  <a className="default-link">wybierz pliki</a> do wysłania
                </p>
              </div>
            </div>
          </Col>
        )}
        <Col span={12}>
          <div className="ant-form-item-label mb-10">
            <label>
              Załadowane pliki: {files.length + existingFiles.length}
            </label>
          </div>
          <ul>{renderThumbs()}</ul>
          <ul>{renderExistingThumbs()}</ul>
        </Col>
      </Row>
    </div>
  );
}

export default MyDropzone;
