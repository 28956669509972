import "../../wdyr";
import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "components/buttons/form-button";
import CheckFormField from "components/form-fields/hook-form-fields/hook-check-field";
import TextFormField from "components/form-fields/hook-form-fields/hook-text-field";
import RadioGroup from "components/form-fields/hook-form-fields/hook-radio-group";
import WarningIcon from "@material-ui/icons/Warning";
import FormButtons from 'components/common/form-buttons';
import { IActionState } from "types/async-types";
import {
  INetworkAssuranceCompany,
  INetworkProvisionFormValues,
} from "types/BDO-report-types";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { calculateNetwork } from "actions/BDO-reports";
import FormButton from "components/buttons/form-button";
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormState,
} from "react-hook-form";
import StatCounter from "components/common/stat-counter";
import { numToCurrency, isEmpty } from "utils/helpers";
import { useGraphForm } from "./graph-form-utils";
import { AgreementsList } from "components/home/services-list";

const BDOYearReportStep3 = (props: { setNavState: any }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { setNavState } = props;
  const id = urlParams.get("id");
  const {
    state,
    initializeStep,
    saveStep,
    goToStep,
    formData,
    stepNavData,
  } = useGraphForm<INetworkProvisionFormValues>(id);

  const initialValues = {
    business_started_in_report_year: false,
    network_fee: 0,
    vehicle_count: 0,
    is_other_agreements: false,
    bdo_terms_accepted: false,
    business_start_date: "",
    integrator_agreement: {
      form: "",
      to: "",
      name: "",
    },
    other_agreements: [],
  };

  const [calculateState, execCalculate]: readonly [
    IActionState<INetworkProvisionFormValues>,
    typeof calculateNetwork
  ] = useAsyncAction(calculateNetwork);

  const formMethods = useForm<INetworkProvisionFormValues>({
    mode: "onChange",
    defaultValues: initialValues,
  });

  const { control, watch, reset, getValues } = formMethods;

  useEffect(() => {
    setNavState({
      goToStep,
      stepNavData,
    });
  }, [stepNavData]);

  useEffect(() => {
    initializeStep("network");
  }, []);

  useEffect(() => {
    if (formData.data) {
      reset({
        ...formData.data,
        other_agreements: formData.data.other_agreements || [],
      });
    }
  }, [formData.data]);

  const submitForm = (values: INetworkProvisionFormValues) => {
    saveStep("network", values);
  };

  const calculate = () => {
    if (id) {
      execCalculate(id, getValues());
    }
  };

  useEffect(() => {
    if (calculateState.data) {
      reset({ ...getValues() });
    }
  }, [calculateState.data]);

  const [countInputMode, setCountInputMode] = useState<boolean>(false);
  const defaultAgreement: INetworkAssuranceCompany = {
    name: "",
    from: "",
    to: "",
  };

  const { fields, append, remove } = useFieldArray({
    control,
    name: "other_agreements",
  });

  const StartedIn2021 = watch("business_started_in_report_year");
  const networkFee = watch("network_fee");
  const has_network_assurance = watch("is_other_agreements");

  const VehicleTotal = ({ amount, title, suffix, color, className }: any) => {
    return (
      <div className={className}>
        <h3 className="black t-500">{title}</h3>
        <div>
          {amount !== null && amount !== undefined ? (
            <>
              {countInputMode ? (
                <TextFormField
                  label=""
                  noBottomMargin={true}
                  name="vehicle_count"
                  className="mt-10"
                  textClass="price text-42 t-500 heading"
                  formMethods={formMethods}
                  type="number"
                />
              ) : (
                <div
                  onClick={() => setCountInputMode(true)}
                  className={`hover price text-42 t-500 ${color || "green"}`}
                >
                  {amount}
                </div>
              )}

              {suffix && <p className="sm-l-t">{suffix}</p>}
            </>
          ) : (
            <p className="grey">Brak danych </p>
          )}{" "}
        </div>
      </div>
    );
  };

  const renderCompanies = () => {
    let result: any = undefined;

    result = fields.map((item, index) => {
      return (
        <Row key={item.id} gutter={10} align="middle" type="flex">
          <Col xxl={13} className="flex">
            <span style={{ width: "80px" }} className="sm-l-t mr-5">
              Umowa {index + 1}:
            </span>
            <TextFormField
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
              formMethods={formMethods}
              label="Nazwa firmy"
              className="grow-1"
              name={`other_agreements.${index}.name`}
            />
          </Col>
          <Col xxl={4}>
            <TextFormField
              type="date"
              formMethods={formMethods}
              label="Data obowiązywania umowy (od)"
              name={`other_agreements.${index}.from`}
            />
          </Col>
          <Col xxl={4}>
            <TextFormField
              type="date"
              formMethods={formMethods}
              label="Data obowiązywania umowy (do)"
              name={`other_agreements.${index}.to`}
            />{" "}
          </Col>
          <Col xxl={3}>
            <Button onClick={() => remove(index)} title="Usuń" color="white" />
          </Col>
        </Row>
      );
    });
    // }
    if (result) {
      return result;
    }
  };

  const Buttons = () => {
    const { isDirty } = useFormState({ control });
    return (
      <div className="form-content">
        <div className="i-b mr-5">
          <Button
            width={220}
            title="Zapisz"
            onClick={calculate}
            color="primary"
            className="mr-5"
            inline={true}
            disabled={!isDirty}
          />
        </div>

        <FormButton
          width={220}
          color="primary"
          onClick={formMethods.handleSubmit(submitForm)}
          title="Kontynuuj"
          inline={true}
          disabled={isDirty}
        />
      </div>
    );
  };

  const dueFee = !isEmpty(calculateState.data?.network_fee) ?  calculateState.data?.network_fee : formData.data?.network_fee;
  const daysWithout =
    !isEmpty(calculateState.data?.days_of_year_without_network) ? calculateState.data?.days_of_year_without_network :
    formData.data?.days_of_year_without_network;
  return (
    <FormProvider {...formMethods}>
      <div>
        {(state.isLoading || calculateState.loading) && (
          <Spinner size="large" fixed={true} />
        )}

        <div className="block">
          <p className="large mb-0">
            Jesteś w trakcie przygotowania sprawozdania i wyliczania należnej
            opłaty za brak sieci. Sprawdź czy wszystkie przygotowane dane są
            poprawne.
          </p>
        </div>
        <div className="block">
          <section>
            <h2 className="h-lg">
              Sprawozdanie o niezapewnieniu Sieci Stacji Demontażu.
            </h2>
            <Row gutter={10}>
              <Col lg={8}>
                <StatCounter
                  amount={numToCurrency(dueFee)}
                  color={(dueFee && dueFee > 0) ? "red" : "green"}
                  title="Należna opłata za brak sieci"
                  suffix="Wysokość należnej opłaty do Urzędu Marszałkowskiego wyliczona na podstawie wprowadzonych danych."
                />
                {(dueFee || 0) > 0 && (
                  <div className="red md-t flex-top-left">
                    <WarningIcon className="md-t mr-5" />{" "}
                    <p className="red">
                      Jeżeli nie zgadzasz się z wyliczoną kwotą sprawdź dane
                      wpisane poniżej. Maja one wpływ na należną opłatę.{" "}
                    </p>
                  </div>
                )}
              </Col>
              <Col lg={8}>
                <StatCounter
                  amount={daysWithout || 0}
                  color={(daysWithout || 0) > 21 ? "red" : "green"}
                  title="Dni bez sieci"
                  suffix="Wyliczona liczba dni w roku kalendarzowym, w których nie zapewniono sieci."
                />
              </Col>
              <Col lg={8}>
                <VehicleTotal
                  amount={formData.data?.vehicle_count}
                  color="heading"
                  title="Liczba pojazdów"
                  suffix="osobowych i dostawczych do 3,5 tony wprowadzonych w 2023 roku (podlegających obowiązkowi zapewnienia sieci)"
                />
              </Col>
            </Row>
          </section>
          <section>
            <div className="mb-15">
              <h2>Dane działalności</h2>
              <div className="mb-10">
                <CheckFormField
                  formMethods={formMethods}
                  cbxLabel="Jeżeli rozpocząłeś działalność w 2023 roku to zaznacz check i wpisz datę rozpoczęcia."
                  name="business_started_in_report_year"
                  value={true}
                  isFlag={true}
                />
              </div>
              <Row>
                <Col xxl={4}>
                  {StartedIn2021 && (
                    <TextFormField
                      name="business_start_date"
                      label="Data rozpoczęcia działalności gospodarczej"
                      type="date"
                      required={true}
                      rules={{
                        required: "Pole jest wymagane",
                      }}
                      formMethods={formMethods}
                    />
                  )}
                </Col>
              </Row>
            </div>
            <h2>Umowy o zapewnienie sieci obowiązujące w 2023 roku</h2>
            <div className="mb-20 border-b-light">
              <AgreementsList
                items={
                  formData.data?.integrator_agreement
                    ? [formData.data?.integrator_agreement]
                    : null
                }
              />
            </div>
            <RadioGroup
              formMethods={formMethods}
              orientation="vertical"
              name="is_other_agreements"
              className="mb-0"
              label=""
              options={[
                {
                  label:
                    "Posiadam inną umowę/y o zapewnienie sieci za rok 2023",
                  value: true,
                },
                {
                  label:
                    "Nie posiadam innej umowy o zapewnienie sieci za rok 2023",
                  value: false,
                },
              ]}
            />
            {has_network_assurance && (
              <div className="mb-30">
                {renderCompanies()}
                <Button
                  width={150}
                  title="Dodaj umowę"
                  onClick={() => append(defaultAgreement)}
                  color="primary"
                  inline={true}
                />
              </div>
            )}
            <CheckFormField
              formMethods={formMethods}
              cbxLabel="Akceptuję usługę Sprawozdanie Roczne BDO za rok 2023"
              name="bdo_terms_accepted"
              value={true}
              isFlag={true}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
            <div className="mt-30"></div>
          </section>
        </div>
      </div>
      <FormButtons>
        <Buttons />
      </FormButtons>
    </FormProvider>
  );
};
BDOYearReportStep3.whyDidYouRender = true;
export default BDOYearReportStep3;
