import './wdyr'; 
import React from 'react';
import  ConfigProvider from 'antd/lib/config-provider';
import "antd/dist/antd.min.css";
import "react-toastify/dist/ReactToastify.css";
// import "react-confirm-alert/src/react-confirm-alert.css";
import 'styles/main.scss';

import History from 'my-history';
import { Switch, Route, Router } from 'react-router-dom';
import Home from 'pages/home';
import MyVehicles from 'pages/my-vehicles';
import ProductCharges from 'pages/services/product-charges';
import NetworkProvision from 'pages/services/network-provision';
import Waste from 'pages/waste';
import WasteDetails from 'pages/waste/waste-details';
import Settlements from 'pages/settlements';
import BDOKey from 'pages/bdo-key';
import BDORegistry from 'pages/bdo-services/bdo-registry';
import BDORegistryForm from 'pages/bdo-services/bdo-registry-form';
import OSOP1Report from 'pages/bdo-services/OS-OP1-report';
import OSOP1 from 'pages/bdo-services/OS-OP1'; 
import OSOP1List from 'pages/admin/os-op1-list'; 
import BDOWSList from 'pages/admin/WS_BDO'; 
import OSOP1Details from 'pages/admin/os-op1-details'; 
import BDOReport from 'pages/bdo-services/bdo-report';
import MassRegistry from 'pages/services/mass-registry';
import Documents from 'pages/documents';
import AgreementScans from 'pages/agreement_scans';
import Login from 'pages/user/login';
import ChangePassword from 'pages/user/change-password';
import NewPassword from 'pages/user/new-password';
import EmailSent from 'pages/user/email-sent';
import { PAGE } from 'paths';
// import PL from 'antd/lib/locale/pl_PL';
import { ToastContainer } from 'react-toastify';
import BDOReportPopup from 'components/bdo-report/bdo-report-popup';
import APIKeyInstruction from 'components/bdo-report/api-key-instruction';
import BDOReportForm from 'pages/bdo-services/bdo-report-form';
import OSOP1Form from 'pages/bdo-services/os-op1-form';
import DocumentSummary from 'pages/settlements/online-payment-summary';
import DocumentTransferSummary from 'pages/settlements/bank-transfer-summary';
import DocumentPaymentStatus from 'pages/settlements/documents-payment-status';
import OOOrders from 'pages/admin/oo-orders';
import OOOrdersForm from 'pages/admin/oo-orders-form';
import OOSummary from 'pages/admin/oo-summary';
import OOSuborders from 'pages/admin/oo-suborders';
import ElectronicsForm from 'components/products/electronics-form/electronics-form';
import AgreementSuccess from 'components/products/electronics-form/agreement-success';
import BDOReportSuccess from 'components/bdo-report-form/bdo-report-success';
import BDOKeys from 'pages/admin/bdo-keys'; 
import AdminDocuments from 'pages/admin/documents';
import AdminVehicles from 'pages/admin-vehicles';
import AdminDocumentsGroupEdit from 'pages/admin/document-group-edit';
import NotFound from 'pages/not-found';
import BDOYearReport from 'pages/bdo-services/bdo-year-report-form';
import BDOYearReportSubmit from 'components/bdo-year-report-form/submit';
import AdminBDOPayments from 'pages/admin/payments-list';
import AdminBDOPaymentDetails from 'pages/admin/payment-details';
import AdminBDOReports from 'pages/admin/bdo-reports-list';


import "react-confirm-alert/src/react-confirm-alert.css";
import SoOrderDeclarationForm from 'components/so-order/declaration-form';
import SoOrderApprovalFrom from 'components/so-order/approval-form';
import OOOrderPopup from 'components/oo-order/oo-order-popup';





function App() {
  return (
    <div className="App">
       
   
      <Router history={History}>
      <Switch>
                    {/* for logged user */}
                    <Route exact path={PAGE.HOME} component={Home}  />
                    <Route  /* withAuthSync in component  */ component={MyVehicles} path={PAGE.MY_VEHICLES} />      
                    <Route /* withAuthSync in component  */ component={Login} path={PAGE.LOGIN} />      
                    <Route /* withAuthSync in component  */ component={ChangePassword} path={PAGE.CHANGE_PASSWORD} />      
                    <Route /* withAuthSync in component  */ component={EmailSent} path={PAGE.EMAIL_SENT} />      
                    <Route /* withAuthSync in component  */ component={NewPassword} path={PAGE.NEW_PASSWORD} />      
                    <Route /* withAuthSync in component  */ component={ProductCharges} path={PAGE.PRODUCT_CHARGES} />   
                    <Route /* withAuthSync in component  */ component={NetworkProvision} path={PAGE.NETWORK_PROVISION} />
                    <Route /* withAuthSync in component  */ component={Waste} path={PAGE.WASTE} />                
                    <Route /* withAuthSync in component  */ component={WasteDetails}  path={`${PAGE.WASTE_DETAILS}/:code`} />
                    <Route /* withAuthSync in component  */ component={Settlements}  path={`${PAGE.SETTLEMENTS}`} />        
                    <Route /* withAuthSync in component  */ component={BDOKey}  path={`${PAGE.BDO_KEY}`} />
                    <Route /* withAuthSync in component  */ component={BDORegistry}  path={`${PAGE.BDO_REGISTRY}`} />          
                    <Route /* withAuthSync in component  */ component={OSOP1Report}  path={`${PAGE.OS_OP1_REPORT}`} />
                    <Route /* withAuthSync in component  */ component={OSOP1}  path={`${PAGE.OS_OP1}`} />
                    <Route /* withAuthSync in component  */ component={BDOReport}  path={`${PAGE.BDO_REPORT}`} />   
                    <Route /* withAuthSync in component  */ component={BDOYearReport}  path={`${PAGE.BDO_YEAR_REPORT}`} />  
                    <Route /* withAuthSync in component  */ component={BDOYearReportSubmit}  path={`${PAGE.BDO_YEAR_REPORT_SUBMIT}`} />    
                    <Route /* withAuthSync in component  */ component={MassRegistry}  path={`${PAGE.MASS_REGISTRY}`} />          
                    <Route /* withAuthSync in component  */ component={Documents}  path={`${PAGE.DOCUMENTS}`} />
                    <Route /* withAuthSync in component  */ component={AgreementScans}  path={`${PAGE.AGREEMENT_SCANS}`} />  
                    <Route /* withAuthSync in component  */ component={BDOReportPopup}  path={`${PAGE.BDO_INFO}`} />  
                    <Route /* withAuthSync in component  */ component={APIKeyInstruction}  path={`${PAGE.API_KEY_INSTRUTION}`} />  
                    <Route /* withAuthSync in component  */ component={BDOReportForm}  path={`${PAGE.BDO_REPORT_FORM}`} />
                    {/* <Route  component={BDORegistryForm}  path={`${PAGE.BDO_REGISTRY_FORM}`} /> */}
                    <Route /* withAuthSync in component  */ component={OSOP1Form}  path={`${PAGE.OS_OP1_FORM}`} />
                    <Route /* withAuthSync in component  */ component={DocumentSummary}  path={`${PAGE.DOCUMENT_PAYMENT_SUMMARY}`} />
                    <Route /* withAuthSync in component  */ component={DocumentTransferSummary}  path={`${PAGE.DOCUMENT_TRANSFER_SUMMARY}`} />
                    <Route /* withAuthSync in component  */ component={DocumentPaymentStatus}  path={`${PAGE.DOCUMENT_PAYMENT_STATUS}`} />
                    <Route /* withAuthSync in component  */ component={OOOrders}  path={`${PAGE.ADMIN_OO_ORDERS}`} />
                    <Route /* withAuthSync in component  */ component={OOOrdersForm}  path={`${PAGE.ADMIN_OO_ORDERS_FORM}`} />
                    <Route /* withAuthSync in component  */ component={OOSummary}  path={`${PAGE.ADMIN_OO_SUMMARY}`} />
                    <Route /* withAuthSync in component  */ component={OOSuborders}  path={`${PAGE.ADMIN_OO_SUBORDERS}`} />
                    <Route /* withAuthSync in component  */ component={BDOKeys}  path={`${PAGE.ADMIN_BDO_KEYS}`} />
                    <Route /* withAuthSync in component  */ component={OSOP1Details}  path={`${PAGE.ADMIN_OS_OP1_DETAILS}/:id`} />
                    <Route /* withAuthSync in component  */ component={OSOP1List}  path={`${PAGE.ADMIN_OS_OP1_LIST}`} />
                    <Route /* withAuthSync in component  */ component={BDOWSList}  path={`${PAGE.ADMIN_BDO_WS_LIST}`} />
                    <Route /* withAuthSync in component  */ component={AdminDocuments}  path={`${PAGE.ADMIN_DOCUMENTS}`} />
                    <Route /* withAuthSync in component  */ component={AdminBDOPaymentDetails}  path={`${PAGE.ADMIN_BDO_PAYMENT_DETAILS}/:id`} />
                    <Route /* withAuthSync in component  */ component={AdminBDOReports}  path={`${PAGE.ADMIN_BDO_REPORTS}`} />
                    <Route /* withAuthSync in component  */ component={AdminBDOPayments}  path={`${PAGE.ADMIN_BDO_PAYMENTS}`} />
                    <Route /* withAuthSync in component  */ component={AdminVehicles}  path={`${PAGE.ADMIN_VEHICLES}`} />
                    <Route /* withAuthSync in component  */ component={AdminDocumentsGroupEdit}  path={`${PAGE.ADMIN_DOCUMENTS_GROUP_EDIT}/:id`} />
                    <Route /* withAuthSync in component  */ component={ElectronicsForm}  path={`${PAGE.ELECTRONICS_FORM}`} />
                    <Route /* withAuthSync in component  */ component={AgreementSuccess}  path={`${PAGE.AGREEMENT_SUCCESS}`} />
                    <Route /* withAuthSync in component  */ component={BDOReportSuccess}  path={`${PAGE.BDO_REPORT_SUCCESS}`} /> 
                    <Route /* withAuthSync in component  */ component={SoOrderApprovalFrom}  path={`${PAGE.SO_ORDER_APPROVAL}`} /> 
                    <Route /* withAuthSync in component  */ component={SoOrderDeclarationForm}  path={`${PAGE.SO_ORDER_DECLARATION}`} /> 
                    <Route /* withAuthSync in component  */ component={OOOrderPopup}  path={`${PAGE.SO_ORDER_INFO}`} />  
                    
                    <Route path="*" component={() => <NotFound />} />
                    
                    
                    
                          
      </Switch>
      </Router>
          <ToastContainer
            limit={1}
        // position="top-center"
        // // autoClose={true}
        // hideProgressBar={false}
        // newestOnTop={false}
        // // closeOnClick
        // rtl={false}
        // // pauseOnVisibilityChange
        // draggable
        // // pauseOnHover
      />
      
    </div>
  );
}
App.whyDidYouRender = true;
export default App;
