import React, { useEffect } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Spinner from "components/core/spinner";
import FormButton from "components/buttons/form-button";
import StatCounter from "components/common/stat-counter";
import { numToCurrency } from "utils/helpers";
import FormButtons from "components/common/form-buttons";
import { useGraphForm } from "./graph-form-utils";
import { ICampaignFormData } from "types/BDO-report-types";

const BDOYearReportStep4 = (props: any) => {
  const { setNavState } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const {
    state,
    initializeStep,
    saveStep,
    goToStep,
    formData,
    stepNavData,
  } = useGraphForm<ICampaignFormData>(id);

  useEffect(() => {
    initializeStep("campaign");
  }, []);

  useEffect(() => {
    setNavState({
      goToStep,
      stepNavData,
    });
  }, [stepNavData]);

  const submitForm = async (values: any) => {
    saveStep("campaign");
  };

  return (
    <>
      {state.isLoading && <Spinner size="large" fixed={true} />}

      <div className="block">
        <section>
          <h2 className="h-lg">
            Jesteś w trakcie przygotowania sprawozdania z publicznej kampanii
            edukacyjnej. Sprawdź czy wszystkie przygotowane dane są poprawne.
          </h2>
          <Row gutter={10} className="mb-25">
            <Col lg={12}>
              <StatCounter
                amount={numToCurrency(formData.data?.funds_min)}
                color="heading"
                title="Minimalna wysokość środków"
                suffix="Minimalna wysokość środków, które należy przeznaczyć na publiczne kampanie edukacyjne"
              />
            </Col>
            <Col lg={12}>
              <StatCounter
                amount={numToCurrency(formData.data?.funds_transferred)}
                color="heading"
                title="Wysokość środków przekazanych"
                suffix="Wysokość środków przeznaczonych na publiczne kampanie edukacyjne przekazanych na odrębny rachunek bankowy Marszałka Województwa"
              />
            </Col>
          </Row>
          <Row gutter={10} className="mb-0">
            <Col lg={12}>
              <StatCounter
                amount={numToCurrency(formData.data?.funds_transferred_own)}
                color="heading"
                title="Środki przekazane we własnym zakresie"
                suffix="Wysokość środków przeznaczonych na publiczne kampanie edukacyjne we własnym zakresie"
              />
            </Col>
            <Col lg={12}>
              <StatCounter
                amount={formData.data?.funds_purpose || null}
                color="heading"
                title="Sposób wykorzystania"
                suffix="Sposób wykorzystania środków przeznaczonych na publiczne kampanie edukacyjne."
              />
            </Col>
          </Row>
        </section>
      </div>
      <FormButtons>
        <FormButton
          width={220}
          color="primary"
          onClick={submitForm}
          title="Kontynuuj"
          inline={true}
        />
      </FormButtons>
    </>
  );
};

export default BDOYearReportStep4;
