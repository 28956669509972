import React from "react";
import Button from "components/buttons/button";
import { PAGE } from "paths";


import Spinner from "components/core/spinner";
import { numToCurrency } from "utils/helpers";

import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import Price from 'components/common/price';

const PaymentsTile = (props: any) => {
  const user = useSelector((state: RootState) => state.user);
  const async = useSelector((state: RootState) => state.async);
  const ballance: number | undefined = user.ballance;



  return (
    <div className="block">
      {(async.property === "user" && async.isLoading) && <Spinner />}
      <h2 className="primary h-lg">Płatności</h2>
      <section>
        {/* <h3>Bieżące saldo</h3>
        <div className="text-42">
          <span className={`${(ballance || 0) < 0 ? "red" : "green"}`}>
            {numToCurrency(ballance)}
          </span>
        </div> */}
        <Price
        title="Bieżące saldo"
               price={ballance }
               suffix={false}
             />
      </section>
      <section>
        <Button
          width={200}
          color="primary"
          title="Zobacz szczegóły"
          link={PAGE.SETTLEMENTS}
        />
      </section>
    </div>
  );
};

export default PaymentsTile;
