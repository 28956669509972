import React from "react";
import Header from "components/layout/header";
import { useState, useEffect } from "react";
import Layout from "antd/lib/layout";
import Spinner from "components/core/spinner";
//@ts-ignore
import "styles/layout/layout.scss";


const withLayout = (Page: any) => {
  const MainLayout = (props: any) => {
    const [userLoading, setUserLoading] = useState(false);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [mobileMenuHidden, setMobileMenuHidden] = useState(true);
    const [notesCollapsed, setNotesCollapsed] = useState(true);


    const hamburgerClick = () => {
      const hidden = mobileMenuHidden ? false : true;
      setMobileMenuHidden(hidden);
    };

    const bellClick = () => {
      const collapsed = notesCollapsed ? false : true;
      setNotesCollapsed(collapsed);
    };


    return (
      <Layout id="layout">
        {userLoading && <Spinner size="large" />}

        <Header
          fixed={true}
          hamburgerClick={hamburgerClick}
          handleMenuClick={() => setMenuCollapsed(!menuCollapsed)}
          handleBellClick={()=> {}}
          setUserLoading={setUserLoading}
        />
        <Page {...props} />
      </Layout>
    );
  };

  if (Page.getInitialProps) {
    MainLayout.getInitialProps = Page.getInitialProps;
  }

  return MainLayout;
};

export default withLayout;
