import React, { useEffect, useState, useRef } from "react";
import { IActionState, ActionResponse } from "types/async-types";
import {
  IAccountingDocuments,
  IAccountingDocument,
  documentTypes,
  IDocumentPdf,
} from "types/document-types";
import CheckFormField from "components/form-fields/check-form-field";
import TableWrapper from "components/common/table-wrapper";
import FormButton from "components/buttons/form-button";
import CostSummary from "components/common/cost-summary";
import Spinner from "components/core/spinner";
import {
  fetchAccountingDocuments,
  fetchPaymentSummary,
  fetchAccountingDocument,
  createPayment,
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import Table from "antd/lib/table";
import { Formik, FormikProps, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";

interface ISummary {
  can_pay_online: boolean;
  bank_transfer_details: {
    account_number: string | null;
    recipient: string | null;
  };
  documents: IAccountingDocument[];
}

interface IFormValues {
  document_ids: string[];
}

const PaymentSummary = (props: any) => {
  const formRef = useRef<FormikProps<IFormValues>>(null);
  const history = useHistory();
  const [prices, setPrices] = useState<number>(0);
  const [formik, setFormik] = useState<any>();
  const [total, setTotal] = useState<number>();
  
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [tableData, setTableData] = useState<any>({});
  const [summaryState, execFetchSummary]: readonly [
    IActionState<ISummary>,
    typeof fetchPaymentSummary
  ] = useAsyncAction(fetchPaymentSummary);
  
  const [documentFileState, execFetchAccountingDocument]: readonly [
    IActionState<IDocumentPdf>,
    typeof fetchAccountingDocument
  ] = useAsyncAction(fetchAccountingDocument);

  const [paymentState, execCreatePayment]: readonly [
    IActionState<{ id: string; payment_address: string }>,
    typeof createPayment
  ] = useAsyncAction(createPayment);

  const onSubmit = async (values: IFormValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("seller_id");
    if (id) {
      
      execCreatePayment(id, selectedIds.join("|"));
    }
  };

  const result = summaryState.data?.documents?.map((item, index) => ({
    ...item,
    key: item.id,
  }));

  useEffect(() => {
    if (paymentState.data) {
      window.location.href = paymentState.data?.payment_address;
    }
    
  }, [paymentState.data]);

  useEffect(() => {
    if (paymentState.data) {
    }
  }, [paymentState.data]);

  const downloadFile = async (id: string) => {
    const res:
      | ActionResponse<IDocumentPdf>
      | undefined = await execFetchAccountingDocument(id);
    if (res) {
      const linkSource = `data:application/pdf;base64,${res.data.data.pdf_base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "dokument.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("seller_id");
    if (id) {
      execFetchSummary(id);
    }
    setTableData(getTableData());
  }, []);

  useEffect(() => {
    if(summaryState.data){
      setTableData(getTableData());
    }
  
  }, [summaryState.data]);

  useEffect(() => {
    
  }, [formik]);

  // useEffect(() => {
  //   if (summaryState.data && formRef.current) {
  //     summaryState.data.documents.forEach((item, index) => {
  //       formRef.current?.setFieldValue(`document_ids[${index}]`, item.id);
  //     });
  //     const result = summaryState.data.documents.reduce((total, current) => {
  //       return total + parseFloat(current.brutto);
  //     }, 0);
  //     setTotal(result);
  //   }
  // }, [summaryState.data]);
  const documentIds = formRef.current?.values.document_ids;

  const rowSelection = {
    onChange: (selectedRowKeys :any , selectedRows : IAccountingDocument[]) => {
      
      const ids = selectedRows.map((item, index) => {
            return item.id;
      })
      setSelectedIds(ids);
      
      const result = selectedRows.reduce((total, current) => {
        return total + parseFloat(current.brutto);
      }, 0);
      
      setTotal(result);
    },
    // getCheckboxProps: (record: any) => ({
    //   checked : true
    // }),
  };



  const renderColumns = () => {
    return [
      // {
      //   width: 40,
      //   title:  <CheckFormField isFlag={true} name="select_all" value={true} onCheckChange={(e : React.ChangeEvent<HTMLInputElement>) => selectAll(summaryState, e)} />,
      //   render: (field: any, row: IAccountingDocument) => (
      //     <div className="checkbox">
      //       <checkbox
      //         name={`document_ids`}
      //         id={`translations_${row.id}`}
      //         value={`${row.id}`}
      //         keyValues={true}
      //       />
      //     </div>
      //   ),
      // },
      {
        title: "Data",
        align: "left",
        width: 100,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.draw_date}</div>
        ),
      },
      {
        title: "Typ",
        align: "left",
        width: 200,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap column-upper">{documentTypes[row.type]}</div>
        ),
      },
      {
        title: "Numer",
        align: "left",
        width: 140,
        render: (field: any, row: IAccountingDocument) => (
          <a onClick={() => downloadFile(row.id)}>{row.number}</a>
        ),
      },
      {
        title: "Sprzedający",
        align: "left",
        width: 450,
        render: (field: any, row: IAccountingDocument) => (
          <div>{row.seller_string}</div>
        ),
      },
      {
        title: "Termin płatności",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{row.payment_due_date}</div>
        ),
      },
      {
        title: "Dni do terminu wpłaty",
        align: "left",
        width: 110,
        render: (field: any, row: IAccountingDocument) => (
          <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{row.days_due_date}</div>
        ),
      },
      {
        title: "Kwota do zapłaty",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap t-500 ${row.payment_date ? "green" : "red"}`}
          >
            {" "}
            {row.brutto} zł
          </div>
        ),
      },
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      title: () => (
        <div className="table_title">
          <CheckFormField isFlag={true} name="select_all" value={true} onCheckChange={(e : any) => selectAll(summaryState, e)} />
          
        </div>
      ),
      columns: renderColumns(),
    };

    return data;
  };



  const Effects = () => {
    const formikProps = useFormikContext<IFormValues>();

    useEffect(() => {
      const documentIds = formikProps.values.document_ids;

      const selectedDocuments = summaryState.data?.documents?.filter(
        (item, index) => {
          return documentIds?.find((docId) => {
            return docId === item.id;
          });
        }
      );
      
      const result = selectedDocuments?.reduce((total, current) => {
        return total + parseFloat(current.brutto);
      }, 0);
      setTotal(result);
    }, [formikProps.values.document_ids]);

    return <></>;
  };

  const selectAll = (documents: IActionState<ISummary>, e : React.ChangeEvent<HTMLInputElement>) => {
    let ids: Array<string> = [];
    if(!e.target.checked ){
      formRef.current?.setFieldValue("document_ids", []);
      return;
    }
    if (documents) {
      documents.data?.documents.forEach((item, index: number) => {
        ids[index] = item.id;
      });
    }
     
    formRef.current?.setFieldValue("document_ids", ids);
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ document_ids: [], select_all : true }}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        

        return (
          <div className="page-content">
            {(paymentState.loading || summaryState.loading) && (
              <Spinner size="large" />
            )}
            {/* <Effects /> */}
            <div className="breadcrumbs">
              Integrator <span className="arrow">»</span>
              <a href="#">Rozliczenia </a>
            </div>
            <header className="page-header">
              <h1>Rozliczenia</h1>
            </header>{" "}
            <h2 className="primary h-lg mb-10">Opłać teraz</h2>
            <TableWrapper className="mb-10">
              <Table
                size="middle"
                rowSelection={rowSelection}
                locale={{ emptyText: "Brak elementów do wyświetlenia" }}
                rowKey={(item) => item.key}
                columns={tableData.columns}
                bordered={false}
                dataSource={result}
                pagination={false}
                loading={tableData.loading}
                className={`custom-ant-table`}
              />
            </TableWrapper>
            <CostSummary
              title="Łącznie do zapłaty"
              onClick={formikProps.submitForm}
              buttonTitle="Przejdź do płatności on-line"
              price={total || 0}
              disabled={selectedIds.length === 0 ? true : false}
            />
            <div className="mb-20 i-b">
              {/* <FormButton color="primary"   className="f-right inline mb-20" onClick={formikProps.submitForm} title="Przejdź do płatności on-line" /> */}
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default withAuthSync(withLayout(PaymentSummary));
