import React from "react";
import {Link} from 'react-router-dom';
//@ts-ignore
import  "styles/components/button.scss";

export type BtnColor = "primary" | "white" | "white bold" | "danger" | "green";
interface IProps {
  testId?: string;
  link?: string;
  color: BtnColor;
  title: string;
  half?: string;
  className?: string;
  onClick?: () => any;
  inline?: boolean;
  Icon?: any;
  id?: string;
  width?: number;
  newCard? : boolean;
  outerLink? :boolean;
  disabled?: boolean;
}
const Button = (props: IProps) => {
  const {
    link,
    color,
    title,
    half,
    onClick,
    inline,
    Icon,
    className,
    testId,
    width,
    newCard,
    outerLink,
    disabled
  } = props;

  if (onClick) {
    return (
      <div
        className={`${color} button ${half} ${inline ? "inline" : ""} ${
          className || ""
        } ${disabled && "disabled"}`}
        style={width ? { width: width + "px" } : {}}
        // disabled= {disabled || false}
      >
        <a key={1} data-testid={testId} onClick= { () => { if (!disabled) {onClick()} }}>
          {Icon && <Icon />}
          {title}
        </a>
        {/* {process.env.NODE_ENV !== "test" && (
          <style jsx global>
            {s1}
          </style>
        )} */}
      </div>
    );
  } else {
    if(outerLink ){
      return (
        <div
          className={`${color} button ${half} ${inline && "inline"} ${
            className || ""
          }`}
          style={width ? { width: width + "px" } : {}}
        >
          <a target={newCard ? "_blank" : undefined} data-testid={testId} key={1} href={link ? link : "#"} rel="noreferrer">
            
            {title}
            {Icon && <Icon className="ml-10" />}
          </a>
  
          {/* {process.env.NODE_ENV !== "test" && (
            <style jsx global>
              {s1}
            </style>
          )} */}
        </div>
      );
    }
    return (
      <div
        className={`${color} button ${half} ${inline && "inline"} ${
          className || ""
        }`}
        style={width ? { width: width + "px" } : {}}
      >
        <Link target={newCard ? "_blank" : undefined} data-testid={testId} key={1} to={link ? link : "#"}>
          
          {title}
          {Icon && <Icon className="ml-10" />}
        </Link>

        {/* {process.env.NODE_ENV !== "test" && (
          <style jsx global>
            {s1}
          </style>
        )} */}
      </div>
    );
  }
};
export default Button;
