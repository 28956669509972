import React, { useRef } from "react";
import { connect, RootStateOrAny } from "react-redux";
import { Formik, FormikProps, ErrorMessage, useFormikContext, FieldArray } from "formik";
import SelectField from "components/form-fields/select-field";
import TextField from "components/form-fields/formik-text-field";
import FormButton from "components/buttons/form-button";
import DeafultButton from "components/buttons/button";
import { preloadVehicle, fetchModels } from "redux/actions/vehicles";
import { vehicleFields, payments } from "config/vehicle-fields";
import {
  renderJsonOptions,
  getYears,
  renderRangeOpts,
} from "utils/select-options";
import { Upload, message, Button, Icon } from "antd";
import FormField from "components/form-fields/form-field";
import ProductAttributes from "./product-attributes-list";
import WarningIcon from "@material-ui/icons/Warning";

import Spinner from "components/core/spinner";

import * as Yup from "yup";
import DeleteOutlinedIcon from '@material-ui/icons/RemoveCircleOutline';

import { yupRequired, VINRegexp } from "validators";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import {
  IVehicleBasicFormValues,
  IVehicleFormData,
  IVehicle,
  IEnginesFormData,
  IPowersCapacities,
  IFetchPowerCapacities,
  PreloadByVinRes,
  IPreloadByVin,
  IPowerCapacity,
  ICreateVehicleRequestData,
  ITireValidValues,
  IVehicleDataSet,
  engineTypes,
  ICapacity,
  IPower,
  ITiresRange,
} from "types/vehicle-types";
import { useAsyncAction } from "utils/async-action";
import {
  createVehicle,
  fetchBrands,
  fetchEngines,
  fetchPowerCapacities,
  fetchVehicleDataSet,
  preloadByVIN,
  fetchVehicleFormData,
  fetchTireValidValuesByWidth,
  fetchCapacities,
  fetchPowers,
  fetchVehicleRequestFormData,
  submitVehicleRequest,
} from "actions/vehicles";
import { IActionState, ActionResponse } from "types/async-types";
import { useEffect, useState } from "react";
import Select from "antd/lib/select";
import { vehicleFieldNames as names } from "config/vehicle-fields";
import CostSummary from "components/common/cost-summary";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import ErrorMsg from "components/common/error-message";
import _ from "lodash";
import { getNested } from "utils/helpers";

interface IDataStepProps {
  fetchModels: (a: string, a2: string) => void;
  toggleFilters: () => any;
  formData: IVehicleFormData;
  carModels: any;
  isLoading: boolean;
  mode?: "vin_step2" | "data";
  afterDataSubmit?: (a: ICreateVehicleRequestData) => void;
  decodedVehicle?: IVehicle;
  execFetchVehicles: (a: any) => any;
  reset: () => any;
  copiedValues?: IVehicleBasicFormValues;
}

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const tireCount = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function VehicleForm(props: IDataStepProps) {
  const {
    mode,
    isLoading,
    execFetchVehicles,
    toggleFilters,
    reset,
    copiedValues,
  } = props;

  const formikRef: any = useRef();
  const formRef: FormikProps<IVehicleBasicFormValues> = formikRef.current;

  const initialVals: IVehicleBasicFormValues = {
    vin_number: "",
    vehicle_type: "",
    brand: "",
    model: "",
    attachments: [],
    fuel_type: "",
    engine_power_kw: "",
    engine_capacity: "",
    import_date: "",
    tire_width: "",
    tire_profile_height: "",
    tire_rim_diameter: "",
    production_year: "",
    drive_type: "",
    notes: "",
    tire_count: undefined,
    vehicle_products_attributes: [],
  };

  // const [conditionWarning, setConditionWarning] = useState<boolean>(false);
  const [reDecoded, setReDecoded] = useState(false);
  const [tiresRange, setTiresRange] = useState<ITiresRange>();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [profileDisabled, setProfileDisabled] = useState(true);
  const [widthDisabled, setWidthDisabled] = useState(true);
  const [isTireCountZero, setIsTireCountZero] = useState(false);
  const [batterySelectVisible, setBatterySelectVisible] = useState(false);
  const [initialFormData, setInitialFormData] = useState<
    IVehicleBasicFormValues
  >({ vehicle_products_attributes: [] });

  const [createVehicleState, execCreateVehicle]: readonly [
    IActionState<{ data: IVehicle[] }>,
    typeof submitVehicleRequest
  ] = useAsyncAction(submitVehicleRequest);

  const [vehicleDataSetState, execFetchVehicleDataSet]: readonly [
    IActionState<{ data: IVehicleDataSet[] | [] }>,
    typeof fetchVehicleDataSet
  ] = useAsyncAction(fetchVehicleDataSet);

  const [formDataState, execFetchVehicleFormData]: readonly [
    IActionState<{ data: IVehicleFormData }>,
    typeof fetchVehicleRequestFormData
  ] = useAsyncAction(fetchVehicleRequestFormData);

  const formData = formDataState.data?.data;

  const [enginesState, execFetchEngines]: readonly [
    IActionState<IEnginesFormData>,
    any
  ] = useAsyncAction(fetchEngines);

  const [brandsState, execFetchBrands]: readonly [
    IActionState<{ data: string[]; tires: ITiresRange }>,
    any
  ] = useAsyncAction(fetchBrands);

  const [tireValues, execFetchTireValidValues]: readonly [
    IActionState<{ data: ITireValidValues }>,
    typeof fetchTireValidValuesByWidth
  ] = useAsyncAction(fetchTireValidValuesByWidth);

  const [preloadedVehicleState, execPreloadByVin]: readonly [
    IActionState<{ data: PreloadByVinRes }>,
    typeof preloadByVIN
  ] = useAsyncAction(preloadByVIN);

  const onSubmit = async (values: IVehicleBasicFormValues) => {
    const submitData: IVehicleBasicFormValues = {
      ...values,
      tires_attributes: values.tires_attributes || []
    };

    const res = await execCreateVehicle(submitData);
    if (res?.status === 200 || res?.status === 201) {
      await execFetchVehicles(null);
      toggleFilters();
    }
  };

  const resetForm = () => {
    reset();
  };

  useEffect(() => {
    execFetchVehicleFormData();
  }, []);

  useEffect(() => {
    if (preloadedVehicleState.data) {
      const fuelType = preloadedVehicleState.data.data.fuel_type;
      const vehicleType = preloadedVehicleState.data.data.vehicle_type;

      const engineTypes = preloadedVehicleState.data.data.engines_list;
      const vehicleTypes = preloadedVehicleState.data.data.vehicle_types;

      const data = {
        ...initialVals,
        import_date: formRef.values.import_date,
        vehicle_products_attributes: [],
        production_year: preloadedVehicleState.data.data.production_year,
        drive_type: preloadedVehicleState.data.data.transmission_type,
        vin_number: preloadedVehicleState.data.data.vin_number,
        brand: preloadedVehicleState.data.data.brand,
        model: preloadedVehicleState.data.data.model,
        vehicle_type: vehicleType
          ? vehicleTypes[vehicleType]
            ? vehicleType
            : "OTH"
          : "",
        fuel_type: fuelType && (engineTypes[fuelType] ? fuelType : undefined),
        engine_power_kw: preloadedVehicleState.data.data.engine_power_kw,
        engine_capacity: preloadedVehicleState.data.data.engine_capacity,
      };
      formRef.setValues(data);
      setProfileDisabled(true);
      setWidthDisabled(true);

      // setInitialFormData(data);

    }
  }, [preloadedVehicleState.data]);

  const [rimValue, setRimValue] = useState<string>("");

  useEffect(() => {
    const formRef: FormikProps<IVehicleBasicFormValues> = formikRef.current;
    if (copiedValues && formRef) {
      const data: IVehicleBasicFormValues = {
        ...initialVals,
        ...copiedValues,
      };
      formRef.setValues(data);
      setBatterySelectVisible(data.fuel_type == 'ELE' || data.fuel_type == 'HYB')
    }
  }, []);

  const validationSchema = Yup.object().shape({
    // vin_number :  Yup.string().concat(VIN) as Yup.StringSchema<string>,
    vehicle_type: Yup.string().concat(yupRequired).nullable(),
    brand: Yup.string().concat(yupRequired).nullable(),
    model: Yup.string().concat(yupRequired).nullable(),
    fuel_type: Yup.string().concat(yupRequired).nullable(),
    engine_capacity: Yup.string().concat(yupRequired).nullable(),
    engine_power_kw: Yup.string().concat(yupRequired).nullable(),
    // capacity_power: Yup.string().concat(yupRequired),
    attachments: Yup.array().test(
      "attachments",
      "Wymagany jest przynajmniej jeden plik",
      (values) => {
        if (values && values.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    ),
    tires_attributes: Yup.array()
      .of(Yup.object().shape({
        rim_diameter: Yup.string().concat(yupRequired).nullable(),
        width: Yup.string().concat(yupRequired).nullable(),
        profile_height: Yup.string().concat(yupRequired).nullable()
      })),
    import_date: Yup.string().concat(yupRequired).nullable(),
    production_year: Yup.string().concat(yupRequired).nullable(),
    drive_type: Yup.string().concat(yupRequired).nullable(),
  });

  const onChangeTiresCount = (values: IVehicleBasicFormValues, removeIndex: number) => {
    const tires_attributes = [...values.tires_attributes || []];

    if (removeIndex >= 0) {
      tires_attributes.splice(removeIndex, 1);
    } else {
      if (tires_attributes.length < 3) {
        tires_attributes.push({});
      } else {
        alert('Nie można dodać więcej niż 3 typy opon');
      }
    }
    formRef.setValues({ ...values, tires_attributes });
  }


  return (
    <Formik
      // key={componentKey}
      innerRef={formikRef}
      onSubmit={onSubmit}
      isInitialValid={false}
      validateOnChange={true}
      validateOnBlur={false}
      enableReinitialize={true}
      initialValues={initialVals}
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<IVehicleBasicFormValues>) => {
        const {
          values,
          setFieldValue,
          errors,
          validateForm,
          touched,
          isValid,
        } = formikProps;

        const decodeVin = async () => {
          const vin = values.vin_number;
          if (vin) {
            setReDecoded(true);
            const res = await execPreloadByVin(vin);

            setTimeout(() => validateForm());
          }
        };

        const handleTireCount = (value: string, index: number) => {
          const tires_attributes = [...values.tires_attributes || []];
          tires_attributes[index].count = parseInt(value);
          setIsTireCountZero(false);

          if (value === "0") {
            tires_attributes[index].profile_height = "0";
            tires_attributes[index].rim_diameter = "0";
            tires_attributes[index].width = "0";
            setIsTireCountZero(true);
          } else if (values.tire_rim_diameter === "0") {
            tires_attributes[index].profile_height = "";
            tires_attributes[index].rim_diameter = "";
            tires_attributes[index].width = "";
          }

          formRef.setValues({ ...values, tires_attributes });
        };

        const dummyRequest = ({ file, onSuccess }: any) => {
          setTimeout(() => {
            onSuccess("ok");
          }, 0);
        };

        const handleFuelType = (fuel_type: string) => {
          setBatterySelectVisible(fuel_type == 'ELE' || fuel_type == 'HYB')
        };
        //initial values for all OPTIONS variables for dropdowns //

        // as first field, it's only loaded from preloadedVehicleState or formDataState
        let vehicleTypes = preloadedVehicleState.data?.data.vehicle_types || {};

        let engines = preloadedVehicleState.data?.data.engines_list || {};

        // setting models list for dropdown options //
        // if (modelsState.data?.data && !reDecoded) {
        //   models = modelsState.data?.data;
        // } else if (preloadedVehicleState.data?.data.models_list) {
        //   models = preloadedVehicleState.data?.data.models_list;
        // }

        // setting brands list for dropdown  options //
        // if (brandsState.data?.data && !reDecoded) {
        //   brands = brandsState.data?.data;
        // } else if (preloadedVehicleState.data?.data.brands_list) {
        //   brands = preloadedVehicleState.data.data.brands_list;
        // }

        // setting engines list for dropdown options //

        // setting engines list for dropdown options //
        if (
          preloadedVehicleState.data?.data.engines_list &&
          Object.keys(preloadedVehicleState.data?.data.engines_list).length > 0
        ) {
          engines = preloadedVehicleState.data.data.engines_list;
        } else if (formData) {
          engines = formData.fuel_types;
        }



        //setting capacities list for dropdown options //
        // if (capacitiesState.data?.data && !reDecoded) {
        //   capacities = capacitiesState.data?.data;
        // } else if (preloadedVehicleState.data?.data.engine_capacities_list) {
        //   capacities = preloadedVehicleState.data?.data.engine_capacities_list;
        // }

        //setting powers list for dropdown options //
        // if (powersState.data?.data && !reDecoded) {
        //   powers = powersState.data?.data;
        // } else if (preloadedVehicleState.data?.data.engine_powers_list) {
        //   powers = preloadedVehicleState.data?.data.engine_powers_list;
        // }

        const tiresWidthOpts =
          tireValues.data?.data.widths ||
          (formData ? formData.tire_valid_values.widths : []);
        const tiresProfileHeights =
          tireValues.data?.data.profile_heights ||
          (formData ? formData.tire_valid_values.profile_heights : []);
        const tiresRimDiameter =
          tireValues.data?.data.rim_diameters ||
          (formData ? formData.tire_valid_values.rim_diameters : []);

        const filesTouched = touched.attachments;
        const filesError = errors.attachments;

        return (
          <>
            {(formDataState.loading ||
              enginesState.loading ||
              preloadedVehicleState.loading ||
              createVehicleState.loading ||
              tireValues.loading ||
              isLoading) && (
                <Spinner bgColor="grey" size="large" fixed={true} />
              )}

            <header className="sider-header">
              <h2 className="primary mb-15">Dodaj pojazd</h2>
              <p className="mb-0">
                Wprowadź nr VIN aby pobrać dane pojazdu lub wypełnij formularz
                aby wyliczyć należne opłaty
              </p>

              {mode === "vin_step2" && (
                <Row gutter={5}>
                  <Col md={18}>
                    <FormField
                      label="Numer VIN"
                      placeholder="Podaj VIN"
                      name="vin_number"
                      className="mb-0"
                      maxlength={20}
                    />
                  </Col>

                  <Col md={6}>
                    <FormButton
                      onClick={() => decodeVin()}
                      className="mt-16"
                      color="white"
                      title="Pobierz dane"
                    />
                  </Col>
                </Row>
              )}
            </header>
            <div className="sider-body">
              <Row gutter={15}>
                <Col md={12}>
                  <h4 className="mb-5 primary">Dane pojazdu</h4>
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        testId="vehicle-type"
                        {...vehicleFields.vehicleType}
                        onChange={() => formikProps.setFieldValue('tire_count', undefined)}
                        options={renderJsonOptions(
                          formData ? formData.vehicle_types : vehicleTypes
                        )}
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <TextField
                        testId="make"
                        {...vehicleFields.brand}
                        type="text"
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col md={12}>
                      <TextField
                        testId="model"
                        {...vehicleFields.model}
                        type="text"
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        testId="fuel-type"
                        {...vehicleFields.fuelType}
                        options={renderJsonOptions(engines || [])}
                        required={true}
                        onChange={handleFuelType}
                      />
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col md={12}>
                      <TextField
                        name="engine_capacity"
                        label="Pojemność"
                        type="number"
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <TextField
                        name="engine_power_kw"
                        label="Moc silnika (kW)"
                        required={true}
                        type="number"
                      />
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        name="drive_type"
                        label="Skrzynia biegów"
                        required={true}
                        options={
                          formData?.transmission_type
                            ? renderJsonOptions(formData.transmission_type)
                            : []
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        testId="vehicle-year"
                        {...vehicleFields.productionYear}
                        required={true}
                        options={renderJsonOptions(getYears())}
                      />
                    </Col>
                  </Row>
                  {(batterySelectVisible) &&
                    <Row gutter={5}>
                      <Col md={12}>
                        <TextField
                          name="ev_battery_capacity"
                          label="Bateria"
                          type="number"
                          required={true}
                        />
                      </Col>
                    </Row>}

                  <FieldArray name="tires_attributes">
                    {() => ((values.tires_attributes || []).map((item, i) => {
                      return (
                        <Row gutter={5}>
                          <Col md={6}>
                            <SelectField
                              name={`tires_attributes[${i}].count`}
                              label="Liczba opon"
                              onChange={(e) => { handleTireCount(e, i) }}
                              required={true}
                              disabled={false}
                              options={renderRangeOpts(0, 12)}
                              selectedValue={Number(item.count) >= 0 ? Number(item.count).toString() : false}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              name={`tires_attributes[${i}].rim_diameter`}
                              label="Średnica"
                              disabled={isTireCountZero}
                              required={true}
                            />
                          </Col>
                          <Col md={6}>
                            <TextField
                              name={`tires_attributes[${i}].width`}
                              label="Szerokość"
                              disabled={isTireCountZero}
                              required={true}
                            />
                          </Col>
                          <Col md={5}>
                            <TextField
                              name={`tires_attributes[${i}].profile_height`}
                              label="Profil"
                              disabled={isTireCountZero}
                              required={true}
                            />
                          </Col>
                          <Col md={1}>
                            <DeleteOutlinedIcon onClick={() => onChangeTiresCount(values, i)} />
                          </Col>
                        </Row>
                      );
                    }))}

                  </FieldArray>

                  <Row gutter={5}>
                    <DeafultButton
                      className="mb-5"
                      inline={true}
                      half=""
                      onClick={() => onChangeTiresCount(values, -1)}
                      color="white"
                      title="Dodaj opony" />
                  </Row>
                  <Row className="mb-10">
                    <Col md={12}>
                      <FormField
                        label="Data wprowadzenia pojazdu na teren RP lub wystawienia faktury"
                        name="import_date"
                        type="date"
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row className="mb-10">
                    <Col md={24}>
                      <FormField
                        label="Uwagi (Proszę uzupełnić pole jeśli nie ma odpowiednich dla Państwa pojazdu parametrów, np. konkretnych wartości dotyczących opon)"
                        name="notes"
                        required={false}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Upload
                        customRequest={dummyRequest}
                        method={undefined}
                        onRemove={(e) => { }}
                        onChange={(file) => {
                          formikProps.setFieldValue(
                            "attachments",
                            file.fileList
                          );
                        }}
                      >
                        <Button>
                          <Icon type="upload" /> Wgraj skan dowodu
                          rejestracyjnego
                        </Button>
                      </Upload>
                      <ErrorMsg name="attachments" />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            <div className="sider-footer flex-top-left">
              <div className="grow-1 t-right">
                <FormButton
                  color="primary"
                  width={200}
                  onClick={formikProps.submitForm}
                  title="Dodaj pojazd"
                  className="mb-20 mr-5"
                  inline={true}
                />
                <DeafultButton
                  width={200}
                  inline={true}
                  onClick={() => resetForm()}
                  color="white"
                  title="Wyczyść formularz"
                />
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

function mapStateToProps(state: RootStateOrAny) {
  return {
    formData: state.vehicles.form_data,
    carModels: state.vehicles.models,
  };
}

export default connect(mapStateToProps, { preloadVehicle, fetchModels })(
  VehicleForm
);
