import * as Yup from "yup";
import { required, dateRequired, yupRequired } from "validators";
import { addressValidation } from "validators/address-schema";
import _ from "lodash";

/* field name consts */

export const FIELD_NAMES = {
  CARD_NR: "card_number",
  IS_CARD: "is_card",
  DATE: "manufacture_date",
  WASTE_ADDRESS: "address_id",
  NO_CARD : "no_card_number"
};



//validation schema
export const stepsValidationSchema = (withCard: boolean ) =>  ({
  1: Yup.object().shape({
    no_card_number : Yup.string(),
    card_number: Yup.string().when(FIELD_NAMES.IS_CARD, {
      is: false,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().when(FIELD_NAMES.NO_CARD, {
        is: "true",
        then: Yup.string().notRequired(),
        otherwise: Yup.string().concat(yupRequired),
      })
    }),
    address_id: Yup.string().concat(yupRequired),
    manufacture_date: Yup.date().concat(dateRequired).test(`manufacture_date`, `Dopuszczalna jest tylko data między 01.01.2019 a dniem bieżącym`, function (
      value : any
    ) {
      
      if(value > new Date() || value < new Date('01-01-2019')){
        return false;
      }else{
        return true;
      }
    })
  }),
  // 2: Yup.object().shape({
  //   codes: Yup.object().nullable().test('codes', 'Należy dodać masę dla przynajmniej jednego kodu odpadu', function (values){
  //     
  //     return Object.keys(values).length >= 1 ? true : false;
      
  //   }).test(`codes`, `Wartość dla kodu nie może być mniejsza niż 1kg`, function (
  //     values
  //   ) {
      
  //     if(withCard){
  //       return !_.some(Object.keys(values), (key) => {
  //         if(values[key].unit == 'kg'){
  //             return (!values[key].amount ||  values[key].amount < 1 || values[key].amount == 0);
  //         }else{
  //           return (!values[key].amount ||  values[key].amount < 0.001);
  //         }
          
  //       });
  //     }else{
  //       return true;
  //     }
      
  //   })
 
    
  // })

  2: Yup.object().shape({
    codes:  Yup.object().nullable().test('codes', 'Należy dodać masę dla przynajmniej jednego kodu odpadu', function (values: any){
      if(Object.keys(values).length){
        return true;
      }
      else{
        return false;
      }
    
  })
})

})
