import { IActionState, ActionResponse } from "types/async-types";
import { IDocumentPdf } from "types/document-types";
import { downloadBDOPaymentPDF } from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";

export const useLocalFile = (id?: string | null) => {
  const [localFileState, execFetchLocalFile]: readonly [
    IActionState<IDocumentPdf>,
    typeof downloadBDOPaymentPDF
  ] = useAsyncAction(downloadBDOPaymentPDF);

  const downloadLocalFile = async (paymentId: string) => {
    if (!id) {
      return;
    }
    const res: ActionResponse<any> | undefined = await execFetchLocalFile(
      id,
      paymentId
    );
    if (res) {
      const linkSource = `data:application/pdf;base64,${res.data.pdf_base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "dokument.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }

   
    }
  
    return { localFileState, downloadLocalFile };
}



