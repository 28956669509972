import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";

import { requestHeaders } from "utils/auth";
import { IAgreementsRes } from "types/agreement-types";

const PRODUCT_AGREEMENT_URL = `${API_BASE_URL}/agreements/product_agrements`;
const NETWORK_ASSURANCE_URL = `${API_BASE_URL}/agreements/network_assurance`;
const AGREEMENT_SCANS_URL = `${API_BASE_URL}/agreements/scans`;


export const fetchProductAgreements = async () => {
    const options: AxiosRequestConfig = {
      url: `${PRODUCT_AGREEMENT_URL}`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<IAgreementsRes> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchNetworkAssurance = async () => {
    const options: AxiosRequestConfig = {
      url: NETWORK_ASSURANCE_URL,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<IAgreementsRes> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchAgreementScans = async () => {
    const options: AxiosRequestConfig = {
      url: `${AGREEMENT_SCANS_URL}`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if (res) {
      return res;
    }
  };