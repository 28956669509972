import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { requestHeaders } from "utils/auth";
import { IActionState, ActionResponse } from "types/async-types";
import { asyncAxiosWrapper } from "utils/async-action";
import {
  IBDOReport,
  IBDOWSFormData,
  IOSOP1FormValues,
  IBDOWSAccepsFormValues,
  BDOGraphNode,
  INetworkProvisionFormValues,
} from "types/BDO-report-types";
import { IOSOP1FiltersFormValues } from "pages/admin/os-op1-list";

const BDO_REPORTS_URL = `${API_BASE_URL}/bdo_reports`;
const PAYMENTS_URL = `${API_BASE_URL}/admin/payments/`;

export const downloadBDOPaymentPDF = async (id : string, paymentId : string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/payment_pdf?payment_id=${paymentId}`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;

}

export const downloadBDOReportPDF = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/report_pdf`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};


export const retryBDOReport = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/send_retry`,
    headers: requestHeaders().headers,
    method: "POST",
  };

  const res = await axios(options);
  return res;
};


export const checkBDOReportStatus = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/send_status`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const createBDOPayment = async (id: string, seller_id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/payments/online`,
    headers: requestHeaders().headers,
    method: "POST",
    params: {
      seller_id,
    },
  };

  const res = await axios(options);
  return res;
};


export const getBDOPaymentStatus = async (id: string, payment_id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/payments/online/status`,
    headers: requestHeaders().headers,
    method: "GET",
    params: {
      payment_id
    },
  };

  const res = await axios(options);
  return res;
};

export const transferPaymentSummary = async (id: string, seller_id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/form_data/payments/transfer`,
    headers: requestHeaders().headers,
    method: "GET",
    params: {
      seller_id,
    },
  };

  const res = await axios(options);
  return res;
};

export const confirmTransfer = async (files: any[], id: string, sellerId : string) => {
  const formData = new FormData();

  files.forEach((file) => {
    formData.append("confirmation[files][]", file);
  });

  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/payments/transfer`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      "content-type": "multipart/form-data",
    },
    data: formData,
    params: {
      seller_id: sellerId,
    },
    method: "POST",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const generateInvoice = async (id: string, send_mail: boolean) => {
  const options: AxiosRequestConfig = {
    url: `${PAYMENTS_URL}/${id}/create_document`,
    headers: requestHeaders().headers,
    method: "POST",
    data : {
      send: send_mail,
    }
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOAgreement = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/agreement`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const checkBDOGraph = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/check_graph`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOReport = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOReports = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOReportFormData = async (
  id: string,
  step: BDOGraphNode,
  params?: any
) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/form_data/${step}`,
    headers: requestHeaders().headers,
    method: "GET",
    params,
  };

  const res = await axios(options);
  return res;
};

export const calculateNetwork = async (
  id: string,
  params?: INetworkProvisionFormValues
) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/form_data/network/calculate`,
    headers: requestHeaders().headers,
    method: "POST",
    data: params,
  };

  const res = await axios(options);
  return res;
};

export const saveBDOReportStep = async (
  id: string,
  step: BDOGraphNode,
  params?: any
) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/${id}/save_step/${step}`,
    headers: requestHeaders().headers,
    method: "POST",
    data: params,
  };

  const res = await axios(options);
  return res;
};

export const createBDOReport = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/new`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOWSFormData = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/ws/form_data`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOWSReports = async (params?: IOSOP1FiltersFormValues) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/ws`,
    headers: requestHeaders().headers,
    method: "GET",
    params,
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOWSFile = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/ws/download_special_conditions`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const canAccept = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/ws/can_accept`,
    headers: requestHeaders().headers,
    method: "POST",
  };

  const res = await axios(options);
  return res;
};

export const acceptWS = async (data: any) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/ws/accept`,
    headers: requestHeaders().headers,
    method: "POST",
    data,
  };

  const res = await axios(options);
  return res;
};

export const checkIntegrationNotice = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/integration_notice`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export interface IAxiosWrapperConfig {
  options: AxiosRequestConfig;
  setItemState: (a: IActionState<any>) => any;
  successMessage?: string;
}
export const fetchBDORegistryData = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_REPORTS_URL}/registry_data`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const fetchBDOWSFormData2 = async (
  setItemState: (a: IActionState<{ data: IBDOWSFormData }>) => any
) => {
  const config: IAxiosWrapperConfig = {
    options: {
      url: `${BDO_REPORTS_URL}/ws/form_data`,
      headers: requestHeaders().headers,
      method: "GET",
    },
    setItemState,
  };

  const res = await asyncAxiosWrapper(config);
  return res;
};

export const fetchOSOP1FormData = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/form_data/default`,
    headers: requestHeaders().headers,
    method: "GET",
  };

  const res = await axios(options);
  return res;
};

export const submitOSOP1 = async (values: IOSOP1FormValues) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      Accept: "multipart/form-data",
    },
    method: "POST",
    data: values,
  };

  const res = await axios(options);
  const result: ActionResponse<any> = {
    ...res,
    successMessage: "Wniosek został wysłany",
  };
  return result;
};

export const fetchOSOP1Price = async () => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/price`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "GET",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchOSOP1PriceById = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}/price`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "GET",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchOSOP1Report = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "GET",
  };

  const res = await axios(options);

  if (res) {
    return res;
  }
};

export const deleteOSOP1Attachment = async (
  id: string,
  attachmentId: string
) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}/remove_attachment`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "DELETE",
    data: {
      attachment_id: attachmentId,
    },
  };

  const res = await axios(options);

  if (res) {
    return res;
  }
};

export const fetchOSOP1Reports = async (params?: IOSOP1FiltersFormValues) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "GET",
    params,
  };

  const res = await axios(options);

  const result = {
    ...res,
  };
  return result;
};

export const saveOSOP1 = async (data: IOSOP1FormValues, id?: string) => {
  const formData = new FormData();
  const submitData: { [key: string]: any } = data;

  Object.keys(submitData).map((key, index) => {
    if (key === "address_attributes") {
      for (let addressKey in submitData[key]) {
        if (submitData[key][addressKey]) {
          formData.append(
            `os_op1_report[${key}][${addressKey}]`,
            submitData[key][addressKey]
          );
        }
      }
    } else if (key === "notice_files") {
      if (data.notice_files) {
        for (let i = 0; i < data.notice_files.length; i++) {
          formData.append(
            "os_op1_report[notice_files][]",
            data.notice_files[i]
          );
        }
      }
    } else {
      formData.append(`os_op1_report[${key}]`, submitData[key]);
    }
  });

  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/save_step${id ? `?id=${id}` : ""}`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      "content-type": "multipart/form-data",
    },
    data: formData,
    method: "PUT",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
  // const result = {
  //   ...res,
  // };
  // return result;
};

export const commitOSOP1 = async (data: IOSOP1FormValues, id?: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}/commit`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    data: data,
    method: "POST",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const deleteOSOP1 = async (id?: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "DELETE",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const checkOSOP1PaymentStatus = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}/payment_status`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "GET",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const retryOSOP1Payment = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}/retry_payment`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "POST",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchOSOP1Link = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/os_op1_reports/${id}/download_link`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "GET",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};
