import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "components/buttons/button";
import { IActionState } from "types/async-types";
import {
  IBDOServices,
  IRecycleServices,
  IVehicleStats,
} from "types/dashboard-types";
import {
  fetchBDOServices,
  fetchRecyclingServices,
  fetchDashboardVehicleStats,
} from "actions/dashboard";
import { useAsyncAction } from "utils/async-action";
import { formatDateTime } from "utils/date";
import Spinner from "components/core/spinner";
import { numToCurrency } from "utils/helpers";
import ProductsList from "../services-list";
import { PAGE } from "paths";
import StatCounter from "components/common/stat-counter";

const VehiclesTile = (props: any) => {
  const [recycleServicesState, execFetchRecycleServices]: readonly [
    IActionState<IVehicleStats>,
    typeof fetchDashboardVehicleStats
  ] = useAsyncAction(fetchDashboardVehicleStats);

  useEffect(() => {
    execFetchRecycleServices();
  }, []);

  return (
    <div className="block">
      {recycleServicesState.loading && <Spinner />}
      <h2 className="primary h-lg">Moje pojazdy</h2>
      <p className="md-t mt-5 mb-10">
        Dodaj pojazdy do systemu, aby w pełni wykorzystać dostępne usługi
      </p>
      <Row className="mb-20">
        <Col span={12}>
          <StatCounter
            className="mb-20"
            title="Pojazdy"
            amount={recycleServicesState.data?.added_vehicles || null}
            suffix="zadeklarowane w bieżącym roku sprawozdawczym"
          />
          {/* <h3>Pojazdy</h3>
          <div className="text-42 ">
            <span className="green">
              {recycleServicesState.data?.added_vehicles}
            </span>
          </div> */}
          {/* <p className="sm-l-t mb-20">zadeklarowane w bieżącym roku sprawozdawczym</p> */}
          <Button
            width={200}
            color="primary"
            title="Dodaj pojazd"
            link={`${PAGE.MY_VEHICLES}?show_form=true`}
          />
        </Col>
      </Row>
    </div>
  );
};

export default VehiclesTile;
