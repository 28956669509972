import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import Checkbox from "components/form-fields/hook-form-fields/hook-check-field";
import Button from "components/buttons/button";
import FormButton from "components/buttons/form-button";
import { companyFields } from "config/company-fields";
import AddressSection from "components/common/hook-form-address-section";
import Spinner from "components/core/spinner";
import { personFields } from "config/person-fields";
import { IActionState, ActionResponse } from "types/async-types";
import {
  fetchElectronicsSecondStepFormData,
  fetchEelectronicsDeclaration,
  saveElectronicsSecondStep,
} from "actions/product-groups";
import { useAsyncAction } from "utils/async-action";
import {
  IElectronicsSecondStepFormData,
  IElectronicsSecondStepFormValues,
} from "types/products/electronics-types";
import { renderJsonOptions } from "utils/select-options";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";

const ElectronicsStep2 = (props: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const history = useHistory();
  const formMethods = useForm<IElectronicsSecondStepFormValues>({
    mode: "onChange",
    // defaultValues: { no_nip: false },
  });

  const [formDataState, execFetchFormData]: readonly [
    IActionState<IElectronicsSecondStepFormData>,
    typeof fetchElectronicsSecondStepFormData
  ] = useAsyncAction(fetchElectronicsSecondStepFormData);

  const [showDataState, execFetchShowData]: readonly [
    IActionState<IElectronicsSecondStepFormValues>,
    typeof fetchEelectronicsDeclaration
  ] = useAsyncAction(fetchEelectronicsDeclaration);

  const [saveStepData, execSaveStep]: readonly [
    IActionState<any>,
    typeof saveElectronicsSecondStep
  ] = useAsyncAction(saveElectronicsSecondStep);

  //@ts-ignore
  const noNip: boolean | undefined = formMethods.watch(`no_nip`);
  
  const onSubmit = async (data: IElectronicsSecondStepFormValues) => {
    const submitData: IElectronicsSecondStepFormValues = {
      ...data,
      declared_representants: 1,
    };
    if (id) {
      const res: ActionResponse<{ id: string }> = await execSaveStep(
        id,
        submitData
      );
      if (res.status === 200 || res.status === 201) {
        history.push(`${PAGE.ELECTRONICS_FORM_STEP_3}?id=${res.data.id}`);
      }
    }
  };

  useEffect(() => {
    const func = async () => {
      if (id) {
        await execFetchFormData(id);
        await execFetchShowData(id);
      }
    };

    func();
  }, []);

  useEffect(() => {
    const reset = formMethods.reset;
    if (formDataState.data) {
      reset({
        ...formDataState.data.electronic_declaration,
      });
    }
  }, [formDataState.data]);

  useEffect(() => {
    const reset = formMethods.reset;
    if (showDataState.data) {
      reset({
        ...showDataState.data, 
      });
    }
  }, [showDataState.data]);

  return (
    <>
      {(formDataState.loading || saveStepData.loading || showDataState.loading) && <Spinner />}
      <div className="block">
        <h1 className="mb-30">Dane działalności</h1>
        <p>
          W tej sekcji wypełnij podstawowe dane twojej działalności
          gospodarczej. Jeśli nie masz NIPu europejskiego pozostaw puste pole.
        </p>
        <Row gutter={10} className="mb-20">
          <Col md={7}>
            <TextField {...companyFields.nip} formMethods={formMethods} />
          </Col>
          <Col md={7}>
            <TextField
              name="full_name"
              label="Pełna nazwa firmy"
              formMethods={formMethods}
            />
          </Col>
        </Row>
        <Row gutter={10} type="flex">
          <Col md={6}>
            <TextField {...companyFields.regon} formMethods={formMethods} />
          </Col>
          <Col md={6}>
            <SelectField
              {...companyFields.legal_form}
              options={renderJsonOptions(formDataState.data?.legal_forms || [])}
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            <TextField
              {...companyFields.eu_nip}
              disabled={noNip ? true : false}
              formMethods={formMethods}
            />
          </Col>
          <Col md={6}>
            <Checkbox
              className="h-100 flex-left"
              name="no_nip"
              cbxLabel="Nie został nadany"
              value={true}
              isFlag={true}
              formMethods={formMethods}
            />
          </Col>
        </Row>
      </div>
      <div className="block">
        <h1 className="mb-30">Dane adresowe</h1>
        <p>
          Możesz podać adres zamieszkania lub adres siedziby firmy. Nie musi to
          być adres w którym wytwarzasz lub przechowujesz odpady - o te
          zostaniesz poproszony w kolejnych krokach wniosku rejestracyjnego BDO.
        </p>
        <AddressSection
          parentName="address_attributes"
          formMethods={formMethods}
          extended={true}
        />
      </div>
      <div className="block">
        <h1 className="mb-30">Osoby reprezentujące firmę</h1>
        <p>Informacja dotycząca osób prawnie reprezentujących firmę.</p>
        <Row>
          <Col md={6}>
            {" "}
            <TextField
              name="representants_attributes.0.name"
              label="Imię"
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            {" "}
            <TextField
              name="representants_attributes.0.surname"
              label="Nazwisko"
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            {" "}
            <TextField
              {...personFields.pesel}
              name="representants_attributes.0.pesel"
              formMethods={formMethods}
            />
          </Col>
          <Col md={6}>
            {" "}
            <SelectField
              name="representants_attributes.0.occupation"
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
              label="Stanowisko"
              options={renderJsonOptions(formDataState.data?.occupations || [])}
              formMethods={formMethods}
            />
          </Col>
        </Row>
      </div>
      <div className="block">
        <h1 className="mb-30">Dane adresowe</h1>
        <p>Podaj adres zamieszkania w/w osoby</p>
        <AddressSection
          parentName="representants_attributes.0.address_attributes"
          formMethods={formMethods}
          extended={true}
        />
      </div>
      <div className="buttons-panel">
        <div className="form-content">
          <FormButton
            width={220}
            color="primary"
            onClick={formMethods.handleSubmit(onSubmit)}
            title="Zapisz i kontynuuj"
          />
        </div>
      </div>
    </>
  );
};

export default ElectronicsStep2;
