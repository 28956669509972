import React from "react";
import { Field, ErrorMessage } from "formik";

// import Select from "react-select";
// import inputStyle from "./table-fields.module.scss";

const ErrorMsg = (msg: string) => (
  <span >{msg} </span>
);
const renderOpts = (opts:  Array<{ label: string; value: string; default?: boolean }>, disableDefault = false) => {

  const options = opts.map(
    (
      opt: { label: string; value: string; default?: boolean },
      index: number
    ) => (
      <option key={index}  data-testid={`option_${index}`} value={opt.value} selected={opt.default}>
        {opt.label}
      </option>
    )
  );
  if (!disableDefault) {
    options.unshift(
      <option
      key="default"
      data-testid={`option_placeholder`}
        value=""
        // className={inputStyle.select_placeholder}
        disabled
        selected
      >
        -- Wybierz --
      </option>
    );
  }

  return options;
};

type IProps = {
  disabled? : boolean
  name: string
  options : Array<{ label: string; value: string; default?: boolean }>
  autocomplete?:  boolean
  type? : string
  disableDefault? : boolean
  onChange?: (e: any) => void
  onKeyPress?: (e: any) => void
  placeholder? : string
  label?: string
  initialValue? : string
}

function SelectField(props: IProps ) {
  const { disabled, name, options, autocomplete, type, disableDefault } = props;
  // const onCreatableChange = (option) => {

  // }

  const customStyles = {
    placeholder: (provided: any) => {
      const color = "#000000DE";
      // const opacity = 0.9;

      return { ...provided, color };
    },
    indicatorsContainer: (base: any) => ({
      ...base,
      height: 32,
      position: "relative",
      right: -7,
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#000000DE",
      width: 32,
    }),
    control: (base : any) => ({
      ...base,
      height: 32,
      minHeight: 32,
    }),
  };

  return (
    <>
      <Field data-testid="select-field"  className="ant-input"   as="select" {...props} placeholder="--wybierz--">
        {renderOpts(options, disableDefault)}
      </Field>
      <ErrorMessage name={name} render={ErrorMsg} />
    </>
  );
}
export default SelectField;
