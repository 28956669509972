import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/buttons/button";
import LinkButton from "components/buttons/button";
import BlockIcon from "@material-ui/icons/Block";

import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import TextField from "components/form-fields/formik-text-field";
import CheckIcon from "@material-ui/icons/Check";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import Help from "@material-ui/icons/HelpOutline";
import SelectField from "components/form-fields/select-field";
import {
  fetchBDOKeysHistory,
  fetchBDOKeyFormData,
  fetchBDOKeys,
  createBDOKey,
  updateBDOKey,
  fetchBDOKeyPageFormData,
} from "actions/BDO-key";
import {
  IBDOKeyTypes,
  IBDOKeyHistoryItem,
  IBDOKey,
  IBDOKeyResFormData,
} from "types/BDO-keys-types";
import Spinner from "components/core/spinner";

import "styles/pages/bdo-key.scss";
import { renderOptions, renderJsonOptions } from "utils/select-options";
import { formatDateTime } from "utils/date";
import { yupRequired } from "validators";

import * as Yup from "yup";
import { PAGE } from "paths";
import { IDashboardProduct } from "types/dashboard-types";
import { Link } from "react-router-dom";

const ProcessFlow = () => {
  return (
    <section>
      {" "}
      <h2 className="mb-20 black">Jak wygenerować klucz API?</h2>
      <ol className="request-order">
        <li>
          <div>
            <label>
              Otwórz serwis BDO{" "}
              <a
                target="_blank"
                rel="noreferrer"
                href="https://rejestr-bdo.mos.gov.pl"
              >
                https://rejestr-bdo.mos.gov.pl
              </a>
            </label>
          </div>
        </li>
        <li>
          <div>
            <label>Zaloguj się używając profilu zaufanego.</label>
          </div>
        </li>
        <li>
          <div>
            <label>
              Wybierz podmiot (swoją firmę) dla którego chcesz wygenerować
              klucz.
            </label>
          </div>
        </li>
        <li>
          <div>
            <label>Wygeneruj nowy klucz.</label>
          </div>
        </li>
        <li>
          <div>
            <label>
              Skopiuj dane klucza (pola Client ID i Client Secret), wklej
              powyżej i zapisz.
            </label>
          </div>
        </li>
      </ol>
      <div className="md-t black t-500">Instrukcja PDF </div>
      <p>Zobacz dokładną instrukcję krok po kroku jak wygenerować klucz. </p>
      <LinkButton
        width={190}
        link={PAGE.API_KEY_INSTRUTION}
        newCard={true}
        color="white"
        title="Otwórz instrukcję"
        Icon={OpenInNewIcon}
      />
    </section>
  );
};

const NetworkProvision = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const [currentKey, setCurrentKey] = useState<IBDOKey | undefined>(undefined);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const [historyState, execFetchBDOKeysHistory]: readonly [
    IActionState<{ data: IBDOKeyHistoryItem[] }>,
    typeof fetchBDOKeysHistory
  ] = useAsyncAction(fetchBDOKeysHistory);

  const [BDOKeysState, execFetchBDOKeys]: readonly [
    IActionState<{ data: IBDOKey[] }>,
    typeof fetchBDOKeys
  ] = useAsyncAction(fetchBDOKeys);

  const [formDataState, execFetchBDOKeysFormData]: readonly [
    IActionState<IBDOKeyResFormData>,
    typeof fetchBDOKeyPageFormData
  ] = useAsyncAction(fetchBDOKeyPageFormData);

  const [createBDOKeyState, execCreateBDOKey]: readonly [
    IActionState<any>,
    typeof createBDOKey
  ] = useAsyncAction(createBDOKey);

  const [updateBDOKeyState, execUpdateBDOKey]: readonly [
    IActionState<any>,
    typeof updateBDOKey
  ] = useAsyncAction(updateBDOKey);

  useEffect(() => {
    execFetchBDOKeysFormData();
    execFetchBDOKeysHistory();
    execFetchBDOKeys();
  }, []);

  useEffect(() => {
    if (BDOKeysState.data) {
      if (BDOKeysState.data.data) {
        const result = BDOKeysState.data.data.find((item, index) => {
          return item.key_type === "INT";
        });
        if (result) {
          setCurrentKey(result);
        }
      }
    }
  }, [BDOKeysState.data]);

  useEffect(() => {
    if (createBDOKeyState.data || createBDOKeyState.data) {
      execFetchBDOKeysFormData();
      execFetchBDOKeysHistory();
      execFetchBDOKeys();
    }
  }, [createBDOKeyState.data, updateBDOKeyState.data]);

  const updateKey = (values: any) => {
    const vals = { ...values, key_type: "INT" };

    if (formDataState.data?.current_key) {
      execUpdateBDOKey(formDataState.data.current_key.uuid, vals);
    }
  };
  const addNewKey = (values: any) => {
    const vals = { ...values, key_type: "INT" };
    execCreateBDOKey(vals);
  };

  const renderHistory = () => {
    if (historyState.data) {
      return historyState.data.data.map((item, index) => {
        return (
          <tr>
            <td>{formatDateTime(item.updated_at)}</td>
            <td className="black">{item.created_by}</td>
            <td className="black">{item.client_id}</td>
            <td className="black">{item.client_secret}</td>
            <td className="black">{item.key_type_string}</td>
          </tr>
        );
      });
    }
  };
  const validationSchema = Yup.object().shape({
    client_id: Yup.string().concat(yupRequired),
    client_secret: Yup.string().concat(yupRequired),
    key_type: Yup.string().concat(yupRequired),
  });
  if (
    historyState.loading ||
    formDataState.loading ||
    BDOKeysState.loading ||
    updateBDOKeyState.loading ||
    createBDOKeyState.loading
  ) {
    return <Spinner size="large" />;
  }
  return (
    <div className="sider-page network-provision">
      <main className="page-content">
        <div className="breadcrumbs">
          <Link to={PAGE.HOME}>
            Integrator <span className="arrow">»</span>{" "}
          </Link>
          <span> Usługi BDO</span>
          <span className="arrow">»</span>
          <span className="primary-text"> Klucz API (BDO)</span>
          {siderCollapsed && (
            <div className="help-icon">
              {" "}
              <Help onClick={() => toggleSider()} />{" "}
            </div>
          )}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Klucz API (BDO)</h1>{" "}
        </header>
        {!formDataState.data?.product_available && (
          <div className="block">
            <section>
              <h1 className="red flex">
                <BlockIcon className="status-icon" /> Klucz API jest nieaktywny
              </h1>

              <p className="md-t">
                Klucz API służy firmie Integrator wyłącznie do przekazywania
                danych do systemu BDO. Nie przekazuj tego klucza osobom
                postronnym, ani innym podmiotom.{" "}
              </p>
              <p className="md-t">
                Klucz API jest nieodłączną częścią usługi „Sprawozdanie roczne
                BDO. Zaakceptuj warunki świadczenia usługi i zapisz dane klucza
                API. Uzyskaj dostęp do najnowszej usługi pozwalającej na
                składanie rocznych sprawozdań przy użyciu klucza API
                bezpośrednio do systemu BDO.
              </p>
              <Button
                link={PAGE.STEP_1}
                title="Aktywuj klucz API"
                width={200}
                color="primary"
              />
            </section>
            <ProcessFlow />
          </div>
        )}
        {formDataState.data?.product_available &&
          formDataState.data?.current_key && (
            <div className="block">
              <section>
                {formDataState.data.current_key?.is_correct
                  ? <h1 className="green flex"><CheckCircle className="status-icon" /> Klucz API jest aktywny </h1>
                  : <h1 className="red flex"><BlockIcon className="status-icon" /> Klucz API jest niepoprawny</h1>
                }
                <p className="md-t">
                  Klucz API służy firmie Integrator wyłącznie do przekazywania
                  danych do systemu BDO. Nie przekazuj tego klucza osobom
                  postronnym, ani innym podmiotom.
                </p>
                <Formik
                  initialValues={{
                    client_id: formDataState.data.current_key?.client_id,
                    client_secret:
                      formDataState.data.current_key?.client_secret,
                    key_type: formDataState.data.current_key?.key_type,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={updateKey}
                >
                  {(formikProps: FormikProps<any>) => (
                    <>
                      {/* <Row className="flex mb-20">
               <Col md={12}>
                 <TextField name="bdo_key" label="Mój klucz API (BDO)" />
               </Col>
               <Col md={6}>
                 <Button title="Aktualizuj" color="white" />
               </Col>
             </Row> */}
                      <Row className="flex">
                        <Col md={24}>
                          <TextField
                            name="client_id"
                            required={true}
                            label="Client ID"
                          />
                        </Col>
                      </Row>
                      <Row className="flex">
                        <Col md={24}>
                          <TextField
                            name="client_secret"
                            required={true}
                            label="Client Secret"
                          />
                        </Col>
                      </Row>
                      <Row className="flex">
                        <Col md={24}>
                          <SelectField
                            name="key_type"
                            disabled={true}
                            label="Typ klucza"
                            options={[{ value: "INT", label: "Integrator" }]}
                          />
                        </Col>
                      </Row>
                      <p className="sm-t grey">
                        W BDO możesz generować wiele kluczy dla różnych firm.
                        Typ klucza to pomocnicza nazwa dla Ciebie, abyś
                        zapamiętał, której firmie, jaki klucz udostępniłeś.
                      </p>
                      <Row className="flex">
                        <Col md={12}>
                          {/* <SelectField
                           required={true}
                           options={
                             formDataState.data
                               ? renderJsonOptions(formDataState.data.key_types)
                               : []
                           }
                           name="key_type"
                           label="Typ klucza"
                         /> */}
                        </Col>
                      </Row>

                      <div className="i-b mb-20">
                        <Button
                          title="Aktualizuj"
                          // disabled={!formikProps.isValid}
                          onClick={formikProps.submitForm}
                          className="f-left"
                          width={150}
                          color="primary"
                        />
                      </div>
                    </>
                  )}
                </Formik>
              </section>
              <ProcessFlow />

              {historyState.data && (
                <div>
                  <h2 className="mb-15 black">Historia zmian</h2>
                  <table className="simple-table md-t">
                    <thead>
                      <tr className="sm-l-t grey">
                        <th>Utworzono</th>
                        <th>Użytkownik</th>
                        <th>Client ID</th>
                        <th>Client Secret</th>
                        <th>Typ klucza</th>
                      </tr>
                    </thead>
                    <tbody>{renderHistory()}</tbody>
                  </table>
                </div>
              )}
            </div>
          )}
        {/* {currentKey && (
       
        )} */}

        {formDataState.data?.product_available &&
          !formDataState.data?.current_key && (
            <div className="block">
              <section>
                <h1 className="red flex">
                  <BlockIcon className="status-icon" /> Klucz API jest
                  nieaktywny
                </h1>

                <p className="md-t">
                  Klucz API służy firmie Integrator wyłącznie do przekazywania
                  danych do systemu BDO. Nie przekazuj tego klucza osobom
                  postronnym, ani innym podmiotom.{" "}
                </p>

                <Formik
                  validationSchema={validationSchema}
                  initialValues={{ key_type: "INT" }}
                  onSubmit={addNewKey}
                >
                  {(formikProps: FormikProps<any>) => (
                    <>
                      <Row className="flex">
                        <Col md={24}>
                          <TextField
                            name="client_id"
                            required={true}
                            label="Client ID"
                          />
                        </Col>
                      </Row>
                      <Row className="flex">
                        <Col md={24}>
                          <TextField
                            name="client_secret"
                            required={true}
                            label="Client Secret"
                          />
                        </Col>
                      </Row>
                      <Row className="flex">
                        <Col md={24}>
                          <SelectField
                            required={true}
                            disabled={true}
                            options={
                              formDataState.data
                                ? renderJsonOptions(
                                  formDataState.data.key_types
                                )
                                : []
                            }
                            name="key_type"
                            label="Typ klucza"
                          />
                        </Col>
                      </Row>
                      <p className="sm-t grey mb-20">
                        W BDO możesz generować wiele kluczy dla różnych firm.
                        Typ klucza to pomocnicza nazwa dla Ciebie, abyś
                        zapamiętał, której firmie, jaki klucz udostępniłeś.
                      </p>
                      <div className="i-b mb-20">
                        <Button
                          title="Zapisz"
                          // disabled={!formikProps.isValid}
                          onClick={formikProps.submitForm}
                          className="f-left"
                          width={150}
                          color="primary"
                        />
                      </div>
                    </>
                  )}
                </Formik>
              </section>
              <ProcessFlow />
            </div>
          )}
      </main>
      {!siderCollapsed && (
        <div
          className="sider"
        // collapsedWidth="0"
        >
          <div className="sm-l-t mb-20">
            Klucz API (BDO)
            <CloseIcon className="sider-close" onClick={toggleSider} />
          </div>
          <h2 className="mb-15 black">Czym jest klucz API?</h2>
          <p className="black mb-0">
            Klucz API to wygenerowany unikatowy ciąg znaków, który pozwala
            zidentyfikować użytkownika lub system.
          </p>
          <p className="black">
            W naszych usługach klucz ten pozwala przekazać dane z Integratora na
            konto konkretnego podmiotu w systemie BDO w sposób bezpieczny i
            jednocześnie identyfikujący nadawcę danych. Jest formą
            elektronicznego upoważnienia do składania sprawozdań bezpośrednio z
            systemu Integratora do systemu BDO.
          </p>
          <>
            <h2 className="black mb-20">Dlaczego warto?</h2>
            <p className="black mb-0">
              System BDO posiada Moduł Integracyjny API BDO, który umożliwia
              integrację oprogramowania zewnętrznego z systemem BDO.
            </p>
            <p className="black">
              Udostępnienie kluczy API Organizacjom oraz Podmiotom
              pośredniczącym, umożliwi im złożenie sprawozdań za Podmioty
              zarejestrowane w Rejestrze BDO, dla których są do tego
              zobowiązane. Reasumując dane wprowadzone do systemu Integrator nie
              muszą być przepisywane, ale trafiają bezpośrednio do bazy BDO
            </p>
            <p className="black">
              Więcej o integracji:
              <br />
              <div>
                <a
                  target="_blank"
                  href="https://bdo.mos.gov.pl/news/modul-integracyjny-api-bdo/"
                >
                  {" "}
                  https://bdo.mos.gov.pl/news/modul-integracyjny-api-bdo/
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://bdo.mos.gov.pl/integracja-z-bdo-api/"
                >
                  https://bdo.mos.gov.pl/integracja-z-bdo-api/
                </a>
              </div>
            </p>
            {/* <ul className="mb-30">
                <li className="flex mb-5">
                  <CheckIcon className="green mr-5" />
                  <div className="grow-1 md-t bold">
                    Rozliczenie sieci, rozliczenie recyklingu pojazdów,
                    rozliczenie kampanii edukacyjnej - wszystko w jednym miejscu
                  </div>
                </li>
                <li className="flex mb-5">
                  <CheckIcon className="green mr-5" />
                  <div className="grow-1 md-t bold">
                    Automatyczne przekazanie danych z systemu Integrator do
                    systemu BDO
                  </div>
                </li>
                <li className="flex mb-5">
                  <CheckIcon className="green mr-5" />
                  <div className="grow-1 md-t bold">
                    Możliwość składania sprawozdania częściowego dla klientów,
                    którzy mają część usług poza Integratorem
                  </div>
                </li>
                <li className="flex mb-5">
                  <CheckIcon className="green mr-5" />
                  <div className="grow-1 md-t bold">
                    Intuicyjne menu, szybki i sprawny proces od utworzenia do
                    zatwierdzenia sprawozdania
                  </div>
                </li>
                <li className="flex mb-5">
                  <CheckIcon className="green mr-5" />
                  <div className="grow-1 md-t bold">
                    System powiadomień o terminach związanych ze sprawozdaniem
                  </div>
                </li>
              </ul> */}
          </>
        </div>
      )}
    </div>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(NetworkProvision));
// export default Index;
