import React from 'react';
import Button from 'components/buttons/button';
//@ts-ignore
import  'styles/components/buttons-panel.scss'


interface IProps  {
  saveTitle : string
  cancelTitle : string
  handleSubmit : () => any
  handleCancel : () => any
}
const ButtonsPanel = (props: IProps) => {
  const {saveTitle, cancelTitle, handleSubmit, handleCancel} = props;

  return <div className="buttons-panel flex">
    <Button
        title={saveTitle}
        color="primary"
        onClick={handleSubmit}
        half='button--half'
      />
    <Button
      title={cancelTitle}
      onClick={handleCancel}
      color="white"
      half='button--half'
    />
  {/* <style jsx global> {s1} </style> */}
  </div>

}
export default ButtonsPanel;
