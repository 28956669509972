import React from "react";
import { UseFormReturn } from "react-hook-form";
import parse from "react-html-parser";
import { IError } from "types/async-types";
import { AxiosResponse } from "axios";
import { FormikProps } from "formik";
import cookie from "js-cookie";
import { IAgreementPeriod } from "types/agreement-types";
import { formatDateTime } from "./date";
import { Link } from "react-router-dom";
import { PAGE } from "paths";
import { IAccountingDocument } from "types/document-types";

export function isEmpty(value : any){
  return (value === null || value === undefined)
    
}

export function getNested(theObject: any, path: string, separator?: string) {
  try {
    separator = separator || ".";

    return path
      .replace("[", separator)
      .replace("]", "")
      .split(separator)
      .reduce(function (obj, property) {
        return obj[property];
      }, theObject);
  } catch (err) {
    return undefined;
  }
}

interface ICurrencyOpts {
  currency?: "PLN" | "EUR" | "USD";
  precision?: number;
  noRound?: boolean;
  noCurrency?: boolean;
}

export function numToCurrency(
  amount?: number | string | null,
  options?: ICurrencyOpts
) {
  if (amount === null || amount === undefined) {
    return "";
  }
  
  if (typeof amount === "string") {
    if (isNaN(parseInt(amount))) {
      return amount;
    }
    
    amount = parseInt(amount);
  }

  let currencyString = "zł";
  switch (options?.currency) {
    case "PLN":
      currencyString = "zł";
  }
  if (options?.noRound) {
    return (
      amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
        .replace(".", ",") + (options?.noCurrency ? "" : ` ${currencyString}`)
    );
  }
  return (
    amount
      .toFixed(2)
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      .replace(".", ",") + (options?.noCurrency ? "" : ` ${currencyString}`)
  );
}

export function formatNumber(
  value?: number | string | null,
  precision?: number,
  unit? :string
){

  if (value === null || value === undefined) {
    return "";
  }

  if (typeof value === "string") {
    if (isNaN(parseFloat(value))) {
      return value;
    }
    
    value = parseFloat(value);
  }

  if (precision) {
    return (
      value
      .toFixed(precision)
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      .replace(".", ",") + (unit ? ` ${unit}`: "" )
    );
  }

  return (
    value
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
      .replace(".", ",") + (unit ? ` ${unit}`: "")
  );

}

export const setFormErrors = (
  formikProps: FormikProps<any>,
  response?: AxiosResponse<{
    error_code: number;
    error_message: string;
    error_object?: {
      [key: string]: {
        label: string;
        errors: string[];
      };
    };
  }> | null
) => {
  const errorObj = response?.data.error_object;
  if (errorObj) {
    Object.keys(errorObj).map((key, index) => {
      const errorsArr = errorObj && errorObj[key].errors;
      if (errorsArr) {
        formikProps.setFieldError(key, errorsArr[0]);
      }
    });
  }
};

export const setHookFormErrors = (
  formMethods: UseFormReturn<any>,
  response?: AxiosResponse<{
    error_code: number;
    error_message: string;
    error_object?: {
      [key: string]: {
        label: string;
        errors: string[];
      };
    };
  }> | null
) => {
  const errorObj = response?.data.error_object;
  if (errorObj) {
    Object.keys(errorObj).map((key, index) => {
      const errorsArr = errorObj && errorObj[key].errors;
      if (errorsArr) {
        formMethods.setError(key, { type: "manual", message: errorsArr[0] });
      }
    });
  }
};

export interface IText {
  text: string;
  is_required?: boolean;
  extras: {
    [key: string]: {
      link: string;
      text: string;
    };
  };
}

export const renderTextWithLinks = (textItem: IText) => {
  let text = textItem.text;
  Object.keys(textItem.extras).map((key, index) => {
    text = text.replace(
      `#${key}#,`,
      `<a target="_blank" href=${textItem.extras[key].link}>&nbsp;${textItem.extras[key].text},&nbsp;</a>`
    );
    text = text.replace(
      `#${key}#`,
      `<a target="_blank" href=${textItem.extras[key].link}>&nbsp;${textItem.extras[key].text}&nbsp;</a>`
    );
  });
  return parse(text);
};

export const formatOverdue = (
  value: string | number,
  isOverdue: boolean,
  className?: string
) => {
  return (
    <div className={`no-wrap ${className || ""} ${isOverdue ? "red" : ""}`}>
      {value}
    </div>
  );
};

export const disablePopup = (name?: string) => {
  const end = new Date();
  end.setUTCHours(23, 59, 59, 999);
  const cookieName = ["disable_popup", name].filter(n => n).join('_');
  cookie.remove(cookieName);
  cookie.set(cookieName, "true", {
    expires: end,
  });
};

export const disabledPopup = (name?: string): boolean => {
  const cookieName = ["disable_popup", name].filter(n => n).join('_');
  return cookie.get(cookieName) !== "true"
};

export const renderAgreementDates = (
  startDate?: string | null,
  endDate?: string | null,
  indefinite?: boolean | null
) => {
  const start = startDate || "";

  const end = endDate || (indefinite ? "" : "nieokreślony");
  const indefiniteVal = indefinite ? "nieokreślony" : "";
  const join =
    start.length > 0 && (end?.length > 0 || indefiniteVal.length > 0)
      ? " - "
      : "";

  return `${start}${join}${end}${indefiniteVal}`;
};

export const renderBreadcrumbs = (
  items: Array<string | { text: string; link: string }>
) => {
  const result = items.map((item, index) => {
    if (items.length === index + 1) {
      return (
        <span className={items.length === index + 1 ? `primary-text` : ""}>
          {item}
        </span>
      );
    } else {
      if (typeof item === "string") {
        return [<span>{item}</span>, <span className="arrow">»</span>];
      } else {
        return [
          <Link to={PAGE.WASTE}>{item.text}</Link>,
          <span className="arrow">»</span>,
        ];
      }
    }
  });

  return (
    <>
      <Link to={PAGE.HOME}>Integrator</Link> <span className="arrow">»</span>
      {result}
    </>
  );
};

export function arrayEquals(a?: any[], b?: any[]) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

export const getTransferTitle = (items?: IAccountingDocument[] | null) => {
  if (!items) {
    return "";
  }
  return items.reduce((total, current, index) => {
    return total + `${index > 0 ? ", " : ""}${current.number}`;
  }, "");
};
