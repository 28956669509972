import React from 'react';
import Header from "components/layout/header";
import { useState, useEffect } from "react";
import Menu from "components/layout/static-menu";
import Notifications from "components/layout/notifications/notifications";
import Layout from "antd/lib/layout";
import { isMobile } from "react-device-detect";

//@ts-ignore
import  "styles/layout/layout.scss";
import Spinner from 'components/core/spinner';

// const initiallyCollapsed = () => {
//   let collapsed = false;
//   const router = useHistory();
//   if (!router) {
//     return isMobile;
//   }

//   const url = router.pathname;
//   switch (url) {
//     case `${PAGE.VEHICLE}/[id]`:
//       collapsed = true;
//       break;
//     default:
//       collapsed = isMobile;
//       break;
//   }
//   return collapsed;
// };

const withLayout = (Page : any) => {
  const MainLayout = (props : any) => {
    const [userLoading, setUserLoading] = useState(false);
    const [menuCollapsed, setMenuCollapsed] = useState(false);
    const [mobileMenuHidden, setMobileMenuHidden] = useState(true);
    const [notesCollapsed, setNotesCollapsed] = useState(true);
    const menuClick = () => {
      const collapsed = menuCollapsed ? false : true;
      setMenuCollapsed(collapsed);
    };
    
    const hamburgerClick = () => {
      const hidden = mobileMenuHidden ? false : true;
      setMobileMenuHidden(hidden);
    };

    const bellClick = () => {
      const collapsed = notesCollapsed ? false : true;
      setNotesCollapsed(collapsed);
    };

    useEffect(() => {
      setMenuCollapsed(isMobile);
      // setMobileMenuHidden(isMobile)
    }, []);

    return (
      <Layout id="layout">
        {userLoading && 
          <Spinner  size="large"/>
        }
        {/* <style jsx global>
          {layoutStyle}
        </style> */}
        <Header
          hamburgerClick={hamburgerClick}
          handleMenuClick={menuClick}
          handleBellClick={bellClick}
          setUserLoading={setUserLoading}
        />
        <Layout className="ant-layout-has-sider">
          <Menu
            menuCollapsed={menuCollapsed}
            mobileMenuHidden={mobileMenuHidden}
          />

          <Layout.Content className="content">
            <Page {...props} />
          </Layout.Content>
          <Layout.Sider
            collapsed={notesCollapsed}
           
            className="notifications"
            width="348"
            trigger="null"
            collapsible
            collapsedWidth="0"
          >
            {/* <Notifications data={[]} /> */}
            <Notifications setNotesCollapsed={setNotesCollapsed} />
          </Layout.Sider>

          {/* base styles */}
        </Layout>
      </Layout>
    );
  };

  if (Page.getInitialProps) {
    MainLayout.getInitialProps = Page.getInitialProps;
  }

  return MainLayout;
};

export default withLayout;
