import React from "react";

interface IProps {
  className? : string;
  title: string;
  suffix?: string;
  amount: number | string | null;
  color?: "heading" | "red" | "green";
}
const StatCounter = (props: IProps) => {
  const { amount, title, suffix, color, className } = props;
  return (
    <div className={className}>
      <h3 className="black t-500">{title}</h3>
      <div>
        {amount !== null && amount !== undefined ? (
          <>
            <div className={`price text-42 t-500 ${color || 'green'}`}>
              {amount}
            </div>
            {suffix && <p className="sm-l-t mb-0">{suffix}</p>}
          </>
        ) : (
          <p className="grey">Brak danych </p>
        )}{" "}
      </div>
    </div>
  );
};

export default StatCounter;
