import TextFormField from "components/form-fields/formik-text-field";
import {ISelectProps} from "components/form-fields/select-field";
import { isPostalCodeCorrect } from "validators";

type TextProps = React.ComponentProps<typeof TextFormField>;

export const FIELD = {
    ZIP_CODE: "address.postal_code",
    AUP_TYPE: "address.aup_type",
    CITY: "address.city",
    STREET: "address.street",
    POST_OFFICE: 'address.post_office',
    BUILDING_NR: "address.building_number",
    LOCUM_NR: "address.locum_number",
    VOIVODESHIP: "address.voivodeship",
  };

export const aupTypeOpts = [
  { value: "-", label: "-" },
  { value: "ul.", label: "Ulica" },
  { value: "pl.", label: "Plac" },
  { value: "al.", label: "Aleja" },
  { value: "os.", label: "Osiedle" },
];

export const addressFields: {
  companyName: TextProps;
  street: TextProps;
  locum: TextProps;
  building: TextProps;
  zipCode : TextProps;
  city : TextProps;
  post_office : TextProps;
  country : ISelectProps;
  voivodeship : ISelectProps;
  aupType : ISelectProps;
} = {
  companyName:{
    label: "Nazwa odbiorcy",
    name: "company_name",
  },
  street: {
    label: "Ulica",
    name: "street",
  },
  locum: {
    label: "Nr lokalu",
    name: "locum_number",
  },
  building: {
    label: "Nr budynku",
    name: "building_number",
  },
  zipCode: {
    name: "postal_code",
    label: "Kod pocztowy",
    type: "text",
    maxlength: 6,
    validate : isPostalCodeCorrect
    
  },
  city : {
      label : "Miejscowość",
      name : "city"
  },
  post_office : {
    label : "Poczta",
    name : 'post_office'
  },
  country : {
    options : [],
    name : 'country',
    label : "Kraj rejestracji firmy",
    type : 'select',
  },
  voivodeship : {
    options: [
      { value: "dolnośląskie", label: "dolnośląskie" },
      { value: "kujawsko-pomorskie", label: "kujawsko-pomorskie" },
      { value: "lubelskie", label: "lubelskie" },
      { value: "lubuskie", label: "lubuskie" },
      { value: "łódzkie", label: "łódzkie" },
      { value: "małopolskie", label: "małopolskie" },
      { value: "mazowieckie", label: "mazowieckie" },
      { value: "opolskie", label: "opolskie" },
      { value: "podkarpackie", label: "podkarpackie" },
      { value: "podlaskie", label: "podlaskie" },
      { value: "pomorskie", label: "pomorskie" },
      { value: "śląskie", label: "śląskie" },
      { value: "świętokrzyskie", label: "świętokrzyskie" },
      { value: "warmińsko-mazurskie", label: "warmińsko-mazurskie" },
      { value: "wielkopolskie", label: "wielkopolskie" },
      { value: "zachodniopomorskie", label: "zachodniopomorskie" },
    ],
    name : 'voivodeship',
    label : "Województwo",
    type : 'select',
  },
  aupType : {
    type : "select",
    options : aupTypeOpts,
    label : "Typ adresu",
    name : "aup_type"
    
  }
};

