import React from "react";
import "styles/layout/saldo.scss";
import Statistic from "antd/lib/statistic";
import { IUser } from "types/user-types";
import { useSelector } from "react-redux";
import { numToCurrency } from "utils/helpers";
import { PAGE } from "paths";
import { Link } from "react-router-dom";

const Saldo = () => { 

  const user :IUser = useSelector((state: any) => state.user);

  const ballance : number | undefined = user.ballance;
 return ( <Link to={PAGE.SETTLEMENTS} className="saldo flex">
    <img src="/static/img/wallet.svg" alt="portfel" className="icon" />
    <div>
      <div className="sm-t">Bieżące saldo</div>
      <div className={`t-500 ${(ballance || 0) < 0 ? 'red' : ""}`}>{numToCurrency(ballance)}</div>
    </div>
  </Link>)
};

export default Saldo;
