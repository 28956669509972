import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import TextField from "components/form-fields/formik-text-field";
import { addressFields, FIELD } from "config/form-config/address-config";
import SelectField from "components/form-fields/select-field";
import { checkPostalCode } from "actions/addresses";
import { useAsyncAction } from "utils/async-action";
import { useFormikContext } from "formik";
import { zipCodeRegExp } from "validators";

import _ from "lodash";
import Spinner from "components/core/spinner";

interface IProps {
  parentName?: string;
  extended?: boolean;
}

const renderTestFields = () => {
  let result = [];
  for(let i = 0; i < 100; i ++)
  {
      result.push(<TextField name={`test_${i}`} label="test field"  />)
  }
  return <div>{result}</div>
}

const AddressSection = (props: IProps) => {
  const formikProps = useFormikContext<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [cityOpts, setCityOpts] = useState<Array<any>>([]);
  const [streetOpts, setStreetOpts] = useState<Array<any>>([]);
  const [communeOpts, setCommuneOpts] = useState<Array<any>>([]);
  const [poviatOpts, setPoviatOpts] = useState<Array<any>>([]);
  const [postalOfficeOpts, setPostalOfficeOpts] = useState<Array<any>>([]);
  const [postalCodeData, execCheckPostalCode]: readonly [
    any,
    typeof checkPostalCode
  ] = useAsyncAction(checkPostalCode);
  const { setFieldValue, setFieldTouched, values } = formikProps;

  const { parentName, extended } = props;
  const namePrefix = parentName ? `${parentName}.` : "";

  const preloadAddress = (event: { target: { value: string } }) => {
    const text = event.target.value;
    setFieldValue(
      `${namePrefix}${addressFields.zipCode.name}`,
      event.target.value
    );
    setFieldTouched(FIELD.ZIP_CODE, true, false);
    if (zipCodeRegExp.test(text)) {
      setTimeout(async function () {
        setIsLoading(true);
        setStreetOpts([]);
        setCityOpts([]);
        const res = await execCheckPostalCode(text);
    
        // else{
        //   errorMessage('Brak danych adresowych dla podanego kodu')
        // }
        setIsLoading(false);
      }, 500);
    }
  };

useEffect(() => {
  const res = postalCodeData;
  if (res.data && res.data.length) {
    res.data.map((item: any, index: number) => {
      setStreetOpts((oldArray: Array<any>) => [
        ...oldArray,
        { value: item.street, label: item.street },
      ]);

      setCityOpts((oldArray: Array<any>) => [
        ...oldArray,
        { value: item.city, label: item.city },
      ]);

      setPostalOfficeOpts((oldArray: Array<any>) => [
        ...oldArray,
        { value: item.post_office, label: item.post_office },
      ]);
    });

    setCityOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));
    setPostalOfficeOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));
    
    setFieldValue(`${namePrefix}post_office`, res.data[0].post_office);
    setFieldValue(`${namePrefix}city`, res.data[0].city);
    setFieldValue(`${namePrefix}street`, res.data[0].street);
    setFieldValue(`${namePrefix}voivodeship`, res.data[0].voivodeship);
    if(extended){
      setFieldValue(`${namePrefix}poviat`, res.data[0].poviat);
      setFieldValue(`${namePrefix}commune`, res.data[0].commune);
    }
    
  }
}, [postalCodeData.data])


  return (
    <>
      {isLoading && <Spinner />}
      {/* {renderTestFields()}  */}
      <Row gutter={10}>
        <Col md={4}>
          <TextField
            {...addressFields.zipCode}
            required={true}
            name={`${namePrefix}${addressFields.zipCode.name}`}
            onChange={preloadAddress}
          />
        </Col>
        <Col md={8}>
          <TextField
            {...addressFields.post_office}
            name={`${namePrefix}${addressFields.post_office.name}`}
            // type="select"
            // options={postalOfficeOpts}
            required={true}
          />
        </Col>
      </Row>
   
      <Row gutter={10}>
        <Col  md={6}>
      <TextField
        {...addressFields.city}
        name={`${namePrefix}${addressFields.city.name}`}
        // type="select"
        // options={cityOpts}
        required={true}
      />
      </Col>
        <Col md={4}>
          <SelectField
            {...addressFields.aupType}
            name={`${namePrefix}${addressFields.aupType.name}`}
          />
        </Col>
        <Col md={8}>
          <TextField
            {...addressFields.street}
            name={`${namePrefix}${addressFields.street.name}`}
            disabled={values.aup_type === "-" ? true : false}
            // type="select"
            // options={streetOpts}
          />
        </Col>
        <Col md={3}>
          <TextField
            {...addressFields.building}
            name={`${namePrefix}${addressFields.building.name}`}
            required={true}
          />
        </Col>
        <Col md={3}>
          <TextField
            {...addressFields.locum}
            name={`${namePrefix}${addressFields.locum.name}`}
            required={true}
          />
        </Col>
      </Row>
   
      {extended && (
        <Row gutter={10}>
          <Col md={8}>
            <TextField
            // options={[]}
              label="Gmina"
              name={`${namePrefix}commune`}
            />
          </Col>
          <Col md={8}>
            <TextField
            // options={[]}
             label="Powiat"
               name={`${namePrefix}poviat`}

               
              
            />
          </Col>
          <Col md={8}>
          <SelectField
        {...addressFields.voivodeship}
        name={`${namePrefix}${addressFields.voivodeship.name}`}
        required={true}
            />
          </Col>
        </Row>
      )}
      {!extended &&
        <SelectField
        {...addressFields.voivodeship}
        name={`${namePrefix}${addressFields.voivodeship.name}`}
        required={true}
      />
      }
      
    </>
  );
};

export default AddressSection;
