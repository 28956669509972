import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import { IActionState } from "types/async-types";
import {
  IWasteAdmin,
  IProduct,
  IBDOServices,
  IRecycleServices,
  IDashboardProduct,
} from "types/dashboard-types";
import {
  fetchWasteAdministration,
  fetchBDOServices,
  fetchRecyclingServices,
} from "actions/dashboard";
import { useAsyncAction } from "utils/async-action";
import { formatDateTime } from "utils/date";

import Spinner from "components/core/spinner";
import { numToCurrency } from "utils/helpers";
import ProductsList from "../services-list";
import ServicesList from "../services-list";

const WasteAdmin = (props: any) => {
  const [recycleServicesState, execFetchRecycleServices]: readonly [
    IActionState<IDashboardProduct>,
    typeof fetchRecyclingServices
  ] = useAsyncAction(fetchRecyclingServices);

  useEffect(() => {
    execFetchRecycleServices();
  }, []);

  return (
    <div className="block">
      {recycleServicesState.loading && <Spinner />}
      <h2 className="primary h-lg">Opłaty produktowe</h2>
      <p className="md-t mb-20">
        Zapewniamy wyliczanie opłat produktowych oraz pomagamy w rozliczeniach i
        sprawozdaniach rocznych.
      </p>
       <div className="mb-20">
        <ServicesList products={recycleServicesState.data?.products} />
      </div>
      {recycleServicesState.data?.agreement_button && (
        <Button
          width={200}
          color="primary"
          className="mr-10"
          inline={true}
          title="Uzyskaj dostęp do usług"
          outerLink={true}
          newCard={true}
          link={recycleServicesState.data.agreement_url}
        />
      )}
    </div>
  );
};

export default WasteAdmin;
