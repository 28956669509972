import React, { useRef, SetStateAction, Dispatch } from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
  Formik,
  FormikProps,
  ErrorMessage,
  useFormikContext,
  setNestedObjectValues,
  FieldArray
} from "formik";
import SelectField from "components/form-fields/select-field";
import FormButton from "components/buttons/form-button";
import { preloadVehicle, fetchModels } from "redux/actions/vehicles";
import { vehicleFields, payments } from "config/vehicle-fields";
import {
  renderJsonOptions,
  getYears,
  renderRangeOpts,
} from "utils/select-options";

import FormField from "components/form-fields/form-field";
import ProductAttributes from "./product-attributes-list";
import WarningIcon from "@material-ui/icons/Warning";

import Spinner from "components/core/spinner";

import * as Yup from "yup";

import { yupRequired, VINRegexp } from "validators";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import DeleteOutlinedIcon from '@material-ui/icons/RemoveCircleOutline';

import {
  IVehicleBasicFormValues,
  IVehicleFormData,
  IVehicle,
  IEnginesFormData,
  IPowersCapacities,
  IFetchPowerCapacities,
  PreloadByVinRes,
  IPreloadByVin,
  IPowerCapacity,
  ICreateVehicleRequestData,
  ITireValidValues,
  IVehicleDataSet,
  engineTypes,
  ICapacity,
  IPower,
  ITiresRange,
  IVehicleProductAttribute,
  ITireItem,
} from "types/vehicle-types";
import { useAsyncAction } from "utils/async-action";
import {
  createVehicle,
  fetchBrands,
  fetchEngines,
  fetchPowerCapacities,
  fetchBatteryCapacities,
  fetchVehicleDataSet,
  preloadByVIN,
  fetchVehicleFormData,
  fetchTireValidValuesByWidth,
  fetchCapacities,
  fetchPowers,
} from "actions/vehicles";
import { IActionState } from "types/async-types";
import { useEffect, useState } from "react";
import { vehicleFieldNames as names } from "config/vehicle-fields";
import CostSummary from "components/common/cost-summary";
import ErrorMsg from "components/common/error-message";
import _ from "lodash";
import { setFormErrors } from "utils/helpers";
import Button from "components/buttons/button";

interface IDataStepProps {
  fetchModels: (a: string, a2: string) => void;
  toggleFilters: () => any;
  formData: IVehicleFormData;
  carModels: any;
  isLoading: boolean;
  mode?: "vin_step2" | "data";
  afterDataSubmit?: (a: ICreateVehicleRequestData) => void;
  decodedVehicle?: IVehicle;
  execFetchVehicles: (a: any) => any;
  reset: () => any;
  openDraftForm: (a: IVehicleBasicFormValues) => any;
}

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

function VehicleForm(props: IDataStepProps) {
  const {
    fetchModels,
    mode,
    isLoading,
    execFetchVehicles,
    toggleFilters,
    reset,
    openDraftForm,
  } = props;

  const formikRef: any = useRef();
  const formRef: FormikProps<IVehicleBasicFormValues> = formikRef.current;
  const initialVals = {
    vehicle_products_attributes: [],
    vin_number: "",
    vehicle_type: "",
    brand: "",
    model: "",
    fuel_type: "",
    engine_power_kw: "",
    engine_capacity: "",
    import_date: "",
    // tire_width: "",
    // tire_profile_height: "",
    // tire_rim_diameter: "",
    production_year: "",
    drive_type: "",
    tires_attributes: []
  };

  const [requiredVals, setRequiredVals] = useState<string[]>([]);
  const [rimValue, setRimValue] = useState<string>("");
  // const [conditionWarning, setConditionWarning] = useState<boolean>(false);
  const [reDecoded, setReDecoded] = useState(false);
  const [products, setProducts] = useState<IVehicleProductAttribute[]>();
  const [productsSum, setProductsSum] = useState<number>();
  const [profileDisabled, setProfileDisabled] = useState(true);
  const [widthDisabled, setWidthDisabled] = useState(true);
  const [isTireCountZero, setIsTireCountZero] = useState(false);
  const [batterySelectVisible, setBatterySelectVisible] = useState(false);
  const [initialFormData, setInitialFormData] = useState<
    IVehicleBasicFormValues
  >(initialVals);

  const [createVehicleState, execCreateVehicle]: readonly [
    IActionState<{ data: IVehicle[] }>,
    typeof createVehicle
  ] = useAsyncAction(createVehicle);

  const [vehicleDataSetState, execFetchVehicleDataSet]: readonly [
    IActionState<{ data: IVehicleDataSet[] | [] }>,
    typeof fetchVehicleDataSet
  ] = useAsyncAction(fetchVehicleDataSet);

  const [formDataState, execFetchVehicleFormData]: readonly [
    IActionState<{ data: IVehicleFormData }>,
    typeof fetchVehicleFormData
  ] = useAsyncAction(fetchVehicleFormData);

  const formData = formDataState.data?.data;

  const [modelsState, execFetchModels]: readonly [
    IActionState<{ data: string[] }>,
    any
  ] = useAsyncAction(fetchModels);

  const [brandsState, execFetchBrands]: readonly [
    IActionState<{ data: string[]; tires: ITiresRange }>,
    any
  ] = useAsyncAction(fetchBrands);

  const [enginesState, execFetchEngines]: readonly [
    IActionState<IEnginesFormData>,
    any
  ] = useAsyncAction(fetchEngines);

  const [capacitiesState, execFetchCapacities]: readonly [
    IActionState<{ data: { [key: string]: string } }>,
    typeof fetchCapacities
  ] = useAsyncAction(fetchCapacities);

  const [powersState, execFetchPowers]: readonly [
    IActionState<{ data: { [key: string]: string } }>,
    typeof fetchPowers
  ] = useAsyncAction(fetchPowers);

  const [batteryCapacitiesState, execFetchBatteries]: readonly [
    IActionState<{ [key: string]: string }>,
    typeof fetchBatteryCapacities
  ] = useAsyncAction(fetchBatteryCapacities);

  const [tireValues, execFetchTireValidValues]: readonly [
    IActionState<{ data: ITireValidValues }>,
    typeof fetchTireValidValuesByWidth
  ] = useAsyncAction(fetchTireValidValuesByWidth);

  const [preloadedVehicleState, execPreloadByVin]: readonly [
    IActionState<{ data: PreloadByVinRes }>,
    typeof preloadByVIN
  ] = useAsyncAction(preloadByVIN);

  const onSubmit = async (values: IVehicleBasicFormValues) => {
    if (vehicleDataSetState.data) {
      // const productAttributesArray = values.
      const submitData: ICreateVehicleRequestData = {
        ...values,
        dataset_uuid: vehicleDataSetState.data.data[0].uuid,
        vehicle_products: values.vehicle_products_attributes || [],
        tires_attributes: values.tires_attributes || []
      };

      const res = await execCreateVehicle(submitData);
      if (res?.status === 200 || res?.status === 201) {
        await execFetchVehicles(null);
        toggleFilters();
      }
    }
  };

  const resetForm = () => {
    reset();
  };

  const getDataSets = async (
    onChangeValues?: IVehicleBasicFormValues,
    needCompleteForm?: boolean,
    id?: string
  ) => {
    if (id) {
      execFetchVehicleDataSet({}, id);
      return;
    }

    const formRef: FormikProps<IVehicleBasicFormValues> = formikRef.current;
    const formValues = formRef.values;
    let submitData = {
      ...formValues,
      ...onChangeValues,
    };
    
    if (needCompleteForm ) {
      if (
        !(
          submitData.brand &&
          submitData.model &&
          submitData.vehicle_type &&
          submitData.fuel_type &&
          submitData.engine_capacity &&
          submitData.engine_power_kw &&
          // submitData.drive_type &&
          // submitData.tire_count  &&
          // submitData.tire_rim_diameter &&
          // submitData.tire_profile_height &&
          // submitData.ev_battery_capacity &&
          !isTireCountZero
        )
      ) {
        return;
      }
    }

    if (!(submitData.engine_capacity && submitData.engine_power_kw)) {
      return;
    }

    execFetchVehicleDataSet(submitData);
  };

  useEffect(() => {
    execFetchVehicleFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formData) {
      setProducts(formData.products);
    }
  }, [formData]);

  useEffect(() => {
    if (vehicleDataSetState.data && vehicleDataSetState.data.data[0]) {
      const dataSet = vehicleDataSetState.data.data[0];

      setProducts(dataSet.products);
      setProductsSum(dataSet.products_sum);

      //updating product values
      const vals: string[] = [];
      dataSet.products.map((item) => {
        if (item.product_attributes.mandatory_order || item.is_selected) {
          if (item.is_selected) {
            vals.push(`${item.uuid}`);
          }
        }
      });
      setRequiredVals(vals);
      formRef.setFieldValue("vehicle_products_attributes", vals);

      if(formRef.values.tires_attributes && formRef.values.tires_attributes.length <= 1) {
        const tires: ITireItem[] = [];
        const defaultCount = (formRef.values.tires_attributes && formRef.values.tires_attributes[0]?.count) || dataSet.tire?.min_count 
        tires.push({
          count: (Number(defaultCount) >= 0) ? defaultCount : 0,
          width: (formRef.values?.tires_attributes[0]?.width) || dataSet.tire?.width, 
          profile_height: (formRef.values.tires_attributes && formRef.values.tires_attributes[0]?.profile_height) || dataSet.tire?.profile_height, 
          rim_diameter: (formRef.values.tires_attributes && formRef.values.tires_attributes[0]?.rim_diameter) || dataSet.tire?.rim_diameter
        });
        formRef.setFieldValue("tires_attributes", tires);
      }

      if (dataSet.base_fuel == 'ELE' || dataSet.base_fuel == 'HYB') {
        setBatterySelectVisible(true)
        execFetchBatteries(dataSet.uuid)   
        if (!formRef.values.ev_battery_capacity) {formRef.setFieldValue("ev_battery_capacity", dataSet.battery.high_voltage_capacity)}
      } else {
        setBatterySelectVisible(false)
      }

      setTimeout(() => formRef.validateForm());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleDataSetState.data]);


  useEffect(() => {
    if (preloadedVehicleState.data) {
      const fuelType = preloadedVehicleState.data.data.fuel_type;
      const vehicleType = preloadedVehicleState.data.data.vehicle_type;
      const capacity = preloadedVehicleState.data.data.engine_capacity;
      const power = preloadedVehicleState.data.data.engine_power_kw;

      const vehicleTypes = preloadedVehicleState.data.data.vehicle_types;
      const engineTypes = preloadedVehicleState.data.data.engines_list;
      const capacities = preloadedVehicleState.data.data.engine_capacities_list;
      const powers = preloadedVehicleState.data.data.engine_powers_list;
      const batteries = preloadedVehicleState.data.data.ev_battery_capacities

      const data = {
        ...initialVals,
        import_date: formRef.values.import_date,
        production_year: preloadedVehicleState.data.data.production_year,
        drive_type: preloadedVehicleState.data.data.transmission_type,
        vin_number: preloadedVehicleState.data.data.vin_number,
        brand: preloadedVehicleState.data.data.brand,
        model: preloadedVehicleState.data.data.model,
        vehicle_type: vehicleType
          ? vehicleTypes[vehicleType]
            ? vehicleType
            : "OTH"
          : "",
        fuel_type: fuelType && (engineTypes[fuelType] ? fuelType : undefined),
        engine_power_kw: power && (powers[power] ? power : undefined),
        engine_capacity:
          capacity && (capacities[capacity] ? capacity : undefined),
      };
      formRef.setValues(data);
      setProfileDisabled(true);
      setWidthDisabled(true);

      setInitialFormData(data);

      if (data.fuel_type) {
        execFetchCapacities(
          data.vehicle_type,
          data.brand,
          data.model,
          data.fuel_type
        );
      }
    }
  }, [preloadedVehicleState.data]);

  const renderVehicleProductOpts = () => {
    // let products =
    //   vehicleDataSetState.data && vehicleDataSetState.data?.data.length > 0
    //     ? vehicleDataSetState.data.data[0].products
    //     : formDataState.data?.data.products;

    if (products) {
      return products.map((item, index) => {
        const isDisabled =
          !item.product_attributes.trade_agreement ||
          item.product_attributes.mandatory_order;

        // if (!item.product_attributes.trade_agreement) {
        //   setWarning(true);
        // }

        return {
          cbxLabel: item.name,
          value: item.uuid,

          // checked : item.is_selected || undefined,
          disabled: isDisabled || !vehicleDataSetState.data,
          name: "vehicle_products_attributes",
          // icon: !item.product_attributes.trade_agreement
          //   ? WarningIcon
          //   : undefined,
          additionalAttributes: {
            price: item.price,
            unit: item.product_attributes.unit,
            weight: item.weight,
            hasAgreement: item.product_attributes.trade_agreement,
            hasSubscription: item.product_attributes.has_subscription,
          },
        };
      });
    } else {
      return [];
    }
  };

  const validationSchema = Yup.object().shape({
    // vin_number :  Yup.string().concat(VIN) as Yup.StringSchema<string>,
    vehicle_type: Yup.string().concat(yupRequired).nullable(),
    brand: Yup.string().concat(yupRequired).nullable(),
    model: Yup.string().concat(yupRequired).nullable(),
    fuel_type: Yup.string().concat(yupRequired).nullable(),
    engine_capacity: Yup.string().concat(yupRequired).nullable(),
    engine_power_kw: Yup.string().concat(yupRequired).nullable(),
    // capacity_power: Yup.string().concat(yupRequired),
    vehicle_products_attributes: Yup.array().test(
      "vehicle_products_attributes",
      "Wymagane produkty nie są zaznaczone",
      (values) => {
        if (values && requiredVals.every((v) => values.includes(v))) {
          return true;
        } else {
          return false;
        }
      }
    ),
    import_date: Yup.string().concat(yupRequired).nullable(),
    production_year: Yup.string().concat(yupRequired).nullable(),
    drive_type: Yup.string().concat(yupRequired).nullable(),
    // tires_attributes: Yup.array().of(Yup.object().shape({
    //   count: Yup.string().concat(yupRequired).nullable(),
    //   profile_height: Yup.string().concat(yupRequired).nullable(),
    //   rim_diameter: Yup.string().concat(yupRequired).nullable(),
    //   width: Yup.string().concat(yupRequired).nullable()
    // })).test(
    //   "tires_attributes",
    //   "Niepełne dane opon",
    //   (values) => {
    //     return false;
    //   }
    // )

    // tire_count: Yup.string().concat(yupRequired).nullable(),
    // tire_width: Yup.string().concat(yupRequired).nullable(),
    // tire_rim_diameter: Yup.string().concat(yupRequired).nullable(),
    // ev_battery_capacity: Yup.string().concat(yupRequired).nullable(),
  });

  const Effects = () => {
    const formProps = useFormikContext<IVehicleBasicFormValues>();
    const myPreviousState = usePrevious(formProps.values);

    useEffect(() => {
      if (myPreviousState && !_.isEqual(myPreviousState, formProps.values)) {
        getDataSets(undefined, false);
      }

      setTimeout(() => formProps.validateForm);
    }, [formProps.values.vehicle_products_attributes]);

    return null;
  };

  useEffect(() => {
    const formRef: FormikProps<IVehicleBasicFormValues> = formikRef.current;
    setFormErrors(formRef, createVehicleState.response);
  }, [createVehicleState.response]);

  const onChangeTiresCount = (values: IVehicleBasicFormValues, removeIndex: number) => {
    const tires_attributes = [...values.tires_attributes || []];

    if (removeIndex >= 0) {
      tires_attributes.splice(removeIndex, 1);
      getDataSets({ tires_attributes: tires_attributes }, true);
    } else {
      if(tires_attributes.length < 3) {
        tires_attributes.push({ });
      } else {
        alert('Nie można dodać więcej niż 3 typy opon');
      }
    }
    formRef.setValues({ ...values, tires_attributes });
  }

  const tiresOptions = new Map<string, ITireValidValues>();

  return (
    <Formik
      // key={componentKey}
      innerRef={formikRef}
      onSubmit={onSubmit}
      isInitialValid={false}
      enableReinitialize={true}
      initialValues={
        initialFormData || {
          registration_status: "registered",
          vehicle_products_attributes: requiredVals,
        }
      }
      validationSchema={validationSchema}
    >
      {(formikProps: FormikProps<IVehicleBasicFormValues>) => {
        const {
          values,
          setFieldValue,
          errors,
          validateForm,
          isValid,
        } = formikProps;

        const cleanProducts = () => {
          setFieldValue("vehicle_products_attributes", []);
          setRequiredVals([]);
          setProducts(formData?.products);
          setProductsSum(0);
        };

        const decodeVin = async () => {
          cleanProducts();
          const vin = values.vin_number;
          if (vin) {
            setReDecoded(true);
            const res = await execPreloadByVin(vin);
            const datasetId = res?.data?.data?.dataset_uuid;
            if (datasetId) {
              await getDataSets(undefined, undefined, datasetId);
            }

            setTimeout(() => validateForm());
          }
        };

        const handleVehicleType = (vehicleType: string) => {
          setTimeout(() => formikProps.validateForm());
          setReDecoded(false);

          setFieldValue("brand", "");
          setFieldValue("model", "");
          setFieldValue(names[names.fuel_type], "");
          setFieldValue("engine_capacity", "");
          setFieldValue("engine_power_kw", "");
          setFieldValue("ev_battery_capacity", "");

          setBatterySelectVisible(false)

          // setFieldValue("tire_count", "");
          // setFieldValue("tire_profile_height", "");
          // setFieldValue("tire_rim_diameter", "");
          // setFieldValue("tire_width", "");

          setIsTireCountZero(false);

          cleanProducts();
          execFetchBrands(vehicleType);

          // setFieldValue("tire_count", tires?.from);
        };

        const handleBrand = (brand: string) => {
          setReDecoded(false);
          let vehicleType = values.vehicle_type;
          if (!vehicleType) {
            vehicleType = "";
          }
          setFieldValue(names[names.model], "");
          setFieldValue(names[names.fuel_type], "");

          setFieldValue("engine_capacity", "");
          setFieldValue("engine_power_kw", "");
          setFieldValue("ev_battery_capacity", "");

          setBatterySelectVisible(false)

          execFetchModels(brand, vehicleType);
          cleanProducts();
          setTimeout(() => formikProps.validateForm());
        };

        const handleModel = (model: string) => {
          setReDecoded(false);
          let vehicleType = values.vehicle_type;
          let brand = values.brand;
          setFieldValue(names[names.fuel_type], "");
          // setFieldValue("capacity_power", undefined);
          setFieldValue("engine_capacity", "");
          setFieldValue("engine_power_kw", "");

          setBatterySelectVisible(false)

          execFetchEngines(vehicleType, brand, model);
          cleanProducts();
          setTimeout(() => formikProps.validateForm());
        };

        const handleEngineType = (engineType: string) => {
          setReDecoded(false);
          const vehicleType = values.vehicle_type;
          const brand = values.brand;
          const model = values.model;
          // setFieldValue("capacity_power", undefined);
          setFieldValue("engine_capacity", "");
          setFieldValue("engine_power_kw", "");
          setFieldValue("ev_battery_capacity", "");

          setBatterySelectVisible(false)

          cleanProducts();
          setTimeout(() => formikProps.validateForm());

          if (vehicleType && brand && model && engineType) {
            // execFetchPowerCapacities(vehicleType, brand, model, engineType);
            execFetchCapacities(vehicleType, brand, model, engineType);
          }
        };

        const handleTireCount = (value: string, index: number) => {
          const tires_attributes = [...values.tires_attributes || []];
          tires_attributes[index].count = parseInt(value);
          setIsTireCountZero(false);          

          if (value === "0") {
            tires_attributes[index].profile_height = "0";
            tires_attributes[index].rim_diameter = "0";
            tires_attributes[index].width = "0";
            setIsTireCountZero(true);
          } 
          // else if (values.tire_rim_diameter === "0") {
          //   tires_attributes[index].profile_height = "";
          //   tires_attributes[index].rim_diameter = "";
          //   tires_attributes[index].width = "";
          // }

          formRef.setValues({ ...values, tires_attributes });          

          if(tires_attributes[index].profile_height && tires_attributes[index].rim_diameter && tires_attributes[index].width) {
            getDataSets({ tires_attributes: values.tires_attributes }, true);
          }
        };

        const handleCapacity = (capacity: string) => {
          setReDecoded(false);
          const vehicleType = values.vehicle_type;
          const brand = values.brand;
          const model = values.model;
          const engineType = values.fuel_type;
          setFieldValue("engine_power_kw", "");
          cleanProducts();
          setTimeout(() => formikProps.validateForm());

          if (vehicleType && brand && model && engineType && capacity) {
            // execFetchPowerCapacities(vehicleType, brand, model, engineType);
            execFetchPowers(vehicleType, brand, model, engineType, capacity);
          }
        };

        const handlePower = (engine_power_kw: string) => {
          getDataSets({ engine_power_kw: engine_power_kw });

          // setBatterySelectVisible(vehicleDataSetState.data?.data[0].base_fuel == 'ELE' || vehicleDataSetState.data?.data[0].base_fuel == 'HYB')
// 
          // let vehicleDataSetUuid = vehicleDataSetState.data?.data[0].uuid
          // 
          // if (vehicleDataSetUuid) {
            // execFetchBatteries(vehicleDataSetUuid)    
          // }
        };

        const handleTireWidth = async (width: string) => {
          // setFieldValue("tire_profile_height", "");
          setProfileDisabled(false);
          const rim = rimValue
          const queryString = `&width=${width}&rim_diameter=${rim}`;
          await execFetchTireValidValues(queryString);

          setTimeout(() => validateForm());
        };

        const handleTireHeight = async (height: string, index: number) => {
          // const queryString = `&profile_height=${height}`;
          // await execFetchTireValidValues(queryString);
          const tires_attributes = [...values.tires_attributes || []];
          tires_attributes[index].profile_height = height;
          formRef.setValues({ ...values, tires_attributes });   
          getDataSets(
            {
              tires_attributes: tires_attributes,
            },
            true
          );
          setTimeout(() => validateForm());
        };

        const handleTireDiameter = async (diameter: string) => {
          // setFieldValue("tire_width", "");
          // setFieldValue("tire_profile_height", "");
          setWidthDisabled(false);
          setProfileDisabled(true);
          setRimValue(diameter);
          const queryString = `&rim_diameter=${diameter}`;
          await execFetchTireValidValues(queryString);

          setTimeout(() => validateForm());
        };

        const renderCapacities = (capacities: ICapacity[] | undefined) => {
          if (capacities) {
            const opts = capacities.map((item, index) => {
              return {
                value: `${item.engine_capacity}`,
                label: item.caption.replace("cm3", "cm\u00B3"),
              };
            });

            return opts;
          }
        };

        //initial values for all OPTIONS variables for dropdowns //
        let models: string[] = [];
        let brands: string[] = [];

        // as first field, it's only loaded from preloadedVehicleState or formDataState
        let vehicleTypes = preloadedVehicleState.data?.data.vehicle_types || {};

        let engines = preloadedVehicleState.data?.data.engines_list || {};
        let capacities = preloadedVehicleState.data?.data.engines_list || {};
        let powers = preloadedVehicleState.data?.data.engine_powers_list || {};
        let batteries = preloadedVehicleState.data?.data.ev_battery_capacities || {};

        // let capacities: ICapacity[] = [];
        // let powers: IPower[] = [];

        // setting models list for dropdown options //
        if (modelsState.data?.data && !reDecoded) {
          models = modelsState.data?.data;
        } else if (preloadedVehicleState.data?.data.models_list) {
          models = preloadedVehicleState.data?.data.models_list;
        }

        // setting brands list for dropdown  options //
        if (brandsState.data?.data && !reDecoded) {
          brands = brandsState.data?.data;
        } else if (preloadedVehicleState.data?.data.brands_list) {
          brands = preloadedVehicleState.data.data.brands_list;
        }

        if (brandsState.data?.data && !reDecoded) {
          brands = brandsState.data?.data;
        } else if (preloadedVehicleState.data?.data.brands_list) {
          brands = preloadedVehicleState.data.data.brands_list;
        }

        // setting engines list for dropdown options //
        if (enginesState.data?.data && !reDecoded) {
          engines = enginesState.data.data;
        } else if (
          preloadedVehicleState.data?.data.engines_list &&
          Object.keys(preloadedVehicleState.data?.data.engines_list).length > 0
        ) {
          engines = preloadedVehicleState.data.data.engines_list;
        }

        //setting capacities list for dropdown options //

        if (capacitiesState.data?.data && !reDecoded) {
          capacities = capacitiesState.data?.data;
        } else if (preloadedVehicleState.data?.data.engine_capacities_list) {
          capacities = preloadedVehicleState.data?.data.engine_capacities_list;
        }

        //setting powers list for dropdown options //
        if (powersState.data?.data && !reDecoded) {
          powers = powersState.data?.data;
        } else if (preloadedVehicleState.data?.data.engine_powers_list) {
          powers = preloadedVehicleState.data?.data.engine_powers_list;
        }

         //setting powers list for dropdown options //
        if (batteryCapacitiesState.data && !reDecoded) {
          batteries = batteryCapacitiesState.data;
        } 

        const tiresWidthOpts =
          tireValues.data?.data.widths ||
          (formData ? formData.tire_valid_values.widths : []);
        const tiresProfileHeights =
          tireValues.data?.data.profile_heights ||
          (formData ? formData.tire_valid_values.profile_heights : []);
        const tiresRimDiameter =
          tireValues.data?.data.rim_diameters ||
          (formData ? formData.tire_valid_values.rim_diameters : []);

        return (
          <>
            <Effects />
            {(vehicleDataSetState.loading ||
              modelsState.loading ||
              brandsState.loading ||
              capacitiesState.loading ||
              powersState.loading ||
              enginesState.loading ||
              preloadedVehicleState.loading ||
              createVehicleState.loading ||
              tireValues.loading ||
              formDataState.loading ||
              isLoading) && <Spinner bgColor="grey" size="large" />}

            <header className="sider-header">
              <h2 className="primary mb-15">Dodaj pojazd</h2>
              <p className="mb-0">
                Wprowadź nr VIN aby pobrać dane pojazdu lub wypełnij formularz
                aby wyliczyć należne opłaty
              </p>

              {mode === "vin_step2" && (
                <Row gutter={5}>
                  <Col md={18}>
                    <FormField
                      label="Numer VIN"
                      placeholder="Podaj VIN"
                      name="vin_number"
                      maxlength={20}
                      className="mb-0"
                    />
                  </Col>

                  <Col md={6}>
                    <FormButton
                      onClick={() => decodeVin()}
                      className="mt-16"
                      color="white"
                      title="Pobierz dane"
                    />
                  </Col>
                </Row>
              )}
            </header>
            <div className="sider-body">
              <Row gutter={15} className="mb-20">
                <Col md={12}>
                  <h4 className="mb-5 primary">Dane pojazdu</h4>
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        onChange={handleVehicleType}
                        testId="vehicle-type"
                        {...vehicleFields.vehicleType}
                        options={renderJsonOptions(
                          formData ? formData.vehicle_types : vehicleTypes
                        )}
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        testId="make"
                        {...vehicleFields.brand}
                        disabled={values.vehicle_type ? false : true}
                        options={brands ? renderJsonOptions(brands) : []}
                        onChange={handleBrand}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        testId="model"
                        {...vehicleFields.model}
                        options={models ? renderJsonOptions(models) : []}
                        disabled={
                          values.brand && values.vehicle_type ? false : true
                        }
                        onChange={handleModel}
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        testId="fuel-type"
                        {...vehicleFields.fuelType}
                        options={renderJsonOptions(engines || [])}
                        disabled={
                          values.brand && values.model && values.vehicle_type
                            ? false
                            : true
                        }
                        onChange={handleEngineType}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        name="engine_capacity"
                        onChange={handleCapacity}
                        disabled={values.fuel_type ? false : true}
                        label="Pojemność"
                        options={renderJsonOptions(capacities || [])}
                        required={true}
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        name="engine_power_kw"
                        onChange={handlePower}
                        disabled={values.engine_capacity ? false : true}
                        label="Moc silnika"
                        options={renderJsonOptions(powers || [])}
                        required={true}
                      />
                    </Col>
                  </Row>
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        name="drive_type"
                        label="Skrzynia biegów"
                        onChange={(value: string) =>
                          getDataSets({ drive_type: value }, true)
                        }
                        required={true}
                        options={
                          formData?.transmission_type
                            ? renderJsonOptions(formData.transmission_type)
                            : []
                        }
                      />
                    </Col>
                    <Col md={12}>
                      <SelectField
                        testId="vehicle-year"
                        {...vehicleFields.productionYear}
                        required={true}
                        options={renderJsonOptions(getYears())}
                      />
                    </Col>
                  </Row>
                  {(batterySelectVisible) &&
                  <Row gutter={5}>
                    <Col md={12}>
                      <SelectField
                        name="ev_battery_capacity"
                        onChange={(value: string) =>
                          getDataSets({ ev_battery_capacity: value }, true)
                        }
                        label="Pojemność baterii"
                        required={true}
                        options={renderJsonOptions(batteries || [])}
                      />
                    </Col>
                  </Row>}

                  <FieldArray name="tires_attributes">
                    {() => ((values.tires_attributes || []).map((item, i) => {
                      return (
                        <Row gutter={5}>
                          <Col md={6}>
                            <SelectField
                              name={`tires_attributes[${i}].count`}
                              label="Liczba opon"
                              onChange={ (e) => {handleTireCount(e, i)} }
                              required={true}
                              disabled={false}
                              options={ renderRangeOpts(0, 12) }
                              selectedValue={Number(item.count) >= 0 ? Number(item.count).toString() : false }
                            />
                          </Col>
                          <Col md={6}>
                            <SelectField
                              name={`tires_attributes[${i}].rim_diameter`}
                              label="Średnica"
                              onChange={ handleTireDiameter }
                              disabled={isTireCountZero}
                              options={renderJsonOptions(tiresOptions.get(i.toString())?.rim_diameters || tiresRimDiameter)}
                              required={true}
                            />
                          </Col>
                          <Col md={6}>
                            <SelectField
                              name={`tires_attributes[${i}].width`}
                              label="Szerokość"
                              disabled={widthDisabled || isTireCountZero}
                              onChange={handleTireWidth}
                              options={renderJsonOptions(tiresOptions.get(i.toString())?.widths ||tiresWidthOpts)}
                              required={true}
                            />
                          </Col>
                          <Col md={5}>
                            <SelectField
                              name={`tires_attributes[${i}].profile_height`}
                              label="Profil"
                              disabled={profileDisabled || isTireCountZero}
                              options={renderJsonOptions(tiresOptions.get(i.toString())?.profile_heights ||tiresProfileHeights)}
                              required={true}
                              onChange={(e) => {handleTireHeight(e, i) }}
                            />
                          </Col>
                          <Col md={1}>
                            <DeleteOutlinedIcon onClick= { () => onChangeTiresCount(values, i) } />
                          </Col>
                        </Row>
                      );
                    }))}
                  </FieldArray>
                  <Row gutter={5}>
                    <Button                    
                    className="mb-5"
                    inline={true}
                    half=""
                    onClick={() => onChangeTiresCount(values, -1)}
                    color="white"
                    title="Dodaj opony"/>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <FormField
                        label="Data wprowadzenia pojazdu na teren RP lub wystawienia faktury"
                        name="import_date"
                        type="date"
                        required={true}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={12}>
                  <h4 className="mb-5 primary">Opłaty produktowe</h4>
                  <div className="vehicle_product_attributes">
                    <ProductAttributes
                      // key={componentKey}
                      type="checkbox-group"
                      {...payments}
                      name="vehicle_products_attributes"
                      options={renderVehicleProductOpts()}
                      orientation="vertical"
                    />
                  </div>

                  <ErrorMsg name="vehicle_products_attributes" />
                  <div>
                    <CostSummary
                      // buttonTitle="Dodaj pojazd"
                      // onClick={formikProps.submitForm}
                      // disabled={!isValid}
                      title="Łącznie netto"
                      price={productsSum || 0}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="sider-footer">
              <div className="flex-top-left">
                <div className="t-center">
                  <div
                    className="link t-500 mb-20 mt-10 md-t"
                    onClick={() => openDraftForm(formikProps.values)}
                  >
                    Nie ma mojego pojazdu. Chcę zgłosić nowy pojazd
                  </div>
                </div>
                <div className="grow-1 t-right">
                  <FormButton
                    color="primary"
                    width={200}
                    onClick={formikProps.submitForm}
                    title="Dodaj pojazd"
                    // disabled={!isValid}
                    className="mb-20 mr-5"
                    inline={true}
                  />
                  <Button
                    width={200}
                    inline={true}
                    onClick={() => resetForm()}
                    color="white"
                    title="Wyczyść formularz"
                  />
                </div>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

function mapStateToProps(state: RootStateOrAny) {
  return {
    formData: state.vehicles.form_data,
    carModels: state.vehicles.models,
    // dataSubmitting: state.loader && state.loader.data_submitting,
  };
}

export default connect(mapStateToProps, { preloadVehicle, fetchModels })(
  VehicleForm
);
