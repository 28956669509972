import React from "react";
import { Field } from "formik";
import AntdCheckbox from "antd/lib/checkbox";
import ToolTip from "antd/lib/tooltip";
//@ts-ignore
import "styles/components/input.scss";
import { IInquiryFormValues } from "types/insurance-types";
import { getNested } from "utils/helpers";
import { UseFormReturn, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { ErrorLabel } from "components/common/error-message";

//
// import style from "./check-form-field.module.scss";
export interface ICheckFormFieldProps<T> {
  id?: string;
  name: string;
  value: T | boolean;
  checked?: boolean;
  onCheckChange?: any;
  cbxLabel?: string | JSX.Element | JSX.Element[];
  cbxDetails?: string;
  keyValues?: boolean;
  disabled?: boolean;
  required?: boolean;
  isFlag?: boolean;
  type?: "checkbox";
  className?: string;
  additionalAttributes?: any;
  icon?: any;
  validate?: any;
  formMethods: UseFormReturn<any>;
  rules?: any;
}
function Checkbox(props: ICheckFormFieldProps<any>) {
  const { formMethods, name, rules } = props;
  return (
    <Controller
      name={name}
      control={formMethods?.control}
      rules={rules}
      render={({ field, fieldState, formState, }) => {
        
        // const touched = getNested(formState.touchedFields, props.name);
        // const error = getNested(formState.errors, props.name);

        return (
          <AntdCheckbox
            data-testid={props.id}
            onChange={(e) => {
              if (props.isFlag) {
                // field.onChange(e);
                formMethods.setValue(
                  props.name,
                  field.value ? false : e.target.value, {shouldValidate: true, shouldDirty : true} );
                
                
              } else {
                //* in case its checkbox group field
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    (value: string) => value !== props.value
                  );
                  formMethods.setValue(props.name, nextValue, {shouldValidate : true, shouldDirty: true});
                } else {
                  const nextValue = field.value.concat(props.value);
                  formMethods.setValue(props.name, nextValue, {shouldValidate : true, shouldDirty: true});
                }
              }

              if (props.onCheckChange) {
                props.onCheckChange(e, props.additionalAttributes);
              }
            }}
            className={`checkbox ${props.className}`}
            {...props}
            checked={
              props.checked !== undefined
                ? props.checked
                : props.isFlag
                ? field.value
                : field.value.includes(props.value)
            }
          >
            <div className={`cbx-title`}>
              {props.icon && (
                <ToolTip title="Brak umowy">
                  <props.icon className="red sm-t" />{" "}
                </ToolTip>
              )}
              {props.cbxLabel}
              {props.required ? "*" : ""}
            </div>
            {props.cbxDetails ? <p className="mt-5">{props.cbxDetails}</p> : ""}
            {formMethods.formState && (
                    <ErrorMessage
                    errors={formMethods?.formState.errors}
                    name={name}
                    render={({ message }: any) => ( 
                      ErrorLabel(message) 
                    )}
                  />
                )}
          </AntdCheckbox>
        );
      }}
    />
  );
}

export default Checkbox;
