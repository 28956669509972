import React, { useState, useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import SpinnerPopup from "components/core/spinner";
import { IReportData, IReportFormValues, IReportList } from "types/report-types";
import { useAsyncAction } from "utils/async-action";
import TextField from "components/form-fields/formik-text-field";
import { getReport, getReportList, submitReport } from "actions/report";
import { Formik, FormikProps } from "formik";
import { IUser } from "types/user-types";
import { IActionState } from "types/async-types";
import * as Yup from "yup";
import { dateRequired, email, required, yupRequired } from "validators/index";
import TableWrapper from 'components/common/table-wrapper'
import _ from "lodash";
import Button from 'components/buttons/form-button'
import {
  IReportState,
  IGetReport,
  ISubmitReport,
  IReport,
  IProduced,
  ITotal,
  ITransferedWithCard,
  ITransferedWithoutCard,
} from "types/report-types";
import styles from "./report-modal.module.scss";
import CheckFormField from "components/form-fields/check-form-field";

interface IProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  submitReportState: IActionState<any>;
  displayedEmail: string | null;
  setDisplayedEmail: any;
  reportYear: string;
}

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1100px",
    height: "80%",
  },
};

const mailModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "200px",
  },
};

// const mockData: IReport = {
//   produced: [{ code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }, { code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }],
//   produced_total: {kg: 12, mg: 0.012},
//   transfered_with_card: [{ code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }],
//   transfered_with_card_total: {kg: 12, mg: 0.012},
//   transfered_without_card: [{ code: "12_34", name: "Szkło", kg: 12, mg: 0.012 }],
//   transfered_without_card_total: {kg: 12, mg: 0.012},
// };

const ReportModal = (props: any) => {
  const [submitReportState, execSubmitReport]: readonly [
    IActionState<any>,
    typeof submitReport
  ] = useAsyncAction(submitReport);

  const user: IUser = useSelector((state: any) => state.user);

  const onSubmit = (formValues: IReportFormValues) => {
    execSubmitReport(formValues)
    setPkd4520Checked(false)
    setPkd4540Checked(false)
  };

  const formRef: any = useRef();
  const formikRef: FormikProps<IReportFormValues> = formRef.current;
  const initialValues: IReportFormValues = { year: props.reportYear, email: user.email, pkd_4520Z_date: "", pkd_4540Z_date: "" };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .concat(yupRequired)
      .email("Niepoprawny format adresu email")
      .nullable(),
    
  });

  let [newValidationSchema, setNewValidationSchema] = useState(validationSchema);
  let [pkd4520Checked, setPkd4520Checked] = useState(false);
  let [pkd4540Checked, setPkd4540Checked] = useState(false);


  const {
    modalIsOpen,
    closeModal,
    reportYear
  } = props;

  // const [pkdDetected, setPkdDetected] = useState(false);

  const [reportState, execGetReport]: readonly [
    IActionState<IReportData>,
    IGetReport
  ] = useAsyncAction(getReport);

  //** side effects */
  useEffect(() => {
    if (modalIsOpen) {
      const year = reportYear.toString();
      execGetReport(year);
      formikRef.resetForm();
      formikRef.setFieldValue("year", year);
      formikRef.setFieldValue("email", user.email);
    }
  }, [modalIsOpen]);

  useEffect(() => {
    if (reportState.error) {
      setPkd4520Checked(false)
      setPkd4540Checked(false)
      closeModal();
    }
  }, [reportState.error]);

  useEffect(() => {
    if (reportState.data) {
      // setPkdDetected(reportState.data?.pkd_detected)
      formikRef.setFieldValue("pkd_4520Z_date", "");
      formikRef.setFieldValue("pkd_4540Z_date", "");
      formikRef.setFieldValue("pkd_4520Z_confirm", false);
      formikRef.setFieldValue("pkd_4540Z_confirm", false);
    }
  }, [reportState.data]);

  useEffect(() => {
    if(pkd4520Checked == false && pkd4540Checked == false){
      let newValidationSchema = validationSchema.required().concat( 
        Yup.object().shape({
            pkd_4520Z_confirm: Yup.boolean().oneOf([true], 'przynajmniej jeden z kodów PKD jest wymagany'),
            pkd_4540Z_confirm: Yup.boolean().oneOf([true], 'przynajmniej jeden z kodów PKD jest wymagany')
          }).required()
        )
      setNewValidationSchema(newValidationSchema)
    } else {
      let newValidationSchema = validationSchema.required()
      if(pkd4520Checked) {
        newValidationSchema = newValidationSchema.concat( 
          Yup.object().shape({
              pkd_4520Z_date: dateRequired,
            }).required()
          )
      }

      if(pkd4540Checked) {
        newValidationSchema = newValidationSchema.concat( 
          Yup.object().shape({
              pkd_4540Z_date: dateRequired,
            }).required()
          )
      }
      setNewValidationSchema(newValidationSchema)
    }
}, [pkd4520Checked, pkd4540Checked])

  useEffect(() => {
    if (submitReportState.data) {
      setPkd4520Checked(false)
      setPkd4540Checked(false)
      closeModal();
    }
  }, [submitReportState.data]);

  const renderProduced = (items: IProduced, raportedItems?: IProduced) => {
    return Object.keys(items).map((key) => {
      let reportedKg = (raportedItems && raportedItems[key]?.produced_kg || 0)
      let reportedMg = (raportedItems && raportedItems[key]?.produced_mg || 0)
      return (
        <tr>
          <td className="text-left">
            {key} {items[key].code_name}
          </td>
          <td className="t-right">{_.round(reportedKg, 4)}</td>
          <td className="t-right">

          { (items && items[key].produced_kg) ? <span className={`ml-10 ${items[key].produced_kg != reportedKg ? "red": ""}`}>{_.round(items[key].produced_kg, 4)}</span> : ""}
          </td>
          <td className="t-right">{_.round(reportedMg, 4)}</td>
          <td className="t-right">

          { (items && items[key]?.produced_mg) ? <span className={`ml-10 ${items[key].produced_mg != reportedMg ? "red": ""}`}>{_.round(items[key].produced_mg, 4)}</span> : ""}
          </td>
        </tr>
      );
    });
  };

  const renderTransferedWithCard = (items: ITransferedWithCard, raportedItems?: ITransferedWithCard) => {
    return Object.keys(items).map((key) => {
      let reportedKg = (raportedItems && raportedItems[key]?.transfered_with_card_kg || 0)
      let reportedMg = (raportedItems && raportedItems[key]?.transfered_with_card_mg || 0)
      return (
        <tr>
          <td className="text-left">
            {key} {items[key].code_name}
          </td>
          <td className="t-right">
            {_.round(items[key].transfered_with_card_kg, 4)}
            { (raportedItems && raportedItems[key]?.transfered_with_card_kg) ? <span className={`ml-10 ${items[key].transfered_with_card_kg != reportedKg ? "red": ""}`}>({_.round(raportedItems[key].transfered_with_card_kg, 4)})</span> : ""}
          </td>
          <td className="t-right">
            {_.round(items[key].transfered_with_card_mg, 4)}
          { (raportedItems && raportedItems[key]?.transfered_with_card_mg) ? <span className={`ml-10 ${items[key].transfered_with_card_mg != reportedMg ? "red": ""}`}>({_.round(raportedItems[key].transfered_with_card_mg, 4)})</span> : ""}
          </td>
        </tr>
      );
    });
  };

  const renderTransferedWithoutCard = (items: ITransferedWithoutCard, raportedItems?: ITransferedWithoutCard) => {
    return Object.keys(items).map((key) => {
      let reportedKg = (raportedItems && raportedItems[key]?.transfered_without_card_kg || 0)
      let reportedMg = (raportedItems && raportedItems[key]?.transfered_without_card_mg || 0)
      return (
        <tr>
          <td className="text-left">
            {key} {items[key].code_name}
          </td>
          <td className="t-right">
            {_.round(items[key].transfered_without_card_kg, 4)}
            { (raportedItems && raportedItems[key]?.transfered_without_card_kg) ? <span className={`ml-10 ${items[key].transfered_without_card_kg != reportedKg ? "red": ""}`}>({_.round(raportedItems[key].transfered_without_card_kg, 4)})</span> : ""}
          </td>
          <td className="t-right">
            {_.round(items[key].transfered_without_card_mg, 4)}
            { (raportedItems && raportedItems[key]?.transfered_without_card_mg) ? <span className={`ml-10 ${items[key].transfered_without_card_mg != reportedMg ? "red": ""}`}>({_.round(raportedItems[key].transfered_without_card_mg, 4)})</span> : ""}
          </td>
        </tr>
      );
    });
  };

  const report = reportState.data?.data;
  
  const renderReport = () => {
    if (!report) {
      return <> </>;
    }

    const reportData = reportState.data?.report_data || {}

    
    return Object.keys(report).map((key) => {
      return (
        <div>
          <div className={styles["address-header"]}>
            {report[key].address_string}
          </div>
          <TableWrapper>
          <table className="ant-table waste-table mb-20" cellSpacing="1">
            <thead className="ant-table-thead">
              <tr>
                <th className="text-left">Odpady wytworzone w {reportYear}:</th>
                <th>było kg</th>
                <th>jest kg</th>
                <th>było Mg</th>
                <th>jest Mg</th>
              </tr>
            </thead>
            <tbody>
              {renderProduced(report[key].wastes_produced, reportData[key]?.wastes_produced)}
              <tr className="row-summary t-500 black">
                <td className="t-right">suma</td>
                <td className="t-right">
                  {_.round(reportData[key]?.wastes_produced_sum_kg || 0, 4)}
                  </td>
                <td className="t-right">
                  { <span className={`ml-10 ${report[key].wastes_produced_sum_kg != (reportData[key]?.wastes_produced_sum_kg || 0) ? "red": ""}`}>{_.round(report[key].wastes_produced_sum_kg, 4)}</span> }
                </td>
                <td className="t-right">
                  {_.round(reportData[key]?.wastes_produced_sum_mg  || 0, 4)}
                  </td>
                <td className="t-right">
                  { <span className={`ml-10 ${report[key].wastes_produced_sum_mg != (reportData[key]?.wastes_produced_sum_mg || 0) ? "red": ""}`}>{_.round(report[key].wastes_produced_sum_mg, 4)}</span> }
                </td>
              </tr>
              <tr className="header-row ant-table-thead black">
                <td colSpan={3}>Odpady przekazane z kartą KPO w {reportYear} roku</td>
              </tr>
              {renderTransferedWithCard(report[key].wastes_with_card, reportData[key]?.wastes_with_card)}
              <tr className="row-summary t-500 black">
                <td className="t-right">suma</td>
                <td className="t-right">
                  {_.round(reportData[key]?.wastes_with_card_sum_kg || 0, 4)}
                </td>
                <td className="t-right">
                  { <span className={`ml-10 ${report[key].wastes_with_card_sum_kg != (reportData[key]?.wastes_with_card_sum_kg || 0) ? "red": ""}`}>{_.round(report[key].wastes_with_card_sum_kg, 4)}</span> }
                </td>
                <td className="t-right">
                  {_.round(reportData[key]?.wastes_with_card_sum_mg || 0, 4)}
                </td>
                <td className="t-right">
                  { <span className={`ml-10 ${report[key].wastes_with_card_sum_mg != (reportData[key]?.wastes_with_card_sum_mg || 0) ? "red": ""}`}>{_.round(report[key].wastes_with_card_sum_mg, 4)}</span> }
                </td>
              </tr>
              <tr className="header-row ant-table-thead black">
                <td colSpan={3}>Odpady przekazane bez karty KPO w {reportYear} roku</td>
              </tr>
              {renderTransferedWithoutCard(report[key].wastes_without_card, reportData[key]?.wastes_without_card)}
              <tr className="row-summary t-500 black">
                <td className="t-right">suma</td>
                <td className="t-right">
                  {_.round(reportData[key]?.wastes_without_card_sum_kg || 0, 4)}
                </td>
                <td className="t-right">
                  { <span className={`ml-10 ${report[key].wastes_without_card_sum_kg != (reportData[key]?.wastes_without_card_sum_kg || 0) ? "red": ""}`}>{_.round(report[key].wastes_without_card_sum_kg, 4)}</span> }
                </td>
                <td className="t-right">
                  {_.round(reportData[key]?.wastes_without_card_sum_mg || 0, 4)}
                </td>
                <td className="t-right">
                  { <span className={`ml-10 ${report[key].wastes_without_card_sum_mg != (reportData[key]?.wastes_without_card_sum_mg || 0) ? "red": ""}`}>{_.round(report[key].wastes_without_card_sum_mg, 4)}</span> }
                </td>
              </tr>
            </tbody>
          </table>
          </TableWrapper>
        </div>
      );
    });
  };

  return (
    <Formik
      innerRef = { formRef }
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={newValidationSchema}
      isInitialValid = {false}
    >
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => {
          setPkd4520Checked(false)
          setPkd4540Checked(false)
          closeModal();
        }}
        style={customStyles}
      >
        {reportState.loading || submitReportState.loading ? (
          <SpinnerPopup />
        ) : (
          <div >
            <h2 className="mb-10">Zamówienie sprawozdania BDO za {reportYear}</h2>
            <div className={styles["report-container"]}>{renderReport()}</div>

            {!reportState.data?.read_only && reportState.data?.correction && <p className="t-center red bold">
                Wprowadzone dane nie są zgodne z wygenerowanym raportem za rok {reportYear}. Przekazane dane spowodują wykonanie korekty.
              </p>
            }

            {reportState.data?.read_only ?             <div className="mt-10 buttons flex-center">
                <Button
                  className="mr-10"
                  color="white"
                  onClick={() => {
                    setPkd4520Checked(false)
                    setPkd4540Checked(false)
                    closeModal() 
                  }
                  }
                  title="Zamknij"
                  width={100}
                />
                </div> : (<div>
                    <p className = "t-center bold">W celu wygenerowania Sprawozdania BDO potwierdź przynajmniej jedno z poniższych oświadczeń:</p>
                    <div className="ant-row mt-10 mb-20">
                      {/* <p className="t-center red bold"> {pkdDetected ? "" : "Nie odszukaliśmy czy Twoja działalność ma klasyfikację PKD 45.20.Z Konserwacja i naprawa maszyn lub . Sprawdź wpis do GUS. Jeżeli masz takie PKD potwierdź poniższe oświadczenie:" }</p> */}
                      <div className="mb-20">
                        <CheckFormField
                          name={'pkd_4520Z_confirm'}
                          id={"pkd_4520Z_confirm"}
                          value={ true }
                          cbxLabel="Oświadczam, ze prowadzę działalność gospodarczą w zakresie PKD 45.20.Z Konserwacja i naprawa maszyn."
                          isFlag = {true}
                          required = { true }
                          onCheckChange = { (e : any) => {
                            setPkd4520Checked(e.target?.checked || !pkd4520Checked )
                           } }
                        />
                      </div>
                      <div className= "ant-col ant-col-md-6">
                        <TextField  label="Data rozpoczęcia działalności w zakresie gospodarowania odpadami w zakresie PKD 45.20.Z" type="date" required={true} name="pkd_4520Z_date" />
                      </div>

                    </div>

                    <div className="ant-row mt-10 mb-20">
                      <div className="mb-20">
                        <CheckFormField
                          name={'pkd_4540Z_confirm'}
                          id={"pkd_4540Z_confirm"}
                          value={ true }
                          cbxLabel="Oświadczam, ze prowadzę działalność gospodarczą w zakresie PKD 45.40.Z dotyczący naprawy i konserwacji motocykli."
                          isFlag = {true}
                          required = { true }
                          onCheckChange = { (e : any) => {
                            setPkd4540Checked(e.target?.checked || !pkd4540Checked )
                           } }
                        />
                      </div>
                      <div className= "ant-col ant-col-md-6">
                        <TextField  label="Data rozpoczęcia działalności w zakresie gospodarowania odpadami w zakresie PKD 45.40.Z" type="date" required={true} name="pkd_4540Z_date" />
                      </div>
                    </div>

              <p className="t-center">
                Sprawozdanie będzie utworzone na podstawie danych przekazanych przez
                Użytkownika. Integrator nie ponosi odpowiedzialności za
                nieprawidłowe dane.
              </p>
              <p className="t-center">
                Po wysłaniu zamówienia, sprawozdanie zostanie przygotowane i wysłane
                na podany poniżej adres.
                {/* na podany poniżej adres: {displayedEmail} / adres mailowy z CBK */}
              </p>
              <div className={`${styles['report-email']} ant-row`}>
                <div className= "ant-col ant-col-md-12">
                    <TextField  label="Adres email" type="email" required={true} name="email" />
                  </div>
              </div>

              <div className="mt-10 buttons flex-center">
                <Button
                  className="mr-10"
                  color="white"
                  onClick={() => {
                    setPkd4520Checked(false)
                    setPkd4540Checked(false)
                    closeModal()
                  }}
                  title="Anuluj"
                  width={100}
                />
                <Button
                
                  title="Wyślij"
                  color="primary"
                  onClick= { () => {formikRef.submitForm()}}
                  // disabled={ formikRef.isValid }
                  width={100}
                />
              </div>
            </div>
            ) }
          </div>
        )}
      </Modal>

    </Formik>
  );
};

export default ReportModal;
