import React, { useState } from "react";

import { logout, fetchSessionData } from "actions/user";
import { IActionState } from "types/async-types";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { useHistory, Link } from "react-router-dom";
import Button from "components/buttons/button";
import { PAGE } from "paths";
// import {  switchProfile } from "actions/company-profiles";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "redux/reducers";
// import Modal from 'components/common/modal'
// import ProfileForm from 'components/user/profile-form'
import Spinner from "components/core/spinner";
import { LOGOUT, UserActionTypes } from "types/redux-types";

interface IProps {
  logoutUser: any;
  setDropdownVisible: any;
}
function UserDropdown(props: IProps) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { setDropdownVisible } = props;
  const NIPModalProps = {
    modalIsOpen: modalIsOpen,
    closeModal: () => setModalIsOpen(false),
    mode: "first",
  };
  const [logoutState, execLogout]: readonly [
    IActionState<any>,
    typeof logout
  ] = useAsyncAction(logout);

  // const [switchProfileState, execSwitchProfile]: readonly [
  //   IActionState<any>,
  //   typeof switchProfile
  // ] = useAsyncAction(switchProfile);

  const [session, execFetchSession]: readonly [any, () => any] = useReduxAction(
    fetchSessionData,
    "user"
  );

  const sessionState = useSelector((state: RootState) => state.user.session);
  const asyncState = useSelector((state: RootState) => state.async);

  // const changeProfile = async (id : string) => {

  //  const res =  await  execSwitchProfile(id);
  //  if(res){
  //   // await execFetchSession();
  //   window.location.reload();
  //   setDropdownVisible(false);
  //  }
  // }

  const router = useHistory();
  const logoutUser = async () => {
    const res = await execLogout();
    if (res?.status === 200) {
      const action: UserActionTypes = {
        type: LOGOUT,
      };
      dispatch(action);

      router.push(PAGE.LOGIN);
    }
  };

  return (
    <div className="menu_dropdown user-dropdown">
      {/* {(switchProfileState.loading || asyncState.isLoading || logoutState.loading) &&
        <Spinner />
      } */}
      {/* <Modal width={"360px"} {...NIPModalProps} >
            <ProfileForm {...NIPModalProps} />
        </Modal> */}
      <section>
        <div className="md-t mb-10">
          Jesteś zalogowany/a jako:
          <a onClick={logoutUser} className="f-right">
            Wyloguj
          </a>
        </div>
        <div className="md-b-t">
          <div>{sessionState?.company_name}</div>
          <div>{sessionState?.email}</div>
        </div>
      </section>
      {/* <section className="no-borders">
        <ul className="links">
          <li>
            <Link to="/">
                Dane firmowe
            </Link>
          </li>
          <li>
            <Link to="/">
                Pracownicy
            </Link>
          </li>
          <li>
            <Link to="/">
                Moje adresy
            </Link>
          </li>
        </ul>
      </section> */}

      {/* <section className="no-border">
        <div className="md-t mb-15">Dodaj profil</div>
        <p className="sm-t mb-15">
          Jeżeli jesteś właścicielem lub współwłaścicielem kilku firm możesz
          dodać je wszystkie jako osobne profile w ECAR.
        </p>
        <Button onClick={() =>{setModalIsOpen(true); setDropdownVisible(false) }} title="dodaj profil firmowy" color="white" />
      </section> */}
    </div>
  );
}

export default UserDropdown;
