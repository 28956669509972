import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { IAccountingDocument } from 'types/document-types';
import { numToCurrency } from 'utils/helpers';


export const DocumentColumns = ( downloadFile : any, downloadLocalFile : any, reportId? : string | null) : ColumnProps<IAccountingDocument>[] => {

  return [
    {
      title: "Data wystawienia",
      align: "left",
      // width: 100,
      render: (field: any, row: IAccountingDocument) => (
        <div className="no-wrap">{row.draw_date}</div>
      ),
    },
    {
      title: "Tytuł płatności",
      align: "left",
      // width: 140,
      render: (field: any, row: IAccountingDocument) => (
       <a onClick={  (row.local_document) ?  () => downloadLocalFile( row.id ) : () => downloadFile(row.id) }>{row.title}</a>
      ),
    },
    // {
    //   title: "Opis",
    //   align: "left",
    //   // width: 140,
    //   render: (field: any, row: IAccountingDocument) => (
    //     <div>{row.description}</div>
    //   ),
    // },
    {
      title: "Sprzedający",
      align: "left",
      // width: 450,
      render: (field: any, row: IAccountingDocument) => (
        <div>{row.seller_string}</div>
      ),
    },
    {
      title: "Termin płatności",
      align: "left",
      width: 120,
      render: (field: any, row: IAccountingDocument) => (
        <div
          className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}
        >
          {row.payment_due_date}
        </div>
      ),
    },
    {
      title: "Kwota do zapłaty",
      align: "left",
      width: 120,
      render: (field: any, row: IAccountingDocument) => (
        <div className={`no-wrap t-500 black`}>{numToCurrency(row.brutto)}</div>
      ),
    },
    {
      render: (field: any, row: IAccountingDocument) =>
        row.status === "PEN" && (
          <div className="grey">{row.status_string}</div>
        ),
    },
  ];
}   