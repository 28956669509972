import React from "react";
import Button from "components/buttons/button";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import

//@ts-ignore
import "styles/components/common/modal.scss";

interface IProps {
  action: (...a: any) => void;
  header: string;
  text: string | JSX.Element | JSX.Element[];
  yesText?: string;
  noText?: string;
  btnColor: "primary" | "white" | "white bold";
}

export function ConfirmModal({
  action,
  header,
  text,
  yesText,
  noText,
  btnColor,
}: IProps) {
  return confirmAlert({
    customUI: ({ onClose }: any) => {
      return (
        <div className={"modal confirm-modal"}>
          <div className="mb-20">
            <h2>
              {header}
              <CloseIcon onClick={onClose} className="close" />
            </h2>
            <p>{text}</p>
          </div>
          <div className="two-buttons">
            <Button
              color={"white"}
              onClick={onClose}
              title={noText ? noText : "Nie"}
            />
            <Button
              color={btnColor}
              onClick={() => {
                action();
                onClose();
              }}
              title={yesText ? yesText : "Tak"}
            />
          </div>
    
        </div>
      );
    },
  });
}
