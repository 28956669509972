import React from 'react';
import FormButton from 'components/buttons/form-button';

//@ts-ignore
import  'styles/components/common/cost-summary.scss';
import { numToCurrency } from 'utils/helpers';
interface IProps {title: string, price: number, note?: string, onClick?: () => void, buttonTitle?: string, disabled? : boolean}
const CostSummary = (props: IProps) => {
  const {title, price, note, onClick, buttonTitle, disabled} = props;
  
  return (
    <div className="cost_summary t-right">
    <div className="mb-20 i-b">
      <h3 className="primary-h">
        
        <div className="flex"><span className="title grow-1">{title}:{" "}</span><p className="green  mb-0">{numToCurrency(price)}</p> </div>
        
        <label className="sm-l-t">{note}</label></h3>
    </div>
    {(buttonTitle && onClick) &&
      <FormButton disabled={disabled} color="primary" className="inline f-right" onClick={onClick} title={buttonTitle} />
    }
    
      
    </div>
  )
}

export default CostSummary;
