export const LPColumn = (startIndex?: number) => ({
  width: 70,
  title: "Lp.",
  render: (field: any, row: any, index: number) => startIndex ? `${index  + startIndex}.` : "",
});

export const CreatedAt = {
  title: "Utworzono",
  render: (field: any, row: any) => row.created_at,
};
