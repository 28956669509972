import { ISessionData } from "./session-types";
import { IPayments } from "./dashboard-types";

// user types
export const AUTH_USER = "AUTH_USER";
export const SEND_EMAIL = "SEND_EMAIL";
export const ADD_TO_CART = "ADD_TO_CART";
export const FETCH_SESSION = "FETCH_SSSION"
export const FETCH_SHOWS = "FETCH_SHOW";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_PAYMENTS = "FETCH_PAYMENTS";
export const LOGOUT = "LOGOUT";

export interface ILogoutUser {
  type: typeof LOGOUT;

}

export interface IChangePasswordAction {
  type: typeof SEND_EMAIL;
  payload: {
    password_sent_info: {
      message: string;
      debug_token_preview?: string;
    };
  };
}

export interface ISignInAction {
    type: typeof AUTH_USER;
    payload: { 
        user : {token : string}
    }
  }

  export interface IAddToCartAction {
    type: typeof ADD_TO_CART;
    payload: {
        cart : {
            translationIds : string[]
        }
       
    }
  }

  export interface IFetchSessionAction {
    type: typeof FETCH_SESSION;
    payload: ISessionData
  }

  export interface IFetchPaymentsAction {
    type: typeof FETCH_PAYMENTS;
    payload: IPayments
  }


export type UserActionTypes = IChangePasswordAction | ISignInAction | IAddToCartAction | IFetchSessionAction | IFetchPaymentsAction | ILogoutUser

export type WasteActionTypes = {
  type : 'SET_TOGGLE_WASTE_EDIT',
  payload : {
    toggleWasteEdit : (id : string) => any
  }
}


//vehicles types
export const FETCH_VEHICLE_FORM_DATA = "FETCH_VEHICLE_FORM_DATA";
export const FETCH_MODELS = "FETCH_MODELS";
export const FETCH_VEHICLE = "FETCH_VEHICLE";
export const FETCH_VEHICLES = "FETCH_VEHICLES";
export const PRELOAD_VEHICLE = "PRELOAD_VEHICLE";

//documents types
export const FETCH_DOCUMENTS = "FETCH_DOCUMENTS";
export const SEARCH_DOCUMENTS = "SEARCH_DOCUMENTS";
export const GET_DOCUMENT_FILE = "GET_DOCUMENT_FILE";
export const LIKE_DOCUMENT = "LIKE_DOCUMENT";
export const DISLIKE_DOCUMENT = "DISLIKE_DOCUMENT";

//error types
export const SET_REQUEST_ERROR = "SET_REQUEST_ERROR";
export const DATA_SUBMITTING = "DATA_SUBMITTING";
export const DATA_FETCHING = "DATA_FETCHING";

//translations types
export const FETCH_TRANSLATION = "FETCH_TRANSLATION";
export const FETCH_TRANSLATIONS = "FETCH_TRANSLATIONS";
