import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import { IListing } from "types/async-types";
import Table, { TableProps, ColumnProps } from "antd/lib/table";
import "styles/components/common/table.scss";
import "styles/pages/my-vehicles.scss";

interface IProps {
  data: IListing<any> | null;
  columns: ColumnProps<any>[];
  setPage?: (page: number) => any;
  tableProps: TableProps<any>;
  className?: string;
  ref?: any;
  noTotal?: boolean;
  noPagination?: boolean;
}
const AntdTableWrapper = (props: IProps) => {
  const {
    className,
    tableProps,
    setPage,
    ref,
    data,
    columns,
    noPagination,
    noTotal,
  } = props;
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (data?.total_pages) {
      setPageCount(data?.total_pages);
    }
    if (data?.current_page) {
      setPageNo(data?.current_page);
    }
  }, [data]);

  return (
    <div ref={ref} className={`table_wrapper ${className || ""}`}>
      {(data && !noTotal) && (
        <div className="md-t mb-10">
          <span className="grey-2">
            Znaleziono:{" "}
            <span className="md-b-t dark">{data?.total_items || 0}</span>
          </span>
        </div>
      )}
      <Table
        columns={columns}
        dataSource={data?.list || (data?.data || data?.items)}
        pagination={false}
        locale={{ emptyText: "Brak rekordów" }}
        size="middle"
        bordered={false}
        {...tableProps}
      />
      {!noPagination && (
        <div className="mb-20 i-b">
          <div className="pagination">
            <Pagination
              shape="rounded"
              onChange={(e, page) => {
                setPage && setPage(page);
              }}
              page={pageNo}
              count={pageCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AntdTableWrapper;
