import {
  SEND_EMAIL,
  ADD_TO_CART,
  FETCH_SESSION,
  IChangePasswordAction,
  IAddToCartAction,
  IFetchSessionAction,
} from "types/redux-types";
import axios, { AxiosRequestConfig } from "axios";
import cookie from "js-cookie";
import { login, requestHeaders } from "utils/auth";
import { API_BASE_URL, FRONT_DATE } from "config";
import history from "my-history";

import {
  IStandardSignIn,
  IStandardLoginFormValues,
  INewPassword,
  IRegisterReq,
} from "types/login-types";
import { ActionResponse } from "types/async-types";
import { PAGE } from "paths";
import { Dispatch } from "redux";
import { ISessionData, ILoginData } from "types/session-types";
import { IPasswordFormValues } from "components/user/password-form";

const LOGIN_URL = `${API_BASE_URL}/login`;
const LOGOUT_URL = `${API_BASE_URL}/logout`;
const PASSWORD_RESETS_URL = `${API_BASE_URL}/password_reset`;
const USERS_URL = `${API_BASE_URL}/users`;

export const getWorkshopToken = async () => {
  // alert(`SID: ${sid}`)
  
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/war_token`,
    method: "GET",
    headers: requestHeaders().headers,
  };

  let res = await axios(options);
  cookie.remove("war_token");
  if(res.data.war_api_token){
    cookie.set("war_token", res.data.war_api_token);
  }
  
 
  // login(res.data.api_token, redirectUrl);
  return res;
};

export const fetchSessionData = (ctx?: any) => async (dispatch: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/me`,
    method: "GET",
    headers: requestHeaders(ctx).headers,
  };
  let res: ActionResponse<ISessionData> = await axios(options);
  if (res) {
    const action: IFetchSessionAction = {
      type: FETCH_SESSION,
      payload: res.data,
    };
    dispatch(action);
    return res;
  }
};

export const loginBySIDToken = async (sid: string, redirectUrl?: string) => {
  // alert(`SID: ${sid}`)
  const data = { token: sid };
  const options: AxiosRequestConfig = {
    url: `${USERS_URL}/LoginByToken/${sid}`,
    method: "POST",
    data: data,
  };

  let res = await axios(options);
  if(res.status === 200 || res.status === 201 ){
    cookie.remove("token");
    cookie.set("token", res.data.api_token);

    history.push({
      pathname: "/",
      search: ``,
    });
  }
 
  // login(res.data.api_token, redirectUrl);
  return res;
};



export const standardSignIn: IStandardSignIn = async (
  data: IStandardLoginFormValues,
  redirectUrl?: string | null
) => {
  data.front_date = FRONT_DATE;
  const options: AxiosRequestConfig = {
    url: `${LOGIN_URL}`,
    method: "POST",
    data,

    // headers:  requestHeaders().headers ,
  };
  let res : ActionResponse<ISessionData> = await axios(options);

  login(res.data.api_token, redirectUrl);
  return res;
};

export interface IChangePassword {
  (data: { email: string }): any;
}

// export const changePassword: IChangePassword = (data: {
//   email: string;
// }) => async (dispatch: Dispatch<any>) => {
//   const options: AxiosRequestConfig = {
//     url: PASSWORD_RESETS_URL,
//     method: "POST",
//     data,
//     headers: requestHeaders().headers,
//   };
//   let res = await axios(options);

//   const action: IChangePasswordAction = {
//     type: SEND_EMAIL,
//     payload: { password_sent_info: res.data },
//   };
//   dispatch(action);

//   history.push(`${PAGE.EMAIL_SENT}`);

//   return res;
// };

export const changePassword = async (data: IPasswordFormValues) =>  {
  const options: AxiosRequestConfig = {
    url: PASSWORD_RESETS_URL,
    method: "POST",
    data,
    headers: requestHeaders().headers,
  };
  let res = await axios(options);

  // history.push(`${PAGE.EMAIL_SENT}`);

  return res;
};

export const setNewPassword = async (data: any) => {
  const options: AxiosRequestConfig = {
    url: `${API_BASE_URL}/new_password`,
    method: "POST",
    data,
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  res = {
    ...res,
    successMessage: "Hasło użytkownika zostało zmienione",
  };
  return res;
};

export const logout = async () => {
  const options: AxiosRequestConfig = {
    url: `${LOGOUT_URL}`,
    method: "DELETE",
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<any> = await axios(options);
  cookie.remove("token");
  cookie.remove("war_token");
  cookie.remove("disable_popup");
  res = {
    ...res,
    successMessage: "Użytkownik został poprawnie wylogowany",
  };
  if (res) {
    return res;
  }
};
export interface IAddTranslationsToCart {
  (traslationIds: string[]): any;
}

export const addTranslationsToCart: IAddTranslationsToCart = (
  traslationIds: string[]
) => async (dispatch: Dispatch<any>) => {
  const action: IAddToCartAction = {
    type: ADD_TO_CART,
    payload: { cart: { translationIds: traslationIds } },
  };
  dispatch(action);
};

export const registerUser = async (data: IRegisterReq) => {
  const options: AxiosRequestConfig = {
    url: `${USERS_URL}/register`,
    method: "POST",
    data,
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<{ debug_token?: string }> = await axios(options);

  if (res) {
    res = {
      ...res,
      successMessage: `Wysłano e-mail potwierdzający założenie konta`,
    };
    return res;
  }
};

export const confirmEmail = async (token: string) => {
  const options: AxiosRequestConfig = {
    url: `${USERS_URL}/confirm_email`,
    method: "POST",
    data: {
      token,
    },
    headers: requestHeaders().headers,
  };
  let res: ActionResponse<{ token: string }> = await axios(options);
  if (res) {
    return res;
  }
};

// export const addItemsToCart = async (translationIds : string[]) => {
//   let translationsString = translationIds.join(',');
//   const oldTranslationsString : string = window.localStorage['package_translations'];
//   translationsString += oldTranslationsString;
//   window.localStorage.setItem('package_translations', translationsString);
// };

// export const getCartItems = async () => {
//   const translationsStr : string = window.localStorage['package_translations'];
//   const translationsArray = translationsStr.split(',');
//   return translationsArray;
// };
