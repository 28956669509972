import React from "react";
import { Formik } from "formik";
import FormikTextField from "components/form-fields/formik-text-field";
import FormButton from "components/buttons/form-button";
import Spinner from "components/core/spinner";
import * as Yup from "yup";
import { yupRequired, newPassword, isPasswordCorrect } from "validators";
import {INewPassword } from "types/login-types";
import  "styles/pages/login.scss";
import { IActionState } from "types/async-types";
import { setNewPassword } from "actions/user";
import { useAsyncAction } from "utils/async-action";
import { PAGE } from "paths";
import BlockIcon from "@material-ui/icons/Block";
import { useHistory } from "react-router-dom";
interface IProps {
  token : string;
}

export interface INewPasswordFormValues {
  password : string;
  password_confirmation :string;
  token : string;
} 

function RegisterForm(props : IProps) {
  // props & hooks //
  const router = useHistory();
  const [passwordState, execSetNewPassword]: readonly [
    IActionState<any>,
    typeof setNewPassword
  ] = useAsyncAction(setNewPassword, {customError : true});

  // functions //
  const Register = async (values : INewPasswordFormValues) => {
    const data = {...values, token : props.token};
  const res = await execSetNewPassword(data); 
    
    if((res?.status === 200) || (res?.status === 201)){
      router.push(`${PAGE.LOGIN}`);
    }
   
  };

  // form fields data
  const fields : {
    password : React.ComponentProps<typeof FormikTextField>,
    passwordRepeat : React.ComponentProps<typeof FormikTextField>,
  } = {
    password: {
      name: "password",
      type: "new-password",
      label: "Hasło",
      required: true,
      validate : isPasswordCorrect
    },
    passwordRepeat: {
      name: "password_confirmation",
      type: "password",
      label: "Powtórz hasło",
      required: true,
    },
  };

  // Formik setup props
  const initialValues: any = {
    password_confirmation: "",
    password: "",
  };
  let validationSchema = Yup.object().shape({
    password: Yup.string().concat(newPassword).concat(yupRequired),
    password_confirmation: Yup.string().concat(yupRequired).oneOf([Yup.ref('password'), ""], 'Wartości pól muszą być identyczne'),
  });

  return (
    <div>
       {(passwordState.loading) && <Spinner />}
      <Formik
        autoComplete="off"
        className="login-form"
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={initialValues}
        onSubmit={Register}
        // onSubmit={codeSent ? logIn : getCode}
      >
        {({submitForm, isValid }) => {
          
          return (
            <div>
                  <h1 className="mb-10">Nowe hasło</h1>
              <p className="mb-20">
              Ustaw swoje nowe hasło do systemu Integrator.
              </p>
              {passwordState.error && (
                <div className="error-message flex-left">
                  <div className="icon red">
                    <BlockIcon fontSize="large" />
                  </div>
                  <div>
                    <div className="lg-t bold red">Błąd</div>
                    <p className="mb-0">
                      {passwordState.response?.data?.error_message}
                    </p>
                  </div>
                </div>
              )}
              <div className="mb-30">
              <FormikTextField {...fields.password}   />
              <FormikTextField  {...fields.passwordRepeat}   />
              </div>
              <FormButton
                disabled={!isValid} 
                title="Potwierdź"
                color="primary"
                onClick={submitForm}
              />
            </div>
          );
        }}
      </Formik>
      
    </div>
  );
}

export default RegisterForm;
