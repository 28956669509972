import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";

import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
// import CompanyProfileDropdown from 'components/company-profile/company-profile-dropdown';
import Logo from "components/layout/logo";
import Saldo from "components/layout/saldo";
import Layout from "antd/lib/layout";
import Icon from "antd/lib/icon";
import Badge from "antd/lib/badge";
import BellIcon from "@material-ui/icons/Notifications";
import UserIcon from "@material-ui/icons/AccountCircle";
import { isMobile } from "react-device-detect";
import Dropdown from "antd/lib/dropdown";
import UserDropdown from "components/user/user-dropdown";
// import AddIcon from "@material-ui/icons/Add";
// const { Menu, Dropdown } = antd;
// import Menu from "antd/lib/menu";
import { IActionState } from "types/async-types";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { logout } from "actions/user";
import Statistic from "antd/lib/statistic";
import { fetchPayments } from "actions/dashboard";
// import Dropdown from 'antd/lib/dropdown';
const { Header } = Layout;
const Navbar = (props: any) => {
  const { setUserLoading, fixed } = props;
  const router = useHistory();
  const [logoutState, execLogout]: readonly [
    IActionState<any>,
    typeof logout
  ] = useAsyncAction(logout);
  const [paymentsState, execFetchPayments]: readonly [
    any,
    any
  ] = useReduxAction(fetchPayments, "user");

  const isGuestUser = (): boolean => {
    let urlParams = new URLSearchParams(window.location.search);

    return typeof urlParams.get("token") === 'string'
  }

  const [isGuestUserState, setIsGuestUserState] = useState(isGuestUser);

  useEffect(() => {
    if (!isGuestUserState) {
      execFetchPayments();
    }
  }, []);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const sessionState = useSelector((state: RootState) => state.user.session);
  const logoutUser = async () => {
    const res = await execLogout();
    if (res?.status === 200) {
      router.push(PAGE.LOGIN);
    }
  };
  // const menu = (
  //   <Menu>
  //     <Menu.Item>
  //       <a onClick={logoutUser}>Wyloguj</a>
  //     </Menu.Item>
  //   </Menu>
  // );

  useEffect(() => {
    setUserLoading(logoutState.loading);
  }, [logoutState.loading]);

  // const activeCompany = sessionState?.profiles.find((value) => {
  //   return value.is_active === true;
  // });

  const onVisibleChange = (e: boolean) => {
    setDropdownVisible(e);
  };

  return (
    <Header className={`header ${fixed ? 'header--fixed' : ''}`}>
      <div className="header__logo">
        {isMobile && (
          <Icon
            //@ts-ignore
            className="trigger"
            type="menu"
            onClick={props.hamburgerClick}
          />
        )}
        <Logo onClick={props.handleMenuClick} />

        {/* <Button
          color="primary"
          inline={true}
          Icon={() => <AddIcon />}
          title="Na skróty"
        ></Button> */}
      </div>
      <div className="flex partner">
        <div className="ant-statistic">
          <div className="ant-statistic-content-value-int flex"> <img alt="tom-doleko-ekola logo" src="/static/img/tom@2x.png" />TOM-DOLEKO-EKOLA</div>

        </div>

      </div>
      {/* <DropdownMenu /> */}
      {/* <Search className="search" placeholder="Wyszukaj pojazd..." /> */}
      <div className="header__pane">
        {/* <img
          className="help"
          src="/static/img/circle-outline.svg"
          alt="pomoc"
        /> */}
        {!isGuestUserState && <Saldo />}
        {!isGuestUserState &&

          <div className="flex">
            <a onClick={() => props.handleBellClick()}>
              <Badge count={sessionState?.notifications_count} className="hover">
                <BellIcon />
              </Badge>
            </a>

            {/* <a className="ant-dropdown-link" onClick={e => e.preventDefault()}> */}

            {/* </a> */}
          </div>
        }
        {/* {(sessionState && sessionState.profiles_count > 0) &&
            <Dropdown trigger={["click"]} overlay={<CompanyProfileDropdown />} >
            <div className="flex manage">
              <DomainIcon className="domain-icon" />
              <div className="ant-statistic">
                <div className="ant-statistic-content-value-int">Zarządzaj</div>
                <p className="ant-statistic-title">{activeCompany?.name}</p>
              </div>
              <ArrowDropDownIcon />
            </div>
            </Dropdown>
        } */}
        {!isGuestUserState &&
          <Dropdown
            visible={dropdownVisible}
            onVisibleChange={onVisibleChange}
            trigger={["click"]}
            overlay={
              <UserDropdown
                setDropdownVisible={setDropdownVisible}
                logoutUser={null}
              />
            }
          >
            <div className="flex profile-details">
              <UserIcon className="user-icon" />
            </div>
          </Dropdown>
        }
      </div>
    </Header>
  );
};

export default Navbar;
