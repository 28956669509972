import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
// import TextField from "components/form-fields/formik-text-field";
import { addressFields, FIELD } from "config/form-config/address-config";
import SelectField from "components/form-fields/select-field";
import { checkPostalCode } from "actions/addresses";
import { useAsyncAction } from "utils/async-action";
import { zipCodeRegExp } from "validators";
import HookTextField from "components/form-fields/hook-form-fields/hook-text-field";
import HookSelectField from "components/form-fields/hook-form-fields/hook-select-field";

import _ from "lodash";
import Spinner from "components/core/spinner";
import { UseFormReturn, useFormContext } from "react-hook-form";
import { IOSOP1FormValues } from "types/BDO-report-types";
import { IActionState } from "types/async-types";
import { errorMessage } from "utils/toaster";

interface IProps {
  parentName?: string;
  extended?: boolean;
  formMethods: UseFormReturn<any>;
  disabled?: boolean;
  companyName?: boolean;
  disableAddressPreload?: boolean;
}

// const renderTestFields = () => {
//   let result = [];
//   for (let i = 0; i < 100; i++) {
//     result.push(<TextField name={`test_${i}`} label="test field" />);
//   }
//   return <div>{result}</div>;
// };

const AddressSection = (props: IProps) => {
  // const formikProps = useFormikContext<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [cityOpts, setCityOpts] = useState<Array<any>>([]);
  const [streetOpts, setStreetOpts] = useState<Array<any>>([]);
  const [communeOpts, setCommuneOpts] = useState<Array<any>>([]);
  const [poviatOpts, setPoviatOpts] = useState<Array<any>>([]);
  const [postalOfficeOpts, setPostalOfficeOpts] = useState<Array<any>>([]);
  const [postalCodeData, execCheckPostalCode]: readonly [
    IActionState<any>,
    typeof checkPostalCode
  ] = useAsyncAction(checkPostalCode);
  // const { setFieldValue, setFieldTouched, values } = formikProps;

  const { parentName, extended } = props;

  const formMethods = useFormContext<any>();

  const namePrefix = parentName ? `${parentName}.` : "";

  useEffect(() => {
    const res = postalCodeData;

    if (res.data) {
      if (res.data.length === 0) {
        setIsLoading(false);
        errorMessage("Brak danych adresowych dla podanego kodu");
        return;
      }

      res.data.map((item: any, index: number) => {
        setStreetOpts((oldArray: Array<any>) => [
          ...oldArray,
          { value: item.street, label: item.street },
        ]);

        setCityOpts((oldArray: Array<any>) => [
          ...oldArray,
          { value: item.city, label: item.city },
        ]);

        setPostalOfficeOpts((oldArray: Array<any>) => [
          ...oldArray,
          { value: item.post_office, label: item.post_office },
        ]);
      });

      setCityOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));
      setPostalOfficeOpts((oldArray) => _.uniqWith(oldArray, _.isEqual));

      formMethods.setValue(`${namePrefix}post_office`, res.data[0].post_office);

      formMethods.setValue(`${namePrefix}city`, res.data[0].city);
      formMethods.setValue(`${namePrefix}street`, res.data[0].street);
      formMethods.setValue(`${namePrefix}voivodeship`, res.data[0].voivodeship);
      if (extended) {
        formMethods.setValue(`${namePrefix}poviat`, res.data[0].poviat);
        formMethods.setValue(`${namePrefix}commune`, res.data[0].commune);
      }
    }
  }, [postalCodeData.data]);

  const preloadAddress = (value: string) => {
    if (props.disableAddressPreload) {return ''} 
    const text = value;
    // formMethods.setValue(
    //   `${namePrefix}${addressFields.zipCode.name}`,
    //   value
    // );

    // setFieldTouched(FIELD.ZIP_CODE, true, false);
    if (zipCodeRegExp.test(text)) {
      setTimeout(async function () {
        setIsLoading(true);
        setStreetOpts([]);
        setCityOpts([]);
        const res = await execCheckPostalCode(text);
        setIsLoading(false);
      }, 500);
    }
  };

  return (
    <>
      {isLoading && <Spinner />}

      {props.companyName && (
        <Row gutter={10}>
          <Col md={24}>
            <HookTextField
              formMethods={formMethods}
              {...addressFields.companyName}
              required={true}
              name={`${namePrefix}${addressFields.companyName.name}`}
              rules={{
                required: "Pole jest wymagane",
              }}
              disabled={props.disabled}
            />
          </Col>
        </Row>
      )
      }

      <Row gutter={10}>
        <Col md={4}>
          <HookTextField
            formMethods={formMethods}
            {...addressFields.zipCode}
            required={true}
            name={`${namePrefix}${addressFields.zipCode.name}`}
            onChange={preloadAddress}
            rules={{
              required: "Pole jest wymagane",
            }}
            disabled={props.disabled}
          />
        </Col>
        <Col md={8}>
          <HookTextField
            {...addressFields.post_office}
            name={`${namePrefix}${addressFields.post_office.name}`}
            formMethods={formMethods}
            required={true}
            rules={{
              required: "Pole jest wymagane",
            }}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      <Row gutter={10}>
        <Col md={6}>
          <HookTextField
            {...addressFields.city}
            formMethods={formMethods}
            name={`${namePrefix}${addressFields.city.name}`}
            rules={{
              required: "Pole jest wymagane",
            }}
            required={true}
            disabled={props.disabled}
          />
        </Col>
        <Col md={2}>
          <HookSelectField
            formMethods={formMethods}
            {...addressFields.aupType}
            name={`${namePrefix}${addressFields.aupType.name}`}
            disabled={props.disabled}
          />
        </Col>
        <Col md={10}>
          <HookTextField
            formMethods={formMethods}
            {...addressFields.street}
            name={`${namePrefix}${addressFields.street.name}`}
            // disabled={formMethods.getValues().aup_type === "-" ? true : false}
            disabled={props.disabled}
          />
        </Col>
        <Col md={3}>
          <HookTextField
            formMethods={formMethods}
            {...addressFields.building}
            name={`${namePrefix}${addressFields.building.name}`}
            required={true}
            rules={{
              required: "Pole jest wymagane",
            }}
            disabled={props.disabled}
          />
        </Col>
        <Col md={3}>
          <HookTextField
            formMethods={formMethods}
            {...addressFields.locum}
            name={`${namePrefix}${addressFields.locum.name}`}
            disabled={props.disabled}
          />
        </Col>
      </Row>

      {extended && (
        <Row gutter={10}>
          <Col md={8}>
            <HookTextField
              formMethods={formMethods}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
              label="Gmina"
              name={`${namePrefix}commune`}
              disabled={props.disabled}
            />
          </Col>
          <Col md={8}>
            <HookTextField
              formMethods={formMethods}
              label="Powiat"
              name={`${namePrefix}poviat`}
              disabled={props.disabled}
            />
          </Col>
          <Col md={8}>
            <HookSelectField
              formMethods={formMethods}
              {...addressFields.voivodeship}
              name={`${namePrefix}${addressFields.voivodeship.name}`}
              required={true}
              rules={{
                required: "Pole jest wymagane",
              }}
              disabled={props.disabled}
            />
          </Col>
        </Row>
      )}
      {!extended && (
        <HookSelectField
          {...addressFields.voivodeship}
          formMethods={formMethods}
          name={`${namePrefix}${addressFields.voivodeship.name}`}
          required={true}
          rules={{
            required: "Pole jest wymagane",
          }}
        />
      )}
    </>
  );
};

export default AddressSection;
