import React, { Component } from "react";
import Badge from "antd/lib/badge";
import Button from "components/buttons/button";

//@ts-ignore
import "styles/layout/notification-card.scss";
import { INotification } from "types/notification-types";
import { formatDateTime } from "utils/date";

interface IProps {
  data?: INotification;
}

const NotificationCard = (props: IProps) => {
  const { data } = props;

  let cardType = "";
  switch (data?.type) {
    case "ALERT":
      cardType = "alert";
      break;
    case "WARNING":
      cardType = "warning";
      break;
    case "CONFIRM":
      cardType = "confirm";
      break;
    default:
      cardType = "default";
      break;
  }

  const linkRegexExp = new RegExp("\\^(.+?)\\^", "g");
  let message: string = data?.message || "";
  const links = Array.from((message).matchAll(linkRegexExp), (m) => m[1]);
  message = message.replace(linkRegexExp, "");
  const msgArray = message?.split("#");
  let messageHtml = msgArray?.map((item) => <p>{item}</p>);

  links.forEach((link) => {
    const cc = link.split("|");
    messageHtml.push(
      <p>
        <a href={`${cc[1]}`} target="_blank" rel="noreferrer">
          {cc[0]}
        </a>
      </p>
    );
  });

  return (
    <article className={`card card--new card--${cardType}`}>
      {/* <span className="card__car">{data.car}</span> */}
      <h4 className="card__title">{data?.title}</h4>
      {/* <p className="card__description">{data.message}</p> */}
      <div className="card__description">{messageHtml}</div>
      {/* <div className="flex">
        {data.url && (
          <Button
            title={data.urlTitle}
            link={data.url}
            color="primary"
            half={data.cancel ? "button--half" : undefined}
          />
        )}
        {data.cancel && (
          <Button
            title={data.cancel}
            color="white"
            half={data.cancel ? "button--half" : undefined}
          />
        )}
      </div> */}
      <div className="card__footer">
        <span className="primary-text">
          {formatDateTime(data?.published_date)}
        </span>
        {/* <span>{data.author}</span> */}
      </div>
    </article>
  );
};

export default NotificationCard;
