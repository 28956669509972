import React, { useEffect } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { userFields } from "config/user-fields";
import HookSelectField from "components/form-fields/hook-form-fields/hook-select-field";
import CheckFormField from "components/form-fields/hook-form-fields/hook-check-field";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import RadioGroup, {
  IRadioGroupProps,
} from "components/form-fields/hook-form-fields/hook-radio-group";
import WarningIcon from "@material-ui/icons/Warning";
import FormButtons from "components/common/form-buttons";
import {
  ISummaryFormData,
  ISummaryFormValues,
  ReportType,
} from "types/BDO-report-types";
import Spinner from "components/core/spinner";
import FormButton from "components/buttons/form-button";
import { useForm, FormProvider } from "react-hook-form";
import StatCounter from "components/common/stat-counter";
import { numToCurrency, arrayEquals } from "utils/helpers";
import { AgreementsList } from "components/home/services-list";
import { isPhoneCorrectHook } from "validators";
import { useGraphForm } from "./graph-form-utils";
import { renderJsonOptions } from "utils/select-options";

const BDOYearReportStep5 = (props: any) => {
  const { setNavState } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const {
    state,
    initializeStep,
    saveStep,
    goToStep,
    formData,
    stepNavData,
  } = useGraphForm<ISummaryFormData>(id);

  const isElectronic = arrayEquals(formData.data?.bdo_report_types, [
    "draft",
    "submitted",
  ]);


  const network = formData.data?.network;
  const campaign = formData.data?.campaign;

  const formMethods = useForm<ISummaryFormValues>({
    mode: "onTouched",
    defaultValues: {
      name: "",
      surname: "",
      phone_number: "",
      email: "",
      marshal_id: "",
      bdo_report_type: "submitted",
      declaration_of_data_correctness: false,
    },
  });

  const { reset } = formMethods;

  useEffect(() => {
    initializeStep("summary");
    props.setNavState({
      goToStep,
    });
  }, []);

  useEffect(() => {
    if (formData.data) {
      reset({
        business_start_date: formData.data?.network?.business_start_date,
        marshal_id: formData.data?.marshal,
        bdo_report_type: isElectronic ? "submitted" : "paper",
      });
    }
  }, [formData.data, reset]);

  useEffect(() => {
    setNavState({
      goToStep,
      stepNavData,
    });
  }, [stepNavData]);

  const submitForm = (values: any) => {
    saveStep("summary", values);
  };


  const methodField: IRadioGroupProps<ReportType> = {
    orientation: "vertical",
    name: "bdo_report_type",
    formMethods,
    label: "",

    options: [
      {
        value: "submitted",
        label: (
          <div>
            <p className="">
              <span className="mr-5">Złóż i zatwierdź sprawozdanie do BDO</span>{" "}
              <p className="sm-t grey">
                Przekazanie i zamknięcie sprawozdania.
              </p>
            </p>
          </div>
        ),
      },
      {
        value: "draft",
        label: (
          <div>
            <p className="">
              Złóż wersję roboczą (częściową) sprawozdania rocznego do BDO
            </p>
            <p className="sm-t grey">
              <span className="red">UWAGA: </span> Tylko dla tych podmiotów,
              które z tytułu prowadzonej działalności wprowadzają i wytwarzają
              odpady inne niż objęte umowami z Integratorem lub/i TOM.
            </p>
          </div>
        ),
      },
    ],
  };

  const paperMethodField: IRadioGroupProps<ReportType> = {
    orientation: "vertical",
    name: "method",
    formMethods,
    label: "",
    options: [
      {
        value: "paper",
        label: (
          <div>
            <p className="">
              Złóż wersję papierową sprawozdania rocznego do BDO
            </p>
            {/* <p className="sm-t grey">
              <span className="red">UWAGA: </span> Tylko dla tych podmiotów,
              które z tytułu prowadzonej działalności wprowadzają odpady inne
              niż objęte umowami z Integratorem i TOM i mają obowiązek
              wypełnienia innych tabel poza Integratorem.
            </p> */}
          </div>
        ),
      },
    ],
  };



  return (
    <FormProvider {...formMethods}>
      <div>
        {state.isLoading && <Spinner size="large" fixed={true} />}
        <div className="block">
          <p className="large mb-0">
            Sprawozdanie zostało przygotowane. Sprawdź czy wszystkie dane są
            poprawne.
          </p>
        </div>
        {network && (
          <div className="block">
            <section>
              <h2 className="h-lg">
                Sprawozdanie o niezapewnieniu Sieci Stacji Demontażu.
              </h2>
              <Row gutter={10}>
                <Col lg={8}>
                  <StatCounter
                    amount={numToCurrency(network?.network_fee || 0)}
                    color={(network?.network_fee || 0) > 0 ? "red" : "green"}
                    title="Należna opłata za brak sieci"
                    suffix="Wysokość należnej opłaty do Urzędu Marszałkowskiego wyliczona na podstawie wprowadzonych danych."
                  />
                  {(network?.network_fee || 0) > 0 && (
                    <div className="red md-t flex-top-left">
                      <WarningIcon className="md-t mr-5" />{" "}
                      <p className="red">
                        Jeżeli nie zgadzasz się z wyliczoną kwotą sprawdź dane
                        wpisane poniżej. Maja one wpływ na należną opłatę.{" "}
                      </p>
                    </div>
                  )}
                </Col>
                <Col lg={8}>
                  <StatCounter
                    amount={network?.days_of_year_without_network || 0}
                    color={
                      (network?.days_of_year_without_network || 0) > 21
                        ? "red"
                        : "green"
                    }
                    title="Dni bez sieci"
                    suffix="Wyliczona liczba dni w roku kalendarzowym, w których nie zapewniono sieci."
                  />
                </Col>
                <Col lg={8}>
                  <StatCounter
                    amount={network?.vehicle_count || 0}
                    color="heading"
                    title="Liczba pojazdów"
                    suffix="osobowych i dostawczych do 3,5 tony wprowadzonych w 2023 roku (podlegających obowiązkowi zapewnienia sieci)"
                  />
                </Col>
              </Row>
            </section>

            <div className="mb-15">
              <h2>Dane działalności</h2>
              <Row>
                <Col xxl={4}>
                  <TextField
                    disabled={true}
                    name="business_start_date"
                    label="Data rozpoczęcia działalności gospodarczej"
                    type="date"
                    formMethods={formMethods}
                  />
                </Col>
              </Row>
            </div>

            <section>
              <h2>Umowy o zapewnienie sieci obowiązujące w 2023 roku</h2>
              <AgreementsList
                items={[
                   ...(network?.other_agreements || []),
                  network?.integrator_agreement,
                ]}
              />
              {/* <ServicesList products={recycleServicesState.data?.products} /> */}
            </section>
          </div>
        )}
        {campaign && (
          <div className="block">
            <h2 className="h-lg">
              Informacja o przeprowadzonych publicznych kampaniach edukacyjnych.
            </h2>
            <Row gutter={10} className="mb-25">
              <Col lg={12}>
                <StatCounter
                  amount={numToCurrency(formData.data?.campaign.funds_min)}
                  color="heading"
                  title="Minimalna wysokość środków"
                  suffix="Minimalna wysokość środków, które należy przeznaczyć na publiczne kampanie edukacyjne"
                />
              </Col>
              <Col lg={12}>
                <StatCounter
                  amount={numToCurrency(
                    formData.data?.campaign.funds_transferred
                  )}
                  color="heading"
                  title="Wysokość środków przekazanych"
                  suffix="Wysokość środków przeznaczonych na publiczne kampanie edukacyjne przekazanych na odrębny rachunek bankowy Marszałka Województwa"
                />
              </Col>
            </Row>
            <Row gutter={10} className="mb-0">
              <Col lg={12}>
                <StatCounter
                  amount={numToCurrency(
                    formData.data?.campaign.funds_transferred_own
                  )}
                  color="heading"
                  title="Środki przekazane we własnym zakresie"
                  suffix="Wysokość środków przeznaczonych na publiczne kampanie edukacyjne we własnym zakresie"
                />
              </Col>
              <Col lg={12}>
                <StatCounter
                  amount={formData.data?.campaign.funds_purpose || null}
                  color="heading"
                  title="Sposób wykorzystania"
                  suffix="Sposób wykorzystania środków przeznaczonych na publiczne kampanie edukacyjne."
                />
              </Col>
            </Row>
          </div>
        )}

        <div className="block">
          <h2 className="h-lg">Osoba sporządzająca sprawozdanie</h2>
          <Row gutter={15}>
            <Col md={6}>
              <TextField
                label="Imię"
                name="name"
                required={true}
                formMethods={formMethods}
                rules={{
                  required: "Pole jest wymagane",
                }}
              />
            </Col>
            <Col md={6}>
              <TextField
                label="Nazwisko"
                name="surname"
                required={true}
                formMethods={formMethods}
                rules={{
                  required: "Pole jest wymagane",
                }}
              />
            </Col>
            <Col md={6}>
              <TextField
                {...userFields.phone}
                formMethods={formMethods}
                name="phone_number"
                rules={{
                  required: "Pole jest wymagane",
                  validate: isPhoneCorrectHook,
                }}
              />
            </Col>
            <Col md={6}>
              <TextField
                {...userFields.email}
                formMethods={formMethods}
                rules={{
                  required: "Pole jest wymagane",
                }}
              />
            </Col>
          </Row>
        </div>
        <div className="block">
          <h2 className="h-lg">Adresat sprawozdania</h2>
          <HookSelectField
            name="marshal_id"
            label="Marszałek Województwa"
            formMethods={formMethods}
            options={renderJsonOptions(formData.data?.marshals || [])}
          />
        </div>
        <div className="block">
          {isElectronic ?
            <h2 className="h-lg">Rodzaj przekazania i oświadczenie</h2>
            : <h2 className="h-lg">Oświadczenie</h2>
          } 
           
          {isElectronic && 
            <RadioGroup {...methodField} />
          }
          {/* <h2>Oświadczenie</h2> */}
          <CheckFormField
            formMethods={formMethods}
            cbxLabel="Oświadczam że wszystkie powyższe dane są prawdziwe i zgodne ze stanem faktycznym"
            name="declaration_of_data_correctness"
            value={true}
            isFlag={true}
            required={true}
            rules={{
              required: "Pole jest wymagane",
            }}
          />
        </div>
      </div>
      <FormButtons>
        <FormButton
          width={300}
          color="primary"
          onClick={formMethods.handleSubmit(submitForm)}
          title={
            isElectronic
              ? "Potwierdź i wyślij sprawozdanie"
              : "Potwierdź i wygeneruj sprawozdanie"
          }
          inline={true}
        />
      </FormButtons>
    </FormProvider>
  );
};

export default BDOYearReportStep5;
