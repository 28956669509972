import React, { useEffect, useState } from "react";
import FormStatusScreen from "components/common/form-status-screen";
import Button from "components/buttons/button";
import SimpleLayout from "components/layout/simple-layout";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { IActionState } from "types/async-types";
import {
  checkOSOP1PaymentStatus,
  retryOSOP1Payment,
} from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";
import { PAGE } from "paths";
import { useHistory } from "react-router-dom";
import {
  checkDocumentsPaymentStatus,
  retryDocumentsPayment,
} from "actions/accounting-documents";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";

const PaymentStatus = (props: any) => {
  const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState<"PEN" | "FIN" | "ERR">(
    "PEN"
  );
  const [myTimeout, setMyTimeout] = useState<NodeJS.Timeout>();

  const [id, setId] = useState<string | undefined>();

  const [paymentStatusState, execCheckPaymentStatus]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof checkDocumentsPaymentStatus
  ] = useAsyncAction(checkDocumentsPaymentStatus);

  const [retryPaymentState, execRetry]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof retryDocumentsPayment
  ] = useAsyncAction(retryDocumentsPayment);

  //    ------- REGION paymentStatus  ------------
  const checkPaymentStatus = () => {
    //if it's not in the summary,
    if (window.location.pathname !== PAGE.DOCUMENT_PAYMENT_STATUS) {
      return;
    }

    if (id) {
      execCheckPaymentStatus(id);
      const timer = setTimeout(checkPaymentStatus, 5000);

      setMyTimeout(timer);
    }
  };

  useEffect(() => {
    if (paymentStatusState.data?.payment_status === "FIN") {
      setTimeout(() => {
        history.push(PAGE.SETTLEMENTS);
      }, 2000);
      setPaymentStatus("FIN");
    }
  }, [paymentStatusState.data]);

  useEffect(() => {
    if (paymentStatusState.response?.status === 422) {
      setPaymentStatus("ERR");
    }
  }, [paymentStatusState.response]);

  useEffect(() => {
    let myTimeout: NodeJS.Timeout | null = null;
    const checkPaymentStatus = () => {
      if (id) {
        execCheckPaymentStatus(id);
        const timer = setTimeout(checkPaymentStatus, 5000);
        
        myTimeout = timer;
      }
    };

    if (paymentStatus === "PEN") {
      if (id) {
        
        checkPaymentStatus();
      }
    } else if (myTimeout) {
      clearTimeout(myTimeout);
    }

    return function cleanUp() {
      if (myTimeout) {
        clearTimeout(myTimeout);
      }
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus, id]);

  //    ------- ENDREGION paymentStatus  -------------

  // ---------- REGION RetryPayment ------------------
  const retryPayment = async () => {
    if (id) {
      await execRetry(id);
    }
  };

  useEffect(() => {
    if (retryPaymentState.data) {
      window.location.href = retryPaymentState.data.payment_address;
      setPaymentStatus("PEN");
    }
  }, [retryPaymentState.data]);

  // ---------- ENDREGION RetryPayment ------------------

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id) {
      setId(id);
    }
    // if (paymentStatus !== "PEN" && myTimeout) {
    //   clearTimeout(myTimeout);
    // }

    // return function cleanup() {
    //   
    //   if (myTimeout) {
    //     clearTimeout(myTimeout);
    //   }
    // };
  }, []);

  switch (paymentStatus) {
    case "PEN":
      return (
        <div className="bdo-report-form">
          <div className="form-content">
            <FormStatusScreen
              title="Weryfikacja płatności"
              description="Trwa oczekiwanie na potwierdzenie realizacji płatności w serwisie przelewy24"
              type="loading"
              aditionalContent={
                <div className="t-center">
                 
                   <Button
                    link={PAGE.SETTLEMENTS}
                    width={300}
                    className="i-b"
                    title="Powrót do rozliczeń"
                    color="white"
                  />{" "}
                </div>
              }
            />
          </div>
        </div>
      );
    case "FIN":
 
      return (
        <div className="bdo-report-form">
        <div className="form-content">
        <FormStatusScreen
          title="Płatność powiodła się. Dziękujemy."
          type="success"
        />
        </div>
        </div>
      );
    case "ERR":
      return (
        <div className="bdo-report-form">
        <div className="form-content">
        <FormStatusScreen
          title="Płatność nie powiodła się.."
          description="Nie otrzymaliśmy potwierdzenia wykonania płatności od Przelewy 24"
          type="error"
          aditionalContent={
            <div className="t-center">
              <Button
                onClick={() => retryPayment()}
                width={300}
                className="i-b"
                title="Ponów płatność"
                color="primary"
              />{" "}
               <Button
                link={PAGE.SETTLEMENTS}
                width={300}
                className="i-b"
                title="Powrót do rozliczeń"
                color="white"
              />{" "}
            </div>
          }
        />
             </div>
        </div>
      );
  }
};

export default SimpleLayout(withAuthSync(PaymentStatus));
