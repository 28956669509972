import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/buttons/button";
import BlockIcon from "@material-ui/icons/Block";
import { fetchNetworkAssurance } from "actions/agreements";
import { IActionState } from "types/async-types";
import { IAgreement } from "types/agreement-types";
import { useAsyncAction } from "utils/async-action";
import TextField from "components/form-fields/formik-text-field";
import Help from "@material-ui/icons/HelpOutline";
import { Formik, FormikProps } from "formik";
import CheckIcon from "@material-ui/icons/Check";
import { Row, Col } from "antd";
import "styles/pages/bdo-key.scss";
import {
  IBDONumberReq,
  IBDONumberHistory,
  IBDORegistryData,
} from "types/BDO-report-types";
import {
  fetchBDONumber,
  updateBDONumber,
  fetchBDONumberHistory,
} from "actions/BDO-numbers";
import Spinner from "components/core/spinner";
import { formatDateTime } from "utils/date";
import WithBDONumber from "components/bdo-registry/with-bdo-number";
import WithoutBDONumber from "components/bdo-registry/without-bdo-number";
import { fetchBDORegistryData } from "actions/BDO-reports";
import { renderBreadcrumbs } from "utils/helpers";

const NetworkProvision = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  // const [BDONumberState, execFetchBDONumber]: readonly [
  //   IActionState<IBDONumberReq>,
  //   typeof fetchBDONumber
  // ] = useAsyncAction(fetchBDONumber);

  const [registryDataState, execFetchData]: readonly [
    IActionState<IBDORegistryData>,
    typeof fetchBDORegistryData
  ] = useAsyncAction(fetchBDORegistryData);

  const [updateState, execUpdateBDONumber]: readonly [
    IActionState<any>,
    typeof updateBDONumber
  ] = useAsyncAction(updateBDONumber);

  const [historyState, execFetchBDONumberHistory]: readonly [
    IActionState<IBDONumberHistory>,
    typeof fetchBDONumberHistory
  ] = useAsyncAction(fetchBDONumberHistory);

  useEffect(() => {
    // execFetchBDONumber();
    // execFetchBDONumberHistory();
    execFetchData();
  }, []);

  useEffect(() => {
    if (updateState.data) {
      // execFetchBDONumber();
      execFetchData();
      // execFetchBDONumberHistory();
    }
  }, [updateState.data]);

  const renderHistory = () => {
    if (historyState.data) {
      return historyState.data.data.map((item, index) => {
        return (
          <tr>
            <td>{formatDateTime(item.updated_at)}</td>
            <td className="black">{item.active ? "Aktywny" : "Nieaktywny"}</td>
          </tr>
        );
      });
    }
  };

  const onSubmit = (values: { bdo_number: string }) => {
    execUpdateBDONumber(values);
  };
  if (registryDataState.loading || historyState.loading) {
    return <Spinner size="large" />;
  }
  return (
    <div className="sider-page network-provision">
      {updateState.loading && <Spinner size="large" />}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Usługi BDO", "Rejestr BDO"])}
          {siderCollapsed && (
            <div className="help-icon">
              {" "}
              <Help onClick={() => toggleSider()} />{" "}
            </div>
          )}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Rejestr BDO</h1>
        </header>

        <div className="block">
          {registryDataState.data?.bdo_number ? (
            <h1 className="green flex">
              <CheckCircle /> Numer BDO zapisany
            </h1>
          ) : (
            <h1 className="red flex">
              <BlockIcon /> Brak numeru BDO
            </h1>
          )}
          {registryDataState.data?.bdo_number ? (
            <WithBDONumber
              BDONumberState={registryDataState}
              onSubmit={onSubmit}
            />
          ) : (
            <WithoutBDONumber
              BDONumberState={registryDataState}
              onSubmit={onSubmit}
            />
          )}

          {/* <div className="block">
          <h1 className="red flex">
            <BlockIcon /> Brak klucza API (BDO)
          </h1>
          <Formik initialValues={{ bdo_number: "" }} onSubmit={onSubmit}>
            {(formikProps: FormikProps<{ bdo_number: string }>) => (
              <Row className="flex mb-20">
                <Col md={18}>
                  <TextField name="bdo_number" label="Mój klucz API (BDO)" />
                </Col>
                <Col md={6}>
                  <Button title="Zapisz" color="white" />
                </Col>
              </Row>
            )}
          </Formik>
          <p className="black mb-30">
            Pobierz klucz zgodnie z załączoną instrukcją i wklej do pola powyżej
          </p>
        </div> */}
        </div>
      </main>
      {!siderCollapsed && (
        <div
          className="sider"
          // collapsedWidth="0"
        >
          <div className="sm-l-t mb-20">
            Rejestr BDO
            <CloseIcon className="sider-close" onClick={toggleSider} />
          </div>
          <h2 className="mb-15 black">Czym jest rejestr BDO?</h2>
          <p className="black">
            BDO, czyli “Baza Danych Odpadowych”, to system elektroniczny
            opracowany przez Ministerstwo Środowiska mający na celu ewidencję
            wytwarzanych przez przedsiębiorców odpadów. Wszystkie podmioty
            wymienione w art. 50 ust. 1 oraz art. 51 ust. 1 ustawy o odpadach
            podlegają obowiązkowi rejestracji w BDO i muszą złożyć wniosek o
            wpis do Rejestru
          </p>
          <p className="black mb-0">
            Nadany numer rejestrowy należy umieszczać na dokumentach związanych
            z działalnością w zakresie produktów i odpadów, na przykład:
          </p>
          <ul className="bullet-list black mb-20">
            <li className="">
              <div className="grow-1 md-t ">faktury VAT</div>
            </li>
            <li className="">
              <div className="grow-1 md-t ">paragony fiskalne</div>
            </li>
            <li className="">
              <div className="grow-1 md-t ">umowy kupna-sprzedaży</div>
            </li>
            <li className="">
              <div className="grow-1 md-t ">sprawozdania</div>
            </li>
            <li className="">
              <div className="grow-1 md-t">karty przekazania odpadów</div>
            </li>
            <li className="">
              <div className=" md-t">karty ewidencji odpadów</div>
            </li>
          </ul>
          <p className="black mb-0">
            Należy pamiętać, że dane wprowadzone do rejestru należy aktualizować
            w przypadku zmiany:
          </p>
          <ul className="bullet-list black md-t mb-20">
            <li className="">
              informacji dotyczących danych podmiotu np. nazwa firmy, adres
              siedziby itp.
            </li>
            <li className="">
              zakresu prowadzonej działalności wymagającej wpisu do Rejestru-BDO
            </li>
          </ul>
          <div className="md-t">
            <p className="black">
              Więcej informacji: <br />
              <a target="_blank" href="https://bdo.mos.gov.pl/">
                https://bdo.mos.gov.pl/
              </a>
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(NetworkProvision));
// export default Index;
