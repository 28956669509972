//@ts-nocheck
import { useState, useCallback } from "react";
import { isLoading } from "redux/actions/async";
import { useDispatch } from "react-redux";
import { successMessage, message } from "utils/toaster";
import { handleError } from "utils/http";
import { ActionResponse, IActionState, IError } from "types/async-types";
import axios from "axios";  
import { IAxiosWrapperConfig } from "actions/BDO-reports"; 



// interface IError {
//   response: any  ;
//   customMessage?: boolean;
//   messageConfig: IMessageProps;
//   error: any;
// }

//hook for redux action creators (with API calls) - returns asyncAction, loading & error, also sets store-level loaders & error
export const useReduxAction = (
  action: any,
  stateProperty: string
) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(0);

  const asyncAction = async (...body: any[]) => {
    // async function callback() {
    const loadingData = {
      isLoading: true,
      stateProperty,
      errorCode: 0,
    };
    setLoading(true);
    dispatch(isLoading({ ...loadingData }));

    try {
      const res: ActionResponse<any> = await dispatch(action(...body));
      
      dispatch(isLoading({ ...loadingData, isLoading: false }));
      setError(0);
      setLoading(false);
      if (res.successMessage) {
        successMessage(res.successMessage);
      }
      return res;
    } catch (e) {
      
      if (!e.response) {
        throw e;
      }
      
      dispatch(
        isLoading({
          ...loadingData,
          isLoading: false,
          errorCode: e.response.status,
        })
      );
      setLoading(false);
      setError(e.response.status);
      handleError(e);

      return e;
    }
    // }

    // callback();
  };

  // [action, ...dependeces]
  // );

  return [{ loading, error }, asyncAction] as const;
};

// hook for regular (non redux) API calls
export const useAsyncAction = (action: any, config? :  { customError? : boolean, no_reset? : boolean }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any | null>(null);
  const [response, setResponse] = useState<ActionResponse<any> | null>(null);
  const [error, setError] = useState<number | null>(null);

  const performAction: () => Promise<ActionResponse<any>> = useCallback(async (
    ...body: any[]
  ) => {
    try {
      setLoading(true);
    // if(!config?.no_reset){
        setData(null);
      // }
      
      setError(null);
      const res: ActionResponse<any> = await action(...body);
      
      if (res) {
        setData(res.data);
        setResponse(res);
        if (res.successMessage) {
          message({ title: res.successMessage, type: "positive" });
        }
        if (res.messageConfig) {
          message({ ...res.messageConfig });
        }
      }
      return res;
      
    } catch (e) {
      //if error is not https-related
      if (!e.response) {
        throw e;
      }
      const errorRes: IError = e;
      setError(errorRes.response.status);
      setResponse(errorRes.response);
      if (!config?.customError) {
        
        handleError(errorRes, errorRes.messageConfig);
      }
      return e;
    } finally {
      setLoading(false);
    }
  }, [action, config?.customError]);
  return [{ data, response, loading, error }, performAction] as const;
};
//regular wrapper function for redux action creators (API) (sets only store-level loaders, errors etc)
export const reduxAction = (action: any, stateProperty = null) => async (
  dispatch: any
) => {
  const loadingData = {
    isLoading: true,
    stateProperty,
    errorCode: null,
  };

  dispatch(isLoading({ ...loadingData }));

  try {
    const res = await dispatch(action());
    dispatch(isLoading({ ...loadingData, isLoading: false }));
    if (res.successMessage) {
      successMessage(res.successMessage);
    }
    return res;
  } catch (e) {
    if (!e.response) {
      throw e;
    }

    dispatch(
      isLoading({
        ...loadingData,
        isLoading: false,
        errorCode: e.response.status,
      })
    );
    handleError(e);
    return e;
  }
};

//we use this function INSIDE action
export const asyncAxiosWrapper = async (config: IAxiosWrapperConfig) => {


  config.setItemState({data : null, loading: true, error : 0, response : null});

  try{
    const res: ActionResponse<any> = await axios(config.options);
    if (config.successMessage) {
      message({ title: config.successMessage, type: "positive" });
    }
    // if (res.messageConfig) {
    //   message({ ...res.messageConfig });
    // }
    config.setItemState({data : res.data, loading: false, response : res, error : 0});
    return res;
  }catch(e){
    ;
    const errorRes: IError = e;
    config.setItemState({data : null, loading: false, error : errorRes.response.status, response : errorRes.response});
  }

};

// we pass  an action & state setter TO this wrapper
export const asyncAction = async (action: any, setItemState : (a: IActionState<any>) => any) => {

 
  setItemState({data : null, loading: true, error : 0, response : null});

  try{
    const res: ActionResponse<any> = await action();
    if (res.successMessage) {
      message({ title: res.successMessage, type: "positive" });
    }
    if (res.messageConfig) {
      message({ ...res.messageConfig });
    }
    setItemState({data : res.data, loading: false, response : res, error : 0});
    return res;
  }catch(e){
    
    const errorRes: IError = e;
    setItemState({data : null, loading: false, error : errorRes.response.status, response : errorRes.response});
  }

};
