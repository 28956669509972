import leaflet01 from './bdo_campaign_leaflets/01.png'
import leaflet02 from './bdo_campaign_leaflets/02.png'
import leaflet03 from './bdo_campaign_leaflets/03.png'
import leaflet04 from './bdo_campaign_leaflets/04.png'
import leaflet05 from './bdo_campaign_leaflets/05.png'
import leaflet06 from './bdo_campaign_leaflets/06.png'
import leaflet07 from './bdo_campaign_leaflets/07.png'
import leaflet08 from './bdo_campaign_leaflets/08.png'
import leaflet09 from './bdo_campaign_leaflets/09.png'
import leaflet10 from './bdo_campaign_leaflets/10.png'
import leaflet11 from './bdo_campaign_leaflets/11.png'
import leaflet12 from './bdo_campaign_leaflets/12.png'
import leaflet13 from './bdo_campaign_leaflets/13.png'
import leaflet14 from './bdo_campaign_leaflets/14.png'
import leaflet15 from './bdo_campaign_leaflets/15.png'
import leaflet16 from './bdo_campaign_leaflets/16.png'
import leaflet17 from './bdo_campaign_leaflets/17.png'
import leaflet18 from './bdo_campaign_leaflets/18.png'
import leaflet19 from './bdo_campaign_leaflets/19.png'
import leaflet20 from './bdo_campaign_leaflets/20.png'

const leaflets = [
  { key: '01', file: (leaflet01) },
  { key: '02', file: (leaflet02) },
  { key: '03', file: (leaflet03) },
  { key: '04', file: (leaflet04) },
  { key: '05', file: (leaflet05) },
  { key: '06', file: (leaflet06) },
  { key: '07', file: (leaflet07) },
  { key: '08', file: (leaflet08) },
  { key: '09', file: (leaflet09) },
  { key: '10', file: (leaflet10) },
  { key: '11', file: (leaflet11) },
  { key: '12', file: (leaflet12) },
  { key: '13', file: (leaflet13) },
  { key: '14', file: (leaflet14) },
  { key: '15', file: (leaflet15) },
  { key: '16', file: (leaflet16) },
  { key: '17', file: (leaflet17) },
  { key: '18', file: (leaflet18) },
  { key: '19', file: (leaflet19) },
  { key: '20', file: (leaflet20) },
]

const leaflets_keys = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20'
]

export { leaflets, leaflets_keys }