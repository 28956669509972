import React, { useEffect, useState, useRef } from "react";
import { IActionState, ActionResponse, IListing } from "types/async-types";
import {
  
  IAccountingDocument,
  documentTypes,
  IDocumentPdf,
} from "types/document-types";
import CheckFormField from "components/form-fields/check-form-field";
import TableWrapper from "components/common/table-wrapper";
import FormButton from "components/buttons/form-button";
import CostSummary from "components/common/cost-summary";
import Spinner from "components/core/spinner";
import {
  
  fetchPaymentSummary,
  fetchAccountingDocument,
  
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import Table from "antd/lib/table";
import { Formik, FormikProps, useFormikContext } from "formik";
import { useHistory } from "react-router-dom";

import { Row, Col } from "antd";

export interface ISummary {
  can_pay_online: boolean;
  bank_transfer_details?: {
    account_number: string | null;
    recipient: string | null;
  };
  documents: IAccountingDocument[];
}

export interface ITransferSummary {
  can_pay_online: boolean;
  bank_transfer_details?: {
    account_number: string | null;
    recipient: string | null;
  };
  sum : number;
  documents: IListing<IAccountingDocument> | null;
}

interface IFormValues {
  document_ids: string[];
}

const PaymentSummary = (props: any) => {
  const formRef = useRef<FormikProps<IFormValues>>(null);
  const history = useHistory();
  const [prices, setPrices] = useState<number>(0);
  const [formik, setFormik] = useState<any>();
  const [total, setTotal] = useState<number>();
  const [title, setTitle] = useState<string>();
  const [tableData, setTableData] = useState<any>({});
  const [summaryState, execFetchSummary]: readonly [
    IActionState<ISummary>,
    typeof fetchPaymentSummary
  ] = useAsyncAction(fetchPaymentSummary);

  const [documentFileState, execFetchAccountingDocument]: readonly [
    IActionState<IDocumentPdf>,
    typeof fetchAccountingDocument
  ] = useAsyncAction(fetchAccountingDocument);


  const downloadFile = async (id: string) => {
    const res:
      | ActionResponse<IDocumentPdf>
      | undefined = await execFetchAccountingDocument(id);
    if (res) {
      const linkSource = `data:application/pdf;base64,${res.data.data.pdf_base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "dokument.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("seller_id");
    if (id) {
      execFetchSummary(id);
    }
    setTableData(getTableData());
  }, []);

  useEffect(() => {
    
  }, [formik]);

  useEffect(() => {
    if (summaryState.data && formRef.current) {
      summaryState.data.documents.forEach((item, index) => {
        formRef.current?.setFieldValue(`document_ids[${index}]`, item.id);
      });
      const result = summaryState.data.documents.reduce((total, current) => {
        return total + parseFloat(current.brutto);
      }, 0);
      setTotal(result);

      const titleResult = summaryState.data.documents.reduce((total, current, index) => {

        return total + `${index > 0 ? ", " : "" }${current.number}`;
      }, "");
      setTitle(titleResult); 
    }
  }, [summaryState.data]);
  

  const renderColumns = () => {
    return [
      {
        width: 40,
        render: (field: any, row: IAccountingDocument) => (
          <div className="checkbox">
            <CheckFormField
              name={`document_ids`}
              id={`translations_${row.id}`}
              value={`${row.id}`}
              keyValues={true}
            />
          </div>
        ),
      },
      {
        title: "Data",
        align: "left",
        width: 100,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.draw_date}</div>
        ),
      },
      {
        title: "Typ",
        align: "left",
        width: 200,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap column-upper">{documentTypes[row.type]}</div>
        ),
      },
      {
        title: "Numer",
        align: "left",
        width: 140,
        render: (field: any, row: IAccountingDocument) => (
          <a onClick={() => downloadFile(row.id)}>{row.number}</a>
        ),
      },
      {
        title: "Sprzedający",
        align: "left",
        width: 450,
        render: (field: any, row: IAccountingDocument) => (
          <div>{row.seller_string}</div>
        ),
      },
      {
        title: "Termin płatności",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{row.payment_due_date}</div>
        ),
      },
      {
        title: "Dni do terminu zapłaty",
        align: "left",
        width: 110,
        render: (field: any, row: IAccountingDocument) => (
          <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{row.days_due_date}</div>
        ),
      },
      {
        title: "Kwota do zapłaty",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap t-500 ${row.payment_date ? "green" : "red"}`}
          >
            {" "}
            {row.brutto} zł
          </div>
        ),
      },
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };

  const result = summaryState.data?.documents?.map((item, index) => ({
    ...item,
    key: item.id,
  }));

  const Effects = () => {
    const formikProps = useFormikContext<IFormValues>();

    useEffect(() => {
      const documentIds = formikProps.values.document_ids;

      const selectedDocuments = summaryState.data?.documents?.filter(
        (item, index) => {
          return documentIds?.find((docId) => {
            return docId === item.id;
          });
        }
      );
      
      const result = selectedDocuments?.reduce((total, current) => {
        return total + parseFloat(current.brutto);
      }, 0);
      setTotal(result);

      const titleResult = selectedDocuments?.reduce((total, current, index) => {

        return total + `${index > 0 ? ", " : "" }${current.number}`;
      }, "");
      setTitle(titleResult);


    }, [formikProps.values.document_ids]);

    return <></>;
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ document_ids: [] }}
      onSubmit={() => {}}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        

        if( summaryState.loading){
            return <Spinner size="large" />
        }
          
        return (
          <div className="page-content">
            { summaryState.loading && (
              <Spinner size="large" />
            )}
            <Effects />
            <div className="breadcrumbs">
              Integrator <span className="arrow">»</span>
              <a href="#">Rozliczenia </a>
            </div>
            <header className="page-header">
              <h1>Rozliczenia</h1>
            </header>{" "}
            
            <div className="block mb-25">
                <h2 className="primary h-lg mb-20">Dane do przelewu</h2>
                <div className="transfer-data">
                    <Row className="mb-5">
                        <Col md={3} className="grey">Odbiorca:</Col>
                    <Col md={20} className="black t-500">{summaryState.data?.bank_transfer_details?.recipient}</Col>
                    </Row>
                    <Row className="mb-5">
                        <Col md={3} className="grey">Nr. rachunku bankowego:</Col>
                    <Col md={20} className="black">{summaryState.data?.bank_transfer_details?.account_number}</Col>
                    </Row>
                    <Row className="mb-5">
                        <Col md={3} className="grey">Do zapłaty:</Col>
                    <Col md={20} className="red t-500 ">{total?.toFixed(2)} zł</Col>
                    </Row>
                    <Row className="mb-5">
                        <Col md={3} className="grey">Tytuł wpłaty:</Col>
                    <Col md={20} className="black">{title}</Col>
                    </Row>
                    <Row className="mb-5">
                        <Col md={3} className="grey">Informacje:</Col>
                    <Col md={20} className="black">W przypadku przelewu tradycyjnego saldo odświeżane jest z kilkudniowym opóźnieniem. Jeżeli płatność została już zrealizowana, sprawdź saldo za kilka dni.</Col>
                    </Row>
                </div>
            </div>
            <h2 className="primary h-lg mb-5">Zestawienie dokumentów</h2>
            <TableWrapper className="mb-10">
              <Table
                size="middle"
                locale={{ emptyText: "Brak elementów do wyświetlenia" }}
                rowKey={(item) => item.key}
                columns={tableData.columns}
                bordered={false}
                dataSource={result}
                pagination={false}
                loading={tableData.loading}
                className={`custom-ant-table`}
              />
            </TableWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default withAuthSync(withLayout(PaymentSummary));
