import React, { useState, useEffect } from "react";
import Row from "antd/lib/row";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import Col from "antd/lib/col";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import { ITransferSummary } from "pages/settlements/bank-transfer-summary";
import AntdTableWrapper from "components/common/antd-table-wrapper";
import FileDropzone from "components/admin/file-dropzone-simple";
import { useForm } from "react-hook-form";
import {
  deleteOSOP1Attachment,
  transferPaymentSummary,
  confirmTransfer,
} from "actions/BDO-reports";
import { IOSOP1FormData } from "types/BDO-report-types";
import FormButtons from "components/common/form-buttons";
import FormButton from "components/buttons/form-button";
import { numToCurrency, getTransferTitle } from "utils/helpers";
import Spinner from "components/core/spinner";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";
import { DocumentColumns } from "./document-columns";
import { useTable } from "utils/hooks/useTable";
import { useLocalFile } from "../useLocalFile";

const Step2PaymentSummary = (props: any) => {
  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const urlParams = new URLSearchParams(window.location.search);
  const history = useHistory();

  const id = urlParams.get("id");
  const sellerId = urlParams.get("seller_id");

  const { downloadFile } = useTable();
  const { downloadLocalFile } = useLocalFile(id);

  const [summaryState, execFetchSummary]: readonly [
    IActionState<ITransferSummary>,
    typeof transferPaymentSummary
  ] = useAsyncAction(transferPaymentSummary);

  const [confirmState, execConfirm]: readonly [
    IActionState<any>,
    typeof confirmTransfer
  ] = useAsyncAction(confirmTransfer);

  useEffect(() => {
    if (id && sellerId) {
      execFetchSummary(id, sellerId);
    }
  }, []);

  const [deleteState, execDeleteFile]: readonly [
    IActionState<IOSOP1FormData>,
    typeof deleteOSOP1Attachment
  ] = useAsyncAction(deleteOSOP1Attachment);

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const submitForm = async (values: any) => {
    if (id && sellerId) {
      const res = await execConfirm(newFiles, id, sellerId);
      if (res?.status === 200 || res?.status === 201) {
        //  history.push(`${PAGE.BDO_REPORT_STEP_2}?id=${id}` )
      }
      history.push(`${PAGE.BDO_REPORT_STEP_2}?id=${id}`);
    }
  };

  return (
    <>
      {(summaryState.loading ||
        deleteState.loading ||
        confirmState.loading) && <Spinner size="large" />}
      <div className="block">
        <h2 className="h-lg">Dane do przelewu</h2>
        <div className="transfer-data">
          <Row className="mb-5">
            <Col md={3} className="grey">
              Odbiorca:
            </Col>
            <Col md={20} className="black t-500">
              {summaryState.data?.bank_transfer_details?.recipient}
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={3} className="grey">
              Nr. rachunku bankowego:
            </Col>
            <Col md={20} className="black">
              <div className="no-wrap">
                {summaryState.data?.bank_transfer_details?.account_number}
              </div>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={3} className="grey">
              Do zapłaty:
            </Col>
            <Col md={20} className="red t-500 ">
              {numToCurrency(summaryState.data?.sum)}
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md={3} className="grey">
              Tytuł wpłaty:
            </Col>
            <Col md={20} className="black">
              {getTransferTitle(summaryState.data?.documents?.data)}
            </Col>
          </Row>
        </div>
      </div>
      <AntdTableWrapper
        noTotal={true}
        noPagination={true}
        data={summaryState.data?.documents || null}
        columns={DocumentColumns(downloadFile, downloadLocalFile)}
        tableProps={{
          rowKey: "uuid",
          className: "custom-ant-table mb-10",
        }}
      />
      <div className="block">
        <h2 className="h-lg">Potwierdzenie przelewu</h2>
        <p className="md-t flex-top-left">
          <span className="warning-label">
            <WarningOutlinedIcon />
          </span>
          Aby przyspieszyć rozliczenie wgraj potwierdzenie wpłaty
        </p>
        <FileDropzone
          formMethods={formMethods}
          files={newFiles}
          setFiles={setNewFiles}
          existingFiles={[]}
          execDeleteFile={async (attachmentId: string) => {
            if (id) {
              await execDeleteFile(id, attachmentId);
            }
          }}
        />
      </div>
      <FormButtons>
        <FormButton
          width={220}
          color="primary"
          onClick={formMethods.handleSubmit(submitForm)}
          title="Potwierdzam wpłatę"
        />
      </FormButtons>
    </>
  );
};

export default Step2PaymentSummary;
