export interface IAccountingDocument {
  id: string;
  draw_date: string;
  type: "IV" | "PF" | "SI" | "RE" | "IN" | "DN";
  number: string;
  seller: string;
  payment_due_date: string;
  brutto: string;
  payment_date: string;
  seller_string: string;
  seller_id: string;
  // days_after_due_date?: string;
  days_due_date?: string;
  online_payment: boolean;
  is_overdue : boolean;
  status? : "PEN" | "FIN";
  status_string? : string;
  description? : string;
  download_document : boolean;
  local_document? : boolean;
  title? :string;
  actions? : any;
}

export interface ISeller {
  name: string;
  online_payment: boolean;
}


export interface ISellerDocumentsDebt{
  days_due_date : string;
  is_overdue : boolean;
  brutto : number,
  seller_string: string,
  seller_id: string,
  payment_due_date: string,
  payment_days: string,
  current_page: string,
  total_pages: string,
  items_from: string,
  items_to: string,
  online_payment : boolean;
  transfer_payment : boolean;
  items: IAccountingDocument[],
  status? : string
  // status_string : string;
}


export interface IAccountingDocuments {

  expired_total: number;
  sellers: { [key: string]: ISeller };
  current: ISellerDocumentsDebt[];
  settled: IAccountingDocument[];
  
}

export interface IDocumentPdf {
  data: {
    pdf_base64: string;
  };
}
export interface IDocumentsGroup {
  
  id :string;
  name : string;
  position : number;
  documents : ISimpleDocument[]
} 
export interface IDocuments {
  // data: { [key: string]: ISimpleDocument[] };
  list : IDocumentsGroup[];
  document_groups: { key: string, name: string; position: number }[];
}

export interface IDocument {
  uuid: string;
  name: string;
  description: string;
  category: string;
  category_string: string;
}

export interface ISimpleDocument {
  id?: string;
  name?: string;
  description?: string;
  files?  : {file_name : string; id : string, url : string}[]
}

export type SimpleDocument = ISimpleDocument | {}

export interface IDocumentFormValues {
  id: string;
  name: string;
  description: string;
  document_attachments  : File[];
}


export const documentTypes = {
  IV: "Faktura VAT",
  IVC: "Faktura korygująca",
  HC: "Faktura korygująca",
  PF: "Faktura Proforma",
  SI: "Faktura VAT (zewn.)",
  RE: "Paragon",
  IN: "Nota odsetkowa",
  DN: "Nota windykacyjna",
};

export interface IDocumentFile {
  file_name: string;
  url: string;
}
