import {
  FETCH_VEHICLE_FORM_DATA,
  FETCH_MODELS,
  FETCH_VEHICLE,
  FETCH_VEHICLES,
  PRELOAD_VEHICLE
} from 'types/redux-types'

export default function (state = {}, action: any) {

  switch (action.type) {
    case FETCH_VEHICLE:

      return {...state, vehicle : action.payload.vehicle.data}
    case FETCH_VEHICLES:

      const resData = action.payload.my_vehicles;
      const isArray = resData instanceof Array;
      let vehicles;

      if(!resData)
      {
        return {...state, vehicles : null}
      }else if(!isArray){
        vehicles = [resData]

      }else{
        vehicles = resData;
      }


      vehicles = vehicles.map((item: any, index: number) => {
        item.index = index + 1;
        return item;
      })
      
      // 

      return {...state, vehicles : vehicles}
    case PRELOAD_VEHICLE:
      return {...state, vehicle : action.payload.vehicle}
    case FETCH_VEHICLE_FORM_DATA:
      return {...state, form_data : action.payload.form_data}
    case FETCH_MODELS:
      return {...state, models : action.payload.models}
  default:
    return state;
}
}
