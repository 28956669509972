import {
  FETCH_VEHICLE_FORM_DATA,
  FETCH_MODELS,
  FETCH_VEHICLES,
  FETCH_VEHICLE,
  PRELOAD_VEHICLE,
  SET_REQUEST_ERROR,
  DATA_SUBMITTING,
} from "types/redux-types";
import { API_BASE_URL } from "config";
import axios from "init-axios";
import History from 'my-history';
// import Router from "next/router";
import { PAGE } from "paths";
import { requestHeaders } from "utils/auth";
import { handleError } from "utils/http";
import { setLoader } from "utils/dispatch";
import { AxiosRequestConfig } from "axios";

const VEHICLES_URL = `${API_BASE_URL}/vehicles`;
export const VEHICLE_REQUESTS_URL = `${API_BASE_URL}/vehicle_requests`;
const PRELOAD_VEHICLE_URL = `${VEHICLES_URL}/new?vin_number=`;
export const FORM_DATA_URL = `${API_BASE_URL}/vehicles/form_data/default`;
export const FILTER_URL = `${API_BASE_URL}/vehicles/form_data/vehicle_models`;
const filterQuery = `?&search[make_cont]=Ford&search[model_cont]=Omni`;

export function filterVehicles(queryString: string) {
  return function (dispatch : any) {
    setLoader(dispatch, DATA_SUBMITTING, true)
    axios
      .get(`${VEHICLES_URL}?${queryString}`, requestHeaders())
      .then((response: any) => {
        setLoader(dispatch, DATA_SUBMITTING, false)

        dispatch({
          type: FETCH_VEHICLES,
          payload: {
            my_vehicles: response.data,
          },
        });
      })
      .catch((error : any) => {
        setLoader(dispatch, DATA_SUBMITTING, false)
        handleError(error);
      });
  };
}


export const fetchVehicleFormData = (ctx? : any) => async (dispatch: any) => {
  const options : AxiosRequestConfig = {
    url: FORM_DATA_URL,
    method: "GET",
    headers:  requestHeaders(ctx).headers ,
  };
  const res = await axios(options);
  dispatch({
    type: FETCH_VEHICLE_FORM_DATA,
    payload: {
      form_data: res.data.data,
    },
  });

  return res;
};

export const fetchModels = (make : string, model : string)  => async (dispatch : any) =>  {

  const options:  AxiosRequestConfig = {
    url: `${FILTER_URL}?vehicle_brand=${make}&vehicle_type=${model}`,
    method: "GET",
    // headers: { authorization:  requestHeaders().headers.authorization },
    headers:  requestHeaders().headers ,
  };
  const res = await axios(options);
  dispatch({
    type: FETCH_MODELS,
    payload: {
      models: res.data.data,
    },
  });

  return res;

}

export function addVehicle(data : any) {
  return async function (dispatch : any) {
    setLoader(dispatch, DATA_SUBMITTING, true);
    axios
      .post(`${VEHICLES_URL}`, data, requestHeaders())
      .then((response: any) => {
        setLoader(dispatch, DATA_SUBMITTING, false);
        History.push(PAGE.MY_VEHICLES);
      })
      .catch((error: any) => handleError(error));
  };
}

export function fetchVehicle({ ctx, id} : any) {
  return async function (dispatch: any) {
    await axios
      .get(`${VEHICLES_URL}/${id}`, requestHeaders(ctx))
      .then((response: any) => {
        dispatch({
          type: FETCH_VEHICLE,
          payload: {
            vehicle: response.data,
          },
        });
      })
      .catch((error: any) => handleError(error));
  };
}

export function fetchVehicles() {
  return async function (dispatch : any) {
    const res = await axios.get(`${VEHICLES_URL}`, requestHeaders());
    dispatch({
      type: FETCH_VEHICLES,
      payload: {
        my_vehicles: res.data,
      },
    });
    return res;
  };
}


export function preloadVehicle(VIN : any, path  : any | null) {
  return async function (dispatch: any) {
    await axios
      .get(`${PRELOAD_VEHICLE_URL}${VIN}`, requestHeaders())
      .then((response: any) => {
        dispatch({
          type: PRELOAD_VEHICLE,
          payload: {
            vehicle: response.data.data,
          },
        });
        if (path) {
          History.push(path);
        }
      })
      .catch((error: any) => error.response && handleError(error));
  };
}
