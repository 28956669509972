import React, { useEffect, useState } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import Table, { ColumnProps } from "antd/lib/table";
import TableWrapper from "components/common/table-wrapper";
import { IActionState } from "types/async-types";
import { IBDOKey, BDOKeysListing, IBDOKeyItem } from "types/BDO-keys-types";
import { fetchBDOKeys, checkBDOKey } from "actions/BDO-key";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import Pagination from "@material-ui/lab/Pagination";
import Spinner from "components/core/spinner";
import CloseIcon from "@material-ui/icons/Close";
import { useAsyncAction } from "utils/async-action";
import { IOOSummaryItem } from "types/recovery-organization-types";
import Button from "components/buttons/button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { fetchAdminBDOKeys } from "actions/admin";
import { useForm } from "react-hook-form";
import { IOSOP1FiltersFormValues } from "./os-op1-list";
import { Row, Col } from "antd";
import  TextField  from "components/form-fields/hook-form-fields/hook-text-field";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { LPColumn, CreatedAt } from "utils/columns";
import { renderBreadcrumbs } from "utils/helpers";

// import { Pagination } from 'antd';
interface ICheckKeyRes {
  is_correct: boolean;
  last_check: string;
}
const BDOKeys = () => {
    const [pageNo, setPageNo] = useState(1); 
    const [pageCount, setPageCount] = useState(1); 
  const [BDOKeysState, execFetchBDOKeys]: readonly [
    IActionState<BDOKeysListing>,
    typeof fetchAdminBDOKeys
  ] = useAsyncAction(fetchAdminBDOKeys); 
  const user = useSelector((state: RootState) => state.user);


  const formMethods = useForm<IOSOP1FiltersFormValues>({
    mode: "onChange",
  });


  const onSubmit = (values: IOSOP1FiltersFormValues) => {
    execFetchBDOKeys({ ...values, page: 1 });
  };
  

  const [checkKeyState, execCheckKey]: readonly [
    IActionState<ICheckKeyRes>,
    typeof checkBDOKey
  ] = useAsyncAction(checkBDOKey);

  const setPage = (page: number) => {
    execFetchBDOKeys({ ...formMethods.getValues(), page: page });
    setPageNo(page);
  };

  useEffect(() => {
    if(user.session){
      execFetchBDOKeys();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.session]);

  useEffect(() => {
   if(BDOKeysState.data){
       setPageCount(BDOKeysState.data.total_pages)
   }
  }, [BDOKeysState.data]);

  const checkKey = (id: string) => {
    execCheckKey(id);
  };

  useEffect(() => {
      if(checkKeyState.data){
        if(checkKeyState.data.is_correct){
            alert('Poprawnie zweryfikowano klucz')
         }else{
           alert('Niepoprawna weryfikacja klucza')
         }
         execFetchBDOKeys({  ...formMethods.getValues(), page : BDOKeysState.data?.current_page});
      }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkKeyState.data]);

  const tableData = {
    filtersCollapsed: true,
    // pagination: false,
    loading: false,
    showHeader: false,

    columns: [
      // {
      //     width: 150,
      //     title: "Data aktualizacji",

      //     render: (field: any, row: IBDOKeyItem) => row.updated_at,
      //   },
      LPColumn(BDOKeysState.data?.items_from),
      CreatedAt,
      {
        
        title: "Utworzone przez",

        render: (field: any, row: IBDOKeyItem) => row.created_by,
      },
      {
        
        title: "CBK ID",

        render: (field: any, row: IBDOKeyItem) => row.cbk_id,
      },
      {
        
        title: "Client ID",

        render: (field: any, row: IBDOKeyItem) => row.client_id,
      },
      {
        
        title: "Client secret",

        render: (field: any, row: IBDOKeyItem) => row.client_secret,
      },
      {
        
        title: "Typ klucza",

        render: (field: any, row: IBDOKeyItem) => row.key_type_string,
      },
    
      {
        
        title: "Ostatnio użyty",

        render: (field: any, row: IBDOKeyItem) => row.last_use,
      },
      {
        
        title: "Ostatnio sprawdzony",
        render: (field: any, row: IBDOKeyItem) => row.last_check,
      },
      {
        align: "center",
        title: "Czy poprawny",
        width: 150,
        render: (field: any, row: IBDOKeyItem) =>
          row.is_correct ? (
            <CheckCircle className="green" />
          ) : (
            <HighlightOffIcon className="red" />
          ),
      },
      {
        align: "right",
        render: (field: any, row: IBDOKeyItem) => (
          <Button
            title="Sprawdź"
            color="primary"
            inline={true}
            width={200}
            onClick={() => checkKey(row.uuid)}
          />
        ),
      },
    ] as ColumnProps<IBDOKeyItem>[] | undefined,
  };

  
  return (
    <div className="sider-page">
      {(BDOKeysState.loading || checkKeyState.loading) && (
        <Spinner size="large" />
      )}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Administrator", "Klucze BDO"])} 
        </div>
        <header className="page-header">
          <h1 className="grow-1">Klucze BDO</h1>
        </header>
        <div className="list-filters mb-20">
          <Row type="flex" align="middle" gutter={15}>
            <Col md={6}>
              <TextField
                name="cbk_id"
                formMethods={formMethods}
                label="CBK ID"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="date_from"
                type="date"
                formMethods={formMethods}
                label="Data utworzenia (od)"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="date_to"
                type="date"
                formMethods={formMethods}
                label="Data utworzenia (do)"
              />
            </Col>
            <Col md={12} className="t-right">
            <Button
                    title="Filtruj"
                    color="primary"
                    width={150}
                    onClick={formMethods.handleSubmit(onSubmit)}
                    className="mr-5"
                    inline={true}
                  />
                       <Button
                    title="Wyczyść"
                    color="white"
                    width={150}
                    inline={true}
                    onClick={() => {formMethods.reset(); execFetchBDOKeys(); }}
                  />
            
            </Col>
          </Row>
        </div>
        <TableWrapper
          className="grow-1"
          data={BDOKeysState.data}
          fetchData={
            BDOKeysState.data && {
              current_page: BDOKeysState.data?.current_page,
              total_pages: BDOKeysState.data?.total_pages,
            }
          }
          setPage={setPage}
          addPagination={true}
        >
          <Table
            size="middle"
            locale={{ emptyText: "Brak rekordów" }}
            rowKey="uuid"
            columns={tableData.columns}
            bordered={false}
            dataSource={BDOKeysState.data?.list}
            pagination={false}
            className="custom-ant-table mb-10"
          />
           {/* <Pagination
              shape="rounded"
              onChange={setPage ? (e, page) => setPage(page) : undefined}
              page={pageNo}
              count={pageCount}
            /> */}
            {/* <Pagination current={BDOKeysState.data?.current_page} onChange={(e) => setPage(e)}  total={3} /> */}
        </TableWrapper>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(BDOKeys));
