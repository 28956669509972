import React from "react";
import Form from "antd/lib/form";
import Radio from "antd/lib/radio";

import { getNested } from "utils/helpers";
import { UseFormReturn, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
export interface IRadioProps<T> {
  name: string;
  value: T | boolean;
  label: string | JSX.Element; 
  checked?: boolean;

  
}

export interface IRadioGroupProps<T> {
  name: string; 
  label: string | JSX.Element;
  orientation: "horizontal" | "vertical";
  testId?: string;
  options: Array<Omit<IRadioProps<T>, "name">>;
  type?: "radio";
  required?: boolean;
  onChange?: any;
  formMethods: UseFormReturn<any>;
  rules?: any;
  className? : string;
  

}

const CheckFormFields = (props: IRadioGroupProps<any>) => {
  const { options, name, orientation, label, required, onChange, rules, formMethods, className } = props;
  const result = options.map((item, index) => {
    return (
      <Radio.Button key={"radio_" + index} checked={item.checked} value={item.value} className={className}>
        <div className="black">{item.label} </div>
        
      </Radio.Button>
    );
  });

  return (
    <Form.Item required={required} label={props.label}>
      <Controller
        name={name}
        control={formMethods.control}
        rules={rules}
        render={({ field, fieldState, formState }) => {
          const touched = getNested(formState.touchedFields, name);
          const error = getNested(formState.errors, name);

          return (
            <>
              <Radio.Group
                onChange={(e) => {
                  
                  formMethods.setValue(props.name, e.target.value, {shouldDirty: true, shouldValidate : true});
                  if (onChange) {
                    onChange(e.target.value);
                  }
                }}
                className={`${props.orientation} ${props.className || ""}`}
                name={props.name}
                value={field.value}
              >
                {result}
                {formMethods.formState && (
                  <ErrorMessage
                    errors={formMethods.formState.errors}
                    name={name}
                    render={({ message }: any) => (
                      <span className="input-error">{message}</span>
                    )}
                  />
                )}
              </Radio.Group>
            </>
          );
        }}
      />
    </Form.Item>
  );
};

export default CheckFormFields;
