import React, { useEffect } from "react";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import { UseFormReturn } from "react-hook-form";
import Button from "components/buttons/button";
import FormButton from "components/buttons/form-button";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import SimpleLayout from "components/layout/simple-layout";
import "styles/components/bdo-report/bdo-report-form.scss";
import { IActionState } from "types/async-types";
import { IDashboardProduct } from "types/dashboard-types";
import { IBDOReportButton } from "types/BDO-report-types";
import {
  fetchBDOAgreement,
  fetchBDOReports,
  createBDOReport,
} from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";
import FormLayout from "components/layout/form-layout";
import Spinner from "components/core/spinner";
import { useGraphForm } from "components/bdo-year-report-form/graph-form-utils";

const ImportedVehiclesSection = (props: {
  formMethods: UseFormReturn<any>;
  vehicle_types?: { [key: string]: string };
}) => {
  const [BDOAgreementState, execFetchBDOAgreement]: readonly [
    IActionState<IDashboardProduct & IBDOReportButton>,
    typeof fetchBDOAgreement
  ] = useAsyncAction(fetchBDOAgreement);
  const generateButton = BDOAgreementState.data?.generate_bdo_button;

  const [createReportState, execCreateReport]: readonly [
    IActionState<any>,
    typeof fetchBDOReports
  ] = useAsyncAction(createBDOReport);

  const id = generateButton?.id || createReportState.data?.id;

  const { execCheckGraph, goToNext, graphState } = useGraphForm(id);

  useEffect(() => {
    execFetchBDOAgreement();
  }, []);

  useEffect(() => {
    if (generateButton?.id) {
      execCheckGraph(generateButton.id);
    }
  }, [generateButton]);

  const goToReport = async () => {
    if (!generateButton?.id) {
      const reportRes = await execCreateReport();
      let graphRes;
      if (reportRes.status === 201) {
        graphRes = await execCheckGraph(reportRes.data?.id);
      }

      if (graphRes?.status === 200 && reportRes.status === 201) {
        goToNext({
          graph_data: graphRes.data?.graph_data,
          id: reportRes.data?.id,
        });
      }
    } else if (graphState.data) {
      goToNext({ graph_data: graphState.data?.graph_data });
    }
  };

  return (
    <>
      {(BDOAgreementState.loading ||
        graphState.loading ||
        createReportState.loading) && <Spinner size="large" />}

      <div className="block flex-center grow-1 flex-column">
        <div>
          <div className="flex-center">
            <div></div>
            <CheckCircleOutlineOutlinedIcon className="success" />
          </div>
          <h1 className="t-center">Usługa została aktywowana</h1>

          <div className="t-center">
            <p className="md-t mb-15 t-center">
              Gratulacje. Udało się pomyślnie zintegrować Twój profil
              Integratora z serwisem BDO. Składanie sprawozdań na ten rok nie
              zostało jeszcze uruchomione przez BDO.{" "}
            </p>
            <p className="md-t t-center mb-30">
              Złożenie sprawozdania będzie dostępne po zakończeniu roku
              rozliczeniowego.{" "}
            </p>
            <div className="i-b">
              <FormButton
                onClick={goToReport}
                disabled={!generateButton?.show}
                color="primary"
                className="mb-10"
                title="Generuj sprawozdanie BDO"
                width={330}
              />
              <Button
                color="white"
                title="Wróć do systemu"
                link="/"
                width={330}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SimpleLayout(FormLayout(ImportedVehiclesSection));
