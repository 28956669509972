import {
  FETCH_TRANSLATION,
  FETCH_TRANSLATIONS
} from 'types/redux-types'
import _ from 'lodash'

export default function (state = {}, action: any) {

  switch (action.type) {
    case FETCH_TRANSLATION:
      return {...state, translation : action.payload.translation}
    case FETCH_TRANSLATIONS:
      return {...state, translations : action.payload.translations}
  default:
    return state;
}
}
