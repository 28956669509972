import React, { useState } from "react";
import NewPasswordForm from "components/user/new-password-form";
import SimpleHeader from "components/layout/simple-header";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import CloseIcon from "@material-ui/icons/Close";
import BackButton from "components/buttons/back-button";

import "antd/dist/antd.min.css";

import "styles/pages/login.scss";
import { useHistory, Link } from "react-router-dom";
import { PAGE } from "paths";

interface IProps {
  // token : string ;
}

function NewPassword(props: IProps) {
  // props & hooks
  const history = useHistory();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  if (!token) {
    history.push(PAGE.LOGIN);
    return null;
  }

  return (
    <div data-test="login-page" className="login-page">
      <main>
        <SimpleHeader />
        <Link to={PAGE.LOGIN} className="close-page">
          <CloseIcon className="close-button" />
        </Link>
        {/* <BackButton simple={true} /> */}
        <Row className="ant-row-flex login" itemType="flex">
          <Col xs={24} lg={24}>
            <section>
              <div className="login-form">
                <NewPasswordForm token={token} />
              </div>
            </section>
          </Col>
        </Row>
      </main>
    </div>
  );
}

export default NewPassword;
