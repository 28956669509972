import React, { useState, useEffect, useRef } from "react";
import { Formik, FormikProps, useFormikContext } from "formik";
import { Link, useHistory } from "react-router-dom";
import { PAGE } from "paths";
import Alert from "components/common/alert";
import { useAsyncAction } from "utils/async-action";
import CheckFormField from "components/form-fields/check-form-field";
import { fetchAddedCodes } from "actions/waste";
import SpinnerPopup from "components/core/spinner";
import { ICode, ICodesState, addedCodesType } from "types/waste-types";
import TableWrapper from "components/common/table-wrapper";
import Button from "components/buttons/form-button";

import styles from "./waste-tabs.module.scss";
import { IActionState } from "types/async-types";
import _ from "lodash";

import { WasteCardType } from "types/waste-card-types";

interface IFormValues {
  selected_codes: Array<string>;
}

interface IProps {
  setTransferType: (a: WasteCardType) => any;
  wasteCardsState: any;
  execFetchAddedCodes: any;
}
function WasteList(props: IProps) {
  //** variables */
  const { setTransferType, wasteCardsState, execFetchAddedCodes } = props;
  const { submitForm, values } = useFormikContext<IFormValues>();
  const [forwardDisabled, setForwardDisabled] = useState(false);

  // const [wasteCardsState, execfetchAddedCodes]: readonly [
  //   IActionState<addedCodesType>,
  //   any
  // ] = useAsyncAction(fetchAddedCodes);
  // const history = useHistory();

  type addedCodesType = {
    [key: string]: ICode;
  };

  //** side effects ********************************/
  useEffect(() => {
    execFetchAddedCodes();
  }, []);

  useEffect(() => {
    if (values.selected_codes.length) {
      const containDangerous = _.some(values.selected_codes, (codeKey) => {
        if (
          wasteCardsState.data &&
          wasteCardsState.data[codeKey].is_dangerous
        ) {
          return true;
        }
      });

      setForwardDisabled(containDangerous);
    }
  }, [values]);

  if (wasteCardsState.loading) {
    return <SpinnerPopup />;
  }

  if (wasteCardsState.data && !Object.keys(wasteCardsState.data).length) {
    return   <div className="block">Brak elementów do wyświetlenia </div>;
  }

  const renderTableItems = (items: addedCodesType) => {
    return Object.keys(items).map((key: any, index: number) => {
      return (
        <tr key={key} className="odd record-137720-row first">
          <td>
            <CheckFormField
              id={`check_${index}`}
              name="selected_codes"
              value={key}
              disabled={items[key].percent === 100}
            />
          </td>
          <td className={`t-500 waste_code md-t text-left black`}>
            {items[key].code}
          </td>{" "}
          <td className={`${styles.desc} text-left`}>
            <Link to={`${PAGE.WASTE_DETAILS}/${key}`}>{items[key].name}</Link>
          </td>{" "}
          <td className={` md-t text-right black`}>
            {items[key].produced} kg
          </td>{" "}
          <td className={` md-t text-right red`}>
            {items[key].remained} kg
          </td>{" "}
          <td className={` md-t text-right green `}>
            <div className={`${styles.forwarded} flex`}>
              <span>{items[key].transfered} kg </span>
              <div className={`${styles.forwarded_bar} grow-1`}>
                <div
                  className={`${styles.fill}`}
                  style={{ width: `${items[key].percent}%` }}
                ></div>
              </div>
              <span className={`${styles.percent} lg-t`}>
                {items[key].percent} %
              </span>
            </div>
          </td>{" "}
        </tr>
      );
    });
  };

  return (
    <form>
      <TableWrapper>
        <table className="custom-ant-table ant-table waste-table">
          <thead className="ant-table-thead">
            <tr>
              <th style={{width: "40px"}}></th>
              <th className={`text-left`}>Kod odpadu</th>
              <th className={`text-left`}>Nazwa odpadu</th>
              <th className={`text-right`}>Wytworzonych</th>
              <th className={`text-right`}>Nieprzekazanych</th>
              <th className={`text-left`}>Przekazanych</th>
            </tr>
          </thead>
          <tbody>
            {wasteCardsState.data ? renderTableItems(wasteCardsState.data) : ""}
          </tbody>
        </table>
      </TableWrapper>
      <div className="mt-16 mb-20 i-b w-100 t-right">
        <div className="i-b mr-5">
          <Button
            inline={true}
            title="Przekaż odpad osobie fizycznej"
            className="mr-5"
            color="primary"
            onClick={() => {
              setTransferType("export");
              submitForm();
            }}
            disabled={!values.selected_codes.length || forwardDisabled}
          />
        </div>
        <Button
          color="primary"
          inline={true}
          title="Przekaż odpad firmie"
          onClick={() => {
            setTransferType("company_export");
            submitForm();
          }}
          disabled={!values.selected_codes.length}
        />
      </div>
    </form>
  );
}

const FormWrapper = (props: {
  setForwardSiderCollapsed: any;
  wasteCardsState: IActionState<addedCodesType>;
  execFetchAddedCodes: any;
}) => {
  const initialValues: IFormValues = { selected_codes: [] };
  const history = useHistory();
  const [transferType, setTransferType] = useState<WasteCardType>("export");

  const onSubmit = (values: IFormValues) => {
    const codes = values.selected_codes;

    let codesParam = "";
    for (const code of codes) {
      codesParam += `waste_codes[]=${code}&`;
    }
    props.setForwardSiderCollapsed(false);
    history.push(`${PAGE.WASTE}?${codesParam}&type=${transferType}`);
  };
  const childProps = {
    setTransferType: setTransferType,
    wasteCardsState: props.wasteCardsState,
    execFetchAddedCodes: props.execFetchAddedCodes,
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      <WasteList {...childProps} />
    </Formik>
  );
};

export default FormWrapper;
