import React, { useEffect, useState } from "react";
import SimpleLayout from "components/layout/simple-layout";
import { Router, Switch, Route, useHistory } from "react-router-dom";
import BDOReportPopup from "components/bdo-report/bdo-report-popup";
import { PAGE } from "paths";
import Step1 from "./step-1";
import Step2 from "./step-2";
import Step3 from "./step-3";
import PaymentStatus from "components/os-op1-form/payment-status";
import "styles/components/bdo-report/bdo-report-form.scss";

const ElectronicsForm = (props: any) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    switch (history.location.pathname) {
      case PAGE.ELECTRONICS_FORM_STEP_1: 
        setCurrentStep(1);
        break;
      case PAGE.ELECTRONICS_FORM_STEP_2:
        setCurrentStep(2);
        break;
        case PAGE.ELECTRONICS_FORM_STEP_3:
          setCurrentStep(3);
          break;
      default :
        break;
    }
  }, [history.location]);

  

  return (
    <div className="bdo-report-form">
      <div className="form-content">
        <h1 className="primary">Generowanie umów na elektronikę</h1>
        <div className="steps-nav">
          <ul className="request-order">
            <li className={`${currentStep === 1 && 'active'} grow-1`}>
              <div>
                <label>Wartość umowy</label>
              </div>
            </li>
            <li className={`${currentStep === 2 && 'active'} grow-1`}> 
              <div>
                <label>Dane podmiotu</label>
              </div>
            </li>
            <li className={`${currentStep === 3 && 'active'}`}>
              <div>
                <label>Dane umowy</label>
              </div>
            </li>
          </ul>
        </div>

        <Route path={PAGE.ELECTRONICS_FORM_STEP_1} component={Step1} />
        <Route path={PAGE.ELECTRONICS_FORM_STEP_2} component={Step2} />
        <Route path={PAGE.ELECTRONICS_FORM_STEP_3} component={Step3} />
        
      </div>
    </div>
  );
};
 
export default SimpleLayout(ElectronicsForm);
