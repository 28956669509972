import React, { useState, useRef, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import LoopIcon from "@material-ui/icons/Loop";
import { withAuthSync } from "utils/auth";
import OOOrderForm from "../../components/oo-order/oo-order-form";
import CloseIcon from "@material-ui/icons/Close";
import CheckFormField from "components/form-fields/check-form-field";
import Button from "components/buttons/button";
import {
  IOOSummaryItem,
  IOOSummaryFormValue,
  OOSummaryList,
} from "types/recovery-organization-types";
import Table, { ColumnProps } from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import { IActionState } from "types/async-types";
import { submitOOOrderSearch, filterOOSummary } from "actions/oo-orders";
import { useAsyncAction } from "utils/async-action";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import { useForm } from "react-hook-form";
import FormButton from "components/buttons/form-button";
import TableWrapper from "components/common/table-wrapper";
import { Link } from "react-router-dom";

const OOOrders = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const formRef = useRef<FormikProps<any>>(null);

  const [filterResultState, execFilterResult]: readonly [
    IActionState<OOSummaryList>,
    typeof filterOOSummary
  ] = useAsyncAction(filterOOSummary);

  const onSubmit = (values: IOOSummaryFormValue) => {
    execFilterResult(values);
  };

  useEffect(() => {
    execFilterResult({ page: 1 });
  }, []);

  const formMethods = useForm<IOOSummaryFormValue>({
    mode: "onChange",
  });

  const reset = () => {
    formMethods.reset();
  };

  const setPage = (page: number) => {
    const values = {
      ...formMethods.getValues(),
      page,
    };
    execFilterResult(values);
  };

  const testItems: IOOSummaryItem[] = [
    {
      created_at: "string",
      number: "sddfdfdf",
      product_type: "sddfdfdf",
      period: "sddfdfdf",
      organization_name: "sddfdfdf",
      document_name: "",
      declaration_name: null,
    },
  ];

  const tableData = {
    filtersCollapsed: true,
    // pagination: false,
    loading: false,
    showHeader: false,

    columns: [
      {
        width: 150,
        title: "Data utworzenia",

        render: (field: any, row: IOOSummaryItem) => row.created_at,
      },
      {
        title: "Nr zestawienia",
        render: (field: any, row: IOOSummaryItem) => row.number,
      },
      {
        title: "Produkt",
        render: (field: any, row: IOOSummaryItem) => row.product_type,
      },
      {
        title: "Okres",
        render: (field: any, row: IOOSummaryItem) => row.period,
      },
      {
        title: "Nazwa organizacji",
        width: 100,
        render: (field: any, row: IOOSummaryItem) => row.organization_name,
      },
      {
        title: "Dokument",
        render: (field: any, row: IOOSummaryItem) =>
          !row.document_name || row.declaration_name === "" ? (
            <LoopIcon className="green" />
          ) : (
            <Link target="_blank" to={row.document_name}>
              Pobierz
            </Link>
          ),
      },
      {
        title: "Deklaracja",
        render: (field: any, row: IOOSummaryItem) =>
          !row.declaration_name || row.declaration_name === "" ? (
            <LoopIcon className="green" />
          ) : (
            <Link target="_blank" to={row.declaration_name}>
              Pobierz
            </Link>
          ),
      },
    ] as ColumnProps<IOOSummaryItem>[] | undefined,
  };

  return (
    <div className="sider-page">
      <main className="page-content">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span>
          <a href="#">
            {" "}
            Lista wygenerowanych zestawień dla Organizacji Odzysku{" "}
          </a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">
            Lista wygenerowanych zestawień dla Organizacji Odzysku
          </h1>
        </header>
        <div>
          <Row gutter={15}>
            <Col md={8}>
              <TextField
                name="created_at"
                formMethods={formMethods}
                label="Data utworzenia"
              />
            </Col>
            <Col md={8}>
              <TextField
                name="number"
                formMethods={formMethods}
                label="Numer zestawienia"
              />
            </Col>
            <Col md={8}>
              <SelectField
                name="product_type"
                formMethods={formMethods}
                label="Produkt"
                options={[]}
              />
            </Col>
          </Row>

          <Row gutter={15}>
            <Col md={8}>
              <TextField
                name="period"
                formMethods={formMethods}
                label="Okres"
              />
            </Col>
            <Col md={8}>
              <TextField
                name="organization_name"
                formMethods={formMethods}
                label="Nazwa organizacji"
                type="date"
                maxDate={new Date()}
              />
            </Col>
            <Col md={8}>
              <Row className="mt-15" gutter={15}>
                <Col md={12}>
                  <FormButton
                    color="primary"
                    className="mb-10"
                    onClick={formMethods.handleSubmit(onSubmit)}
                    title="Filtruj"
                  />
                </Col>
                <Col md={12}>
                  <Button color="white" onClick={reset} title="Wyczyść" />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Formik
          innerRef={formRef}
          onSubmit={() => {}}
          initialValues={{ selected_items: [] }}
        >
          {(formikProps: FormikProps<any>) => {
            

            return (
              <>
                <TableWrapper
                  className="grow-1"
                  fetchData={
                    filterResultState.data && {
                      current_page: filterResultState.data?.current_page,
                      total_pages: filterResultState.data?.total_pages,
                    }
                  }
                  setPage={setPage}
                  addPagination={true}
                >
                  <Table
                    size="middle"
                    locale={{ emptyText: "Brak rekordów" }}
                    rowKey="uuid"
                    columns={tableData.columns}
                    bordered={false}
                    dataSource={testItems}
                    pagination={false}
                    loading={tableData.loading}
                    className="custom-ant-table"
                  />
                </TableWrapper>
                {/* <FormButton
                color="primary"
                width={200}
                onClick={formikProps.handleSubmit()}
                title="Wyszukaj"
                // disabled={!isValid}
              /> */}
              </>
            );
          }}
        </Formik>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(OOOrders));
