import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import FormButton from "components/buttons/form-button";
import Button from "components/buttons/button";
import BlockIcon from "@material-ui/icons/Block";
import { fetchNetworkAssurance } from "actions/agreements";
import { IActionState } from "types/async-types";
import { IAgreement } from "types/agreement-types";
import { useAsyncAction } from "utils/async-action";
import TextField from "components/form-fields/formik-text-field";
import SelectField from "components/form-fields/select-field";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import "styles/pages/bdo-key.scss";
import {
  fetchWasteRaports,
  fetchWasteRaportAgreement,
} from "actions/waste-raports";
import { IWasteRaports } from "types/waste-types";
import Spinner from "components/core/spinner";

const NetworkProvision = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const [agreementState, execFetchWasteRaportAgreement]: readonly [
    IActionState<{ data: IAgreement }>,
    typeof fetchWasteRaportAgreement
  ] = useAsyncAction(fetchWasteRaportAgreement);

  const [wasteRaportsState, execFetchWasteRaports]: readonly [
    IActionState<IWasteRaports>,
    typeof fetchWasteRaports
  ] = useAsyncAction(fetchWasteRaports);

  useEffect(() => {
    execFetchWasteRaportAgreement();
    execFetchWasteRaports();
  }, []);

  const renderReports = () => {
    // if (wasteRaportsState.data) {
    //   return wasteRaportsState.data.data.map((item, index) => {
    //     return (
    //       <tr>
    //         <td>{item.created_at}</td>
    //         <td>
    //           {item.date_from} - {item.date_to}
    //         </td>
    //         <td className="black">{item.category_string} </td>
    //         {/* <td className="black">
    //         <a href="#">{item.}</a>
    //       </td> */}
    //       </tr>
    //     );
    //   });
    // }
  };

  const onSubmit = (values: any) => {};

  
  if(!agreementState.data ||  agreementState.loading){
    return <Spinner size="large" />;
  }


  return (
    <div className="sider-page network-provision">
      <main className="page-content flex-column">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span> Usługi recyklingowe <span className="arrow">»</span>
          <a href="#"> Ewidencja mas </a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">Ewidencja mas</h1>
        </header>

        <div className="block grow-1">
          {agreementState.data &&
            agreementState.data.data.product_attributes?.trade_agreement && (
              <section>
                <h2 className="mb-15 black">Wygeneruj ewidencję</h2>
                <p className="grey">
                  Podaj zakres dat zgodnie z tym za jaki okres chcesz
                  wygenerować ewidencję oraz wybierz rodzaj odpadów. W
                  utworzonym w ten sposób dokumencie będą wyszczególnione
                  odpowiednie masy ze wszystkich pojazdów, których data
                  wprowadzenia na teren RP zawiera się w podanym okresie.
                </p>
                <Formik
                  initialValues={{ date_from: "", date_to: "", waste_type: "" }}
                  onSubmit={onSubmit}
                >
                  {(formikProps: FormikProps<any>) => (
                    <>
                      <Row gutter={5}>
                        <Col md={8}>
                          <TextField
                            name="dates"
                            type="date-range"
                            label="Zakres dat"
                          />
                        </Col>
                        <Col md={7}>
                          <SelectField
                            options={[]}
                            name="waste_type"
                            type="select"
                            label="Rodzaj odpadów mas"
                          />
                        </Col>
                      </Row>
                      <div>
                        <FormButton
                          color="primary"
                          title="Generuj"
                          width={200}
                          onClick={formikProps.submitForm}
                        />
                      </div>
                    </>
                  )}
                </Formik>
              </section>
            )}

          {/* {wasteRaportsState.data && wasteRaportsState.data.data.length > 0 && (
            <section>
              <h2 className="mb-15 black">Historia</h2>
              <table className="simple-table md-t mb-30">
                <thead>
                  <tr>
                    <th>Data</th>
                    <th>Zakres</th>
                    <th>Kategoria</th>
                    
                  </tr>
                </thead>
                <tbody>{renderReports()}</tbody>
              </table>
            </section>
          )} */}
          {agreementState.data &&
          agreementState.data.data.product_attributes?.trade_agreement ? (
            <section>
              <h1 className="green flex">
                <CheckCircle /> Usługa jest aktywna
              </h1>
              <div className="md-t flex mb-20">
                <span className="grow-1">
                  Umowa ważna od:{" "}
                  <span className="t-500 black">14.01.2017 </span> do:{" "}
                  <span className="t-500 black">bezterminowo</span>
                </span>
                <Button title="Pobierz" width={200} color="white" />
              </div>
              <p className="black">
                Usługa umożliwia automatyczne generowanie sprawozdań i
                przesyłanie ich bezpośrednio do systemu BDO{" "}
              </p>
            </section>
          ) : (
            <section>
              <h1 className="red flex">
                <BlockIcon /> Umowa produktowa nie jest podpisana
              </h1>
              <p className="grey">
              Aby skorzystać z tej usługi musisz mieć zawarte powiązane umowy produktowe. Wybierz umowy produktowe z menu bocznego. Proste formularze pozwolą Ci zamówić odpowiednie umowy wraz z usługą ewidencji..
              </p>
            </section>
          )}

          {/* <Button
            title="wypełnij wniosek aktualizacyjny"
            inline={true}
            color="primary"
          /> */}
        </div>
      </main>
      {!siderCollapsed && (
        <div
          className="sider"
          // collapsedWidth="0"
        >
          <div className="sm-l-t mb-20">
            Ewidencja mas
            <CloseIcon className="sider-close" onClick={toggleSider} />
          </div>
          <h2 className="mb-15 black">Czym jest ewidencja mas?</h2>
          <p className="black">
            Wprowadzający do obrotu sprzęt elektryczny i elektroniczny, baterie
            i akumulatory lub opakowania jest obowiązany do prowadzenia
            ewidencji obejmującej informacje dotyczące ilości i masy
            wprowadzonego sprzętu i opakowań.
          </p>

          <h2 className="mb-15 black">Dlaczego warto?</h2>
          <p className="black">
            Wprowadzający jest obowiązany do przechowywania ewidencji przez
            okres 5 lat, licząc od końca roku kalendarzowego, którego ona
            dotyczy. Dodatkowo, w razie kontroli, może być wezwany do
            przedstawienia ewidencji za dowolny okres. Mając usługę ewidencji w
            naszym systemie możesz w dowolnym momencie utworzyć taki dokument.
            Ważne, aby lista pojazdów byłą aktualna i kompletna.
          </p>
        </div>
      )}
    </div>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(NetworkProvision));
// export default Index;
