import React from "react";
import { IProduct } from "types/dashboard-types";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { formatDateTime } from "utils/date";
import { renderAgreementDates } from "utils/helpers";
import { INetworkAssuranceCompany } from "types/BDO-report-types";

interface IProps {
  products?: IProduct[];
}
const ServicesList = (props: IProps) => {
  const { products } = props;
  if (products) {
    const sorted = products.sort((first, second) => first.position - second.position)
    const result = sorted.map((item, index) => {
      return (
        <div key={item.uuid + "_service"} className="check-list__element">
          {item.product_attributes.trade_agreement ? (
            <CheckIcon className="green" />
          ) : (
            <CloseIcon className="red" />
          )}
          <span className="md-t bold">{item.name}</span>

          <p className="grow-1 md-t t-right">
            {item.product_attributes.trade_agreement
              ? renderAgreementDates(
                  item.agreement?.start_date,
                  item.agreement?.end_date,
                  item.agreement?.indefinite
                )
              : item.agreement?.start_date || "Brak umowy"}
          </p>
        </div>
      );
    });

    return <div className="check-list"> {result}</div>;
  } else {
    return <> </>;
  }
};

export default ServicesList;

export const AgreementsList = ({
  items,
}: {
  items?: INetworkAssuranceCompany[] | null;
}) => {
  let result : any[] = [];
 
  if (items) {
     result = items.map((item, index) => {
      return (
        <div key={item.name} className="check-list__element">
          <span className="md-t bold">{item.name}</span>
          <p className="grow-1 md-t t-right">
            {renderAgreementDates(item.from || item.form, item.to)}
          </p>
        </div>
      );
    });
  
    
  } 
 return <div className="check-list"> {result}</div>;
};
