import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import SpinnerPopup from "components/core/spinner";
import StatCounter from 'components/common/stat-counter'
import { useParams } from "react-router-dom";
// import Panel from "components/common/panel";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import {
  fetchAddedCode,
  fetchCodeHistory,
  deleteTransferCard,
  deleteWasteCard,
} from "actions/waste";
import Alert from "components/common/alert";
import { useAsyncAction } from "utils/async-action";
import { ConfirmModal } from "components/common/confirm-modal";
import {
  IWasteCode,
  IStats,
  IHistory,
  ICode,
  ICardItem,
  ICodeHistoryFormValues,
} from "types/waste-types";
// import { makeStyles } from '@material-ui/core/styles';
// import Pagination from "components/common/pa";
import style from "./waste-details.module.scss";
import TableWrapper from 'components/common/table-wrapper';
import { PAGE } from "paths";
import { IActionState } from "types/async-types";
import { withAuthSync } from "utils/auth";
import withLayout from "components/layout/MyLayout";
import WasteCardEditForm from 'components/waste/waste-card/waste-card-edit-form';
import CloseIcon from "@material-ui/icons/Close";
import Button from 'components/buttons/button';
import WasteForwardForm from 'components/waste/waste-forward-form';
import { renderBreadcrumbs } from "utils/helpers";
import { formatDateTime } from "utils/date";


function WasteDetails(props: any) {
  const [editSiderCollapsed, setEditSiderCollapsed] = useState(true);
  const [forwardSiderCollapsed, setForwardSiderCollapsed] = useState(true);

  const hist = useHistory();

  const { code }: { code: string } = useParams();
  const [summaryYear, setSummaryYear] = useState<string>("2020");
  const [pageNo, setPageNo] = useState<number>(1);
  const [codeState, execFetchAddedCode]: readonly [
    IActionState<any>,
    typeof fetchAddedCode
  ] = useAsyncAction(fetchAddedCode);
  const [historyState, execFetchCodeHistory]: readonly [
    IActionState<any>,
    typeof fetchCodeHistory
  ] = useAsyncAction(fetchCodeHistory);
  const [historyData, setHistoryData] = useState<IHistory | null>(null);

  const [transferCardState, execDeleteTransferCard]: readonly [
    IActionState<any>,
    typeof deleteTransferCard
  ] = useAsyncAction(deleteTransferCard);

  const [wasteCardState, execDeleteWasteCard]: readonly [
    IActionState<any>,
    typeof deleteWasteCard
  ] = useAsyncAction(deleteWasteCard);

  const showEditSider = (cardId : string) => {
    setEditSiderCollapsed(false);
    hist.push({pathname : `${PAGE.WASTE_DETAILS}/${code}`, 
    search : `id=${cardId}` });
    
  };

  const showForwardSider = () => {
    setForwardSiderCollapsed(false);
    hist.push({pathname : `${PAGE.WASTE_DETAILS}/${code}`, 
    search : `waste_codes[]=${waste_code.normalized_code}&type=export` });
    
  };

  /** functions */
  /** functions  *********************************/
  const deleteItem = (operationType: string, cardNumber: string) => {
    switch (operationType) {
      case "import":
        ConfirmModal({
          action: () => execDeleteWasteCard(cardNumber),
          header: "Usuwanie odpadu",
          text: "Czy chcesz usunąć odpad?",
          yesText: "Tak",
          noText: "Nie",
          btnColor: "primary",
        });
        break;
      case "import_export":
        ConfirmModal({
          action: () => execDeleteWasteCard(cardNumber),
          header: "Usuwanie odpadu",
          text: "Czy chcesz usunąć odpad?",
          yesText: "Tak",
          noText: "Nie",
          btnColor: "primary",
        });
        break;
      case "export":
        ConfirmModal({
          action: () => execDeleteTransferCard(cardNumber),
          header: "Usuwanie odpadu",
          text: "Czy chcesz usunąć odpad?",
          yesText: "Tak",
          noText: "Nie",
          btnColor: "primary",
        });
        break;
    }
  };

  const onPageChange = (e: any, page: number) => {
    const params: ICodeHistoryFormValues = {
      year: summaryYear,
      page: page,
    };
    setPageNo(page);
    execFetchCodeHistory(code, params);
  };

  const onYearChange = (year: string) => {
    const params: ICodeHistoryFormValues = {
      year: year,
      page: pageNo,
    };
    setSummaryYear(year);
    execFetchCodeHistory(code, params);
  };

  /** side effects */

  useEffect(() => {
    execFetchAddedCode(code);
  }, []);

  useEffect(() => {
    execFetchAddedCode(code);
  }, [transferCardState.data, wasteCardState.data]);

  useEffect(() => {
    if (codeState.data) {
      const code: IWasteCode = codeState.data;
      setHistoryData(code.history);
    }
  }, [codeState.data]);

  useEffect(() => {
    if (historyState.data) {
      const history: IHistory = historyState.data.history;
      setHistoryData(history);
    }
  }, [historyState.data]);

  function renderListItems(history: IHistory | null) {
    if (!history) {
      return <div></div>;
    }

    return history.items.map((item: ICardItem, index: number) => {
      // const remained = item.produced - (item.transfered === '-' ? 0 :
      return (
        <tr key={index} className="odd record-137720-row first">
          <td className={`${style.bold}text-left`}>{item.created_at}</td>{" "}
          <td className={`text-left`}>
            {/* <img src="/images/circle-off-outline.svg" /> */}
            <div className={style.status}>
              {/* <img src="/images/checkbox-marked.svg" /> */}
              <div>
                {/* <div className="green">Przekazane</div> */}
                <div>{item.card_number}</div>
              </div>
            </div>
          </td>{" "}
          <td className={` text-left`}>{formatDateTime(item.manufacture_date)}</td>{" "}
          <td className={` text-left`}>{formatDateTime(item.transfer_date)}</td>{" "}
          <td className={` text-left`}>{item.address}</td>{" "}
          <td className={`${style.bold}  text-right`}> {item.produced === " - " ? "" : `+ ${item.produced}` }</td>{" "}
          <td className={`${style.bold}  text-right green`}>
            {item.transfered !== " - " ? `${item.transfered}` : ""}
          </td>{" "}
          {/* <td className={`${style.bold} lg-t text-right red`}>{item.produced - item.transferred} kg</td>{" "} */}
          <td>
            <div className="t-right">
              {!item.read_only && (
                <>  
                   <Button
                      className="mr-5"
                      inline={true}
                      color="white"
                      onClick={() => showEditSider(item.id)}
                      title="edytuj"
                    />
                  
                  {/* </Link> */}
                  <Button
                    inline={true}
                    color="white"
                    onClick={() =>
                      deleteItem(item.operation_type, item.import_id)
                    }
                    title="usuń"
                  />
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  }

  const tabProps = {
    selectedClassName: style.selected,
    className: style.tab,
  };
  const year2020 = {
    className: `${style.tab} ${summaryYear === "2020" && style.selected}`,
    onClick: () => onYearChange("2020"),
  };
  const year2019 = {
    className: `${style.tab} ${summaryYear === "2019" && style.selected}`,
    onClick: () => onYearChange("2019"),
  };
  const allYears = {
    className: `${style.tab} ${summaryYear === "all" && style.selected}`,
    onClick: () => onYearChange("all"),
  };

  if (
    codeState.loading ||
    !codeState.response ||
    historyState.loading ||
    !historyData
  ) {
    return <SpinnerPopup />;
  }

  const data: IWasteCode = codeState.data;
  const {
    stats,
    history,
    waste_code,
  }: { stats: IStats; history: IHistory; waste_code: ICode } = data;
  return (
    <div className={`${style["waste-details"]} sider-page waste`}>
      <main className="page-content">
        <div>
          {codeState.loading && <SpinnerPopup />}
          <div className="breadcrumbs">
       
          {renderBreadcrumbs(["Administracja odpadów", { text : "Magazyn odpadów", link : PAGE.WASTE} , `Odpad ${waste_code.code}`])}
       
            {/* Integrator <span className="arrow">»</span> Administracja{" "}
            <span className="arrow">»</span>
            <Link to={PAGE.WASTE}>Magazyn odpadów </Link>
            <span className="arrow">»</span>
            <a href="#">{`Odpad ${waste_code.code}`}</a> */}
          </div>
          <header className="page-header">
                  <h1 className="grow-1"> {`Odpad ${waste_code.code}`} - {waste_code.name} </h1>
                  {!waste_code.is_dangerous && (
                
                  <Button
                    // link={`${PAGE.WASTE_FORWARD}?waste_codes[]=${waste_code.normalized_code}`}
                    color="primary"
                    title="Przekaż odpad"
                    width={150}
                    onClick={showForwardSider}
                    className="f-right"
                   />
                
              )}
          </header>
       
         

          <div className={`${style.tablist}`}>
            <div {...year2020}>2020</div>
            <div {...year2019}>2019</div>
            <div {...allYears}>Wszystkie</div>
          </div>
          
          <div className="block mb-20">
            
            <div className={`${style.summary} flex`}>
              <div className={`${style.waste_kg}`}>
         
              <StatCounter
                  amount={stats.produced ? `${stats.produced} kg`  : 0}
                  color="heading"
                  title="Łącznie wytworzone"
                  
                />
              </div>
              <div className={`${style.waste_kg}`}>
                <StatCounter
                  amount={stats.remained ? `${stats.remained} kg`  : 0}
                  color="red"
                  title="Nieprzekazanych"
                  
                />
              </div>
              <div className={`${style.waste_kg}`}>
              <StatCounter
                  amount={stats.transfered ? `${stats.transfered} kg (${stats.percent} %)`  : 0}
                  color="green"
                  title="Przekazanych"
                  
                />
                {/* <span>Przekazanych:</span>
                <div className={`${style.forwarded}`}>
                  <div className={`${style.forwarded_kg}`}>
                    <div className={`${style.amount} green`}>
                      {stats.transfered}{" "}
                    </div>
                    <div className={`${style.kg}`}>kg</div>
                  </div>
                  <div className={`${style.bar_container}`}>
                    <div className={` green`}>{stats.percent} % </div>
                    <div className={`${style.forwarded_bar}`}>
                      <div
                        className={`${style.fill}`}
                        style={{ width: `${stats.percent}%` }}
                      ></div>
                    </div>
                  </div>
                </div> */}
              </div>
           
            </div>
          </div>
          
          {!historyData.items.length ? (
            <div className="block">Brak elementów do wyświetlenia </div>
          ) : (
            <TableWrapper>
            <table className="custom-ant-table ant-table waste-table">
              <thead className="ant-table-thead">
                <tr>
                  <th className={`text-left`}>Data wpisu</th>
                  <th className={`text-left`}>
                    Nr karty / identyfikator przekazania
                  </th>
                  <th className={`text-left`}>Data wytworzenia</th>
                  <th className={`text-left`}>Data przekazania</th>
                  <th className={`text-left`}>Miejsce wytworzenia</th>
                  <th className={`text-right`}>Wytworzono</th>
                  <th className={`text-right`}>Przekazano</th>
                  {/* <th className={`text-right`}>Saldo nieprzekazanych</th> */}
                  <th></th>
                </tr>
              </thead>
              <tbody id="tbody-browse">{renderListItems(historyData)}</tbody>
            </table>
            </TableWrapper>
          )}

          {/* <Pagination count={historyData.total_pages} onPageChange={onPageChange} /> */}
        </div>
      </main>
      {!editSiderCollapsed &&
        <div className="sider fixed">
               <header className="sider-header">
            <h2 className="primary mb-15">Edytuj odpad</h2>
          </header>
            <WasteCardEditForm  closeSider={() => setEditSiderCollapsed(true)} execFetchCardsHistory={execFetchCodeHistory} />
            <CloseIcon
              className="sider-close"
              onClick={() => setEditSiderCollapsed(true)}
            />
            
        </div>
      }
        {!forwardSiderCollapsed &&
        <div style={{width: "1000px"}} className="sider fixed">
            <WasteForwardForm code={code}  closeSider={() => setForwardSiderCollapsed(true)}/>
            <CloseIcon
              className="sider-close"
              onClick={() => setForwardSiderCollapsed(true)}
            />
            
        </div>
      }  
    </div>
  );
}

export default withAuthSync(withLayout(WasteDetails));
