import React from "react";
import { numToCurrency } from "utils/helpers";

interface IProps {
  title?: string;
  suffix?: string | false;
  price: number | string | null | undefined;
}
const Price = (props: IProps) => {
  const { price, title, suffix } = props;
  return (
    <div>
      <h3 className=" black t-500">{title || "Cena:"}</h3>
      <div className="">
        {price !== null && price !== undefined ? (
          <>
            <div className={`price text-42 t-500 ${(price || 0) < 0 ? "red" : "green"}`}>
              {numToCurrency(price)}{" "}
            </div>
            {suffix !== false &&
              <p className="sm-l-t">{suffix || "netto"}</p>
            }
            
          </>
        ) : (
          <p className="grey">Brak danych </p>
        )}{" "}
      </div>
    </div>
  );
};

export default Price;
