import React, { useEffect, useState } from "react";
import FormStatusScreen from "components/common/form-status-screen-simple";
import Button from "components/buttons/button";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { IActionState } from "types/async-types";
import {
  getBDOPaymentStatus,
} from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";
import { PAGE } from "paths";
import { useHistory } from "react-router-dom";
import {
  
  retryDocumentsPayment,
} from "actions/accounting-documents";


const PaymentStatus = (props: { setNavState: any }) => {
  const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState<"PEN" | "FIN" | "ERR">(
    "PEN"
  );
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const paymentId = urlParams.get("payment_id");

  const [myTimeout, setMyTimeout] = useState<NodeJS.Timeout>();
  

  const [paymentStatusState, execCheckPaymentStatus]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof getBDOPaymentStatus
  ] = useAsyncAction(getBDOPaymentStatus);

  const [retryPaymentState, execRetry]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof retryDocumentsPayment
  ] = useAsyncAction(retryDocumentsPayment);

  //    ------- REGION paymentStatus  ------------
  const checkPaymentStatus = () => {
    //if it's not in the summary,
    if (window.location.pathname !== PAGE.DOCUMENT_PAYMENT_STATUS) {
      return;
    }

    if (id && paymentId) {
      execCheckPaymentStatus(id, paymentId); 
      const timer = setTimeout(checkPaymentStatus, 5000);

      setMyTimeout(timer);
    }
  };



  useEffect(() => {
    if (paymentStatusState.data?.payment_status === "FIN") {
      setTimeout(() => {
        history.push(`${PAGE.BDO_REPORT_STEP_2}?id=${id}`);
      }, 5000);
      setPaymentStatus("FIN");
    }
  }, [paymentStatusState.data]);

  useEffect(() => {
    if (paymentStatusState.response?.status === 422) {
      setPaymentStatus("ERR");
    }
  }, [paymentStatusState.response]);

  useEffect(() => {
    let myTimeout: NodeJS.Timeout | null = null;
    const checkPaymentStatus = () => {
      if (id && paymentId) {
        execCheckPaymentStatus(id, paymentId);
        const timer = setTimeout(checkPaymentStatus, 5000);

        myTimeout = timer;
      }
    };

    if (paymentStatus === "PEN") {
      if (paymentId) {
        checkPaymentStatus();
      }
    } else if (myTimeout) {
      clearTimeout(myTimeout);
    }

    return function cleanUp() {
      if (myTimeout) {
        clearTimeout(myTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentStatus, paymentId]);

  //    ------- ENDREGION paymentStatus  -------------

  // ---------- REGION RetryPayment ------------------
  const retryPayment = async () => {
    if (paymentId) {
      await execRetry(paymentId);
    }
  };

  useEffect(() => {
    if (retryPaymentState.data) {
      window.location.href = retryPaymentState.data.payment_address;
      setPaymentStatus("PEN");
    }
  }, [retryPaymentState.data]); 

  // ---------- ENDREGION RetryPayment ------------------

 

  switch (paymentStatus) {
    case "PEN":
      return (
        <FormStatusScreen
          title="Weryfikacja płatności"
          description="Trwa oczekiwanie na potwierdzenie realizacji płatności w serwisie przelewy24"
          type="loading"
          aditionalContent={
            <div className="t-center">
              <Button
                link={`${PAGE.BDO_REPORT_STEP_2}?id=${id}`}
                width={300}
                className="i-b"
                title="Powrót do opłat"
                color="white"
              />{" "}
            </div>
          }
        />
      );
    case "FIN":
      return (
        <FormStatusScreen
          title="Płatność powiodła się. Dziękujemy."
          type="success"
        />
      );
    case "ERR":
      return (
        <FormStatusScreen
          title="Płatność nie powiodła się.."
          description="Nie otrzymaliśmy potwierdzenia wykonania płatności od Przelewy 24"
          type="error"
          aditionalContent={
            <div className="t-center">
              <Button
                onClick={() => retryPayment()}
                width={300}
                className="i-b"
                title="Ponów płatność"
                color="primary"
              />{" "}
              <Button
                link={PAGE.SETTLEMENTS}
                width={300}
                className="i-b"
                title="Powrót do rozliczeń"
                color="white"
              />{" "}
            </div>
          }
        />
      );
  }
};

export default PaymentStatus;
