import React from "react";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { UseFormReturn } from "react-hook-form";
import Button from "components/buttons/button";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import SimpleLayout from 'components/layout/simple-layout'
import "styles/components/bdo-report/bdo-report-form.scss";

const  ImportedVehiclesSection = (props: {
  formMethods: UseFormReturn<any>;
  vehicle_types?: { [key: string]: string };
}) => {
  return (
    <div className="bdo-report-form">
    <div className="form-content">
    <div className="block relative">
      <div >
        <div className="flex-center">
          <div></div>
          <CheckCircleOutlineOutlinedIcon className="mb-30 green text-60"  />
        </div>
        <h1 className="t-center">Umowa została przygotowana</h1>
        <ul className="lg-t t-left grey mb-20 success-list">
          <li>
            1) Pobierz i{" "}
            <span className="primary bold">wydrukuj w dwóch egzemplarzach </span>{" "}
            wszystkie dokumenty
          </li>
          <li>2)  <span className="primary bold">Podpisz i opieczętuj </span> w wyznaczonych miejscach</li>
          <li></li>
          <li>
           <div className="mb-15"> 4) <span className="primary bold"> Wyślij wszystkie dokumenty </span> (również potwierdzenie wniesienia 
            opłaty skarbowej) na adres:</div>
            <div className="ml-20 nested">
              <div>INTEGRATOR Sp. z o.o.</div>{" "}
              <div> ul. Powstańców Listopadowych 29E/8 </div>
              <div> 35-606 Rzeszów </div>
            </div>
          </li>
          <li className="bold">
          Po weryfikacji odeślemy zwrotnie oryginały dokumentów.
          </li>
        </ul>
        <div className="t-center">
          <div className="i-b">
              <Button color="primary" className="mb-10" title="Pobierz umowę" width={330}  />
              <Button color="white" title="Zapoznaj się z ogólnymi warunkami umowy"  width={330} />
              </div>
          </div>
      </div>
    </div>
    </div>
    </div>
  );
}

export default SimpleLayout(ImportedVehiclesSection);