import React, { useState, useEffect, useRef } from "react";
import Table from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import TextField from "components/form-fields/formik-text-field";
import TableWrapper from "components/common/table-wrapper";
import { Row, Col } from "antd";
import {
  IAccountingDocument,
  IAccountingDocuments,
  IDocumentPdf,
  documentTypes,
} from "types/document-types";
import SelectField from "components/form-fields/select-field";
import { IActionState, ActionResponse } from "types/async-types";
import {
  fetchAccountingDocument,
  fetchAccountingDocuments,
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { renderOptions, renderJsonOptions } from "utils/select-options";
import Button from "components/buttons/button";
import _ from "lodash";
import { comparePaid } from "./pending-invoices";



const OverdueInvoices = (props : {invoicesState :  IActionState<IAccountingDocuments>}) => { 
  const {invoicesState} = props;
  const settledInvoices = invoicesState.data?.settled;
  const [tableData, setTableData] = useState<any>({});
const [documentFileState, execFetchAccountingDocument]: readonly [
    IActionState<IDocumentPdf>,
    typeof fetchAccountingDocument
  ] = useAsyncAction(fetchAccountingDocument);

  const setPage = (page: number) => {
    // formikProps.setFieldValue("page", page);
    // formikProps.submitForm();
  };

  const downloadFile = async (id: string) => {
    const res:
      | ActionResponse<IDocumentPdf>
      | undefined = await execFetchAccountingDocument(id);
    if (res) {
      const linkSource = `data:application/pdf;base64,${res.data.data.pdf_base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "dokument.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  useEffect(() => {
    
    setTableData(getTableData());
  }, []);

  const renderActions = (actions?: { label: string; url: string }[]) => {

    if(!actions){
      return <> </>
    }
    return actions.map((item) => (
      <Button color="white"  key={item.label} title={item.label} outerLink={true} link={item.url} newCard={true}  />
       
    ));
  };

  const renderColumns = () => {
    return [
      {
        title: "Data",
        align: "left",
        width: 100,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.draw_date}</div>
        ),
      },
      {
        title: "Typ",
        align: "left",
        width: 200,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap column-upper">{documentTypes[row.type]}</div>
        ),
      },
      {
        title: "Numer",
        align: "left",
        width: 140,
        render: (field: any, row: IAccountingDocument) => (
          <a onClick={() => downloadFile(row.id)}>{row.number}</a>
        ),
      },
      {
        title: "Sprzedający",
        align: "left",
        width: 450,
        render: (field: any, row: IAccountingDocument) => (
          <div>{row.seller_string}</div>
        ),
      },
      {
        title: "Termin płatności",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.payment_due_date}</div>
        ),
      },
  
      {
        title: "Kwota zapłacona",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap t-500`}
          >
            {" "}
            {row.brutto} zł
          </div>
        ),
      },
      {
        title: "Data wpłaty",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap`}
          >
            {" "}
            {row.payment_date}
          </div>
        ),
      },
      {
        title: "",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{renderActions(row.actions)}</div>
        ),
      },

  
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };



  let result = invoicesState.data?.settled?.map((item, index) => ({
    ...item,
    key: item.id,
  }));
if(result){
    result = result.sort(comparePaid)
   
}
 




  return (
    <TableWrapper

    >
          <Table
        size="middle"
        
        locale={{ emptyText: "Brak elementów do wyświetlenia" }}
        
        columns={tableData.columns}
        bordered={false}
        dataSource={result}
        pagination={false}
        loading={tableData.loading}
        className={`custom-ant-table`}
      />
    </TableWrapper>
  );
};

export default OverdueInvoices;
