import React, { useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import { ColumnProps } from "antd/lib/table";
import AntdTableWrapper from "components/common/antd-table-wrapper";
import StatCounter from "components/common/stat-counter";
import { IActionState } from "types/async-types";
import Spinner from "components/core/spinner";
import { useAsyncAction } from "utils/async-action";
import Button from "components/buttons/button";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { useForm } from "react-hook-form";
import { ConfirmModal as ConfirmAlert } from "components/common/confirm-modal";
import { renderBreadcrumbs } from "utils/helpers";
import { LPColumn } from "utils/columns";
import { confirmBDOReportPayment, rollbackBDOReportPayment } from "actions/admin";
import { IAdminBDOReports, IAdminBDOReport } from "./mock-reports";
import { useTable } from "utils/hooks/useTable";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import { fetchAdminBDOReports } from "actions/admin";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import { Link } from "react-router-dom";

export interface IOSOP1FiltersFormValues {
  cbk_id?: string;
  date_from?: string;
  page?: number;
  date_to?: string;
}

const itemValues: { [key: string]: string } = {
  Tak: "TAK",
  Nie: "NIE",
  "n/d": "N/D",
};

const AdminBDOReports = () => {
  const [reportsState, execFetchReports]: readonly [
    IActionState<IAdminBDOReports>,
    typeof fetchAdminBDOReports
  ] = useAsyncAction(fetchAdminBDOReports, { no_reset: true });

  const [confirmState, execConfirm]: readonly [
    IActionState<IAdminBDOReports>,
    typeof confirmBDOReportPayment
  ] = useAsyncAction(confirmBDOReportPayment, { no_reset: true });

  const [rollbackState, execRollback]: readonly [
    IActionState<IAdminBDOReports>,
    typeof rollbackBDOReportPayment
  ] = useAsyncAction(rollbackBDOReportPayment, { no_reset: true });

  const BDOReports = reportsState.data?.list;

  const formMethods = useForm<IOSOP1FiltersFormValues>({
    mode: "onChange",
  });

  const setPage = (page: number) => {
    execFetchReports({ ...formMethods.getValues(), page: page });
  };

  useEffect(() => {
    execFetchReports();
  }, []);

  const renderActions = (actions?: { label: string; url: string }[]) => {

    if (!actions) {
      return <> </>
    }
    return actions.map((item) => (
      <Button color="white" key={item.label} title={item.label} outerLink={true} link={item.url} newCard={true} />

    ));
  };

  useEffect(() => {
    if (confirmState.data) {
      execFetchReports({ ...formMethods.getValues() });
    }
  }, [confirmState.data]);

  const tableData = {
    filtersCollapsed: true,
    loading: false,
    showHeader: false,

    columns: [
      {
        ...LPColumn(reportsState.data?.items_from),
        fixed: "left",
      },

      {
        title: "CBK ID",
        width: 80,
        render: (field: any, row: IAdminBDOReport) => row.cbk_id,
      },
      {
        title: "Nazwa firmy",
        // width: 200,
        render: (field: any, row: IAdminBDOReport) => row.company_name,
      },
      {
        title: "Sieć",
        // width: 200,
        render: (field: any, row: IAdminBDOReport) => row.network_agreement,
      },
      {
        title: "Akumulator",
        // width: 200,
        render: (field: any, row: IAdminBDOReport) => row.battery_agreement,
      },
      {
        title: "BDO (111)",
        // width: 200,
        render: (field: any, row: IAdminBDOReport) => row.old_bdo_report_product,
      },
      {
        width: 100,
        title: "WS",

        render: (field: any, row: IAdminBDOReport) => (
          <div className="no-wrap">{row.ws}</div>
        ),
      },
      {
        title: "Klucz API",
        width: 100,
        render: (field: any, row: IAdminBDOReport) => (
          <div className="no-wrap">{row.bdo_key}</div>
        ),
      },
      {
        title: "Pojazdy",
        width: 70,
        render: (field: any, row: IAdminBDOReport) => <div>{row.vehicles}</div>,
      },
      {
        title: "Kampania ulotki",
        width: 70,
        render: (field: any, row: IAdminBDOReport) => <div>{row.campaign_leaflet}</div>,
      },
      {
        title: "Sieć",
        width: 70,
        render: (field: any, row: IAdminBDOReport) => <div>{row.network}</div>,
      },
      {
        title: "Podsumowanie",

        render: (field: any, row: IAdminBDOReport) => <div>{row.summary}</div>,
      },
      {
        title: "Zakończono",

        render: (field: any, row: IAdminBDOReport) => (
          <div className="no-wrap">{row.submitted}</div>
        ),
      },
      {
        title: "Akcje",

        render: (field: any, row: IAdminBDOReport) => (
          <div className="no-wrap">{renderActions(row.actions)}</div>
        ),
      },
    ] as ColumnProps<IAdminBDOReport>[],
  };

  const summary = reportsState.data;

  const renderSellerColumns = (): ColumnProps<IAdminBDOReport>[] | [] => {
    if (BDOReports && BDOReports != null) {
      return Object.keys(summary?.sellers_columns || []).map(
        (columnId, index) => {
          return {
            key: columnId,
            title: summary && `Opłaty - ${summary.sellers_columns[columnId]}`,
            align: "left",
            render: (make: any, row: IAdminBDOReport) => {
              const sellerItem = row.sellers && row.sellers[columnId];
              if (!sellerItem) {
                return "";
              }
              const renderAttachments = () => {
                return sellerItem?.attachment?.map((item, index) => {
                  return (
                    <div className="attachment-file-name sm" key={item.id}>
                      <a target="_blank" href={item.url} rel="noreferrer">
                        {item.file_name}
                      </a>
                    </div>
                  );
                });
              };

              return (
                <div className="flex-left">
                  <>
                    {sellerItem.attachment && (
                      <div style={{ marginRight: "10px" }}>
                        {renderAttachments()}
                      </div>
                    )}

                    <div>
                      {sellerItem?.message === "Zatwierdzone" && (
                        <span className="flex-center">
                          {" "}
                          <CheckCircle className="green mr-5" />
                          <span>Zatwierdzone</span>
                        </span>
                      )}
                      {sellerItem?.message === "Zatwierdź" && (
                        <Button
                          color="primary"
                          title="Zatwierdź"
                          onClick={() => confirm(row.id, columnId)}
                        />
                      )}
                      {sellerItem?.can_rollback && (
                        <Button
                          color="danger"
                          title="Wycofaj"
                          onClick={() => rollback(row.id, columnId)}
                        />
                      )}
                      {sellerItem?.message !== "Zatwierdzone" &&
                        sellerItem.message !== "Zatwierdź" &&
                        itemValues[sellerItem.message]}
                    </div>
                  </>
                </div>
              );
            },
          };
        }
      );
    } else {
      return [];
    }
  };

  const columns = [
    ...tableData.columns.slice(0, 10),
    ...renderSellerColumns(),
    ...tableData.columns.slice(10),
  ];

  const onSubmit = (values: IOSOP1FiltersFormValues) => {
    execFetchReports({ ...values, page: 1 });
  };

  const confirm = (ReportId: string, sellerId: string) => {
    ConfirmAlert({
      action: () => confirmWithRefresh(ReportId, sellerId),

      header: "Potwierdzenie wpłaty",
      text: "Potwierdź, że przelew tradycyjny został wykonany",
      yesText: "Tak",
      noText: "Nie",
      btnColor: "primary",
    });
  };

  const confirmWithRefresh = (reportId: string, sellerId: string) => {
    execConfirm(reportId, sellerId)
    execFetchReports({ ...formMethods.getValues() });
  };

  const rollback = (reportId: string, sellerId: string) => {
    ConfirmAlert({
      action: () => rollbackWithRefresh(reportId, sellerId),

      header: "Wycofanie potwierdzenia wpłaty transferem",
      text: "Potwierdź, że klient zmienił zdanie co do sposobu płatności. Wycofuje akcje nacisniecia przycisku 'Przelew tradycyjny'",
      yesText: "Tak",
      noText: "Nie",
      btnColor: "primary",
    });
  };

  const rollbackWithRefresh = (reportId: string, sellerId: string) => {
    execRollback(reportId, sellerId)
    execFetchReports({ ...formMethods.getValues() });
  };

  function getOptions(): Array<{ label: string; value: string }> {
    let opts: Array<{ label: string; value: string }> = []
    Object.keys(reportsState.data?.groups || []).map((key) => {
      opts.push(
        {
          value: key,
          label: reportsState.data?.groups[key] || ""
        }
      )
    }
    );
    return opts;
  }

  function yearOptions(): Array<{ label: string; value: string }> {
    let opts: Array<{ label: string; value: string }> = []
    for (let i = 2021; i <= new Date().getFullYear(); i++) {
      opts.push(
        {
          value: i.toString(),
          label: i.toString()
        }
      );
    }
    return opts;
  }

  return (
    <div className="sider-page my-vehicles">
      {(reportsState.loading || confirmState.loading) && (
        <Spinner size="large" />
      )}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Administrator", "Sprawozdania roczne BDO"])}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Sprawozdania roczne BDO {reportsState.data?.year}</h1>
        </header>
        <div className="block mb-20">
          <Row className="mb-0">
            <Col md={3}>
              <StatCounter
                title="Rozpoczęto"
                amount={
                  reportsState.data?.summary
                    ? `${reportsState.data?.summary.draft_today}/${reportsState.data?.summary.started_all}`
                    : null
                }
                suffix="dzisiaj / wszystkie"
              />
            </Col>
            <Col md={3}>
              <StatCounter
                title="Zakończono"
                amount={
                  reportsState.data?.summary
                    ? `${reportsState.data?.summary.submitted_today}/${reportsState.data?.summary.submitted_all}`
                    : null
                }
                suffix="Dzisiaj / wszystkie"
              />
            </Col>
          </Row>
        </div>
        <div className="list-filters">
          <Row type="flex" align="middle" gutter={15}>
            <Col md={2}>
              <TextField
                name="cbk_id"
                formMethods={formMethods}
                label="CBK ID"
              />
            </Col>
            <Col md={6}>
              <TextField
                name="company_name"
                formMethods={formMethods}
                label="Nazwa firmy"
              />
            </Col>
            <Col md={4}>
              <SelectField
                name="group"
                formMethods={formMethods}
                label="Dokument"
                onChange={formMethods.handleSubmit(onSubmit)}
                options={getOptions()}
              />
            </Col>
            <Col md={2}>
              <SelectField
                name="year"
                formMethods={formMethods}
                label="Rok"
                onChange={formMethods.handleSubmit(onSubmit)}
                options={yearOptions()}
              />
            </Col>
            <Col md={10} className="t-right">
              <Button
                title="Filtruj"
                color="primary"
                inline={true}
                width={150}
                onClick={formMethods.handleSubmit(onSubmit)}
                className="mr-5"
              />
              <Button
                title="Wyczyść"
                color="white"
                inline={true}
                width={150}
                onClick={() => {
                  formMethods.reset();
                  execFetchReports();
                }}
                className="mr-5"
              />
              <div className="green button inline mr-5">
                <Link to={"/mass_registry/bdo_admin_index_csv?token=" + (reportsState.data?.csv_token || '')} target="_blank">CSV</Link>
              </div>

            </Col>
          </Row>
        </div>
        <AntdTableWrapper
          setPage={setPage}
          data={reportsState.data}
          columns={columns}
          tableProps={{
            rowKey: "uuid",
            className: "custom-ant-table mb-10 grow-1",
          }}
        />
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(AdminBDOReports));
