import React, { useState } from "react";
import Select from "antd/lib/select";
import { Formik } from "formik";
import FormikTextField from "components/form-fields/formik-text-field";
import FormButton from "components/buttons/form-button";
import { sendVerificationCode } from "redux/actions/user";
import Spinner from "components/core/spinner";
import * as Yup from "yup";
import { yupRequired, email, isPasswordCorrect } from "validators";
import axios from "axios";
import { useReduxAction, useAsyncAction } from "utils/async-action";
import {
  ILoginFormValues,
  ISignIn,
  ISendVerificationCode,
  IStandardLoginFormValues,
  IStandardSignIn,
} from "types/login-types";
import "styles/pages/login.scss";
import { Link } from "react-router-dom";
import { standardSignIn, getWorkshopToken } from "actions/user";
import { IActionState } from "types/async-types";
import { PAGE } from "paths";
import Button from "antd/lib/button/button";

function LoginForm() {
  // props & hooks //
  const [codeSent, setCodeSent] = useState(false);
  const [stepNo, setStepNo] = useState(1);

  //async actions
  const [signInState, execStandardSignIn]: readonly [
    IActionState<any>,
    IStandardSignIn
  ] = useAsyncAction(standardSignIn);

  const [codeState, performSendVerificationCode]: readonly [
    any,
    ISendVerificationCode
  ] = useAsyncAction(sendVerificationCode);

  // functions //
  const logIn = async (data: IStandardLoginFormValues) => {
    // execStandardSignIn(values);

    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("redirect");
    // await getWorkshopToken();
    execStandardSignIn(data, redirectUrl);
  };

  const getCode = async (values: ILoginFormValues) => {
    setStepNo(2);
    const phoneNumber = values.prefix + values.phone;
    await performSendVerificationCode(`${phoneNumber}`);
    //** we can perform this in useEffect as well*/
    setCodeSent(true);
  };

  const renderPrefixOptions = () => {
    const prefixes: Array<{
      country: string;
      label: string;
      prefix: string;
    }> = [
      { country: "cz", label: "Czechy", prefix: "47" },
      { country: "es", label: "Hiszpania", prefix: "24" },
      { country: "de", label: "Niemcy", prefix: "43" },
      { country: "pl", label: "Polska", prefix: "48" },
      { country: "pt", label: "Portugalia", prefix: "12" },
    ];

    return prefixes.map((item, index) => {
      return (
        <Select.Option key={index} value={item.prefix}>
          <div className="prefix-option">
            <img src={`/static/img/${item.country}@2x.png`} />
            <span>
              <span className="country">{item.label}</span>{" "}
              <div className="tel-prefix">+ {item.prefix}</div>
            </span>
          </div>
        </Select.Option>
      );
    });
  };

  // form fields data
  const fields = {
    login: {
      name: "login",
      type: "text",
      label: "Nazwa użytkownika",
      required: true,
    },
    email: {
      name: "email",
      type: "email",
      label: "Adres email",
      required: true,
    },
    password: {
      name: "password",
      type: "password",
      label: "Hasło",
      required: true,
    } as React.ComponentProps<typeof FormikTextField>,
  };

  // Formik setup props
  const initialValues: IStandardLoginFormValues = {
    login: "",
    password: "",
  };
  let validationSchema = Yup.object().shape({
    login: Yup.string().concat(yupRequired),
    password: Yup.string().concat(yupRequired),
  });

  return (
    <div>
      <Formik
        className="login-form"
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={initialValues}
        onSubmit={logIn}
        // onSubmit={codeSent ? logIn : getCode}
      >
        {({ values, submitForm, isValid, errors }) => {
          const handleKeypress = (e: any) => {
            //it triggers by pressing the enter key
            if (e.charCode === 13) {
              submitForm();
            }
          };

          return (
            <div data-testid="standard-form" className="login-form">
              {signInState.loading && <Spinner />}
              <h2 className="primary text-32">Logowanie</h2>
              <FormikTextField
                testId={"email"}
                {...fields.login}
                onKeyPress={handleKeypress}
                type="text"
              />
              <FormikTextField
                testId={"password"}
                {...fields.password}
                className="mb-5"
                onKeyPress={handleKeypress}
                type="password"
              />
              <p className="t-right">
                <Link to={PAGE.CHANGE_PASSWORD}>zapomniałem hasła</Link>
              </p>
              <FormButton
                dataTest="submit-btn"
                
                disabled={!isValid}
                className="mt-16 mb-30"
                onClick={(values) => {
                  submitForm().then(() => {
                    // validateForm();
                  });
                }}
                title="Zaloguj"
                color="primary"
              />
               <p className="md-t mb-0">Nie masz konta?</p>
              <p className="md-t">Aby uzyskać dostęp do systemu <a className="t-bold" href="https://integrator.org.pl/?Contact/Main"> skontaktuj się z nami</a></p>
              <div className="t-center bottom-links"><a href="https://integrator.org.pl/files/polityka_prywatnosci_v2.pdf" target="_blank" rel="noreferrer">Polityka prywatności</a> <a href="https://integrator.org.pl/files/regulamin_serwisu_v4.pdf" target="_blank" rel="noreferrer">Regulamin serwisu</a> </div>
            </div>
          );
        }}
      </Formik>

      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </div>
  );
}

export default LoginForm;
