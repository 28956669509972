import React from "react";
// import { Field, FastField } from "formik";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import { ErrorMessage } from "@hookform/error-message";

// @ts-ignore
import "styles/components/input.scss";
import { getNested } from "utils/helpers";
import { Controller, UseFormReturn } from "react-hook-form";
import { ErrorLabel } from "components/common/error-message";

const renderOpts = (opts: Array<{ label: string; value: string }>) => {
  const options = opts.map((opt, index) => (
    <Select.Option
      data-testid={`option_${index}`}
      key={index}
      value={opt.value}
    >
      {opt.label}
    </Select.Option>
  ));
  options.unshift(
    <Select.Option
      data-testid={`option_placeholder`}
      key="default"
      value=""
      disabled
    >
      Wybierz
    </Select.Option>
  );
  return options;
};

export interface ISelectProps {
  mode?: "multiple" | "tags";
  optFixedWidth?: boolean;
  defaultValue?: string;
  label: string;
  size?: string;
  customOptsRender?: any;
  name: string;
  onChange?: (e: any) => void;
  options: Array<{ label: string; value: string }>;
  placeholder?: string;
  disabled?: boolean;
  testId?: string;
  required?: boolean;
  type?: "select";
  value?: string;
  selectedValue?: string | boolean;
  formMethods: UseFormReturn<any>;

  rules?: any;
}

export default function SelectFormField(props: ISelectProps) {
  //   const { field, label, size } = props;

  const {
    mode,
    optFixedWidth,
    defaultValue,
    onChange,
    label,
    size,
    customOptsRender,
    required,
    disabled,
    testId,
    value,
    selectedValue,
    formMethods,

    rules,
  } = props;
  const name: string = props.name;
  const options: Array<{ label: string; value: string }> = props.options;
  return (
    <>
      <Form.Item required={required} label={label} className={size}>
        <Controller
          name={name}
          control={formMethods?.control}
          rules={rules}
          render={({ field, fieldState, formState }) => {
            const touched = getNested(formState.touchedFields, name);
            const error = getNested(formState.errors, name);

            return (
              <div>
                <Select
                  showSearch={true}
                  data-testid={testId}
                  aria-disabled={disabled || false}
                  mode={mode}
                  placeholder="Wybierz"
                  defaultValue={defaultValue}
                  id={name}
                  disabled={disabled}
                  {...field}
                  // @ts-ignore
                  value={
                    value ||
                    getNested(formMethods?.getValues(), name) ||
                    selectedValue
                  }
                  onChange={(e: string) => {
                    // formMethods?.setValue(name, e)
                    field.onChange(e);
                    if (onChange) {
                      onChange(e);
                    }
                  }}
                  // value={options.length > 0 ? form.values[name] : ""}
                >
                  {customOptsRender ? customOptsRender : renderOpts(options)}
                </Select>
                {formMethods?.formState && (
                  <ErrorMessage
                    errors={formMethods?.formState.errors}
                    name={name}
                    render={({ message }: any) => (
                      ErrorLabel(message) 
                    )}
                  />
                )}
                {/* {(touched && error ) &&
                  <span data-testid="text-field-error" className="input-error">{error} </span>
                } */}
              </div>
            );
          }}
        />
      </Form.Item>
      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </>
  );
}
