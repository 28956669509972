import {
  isPESELCorrectHook,
  isEmailHookCorrect,
  isPhoneCorrectHook,
} from "validators";

export const personFields = {
  pesel: {
    label: "PESEL",
    name: "pesel",
    maxlength: 13,
    required: true,
    rules: {
      required: "Pole jest wymagane",
      validate: isPESELCorrectHook,
    },
  },

  email: {
    label: "Email",
    name: "email",
    required: true,
    rules: {
      required: "Pole jest wymagane",
      validate: isEmailHookCorrect,
    },
  },
 
  phone: {
    label: "Phone",
    name: "phone",
    rules: {
      validate: isPhoneCorrectHook,
    },
  },
};
