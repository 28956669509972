import SelectFormField from "components/form-fields/select-field";
import TextFormField from "components/form-fields/formik-text-field";
import CheckFormField from "components/form-fields/check-form-field";
import { ICheckGroupProps } from "components/form-fields/checkbox-group";
import { getYears, renderJsonOptions } from "utils/select-options";

type SelectProps = React.ComponentProps<typeof SelectFormField>;
type TextProps = React.ComponentProps<typeof TextFormField>;
type CheckProps = React.ComponentProps<typeof CheckFormField>;

// import { SecurityType } from "types/insurance-types";


export enum vehicleFieldNames {
  id,
  uuid,
  vin_number,
  brand,
  model,
  vehicle_type,
  production_year,
  engine_name,
  engine_power_hp,
  engine_power_kw,
  engine_capacity,
  odometer_value,
  fuel_type,
  is_right_hand_drive,
  color,
  color_option,
  has_dpf,
  drive_type,
  transmission_type,
  co2_emission,
  body_type,
  door_count,
  seats_count,
  equipment_variant,
  origin_country_code,
  first_registration_date,
  import_date,
  status,
  equipment,
  index,
  version,
  vehicle_usage_type,
  registration_number,
  purchase_date,
  security_items,
  is_vehicle_registered_in_poland,
  is_leasing,
  is_vehicle_imported,
  condition,
  condition_date,
  ev_battery_capacity
}

const names = vehicleFieldNames;

export const vehicleFields: {
  vehicleType: SelectProps;
  vinNumber: TextProps;
  productionYear: SelectProps;
  brand: SelectProps;
  model: SelectProps;
  version: TextProps;
  fuelType: SelectProps;
  engineCapacity: SelectProps;
  enginePowerKW: TextProps;
  enginePowerHP: SelectProps;
  enginePower: TextProps;
  bodyType: SelectProps;
  seatsCount: TextProps;
  hasRightHand: CheckProps;
  registrationCountry: SelectProps;
  registrationNumber: TextProps;
  firstRegistrationDate: TextProps;
  courseCount: TextProps;
  buyYear: SelectProps;
  isDamaged: SelectProps;
  // securityItems: ICheckGroupProps<SecurityType>;
  otherSecurity: TextProps;
  registrationConfirmation: CheckProps;
  originCountry: SelectProps;
  transmissionType: SelectProps;
  importDate : TextProps;
  evBatteryCapacity : SelectProps;
} = {
  importDate : {
    type : "date",
    label : "Data importu",
    name : "import_date"
  },

  transmissionType: {
    name: "transmission_type",
    label: "Rodzaj skrzyni biegów",
    type : "select",
    options: [
      {
        value: "none",
        label: "N/D",
      },
      { value: "manual", label: "Manualna" },
      { value: "automatic", label: "Automatyczna" },
      { value: "stepless", label: "Bezstopniowa" },
      { value: "sequential", label: "Sekwencyjna" },
      { value: "dual_clutch", label: "Dwusprzęgłowa" },
      { value: "one_clutch", label: "Półautomatyczna stopniowa" },
    ],
  },

  vehicleType: {
    name: "vehicle_type",
    label: "Rodzaj pojazdu",
    options: [],
  },
  vinNumber: {
    name: names[names.vin_number],
    label: "Numer Vin",
  },
  productionYear: {
    name: names[names.production_year],
    label: "Rok produkcji",
    options: renderJsonOptions(getYears()),
  },

  evBatteryCapacity: {
    name: names[names.ev_battery_capacity],
    label: "Pojemność baterii",
    options: renderJsonOptions(getYears()),
  },

  brand: {
    name: names[names.brand],
    label: "Marka Pojazdu",
    options: [],
  },
  model: {
    name: names[names.model],
    label: "Model",
    options: [],
  },
  version: {
    name: names[names.version],
    label: "Wersja",
  },
  fuelType: {
    name: names[names.fuel_type],
    label: "Silnik",
    options: [],
  },
  engineCapacity: {
    name: names[names.engine_capacity],
    label: "Pojemność skokowa",
    type: "select",

    options: [],
  },

  enginePowerKW: {
    name: names[names.engine_power_kw],
    label: "Moc silnika (kW)",
    type: "text",
    suffix: "kW",
  },

  enginePowerHP: {
    name: names[names.engine_power_hp],
    label: "Moc silnika (KM)",
    type: "select",

    options: [],
  },
  enginePower: {
    name: "engine_power",
    label: "Moc silnika",
    type: "text",
  },
  bodyType: {
    name: names[names.body_type],
    label: "Nadwozie",
    options: [],
  },
  seatsCount: {
    name: names[names.seats_count],
    label: "Liczba miejsc",
    type: "number",
  },
  hasRightHand: {
    name: names[names.is_right_hand_drive],
    cbxLabel: "Kierownica po prawej stronie",
    value: true,
    isFlag: true,
  },

  registrationCountry: {
    name: "registration_country",
    label: "Kraj rejestracji",
    type: "select",
    options: [],
  },

  originCountry: {
    name: "origin_country",
    label: "Kraj pochodzenia",
    type: "select",
    options: [],
  },

  courseCount: {
    name: "course_count",
    label: "Przebieg",
    suffix: "km",
  },

  registrationNumber: {
    name: "registration_number",
    label: "Numer rejestracyjny",
  },

  firstRegistrationDate: {
    type: "date",
    name: "first_registration_date",
    label: "Data  pierwszej rejestracji",
  },

  buyYear: {
    name: "buy_year",
    label: "Rok nabycia pojazdu",
    options: renderJsonOptions(getYears()),
    type: "select",
  },

  isDamaged: {
    name: "is_demaged",
    options: [
      { label: "Uszkodzony", value: "true" },
      { label: "Nieuszkodzony", value: "false" },
    ],
    label: "Stan pojazdu",
  },

  // securityItems: {
  //   name: names[names.security_items],
  //   orientation: "vertical",
  //   label: "Zabezpieczenia",
  //   options: [],
  // },
  otherSecurity: {
    type: "text",
    name: "other_security",
    label: "Inne (jakie)",
  },
  registrationConfirmation: {
    cbxLabel:
      "Potwierdzam, że samochód jest zarejestrowany poza granicami RP (na terenie Unii Europejskiej), będzie rejestrowany w Polsce oraz data zakupu nie jest starsza niż 30 dni od daty wystawienia polisy.",
    isFlag: true,
    value: true,
    name: "registration_confirmation",
  },
};


export const payments = {
  name: "vehicle_products_attributes",
  orientation: "vertical",
  label: "",
  options: [
  ],
}