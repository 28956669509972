import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import { IActionState } from "types/async-types";
import {
  IWasteAdmin,
  IProduct,
  IBDOServices,
  IDashboardProduct,
} from "types/dashboard-types";
import { fetchWasteAdministration, fetchBDOServices, fetchNetworkServices } from "actions/dashboard";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { numToCurrency } from "utils/helpers";
import ProductsList from "../services-list";

const NetworkServices = (props: any) => {
  const [networkProvisionState, execFetchData]: readonly [
    IActionState<IDashboardProduct>,
    typeof fetchNetworkServices
  ] = useAsyncAction(fetchNetworkServices);

  useEffect(() => {
    execFetchData();
  }, []);

  return (
    <div className="block">
      {networkProvisionState.loading && <Spinner />}
      <h2 className="primary h-lg">Zapewnienie sieci</h2>
      <p className="md-t mt-5 mb-10">
        Zapewniamy sieć zbierania pojazdów oraz pomagamy w rocznych
        rozliczeniach
      </p>
      <div className="mb-15">
      <ProductsList products={networkProvisionState.data?.products} />
      </div>
      {networkProvisionState.data?.agreement_button && (
        <Button
          width={200}
          color="primary"
          className="mr-10"
          inline={true}
          title="Uzyskaj dostęp do usług"
          outerLink={true}
          newCard={true}
          link={networkProvisionState.data.agreement_url}
        />
      )}
    </div>
  );
};

export default NetworkServices;
