import React from "react";
import { ErrorMessage } from "formik";
export const ErrorLabel = (msg : string, className? : string) => (
  <div className="absolute-msg"> <span className={`input-error ${className || ""}`}>{msg}</span> </div>
);

function ErrorMsg(props: {name : string, className? : string}) {
  const { name , className} = props;

  return <ErrorMessage name={name} render={(msg) =>  ErrorLabel(msg, className)} />;
}

export default ErrorMsg;