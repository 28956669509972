import React, { useState, useEffect, useCallback, useMemo } from "react";
import Row from "antd/lib/row";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import Col from "antd/lib/col";
import { IActionState, ActionResponse } from "types/async-types";
import {
  fetchPaymentSummary,
  fetchAccountingDocument,
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import { title } from "process";
import {
  ISummary,
  ITransferSummary,
} from "pages/settlements/bank-transfer-summary";
import AntdTableWrapper from "components/common/antd-table-wrapper";
import {
  IAccountingDocument,
  documentTypes,
  IDocumentPdf,
} from "types/document-types";
import FileDropzone from "components/admin/file-dropzone-simple";
import { ColumnProps } from "antd/lib/table";
import { IExistingFile } from "types/translation-types";
import { useForm } from "react-hook-form";
import {
  deleteOSOP1Attachment,
  transferPaymentSummary,
  confirmTransfer,
} from "actions/BDO-reports";
import { IOSOP1FormData, IAdminBDOPayment } from "types/BDO-report-types";
import FormButtons from "components/common/form-buttons";
import FormButton from "components/buttons/form-button";
import { numToCurrency, getTransferTitle, renderBreadcrumbs } from "utils/helpers";
import Spinner from "components/core/spinner";
import { useHistory, useParams } from "react-router-dom";
import { PAGE } from "paths";
import {
  confirmBDOReportPayment,
  fetchAdminBDOReportPayment,
} from "actions/admin";
import { withAuthSync } from "utils/auth";
import withLayout from "components/layout/MyLayout";
import Button from "components/buttons/button";
import { formatDateTime } from "utils/date";
import { ConfirmModal as ConfirmAlert } from "components/common/confirm-modal";

const AdminPaymentDetails = (props: any) => {
  const { id }: { id: string } = useParams();
  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const [existingFiles, setExistingFiles] = useState<IExistingFile[]>([]);
  const history = useHistory();

  const [paymentState, execFetchPayment]: readonly [
    IActionState<IAdminBDOPayment>,
    typeof fetchAdminBDOReportPayment
  ] = useAsyncAction(fetchAdminBDOReportPayment);

  const [confirmState, execConfirm]: readonly [
    IActionState<any>,
    typeof confirmBDOReportPayment
  ] = useAsyncAction(confirmBDOReportPayment);

  useEffect(() => {
    if (id) {
      execFetchPayment(id);
    }
  }, []);

  useEffect(() => {
    if (paymentState.data) {
      if (paymentState.data.attachments) {
        setExistingFiles(paymentState.data.attachments);
      }
    }
  }, [paymentState.data]);

  useEffect(() => {
      if(confirmState.data){
        history.push(PAGE.ADMIN_BDO_PAYMENTS)
      }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmState.data]);

  const downloadFile = (id: string) => {};

  const renderColumns = useMemo((): ColumnProps<IAccountingDocument>[] => {
    return [
      {
        title: "Data",
        align: "left",
        width: 100,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.draw_date}</div>
        ),
      },
      {
        title: "Typ",
        align: "left",
        width: 200,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap column-upper">{documentTypes[row.type]}</div>
        ),
      },
      {
        title: "Numer",
        align: "left",
        width: 140,
        render: (field: any, row: IAccountingDocument) => (
          <a onClick={() => downloadFile(row.id)}>{row.number}</a>
        ),
      },
      {
        title: "Sprzedający",
        align: "left",
        width: 450,
        render: (field: any, row: IAccountingDocument) => (
          <div>{row.seller_string}</div>
        ),
      },
      {
        title: "Termin płatności",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}
          >
            {row.payment_due_date}
          </div>
        ),
      },
      {
        title: "Dni do terminu zapłaty",
        align: "left",
        width: 110,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}
          >
            {row.days_due_date}
          </div>
        ),
      },
      {
        title: "Kwota do zapłaty",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap t-500 ${row.payment_date ? "green" : "red"}`}
          >
            {" "}
            {row.brutto} zł
          </div>
        ),
      },
    ];
  }, [downloadFile]);

  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  // const confirm = (id: string) => {
  //   ConfirmAlert({
  //     action: () => execConfirm(id),

  //     header: "Potwierdzenie wpłaty",
  //     text: "Potwierdź, że przelew tradycyjny został wykonany",
  //     yesText: "Tak",
  //     noText: "Nie",
  //     btnColor: "primary",
  //   });
  // };

  const formatDocumentNumbers = (numbers?: string) => {
      if(!numbers){
          return "";
      }
    return numbers.split("|").join(", ");
  };

  return (
    <div className="sider-page">
      {(paymentState.loading || confirmState.loading) && (
        <Spinner size="large" />
      )}
      <main className="page-content">
      <div className="breadcrumbs">
          {renderBreadcrumbs(["Administrator", "Płatności"])}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Płatności</h1>
        </header>
        <div className="block  mb-10">
        <h2 className="primary h-lg mb-20">Szczególy płatności</h2>
        <div className="mb-5">
            <div className="black i-b mr-5">Kwota: </div>
            <div className="i-b black t-500 ">{numToCurrency(paymentState.data?.amount)}</div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Numery dokumentów: </div>
            <div className="i-b black t-500 ">{formatDocumentNumbers(paymentState.data?.document_numbers)}</div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Opis: </div>
            <div className="i-b black t-500">
              {paymentState.data?.description}
            </div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Data: </div> 
            <div className="i-b black t-500">
              {formatDateTime(paymentState.data?.user_confirmation_time)}
            </div>
          </div>
        </div>
      {/* <AntdTableWrapper
        noTotal={true}
        noPagination={true}
        data={summaryState.data?.documents || null}
        columns={renderColumns}
        tableProps={{
          rowKey: "uuid",
          className: "custom-ant-table mb-10",
        }}
      /> */}
        <div className="block mb-10">
          <h2 className="h-lg">Potwierdzenia przelewu</h2>
          <FileDropzone
            formMethods={formMethods}
            files={newFiles}
            setFiles={setNewFiles}
            existingFiles={existingFiles}
            readOnly={true}
          />
        </div>
        <div className="block">
          <FormButton
            width={220}
            color="primary"
            onClick={() => {}}
            title="Potwierdź płatność"
            inline={true}
            className="mr-5"

          />
          <Button
            width={220}
            color="primary"
            link={PAGE.ADMIN_BDO_PAYMENTS}
            title="Powrót do płatności"
            inline={true}
          />
        </div>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(AdminPaymentDetails));
