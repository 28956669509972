import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";
import {  ActionResponse } from "types/async-types";
import { requestHeaders } from "utils/auth";
import { IBDOKey } from "types/BDO-keys-types";

const BDO_KEY_URL = `${API_BASE_URL}/bdo_keys`

export const createBDOKey = async (bdoKey :  {client_id : string, client_secret: string, key_type : string}) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_KEY_URL}`,
    headers: requestHeaders().headers,
    method: "POST",
    data : bdoKey
  };
  
  const res  = await axios(options);
  const result : ActionResponse<any> = {
    ...res,
    successMessage : "Zapisano klucz BDO"
  }
  return result;
  
};

export const updateBDOKey = async (id : string, bdoKey : IBDOKey) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_KEY_URL}/${id}`,
    headers: requestHeaders().headers,
    method: "PUT",
    data : bdoKey
  };
  
  const res  = await axios(options);
  const result : ActionResponse<any> = {
    ...res,
    successMessage : "Zaktualizowano klucz BDO"
  }
  return result;
};

export const getIntegrationNotice: () => Promise<AxiosResponse<any>> = async () => {
    const options: AxiosRequestConfig = {
      url: `${BDO_KEY_URL}/integration_notice`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    
    const res  = await axios(options);
    return res;
  };

  export const fetchBDOKeyPageFormData: () => Promise<AxiosResponse<any>> = async () => {
    const options: AxiosRequestConfig = {
      url: `${BDO_KEY_URL}/form_data`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    
    const res  = await axios(options);
    return res;
  };

  export const fetchBDOKeyFormData: () => Promise<AxiosResponse<any>> = async () => {
    const options: AxiosRequestConfig = {
      url: `${BDO_KEY_URL}/form_data/default`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    
    const res  = await axios(options);
    return res;
  };

  export const fetchBDOKeys = async (page?: number) => {
    const options: AxiosRequestConfig = {
      url: `${BDO_KEY_URL}`,
      headers: requestHeaders().headers,
      method: "GET",
      params : {
        page,
        
      }
    };
    
    const res  = await axios(options);
    return res;
  };

  export const checkBDOKey = async (id : string) => {
    const options: AxiosRequestConfig = {
      url: `${BDO_KEY_URL}/${id}/check_key`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    
    const res  = await axios(options);
    return res;
  };

  export const fetchBDOKeysHistory: () => Promise<AxiosResponse<any>> = async () => {
    const options: AxiosRequestConfig = {
      url: `${BDO_KEY_URL}/history`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    // let res = null;
    const res  = await axios(options);
    // const result :IActionState = res.data;
    
    return res;
  };