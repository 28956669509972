import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { showWasteCard, editWasteCard, editTransferCard } from "actions/waste";
import { IWasteCard, IWasteCardFormValues } from "types/waste-card-types";
import { ICode } from "types/waste-types";
import { formatDateTime } from "utils/date";
import { fetchAddresses } from "actions/company";
import { useAsyncAction } from "utils/async-action";
// TO UNCOMMENT
// import WasteAddress from '../waste-create-form/waste-address/waste-address'
import CheckFormField from "components/form-fields/check-form-field";
import TextField from "components/form-fields/formik-text-field";
import SelectField from "components/form-fields/select-field";
import CodeTextField from "components/waste/waste-create-form/text-field";
import CodeSelectField from "components/waste/waste-create-form/select-field";
import { useParams } from "react-router-dom";
import { required, dateRequired, yupRequired } from "validators/index";
import SpinnerPopup from "components/core/spinner";
import _ from "lodash";
import * as Yup from "yup";
import { PAGE } from "paths";
import { FIELD_NAMES } from "config/form-config/waste-card-config";
import style from "../waste-create-form/waste-card-form.module.scss";
import { IActionState } from "types/async-types";
import { Row, Col } from "antd";
import Button from "components/buttons/form-button";
import WasteAddress from "../waste-create-form/waste-address/waste-address";

type ITextProps = React.ComponentProps<typeof TextField>;
interface Props {
  [x: string]: any;
  wasteCard: IWasteCard;
  closeSider: () => any;
  execFetchCardsHistory: any;
}
function WasteCardEdit(props: Props & FormikProps<IWasteCardFormValues>) {
  const {
    values,
    setFieldValue,
    execShowWasteCard,
    wasteCardState,
    submitForm,
    id,
    editWasteCardState,
    editTransferCardState,
    isValid,
    errors,
    setFieldTouched,
    closeSider,
    execFetchCardsHistory,
  } = props;

  const { wasteCard }: { wasteCard: IWasteCard } = props;

  const history = useHistory();

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [noCard, setNoCard] = useState(false);
  const [addressOpts, setAddressOpts] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressesState, execFetchAddresses] = useAsyncAction(fetchAddresses);
  const [wasteCardMode, setWasteCardMode] = useState(false);

  const unitOptions = [
    { value: "kg", label: "kg", default: true },
    { value: "mg", label: "mg" },
  ];

  // const onAmountChange = (e: any, code: string) => {
  //   const val = parseInt(e.target.value);
  //   if (!val) {
  //     setFieldValue(`items[${code}]`, null);
  //   } else {
  //     setFieldValue(`items[${code}]`, {
  //       amount: val,
  //       unit:
  //         values.items[code] && values.items[code].unit
  //           ? values.items[code].unit
  //           : "kg",
  //     });
  //   }
  // };

  const onUnitChange = (e: { target: { value: string } }, code: string) => {
    const val = e.target.value;

    // if(!values.codes[code] || !values.codes[code].amount){
    //   return;
    // }
    let amount = values.codes[code].amount;
    if (amount != null) {
      if (val === "kg") {
        amount = amount * 1000;
      } else if (val === "mg") {
        amount = amount / 1000;
      }
    }

    setFieldValue(`codes[${code}]`, {
      ...values.codes[code],
      amount: amount,
      unit: val,
    });

    setFieldTouched(`codes[${code}]`, true, true);
  };

  const renderCodes = () => {
    if (!wasteCard) {
      return <tr></tr>;
    }

    function validateCode(amount: number, code: string) {
      let unit: string | undefined = "";
      if (values.codes[code]) {
        unit = values.codes[code].unit ? values.codes[code].unit : "kg";
      }
      let error;

      if (
        wasteCard.card_type !== "import" &&
        (amount === 0 ||
          (unit === "kg" && amount < 1) ||
          (unit === "mg" && amount < 0.001))
      ) {
        error = "Wartość nie może być równa 0";
      }
      return error;
    }

    const codes: any = errors.codes;

    return wasteCard.items.map((item: ICode, index: number) => {
      return (
        <tr className="odd record-2-row first">
          <td className="code">
            <div>{item.code}</div>
          </td>{" "}
          <td>
            <div>
              {item.name}
              <div className="info">{item.info}</div>
            </div>
          </td>{" "}
          <td className="_check">
            <Row gutter={5}>
              <Col md={12}>
                <CodeTextField
                  name={`codes['${item.normalized_code}'].amount`}
                  type="number"
                  min="0"
                  validate={(amount: number) =>
                    validateCode(amount, item.normalized_code)
                  }
                  disableErrorMessage={true}
                  // onChange={(e: any) => onAmountChange(e, item.normalized_code)}
                />
                {errors.codes &&
                  item.normalized_code &&
                  codes[item.normalized_code] && (
                    <label className="validation_error">
                      {codes[item.normalized_code].amount}
                    </label>
                  )}
              </Col>
              <Col md={12}>
                <CodeSelectField
                  name={`codes['${item.normalized_code}'].unit`}
                  type="select"
                  options={unitOptions}
                  disableDefault={true}
                  initialValue="kg"
                  onChange={(e: { target: { value: string } }) =>
                    onUnitChange(e, item.normalized_code)
                  }
                />
              </Col>
            </Row>
          </td>
        </tr>
      );
    });
  };

  /** side effects */
  useEffect(() => {
    const noCard: Array<string> = values.no_card_number;
    // setTimeout(() => validateForm());
    if (noCard.length > 0) {
      setNoCard(true);
      setFieldValue(FIELD_NAMES.CARD_NR, "");
    } else {
      setNoCard(false);
    }

    // setTimeout(() => validateForm());
  }, [values.no_card_number]);

  useEffect(() => {
    execShowWasteCard(id);
  }, []);

  useEffect(() => {
    execFetchAddresses();
  }, []);

  useEffect(() => {
    if (editWasteCardState.data || editTransferCardState.data) {
      closeSider();
      execFetchCardsHistory();
    }
  }, [editWasteCardState.data, editTransferCardState.data]);

  useEffect(() => {
    const addresses = addressesState.data;
    if (addresses) {
      if (!addresses.length) {
        setAddressOpts([]);
      } else {
        const opts = addresses.map((item: any, index: number) => {
          return {
            label: `${item.street} ${item.building_number}${
              item.locum_number ? `/${item.locum_number}` : ""
            } ${item.postal_code} ${item.city}`,
            value: item.id,
          };
        });
        setAddressOpts(opts);
      }
    }
  }, [addressesState.data]);

  // *** form fields *************************************** //
  const formFields = {
    card_number: {
      name: FIELD_NAMES.CARD_NR,
      type: "text",
      label: "Numer karty",
      required: !noCard,
      disabled:
        noCard || (wasteCard && wasteCard.card_type !== "import_export"),
    } as ITextProps,

    no_card_number: {
      id: `no_card_number`,
      name: FIELD_NAMES.NO_CARD,
      cbxLabel: "Nie posiadam numeru karty",
      value: "true",
    },

    manufacture_date: {
      name:
        wasteCard && wasteCard.card_type === "export"
          ? "transfer_date"
          : FIELD_NAMES.DATE,
      type: "date",
      label:
        wasteCard &&
        (wasteCard.card_type === "export" ||
          wasteCard.card_type === "import_export")
          ? "Data przekazania odpadu"
          : "Data wytworzenia odpadu",
      required: true,
    } as ITextProps,
    [FIELD_NAMES.WASTE_ADDRESS]: {
      name: FIELD_NAMES.WASTE_ADDRESS,
      type: "text",
      label: "Miejsce generowania odpadu",
      required: true,
      options: addressOpts,
    },
  };

  // adding_disabled : true,
  //   setFieldValue,
  //   onChange : onAddressChange,
  //   values,
  //   wasteCardMode : true,
  //   addressesState,
  //   execFetchAddresses,
  //   addressField : fieldData[field.WASTE_ADDRESS]

  const addressModalProps = {
    adding_disabled: true,
    input_disabled: true,
    setFieldValue,
    values,
    setIsSubmitting,
    setSelectedAddress,
    addressField: formFields[FIELD_NAMES.WASTE_ADDRESS],
    addressesState,
  };

  if (editWasteCardState.loading || editTransferCardState.loading) {
    return <SpinnerPopup size="small" />;
  }
  if (wasteCardState.loading || addressesState.loading) {
    return <SpinnerPopup />;
  }

  if (!wasteCard) {
    return <div></div>;
  }

  return (
    <>
      <div className="sider-body">
        {/* {(isSubmitting || isLoading) && <SpinnerPopup/>} */}
        {/* {!values.is_card &&
        <AddressModal {...addressModalProps} />
      } */}

        {/* {wasteCardMode && <TableTextField {...formFields[FIELD_NAMES.CARD_NR]} />} */}
        {/* {wasteCard.card_type === 'import_export' && */}

        <Row gutter={10}>
          <Col md={14}>
            <TextField {...formFields.card_number} label="Numer karty" />
          </Col>
          <Col md={8}>
            {wasteCard.card_type === "import_export" && (
              <div className="mt-10 mt-20">
                <CheckFormField {...formFields.no_card_number} />
              </div>
            )}
          </Col>
        </Row>
        <WasteAddress {...addressModalProps} />
        <Row className="mb-10" gutter={10}>
          <Col md={8}>
            <TextField {...formFields.manufacture_date} />
          </Col>
        </Row>

        {/* } */}

        <h4 className="primary mb-10">Kody odpadów</h4>
        <div className={`${style.waste_group_header}`}>
          <table className="custom-ant-table ant-table waste-table">
            <thead className="ant-table-thead">
              <tr>
                <th className="code">Nr i kod odpadu</th>
                <th className="_name">Rodzaj odpadu</th>
                <th className="text-left">Masa odpadu</th>
              </tr>
            </thead>
            <tbody>{renderCodes()}</tbody>
          </table>
        </div>
        <div className="i-b">
      
        </div>
      </div>
      <div className="sider-footer t-right">
      <Button
            title="Zapisz"
            color="primary"
            onClick={submitForm}
            disabled={!isValid}
            width={150}
            className="f-right"
          />
      </div>
    </>
  );
}

const FormWrapper = (props: any) => {
  const [wasteCardState, execShowWasteCard] = useAsyncAction(showWasteCard);
  const [editWasteCardState, execEditWasteCard]: readonly [
    IActionState<any>,
    any
  ] = useAsyncAction(editWasteCard);
  const [editTransferCardState, execEditTransferCard] = useAsyncAction(
    editTransferCard
  );
  const [initialValues, setInitialValues] = useState<IWasteCardFormValues>({
    codes: {},
    no_card_number: [],
  });

  const query = window.location.search;
  const params = new URLSearchParams(query);
  const id = params.get("id");
  const wasteCard: IWasteCard = wasteCardState.data;

  useEffect(() => {
    if (wasteCardState.data) {
      const {
        is_card,
        card_number,
        manufacture_date,
        transfer_date,
        address_id,
        card_type,
      } = wasteCard;

      // let dateStr : string | null = null;
      // if(transfer_date){
      //   const date = new Date(transfer_date);
      //   const month = (date.getMonth() + 1);
      //   let monthStr = "";
      //   if(month < 10){
      //       monthStr = '0' + month
      //   }else{
      //     monthStr = month.toString();
      //   }
      //    dateStr = date.getFullYear() + "-" + monthStr + "-" + date.getDate()

      // }

      const initialValues: IWasteCardFormValues = {
        is_card,
        card_number,
        manufacture_date: manufacture_date ? manufacture_date : "",
        transfer_date: formatDateTime(transfer_date),
        address_id,
        card_type,
        codes: _.keyBy(wasteCard.items, "normalized_code"),
        no_card_number:
          card_number == null || card_number === "" ? ["true"] : [],
      };
      setInitialValues(initialValues);
    }
  }, [wasteCardState.data]);

  const onSubmit = (values: IWasteCardFormValues) => {
    let data = {};

    const {
      manufacture_date,
      card_number,
      address_id,
      no_card_number,
    } = values;
    data = {
      manufacture_date,
      address_id,
      card_number,
      codes: values.codes,
      no_card_number: no_card_number[0] === "true",
    };
    execEditWasteCard(id, data);
    // }
  };

  const schema = Yup.object().shape({
    no_card_number: Yup.string(),
    card_number: Yup.string().when(FIELD_NAMES.IS_CARD, {
      is: false,
      then: Yup.string().notRequired(),
      otherwise: Yup.string().when(FIELD_NAMES.NO_CARD, {
        is: "true",
        then: Yup.string().notRequired(),
        otherwise: Yup.string().concat(yupRequired),
      }),
    }),
    address_id: Yup.string().concat(yupRequired),
    // manufacture_date: Yup.date().concat(dateRequired).test(`manufacture_date`, `Dopuszczalna jest tylko data między 01.01.2019 a dniem bieżącym`, function (
    //   value
    // ) {

    //   if(value > new Date() || value < new Date('01-01-2019')){
    //     return false;
    //   }else{
    //     return true;
    //   }
    // })
  });

  const formConfig = {
    onSubmit: onSubmit,
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: schema,
  };
  const childProps = {
    ...props,
    wasteCardState,
    execShowWasteCard,
    id,
    editWasteCardState,
    editTransferCardState,
    wasteCard,
  };

  return (
    <Formik {...formConfig}>
      {(formikProps: FormikProps<IWasteCardFormValues>) => {
        return <WasteCardEdit {...childProps} {...formikProps} />;
      }}
    </Formik>
  );
};

export default FormWrapper;
