import React, { useEffect } from "react";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import "styles/components/bdo-report/bdo-report-popup.scss";
import PendingInvoices from "./step-2/bdo-report-pending-invoices";
import Spinner from "components/core/spinner";
import FormButton from "components/buttons/form-button";
import {

  ISellerDocumentsDebt,
} from "types/document-types";
import FormButtons from "components/common/form-buttons";
import { useGraphForm } from "./graph-form-utils";

const BDOYearReportStep2 = (props: { setNavState: any }) => {
  const { setNavState } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const {
    state,
    formData,
    initializeStep,
    saveStep,
    goToStep,
    stepNavData,
  } = useGraphForm<{
    current: ISellerDocumentsDebt[];
    show_continue_button: boolean;
  }>(id);

  useEffect(() => {
    initializeStep("payments");
  }, []);

  useEffect(() => {
    setNavState({
      goToStep,
      stepNavData,
    });
  }, [stepNavData]);

  const submitForm = () => {
    saveStep("payments");
  };

  return (
    <>
      {state.isLoading && <Spinner size="large" fixed={true} />}
      <div className="block">
        <p className="large mb-0 flex-top-left">
          <span className="warning-label">
            {" "}
            <WarningOutlinedIcon />
          </span>{" "}
          Przypominamy, że w warunkiem koniecznym dla wygenerowania i
          przekazania sprawozdania jest brak zaległości w zapłacie jakichkolwiek
          świadczeń obciążających Klienta na rzecz Integratora, Stacji Demontażu
          i Organizacji Odzysku. Skorzystaj z płatności online, aby szybko
          przejść do generowanie sprawozdań. W przypadku płatności przelewem
          tradycyjnym przygotuj potwierdzenie zapłaty i skontaktuj się z biurem
          Integratora
        </p>
      </div>
      <h2 className="primary h-lg mb-10">Opłaty</h2>
      <PendingInvoices invoicesState={formData.data?.current} />

      <FormButtons>
        <FormButton
          disabled={!formData.data?.show_continue_button}
          width={220}
          color="primary"
          onClick={submitForm}
          title="Kontynuuj"
        />
      </FormButtons>
    </>
  );
};

export default BDOYearReportStep2;
