import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import withLayout from "components/layout/MyLayout";
// import { withAuthSync } from "utils/auth";
import Layout from "antd/lib/layout";

import Table from "antd/lib/table";
import { Link, useHistory } from "react-router-dom";
import Button from "components/buttons/button";
import {
  fetchVehicleFormData,
  fetchVehicles,
  filterVehicles,
} from "actions/vehicles";
import { PAGE } from "paths";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import TextFormField from "components/form-fields/formik-text-field";
import FormField from "components/form-fields/form-field";
// import CarIcon from "@material-ui/icons/DriveEtaOutlined";
//@ts-ignore
// import "styles/pages/my-vehicles.scss";
import {
  IVehicle,
  IVehicleFormData,
  IVehicleBasicFormValues,
} from "types/vehicle-types";
import { Formik, FormikProps } from "formik";
import { IActionState } from "types/async-types";
import Spinner from "components/core/spinner";
import { withAuthSync } from "utils/auth";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "redux/reducers";
import { Row, Col } from "antd";
import CarDataForm from "components/my-vehicles/car-data-form";
import DraftCarForm from "components/my-vehicles/draft-car-form";
import SelectFormField from "components/form-fields/select-field";
import { renderJsonOptions, getYears } from "utils/select-options";
import TableWrapper from "components/common/table-wrapper";
import { numToCurrency } from "utils/helpers";
import { IOrderStatusForm, orderStatus } from "actions/so-orders";

export interface myVehiclesFormValues {
  page?: number;
  vehicles?: string[];
  name?: string;
  vehicle_type?: string;
  drive_type?: string;
  created_date_min?: string;
  created_date_max?: string;
  import_date_min?: string;
  import_date_max?: string;
  import_date?: [string, string];
  production_year_min?: string;
  production_year_max?: string;
  production_date?: [string, string];
  sort_key?: "created_at" | "import_date";
  cbk_id?: string;
}

interface IVehicleFilters {
  vehicles: string[];
  name: string;
  vehicle_type: string;
  drive_type: string;
  import_date_min: string;
  import_date_max: string;
  production_year_min: string;
  production_year_max: string;
}

const MyVehicles = (props: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const showForm = urlParams.get("show_form");
  const [carFormCollapsed, setCarFormCollapsed] = useState(
    showForm ? false : true
  );
  const [draftCarFormCollapsed, setDraftCarFOrmCollapsed] = useState(true);
  const [vehicleFormValues, setVehicleFormValues] = useState<
    IVehicleBasicFormValues
  >();

  // const tableHeight = window.innerHeight - 56

  const [sortMethod, setSortMethod] = useState<
    "add_date" | "import_date" | undefined
  >();
  const [tableData, setTableData] = useState<any>({});
  const [resetting, setResetting] = useState(false);
  const [timeoutVar, setTimeoutVar] = useState<undefined | any>(undefined);
  const timoutRef = useRef();
  const history = useHistory();

  //layout-related refs

  const tableRef = useRef<any>(null);
  const tableCurrent = tableRef.current;
  let tableMaxHeight: number | undefined = undefined;
  if (tableCurrent !== null) {
    // 138 - pagination height with bottom margin, 20 - table top margin
    tableMaxHeight = window.innerHeight - tableCurrent.offsetTop - 138 - 20;
  }

  timoutRef.current = timeoutVar;
  const session = useSelector((state: RootState) => state.user.session);
  const [filteredVehicles, execFilterVehicles]: readonly [
    IActionState<{
      data: IVehicle[];
      current_page: number;
      total_pages: number;
      total_items: number;
      product_columns: { [key: string]: string };
    }>,
    typeof filterVehicles
  ] = useAsyncAction(filterVehicles);
  const [fetchedVehicles, execFetchVehicles]: readonly [
    IActionState<{
      data: IVehicle[];
      current_page: number;
      total_pages: number;
      product_columns: { [key: string]: string };
    }>,
    typeof fetchVehicles
  ] = useAsyncAction(fetchVehicles);

  const [formDataState, execFetchVehicleFormData]: readonly [
    IActionState<{ data: IVehicleFormData }>,
    typeof fetchVehicleFormData
  ] = useAsyncAction(fetchVehicleFormData);
  const formData = formDataState.data?.data;

  const [orderStatusState, execOrderStatus]: readonly [
    IActionState<IOrderStatusForm>,
    typeof orderStatus
  ] = useAsyncAction(orderStatus);

  const toggleFilters = () => {
    setCarFormCollapsed(!carFormCollapsed);
  };

  const goToSoOrderForm = () => {
    history.push({
      pathname: PAGE.SO_ORDER_APPROVAL,
      search: `?year=${orderStatusState.data?.enabled_order_year}`,
    }
    );
  };

  const reset = () => {
    setResetting(true);
    setCarFormCollapsed(true);
  };

  const resetDraft = () => {
    setResetting(true);
    setDraftCarFOrmCollapsed(true);
  };

  useEffect(() => {
    if (resetting) {
      setCarFormCollapsed(false);
      setResetting(false);
    }
  }, [resetting]);
  useEffect(() => {
    execFetchVehicleFormData();
    execFilterVehicles(null);

    // if (window.innerWidth > 1750) {
    //   setCarFormCollapsed(true);
    // }
  }, []);

  useEffect(() => {
    execOrderStatus();
  }, [])

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    // const pager = { ...this.pagination }
    // pager.current = pagination.current
    // this.pagination = pager
  };

  const onSubmit = (values: myVehiclesFormValues) => {
    execFilterVehicles(values);
  };

  const openDraftForm = (values: IVehicleBasicFormValues) => {
    setVehicleFormValues(values);
    toggleFilters();
    setDraftCarFOrmCollapsed(false);
  };

  return (
    <div className="sider-page my-vehicles">
      <main
        className={`page-content ${!carFormCollapsed ? "sider-visible" : ""
          }`}
      >
        <Formik
          initialValues={{
            vehicles: [],

            // name: "",
            // vehicle_type: "",
            // drive_type: "",
            // import_date: ["", ""],
            // production_date: ["",""],
          }}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<myVehiclesFormValues>) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              setTableData(getTableData());
            }, [filteredVehicles.data]);

            const handleSelectChange = (e: string) => {
              clearTimeout(timoutRef.current);
              formikProps.submitForm();
            };

            const orderBy = (orderKey: "created_at" | "import_date") => {
              formikProps.setFieldValue("sort_key", orderKey);
              formikProps.submitForm();
            };

            const setPage = (page: number) => {
              formikProps.setFieldValue("page", page);
              formikProps.submitForm();
            };

            const renderColumns = () => {
              if (filteredVehicles.data && filteredVehicles.data != null) {
                let columns: any[] = [];
                Object.keys(filteredVehicles.data.product_columns).map(
                  (columnId, index) => {
                    columns.push({
                      title:
                        filteredVehicles.data &&
                        filteredVehicles.data.product_columns[columnId],
                      width: "8%",
                      align: "center",

                      render: (make: any, row: IVehicle) => {
                        return (
                          <div
                            className={`action no-wrap ${columnId === "sum" ? "green" : ""
                              }`}
                          >
                            {" "}
                            {row.products[columnId] ? (
                              numToCurrency(row.products[columnId].sum)
                            ) : (
                              <CloseIcon className="no-product" />
                            )}
                          </div>
                        );
                      },
                    });
                  }
                );
                columns.unshift(
                  {
                    title: "Pojazd",
                    dataIndex: "make",
                    width: 280,
                    align: "left",
                    fixed: "left",

                    render: (make: any, row: IVehicle) => (
                      <div>
                        <div className="vehicle-img">
                          <img src="/static/img/car-front.svg" />
                        </div>
                        <div className="vehicle-basic-info">
                          <div className="make-model">
                            {row.brand} {row.model}
                          </div>
                          <div className="year-vin">{row.production_year}</div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "Dodano",
                    align: "center",
                    width: 90,
                    render: (make: any, row: IVehicle) => (
                      <div className="action no-wrap">{row.created_at}</div>
                    ),
                  },
                  {
                    title: "Import",
                    align: "center",
                    width: 90,
                    render: (make: any, row: IVehicle) => (
                      <div className="action no-wrap">{row.import_date}</div>
                    )
                  },
                  {
                    title: "Rozmiar opon",
                    align: "center",
                    width: 115,
                    render: (make: any, row: IVehicle) => (
                      <div className="action">{row.tire_info}</div>
                    ),
                  }
                );
                return columns;
              }
            };

            const handleValChange = (
              e: React.ChangeEvent<HTMLInputElement>
            ) => {
              // formikProps.setFieldValue("search_term", e.target.value);

              if (e.target.value === "Enter") {
                e.preventDefault();
              }

              clearTimeout(timoutRef.current);

              setTimeoutVar(setTimeout(formikProps.submitForm, 500));
            };
            const getTableData = () => {
              const data = {
                filtersCollapsed: true,
                pagination: {},
                loading: false,
                rowClassName: (row: IVehicle, index: number) => {
                  return row.status === "draft" ? "disabled" : "";
                },
                columns: renderColumns(),
              };

              return data;
            };

            const { values } = formikProps;

            return (
              <>
                {filteredVehicles.loading && <Spinner size="large" />}
                <div className="breadcrumbs">
                  <Link to={PAGE.HOME}>
                    Integrator <span className="arrow">»</span>{" "}
                  </Link>
                  <span> Usługi recyklingowe</span>
                  <span className="arrow">»</span>
                  <span className="primary-text"> Moje pojazdy</span>
                </div>
                <header className="page-header">
                  <h1 className="grow-1">Moje pojazdy</h1>
                  {orderStatusState.data?.enabled_order_year &&
                    <Button
                      title={orderStatusState.data?.button_string || 'Zatwierdź'}
                      onClick={goToSoOrderForm}
                      color="primary"
                      inline={true}
                      className="f-right mr-5"
                      disabled={!orderStatusState.data?.order_button_enabled}
                    />
                  }
                  <Button
                    title="dodaj pojazd"
                    onClick={toggleFilters}
                    color="primary"
                    inline={true}
                    className="f-right"
                  />
                </header>

                <div className="list-filters mb-20">
                  <Row gutter={4}>
                    <Col md={6} xxl={4}>
                      <FormField
                        name="created_date_min"
                        type="date"
                        label="Dodano (od)"
                      />
                    </Col>
                    <Col md={6} xxl={4}>
                      <FormField
                        name="created_date_max"
                        type="date"
                        label="Dodano (do)"
                      />
                    </Col>
                    <Col md={6} xxl={4}>
                      <FormField
                        name="import_date_min"
                        type="date"
                        label="Data importu (od)"
                      />
                    </Col>
                    <Col md={6} xxl={4}>
                      <FormField
                        name="import_date_max"
                        type="date"
                        label="Data importu (do)"
                      />
                    </Col>


                  </Row>
                  <Row gutter={4} align="bottom" type="flex" >
                    <Col xxl={17}>
                      <Row gutter={4} className="mb-0">
                        <Col md={6}>
                          <TextFormField
                            data-test="name"
                            clearVisible={false}
                            placeholder="Marka pojazdu"
                            type="text"
                            name="name"
                            label="Marka pojazdu"
                          // onChange={(e) => {
                          //   formikProps.setFieldValue("name", e.target.value);
                          //   handleValChange(e);
                          // }}
                          />
                        </Col>

                        <Col md={5}>
                          <SelectFormField
                            data-test="type"
                            options={
                              formData
                                ? renderJsonOptions(formData.vehicle_types)
                                : []
                            }
                            name="vehicle_type"
                            label="Rodzaj pojazdu"
                          />
                        </Col>
                        <Col md={5}>
                          <SelectFormField
                            data-test="gear"
                            name="drive_type"
                            label="Skrzynia biegów"
                            options={
                              formData
                                ? renderJsonOptions(formData.transmission_type)
                                : []
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <SelectFormField
                            name="production_year_min"
                            options={renderJsonOptions(getYears())}
                            label="Rok produkcji (od)"
                          />
                        </Col>
                        <Col md={4}>
                          <SelectFormField
                            name="production_year_max"
                            options={renderJsonOptions(getYears())}
                            label="Rok produkcji (do)"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col xxl={7} className="t-right">

                      <Button
                        title="Filtruj"
                        inline={true}
                        width={150}
                        color="primary"
                        onClick={formikProps.submitForm}
                        className="mr-5  mb-20"
                      />

                      <Button
                        title="Wyczyść"
                        color="white"
                        className="mb-20"
                        inline={true}

                        width={150}
                        onClick={() => {
                          formikProps.resetForm();
                          execFilterVehicles(null);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
                <span className="md-t flex i-b mb-10">
                  <span className="grow-1 grey-2">
                    Znaleziono:{" "}
                    <span className="t-500 black">
                      {filteredVehicles.data?.total_items || 0}
                    </span>
                  </span>

                  <div>
                    <span className="mr-10 grey-2">Sortowanie: </span>{" "}
                    <label
                      className={
                        values.sort_key === "created_at"
                          ? "primary t-500 mr-10 hover"
                          : "black t-500 mr-10 hover"
                      }
                      onClick={() => orderBy("created_at")}
                    >
                      Ostatnio dodane
                    </label>
                    <label
                      title="Ostatnio importowane"
                      className={
                        values.sort_key === "import_date"
                          ? "primary t-500 hover"
                          : "black t-500 hover"
                      }
                      onClick={() => orderBy("import_date")}
                    >
                      Ostatnio importowane
                    </label>
                  </div>
                </span>

                {/* {filtersCollapsed && (
                  <a className="f-right" onClick={toggleFilters}>
                    {" "}
                    Filtrowanie{" "}
                  </a>
                )} */}
                {filteredVehicles.data && (
                  <div ref={tableRef}>
                    <TableWrapper
                      className="grow-1"
                      fetchData={
                        filteredVehicles.data && {
                          current_page: filteredVehicles.data?.current_page,
                          total_pages: filteredVehicles.data?.total_pages,
                        }
                      }
                      setPage={setPage}
                      addPagination={true}
                    >
                      <Table
                        scroll={{ x: 1300 }}
                        size="middle"
                        locale={{ emptyText: "Brak elementów do wyświetlenia" }}
                        rowKey="item => item.uuid"
                        columns={tableData.columns}
                        bordered={false}
                        dataSource={filteredVehicles.data.data}
                        pagination={false}
                        loading={tableData.loading}
                        rowClassName={tableData.rowClassName}
                        className={`custom-ant-table`}
                      />
                    </TableWrapper>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </main>
      {!carFormCollapsed && (
        <div className="sider fixed my-vehicles__filters">
          <CarDataForm
            mode="vin_step2"
            isLoading={false}
            toggleFilters={toggleFilters}
            openDraftForm={openDraftForm}
            execFetchVehicles={execFilterVehicles}
            reset={reset}
          />
          <CloseIcon className="sider-close" onClick={toggleFilters} />
          {/* <SideForm formDataSource={props.vehicleFormData} close={toggleFilters} /> */}
        </div>
      )}

      {!draftCarFormCollapsed && (
        <div className="sider fixed my-vehicles__filters">
          <DraftCarForm
            mode="vin_step2"
            isLoading={false}
            toggleFilters={() => setDraftCarFOrmCollapsed(true)}
            execFetchVehicles={execFilterVehicles}
            reset={resetDraft}
            copiedValues={vehicleFormValues}
          />
          <CloseIcon
            className="sider-close"
            onClick={() => setDraftCarFOrmCollapsed(true)}
          />
          {/* <SideForm formDataSource={props.vehicleFormData} close={toggleFilters} /> */}
        </div>
      )}
      {/* <style jsx global>
        {s1}
      </style> */}
    </div>
  );
};

function mapStateToProps(state: any) {
  return {
    myCars: state.vehicles ? state.vehicles : null,
  };
}

export default withAuthSync(
  withLayout(
    connect(mapStateToProps, { fetchVehicles, fetchVehicleFormData })(
      MyVehicles
    )
  )
);
