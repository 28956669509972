import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import {requestHeaders } from "utils/auth";

const WASTE_RAPORTS_URL = `${API_BASE_URL}/waste_raports`;


export const fetchWasteRaportAgreement = async () => {
  const options: AxiosRequestConfig = {
    url: `${WASTE_RAPORTS_URL}/agreement`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if(res){
    return res;
  }
};

export const fetchWasteRaports = async () => {
    const options: AxiosRequestConfig = {
      url: `${WASTE_RAPORTS_URL}`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
  };
  
