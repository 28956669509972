import { ISoOrderDeclarationForm, ISoOrderDeclarationValues, declaration, declarationForm } from "actions/so-orders";
import { Col, Input, Row } from "antd";
import TextFormField from "components/form-fields/hook-form-fields/hook-text-field";
import SimpleLayout from "components/layout/simple-layout";
import React, { useEffect, useState } from "react";
import {
  Controller,
  FormProvider,
  useForm
} from "react-hook-form";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import "../../wdyr";

import Form from "antd/lib/form";
import { default as Button, default as FormButton } from "components/buttons/form-button";
import FormButtons from 'components/common/form-buttons';
import Spinner from "components/core/spinner";
import CheckFormField from "components/form-fields/hook-form-fields/hook-check-field";
import withFormLayout from "components/layout/form-layout";
import { PAGE } from "paths";
import { useHistory } from "react-router-dom";
import { formatNumber } from "utils/helpers";

const DeclarationForm = (props: {}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");

  const history = useHistory();

  const [needCalculate, setNeedCalculate] = useState(true)
  const [extraMass, setExtraMass] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const formMethods = useForm<ISoOrderDeclarationValues>({
    mode: "onChange",
    defaultValues: {},
  });
  // 
  const { control, watch, reset, getValues } = formMethods;

  const [declarationFormState, execDeclarationForm]: readonly [
    IActionState<ISoOrderDeclarationForm>,
    typeof declarationForm
  ] = useAsyncAction(declarationForm);

  const [declarationState, execDeclaration]: readonly [
    IActionState<{ [key: string]: string }>,
    typeof declaration
  ] = useAsyncAction(declaration);

  useEffect(() => {
    initFormData()
  }, []);

  const initFormData = async () => {
    setIsLoading(true)
    let resp = await execDeclarationForm(token);
    setIsLoading(false)
  };

  const submitForm = (values: ISoOrderDeclarationValues) => {
    execDeclaration(token, values);;
  };

  useEffect(() => {
    if (declarationState.response?.status === 200) {
      history.push({
        pathname: PAGE.SO_ORDER_APPROVAL,
        search: `?token=${token}`,
      }
      );
    }

  }, [declarationState.response]);

  const calculate = async () => {
    setIsLoading(true)
    const res = await execDeclarationForm(token, formMethods.getValues());;
    setIsLoading(false)
    setNeedCalculate(false)
  };

  const toggleExtraMass = () => {
    setExtraMass(!extraMass);
    formMethods.setValue('extra_mass', {})
    setNeedCalculate(true)
  }

  const toolTipText = (item: [string, any]) => {
    if (declarationFormState.data?.tool_tips && declarationFormState.data?.tool_tips[item[0]].length !== 0) {
      return `Średnie masy odpadów dla pojazdu:\n${declarationFormState.data?.tool_tips[item[0]].join(`\n`)}`
    }
  }

  return (
    <FormProvider {...formMethods}>
      {(isLoading) && (
        <Spinner fixed={true} />
      )}
      <header className="page-header">
        <h1 className="grow-1">Zgłoszenie mas rzeczywistych za {declarationFormState.data?.year} rok </h1>
      </header>

      <div className="block">
        <p className="mb-0" >
        Znajdujesz się w formularzu zatwierdzania mas rzeczywistych. W związku z zakończeniem roku rozliczeniowego  masz obowiązek przekazania mas rzeczywistych baterii, oleju, opon która Twoja firma wprowadziła do obrotu w 2023 w celu umożliwienia Organizacji Odzysku przejęcia obowiązków Wprowadzającego, określonych odpowiednio w Ustawie o gospodarce opakowaniami i odpadami opakowaniowymi (t.j. Dz.U. z 2018 r. poz. 150 ze zmianami). Niespełnienie obowiązku przekazania mas może skutkować nieprawidłowym rozliczeniem umowy, a co za tym idzie wysokimi karami nałożonymi przez Urząd Marszałkowski.
        </p>
      </div>

      <h2 className="h-lg">Wprowadź liczbę pojazdów importowanych w {declarationFormState.data?.year} roku (Jeżeli nie importowałeś pojazdów w 2023 kliknij przycisk “Przelicz”, a następnie “Dalej”).</h2>

      <div className="block declaration-form">
        <Row gutter={[8, 8]}>
          {declarationFormState.data?.vehicle_types && Object.entries(declarationFormState.data?.vehicle_types).map((entry) => (
            <Col span={4}>
              <TextFormField
                name={`vehicles.${entry[0]}`}
                label={entry[1]}
                type="number"
                formMethods={formMethods}
                noBottomMargin={true}
                onChange={() => (setNeedCalculate(true))}
                toolTip={toolTipText(entry)}
              />
            </Col>
          ))}
          <Col span={3}>
            <div className="mt-15">
              <Button
                width={220}
                title="Przelicz"
                onClick={calculate}
                color="primary"
                className="mr-5"
                inline={true}
              />
            </div>
          </Col>
        </Row>
      </div>

      <p>Jeżeli dodatkowo sprowadzasz produkty takie jak opony, oleje, baterie lub elektronikę skontaktuj się z biurem.</p>
      {/* 
      <div className="mb-20">
        <CheckFormField
          formMethods={formMethods}
          cbxLabel="Jeżeli dodatkowo sprowadzasz produkty takie jak opony, oleje, baterie lub elektronikę uzupełnij poniższe pola."
          name="leaflet_send_address_different"
          value={true}
          isFlag={true}
          onCheckChange={toggleExtraMass}
        />
      </div>
      {extraMass &&

        <div className="block">
          <Row gutter={[32, 8]}>
            {declarationFormState.data?.extra_mass.map((mass) => (
              <Col span={3}>

                <TextFormField
                  name={`extra_mass.${mass.id}`}
                  label={mass.name}
                  type="number"
                  formMethods={formMethods}
                  noBottomMargin={true}
                  onChange={() => (setNeedCalculate(true))}
                />
              </Col>
            ))}
            <Col span={3}>
              <div className="mt-15">
                <Button
                  width={220}
                  title="Przelicz"
                  onClick={calculate}
                  color="primary"
                  className="mr-5"
                  inline={true}
                />
              </div>
            </Col>
          </Row>
        </div>
      } */}

      <h2 className="h-lg">Podsumowanie mas</h2>

      <div className="block">
        <Row gutter={[8, 8]}>
          {declarationFormState.data?.mass_sum.map((sum) => (
            <Col span={3}>
              <Form.Item label={sum.name} className="mb-0">
                <Controller
                  name={sum.name}
                  render={() => {

                    return <Input
                      disabled={true}
                      value={formatNumber(sum.sum, 1, 'kg')}
                    />
                  }}
                />
              </Form.Item>
            </Col>
          ))

          }
        </Row>
      </div>
      <FormButtons>
        <FormButton
          width={220}
          color="primary"
          onClick={formMethods.handleSubmit(submitForm)}
          title="Dalej"
          inline={true}
          disabled={needCalculate}
        />
      </FormButtons>
    </FormProvider >

  );
};

export default SimpleLayout(withFormLayout(DeclarationForm));