import React from 'react';
import Button from 'antd/lib/button'

//@ts-ignore
import 'styles/components/button.scss'

interface IProps {
  color? : string
  title : string
  className? : string
  half? : string
  disabled? : boolean
  onClick : (e: any) => void
  dataTest?: string,
  width? : number
  inline? : boolean
}


const FormButton = (props: IProps) => {
  const {color, title, half, onClick, className, disabled, dataTest, width, inline} = props;
  return <div className={`${inline ? "i-b" : ""}`}>
          <Button data-testid={dataTest} onClick={onClick} disabled={disabled} htmlType="button" style={width ? {width: width + "px" } : {} } className={`${color} button ${half ? half : ''} ${className ? className : ''}`}>
          { title }
          </Button>
        </div>

}

FormButton.propTypes = {
 
}

export default FormButton;
