import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/buttons/button";
import FormButton from "components/buttons/form-button";
import BlockIcon from "@material-ui/icons/Block";
import { IActionState, IListing } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import WarningIcon from "@material-ui/icons/Warning";
import { useHistory } from "react-router-dom";
import "styles/pages/bdo-key.scss";
import {
  fetchBDOAgreement,
  fetchBDOReports,
  createBDOReport,
  downloadBDOReportPDF,
  retryBDOReport,
} from "actions/BDO-reports";
import { IBDOReport, IBDOReportButton } from "types/BDO-report-types"; 
import { formatDateTime } from "utils/date";
import Help from "@material-ui/icons/HelpOutline";
import Spinner from "components/core/spinner";
import { renderBreadcrumbs } from "utils/helpers";
import { IDashboardProduct } from "types/dashboard-types";
import ProductsList from "components/home/services-list";
import { PAGE } from "paths";
import ServicesList from "components/home/services-list";
import { useGraphForm } from "components/bdo-year-report-form/graph-form-utils";
import { IExistingFile } from "types/translation-types";

const NetworkProvision = () => {
  const history = useHistory();
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const [PDFState, execDownloadPDF]: readonly [
    IActionState<IExistingFile>,
    typeof downloadBDOReportPDF
  ] = useAsyncAction(downloadBDOReportPDF);

  const [BDOAgreementState, execFetchBDOAgreement]: readonly [
    IActionState<IDashboardProduct & IBDOReportButton>,
    typeof fetchBDOAgreement
  ] = useAsyncAction(fetchBDOAgreement);
  const generateButton = BDOAgreementState.data?.generate_bdo_button;

  const [BDOReportsState, execFetchBDOReports]: readonly [
    IActionState<IListing<IBDOReport>>,
    typeof fetchBDOReports
  ] = useAsyncAction(fetchBDOReports);

  const [retryState, execRetry]: readonly [
    IActionState<any>,
    typeof retryBDOReport
  ] = useAsyncAction(retryBDOReport);

  const [createReportState, execCreateReport]: readonly [
    IActionState<any>,
    typeof fetchBDOReports
  ] = useAsyncAction(createBDOReport);

  const id = generateButton?.id || createReportState.data?.id;
  const { execCheckGraph, goToNext, graphState } = useGraphForm(id);

  useEffect(() => {
    execFetchBDOAgreement();
    execFetchBDOReports();
  }, [execFetchBDOAgreement, execFetchBDOReports]);

  useEffect(() => {
    
    if (generateButton?.id) {
      execCheckGraph(generateButton.id);
    }
  }, [generateButton, execCheckGraph]);

  useEffect(() => {
    if (PDFState.data) {
      window.open(PDFState.data.url, "_blank");
    }
  }, [PDFState.data]);

  const downloadPDF = (id: string) => {
    execDownloadPDF(id);
  };

  const retrySubmit = async (id: string) => {
    const res = await execRetry(id);
    if (res.status === 200) {
      history.push(`${PAGE.BDO_YEAR_REPORT_SUBMIT}?id=${id}`);
    }
  };

  const renderActions = (actions?: { label: string; url: string }[]) => {

    if(!actions){
      return <> </>
    }
    return actions.map((item) => (
      <Button color="white"  key={item.label} title={item.label} outerLink={true} link={item.url} newCard={true} inline={true} half={'ml-10'} />
       
    ));
  };

  const renderReports = () => {
    if (BDOReportsState.data) {
      return BDOReportsState.data?.data?.map((item, index) => {
        return (
          <tr>
            <td className="black t-500">{item.year}</td>
            <td className="black t-500">{item.status_string}</td>
            <td className="black t-500">{formatDateTime(item.send_date)}</td>
            <td
              className={`t-500 ${item.send_status === "ERROR" ? "red" : ""}`}
            >
              {item.send_response}
            </td>
            <td>
              {item.send_retry_button && (
                <Button
                  title="Ponów wysyłkę"
                  color="white"
                  onClick={() => retrySubmit(item.id)}
                />
              )}
            </td>

            <td className="t-500 report-actions">
              {renderActions(item.actions)} 
            </td>

          </tr>
        );
      });
    }
  };

  const goToReport = async () => {
    if (!generateButton?.id) {
      const reportRes = await execCreateReport();
      let graphRes;
      if (reportRes.status === 201) {
        graphRes = await execCheckGraph(reportRes.data?.id);
      }

      if (graphRes?.status === 200 && reportRes.status === 201) {
        goToNext({
          graph_data: graphRes.data?.graph_data,
          id: reportRes.data?.id,
        });
      }
    } else if (graphState.data) {
      goToNext({ graph_data: graphState.data?.graph_data });
    }
  };

  const ReportsList = () => {
    if (!BDOReportsState.data?.data?.length) {
      return <> </>;
    }
    return (
      <section className="mt-20">
        {" "}
        <h2 className="mb-15 black">Sprawozdania</h2>
        <table className="simple-table md-t mb-30">
          <tbody>{renderReports()}</tbody>
        </table>{" "}
      </section>
    );
  };

  return (
    <div className="sider-page network-provision">
      {(BDOAgreementState.loading ||
        graphState.loading ||
        BDOReportsState.loading ||
        createReportState.loading ||
        retryState.loading) && <Spinner size="large" />}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Usługi BDO", "Sprawozdanie BDO"])}
          {siderCollapsed && (
            <div className="help-icon">
              {" "}
              <Help onClick={() => toggleSider()} />{" "}
            </div>
          )}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Sprawozdanie BDO</h1>
        </header>
        <div className="block">
        {/* {BDOAgreementState.data &&
          BDOAgreementState.data.new_product_available &&
          BDOAgreementState.data.agreement_button && (
            <div className="bloc">
              <section>
                <h1 className="red flex">
                  <BlockIcon /> Nieaktywne
                </h1>
                <p className="md-t black">
                  Aktywuj usługę i zyskaj dostęp do zautomatyzowanego składania
                  sprawozdań w systemie BDO.
                </p>
                <ul className="mb-30 black">
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      Rozliczenie sieci, rozliczanie produktów, rozliczenie
                      kampanii edukacyjnej - wszystko w jednym miejscu
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      Automatyczne przekazanie danych - z wykorzystaniem klucza
                      API - z systemu Integrator do systemu BDO
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      Możliwość składania sprawozdania częściowego dla klientów,
                      którzy mają część usług poza Integratorem
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      Intuicyjne menu, szybki i sprawny proces od utworzenia do
                      zatwierdzenia sprawozdania
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      System powiadomień o terminach związanych ze sprawozdaniem
                    </div>
                  </li>
                </ul>
                <p className="md-t black mb-30">
                  Aby uruchomić usługę zaakceptuj warunki składania sprawozdań
                  oraz zapisz numer BDO i klucz API (BDO).
                </p>

                {BDOAgreementState.data.ws_accepted && (
                  <div className="mb-20">
                    <ServicesList products={BDOAgreementState.data.products} />
                  </div>
                )}

           
              </section>
              <ReportsList />
            </div>
          )} */}

        {BDOAgreementState.data &&
          !BDOAgreementState.data.product_available &&
          !BDOAgreementState.data.new_product_available && (
            <div className="bloc">
              <section>
                <h1 className="red flex">
                  <BlockIcon /> Nieaktywne
                </h1>
                <p className="md-t black">
                  Aktywuj usługę i zyskaj dostęp do zautomatyzowanego składania
                  sprawozdań w systemie BDO.
                </p>
                <ul className="mb-30 black">
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      Rozliczenie sieci, rozliczanie produktów, rozliczenie
                      kampanii edukacyjnej - wszystko w jednym miejscu
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      Automatyczne przekazanie danych - z wykorzystaniem klucza
                      API - z systemu Integrator do systemu BDO
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      Możliwość składania sprawozdania częściowego dla klientów,
                      którzy mają część usług poza Integratorem
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      Intuicyjne menu, szybki i sprawny proces od utworzenia do
                      zatwierdzenia sprawozdania
                    </div>
                  </li>
                  <li className="flex mb-5">
                    <CheckIcon className="green mr-5" />
                    <div className="grow-1 md-t">
                      {" "}
                      System powiadomień o terminach związanych ze sprawozdaniem
                    </div>
                  </li>
                </ul>
                <p className="md-t black mb-30">
                  Aby uruchomić usługę zaakceptuj warunki składania sprawozdań
                  oraz zapisz numer BDO i klucz API (BDO).
                </p>

                {BDOAgreementState.data.ws_accepted && (
                  <div className="mb-20">
                    <ServicesList products={BDOAgreementState.data.products} />
                  </div>
                )}

          
              
              </section>
            </div>
          )}

        {BDOAgreementState.data?.product_available &&
          !BDOAgreementState.data?.new_product_available && (
            <div className="bloc">
              <section>
                <h1 className="green flex">
                  <CheckCircle /> Aktywne
                </h1>
                <p className="md-t red bold">Uwaga! Usługa wkrótce wygasa</p>
                <p className="md-t black">
                  Dotychczasowa wersja usługi polegająca na pobieraniu druku
                  sprawozdania i ręcznym przepisywaniu danych do systemu BDO
                  będzie wycofywana z naszej oferty. Przejdź na nową wersję
                  usługi i zyskaj w pełni zautomatyzowane składanie sprawozdań
                  do systemu BDO za pomocą klucza API.
                </p>
              </section>
              <section>
                <h2 className="black mb-20">Wybierz nową usługę</h2>

                <p className="md-t black">
                  Zapraszamy do skorzystania z nowej usługi automatycznego
                  składania rocznych sprawozdań do systemu BDO. Aby uruchomić
                  usługę zaakceptuj warunki składania sprawozdać oraz zapisz
                  numer BDO i klucz API (BDO).
                </p>

                {/* {BDOAgreementState.data.agreement_button && (
                  <Button
                    color="primary"
                    link={PAGE.STEP_1}
                    width={250}
                    title="Uzyskaj dostęp do usługi"
                  />
                )} */}
                {/* <ReportsList /> */}
              </section>
            </div>
          )}
        {BDOAgreementState.data?.new_product_available &&
          // !BDOAgreementState.data?.agreement_button && (
            (
            <div className="bloc">
              <section>
                <h1 className="green flex">
                  <CheckCircle /> Aktywne
                </h1>
                <p className="md-t black">
                  Usługa rocznych sprawozdań BDO została aktywowana.
                </p>
                {/* <p className="md-t black">Usługa umożliwia w pełni zautomatyzowane składanie sprawozdań do systemu BDO za pomocą klucza API jest aktywne.</p> */}
                <ProductsList products={BDOAgreementState.data?.products} />
              </section>

              {/* <ReportsList /> */}

           
            </div>
          )}
            <ReportsList />

          <section>
            {BDOAgreementState?.data?.agreement_button &&
                  !BDOAgreementState?.data?.ws_accepted && (
                    <Button
                      inline={true}
                      color="primary"
                      className="mr-5 mb-20"
                      link={BDOAgreementState?.data?.agreement_url}
                      width={250}
                      title="Uzyskaj dostęp do usługi"
                    />
                  )}
                {BDOAgreementState?.data?.agreement_button &&
                  BDOAgreementState.data.ws_accepted && (
                    <Button
                    inline={true}
                    className="mr-5 mb-20"
                      color="primary"
                      link={BDOAgreementState.data.agreement_url}
                      width={250}
                      title="Kontynuuj"
                    />
                  )}
                {!generateButton?.id && generateButton?.show && (
                  <FormButton
                    inline={true}
                    className="mr-5 mb-20"
                    color="primary"
                    onClick={() => goToReport()}
                    disabled={!BDOAgreementState.data || !graphState}
                    width={250}
                    title="Generuj sprawozdanie"
                    
                  />
                )}
                {generateButton?.id && generateButton?.show && (
                  <FormButton
                  inline={true}
                  className="mr-5 mb-20"
                    color="primary"
                    onClick={() => goToReport()}
                    // disabled={true}
                    width={250}
                    title="Kontynuuj generowanie sprawozdania"
                    
                  />
                )}

                {BDOAgreementState?.data?.show_notification && (
                  <p className="red flex">
                    <WarningIcon className="mr-5" /> Złożenie sprawozdania
                    będzie dostępne po zamknięciu roku rozliczeniowego.
                  </p>
                )}
              </section>
              </div>
      </main>
      {!siderCollapsed && (
        <div
          className="sider"
          // collapsedWidth="0"
        >
          <div className="sm-l-t mb-20">
            Sprawozdanie BDO
            <CloseIcon className="sider-close" onClick={toggleSider} />
          </div>
          <h2 className="mb-15 black">Czym są sprawozdania BDO?</h2>
          <p className="black">
            Do składania rocznych sprawozdań o produktach, opakowaniach i o
            gospodarowaniu odpadami z nich powstającymi oraz rocznych sprawozdań
            o wytworzonych odpadach i o gospodarowaniu odpadami zobowiązane są
            podmioty, o których mowa w art. 73, art. 74a, i art. 75 ustawy z
            dnia 14 grudnia 2012 r. o odpadach.
          </p>
          <h2 className="mb-15 black">Dlaczego warto?</h2>

          <p className="md-t black">
            Aktywuj usługę i zyskaj w pełni zautomatyzowane składanie sprawozdań
            do systemy BDO za pomocą klucza API.
          </p>
          <ul className="mb-30 black">
            <li className="flex mb-5">
              <CheckIcon className="green mr-5" />
              <div className="grow-1 md-t">
                Automatyczne przekazanie danych z systemu Integrator do systemu
                BDO
              </div>
            </li>
            <li className="flex mb-5">
              <CheckIcon className="green mr-5" />
              <div className="grow-1 md-t">
                Możliwość składania sprawozdania częściowego dla klientów,
                którzy mają część usług poza Integratorem
              </div>
            </li>
            <li className="flex mb-5">
              <CheckIcon className="green mr-5" />
              <div className="grow-1 md-t">
                Intuicyjne menu, szybki i sprawny proces od utworzenia do
                zatwierdzenia sprawozdania
              </div>
            </li>
            <li className="flex mb-5">
              <CheckIcon className="green mr-5" />
              <div className="grow-1 md-t">
                System powiadomień o terminach związanych ze sprawozdaniem
              </div>
            </li>
          </ul>

          {/* {(BDOAgreementState.data && (BDOAgreementState.data?.product_available && !BDOAgreementState.data?.new_product_available)) && <>
            <p className="md-t black">W nowej wersji usługi oferujemy więcej:</p>
            <ul className="mb-30 black">
              <li className="flex mb-5">
                <CheckIcon className="green mr-5" /><div className="grow-1 md-t">Rozliczenie sieci, rozliczanie produktów, rozliczenie kampanii edukacyjnej - wszystko w jednym miejscu</div>
              </li>
              <li className="flex mb-5">
                <CheckIcon className="green mr-5" /><div className="grow-1 md-t"> Automatyczne przekazanie danych z systemu Integrator do systemu BDO</div>
              </li>
              <li className="flex mb-5">
                <CheckIcon className="green mr-5" /><div className="grow-1 md-t"> Możliwość składania sprawozdania częściowego dla klientów, którzy mają część usług poza Integratorem</div>
              </li>
              <li className="flex mb-5">
                <CheckIcon className="green mr-5" /><div className="grow-1 md-t"> Intuicyjne menu, szybki i sprawny proces od utworzenia do zatwierdzenia sprawozdania</div>
              </li>
              <li className="flex mb-5">
                <CheckIcon className="green mr-5" /><div className="grow-1 md-t">  System powiadomień o terminach związanych ze sprawozdaniem</div>
              </li>
            </ul>
          </>} */}
        </div>
      )}
    </div>
  );
};
export default withAuthSync(withLayout(NetworkProvision));



