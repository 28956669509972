import React from "react";
import { Field, FormikProps } from "formik";
import CheckFormField from "./check-form-field";
import { BooleanSchema } from "yup";

//@ts-ignore
import s1 from "styles/components/input.scss";
import Form from "antd/lib/form";
import Radio from "antd/lib/radio";
import { LegalFormType } from "types/insurance-types";
import { getNested } from "utils/helpers";
export interface IRadioProps<T> {
  name: string;
  value: T | boolean;
  label: string | JSX.Element;
  checked?: boolean;
  
}


export interface IRadioGroupProps<T> {
  name: string;
  label: string | JSX.Element
  orientation: "horizontal" | "vertical";
  testId?: string;
  options: Array<Omit<IRadioProps<T>, "name">>;
  type?: "radio";
  required? : boolean;
  onChange? : any
}

const CheckFormFields = (props: IRadioGroupProps<any>) => {
  const { options, name, orientation, label, required, onChange } = props;
  const result = options.map((item, index) => {
 
    return <Radio key={'radio_' + index} checked={item.checked} value={item.value}>{item.label}</Radio>;
  });

  return (
    <Form.Item required={required} label={props.label}>
      <Field name={props.name}>
        {({ field, form }: { field: any; form: FormikProps<any> }) => {

          const touched = getNested(form.touched, name);
          const error = getNested(form.errors, name);
          return (
            <>
            <Radio.Group
              onChange={(e) => {
                form.setFieldValue(props.name, e.target.value);
                if(onChange){
                  onChange(e.target.value);
                }
              }}
              className={props.orientation}
              name={props.name}
              value={field.value}
            >
              {result}
              {(touched && error ) &&
                 <div className="absolute-msg"> <span data-testid="text-field-error" className="input-error">{error} </span> </div>
                }
            </Radio.Group>
        
            </>
          );
        }}
      </Field>
    </Form.Item>
  );
};

export default CheckFormFields;
