import React, { useEffect, useState } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import HookTextField from "components/form-fields/hook-form-fields/hook-text-field";
import Table, { ColumnProps } from "antd/lib/table";
import TableWrapper from "components/common/table-wrapper";
import { IActionState } from "types/async-types";
import { IBDOKey, BDOKeysListing, IBDOKeyItem } from "types/BDO-keys-types";
import { fetchBDOKeys, checkBDOKey } from "actions/BDO-key";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import Pagination from "@material-ui/lab/Pagination";
import Spinner from "components/core/spinner";
import CloseIcon from "@material-ui/icons/Close";
import { useAsyncAction } from "utils/async-action";
import { IOOSummaryItem } from "types/recovery-organization-types";
import Button from "components/buttons/button";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { useForm } from "react-hook-form";
import { fetchOSOP1Reports, fetchOSOP1Report } from "actions/BDO-reports";
import FileDropzone from "components/admin/file-dropzone-simple";
import {
  IOSOP1Report,
  OSOP1ReportListing,
  IOSOP1,
} from "types/BDO-report-types";
import { useParams } from "react-router-dom";
import { IExistingFile } from "types/translation-types";
import { PAGE } from "paths";
import { formatOverdue } from "utils/helpers";
import { formatDateTime } from "utils/date";
import { fetchAdminOSOP1 } from "actions/admin";
// import { Pagination } from 'antd';

const BDOKeys = () => {
  const { id }: { id: string } = useParams();
  const [newFiles, setNewFiles] = useState<Array<File>>([]);
  const [existingFiles, setExistingFiles] = useState<IExistingFile[]>([]);

  const [existingFileIdsToDelete, setExistingFileIdsToDelete] = useState<
    number[]
  >([]);

  const [OSOP1ReportState, execFetchOSOP1Report]: readonly [
    IActionState<IOSOP1>,
    typeof fetchAdminOSOP1
  ] = useAsyncAction(fetchAdminOSOP1);

  useEffect(() => {
    if (id) {
      execFetchOSOP1Report(id);
    }
  }, [id]);

  useEffect(() => {
    if (OSOP1ReportState.data) {
      formMethods.reset(OSOP1ReportState.data);
    }
  }, [OSOP1ReportState.data]);

  useEffect(() => {
    if (OSOP1ReportState.data) {
      if (OSOP1ReportState.data.notice_files) {
        setExistingFiles(OSOP1ReportState.data.notice_files);
      }
    }
  }, [OSOP1ReportState.data]);

  const formMethods = useForm<IOSOP1>({
    mode: "onChange",
  });

  const renderYearFields = () => {
    const date = new Date();
    const result = [];

    const firstYear = date.getFullYear() - 8;

    for (let i = 1; i <= 7; i++) {
      result.push(
        <Col md={3}>
          <HookTextField
            disabled={true}
            formMethods={formMethods}
            label={`w ${firstYear + i} roku`}
            name={`year${i}`}
            type="number"
          />
        </Col>
      );
    }

    return <Row gutter={10}>{result}</Row>;
  };

  const renderReports = () => {
    if (OSOP1ReportState.data) {
      const result = OSOP1ReportState.data.payments.map((item, index) => {
        return (
          <tr>
            <td>{item.payment_type_string || "-"}</td>
            <td className="black">{item.status_string}</td>
            <td className="black">
              {formatOverdue(item.due_date, item.is_overdue)}{" "}
            </td>
            <td className="black">{formatDateTime(item.payment_date)} </td>
          </tr>
        );
      });
      return result;
    }
  };

  const data = OSOP1ReportState.data;

  const reports = renderReports();
  return (
    <div className="sider-page">
      {OSOP1ReportState.loading && <Spinner size="large" />}
      <main className="page-content">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span>
          <a href="#"> Lista wniosków OŚ/OP1</a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">Lista wniosków OŚ/OP1</h1>
        </header>
        <div className="block  mb-10">
          <div className="mb-5">
            <div className="black i-b mr-5">Data utworzenia: </div>
            <div className="i-b black t-500 ">{data?.created_at}</div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Utworzony przez: </div>
            <div className="i-b black t-500">
              {data?.name ? data?.name + " " + data?.surname : "Brak danych"}
            </div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Email: </div>
            <div className="i-b black t-500">
              {data?.email || "Brak danych"}
            </div>
          </div>
        </div>
        <div className="block mb-5">
          <h2 className="primary h-lg mb-20">Dane podmiotu</h2>
          <div className="mb-5">
            <div className="black i-b mr-5">CBK ID: </div>
            <div className="i-b black t-500 ">
              {data?.cbk_id || "Brak danych"}
            </div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Nazwa firmy:</div>
            <div className="i-b black t-500">
              {data?.full_company_name || "Brak danych"}
            </div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">NIP:</div>
            <div className="i-b black t-500">{data?.nip || "Brak danych"}</div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">REGON:</div>
            <div className="i-b black t-500">
              {data?.regon || "Brak danych"}
            </div>
          </div>
          {/* <div className="mb-10">
            <div className="black i-b mr-5">NIP EUROPEJSKI </div>
            <div className="i-b black t-500">{data?.nip}</div>
          </div> */}
          <div className="mb-5">
            <div className="black i-b mr-5">Adres:</div>
            <div className="i-b black t-500">
              {data?.address.address_string}
            </div>
          </div>
          <div className="mb-5">
            <div className="black i-b mr-5">Adresat sprawozdania:</div>
            <div className="i-b black t-500">
              {data?.voivodship_marshal || "Brak danych"}
            </div>
          </div>
        </div>
        <div className="block mb-5">
          <h2 className="primary h-lg mb-20">Ilość pojazdów</h2>
          {renderYearFields()}
        </div>
        <div className="block mb-10">
          <h2 className="primary h-lg mb-20">Załączniki</h2>
          <FileDropzone
            readOnly={true}
            formMethods={formMethods}
            files={newFiles}
            setFiles={setNewFiles}
            existingFiles={existingFiles}
          />
        </div>
        <div className="block mb-10">
          <h2 className="primary h-lg mb-20">Zgody</h2>
          {data?.is_terms_accepted && (
            <p className="md-t">
              Zapoznałem się z zasadami ochrony danych osobowych oraz z
              regulaminem serwisu na stronie www.integrator.org.pl: Polityka
              Prywatności, Regulamin.{" "}
            </p>
          )}
          {data?.is_rodo_accepted && (
            <p className="md-t">
              {" "}
              Wyrażam zgodę na przetwarzanie moich danych osobowych w podanym
              wyżej zakresie przez Administratora Danych w celu wykonania usługi
              przez Integrator sp. z o.o. Informujemy, że Państwa zgoda może
              zostać cofnięta w dowolnym momencie przez wysłanie wiadomości
              e-mail na adres naszej firmy spod adresu, którego zgoda dotyczy.{" "}
            </p>
          )}
          {!data?.is_terms_accepted && !data?.is_rodo_accepted && (
            <p className="md-t">Brak danych</p>
          )}
        </div>
        <div className="block mb-10">
          <h2 className="primary h-lg mb-20">Płatność</h2>
          {reports && reports.length > 0 ? (
            <div style={{ maxWidth: "550px" }}>
              <table className="simple-table mb-10 md-t w-100">
                <thead>
                  <tr>
                    <th>Rodzaj płatności</th>
                    <th className="t-left">Status</th>
                    <th className="t-left">Termin płatności</th>
                    <th className="t-left">Data płatności</th>
                  </tr>
                </thead>

                <tbody>{reports}</tbody>
              </table>
            </div>
          ) : (
            <div>Brak płatności</div>
          )}

          {/* <div className="mb-10">
            <div className="black i-b mr-5">Metoda:</div>
            <div className="i-b black t-500">{data?.payment.}</div>
          </div> */}
          {/* <div className="mb-10">
            <div className="black i-b mr-5">Status:</div>
            <div className="i-b black t-500">{data?.payment?.status}</div>
          </div>
          <div className="mb-10">
            <div className="black i-b mr-5">Data płatności:</div>
            <div className="i-b black t-500">{data?.payment?.payment_date}</div>
          </div> */}
        </div>
        <div className="block">
          <Button
            inline={true}
            color="primary"
            title="Powrót do listy wniosków"
            link={PAGE.ADMIN_OS_OP1_LIST}
          />
        </div>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(BDOKeys));
