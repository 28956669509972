import React from "react";
import withLayout from "../components/layout/MyLayout";
import CustomerDashboard from "components/home/customer-dashboard";
import AdminDashboard from "components/home/admin-dashboard";
import { withAuthSync } from "utils/auth";
import { IUser } from "types/user-types";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

const Index = () => {
  const user = useSelector((state: RootState) => state.user);

  return (
    <main className="homepage page-content">
      {user.session?.is_admin ? <AdminDashboard /> : <CustomerDashboard />}
    </main>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(Index));
// export default Index;
