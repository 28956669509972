import React, { useState, useRef, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import OOOrderForm from "../../components/oo-order/oo-order-form";
import CloseIcon from "@material-ui/icons/Close";
import CheckFormField from "components/form-fields/check-form-field";
import Button from "components/buttons/button";
import {
  IOOOrderItem,
  IOOOrderFormValues,
  IListFormValues,
  OOOrdersList,
} from "types/recovery-organization-types";
import Table from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import { IActionState } from "types/async-types";
import { submitOOOrderSearch, submitOOOrderList } from "actions/oo-orders";
import { useAsyncAction } from "utils/async-action";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import { useForm } from "react-hook-form";
import FormButton from "components/buttons/form-button";
import TableWrapper from "components/common/table-wrapper";
import "components/oo-order/oo-order-form.scss";

const OOOrders = () => {
  const formRef = useRef<FormikProps<any>>(null);

  const [selectedState, execSubmitSelected]: readonly [
    IActionState<any>,
    typeof submitOOOrderList
  ] = useAsyncAction(submitOOOrderList);

  const [filterResultState, execFilterResult]: readonly [
    IActionState<OOOrdersList>,
    typeof submitOOOrderSearch
  ] = useAsyncAction(submitOOOrderSearch);

  const onFiltersFormSubmit = (values: IOOOrderFormValues) => {
    execFilterResult(values);
  };

  const onListSubmit = (values: IListFormValues) => {
    execSubmitSelected(values);
  };

  useEffect(() => {
    execFilterResult({ page: 1 });
  }, []);

  const formMethods = useForm<IOOOrderFormValues>({
    mode: "onChange",
    defaultValues: {
      oil_correction: 100,
      oil_correction_num: 100,
    },
  });

  const selectAll = () => {
    let ids: Array<string> = [];
    if (filterResultState.data?.list) {
      filterResultState.data.list.forEach((item, index: number) => {
        ids[index] = item.id;
      });
    }

    formRef.current?.setFieldValue("selected_ids", ids);
    formRef.current?.setFieldValue("select_all", "Y");
  };

  const setPage = (page: number) => {
    const values = {
      ...formMethods.getValues(),
      page,
    };
    execFilterResult(values);
  };

  const tableData = {
    filtersCollapsed: true,
    // pagination: false,
    loading: false,
    showHeader: false,

    title: () => (
      <div className="table_title">
        <a onClick={selectAll}>Zaznacz wszystko</a>
      </div>
    ),
    columns: [
      {
        width: 40,
        render: (field: any, row: IOOOrderItem) => (
          <div className="checkbox">
            <CheckFormField
              name={`selected_items`}
              id={`selected_items_${row.id}`}
              value={`${field.id}`}
              keyValues={true}
            />
          </div>
        ),
      },
      {
        width: 150,
        title: "CBK_ID",

        render: (field: any, row: IOOOrderItem) => row.id,
      },
      {
        title: "Nazwa klienta",
        render: (field: any, row: IOOOrderItem) => row.client_name,
      },
      {
        title: "Numer umowy",
        render: (field: any, row: IOOOrderItem) => row.agreement_number,
      },
      {
        title: "Produkt",
        render: (field: any, row: IOOOrderItem) => row.product,
      },
      {
        title: "Ilość pojazdów",
        width: 100,
        render: (field: any, row: IOOOrderItem) => row.vehicles_number,
      },
      {
        title: "Masa sprzętu",
        render: (field: any, row: IOOOrderItem) => row.mass,
      },
    ],
  };

  return (
    <div className="sider-page">
      <main className="page-content">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span>
          <a href="#">  Zamówienia w organizacjach odzysku / Deklaracje-zamówienia</a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">
            Zamówienia w organizacjach odzysku / Deklaracje-zamówienia
          </h1>
        </header>
        <div className="list-filters">
          <Row gutter={15}>
            <Col md={8}>
              <TextField
                name="year"
                formMethods={formMethods}
                label="Rok rozliczenia"
              />
            </Col>
            <Col md={8}>
              <SelectField
                options={[]}
                name="agreement_id"
                formMethods={formMethods}
                label="Rodzaj umowy"
              />
            </Col>
            <Col md={8}>
              <TextField
                name="create_date"
                formMethods={formMethods}
                label="Data dodania"
                type="date"
                maxDate={new Date()}
              />
            </Col>
          </Row>
          <Row gutter={15} className="mb-10">
            <Col md={12}>
              <TextField
                name="cbk_list"
                formMethods={formMethods}
                label="ograniczenie listy wg. CBK_ID"
                type="textarea"
              />
            </Col>
            <Col md={12}>
              <div className="ant-form-item-label mb-5">
                <label>Korekta wagi oleju</label>
              </div>

              <div className="oil-range mb-10">
                <div className="grow-1">
                  <div className="oil-values-markers flex">
                    <div> 0% </div>
                    <div className="grow-1 t-center"> 100% </div>
                    <div> 200% </div>
                  </div>
                  <input
                    type="range"
                    min={0}
                    max={200}
                    step={1}
                    {...formMethods.register("oil_correction", {
                      required: true,
                    })}
                    onChange={(e) => {
                      formMethods.setValue(
                        "oil_correction_num",
                        parseInt(e.target.value)
                      );
                    }}
                  />
                </div>
                <div className="oil-preview">
                  <TextField
                    name="oil_correction_num"
                    formMethods={formMethods}
                    label=""
                    min={0}
                    max={200}
                    type="number"
                    suffix="%"
                    onChange={(e) => {
                      
                      formMethods.setValue("oil_correction", parseInt(e));
                    }}
                  />
                  {/* {oilCorrectionValue} % */}
                </div>
              </div>
              <FormButton
                color="primary"
                width={200}
                className="f-right"
                onClick={formMethods.handleSubmit(onFiltersFormSubmit)}
                title="Wyszukaj"
                // disabled={!isValid}
              />
            </Col>
          </Row>
        </div>
        <Formik
          innerRef={formRef}
          onSubmit={onListSubmit}
          initialValues={{ selected_ids: [] }}
        >
          {(formikProps: FormikProps<any>) => {
            

            return (
              <>
                <TableWrapper
                  className="grow-1"
                  fetchData={
                    filterResultState.data && {
                      current_page: filterResultState.data?.current_page,
                      total_pages: filterResultState.data?.total_pages,
                    }
                  }
                  setPage={setPage}
                  addPagination={true}
                >
                  <Table
                    size="middle"
                    locale={{ emptyText: "Brak rekordów" }}
                    rowKey="uuid"
                    columns={tableData.columns}
                    bordered={false}
                    dataSource={filterResultState.data?.list}
                    pagination={false}
                    loading={tableData.loading}
                    title={tableData.title}
                    className="custom-ant-table"
                  />
                </TableWrapper>

                <div className="t-right">
                  <FormButton
                    color="primary"
                    width={200}
                    className="i-b"
                    onClick={formikProps.handleSubmit}
                    title="Wyślij zaznaczone"
                    // disabled={!isValid}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(OOOrders));
