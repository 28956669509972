import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import {requestHeaders } from "utils/auth";
import { IOOOrderFormValues, IOOSummaryFormValue, IOOSuborderFormValues, IListFormValues, IOOOrderZeroFormValues } from "types/recovery-organization-types";

const ADMIN_URL = `${API_BASE_URL}/admin`

export const submitOOOrderZero = async (values : IOOOrderZeroFormValues) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/search_orders`,
      headers: requestHeaders().headers,
      method: "POST",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

export const submitOOOrderSearch = async (values : IOOOrderFormValues) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/search_orders`,
      headers: requestHeaders().headers,
      params : {
          page : values.page || "1"
      },
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const submitOOOrderList = async (values : IListFormValues) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/search_orders`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const filterOOSummary = async (values : IOOSummaryFormValue) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/filter_summary`,
      headers: requestHeaders().headers,
      method: "GET",
      params : {
        page : values.page || "1"
    },
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const filterOOSuborders = async (values : IOOSuborderFormValues) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/filter_summary`,
      headers: requestHeaders().headers,
      method: "POST",
      params : {
        page : values.page || "1"
    },
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };

  export const submitSubordersList = async (values : IListFormValues) => {
    const options: AxiosRequestConfig = {
      url: `${ADMIN_URL}/filter_summary`,
      headers: requestHeaders().headers,
      method: "POST",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };
  