import React, { useEffect, useState } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import SimpleLayout from "components/layout/simple-layout";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import Button from "components/buttons/button";
import "styles/components/bdo-report/bdo-report-form.scss";
import { Router, Switch, Route, useHistory, Link } from "react-router-dom";
import BDOReportPopup from "components/bdo-report/bdo-report-popup";
import { PAGE } from "paths";
import Step2 from "components/bdo-report-form/step-2";
import Step3 from "components/bdo-report-form/step-3";
import StepWS from "components/bdo-report-form/step-ws";
import { IActionState } from "types/async-types";
import { fetchBDOWSFormData } from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";
import ReactHtmlParser from "react-html-parser";
import Spinner from "components/core/spinner";
import { IBDOWSFormData } from "types/BDO-report-types";
import { disablePopup } from "utils/helpers";
import ReactGA from "react-ga";

const BDOReportForm = (props: any) => {
  const history = useHistory();
  const [BDOkeyCreated, setBDOKeyCreated] = useState(false);
  const [BDONumberCreated, setBDONumberCreated] = useState(false);
  const [BDOWSFormDataState, execFethBDOWSFormData]: readonly [
    IActionState<{ data: IBDOWSFormData }>,
    typeof fetchBDOWSFormData
  ] = useAsyncAction(fetchBDOWSFormData);

  const WSData = BDOWSFormDataState.data?.data;

  const redirectWithPopupDisable = () => {
    ReactGA.initialize("UA-212968889-1");

    let step = "brak danych";
    switch (window.location.pathname) {
      case PAGE.STEP_1:
        step = "1";
        break;
      case PAGE.STEP_2:
        step = "2";
        break;
      case PAGE.STEP_3:
        step = "3";
        break;
    }

    // Send a custom event
    ReactGA.event({
      category: "Zamknięcia WS",
      action: `Zamknięcie WS na kroku: ${step}`,
    });

    disablePopup();
    history.push(PAGE.HOME);
  };

  useEffect(() => {
    // execFethBDOWSFormData();
    // if(formRef){
    //   setTimeout(() => formRef.validateForm())
    // }
  }, []);

  let currentStep = 1;
  switch (history.location.pathname) {
    case PAGE.STEP_1:
      currentStep = 1;
      break;
    case PAGE.STEP_3:
      currentStep = 3;
      break;
    default:
      break;
  }
  // useEffect(() => {

  // }, [history.location]);
  // if (BDOWSFormDataState.loading) {
  //   return <Spinner size="large" />;
  // }
  return (
    <div className="bdo-report-form">
      <div className="form-content">
        <h1 className="primary">Sprawozdanie roczne BDO</h1>
        <div className="close-page">
          <CloseIcon
            onClick={redirectWithPopupDisable}
            className="close-button"
            id="ws-close"
          />
        </div>
        <div className="steps-nav">
          <ul className="request-order">
            <li className={`${currentStep === 1 && "active"}`}>
              <div>
                <label>Warunki szczególne usługi </label>
              </div>
            </li>
            <li className={`${currentStep === 3 && "active"}`}>
              <div>
                <label>Konfiguracja klucza API </label>
              </div>
            </li>
            <li className={`${currentStep === 4 && "active"}`}>
              <div>
                <label>Sprawozdanie roczne</label>
              </div>
            </li>
          </ul>
        </div>

        <Route
          path={PAGE.STEP_1}
          component={() => <StepWS formData={WSData} />}
        />
        <Route
          path={PAGE.STEP_3}
          component={() => <Step3 formData={WSData} />}
        />
      </div>
    </div>
  );
};

export default SimpleLayout(BDOReportForm);
