import {
  SET_REQUEST_ERROR
} from 'types/redux-types'
import _ from 'lodash'

export default function (state = {}, action: any) {

  switch (action.type) {
        case SET_REQUEST_ERROR:
          return {...state, error_code: action.payload.error_code}
      default:
        return state;
      }
}
