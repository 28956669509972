import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import TextField from "components/form-fields/formik-text-field";
import { Formik, FormikProps } from "formik";
import { Row, Col, Form } from "antd";
import "styles/pages/bdo-key.scss";
import { addressFields, FIELD } from "config/form-config/address-config";
import SelectField from "components/form-fields/select-field";
import Button from "antd/lib/button";
import Upload, { UploadChangeParam } from "antd/lib/upload";
import { UploadFile, RcFile } from "antd/lib/upload/interface";
import Icon from "antd/lib/icon";
import UploadOutline from "@ant-design/icons/lib/outline/UploadOutline";
import { zipCodeRegExp, yupRequired } from "validators";
import _ from "lodash";
import { checkPostalCode } from "actions/addresses";
import AddressSection from "components/common/address-section";
import { useAsyncAction } from "utils/async-action";
import CheckFormField from "components/form-fields/check-form-field";
import FormButton from "components/buttons/form-button";
import * as Yup from "yup";
import { IActionState } from "types/async-types";
import { IVehicle } from "types/vehicle-types";
import { fetchOSOP1FormData, submitOSOP1, fetchOSOP1Price } from "actions/BDO-reports";
// import { IOSOP1FormValues } from "types/BDO-report-types";
import { renderJsonOptions } from "utils/select-options";
import Spinner from "components/core/spinner";
import { userFields } from "config/user-fields";
import {useHistory} from 'react-router-dom';
import {PAGE} from 'paths'
import { IOSOP1FormData } from "types/BDO-report-types";

 interface IOSOP1FormValues {
  nip: string;
  regon: string;
  full_company_name: string;
  bdo_number: string;
  voivodship_marshal: string;
  voivodeship: string;
  city: string;
  aup_type: string;
  street: string;
  building_number: string;
  locum_number: string;
  postal_code: string;
  post_office: string;
  raport_due_date: string;
  notice_files: File[];  
  year1?: number;
  year2?: number;
  year3?: number;
  year4?: number;
  year5?: number;
  year6?: number;
  year7?: number;
  additional_information: string;

  //agreements
  name: string;
  surname: string;
  email: string;
  phone_number: string;
  is_terms_accepted: boolean;
  is_rodo_accepted: boolean;
}

const initialVals: IOSOP1FormValues = {
  nip: "",
  regon: "",
  full_company_name: "",
  name: "",
  surname: "",
  bdo_number: "",
  voivodship_marshal: "",

  voivodeship: "",
  city: "",
  aup_type: "",
  street: "",
  building_number: "",
  locum_number: "",
  postal_code: "",
  post_office: "",

  email: "",
  phone_number: "",
  raport_due_date: "",
  notice_files: [],
  year1: undefined,
  year2: undefined,
  year3: undefined,
  year4: undefined,
  year5: undefined,
  year6: undefined,
  year7: undefined,
  additional_information: "",
  is_terms_accepted: false,
  is_rodo_accepted: false,
};

const termsField = {
  name: "is_terms_accepted",
  required: true,
  cbxLabel:
    "Zapoznałem się z zasadami ochrony danych osobowych oraz z regulaminem serwisu na stronie www.integrator.org.pl: Polityka Prywatności, Regulamin.",
  value: true,
  isFlag: true,
};
const rodoField = {
  name: "is_rodo_accepted",
  value: true,
  isFlag: true,
  required: true,
  cbxLabel:
    "Wyrażam zgodę na przetwarzanie moich danych osobowych w podanym wyżej zakresie przez Administratora Danych w celu wykonania usługi przez Integrator sp. z o.o. Informujemy, że Państwa zgoda może zostać cofnięta w dowolnym momencie przez wysłanie wiadomości e-mail na adres naszej firmy spod adresu, którego zgoda dotyczy.",
};

const validationSchema = Yup.object().shape({
  nip: Yup.string().concat(yupRequired),
  regon: Yup.string().concat(yupRequired),
  full_company_name: Yup.string().concat(yupRequired),
  voivodeship: Yup.string().concat(yupRequired),
  voivodship_marshal: Yup.string().concat(yupRequired),
  city: Yup.string().concat(yupRequired),
  building_number: Yup.string().concat(yupRequired),
  locum_number: Yup.string().concat(yupRequired),
  postal_code: Yup.string().concat(yupRequired),
  post_office: Yup.string().concat(yupRequired),
  is_rodo_accepted: Yup.boolean().oneOf([true], "Pole jest wymagane"),
  is_terms_accepted: Yup.boolean().oneOf([true], "Pole jest wymagane"),
 
  name: Yup.string().concat(yupRequired),
  surname: Yup.string().concat(yupRequired),
  email: Yup.string().concat(yupRequired).nullable(true),
  phone_number: Yup.string().concat(yupRequired),

  

  // aup_type: "",
  // street: "",
});

const NetworkProvision = () => { 
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<
    IOSOP1FormValues | undefined
  >();
  const [formDataState, execFetchFormData]: readonly [
    IActionState<IOSOP1FormData>,
    typeof fetchOSOP1FormData
  ] = useAsyncAction(fetchOSOP1FormData);

  const [submitFormState, execSubmitForm]: readonly [
    IActionState<any>,
    typeof submitOSOP1
  ] = useAsyncAction(submitOSOP1);

  const [priceState, execFetchPrice]: readonly [
    IActionState<{ price: {netto: string, brutto : string }}>,
    typeof fetchOSOP1Price
  ] = useAsyncAction(fetchOSOP1Price); 

  useEffect(() => {
    execFetchFormData();
    // execFetchPrice(); 

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (formDataState.data) {
  //     setInitialValues(formDataState.data.os_op1_report);
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [formDataState.data]);

  const submitForm =  async (values: IOSOP1FormValues) => {
    // if (formDataState.data) {
    //   const submitValues: IOSOP1FormValues = {
    //     ...values,
    //     voivodship_marshal:
    //       formDataState.data?.voivodeship_with_marhals[
    //         values.voivodship_marshal
    //       ],
    //   };
    // const res = await  execSubmitForm(submitValues);
    // if(res.status == 201){
    //   history.push(PAGE.OS_OP1)
    // }
    // }
  };

  return (
    <div className="sider-page os-op1">
      {(formDataState.loading || submitFormState.loading || priceState.loading) && <Spinner />}
      <main className="page-content">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span> Usługi BDO{" "}
          <span className="arrow">»</span>
          <a href="#"> Sprawozdanie OŚ-OP1 </a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">Sprawozdanie OŚ-OP1</h1>
        </header>
      
        <div className="block form">




          <h1 className="mb-15">Wniosek</h1>
          <div className="md-t mb-20">Proszę poprawnie wypełnić formularz</div>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues || initialVals}
            onSubmit={submitForm}
            isInitialValid={false}
            enableReinitialize={true}
          >
            {(formikprops: FormikProps<IOSOP1FormValues>) => {
              const { setFieldValue, setFieldTouched } = formikprops;
              

              const onFileUpload = (file: RcFile) => {
                formikprops.setFieldValue("notice_files", file);
                return false;
              };
              return (
                <>
                  <section>
                    <TextField
                      label="NIP firmy"
                      name="tax_number"
                      required={true}
                    />
                    <TextField label="REGON" name="regon" required={true} />
                    <TextField
                      label="Pełna nazwa firmy"
                      name="company_name"
                      required={true}
                    />
                    <TextField label="Numer rejestrowy BDO" name="bdo_number" />
                  </section>

                  <h3 className="primary mb-15">Adres siedziby firmy</h3>
                  <section>
                    <AddressSection />
                  </section>

                  <h3 className="primary mb-15">Adresat sprawozdania</h3>
                  <section>
                    <SelectField
                      name="voivodship_marshal"
                      label="Marszałek Województwa"
                      required={true}
                      options={
                        formDataState.data
                          ? renderJsonOptions(
                              formDataState.data?.voivodeship_with_marhals
                            )
                          : []
                      }
                    />
                  </section>

                  <h3 className="primary">
                    UWAGA!! Jeśli otrzymałeś z Urzędu wezwanie do rozliczenia za
                    poprzednie lata podaj termin rozliczenia i wgraj skan
                    wezwania
                  </h3>
                  <section>
                    <Row gutter={10}>
                      <Col md={8}>
                        <TextField
                          label="Podaj termin do którego musisz złożyć OŚ-OP1"
                          name="raport_due_date"
                          type="date"
                        />
                      </Col>
                      <Col md={16}>
                        <Form.Item
                          className="file-upload-item"
                          label="Wgraj plik"
                        >
                          <Upload
                            name="notice_files"
                            beforeUpload={(e) => onFileUpload(e)}
                          >
                            <Button>
                              <Icon type="upload" />
                              Wybierz plik z dysku
                            </Button>
                          </Upload>
                        </Form.Item>

                        {/* <TextField type="file" label="Wgraj plik" name="notice_files" /> */}
                      </Col>
                    </Row>
                  </section>
                  <h3 className="primary">
                    UWAGA!! Wprowadź ilość pojazdów w latach za które chcesz się
                    rozliczyć. Jeżeli w danym roku nie wprowadziłeś pojazdów
                    wpisz "0".
                  </h3>
                  <section>
                    <Row gutter={10}>
                      <Col md={3}>
                        <TextField
                          label="w 2014 roku"
                          name="year1"
                          type="number"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="w 2015 roku"
                          name="year2"
                          type="number"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="w 2016 roku"
                          name="year3"
                          type="number"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="w 2017 roku"
                          name="year4"
                          type="number"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="w 2018 roku"
                          name="year5"
                          type="number"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="w 2019 roku"
                          name="year6"
                          type="number"
                        />
                      </Col>
                      <Col md={3}>
                        <TextField
                          label="w 2020 roku"
                          name="year7"
                          type="number"
                        />
                      </Col>
                    </Row>
                    <TextField
                      name="additional_information"
                      label="Dodatkowe informacje"
                    />
                  </section>
                  <h3 className="primary">Zgody</h3>
                  <section>
                    <TextField label="Imię" name="name" required={true} />
                    <TextField
                      label="Nazwisko"
                      name="surname"
                      required={true}
                    />
                    <TextField {...userFields.phone} name="phone_number" />
                    <TextField {...userFields.email} />
                  </section>
                  <div
                    className={`check-form-fields vertical bottom-dashed mb-30`}
                  >
                    <CheckFormField {...termsField} />
                    <CheckFormField {...rodoField} />
                  </div>
                  <div className="md-t mb-30">
                    <b className="red">*</b> - pola oznaczone gwiazdką są
                    obowiązkowe
                  </div>
                  <div className="md-t black t-500 mb-10">Cena</div>
                  {priceState.data &&
                    <div className="price-large mb-5">  {parseFloat(priceState.data?.price.netto).toFixed(2)}</div>
                  }
                  
                  <p className="sm-l-t mb-30">
                    netto za rok sprawozdawczy. Po zapłaceniu faktury proforma
                    otrzymasz (na e-mail wskazany powyżej) - fakturę wraz z
                    drukami sprawozdań.
                  </p>
                  <FormButton
                    color="primary"
                    onClick={formikprops.submitForm}
                    title="Wyślij wniosek"
                    width={250}
                    disabled={!formikprops.isValid}
                  />
                </>
              );
            }}
          </Formik>
        </div>
      </main>
    </div>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(NetworkProvision));
// export default Index;


