import React from "react";
import { Link, Route } from "react-router-dom";

import cookie from "js-cookie";
import { useState } from "react";
import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import { PAGE } from "paths";
import MenuSettings from "components/layout/menu-settings";
import { isMobile } from "react-device-detect";

import _ from "lodash";
import QuoteIcon from "@material-ui/icons/FormatQuote";

//@ts-ignore
import "styles/layout/side-menu.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";

const { Sider } = Layout;
const { Item, SubMenu } = Menu;

const SUB_KEYS = {
  BDO_SERVICES: "bdo_services",
  RECYCLE_SERVICES: "recycle_services",
  WASTE: "waste_management",
  ADMIN: "admin",
};

interface IItem {
  title: string;
  link: string;
  subKey?: string;
  icon?: string;
}

const getSelectedKey = () => {
  // const router = useHistory();

  // if (!router) {
  //   return [];
  // }
  switch (window.location.pathname) {
    case `${PAGE.VEHICLE}/[id]`:
      return [`${PAGE.MY_VEHICLES}`];
    case PAGE.OS_OP1_REPORT:
      return [`${PAGE.OS_OP1}`];
    default:
      return [window.location.pathname];
  }
};

const getOpenKey = () => {
  if (isMobile) {
    return [];
  }
  // const router = useHistory();
  // if (!router) {
  //   return [];
  // }
  // switch(router.pathname){
  //   case `${PAGE.VEHICLE}/[id]`:
  //     return [SUB_KEYS.MY_CARS];
  // }
  const flatMenu = [...menu.recycle_services, ...menu.BDO_services];
  const selectedItem = _.find(
    flatMenu,
    (item: any) => item.link === window.location.pathname
  );
  if (!selectedItem) {
    return [];
  }
  return [selectedItem.subKey];
};

const menu = {
  BDO_services: [
    {
      title: "Klucz API (BDO)",
      link: PAGE.BDO_KEY,
      subKey: SUB_KEYS.BDO_SERVICES,
      icon: "clipboard-check-outline.svg",
    },
    {
      title: "Rejestr BDO",
      link: PAGE.BDO_REGISTRY,
      subKey: SUB_KEYS.BDO_SERVICES,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Sprawozdanie OŚ-OP1",
      link: PAGE.OS_OP1,
      subKey: SUB_KEYS.BDO_SERVICES,
      icon: "text-box-check-outline.svg",
    },
    {
      title: "Sprawozdanie BDO",
      link: PAGE.BDO_REPORT,
      subKey: SUB_KEYS.BDO_SERVICES,
      icon: "clipboard-check-outline.svg",
    },
  ],
  recycle_services: [
    {
      title: "Moje pojazdy",
      link: PAGE.MY_VEHICLES,
      subKey: SUB_KEYS.RECYCLE_SERVICES,
      icon: "car-multiple.svg",
    },
    {
      title: "Zapewnienie sieci",
      link: PAGE.NETWORK_PROVISION,
      subKey: SUB_KEYS.RECYCLE_SERVICES,
      icon: "shield-car.svg",
    },
    // {
    //   title: "Ewidencja mas",
    //   link: PAGE.MASS_REGISTRY,
    //   subKey: SUB_KEYS.RECYCLE_SERVICES,
    //   icon: "folder-clock-outline.svg",
    // },
    {
      title: "Opłaty produktowe",
      link: PAGE.PRODUCT_CHARGES,
      subKey: SUB_KEYS.RECYCLE_SERVICES,
      icon: "recycle.svg",
    },
  ],
  waste_management: [
    {
      title: "Magazyn odpadów",
      link: PAGE.WASTE,
      subKey: SUB_KEYS.WASTE,
      icon: "warehouse.svg",
    },
  ],

  ph_items: [
    {
      title: "Lista wszystkich pojazdów",
      link: PAGE.ADMIN_VEHICLES,
      subKey: SUB_KEYS.ADMIN,
      icon: "car-multiple.svg",
    },
    {
      title: "Klucze BDO",
      link: PAGE.ADMIN_BDO_KEYS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Wnioski OŚ/OP1",
      link: PAGE.ADMIN_OS_OP1_LIST,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Lista WS BDO",
      link: PAGE.ADMIN_BDO_WS_LIST,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Sprawozdania BDO",
      link: PAGE.ADMIN_BDO_REPORTS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Płatności",
      link: PAGE.ADMIN_BDO_PAYMENTS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    }
  ],

  admin_items: [
    {
      title: "Lista wszystkich pojazdów",
      link: PAGE.ADMIN_VEHICLES,
      subKey: SUB_KEYS.ADMIN,
      icon: "car-multiple.svg",
    },
    // {
    //   title: "Zamówienia - deklaracje",
    //   link: PAGE.ADMIN_OO_ORDERS,
    //   subKey: SUB_KEYS.ADMIN,
    //   icon: "text-box-plus-outline.svg",
    // },
    // {
    //   title: "Zamówienia OO - deklaracje zerowe",
    //   link: PAGE.ADMIN_OO_ORDERS_FORM,
    //   subKey: SUB_KEYS.ADMIN,
    //   icon: "text-box-plus-outline.svg",
    // },
    // {
    //   title: "Zestawienia OO",
    //   link: PAGE.ADMIN_OO_SUMMARY,
    //   subKey: SUB_KEYS.ADMIN,
    //   icon: "text-box-plus-outline.svg",
    // },
    // {
    //   title: "Zamówienia - abonamenty",
    //   link: PAGE.ADMIN_OO_SUBORDERS,
    //   subKey: SUB_KEYS.ADMIN,
    //   icon: "text-box-plus-outline.svg",
    // },
    {
      title: "Klucze BDO",
      link: PAGE.ADMIN_BDO_KEYS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Wnioski OŚ/OP1",
      link: PAGE.ADMIN_OS_OP1_LIST,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Lista WS BDO",
      link: PAGE.ADMIN_BDO_WS_LIST,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Dokumenty",
      link: PAGE.ADMIN_DOCUMENTS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Sprawozdania BDO",
      link: PAGE.ADMIN_BDO_REPORTS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    },
    {
      title: "Płatności",
      link: PAGE.ADMIN_BDO_PAYMENTS,
      subKey: SUB_KEYS.ADMIN,
      icon: "text-box-plus-outline.svg",
    }
  ],
  // { title: "Karty ewidencji odpadów", link: '#', subKey: SUB_KEYS.WASTE },
  // { title: "Karta przekazania odpadów", link : '#', subKey: SUB_KEYS.WASTE },
  // { title: "Raporty roczne", link: '#', subKey: SUB_KEYS.WASTE },
};

function Index(props: any) {
  const [menuSettingsOpen, setMenuSettingsOpen] = useState(false);
  const user = useSelector((state: RootState) => state.user);
  const { menuCollapsed, mobileMenuHidden } = props;

  const warToken = cookie.get('war_token');

  const toggleMenuSettings = () => {
    setMenuSettingsOpen(!menuSettingsOpen);
  };

  const simpleMenuItems = (items: Array<IItem>) => {
    return items.map((item, index) => {
      return (
        <Item key={item.link}>
          <Link to={item.link}>
            <img alt="" className="anticon" src={`/static/img/${item.icon}`} />
            <span>{item.title}</span>
          </Link>
        </Item>
      );
    });
  };

  const MenuItems = (items: Array<IItem>) => {
    return items.map((item) => {
      return (
        <Item key={item.link}>
          <Link to={item.link}>
            <img src={item.icon} alt={item.title} className="anticon" />
            {item.title}
          </Link>
        </Item>
      );
    });
  };

  const saveMenuSettings = () => { };
  if (menuSettingsOpen) {
    return (
      <Sider
        trigger={null}
        className="sidebar"
        width={"320"}
        collapsible
        collapsed={menuCollapsed}
        collapsedWidth={70}
        breakpoint={"xl"}
      >
        <MenuSettings
          handleSubmit={saveMenuSettings}
          handleCancel={() => toggleMenuSettings()}
        />
      </Sider>
    );
  }

  return (
    <Sider
      trigger={null}
      className="sidebar"
      width="320"
      collapsible
      collapsed={menuCollapsed}
      breakpoint={"xl"}
    >
      <Menu
        selectedKeys={getSelectedKey()}
        defaultOpenKeys={[
          SUB_KEYS.RECYCLE_SERVICES,
          SUB_KEYS.WASTE,
          SUB_KEYS.BDO_SERVICES,
        ]}
        id="menu"
        mode={menuCollapsed ? "vertical" : "inline"}
      >
        <Item key="/">
          <Link to="/">
            <img
              src="/static/img/home.svg"
              alt="użytkownik"
              className="anticon"
            />
            <span>Start</span>
          </Link>
        </Item>
        {(!user.session?.is_admin && !user.session?.is_ph) &&
          <Item key={PAGE.SETTLEMENTS}>
            <Link to={PAGE.SETTLEMENTS}>
              <img
                src="/static/img/wallet_gray.svg"
                alt="użytkownik"
                className="anticon"
              />
              <span>Rozliczenia</span>
            </Link>
          </Item>}
        <Item key={PAGE.DOCUMENTS}>
          <Link to={PAGE.DOCUMENTS}>
            <img
              src="/static/img/file-document-edit-outline.svg"
              alt="użytkownik"
              className="anticon"
            />
            <span>Dokumenty</span>
          </Link>
        </Item>
        {(!user.session?.is_admin && !user.session?.is_ph) &&
          <Item key={PAGE.AGREEMENT_SCANS}>
            <Link to={PAGE.AGREEMENT_SCANS}>
              <img
                src="/static/img/file-document-edit-outline.svg"
                alt="Skany umów"
                className="anticon"
              />
              <span>Skany umów</span>
            </Link>
          </Item>}

        {user.session?.is_admin &&
          [
            <li className="section-header">

              <span>Administrator</span> <hr />
            </li>,
            simpleMenuItems(menu.admin_items)

          ]
        }
        {(user.session?.is_admin) &&
          <Item key={PAGE.ADMIN_INVOICES}>
            <Link to={{ pathname: PAGE.ADMIN_INVOICES }} target="_blank" rel="noopener noreferrer">
              <img alt="" className="anticon" src='/static/img/text-box-plus-outline.svg' />
              <span>Korekty</span>
            </Link>
          </Item>
        }
        {user.session?.is_ph &&
          [
            <li className="section-header">

              <span>Przedstawiciel handlowy</span> <hr />
            </li>,
            simpleMenuItems(menu.ph_items)

          ]
        }
        {(!user.session?.is_admin && !user.session?.is_ph) &&
          [
            <li className="section-header">
              {" "}
              <span>Usługi BDO</span> <hr />{" "}
            </li>,
            simpleMenuItems(menu.BDO_services),
            <li className="section-header">
              <span>Usługi recyklingowe</span> <hr />
            </li>,
            simpleMenuItems(menu.recycle_services),

          ]
        }
        {warToken && [
          <li className="section-header">
            {" "}
            <span>Administracja odpadów</span> <hr />
          </li>,
          simpleMenuItems(menu.waste_management)
        ]
        }

        {/* <SubMenu
        
          className="test-class"
          key={SUB_KEYS.RECYCLE_SERVICES}
          title={
            <span>
           
                    <span>Usługi recyklingowe</span>
            </span>
          }
        >
          {simpleMenuItems(menu.recycle_services)}
        </SubMenu> */}
      </Menu>
      {/* {!menuCollapsed && (
        <span className="manage-menu" onClick={toggleMenuSettings}>
          Zarządzaj menu
        </span>
      )} */}

      {/* <style jsx global>
        {s1}
      </style> */}
    </Sider >
  );
}

Index.getInitialProps = async (ctx: any) => {
  return {
    menu: menu,
  };
};

// Index.propTypes = {

//   menuCollapsed : PropTypes.bool,
//   mobileMenuHidden: PropTypes.bool
// }

export default Index;
