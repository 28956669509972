import React, { useEffect, useState } from "react";
import SimpleLayout from "components/layout/simple-layout";
import CloseIcon from "@material-ui/icons/Close";
import { Router, Switch, Route, useHistory, Link } from "react-router-dom";
import BDOReportPopup from "components/bdo-report/bdo-report-popup";
import { PAGE } from "paths";
import Step1 from "components/os-op1-form/step-1";
import Step2 from "components/os-op1-form/step-2";
import Step3 from "components/os-op1-form/step-3";
import PaymentStatus from "components/os-op1-form/payment-status";
import "styles/components/bdo-report/bdo-report-form.scss";

const BDOReportForm = (props: any) => {
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(1);
  useEffect(() => {
    switch (history.location.pathname) {
      case PAGE.OS_OP1_STEP_1:
        setCurrentStep(1);
        break;
      case PAGE.OS_OP1_STEP_2:
        setCurrentStep(2);
        break;
        case PAGE.OS_OP1_STEP_3:
          setCurrentStep(3);
          break;
      default :
        break;
    }
  }, [history.location]);

  

  return (
    <div className="bdo-report-form">
      <div className="form-content">
        <h1 className="primary">Sprawozdanie OŚ-OP1</h1>
        <Link to={PAGE.OS_OP1} className="close-page">
          <CloseIcon className="close-button" />
        </Link>
        <div className="steps-nav">
          <ul className="request-order">
            <li className={`${currentStep === 1 && 'active'} grow-1`}>
              <div>
                <label>Dane podstawowe projektu</label>
              </div>
            </li>
            <li className={`${currentStep === 2 && 'active'} grow-1`}> 
              <div>
                <label>Rozliczenie</label>
              </div>
            </li>
            <li className={`${currentStep === 3 && 'active'}`}>
              <div>
                <label>Zgody i płatność</label>
              </div>
            </li>
          </ul>
        </div>

        <Route path={PAGE.OS_OP1_STEP_1} component={Step1} />
        <Route path={PAGE.OS_OP1_STEP_2} component={Step2} />
        <Route path={PAGE.OS_OP1_STEP_3} component={Step3} />
        <Route path={PAGE.OS_OP1_PAYMENT_STATUS} component={PaymentStatus} />
      </div>
    </div>
  );
};

export default SimpleLayout(BDOReportForm);
