import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import Button from "components/buttons/button";
import FormButton from "components/buttons/form-button";
import { IImportedVehicleNumber, IElectronicsFirstStepFormData } from "types/products/electronics-types";
import {useHistory} from 'react-router-dom'
import { IActionState, ActionResponse } from "types/async-types";
import {
  getCostEstimation,
  fetchEelectronicsDeclaration,
  fetchElectronicsFirstStepFormData,
  saveElectronicsFirstStep,
} from "actions/product-groups";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { numToCurrency } from "utils/helpers";
import { PAGE } from "paths";
import cookie from "js-cookie";

const ElectronicsStep1 = (props: any) => {
    const history = useHistory();
  const formMethods = useForm({
    mode: "onChange",
    defaultValues: {},
  });
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const [costEstimationState, execFetchCostEstimation]: readonly [
    IActionState<IElectronicsFirstStepFormData>, 
    typeof getCostEstimation
  ] = useAsyncAction(getCostEstimation);

  const [formDataState, execFetchFormData]: readonly [
    IActionState<IElectronicsFirstStepFormData>,
    typeof fetchElectronicsFirstStepFormData
  ] = useAsyncAction(fetchElectronicsFirstStepFormData);

  const [showDataState, execFetchShowData]: readonly [
    IActionState<any>,
    typeof fetchEelectronicsDeclaration
  ] = useAsyncAction(fetchEelectronicsDeclaration);

  const [saveStepData, execSaveStep]: readonly [
    IActionState<any>,
    typeof saveElectronicsFirstStep
  ] = useAsyncAction(saveElectronicsFirstStep);

  const renderVehicleFields = () => {
    if (formDataState.data) {
      return Object.keys(formDataState.data?.vehicle_types).map(
        (key, index) => {
          return (
            <Col md={3}>
              <TextField
                label={formDataState.data?.vehicle_types[key] || ""}
                formMethods={formMethods}
                name={`planned_vehicle_number.${key}`}
                type="number"
              />
            </Col>
          );
        }
      );
    }
  };
  const calculate = (values: {
    planned_vehicle_number: { [key: string]: string };
  }) => {
    execFetchCostEstimation(values);
  };

  const onSubmit = async (values: {
    planned_vehicle_number: { [key: string]: string };
  }) => {
   const res : ActionResponse<{id: string}> = await execSaveStep(values);
   if(res.status === 200 || res.status === 201){
    history.push(`${PAGE.ELECTRONICS_FORM_STEP_2}?id=${res.data.id}`);
   }
  };

  useEffect(() => {
    execFetchFormData();
    if(id){
        execFetchShowData(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const reset = formMethods.reset;
    if (showDataState.data) {
      reset({
        ...showDataState.data,
        // * here assignments from formData in case of formData providing form values
      });

   
    }
    if(formDataState.data?.guest_token){
      
      cookie.set('token', formDataState.data?.guest_token );

    }
  }, [formDataState.data, showDataState.data]);

  return (
    <>
      {(formDataState.loading || costEstimationState.loading || saveStepData.loading) && <Spinner />}
      <div className="block">
        <h1 className="mb-30">Sprawdź ile zapłacisz</h1>
        <p>
          Podaj planowaną liczbę importowanych pojazdów w bieżącym roku lub
          liczbę zaimportowanych w roku poprzednim.
        </p>
        <Row gutter={10} className="mb-20 flex-left" type="flex">
          {renderVehicleFields()}
        
          <Col md={2}>
            <FormButton
              onClick={formMethods.handleSubmit(calculate)}
              title="Oblicz"
              color="primary"
            //   disabled={showDataState.data ? true : false}
            />
          </Col>
        </Row>
        {((costEstimationState.data && costEstimationState.data.netto > 0) || (showDataState.data && showDataState.data.netto > 0 ) ) && (
          <div>
            <div className="sm-t mb-10">
              Szacowana roczna opłata produktowa wyniesie
            </div>
            <div>
              <span className="price-large">
                {numToCurrency(costEstimationState.data?.netto || showDataState.data.netto )}
              </span>{" "}
              <span className="sm-t black">netto </span>{" "}
            </div>
          </div>
        )}
      </div>
      <div className="buttons-panel">
        <div className="form-content">
          <FormButton
            width={220}
            color="primary"
            onClick={formMethods.handleSubmit(onSubmit)}
            title="Zapisz i kontynuuj"
          />
        </div>
      </div>
    </>
  );
};

export default ElectronicsStep1;
