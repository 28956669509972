import React from "react";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
// import { Field, ErrorMessage, FormikProps } from "formik";
import DatePicker from "antd/lib/date-picker";
import { PasswordInput } from "antd-password-input-strength";
// @ts-ignore
import s1 from "styles/components/input.scss";
import moment from "moment";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, UseFormReturn } from "react-hook-form";
import { ErrorLabel } from "components/common/error-message";
import { Col, Row, Tooltip } from "antd";

// import { DatePicker } from "antd";

export interface ITextProps {
  disabled?: boolean;
  label: string;
  size?: string;
  name: string;
  search?: boolean;
  clearVisible?: boolean;
  clearSearch?: () => void;
  optFixedWidth?: boolean;
  placeholder?: string;
  onChange?: (a: any) => void;
  type?:
  | "text"
  | "password"
  | "new-password"
  | "tel"
  | "email"
  | "number"
  | "date"
  | "date-range"
  | "textarea"
  | "file";
  testId?: string;
  required?: boolean;
  suffix?: string | JSX.Element;
  max?: number;
  min?: number;
  maxlength?: number;
  validate?: any;
  className?: string;
  value?: any;
  formMethods: UseFormReturn<any>;
  paste?: boolean;
  rules?: any;
  maxDate?: Date;
  noBottomMargin?: boolean;
  textClass?: string;
  toolTip?: string;
}

function TextField(props: ITextProps) {
  const {
    formMethods,
    disabled,
    label,
    size,
    name,
    search,
    clearVisible,
    clearSearch,
    testId,
    required,
    onChange,
    rules,
    maxlength,
    paste,
    maxDate,
    className,
    textClass,
    noBottomMargin,
    toolTip
  } = props;
  rules?.valueOf();
  //   const { register, handleSubmit, watch, errors, setValue } = useForm();

  const inputLabel = (label: string, toolTip?: string) => {
    if (toolTip) {
      return (
        <span>
          < Tooltip title={toolTip} >
          {label}
            <img
              src="/static/img/help-circle-outline.svg"
              alt="Średnie masy"
              className="anticon ml-10"
              height={'15px'}

            />
          </Tooltip >
        </span>
      )

    } else {

      return (<span>{label}</span>)
    }
  }

  return (
    <div className={`${search && "search-field"} ${className || ""}`}>

      <Form.Item required={required}
        label={inputLabel(label, toolTip)}
        labelAlign={'left'}
        className={`${size} ${noBottomMargin ? 'mb-0' : ""}`}>
        {search && (
          <SearchIcon data-test="search-icon" className="search-glass" />
        )}
        {clearVisible && (
          <CloseIcon
            data-test="clear-icon"
            onClick={clearSearch}
            className="clear-search"
          />
        )}
        {/* <input name={props.name}    ref={formMethods.register({required: true})}  /> */}
        <Controller
          name={name}
          control={formMethods?.control}
          rules={rules}
          render={({ field, fieldState, formState }) => {
            const pasteFromClipBoard = () => {
              navigator.clipboard
                .readText()
                .then((clipText) => formMethods.setValue(name, clipText));
            };
            let input: any = null;

            if (props.type === "date") {
              const dateVal = formMethods.getValues(name)
                ? moment(formMethods.getValues(name))
                : undefined;
              input = (
                <DatePicker
                  disabled={disabled}
                  value={dateVal}
                  disabledDate={(d: any) =>
                    !d ||
                    d.isAfter(maxDate || "2030-12-31") ||
                    d.isSameOrBefore("1900-01-01")
                  }
                  data-testid={testId}
                  name={props.name}
                  placeholder={props.placeholder}
                  onChange={(date: any, dateString: any) => {
                    formMethods.setValue(name, dateString, { shouldValidate: true, shouldDirty: true });

                    if (props.onChange) {
                      props.onChange(dateString);
                    }
                  }}
                />
              );
            } else if (props.type === "password") {
              input = <Input.Password data-testid={testId} {...field} />;
            } else if (props.type === "new-password") {
              input = (
                <PasswordInput
                  {...field}
                  {...props}
                  settings={{
                    height: 5,
                    alwaysVisible: true,
                    colorScheme: {
                      levels: [
                        "#ff4033",
                        "#fe940d",
                        "#ffd908",
                        "#cbe11d",
                        "#6ecc3a",
                      ],
                      noLevel: "lightgrey",
                    },
                  }}
                />
              );
            } else if (props.type === "number") {
              const blockInvalidChar = (e: any) => {
                ["+", "-"].includes(e.key) && e.preventDefault();
              };
              input = (
                <Input
                  autoComplete="off"
                  data-testid={testId}
                  {...field}
                  className={textClass}
                  disabled={disabled}
                  type="number"
                  min={0}
                  onKeyDown={blockInvalidChar}
                  // suffix={
                  //   paste ? (
                  //     <a onClick={pasteFromClipBoard}>wklej</a>
                  //   ) : props.suffix
                  // }
                  onChange={
                    onChange
                      ? (e) => {
                        field.onChange(e.target.value);

                        onChange(e.target.value);
                      }
                      : field.onChange
                  }
                />
              );
            } else if (props.type === "textarea") {
              input = (
                <Input.TextArea
                  autoComplete="off"
                  // itemRef={formMethods.register({required: true})}
                  // onChange={onChange}
                  // value={value}
                  {...field}
                  maxLength={maxlength || undefined}
                  onChange={
                    onChange
                      ? (e) => {
                        field.onChange(e.target.value);

                        onChange(e.target.value);
                      }
                      : field.onChange
                  }

                  rows={4}

                />
              );
            } else {
              input = (
                <Input
                  autoComplete="off"
                  // itemRef={formMethods.register({required: true})}
                  // onChange={onChange}
                  // value={value}
                  disabled={disabled}
                  {...field}
                  maxLength={maxlength || undefined}
                  onChange={
                    onChange
                      ? (e) => {
                        field.onChange(e.target.value);

                        onChange(e.target.value);
                      }
                      : field.onChange
                  }
                  size="default"
                />
              );
            }

            return (
              <>
                {input}
                {formMethods?.formState && (
                  <ErrorMessage
                    errors={formMethods?.formState.errors}
                    name={name}
                    render={({ message }: any) => (
                      ErrorLabel(message)
                    )}
                  />
                )}
              </>
            );
          }} // props contains: onChange, onBlur and value
        />

        {/* <Input
          autoComplete="off"
          // itemRef={formMethods.register({required: true})}
          onChange={(e) => {
            formMethods.setValue("email", e.target.value);
            if (onChange) {
              onChange(e);
            }
          }}
          data-testid={testId}
          size="default"
        /> */}
        {/* <Field  autoComplete="off" className="text" {...props} disabled={disabled} validate={validate ? validate() : undefined} > */}

        {/* </Field> */}
        {/* <ErrorMessage name={name} render={ErrorMsg} /> */}
      </Form.Item>
    </div>
  );
}

export default TextField;
