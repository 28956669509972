import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TextField from "components/form-fields/formik-text-field";
import { Formik } from "formik";
import {
  addedCodesType,
  IWasteForwardFormValues,
  ITransferCode,
} from "types/waste-types";
import Alert from "components/common/alert";
import { fetchTransferCodes, createTransferCard } from "actions/waste";
import { fetchAddresses } from "actions/company";
import { useAsyncAction } from "utils/async-action";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";
import { FIELD_NAMES } from "config/form-config/waste-card-config";
import SpinnerPopup from "components/core/spinner";
import WasteAddress from "./waste-create-form/waste-address/waste-address";
import styles from "./waste.module.scss";
import * as Yup from "yup";
import { dateRequired, required, yupRequired } from "validators";
import _ from "lodash";
import { WasteCardType } from "types/waste-card-types";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import FormButton from "components/buttons/form-button";
import Button from "components/buttons/button";

type TextProps = React.ComponentProps<typeof TextField>;

function WasteForward(props: any) {
  interface IForwardedAll {
    [key: string]: {
      [key: string]: boolean;
    };
  }
  const {
    submitForm,
    setFieldValue,
    isValid,
    transferCardState,
    resetForm,
    setFieldTouched,
    validateForm,
    errors,
    code,
    setTransferType,
    closeSider,
  } = props;
  const { transferType }: { transferType: WasteCardType } = props;
  const { values }: { values: IWasteForwardFormValues } = props;
  const [forwardedAll, setForwardedAll] = useState<IForwardedAll>({});

  const [codesState, execFetchTransferCodes]: readonly [
    any,
    any
  ] = useAsyncAction(fetchTransferCodes);
  const [addressesState, execFetchAddresses] = useAsyncAction(fetchAddresses);
  const history = useHistory();

  const codes: addedCodesType | null = codesState.data;
  // onst message : string = codesState
  const [visibleSections, setVisibleSections] = useState<{
    [key: string]: boolean;
  }>({});

  const applyDate = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let date = values.transfer_date ? values.transfer_date : "";
    // const codes = urlParams.getAll('waste_codes[]');
    // const addressId = urlParams.getAll('waste_codes[]');
    urlParams.set("date", date.replace(/-/gi, "."));
    let paramsString = urlParams.toString();
    paramsString = paramsString.replace(/%5B%5D/gi, "[]");
    paramsString = `?${paramsString}`;

    execFetchTransferCodes(paramsString);
    if (code) {
      history.push(`${PAGE.WASTE_DETAILS}/${code}/${paramsString}`);
    } else {
      history.push(`${PAGE.WASTE}${paramsString}`);
    }
  };

  const onDateEnter = (e: any) => {
    if (e.key === "Enter") {
      const urlParams = new URLSearchParams(window.location.search);

      // const codes = urlParams.getAll('waste_codes[]');
      // const addressId = urlParams.getAll('waste_codes[]');
      urlParams.set("date", e.target.value.replace(/-/gi, "."));
      let paramsString = urlParams.toString();
      paramsString = paramsString.replace(/%5B%5D/gi, "[]");
      paramsString = `?${paramsString}`;

      execFetchTransferCodes(paramsString);

      history.push(`${PAGE.WASTE_FORWARD}${paramsString}`);
    }
  };

  //** side effects */
  useEffect(() => {
    const query = window.location.search;
    const params = new URLSearchParams(query);
    let date = params.get("date");

    if (date) {
      date = date.replace(/\./gi, "-");
    }

    setFieldValue(FIELD_NAMES.WASTE_ADDRESS, params.get("address_id"));
    setFieldValue(FIELD.FORWARD_DATE, date, true);

    let type = params.get("type");
    setFieldValue("card_type", type);
    setTransferType(type);
  }, []);

  useEffect(() => {
    execFetchAddresses();
  }, []);

  useEffect(() => {
    setFieldValue("transfers", {});
    setTimeout(() => validateForm());
  }, [codes]);

  useEffect(() => {
    if (transferCardState.data) {
      if (code) {
        history.push(`${PAGE.WASTE}`);
      } else {
        history.push(`${PAGE.WASTE}`);
        closeSider();
      }
    }
  }, [transferCardState.data]);

  const FIELD = {
    FORWARD_DATE: "transfer_date",
    ITEMS: "items",
    required: true,
  };
  const fieldData = {
    forward_date: {
      name: FIELD.FORWARD_DATE,
      onKeyPress: onDateEnter,
      required: true,
      type: "date",
      label: "Data przekazania odpadu",
      // min: "2019-02-18",
    } as TextProps,

    card_number: {
      name: FIELD_NAMES.CARD_NR,
      required: true,
      type: "text",
      label: "Numer karty przekazania odpadu",
    } as TextProps,

    [FIELD_NAMES.WASTE_ADDRESS]: {
      name: FIELD_NAMES.WASTE_ADDRESS,
      type: "text",
      label: "Adres przekazania odpadu",
      // required: false,
      disableDefault: true,
    },
    // [FIELD.FORWARD_AMOUNT]: {
    //   name: FIELD.FORWARD_AMOUNT,
    //   type: "number",
    //   label: "Ile przekazać",
    // },
  };

  const setAmount = (
    code: string,
    addressId: string,
    amount: number | null
  ) => {
    let transfers = values.transfers;

    //   transfers.push({
    //       address_id : addressId,
    //       codes : {
    //         [`${code}`] : {
    //           amount: amount,
    //           normalized_code: code,
    //           unit: "kg",
    //         }
    //       }
    //  })
    const prevCodes = values.transfers[addressId]
      ? values.transfers[addressId].codes
      : null;

    setFieldValue(`transfers[${addressId}]`, {
      codes: {
        ...prevCodes,
        [code]: {
          amount: amount,
          normalized_code: code,
          unit: "kg",
        },
      },
    });
  };

  const toggleSection = (key: string, visible: boolean) => {
    debugger;
    setVisibleSections((prevObj: any) => ({ ...prevObj, [key]: visible }));
  };

  const onAmountChange = (
    e: { target: { value: string } },
    code: string,
    addressId: string
  ) => {
    if (!codes) {
      return;
    }
    const val = parseFloat(e.target.value);

    if (val >= codes[code].addresses[addressId].remained) {
      setForwardedAll({ ...forwardedAll, [code]: { [addressId]: true } });
      if (val > codes[code].addresses[addressId].remained) {
        setAmount(code, addressId, codes[code].addresses[addressId].remained);
        return;
      }
    } else {
      setForwardedAll({
        ...forwardedAll,
        [code]: { ...forwardedAll[code], [addressId]: false },
      });
    }
    setAmount(code, addressId, val);
  };

  const forwardAll = (codeKey: string, addressKey: string) => {
    if (!codes) {
      return;
    }
    setAmount(
      codeKey,
      addressKey,
      codes[codeKey].addresses[addressKey].remained
    );
    // setFieldValue(`transfers[${addressKey}].codes[${codeKey}].amount`, codes[codeKey].addresses[addressKey].remained);
    setForwardedAll({
      ...forwardedAll,
      [codeKey]: { ...forwardedAll[codeKey], [addressKey]: true },
    });
    // values.codes[code].amount = mockCodes[code].produced;
  };

  const onAddressChange = (addressId: string) => {
    setFieldValue(FIELD_NAMES.WASTE_ADDRESS, addressId);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(FIELD_NAMES.WASTE_ADDRESS, addressId);

    let paramsString = urlParams.toString();
    paramsString = paramsString.replace(/%5B%5D/gi, "[]");
    paramsString = `?${paramsString}`;

    execFetchTransferCodes(paramsString);
    if (code) {
      history.push(`${PAGE.WASTE_DETAILS}/${code}${paramsString}`);
    } else {
      history.push(`${PAGE.WASTE}${paramsString}`);
    }
  };

  const renderAddresses = (code: ITransferCode, codeKey: string) => {
    const addresses = code.addresses;

    return Object.keys(addresses).map((addressKey: string, index: number) => {
      return (
        <tr
          key={addressKey}
          className={`${styles['address-row']} ${!visibleSections[codeKey] ? "" : styles['hidden']}`}
        >
          <td className="code"></td>
          <td className={`${styles.bold} address-string text-left`}>
            {addresses[addressKey].address_string}
          </td>{" "}
          {/* <td className={`desc text-left`}>
            <Link to={PAGE.WASTE_DETAILS}>{code[addressKey].name}</Link>
          </td>{" "} */}
          <td className={`${styles.forward_input}`}>
            <div className="flex">
              <span className="mr-10">Przekaż</span>

              <div>
                <TextField
                  name={`transfers['${addressKey}'].codes[${codeKey}].amount`}
                  type="number"
                  label=""
                  min={1}
                  max={addresses[addressKey].remained}
                  onChange={(e: { target: { value: string } }) =>
                    onAmountChange(e, codeKey, addressKey)
                  }
                />
              </div>
              <div>
                kg z dostępnych{" "}
                <span className="red">
                  {addresses[addressKey].remained} kg{" "}
                </span>
              </div>
            </div>
          </td>{" "}
          <td className={`bold lg-t t-right red`}>
            <FormButton
              title="Wszystko"
              color="white"
              // className={`${
              //   (forwardedAll[codeKey]
              //     ? forwardedAll[codeKey][addressKey]
              //     : false) && "disabled"
              // }`}
              disabled={
                (forwardedAll[codeKey]
                  ? forwardedAll[codeKey][addressKey]
                  : false) && true
              }
              onClick={() => forwardAll(codeKey, addressKey)}
            />
          </td>{" "}
          {/* <td className={`bold link text-right`}>
            
          </td> */}
        </tr>
      );
    });
  };

  const FilteredCodes = () => {
    if (!codes) {
      return <div></div>;
    }

    // if (codes.message) {
    //   return <Alert message={codes.message.} />;
    // }

    const codesList = Object.keys(codes).map((key: string, index: number) => {
      return [
        <tr className="code-row">
              <td className={`t-right action-btn`}>
            <div style={{width: '20px'}} className="i-b">
            {!visibleSections[key] ? ( 
              <>
              <div role="button"   onClick={() => toggleSection(key, true)} className="ant-table-row-expand-icon ant-table-row-expanded" aria-label="Collapse row"></div>
              {/* <Button
                color="white"
                
                onClick={() => toggleSection(key, true)}
                className="button inverted-button border visible"
                title="+"
              /> */}
              </>
            ) : (
              <div role="button"   onClick={() => toggleSection(key, false)} className="ant-table-row-expand-icon ant-table-row-collapsed" aria-label="Collapse row"></div>
           
            )}
            </div>
          </td>
          <td className="text-left t-500 code">{codes[key].code}</td>
          <td className="black desc">{codes[key].name}</td>
          <td className="t-right total-amount">{codes[key].total_remained} kg</td>
      
        </tr>,
        renderAddresses(codes[key], key),
      ];
    });


    return (
      <>
        <table className="custom-ant-table ant-table waste-table"> 
          <thead className="ant-table-thead">
            <tr>
            <th style={{width: "40px"}}></th>
              <th  style={{width: "200px"}}  className={`text-left code`}>Kod odpadu</th>
              <th className={`text-left name desc`}>Nazwa</th>
              <th className={`t-right total-amount`}><div className="t-right">Łącznie dostępnych</div></th>
              
            </tr>
          </thead>
          <tbody>{codesList}</tbody>
        </table>
      </>
    );
  };

  const addressModalProps = {
    adding_disabled: true,
    empty_allowed: true,
    setFieldValue,
    onChange: onAddressChange,
    values,
    wasteCardMode: true,
    addressesState,
    addressField: fieldData[FIELD_NAMES.WASTE_ADDRESS],
  };

  if (codesState.loading || transferCardState.loading) {
    return <SpinnerPopup />;
  }

  const urlParams = new URLSearchParams(window.location.search);
  const date = urlParams.get("date");

  return (
    < >
      <header className="sider-header">
        <h2 className="primary mb-15">
          {" "}
          {transferType === "company_export"
            ? "Przekazanie odpadu firmie"
            : "Przekazanie odpadu osobie fizycznej"}{" "}
        </h2>
      </header>
      <div className="sider-body">
      <p>
        Wypełnij datę przekazania, a następnie podaj przekazywane ilości. System
        uwzględniając datę przekazania przelicza ile maksymalnie można
        przekazać. Dostępne są ilości wytworzone przed datą przekazania.
      </p>

      {transferType === "company_export" && (
        <TextField {...fieldData.card_number} />
      )}

      <Row className="flex">
        <Col md={7}>
          <TextField {...fieldData.forward_date} />
        </Col>
        <Col md={3}>
          <FormButton
            title="Zastosuj"
            color="primary"
            disabled={!values.transfer_date || errors.transfer_date}
            onClick={() => applyDate()}
            className="button primary-button ml-10"
          />
        </Col>
      </Row>

      {values.transfer_date && date && (
        <Row>
          <Col md={12}>
            <WasteAddress {...addressModalProps} />
          </Col>
        </Row>
      )}

      {codes && !Object.keys(codes).length ? (
        <Alert message="Brak wyników" />
      ) : (
        FilteredCodes()
      )}
      </div>
      {values.transfer_date && date && (
        <div className="sider-footer t-right">
          <FormButton
            disabled={!isValid}
            onClick={submitForm}
            className="f-right"
            color="primary"
            width={150}
            title="Przekaż"
          />
        </div>
      )}
    </>
  );
}

/* Form wrapper with config and basic actions & props */
const FormWrapper = (props: any) => {
  const { code } = props;
  const [transferType, setTransferType] = useState<string | null>(null);
  const [transferCardState, execCreateTransferCard] = useAsyncAction(
    createTransferCard
  );
  const submitAction: (
    a: IWasteForwardFormValues
  ) => Promise<any> = execCreateTransferCard;

  const validateTransfers = Yup.object()
    .nullable()
    .test(
      "transfers",
      "Należy dodać przynajmniej jeden kod odpadu",
      (transfers: any) => {
        let isValid = true;
        if (!transfers || !Object.keys(transfers).length) {
          return false;
        }
        // return true;
        _.map(Object.keys(transfers), (key) => {
          if (!Object.keys(transfers[key].codes).length) {
            isValid = false;
          }
          isValid = _.some(Object.keys(transfers[key].codes), (codeKey) => {
            return (
              transfers[key].codes[codeKey].amount !== 0 &&
              transfers[key].codes[codeKey].amount !== undefined
            );
          });
          //   values[key].amount !== null && values[key].amount !== undefined
          // );
        });
        return isValid;
      }
    );

  //** validation schema */
  const validationSchema = Yup.object().shape({
    card_number:
      transferType === "company_export"
        ? Yup.string().concat(yupRequired)
        : Yup.string(),
    transfer_date: Yup.date()
      .concat(dateRequired)
      .max(
        new Date(),
        "Podana data nie może być późniejsza niż data dzisiejsza"
      ),
    transfers: validateTransfers,
  });

  const onSubmit = (values: IWasteForwardFormValues) => {
    submitAction(values);
  };

  const formConfig = {
    onSubmit: onSubmit,
    initialValues: { address_id: undefined, transfers: {}, card_type: null },
    validationSchema: validationSchema,
    isInitialValid: false,
    enableReinitialize: true,
  };

  return (
    <Formik {...formConfig}>
      {(formikProps) => {
        return (
          <WasteForward
            {...props}
            {...formikProps}
            transferType={transferType}
            setTransferType={setTransferType}
            transferCardState={transferCardState}
          />
        );
      }}
    </Formik>
  );
};

export default FormWrapper;
