import { IMassDeclaration, ISoOrdersApprovalForm, ISoOrdersApprovalValues, approval, approvalForm } from "actions/so-orders";
import { Slider } from "antd";
import { SliderValue } from "antd/lib/slider";
import { default as Button, default as FormButton } from "components/buttons/form-button";
import FormButtons from 'components/common/form-buttons';
import Spinner from "components/core/spinner";
import withFormLayout from "components/layout/form-layout";
import SimpleLayout from "components/layout/simple-layout";
import React, { useEffect, useState } from "react";
import {
  FormProvider,
  useForm
} from "react-hook-form";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import { formatNumber, numToCurrency } from "utils/helpers";
import "../../wdyr";
import "./approval-form.scss";
import { ImportExport } from "@material-ui/icons";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";
import CheckFormField from "components/form-fields/hook-form-fields/hook-check-field";


const SoOrderApprovalFrom = (props: {}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const year = urlParams.get("year");
  const token = urlParams.get("token");

  const history = useHistory();

  const [weights, setWeights] = useState<{ [key: string]: number }>({})
  const [nettoSums, setNettoSums] = useState<{ [key: string]: number }>({})
  const [test, setTest] = useState(0)
  const [tableState, setTableState] = useState<ISoOrdersApprovalForm | null>(null)
  const [needCalculate, setNeedCalculate] = useState(true)

  const formMethods = useForm<ISoOrdersApprovalValues>({
    mode: "onChange",
    defaultValues: {},
  });

  const { control, watch, reset, getValues } = formMethods;

  const [approvalFormState, execApprovalForm]: readonly [
    IActionState<ISoOrdersApprovalForm>,
    typeof approvalForm
  ] = useAsyncAction(approvalForm);

  const [approvalState, execApproval]: readonly [
    IActionState<{ [key: string]: string }>,
    typeof approval
  ] = useAsyncAction(approval);

  useEffect(() => {
    initFormData()
  }, []);

  const initFormData = async () => {
    let resp = await execApprovalForm(year, token);
    if (resp && resp.status == 200) {
      setTableState(resp?.data || {})
      setNeedCalculate(!resp?.data.mass_declaration.every((element: IMassDeclaration) => element.unmodified_weight === 0))
    }
  };


  const massChange = (item: IMassDeclaration, value: SliderValue) => {
    let valueFloat = parseFloat(value.toString());

    let weight = item.unmodified_weight * (valueFloat / 100)
    formMethods.setValue(`mass_approved.${item.id}`, weight)
    weights[item.id] = weight
    nettoSums[item.id] = weight * item.price_netto * (item.act_salvage_level / 100)

    setTest(weight)
    setNeedCalculate(true)
  }

  const slidePoz = (item: IMassDeclaration) => {
    return (item.weight / item.unmodified_weight) * 100
  }

  const submitForm = (values: ISoOrdersApprovalValues) => {
    execApproval(year, token, values)
  };

  useEffect(() => {
    if (approvalState.response?.status === 200) {
      history.push({
        pathname: PAGE.HOME
      }
      );
    }
  }, [approvalState.response]);

  const calculate = async () => {
    const res = await execApprovalForm(year, token, formMethods.getValues());;
    if(res?.status == 200) {
      setTableState(res?.data || {})
    }
    setNeedCalculate(false)
  };

  return (
    <FormProvider {...formMethods}>
      {(approvalFormState.loading) && (
        <Spinner fixed={true} />
      )}

      <header className="page-header">
        <h1 className="grow-1">Zatwierdź deklarację mas rzeczywistych za {approvalFormState.data?.year} rok</h1>
      </header>

      <div className="block">
        <p>
          Zbliża się okres rozliczeniowy, zatwierdz swoje masy, które zostaną uwzględnione w Sprawozdaniu BDO przez Organizację Odzysku. Masz możliwość edycji danych, pamiętaj jednak, że przekazanie nieprawidłowych informacji moze skutkować nałożeniem kar przez Urząd Marszałkowski.
        </p>

        <table className="mass-table">
          <thead>
            <tr>
              <th className="title heading">Deklaracja mas</th>
              <th className="is-border so-header grey">Wielkość produktów wprowadzonych na rynek w {approvalFormState.data?.year} roku [kg]</th>
              <th className="is-border so-header grey" >Ustawowy poziom odzysku [%]</th>
              <th className="is-border so-header grey">Stawka jednostkowa netto  [PLN/kg]</th>
              <th className="is-border so-header grey">Wartość opłaty netto [PLN]</th>
              <th>
                <p className="so-slider-header primary-blue">Zarządzaj swoimi masami</p>
                <p className="so-slider-subheader">Zgodnie z zapisami umowy mają Państwo możliwość samodzielnego podjęcia decyzji o ostatecznej ilości mas rzeczywistych przekazanych do Organizacji Odzysku TOM.</p>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableState?.mass_declaration.map((item) => (
              <tr>
                <td className="is-border input-text grey">{item.name}</td>
                <td className="is-border input-text primary-blue">{formatNumber((weights[item.id] ?? item.weight), 1, 'kg')}</td>
                <td className="is-border input-text primary-blue">{formatNumber(item.act_salvage_level, 0, "%")}</td>
                <td className="is-border input-text primary-blue">{numToCurrency(item.price_netto)}</td>
                <td className="is-border input-text primary-blue">{numToCurrency(nettoSums[item.id] ?? item.sum_netto)}</td>
                <td>
                  {(item.unmodified_weight && item.unmodified_weight !== 0) ? (
                    <Slider min={0} max={100} step={0.001} defaultValue={slidePoz(item)} tooltipVisible={false} onChange={(value: SliderValue) => { massChange(item, value) }} marks={{ 0: '0', 100: { style: { whiteSpace: 'nowrap' }, label: formatNumber(item.unmodified_weight, 1, 'kg') } }} className='mr-4' />
                  ) : (
                    "Jeżeli sprowadzałeś odpady skontaktuj się z nami"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <p className="mb-30 mt-10">
          Minimalna opłata wynikająca z umowy wynosi {numToCurrency(approvalFormState.data?.administrative_fee.products, { noRound: true })} netto dla produktów oraz {numToCurrency(approvalFormState.data?.administrative_fee.batteries, { noRound: true })} netto dla baterii i akumulatorów. Poniższa kwota jest (niewiążącym wyliczeniem). Podane wartości mogą się różnić w szczególności ze względu na treść umowy z OO oraz w przypadku klientów drugorocznych gdzie do rozliczenia brane są pod uwagę wartości produktów z roku poprzedniego lub z Załącznika nr 1 do umowy.
        </p>

        <table className="summary-table">
          <thead>
            {approvalFormState.data?.fee.map((item) => (
              <th className="is-border input-text grey">{item.name}</th>
            ))}
            <th className="is-border input-text grey">Łączna kwota netto</th>
          </thead>
          <tbody>
            <tr>
              {approvalFormState.data?.fee.map((item) => (
                <td className="is-border input-text">{numToCurrency(item.sum)}</td>
              ))}
              <td className="is-border primary-blue text-32" style={{ minWidth: '400px' }}>{numToCurrency(approvalFormState.data?.fee.reduce((acc, item) => { return acc + item.sum }, 0))}</td>
              <td>
                <Button
                  width={220}
                  title="Przelicz"
                  onClick={calculate}
                  color="primary"
                  className="mr-5"
                  inline={true}
                />
              </td >
            </tr>
          </tbody>
        </table>
        {token && <p className="mt-30 mb-0">Kliknij: “Zatwierdź” i wyślij masy do Organizacji Odzysku.</p>}
        {year && <p className="mt-30 mb-0">Jeśli dodałeś już wszystkie importowane pojazdy w {approvalFormState.data?.year} roku kliknij: “Zatwierdź ” i wyślij masy do Organizacji Odzysku.</p>}

      </div>
      <FormButtons>
      <CheckFormField
          formMethods={formMethods}
          cbxLabel={"Jeśli dodałeś już wszystkie importowane pojazdy w " + approvalFormState.data?.year + " roku lub jeżeli nie importowałeś pojazdów kliknij: “Zatwierdź” i wyślij deklarację do Organizacji Odzysku."}
          name="is_vehicles_add_finished"
          value={true}
          isFlag={true}
          required={true}
          className="mb-10"
        />
        <FormButton
          width={220}
          color="primary"
          onClick={formMethods.handleSubmit(submitForm)}
          title="Zatwierdź"
          inline={true}
          disabled={needCalculate || !formMethods.getValues('is_vehicles_add_finished')}
        />
      </FormButtons>
    </FormProvider >
  );
};
SoOrderApprovalFrom.whyDidYouRender = true;
// export default SoOrderApprovalFrom;

export default SimpleLayout(withFormLayout(SoOrderApprovalFrom));