import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/buttons/button";
import { Link } from "react-router-dom";
import BlockIcon from "@material-ui/icons/Block";
import { fetchNetworkAssurance } from "actions/agreements";
import { IActionState, ActionResponse } from "types/async-types";
import Help from "@material-ui/icons/HelpOutline";
import { IAgreement } from "types/agreement-types";
import { useAsyncAction } from "utils/async-action";
import TextField from "components/form-fields/formik-text-field";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import Spinner from "components/core/spinner";
import "styles/pages/bdo-key.scss";
import {
  fetchOSOP1Price,
  fetchOSOP1Reports,
  deleteOSOP1,
  fetchOSOP1Link,
} from "actions/BDO-reports";
import { PAGE } from "paths";
import { IOSOP1Report, OSOP1ReportListing } from "types/BDO-report-types";
import { formatDateTime } from "utils/date";
import { ConfirmModal } from "components/common/confirm-modal";
import { numToCurrency, renderBreadcrumbs } from "utils/helpers";
import { IPrice } from "types/common-types";
import Price from 'components/common/price'; 
 
const NetworkProvision = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const [OSOP1ReportsState, execFetchOSOP1Reports]: readonly [
    IActionState<OSOP1ReportListing>,
    typeof fetchOSOP1Reports
  ] = useAsyncAction(fetchOSOP1Reports);

  const [OSOP1LinkStatus, execFetchOSOP1Link]: readonly [
    IActionState<{ file_name: string; url: string }>,
    typeof fetchOSOP1Link
  ] = useAsyncAction(fetchOSOP1Link);

  const [priceState, execFetchPrice]: readonly [
    IActionState<IPrice>,
    typeof fetchOSOP1Price
  ] = useAsyncAction(fetchOSOP1Price);

  const [deleteState, execDelete]: readonly [
    IActionState<any>,
    typeof deleteOSOP1
  ] = useAsyncAction(deleteOSOP1);

  useEffect(() => {
    execFetchPrice();
    execFetchOSOP1Reports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openLink = async (id: string) => {
    const res:
      | ActionResponse<{ file_name: string; url: string }>
      | undefined = await execFetchOSOP1Link(id);
    if (res && (res.status === 200 || res.status === 201)) {
      window.open(res.data.url, "_blank");
    }
  };

  const deleteReport = async (id: string) => {
    ConfirmModal({
      action: async () => {
        await execDelete(id);
        await execFetchOSOP1Reports();
      },
      header: "Usuwanie raportu",
      text: "Czy chcesz usunąć raport?",
      yesText: "Tak",
      noText: "Nie",
      btnColor: "primary",
    });
  };

  const renderReports = () => {
    if (OSOP1ReportsState.data) {
      return OSOP1ReportsState.data.list.map((item, index) => {
        return (
          <tr>
            <td>{item.created_at_string}</td>
            <td className="black t-500">{item.full_company_name}</td>
            <td className="black t-500">{item.status_string} </td>
            <td className="t-500 report-actions">
              {item.status === "IN_PROGRESS" && item.step === 0 && (
                <Link
                  className="mr-5"
                  to={`${PAGE.OS_OP1_STEP_1}?id=${item.id}`}
                  target="_blank"
                >
                  Kontynuuj
                </Link>
              )}
              {item.status === "IN_PROGRESS" && item.step === 1 && (
                <Link
                  className="mr-5"
                  to={`${PAGE.OS_OP1_STEP_2}?id=${item.id}`}
                  target="_blank"
                >
                  Kontynuuj
                </Link>
              )}
              {item.status === "IN_PROGRESS" && item.step === 2 && (
                <Link
                  className="mr-5"
                  to={`${PAGE.OS_OP1_STEP_3}?id=${item.id}`}
                  target="_blank"
                >
                  Kontynuuj
                </Link>
              )}
              {item.status === "IN_PROGRESS" && (
                <span className="link" onClick={() => deleteReport(item.id)}>
                  Usuń
                </span>
              )}
              {/* {item.status === "FINISHED" && (
                <span className="link" onClick={() => openLink(item.id)}>
                  Pobierz
                </span>
              )} */}
            </td>
          </tr>
        );
      });
    }
  };

  //   const onSubmit = (values: any) => {};

  return (
    <div className="sider-page network-provision">
      {(priceState.loading ||
        OSOP1ReportsState.loading ||
        deleteState.loading) && <Spinner size="large" />}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Usługi BDO", "Sprawozdanie OŚ-OP1"])}
          {siderCollapsed && (
            <div className="help-icon">
              {" "}
              <Help onClick={() => toggleSider()} />{" "}
            </div>
          )}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Sprawozdanie OŚ-OP1</h1>
        </header>

        <div className="block">
          <h1 className="green flex">
            <CheckCircle /> Sprawozdanie OŚ-OP1
          </h1>

          <h2 className="mb-15 black">Jak uzyskać sprawozdanie OŚ-OP1 ?</h2>
          <ol className="request-order mb-20">
            <li>
              <div>
                <label>Wypełnij wniosek</label>
              </div>
            </li>
            <li>
              <div>
                <label>Opłać fakturę pro-forma.</label>
              </div>
            </li>
            <li>
              <div>
                <label>
                  Po zaksięgowaniu wpłaty wyślemy Ci gotowy druk wniosku (wraz z
                  instrukcją złożenia) i fakturę VAT
                </label>
                <p className="mb-5">
                  Jeśli chcesz przyspieszyć proces, wyślij potwierdzenie wypłaty
                  na adres{" "}
                  <a href="mailto:biuro@integrator.org.pl?subject=Potwierdzenie wpłaty za OS-OP1">
                    biuro@integrator.org.pl
                  </a>
                </p>
              </div>
            </li>
            <li>
              <div>
                <label>Wniosek złóż w BDO</label>
                <p>
                  Złóż wniosek przez{" "}
                  <a
                    target="_blank"
                    href="https://rejestr-bdo.mos.gov.pl"
                    rel="noreferrer"
                  >
                    moduł elektroniczny BDO
                  </a>
                </p>
              </div>
            </li> 
          </ol>

          {priceState.data?.price && (
               <Price
               price={priceState.data?.price.netto}
               suffix="netto za rok sprawozdawczy"
             />
          )}

          <h2 className="mb-15 black">Wypełnione wnioski</h2>
          <table className="simple-table md-t mb-30">
            <tbody>{renderReports()}</tbody>
          </table>

          <Button
            width={250}
            title="wypełnij wniosek"
            inline={true}
            color="primary"
            onClick={() =>
              window.open(
                PAGE.OS_OP1_STEP_1,
                "_blank",
                "toolbar=0,location=0,menubar=0"
              )
            }
          />
        </div>

        {/* <div className="block">
          <h1 className="red flex">
            <BlockIcon /> Brak klucza API (BDO)
          </h1>
          <Formik initialValues={{ bdo_number: "" }} onSubmit={onSubmit}>
            {(formikProps: FormikProps<{ bdo_number: string }>) => (
              <Row className="flex mb-20">
                <Col md={18}>
                  <TextField name="bdo_number" label="Mój klucz API (BDO)" />
                </Col>
                <Col md={6}>
                  <Button title="Zapisz" color="white" />
                </Col>
              </Row>
            )}
          </Formik>
          <p className="black mb-30">
            Pobierz klucz zgodnie z załączoną instrukcją i wklej do pola powyżej
          </p>
        </div> */}
      </main>
      {!siderCollapsed && (
        <div
          className="sider"
          // collapsedWidth="0"
        >
          <div className="sm-l-t mb-20">
            Sprawozdanie OŚ-OP1
            <CloseIcon className="sider-close" onClick={toggleSider} />
          </div>
          <h2 className="mb-15 black">Czym są sprawozdania OŚ-OP1?</h2>
          <p className="black mb-0">
          Jest to roczne sprawozdanie o wysokości należnej opłaty produktowej OŚ-OP1 i o produktach w opakowaniach, opakowaniach i o gospodarowaniu odpadami opakowaniowymi. <br/>
          Przedsiębiorca:
          </p>
          <ul className="bullet-list black">
            <li>wprowadzający opakowania,</li>
            <li>eksportujący opakowania,</li>
            <li>eksportujący produkty w opakowaniach,</li>
            <li>dokonujący wewnątrzwspólnotowej dostawy produktów w opakowaniach,</li>
            <li>wprowadzający produkty w opakowaniach</li>
          </ul>
          <p className="black">
          jest obowiązany sporządzić i złożyć je odpowiedniemu dla swojej działalności marszałkowi województwa.
          </p>
          <p className="black">
          W ramach naszych usług mamy przygotowany formularz zbierający dane do sprawozdania oraz obliczający opłatę produktową.
          </p>
        </div>
      )}
    </div>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(NetworkProvision));
// export default Index;
