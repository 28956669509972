import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import SimpleLayout from "components/layout/simple-layout";
import WarningIcon from "@material-ui/icons/Warning";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from '@material-ui/icons/Check';
import Button from 'components/buttons/button';
import "styles/components/bdo-report/bdo-report-popup.scss";

const BDOKeyInstruction = (props: any) => {
  return (
    <div className="bdo-report-popup">
            <iframe title="instruction" className="bdo-instruction" src="/static/docs/instrukcja.pdf#toolbar=0&navpanes=0">

            </iframe>
        
    </div>
  );
};

export default SimpleLayout(BDOKeyInstruction);
