import React, { useEffect } from "react";
import { Formik, FormikProps } from "formik";
import FormikTextField from "components/form-fields/formik-text-field";
import FormButton from "components/buttons/form-button";
import Spinner from "components/core/spinner";
import * as Yup from "yup";
import { yupRequired, email } from "validators";
import "styles/pages/login.scss";
import { changePassword, IChangePassword } from "actions/user";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import BlockIcon from "@material-ui/icons/Block";
import { useHistory } from "react-router-dom";
import { PAGE } from "paths";

export interface IPasswordFormValues {
  username: string;
  email: string;
}

const PasswordResetForm = () => {
  const history = useHistory();
  //  props & hooks

  const [passwordState, execSubmit]: readonly [
    IActionState<any>,
    typeof changePassword
  ] = useAsyncAction(changePassword, { customError: true });

  const onSubmit = (values: IPasswordFormValues) => {
    execSubmit(values);
  };

  useEffect(() => {
    if (passwordState.data) {
      history.push(PAGE.EMAIL_SENT);
    }
  }, [passwordState.data]);

  // form fields data
  const fields = {
    email: {
      name: "email",
      type: "email",
      label: "Adres email",
      required: true,
    },
  };

  // Formik setup props
  const initialValues: any = {
    username: "",
    email: "",
  };
  let validationSchema = Yup.object().shape({
    username: Yup.string().concat(yupRequired),
    email: Yup.string().concat(yupRequired).concat(email),
  });
  
  return (
    <div>
      {passwordState.loading && <Spinner />}
      <Formik
        autoComplete="off"
        className="login-form"
        validationSchema={validationSchema}
        isInitialValid={false}
        initialValues={initialValues}
        onSubmit={onSubmit}
        // onSubmit={codeSent ? logIn : getCode}
      >
        {(formikProps: FormikProps<IPasswordFormValues>) => {
          return (
            <div>
              <h2 className="primary text-32">Wygeneruj nowe hasło</h2>
              {passwordState.error && (
                <div className="error-message flex-left">
                  <div className="icon red">
                    <BlockIcon fontSize="large" />
                  </div>
                  <div>
                    <div className="lg-t bold red">Błąd</div>
                    <p className="mb-0">
                      {passwordState.response?.data?.error_message}
                    </p>
                  </div>
                </div>
              )}
              <div className="mb-30">
                <FormikTextField
                  name="username"
                  label="Nazwa użytkownika (NIP firmy)"
                />
                <FormikTextField {...fields.email} type="email" />
              </div>
              <FormButton
                disabled={!formikProps.isValid}
                title="Wyślij zgłoszenie wygenerowania hasła"
                color="primary"
                onClick={formikProps.submitForm}
              />
            </div>
          );
        }}
      </Formik>
    </div>
  );
};

export default PasswordResetForm;
