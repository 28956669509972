import React, { useEffect } from "react";
import Row from "antd/lib/row";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";

//@ts-ignore
import "styles/pages/home.scss";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import Col from "antd/lib/col";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import Message from "components/common/message";
import { IActionState, IReduxActionState } from "types/async-types";
import { useHistory } from "react-router-dom";
import WasteAdministration from "components/home/customer/waste-administration"; 
import BDOReport from "components/home/customer/bdo-report-tile";
import VehicleStats from "components/home/admin/admin-vehicles-tile";
import Products from "components/home/customer/products-tile";
import Payments from "components/home/customer/payments-tile";
import NetworkProvision from "components/home/customer/network-provision-tile";
import {
  IRecycleServices,
  IWasteAdmin,
  IProduct,
  IBDOServices,
  IPayments,
} from "types/dashboard-types";
import {
  fetchRecyclingServices,
  fetchWasteAdministration,
  fetchPayments,
  fetchBDOServices,
} from "actions/dashboard";
import { useAsyncAction, useReduxAction } from "utils/async-action";
import { formatDateTime } from "utils/date";
import Spinner from "components/core/spinner";
import { getIntegrationNotice, fetchBDOKeys } from "actions/BDO-key";
import { IBDOKey, BDOKeysListing } from "types/BDO-keys-types";
import { checkIntegrationNotice } from "actions/BDO-reports";
import { numToCurrency } from "utils/helpers";
import { IUser } from "types/user-types";
import cookie from 'js-cookie';

const CustomerDashboard = () => {
  const history = useHistory();
  const [getIntegrationNoticeState, execGetIntegrationNotice]: readonly [
    IActionState<{ show_integration_notice: boolean }>,
    typeof checkIntegrationNotice
  ] = useAsyncAction(checkIntegrationNotice);

  const [recycleServicesState, execFetchRecycleServices]: readonly [
    IActionState<IRecycleServices>,
    typeof fetchRecyclingServices
  ] = useAsyncAction(fetchRecyclingServices);

  const [wasteAdminState, execFetchWasteAdministration]: readonly [
    IActionState<IWasteAdmin>,
    typeof fetchWasteAdministration
  ] = useAsyncAction(fetchWasteAdministration);

  const user: IUser = useSelector((state: any) => state.user);
  const ballance: number | undefined = user.ballance;
  const [paymentsState, execFetchPayments]: readonly [
    any,
    any
  ] = useReduxAction(fetchPayments, "user");

  useEffect(() => {
    execGetIntegrationNotice();
  }, []);

  useEffect(() => {
    if (getIntegrationNoticeState.data) {
      const integrationClosed = cookie.get('disable_popup');
      if (
        getIntegrationNoticeState.data.show_integration_notice &&
        integrationClosed !== "true"
      ) {
        history.push(PAGE.BDO_INFO);
      }
    }
  }, [getIntegrationNoticeState.data]);

  // const renderInvoices = () => {
  //   if (paymentsState.data) {
  //     return paymentsState.data.data.accounting_documents.map((item, index) => {
  //       return (
  //         <div key={index + "_document"} className="flex check-list__element">
  //           <span className="md-t grey mr-10">{item.payment_due_date}</span>{" "}
  //           <span className="md-t t-500 black">{item.number}</span>
  //           <span className="grow-1 green t-right">{item.brutto} zł</span>
  //         </div>
  //       );
  //     });
  //   }
  // };
  if (
    recycleServicesState.loading ||
    wasteAdminState.loading ||
    paymentsState.loading
  ) {
    return <Spinner size="large" />;
  }
  return (
    <>
      <div>

        {/* <Message
            actions={
              <div>
                <Button
                  color="primary"
                  inline={true}
                  width={200}
                  link={PAGE.BDO_KEY}
                  className="mr-10"
                  title="Rozpocznij integrację"
                />
                <Button
                  color="white"
                  inline={true}
                  width={200}
                  title="Więcej informacji"
                />
              </div>
            }
            type="alert"
            title={<h2 className="flex"><div className="mr-5">Sprawozdanie roczne BDO - nowa forma usługi! </div> <div><span className="recommended">Nowość</span></div></h2>}
            text="Zapraszamy do korzystania z nowej usługi automatycznego składania rocznych sprawozdań do systemu BDO. Aby uruchomić usługę zapisz numer BDO, klucz ABI (BDO) i zaakceptuj warunki. Od tej pory generowane sprawozdania będzie można przekazać automatycznie do systemu BDO."
          /> */}


        <Row type="flex" gutter={[20, 20]}>
          <Col span={12}>
            <VehicleStats />
          </Col>

        </Row>
      </div>
    </>
  );
};
// export default withLayout(Index);
export default CustomerDashboard;
// export default Index;
