import { isNIPCorrectHook, isREGONCorrectHook } from "validators";
import { Col } from "antd";
import React from "react";

export const companyFields = {
  nip: {
    label: "NIP firmy",
    name : "nip",
    maxlength: 13,
    required: true,
    rules: {
      required: "Pole jest wymagane",
      validate: isNIPCorrectHook,
    },
  },
  regon: {
    label: "REGON",
    name: "regon",
    required: true,
    rules: {
      required: "Pole jest wymagane",
      validate: isREGONCorrectHook,
    },
  },

  eu_nip: {
    label: "NIP europejski",
    name: "eu_nip",
    required: true,

    rules: {
      required: "Pole jest wymagane",
    },
  },

  legal_form: {
    label: "Forma prawna",
    name: "legal_form",
    options : [],
  },
};
