import React from "react";
import Select from "antd/lib/select";
import _ from "lodash";

type InitialOptions = { [key: string]: string } | Array<string> | Array<number>;
export type IExtOptions = {
  [key: string]: { description: string; label: string };
};
type KeyValues = { [key: string]: string };

type Options = Array<IOption>;
interface IOption {
  label: string;
  value: string;
}

export const renderOptions = (data: InitialOptions) => {
  if (!data) {
    return [];
  }

  if (!Array.isArray(data)) {
    const result = _.map(data).map((item, index) => {
      return (
        <Select.Option key={index} value={item}>
          {item}
        </Select.Option>
      );
    });
    return result;
  }

  const array: string[] = [];
  data.forEach((item: any, index: number) => {
    array.push(item.toString());
  });

  return array.map((item, index) => {
    return (
      <Select.Option key={index} value={item}>
        {item}
      </Select.Option>
    );
  });
};

export const renderJsonOptions = (data: InitialOptions) => {
  if (!data) {
    return [];
  }

  if (!Array.isArray(data)) {
    const result = Object.keys(data).map((key, index) => {
      return { label: data[key], value: key };
    });

    return result;
  }

  const array: string[] = [];
  data.forEach((item: any, index: number) => {
    array.push(item.toString());
  });

  return array.map((item, index) => {
    return { label: item, value: item };
  });
};

// export const renderExtendedCheckOpts = (data: IExtOptions) => {

//   const result = Object.keys(data).map((item, index) => {
//     return {
//       value: item,
//       cbxLabel: data[item].label,
//       cbxDetails : data[item].description,
//       name: name || "",
//     };
//   });
//   return result;
// }

export const getYears = () => {
  const year = new Date().getFullYear();
  return Array.from({ length: year - 1900 }, (value, index) =>
    (1901 + index).toString()
  ).reverse();
};

export const optsToKeyValues = (opts: Options) => {
  const keyVals: KeyValues = {};
  opts.forEach((item, index) => {
    keyVals[item.value] = item.label;
  });
  return keyVals;
};

export const renderCheckOpts = (
  opts: { [key: string]: string },
  name?: string
) => {
  const result = Object.keys(opts).map((item, index) => {
    return {
      value: item,
      cbxLabel: opts[item],
      name: name || "",
    };
  });
  return result;
};

export const renderRangeOpts = (from: number, to: number) => {
  const result = [];
  for (let i = from; i <= to; i++) {
    result.push({
      value: i.toString(),
      label: i.toString(),
    });
  }
  return result;
};
