import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Modal from "react-modal";
import SpinnerPopup from "components/core/spinner";
import { IReportList } from "types/report-types";
import { useAsyncAction } from "utils/async-action";
import { getReportList} from "actions/report";
import { IUser } from "types/user-types";
import { IActionState } from "types/async-types";
import _ from "lodash";
import CloseIcon from "@material-ui/icons/Close";

const ReportListModal = (props: { modalIsOpen: boolean; closeModal: () => void; selectReport: (year: string) => void}) => {
  const user: IUser = useSelector((state: any) => state.user);
  const {
    modalIsOpen,
    closeModal,
    selectReport
  } = props;
  
  const [reportListState, execGetReportList]: readonly [
    IActionState<IReportList>,
    typeof getReportList
  ] = useAsyncAction(getReportList);

  useEffect( () => {
    if(modalIsOpen) {
      execGetReportList();
    }
  }, [modalIsOpen])
  
  const renderList = () => {
    return reportListState.data?.reports?.map ( (report) => {
      return (<tr className="row-summary t-500 black">
         <td >{report.year}</td>
         <td >{report.date}</td>
         <td className="t-right">
           <a onClick={ () => { selectReport(report.year) } }> Zamów </a>
         </td>
       </tr>)
    })
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "1100px",
      height: "80%",
    },
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => {
        closeModal();
      }}
      style={customStyles}
    >
      {reportListState.loading ? (
        <SpinnerPopup />
      ) : (
        <div >          
          <div className="t-right">
          <CloseIcon
            className="sider-close"
            onClick={closeModal}
          />
          </div>
          <h2 className="mb-10">Lista sprawozdań BDO

          </h2>
          {/* <div className={styles["report-container"]}>{renderReport()}</div> */}

          {/* <p className="t-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
          </p> */}
          
          
          <table className="ant-table waste-table mb-20" cellSpacing="1">
            <thead className="ant-table-thead">
              <tr>
                <th className="text-left">Rok</th>
                <th className="text-left">Data zamówienia </th>
                <th ></th>
              </tr>
            </thead>
            <tbody>
              
              { renderList() }
             
              </tbody>
            </table>
        </div>
      )}
    </Modal>
  );
};


export default ReportListModal;
