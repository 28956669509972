import React, { useState, useEffect } from "react";
import Table from "antd/lib/table";
import TableWrapper from "components/common/table-wrapper";
import {
  IAccountingDocument,
  ISellerDocumentsDebt,
} from "types/document-types";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import { numToCurrency } from "utils/helpers";
import { createBDOPayment } from "actions/BDO-reports";
import { useHistory } from "react-router-dom";
import Spinner from 'components/core/spinner';
import { DocumentColumns } from "./document-columns";
import { useTable } from "utils/hooks/useTable";
import { useLocalFile } from "../useLocalFile";

export function compareUnpaid(a: IAccountingDocument, b: IAccountingDocument) {
  if (a.draw_date < b.draw_date) {
    return -1;
  }
  if (a.draw_date > b.draw_date) {
    return 1;
  }
  return 0;
}

export function comparePaid(a: IAccountingDocument, b: IAccountingDocument) {
  if (a.draw_date > b.draw_date) {
    return -1;
  }
  if (a.draw_date < b.draw_date) {
    return 1;
  }
  return 0;
}

const OverdueInvoices = (props: { invoicesState?: ISellerDocumentsDebt[] }) => {
  const id = new URLSearchParams(window.location.search).get("id");
  const {downloadFile} = useTable();
  const {downloadLocalFile} = useLocalFile(id);
  const history = useHistory();
  const { invoicesState } = props;
  const [tableData, setTableData] = useState<any>({});

  


  const [paymentState, execCreatePayment]: readonly [
    IActionState<{ id: string; payment_address: string }>,
    typeof createBDOPayment
  ] = useAsyncAction(createBDOPayment);


  useEffect(() => {
    setTableData(getTableData());
  }, []);

  const submitPayment = (sellerId: string) => {
    if (id && sellerId) {
      execCreatePayment(id, sellerId);
    }
  };

  useEffect(() => {
    if (paymentState.data) {
      // history.push(
      //   `${PAGE.BDO_REPORT_STEP_2_PAYMENT_STATUS}?id=${id}&payment_id=${paymentState.data.id}`
      // );
      window.location.href = paymentState.data?.payment_address;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentState.data]);

  const expandedRowRender = (parent: ISellerDocumentsDebt) => {
    return (
      <div className="p-10">
        <Table
        
          className="custom-ant-table"
          columns={DocumentColumns( downloadFile, downloadLocalFile)}
          dataSource={parent.items}
          pagination={false}
        />{" "}
      </div>
    );
  };

  const renderColumns = () => {
    return [
      {
        title: "Sprzedawca",
        align: "left",
        width: 100,
        render: (field: any, row: ISellerDocumentsDebt) => (
          <div className="no-wrap">{row.seller_string}</div>
        ),
      },
      {
        title: "Łączna kwota",
        align: "left",
        width: 140,
        render: (field: any, row: ISellerDocumentsDebt) => (
          <div
            className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}
          >
            {numToCurrency(row.brutto)}
          </div>
        ),
      },
      {
        align: "right",
        width: 120,
        render: (field: any, row: ISellerDocumentsDebt) => {
          return (
            <>
              {row.status && <div className="grey">{row.status}</div>}
              {row.online_payment && (
                <div>
                  <Button
                    width={170}
                    title="Przelew on-line"
                    color="primary"
                    link={`${PAGE.BDO_REPORT_STEP_2_PAYMENT_STATUS}?id=${id}`}
                    inline={true}
                    className="mb-5"
                    onClick={() => submitPayment(row.seller_id)}
                  />
                </div>
              )}
              {row.transfer_payment && (
                <div>
                  <Button
                    width={170}
                    title="Przelew tradycyjny"
                    color="white"
                    inline={true}
                    link={`${PAGE.BDO_REPORT_STEP_2_SUMMARY}?id=${id}&seller_id=${row.seller_id}`}
                  />
                </div>
              )}
            </>
          );
        },
      },
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };

  return (
    <>
      {paymentState.loading && <Spinner size="large" />}
      <TableWrapper>
        {/* @ts-ignore */}
        <Table
        expandedRowKeys={Array.from(Array(invoicesState?.length).keys())}
          size="middle"
          locale={{ emptyText: "Brak opłat do wyświetlenia" }}
          columns={tableData.columns}
          bordered={false}
          dataSource={invoicesState}
          pagination={false}
          loading={tableData.loading}
          className={`custom-ant-table`}
          expandedRowRender={expandedRowRender}
        />
      </TableWrapper>
    </>
  );
};

export default OverdueInvoices;
