import React, { useState, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import CloseIcon from "@material-ui/icons/Close";
import Button from "components/buttons/button";
import BlockIcon from "@material-ui/icons/Block";
import Badge from "components/common/badge";
import Help from "@material-ui/icons/HelpOutline";
import "styles/components/services/network-provision.scss";
import { fetchNetworkAssurance } from "actions/agreements";
import { IActionState } from "types/async-types";
import { IAgreement } from "types/agreement-types";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { IDashboardProduct } from "types/dashboard-types";
import ProductsList from "components/home/services-list";
import { numToCurrency, renderBreadcrumbs } from "utils/helpers";
import Price from "components/common/price";

const NetworkProvision = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  const [networkAssurance, execFetchNetworkAssurance]: readonly [
    IActionState<IDashboardProduct>,
    typeof fetchNetworkAssurance
  ] = useAsyncAction(fetchNetworkAssurance);

  useEffect(() => {
    execFetchNetworkAssurance();
  }, []);

  if (!networkAssurance.data || networkAssurance.loading) {
    return <Spinner size="large" />;
  }
  // const agreement = networkAssurance.data[0]?.agreement;
  return (
    <div className="sider-page network-provision">
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Usługi recyklingowe", "Zapewnienie sieci"])}
          {siderCollapsed && (
            <div className="help-icon">
              {" "}
              <Help onClick={() => toggleSider()} />{" "}
            </div>
          )}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Zapewnienie sieci</h1>
        </header>

        {networkAssurance.data.product_available ? (
          <div className="block">
            <h1 className="green flex">
              <CheckCircle className="status-icon" /> Sieć zapewniona!
            </h1>
            {/* <p className="md-t black">
              Wszystkie pojazdy dodane przez ciebie mają zapewnioną sieć!
              Dodawaj pojazdy na bieżąco aby uniknąć opóźnień i kar za
              nieterminowe rozliczanie pojazdów.
            </p> */}
            <ProductsList products={networkAssurance.data?.products} />
            {/* <p className="flex">
              <div className="grow-1">
                Umowa ważna od: <span className="md-b-t bold">{agreement?.start_date} </span>{" "}
              do: <span className="md-b-t bold">bezterminowo</span>
              </div>
              <Button
                title="Podgląd umowy"
                color="white"
                inline={true}
                width={170}
              />
            </p> */}
          </div>
        ) : (
          <div className="block">
            <h1 className="red flex">
              <BlockIcon /> Sieć niezapewniona
            </h1>
            <p className="md-t black">
              W systemie Integrator nie masz umowy na zapewnienie sieci
              zbierania pojazdów. Możesz ją uzyskać w kilka minut.
            </p>
            <h2 className="black mb-15">Jak zawrzeć umowę?</h2>
            <ol className="request-order ">
              <li>
                {/* <Badge badgeContent={1} /> */}
                <div>
                  <label>
                    Pobierz <span className="t-400">umowę</span>
                  </label>
                </div>
              </li>
              <li>
                {/* <Badge badgeContent={2} /> */}
                <div>
                  <label>
                    Wydrukuj{" "}
                    <span className="t-400">w dwóch egzemplarzach</span>
                  </label>
                </div>
              </li>
              <li>
                {/* <Badge badgeContent={3} /> */}
                <div>
                  <label className="mb-5">Uzupełnij dane</label>
                </div>
              </li>
              <li>
                {/* <Badge badgeContent={4} /> */}
                <div>
                  <label>
                    <span className="t-400"> Podpisz, opieczętuj i</span> wyślij
                    <span className="t-400"> obie kopie na adres </span>{" "}
                  </label>
                </div>
                <div className="md-t grey mb-20 mt-10">
                  <p className="mb-0 mt-0">Integrator sp z o.o. </p>
                  <p className="mb-0 mt-0">
                    ul. Powstańców Listopadowych 29E/8{" "}
                  </p>
                  <p className="mb-0 mt-0">35-606 Rzeszów</p>
                </div>
              </li>
            </ol>
            <Price
              price={networkAssurance.data?.product_price }
              suffix="za pojazd (netto)"
            />

            {networkAssurance.data.agreement_button && (
              <Button
                newCard={true}
                outerLink={true}
                width={200}
                title="Pobierz umowę"
                color="primary"
                link={networkAssurance.data.agreement_url}
              />
            )}
          </div>
        )}
      </main>
      {!siderCollapsed && (
        <div
          className="sider"
          // collapsedWidth="0"
        >
          <div className="sm-l-t mb-20">
            Zapewnienie sieci
            <CloseIcon className="sider-close" onClick={toggleSider} />
          </div>
          <h2 className="mb-15 black">Czym jest zapewnienie sieci?</h2>
          <p className="black mb-20">
            Zapewnienie sieci jest to obowiązek dotyczący wszystkich importerów
            pojazdów. Wprowadzający nie więcej niż 1000 pojazdów muszą zapewnić
            sieć obejmującą trzy punkty zbierania pojazdów, w tym przynajmniej
            jedną stację demontażu położone w różnych miejscowościach na
            terytorium Polski. Co ważne, obowiązek ten ciąży na przedsiębiorcy
            nawet w chwili, gdy obecnie nie sprowadza pojazdów.
          </p>
          <h2 className="mb-15 black">Dlaczego warto?</h2>
          <p className="black">
            Przedsiębiorca posiadający kod PKD 4511.Z, zgodnie z ustawa z dnia
            20 stycznia 2005 roku o recyklingu pojazdów wycofanych z
            eksploatacji (tj. Dz. U. z 2019 roku, poz. 1610 ze zm.) jest
            zobowiązany do posiadania umowy zapewniającej sieć recyklingu
            pojazdów. Ma również obowiązek sporządzania rocznych sprawozdań w
            systemie BDO. Ci, którzy nie spełnią obowiązku będą zmuszeni do
            uiszczenia opłaty za brak sieci zbierania pojazdów.
          </p>
        </div>
      )}
    </div>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(NetworkProvision));
// export default Index;
