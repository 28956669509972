import React, { useEffect, useState, useRef } from "react";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { Formik, FormikProps } from "formik";
import { PAGE } from "paths";
import * as Yup from "yup";
import FormButton from "components/buttons/form-button";
import { WSText } from "./ws-text";

import CheckFormField from "components/form-fields/check-form-field";
import { useHistory } from "react-router-dom";
import { IActionState, IAsyncState } from "types/async-types";

import {
  fetchBDOWSFormData,
  fetchBDOWSFile,
  canAccept,
  acceptWS,
} from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";
import { IBDOWSFormData, IBDOWSAccepsFormValues } from "types/BDO-report-types";
import Spinner from "components/core/spinner";
import { yupRequired, isPhoneCorrect, isEmailCorrect } from "validators";
import { Row, Col } from "antd";
import TextField from "components/form-fields/formik-text-field";
import { IText, renderTextWithLinks } from "utils/helpers";
import ErrorMsg from "components/common/error-message";

interface IProps {
  formData?: IBDOWSFormData;
}

const AcceptWS = (props: IProps) => {
  
  const formikRef: any = useRef();
  const formRef: FormikProps<IBDOWSAccepsFormValues> = formikRef.current;
  const [WSState, setWSState] = useState<IAsyncState<{ data: IBDOWSFormData }>>(
    {}
  );

  const [requiredVals, setRequiredVals] = useState<string[]>([]);

  const history = useHistory();
  const submitForm = async (values: IBDOWSAccepsFormValues) => {
    const res = await execAcceptWS({
      name: values.name,
      surname: values.surname,
      company_name: values.company_name,
      phone: values.phone,
      email: values.email,
      is_terms_accepted: values.is_terms_accepted,
      agreements: values.agreements.join("|"),
    });
    if (res.status === 200 || res.status === 201) {
      history.push(PAGE.STEP_3);
    }
  };

  const [BDOWSFormDataState, execFethBDOWSFormData]: readonly [
    IActionState<any>,
    typeof fetchBDOWSFormData
  ] = useAsyncAction(fetchBDOWSFormData);

  const [BDOWSFileState, execFetchBDOWSFile]: readonly [
    IActionState<{ data: { url: string } }>,
    typeof fetchBDOWSFile
  ] = useAsyncAction(fetchBDOWSFile);

  // const WSData = BDOWSFormDataState.data?.data.ws_content;

  const formData = BDOWSFormDataState.data?.data;

  const [canAcceptState, execCanAccept]: readonly [
    IActionState<{ data: { can_accept: boolean } }>,
    typeof canAccept
  ] = useAsyncAction(canAccept);

  const [acceptWSState, execAcceptWS]: readonly [
    IActionState<any>,
    typeof acceptWS
  ] = useAsyncAction(acceptWS);

  const validationSchema = Yup.object().shape({
    name: Yup.string().concat(yupRequired),
    surname: Yup.string().concat(yupRequired),
    company_name: Yup.string().concat(yupRequired),
    phone: Yup.string().concat(yupRequired),
    email: Yup.string().concat(yupRequired),
    is_terms_accepted: Yup.boolean()
      .oneOf([true], "Pole jest wymagane")
      .nullable(),
  });

  useEffect(() => {
    execFethBDOWSFormData();
    if (formRef) {
      setTimeout(() => formRef.validateForm());
    }
  }, []);

  useEffect(() => {
    if (BDOWSFormDataState.response?.status === 422) {
      history.push(PAGE.HOME);
    }
  }, [BDOWSFormDataState.response]);

  // useEffect(() => {
  //   fetchBDOWSFormData2(setWSState);
  //   if(formRef){
  //     setTimeout(() => formRef.validateForm())
  //   }

  // }, []);

  useEffect(() => {
    if (BDOWSFileState.data) {
      window.open(BDOWSFileState.data.data.url, "_blank");
      execCanAccept();
    }
  }, [BDOWSFileState.data]);

  useEffect(() => {
    const formikProps: FormikProps<IBDOWSAccepsFormValues> = formikRef.current;
    if (formData) {
      const { agreements, ...initialData } = formData;
      formikProps.setValues({
        ...formikProps.values,
        company_name: formData.company_name,
        is_terms_accepted: false,
        agreements: [],
      });
      if (formikProps) {
        setTimeout(() => formikProps.validateForm());
      }

      const requiredTerms: string[] = [];
      Object.keys(formData.agreements).map((key, index) => {
        if (formData.agreements[key].is_required) {
          requiredTerms.push(key);
        }
      });
      setRequiredVals(requiredTerms);
    }
  }, [formData]);

  // const Effects = () => {
  //   const formikProps = useFormikContext<IBDOWSAccepsFormValues>();
  //   useEffect(() => {
  //     if (formData) {
  //       const {agreements, ...initialData} = formData;
  //       formikProps.setValues({
  //         ...formData,
  //         is_terms_accepted: false,
  //         agreements : []
  //       });
  //       if (formikProps) {
  //         setTimeout(() => formikProps.validateForm());
  //       }
  //     }
  //   }, [formData]);
  //   return null;
  // };

  return (
    <div className="">
      <Formik
        innerRef={formikRef}
        isInitialValid={false}
        validationSchema={validationSchema}
        // initialValues={{ check_1: [], check_2: [], check_3: [] }}
        onSubmit={submitForm}
        initialValues={{
          name: "",
          surname: "",
          company_name: "",
          phone: "",
          email: "",
          is_terms_accepted: false,
          agreements: [],
        }}
        enableReinitialize={true}
      >
        {(formikProps: FormikProps<IBDOWSAccepsFormValues>) => {
          // return <APIKeyLoading />
 
          const renderCheckboxesSection = (
            checkboxes: {
              [key: string]: IText;
            },
            requiredVals: string[],
            fieldValue: string[],
            name?: string
          ) => {
            const validateCbx = (requiredVals: string[], values: string[]) => {
              if (requiredVals.every((v) => values.includes(v))) {
                return undefined;
              } else {
                return "Pole jest wymagane";
              }
            };

            return Object.keys(checkboxes).map((key, index) => {
              return (
                <div className="mb-10 relative">
                  <CheckFormField
                    required={checkboxes[key].is_required}
                    disableMessage={true}
                    // doubleRequired={
                    //   group == "pickup" ? checkboxes[key].is_required : false
                    // }
                    validate={
                      checkboxes[key].is_required
                        ? (value: string[]) => validateCbx(requiredVals, value)
                        : undefined
                    }
                    id={key}
                    value={key}
                    name="agreements"
                    cbxLabel={renderTextWithLinks(checkboxes[key])}
                  />
                  {!fieldValue.includes(key) && checkboxes[key].is_required && (
                    <ErrorMsg name="agreements" className="custom-cbx-error" />
                  )}
                </div>
              );
            });
          };

          return (
            <>
              {/* <Effects /> */}
              <div className="block">
                {(acceptWSState.loading ||
                  canAcceptState.loading ||
                  BDOWSFormDataState.loading ||
                  !BDOWSFormDataState.data) && (
                  <Spinner size="large" noTransparent={true} />
                )}
                {/* {WSData && (
                  <div className="mb-20 terms-conditions">
                    <h2 className="mb-30">
                      {" "}
                      WARUNKI SZCZEGÓLNE USŁUGI „SPRAWOZDANIE ROCZNE BDO” (dalej
                      WS)
                    </h2>
                    <div>{ReactHtmlParser(WSData)}</div>
                  </div>

                  
                )} */}

                <div className="mb-30 terms-conditions">
                  <div>
                    <WSText />
                  </div>
                </div>

                <Row type="flex" className="flex-left md-t" gutter={10}>
                  <Col className="mt-10 input-sentence" md={3}>
                    Oświadczam, że ja
                  </Col>
                  <Col md={6}>
                    <TextField required={true} name="name" label="Imię" />
                  </Col>
                  <Col md={6}>
                    <TextField
                      required={true}
                      name="surname"
                      label="Nazwisko"
                    />
                  </Col>
                  <Col className="mt-10 input-sentence" md={9}>
                    jestem upoważniony do zawierania umów w imieniu firmy:
                  </Col>
                </Row>
                <Row gutter={10}>
                  <Col md={3}></Col>
                  <Col md={21}>
                    <TextField
                      required={true}
                      name="company_name"
                      label="Nazwa firmy"
                      disabled={true}
                    />
                  </Col>
                </Row>
                <Row gutter={10} className="mb-20">
                  <Col md={3}></Col>
                  <Col md={11}>
                    <TextField
                      required={true}
                      type="text"
                      name="phone"
                      label="Telefon do powiadomień"
                      validate={isPhoneCorrect}
                    />
                  </Col>
                  <Col md={10}>
                    <TextField
                      required={true}
                      type="text"
                      name="email"
                      label="Email do powiadomień"
                      validate={isEmailCorrect}
                    />
                  </Col>
                </Row>

                <div className={`check-form-fields vertical mb-30`}>
                  <CheckFormField
                    required={true}
                    name="is_terms_accepted"
                    value={true}
                    isFlag={true}
                    cbxLabel={
                      <div>
                        Zapoznałem/-am się i akceptuję{" "}
                        <a
                          target="_blank"
                          href="https://integrator.org.pl/files/warunki_szczegolne_uslugi_sprawozdanie_roczne_bdo_2022_v1.pdf"
                          rel="noreferrer"
                        >
                          warunki szczególne usługi Sprawozdanie roczne do BDO{" "}
                        </a>{" "}
                      </div>
                    }
                  />
                  {formData &&
                    renderCheckboxesSection(
                      formData?.agreements,
                      requiredVals,
                      formikProps.values.agreements
                    )}
                </div>
                <div className="t-center">
                  <FormButton
                    width={250}
                    color="primary"
                    onClick={formikProps.submitForm}
                    title="Akceptuj warunki szczególne usługi"
                    // disabled={!formikProps.isValid}
                    className="i-b"
                  />
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default AcceptWS;
