import {
  AUTH_USER,
  SEND_EMAIL,
  ADD_TO_CART,
  UserActionTypes,
  FETCH_SESSION,
  FETCH_PAYMENTS,
  LOGOUT,
} from "types/redux-types";
import { ISessionData } from "types/session-types";
import { IPayments } from "types/dashboard-types";

export interface IPasswordInfoPayload {}

export interface IUserState {
  token?: string | null;
  message?: string | null;
  debug_token_preview?: string | null;
  session?: ISessionData | null;
  ballance?: number;
}

const initialState: IUserState = {
  token: null,
  message: null,
  debug_token_preview: null,
  session: null,
  ballance: 0,
};

export default function Reducer(
  state = initialState,
  action: UserActionTypes
): IUserState {
  switch (action.type) {
    case AUTH_USER:
      return action.payload.user;

    case LOGOUT:
      return {};
    case SEND_EMAIL:
      return action.payload.password_sent_info;
    case FETCH_SESSION:
      return { ...state, session: action.payload };
    case FETCH_PAYMENTS:
      return { ...state, ballance: action.payload.data.balance };
    default:
      return state;
  }
}
