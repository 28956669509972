import React, { useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import { ColumnProps } from "antd/lib/table";
import AntdTableWrapper from "components/common/antd-table-wrapper";
import { IActionState } from "types/async-types";
import Spinner from "components/core/spinner";
import { useAsyncAction } from "utils/async-action";
import Button from "components/buttons/button";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { useForm } from "react-hook-form";
import { IOSOP1Report, OSOP1ReportListing } from "types/BDO-report-types";
import { ConfirmModal as ConfirmAlert } from "components/common/confirm-modal";
import { formatOverdue, renderBreadcrumbs } from "utils/helpers";
import { Link } from "react-router-dom";
import { PAGE } from "paths";
import { LPColumn } from "utils/columns";
import { fetchAdminOSOP1Reports, deleteAdminOSOP1Report } from "actions/admin";

export interface IOSOP1FiltersFormValues {
  cbk_id?: string;
  date_from?: string;
  page?: number;
  date_to?: string;
}
const BDOKeys = () => {
  const [reportsState, execFetchReports]: readonly [
    IActionState<OSOP1ReportListing>,
    typeof fetchAdminOSOP1Reports
  ] = useAsyncAction(fetchAdminOSOP1Reports, { no_reset: true });

  const [deleteState, execDelete]: readonly [
    IActionState<any>,
    typeof deleteAdminOSOP1Report
  ] = useAsyncAction(deleteAdminOSOP1Report);

  const formMethods = useForm<IOSOP1FiltersFormValues>({
    mode: "onChange",
  });

  const setPage = (page: number) => {
    execFetchReports({ ...formMethods.getValues(), page: page });
  };

  useEffect(() => {
    if (deleteState.data) {
      execFetchReports(formMethods.getValues());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteState.data]);

  useEffect(() => {
    execFetchReports();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id: string) => {
    ConfirmAlert({
      action: () => execDelete(id),
      header: "Usuwanie wniosku",
      text: "Czy chcesz usunąć wniosek OŚ/OP1 ?",
      yesText: "Tak",
      noText: "Nie",
      btnColor: "primary",
    });
  };

  const tableData = {
    filtersCollapsed: true,
    loading: false,
    showHeader: false,

    columns: [
      LPColumn(reportsState.data?.items_from),
      {
        title: "Utworzono",

        render: (field: any, row: IOSOP1Report) => row.created_at_string,
      },
      {
        title: "CBK ID",

        render: (field: any, row: IOSOP1Report) => row.cbk_id,
      },
      {
        width: 150,
        title: "Sposób płatności",

        render: (field: any, row: IOSOP1Report) => (
          <div>{row.payment?.payment_type_string}</div>
        ),
      },
      {
        title: "Status",

        render: (field: any, row: IOSOP1Report) =>
          row.payment ? (
            <div>
              {formatOverdue(row.status_string, row.payment.is_overdue)}{" "}
            </div>
          ) : (
            row.status_string
          ),
      },
      {
        title: "Termin płatności",

        render: (field: any, row: IOSOP1Report) =>
          row.payment ? (
            <div>
              {formatOverdue(row.payment.due_date, row.payment.is_overdue)}{" "}
            </div>
          ) : (
            "-"
          ),
      },
      {
        title: "Operacje",

        render: (field: any, row: IOSOP1Report) => (
          <div>
            {/* <a target="_blank" href={row.download_url} className="mr-5">
              Pobierz
            </a>{" "} */}
            <Link
              className="mr-5"
              to={`${PAGE.ADMIN_OS_OP1_DETAILS}/${row.id}`}
            >
              Podgląd{" "}
            </Link>
          </div>
        ),
      },
      {
        align: "right",
        render: (field: any, row: IOSOP1Report) => {
          if (row.status === "IN_PROGRESS") {
            return (
              <div>
                <Button
                  inline={true}
                  title="Usuń"
                  color="white"
                  onClick={() => handleDelete(row.id)}
                />
              </div>
            );
          }
        },
      },
    ] as ColumnProps<IOSOP1Report>[],
  };

  const onSubmit = (values: IOSOP1FiltersFormValues) => {
    execFetchReports({ ...values, page: 1 });
  };

  return (
    <div className="sider-page">
      {(reportsState.loading || deleteState.loading) && (
        <Spinner size="large" />
      )}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Administrator", "Lista wniosków OŚ/OP1"])}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Lista wniosków OŚ/OP1</h1>
        </header>
        <div className="list-filters">
          <Row type="flex" align="middle" gutter={15}>
            <Col md={6}>
              <TextField
                name="cbk_id"
                formMethods={formMethods}
                label="CBK ID"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="date_from"
                type="date"
                formMethods={formMethods}
                label="Data utworzenia (od)"
              />
            </Col>
            <Col md={3}>
              <TextField
                name="date_to"
                type="date"
                formMethods={formMethods}
                label="Data utworzenia (do)"
              />
            </Col>
            <Col md={12} className="t-right">
              <Button
                title="Filtruj"
                color="primary"
                inline={true}
                width={150}
                onClick={formMethods.handleSubmit(onSubmit)}
                className="mr-5"
              />
              <Button
                title="Wyczyść"
                color="white"
                inline={true}
                width={150}
                onClick={() => {
                  formMethods.reset();
                  execFetchReports();
                }}
              />
            </Col>
          </Row>
        </div>
        <AntdTableWrapper
          setPage={setPage}
          data={reportsState.data}
          columns={tableData.columns}
          tableProps={{
            rowKey: "uuid",
            className: "custom-ant-table mb-10",
          }}
        />
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(BDOKeys));
