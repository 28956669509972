import { useCallback, useEffect, useRef } from "react";

export const useTimeout = (callback: any, delay: number, repeat = false) => {
  const callbackRef = useRef(callback);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  const clear = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }, []);

  const set = useCallback(() => {
    timeoutRef.current = setTimeout(() => callbackRef.current(), delay);
  }, [delay]);

  const setRepeat = useCallback(
    (fireCallback = false) => {
      // with fireCallback unset, we ommit first callback execution
      clear();
      if (fireCallback) {
        callbackRef.current();
      }
      timeoutRef.current = setTimeout(() => setRepeat(true), delay); // calls setRepeat recursively with 'true', so it will execute callback in next calls
    },
    [delay, clear]
  );

  useEffect(() => {
    repeat ? setRepeat() : set();
    return clear;
  }, [delay, set, clear, repeat, setRepeat]);

  const reset = useCallback(() => {
    clear();
    repeat ? setRepeat() : set();
  }, [set, clear, repeat, setRepeat]);

  return { reset, clear, setRepeat };
};
