import axios, { AxiosRequestConfig } from "axios";
import { WORKSHOP_API_URL, API_BASE_URL } from "config";
import { ActionResponse } from "types/async-types";
import { login, workshopRequestHeaders, requestHeaders } from "utils/auth";
import { IAddressFormValues } from "types/address";
// const ADDRESS_URL = `${WORKSHOP_API_URL}/addresses`;

const ADDRESS_URL = `${API_BASE_URL}/addresses`;




export const checkPostalCode = async (postalCode : string) => {
    const options : AxiosRequestConfig = {
      url: `${ADDRESS_URL}/check_postal_code?code=${postalCode}`,
      headers: {
        Authorization: requestHeaders().headers?.authorization,
      },
      method: "GET",
    };
    let res = null;
    res = await axios(options);
    return res ? res : undefined;
  };

  export const createAddress = async (data : IAddressFormValues) => {
    const options : AxiosRequestConfig = {
      url: `${ADDRESS_URL}`,
      headers: {
        Authorization: workshopRequestHeaders(),
      },
      method: "POST",
      data : data
    };
    
    let res : ActionResponse<any> = await axios(options);
    res = {
      ...res,
      successMessage : "Dodano nowy adres"
    }
    return res ? res : undefined;
  };

  export const fetchAddresses = async () => {
    const options : AxiosRequestConfig = {
      url: `${ADDRESS_URL}`,
      headers: {
        Authorization: workshopRequestHeaders(),
      },
      method: "GET",
    };
    
    let res : ActionResponse<any> = await axios(options);
    return res ? res : undefined;
  };