import React from 'react';
import { Field, ErrorMessage, FastField } from "formik";
import Form from "antd/lib/form";
import Select from "antd/lib/select";
import ErrorMsg from 'components/common/error-message';

// @ts-ignore
import "styles/components/input.scss";
import { getNested } from "utils/helpers";
import { ErrorLabel } from 'components/common/error-message';

const renderOpts = (opts: Array<{ label: string; value: string }>) => {
  const options = opts.map((opt, index) => (
    <Select.Option data-testid={`option_${index}`} key={index} value={opt.value}>{opt.label}</Select.Option>
  ));
  options.unshift(
    <Select.Option data-testid={`option_placeholder`} key="default" value="" disabled >
      Wybierz
    </Select.Option>
  );
  return options;
};

export interface ISelectProps {
  mode?: "multiple" | "tags";
  optFixedWidth?: boolean;
  defaultValue?: string;
  label: string;
  size?: string;
  customOptsRender?: any;
  name: string;
  onChange?: (e: any) => void;
  options: Array<{ label: string; value: string }>;
  placeholder?: string;
  disabled?: boolean;
  testId?: string;
  required?: boolean;
  type?: "select";
  value?: string;
  selectedValue?: string | boolean
}

export default function SelectFormField(props: ISelectProps) {
  //   const { field, label, size } = props;

  const {
    mode,
    optFixedWidth,
    defaultValue,
    onChange,
    label,
    size,
    customOptsRender,
    required,
    disabled,
    testId,
    value,
    selectedValue
  } = props;
  const name: string = props.name;
  const options: Array<{ label: string; value: string }> = props.options;
  return (
    <>
      <Form.Item required={required} label={label} className={size}>
        <Field name={props.name}>
          {({ field, form }: any) => {


            const touched = getNested(form.touched, name);
            const error : string = getNested(form.errors, name)



            return (
              <div className="select-wrapper">
                <Select
                  showSearch={true}
                  data-testid={testId}
                  aria-disabled={disabled || false}
                  mode={mode}
                  placeholder="Wybierz"
                  defaultValue={defaultValue}
                  disabled={disabled}
                  onChange={(e: string) => {
                    form.setFieldValue(name, e);
                    if (onChange) {
                      onChange(e);
                    }
                  }}
                  // value={options.length > 0 ? form.values[name] : ""}
                  value={value || getNested(form.values, name) || selectedValue}
                  id={name}
                >
                  {customOptsRender ? customOptsRender : renderOpts(options)}
                </Select>
                
                {/* {(touched && error) &&
                  ErrorLabel(error)
                 } */}

                <ErrorMsg name={props.name} />
                  
              </div>
            );
          }}
        </Field>
      </Form.Item>
      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </>
  );
}
