import { useState, useEffect, useRef } from "react";
import {
  IBDOReportGraph,
  BDOGraphNode,
  IBDOGraphData,
} from "types/BDO-report-types";
import { PAGE } from "paths";
import { useHistory } from "react-router-dom";
import {
  checkBDOGraph,
  fetchBDOReportFormData,
  saveBDOReportStep,
} from "actions/BDO-reports";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import ReactGA from "react-ga";

export interface IBDOFormNavItem {
  name: BDOGraphNode;
  title: string;
  path: string;
  isTraversable: boolean;
}

export const useGraphForm = <T>(id?: string | null) => {
  const history = useHistory();
  const graphRef = useRef<{ graph_data: IBDOReportGraph }>();
  const [stepNavData, setstepNavData] = useState<IBDOFormNavItem[] | undefined>(
    []
  );

  const [graphState, execCheckGraph]: readonly [
    IActionState<{ graph_data: IBDOReportGraph }>,
    typeof checkBDOGraph
  ] = useAsyncAction(checkBDOGraph);

  const [formData, execFetchFormData]: readonly [
    IActionState<T & IBDOGraphData>,
    typeof fetchBDOReportFormData
  ] = useAsyncAction(fetchBDOReportFormData);

  const [saveState, execSaveStep]: readonly [
    IActionState<T>,
    typeof saveBDOReportStep
  ] = useAsyncAction(saveBDOReportStep);

  // useEffect(() => {
  //   if (graphState.data) {
  //     graphRef.current = graphState.data;
  //   }
  // }, [graphState.data]);

  useEffect(() => {
    if (formData.data?.graph_data) {
      graphRef.current = formData.data;

      const navData:
        | IBDOFormNavItem[]
        | undefined = formData.data?.graph_data.available_nodes.map((item) => {
        return {
          name: item,
          title: nodeTitles[item],
          path: nodePaths[item],
          isTraversable: isTraversable(item),
        };
      });

      setstepNavData(navData);
    }
  }, [formData.data]);

  const nodePaths: {
    [key in BDOGraphNode]: string;
  } = {
    vehicle_list: PAGE.BDO_REPORT_STEP_1,
    campaign_leaflet: PAGE.BDO_REPORT_CAMPAIGN_LEAFLET,
    payments: PAGE.BDO_REPORT_STEP_2,
    network: PAGE.BDO_REPORT_STEP_3,
    campaign: PAGE.BDO_REPORT_STEP_4,
    summary: PAGE.BDO_REPORT_STEP_5,
  };

  const nodeTitles: {
    [key in BDOGraphNode]: string;
  } = {
    vehicle_list: "Pojazdy",
    campaign_leaflet: "Kampania edukacyjna",
    payments: "Opłaty",
    network: "Sieć",
    campaign: "Kampania",
    summary: "Podsumowanie",
  };

  // functions for internal use
  const isTraversable = (node: BDOGraphNode) => {
    const currentGraph = formData.data?.graph_data;
    if (!currentGraph) {
      return false;
    }
    return currentGraph?.traversable_nodes.includes(node);
  };

  // returned methods

  const initializeStep = async (node: BDOGraphNode) => {
    if (id) {
      // execCheckGraph(id);
      const res: any = await execFetchFormData(id, node);
      if (res?.response?.status === 404) {
        history.push(PAGE.BDO_REPORT);
      }
      graphRef.current = res.data;

      if (res?.response?.status === 301) {
        handleStepRedirect(res.response.data);
      }
      
      if (res?.status === 200) {
        ReactGA.initialize("UA-212968889-1");

        const step = nodeTitles[node] || "brak danych";

        ReactGA.event({
          category: "Kroki w formularzu BDO: część 2",
          action: `Otwarcie kroku: ${step}`,
        });
      }
    }
  };

  const saveStep = async (node: BDOGraphNode, params?: any) => {
    if (id) {
      const res: any = await execSaveStep(id, node, params);
      if (res?.response?.status === 404) {
        history.push(PAGE.BDO_REPORT);
      }

      graphRef.current = res.data;
      if (res?.response?.status === 301) {
        handleStepRedirect(res.response.data);
      } else if (res.status === 200 || res.status === 201) {
        if (node === "summary") {
          history.push(`${PAGE.BDO_YEAR_REPORT_SUBMIT}?id=${id}`);
        } else {
          goToNext(res.data);
        }
      }
    }
  };

  const handleStepRedirect = (redirectData: {
    graph_data: IBDOReportGraph;
  }) => {
    let next =
      redirectData.graph_data?.next_node ||
      redirectData.graph_data.current_node;
    if (next) {
      const path = nodePaths[next];
      history.push(`${path}?id=${id}`);
    }
  };

  const goToNext = (redirectData: {
    graph_data: IBDOReportGraph;
    id?: string;
  }) => {
    let next =
      redirectData.graph_data?.next_node ||
      redirectData.graph_data.current_node;
    if (next) {
      const path = nodePaths[next];
      history.push(`${path}?id=${id || redirectData.id}`);
    }
  };

  const goToStep = (node: BDOGraphNode) => {
    const path = nodePaths[node];
    history.push(`${path}?id=${id}`);
  };

  return {
    state: {
      isLoading: formData.loading || saveState.loading || graphState.loading,
      // !formData.data,
    },
    goToNext,
    goToStep,
    handleStepRedirect,
    graphState,
    formData,
    fetchFormData: execFetchFormData,
    saveStep,
    saveState,
    execCheckGraph,
    initializeStep,
    stepNavData,
  };
};
