import axios, { AxiosRequestConfig } from "axios";
import { WORKSHOP_API_URL } from "config";
import { workshopRequestHeaders } from "utils/auth";
import { ActionResponse } from "types/async-types";

//**** regular API calls */
export const fetchFormData = async () => {
  const options : AxiosRequestConfig = {
    url: `${WORKSHOP_API_URL}/form_data`,
    headers: { "content-type": "application/json" },
    method: "GET",
  };
  let res = await axios(options);
  localStorage.setItem("token", res.data.api_token);
  return res;
};

export const fetchCompanyByTax = async (taxNumber: string) => {
  const options : AxiosRequestConfig  = {
    url: `${WORKSHOP_API_URL}/check_gus?tax_number=${taxNumber}`,
    headers: {
      Authorization: workshopRequestHeaders(),
    },
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const checkREGON = async (regonNumber: string) => {
  const options : AxiosRequestConfig  = {
    url: `${WORKSHOP_API_URL}/check_regon?regon_number=${regonNumber}`,
    headers: {
      Authorization: workshopRequestHeaders(),
    },
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const checkPostalCode = async (postalCode: string, strip = null) => {
  const options : AxiosRequestConfig  = {
    url: `${WORKSHOP_API_URL}/check_postal_code?code=${postalCode}${
      strip ? `&strip=${strip}` : ""
    }`,
    headers: {
      Authorization: workshopRequestHeaders(),
    },
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const fetchAddresses = async () => {
  const options : AxiosRequestConfig  = {
    url: `${WORKSHOP_API_URL}/addresses`,
    headers: {
      Authorization: workshopRequestHeaders(),
    },
    method: "GET",
  };
  let res = null;
  res = await axios(options);
  return res;
};

export const createAddress = async (data: any) => {
  const options : AxiosRequestConfig  = {
    url: `${WORKSHOP_API_URL}/addresses`,
    headers: {
      Authorization: workshopRequestHeaders(),
    },
    method: "POST",
    data
  };
  let res : ActionResponse<any> | null  = null;
  res  = await axios(options);
  res.successMessage = "Dodano nowy adres";
  return res;
};
