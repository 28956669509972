import React from 'react';
import {Formik, FormikProps} from 'formik'
import ButtonsPanel from 'components/buttons/buttons-panel'
import CheckFormFIeld from 'components/form-fields/check-form-field';

//@ts-ignore
import 'styles/layout/side-menu.scss'


interface IProps  {
  handleSubmit : (...a : any) => void
  handleCancel : () => void
}
function MenuSettings(props : IProps){

    const {handleSubmit,  handleCancel} = props;
    const onSubmit = (values: any) => {

    }
    
    return (
      
      <Formik onSubmit={onSubmit} initialValues={{menu_opts: []}}>
        {(formikProps: FormikProps<any>) => {
          return <div className="menu-settings">
          <div className="">
          <div className="menu-section">
            <h4 className="md-b-t">Zarządzaj menu</h4>
            <p className="small">Wybierz kategorie które chcesz aby się wyświetlały w menu. Jeśli nie interesują cię niektóre - możesz je ukryć w ten sposób.</p>
          </div>
          <form>
            <div className="menu-section">
            <CheckFormFIeld id="1" value="start" disabled={true} checked={true} cbxLabel="Start" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="2" value="my_cars"  cbxLabel="Moje pojazdy" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="3" value="translations"  cbxLabel="Tłumaczenia" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="4"  cbxLabel="Recykling" value="recycling" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="5"  cbxLabel="Ubezpieczenia" value="insurance" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="6"  cbxLabel="Administracja" value="admin" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="7"  cbxLabel="Handel" value="handel" name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld id="8"  cbxLabel="Ewidencja" value="record"  name="menu_opts" />
            </div>
            <div className="menu-section">
              <CheckFormFIeld  cbxLabel="Rejestracja pojazdów" value="register" name="menu_opts" />
            </div>
            <ButtonsPanel handleCancel={handleCancel} handleSubmit={handleSubmit} saveTitle="Zapisz" cancelTitle="Anuluj" />
          </form>
        </div>
        {/* <style jsx>{s1}</style> */}
        </div>
        }}
      
      
      </Formik>
      
    )
};

// MenuSettings.propTypes = {

//   handleSubmit : PropTypes.func,
//   handleCancel: PropTypes.func
// }

export default MenuSettings;
