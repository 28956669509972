import React, { useState, useEffect, useRef } from "react";
import Table from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import TableWrapper from "components/common/table-wrapper";
import MoreVertIcon from '@material-ui/icons/MoreVert';

import {
  ISimpleDocument,
  IDocuments,
  IDocumentFile,
} from "types/document-types";
import Button from "components/buttons/button";
import { IActionState, ActionResponse } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import { fetchDocuments, fetchDocument } from "actions/accounting-documents";
import Spinner from "components/core/spinner";
import { renderBreadcrumbs } from "utils/helpers";

interface IFormValues {
  invoice_number: string;
  issue_date: string;
}

const Documents = (props: any) => {
  const [timeoutVar, setTimeoutVar] = useState<undefined | any>(undefined);
  const [category, setCategory] = useState<string>("ALL");
  const [tableData, setTableData] = useState<any>({});

  const [documentsState, execFetchDocuments]: readonly [
    IActionState<IDocuments>,
    typeof fetchDocuments
  ] = useAsyncAction(fetchDocuments);

  const [fileState, execFetchFile]: readonly [
    IActionState<IDocumentFile>,
    typeof fetchDocument
  ] = useAsyncAction(fetchDocument);

  useEffect(() => {
    execFetchDocuments();
    setTableData(getTableData());
  }, []);

  const openFile = async (docId: string, fileId: number) => {
    const res: ActionResponse<IDocumentFile> | undefined = await execFetchFile(
      docId,
      fileId
    );
    if (res) {
      window.open(res.data.url, "_blank");
    }
  };

  const filterDocuments = (category: string) => {
    setCategory(category);
  };
  // const renderItems = () => {
  //   let result: ISimpleDocument[] = [];
  //   if (documentsState.data && documentsState.data.data) {
  //     const documents = documentsState.data.data;
  //     if (category === "ALL") {
  //       Object.keys(documents).map((key, index) => {
  //         result = result.concat(documents[key]);
  //       });
  //     } else {
  //       result = documents[category];
  //     }
  //   }

  //   return result;
  // };

  const renderCategorySections = () => {
    if (documentsState.data && documentsState.data.list) {
      const groups = documentsState.data.list;
      if (category === "ALL") {
        const categorySections = groups.sort((first,second) => first.position - second.position).map(
          (group, index) => {
            const documents = group.documents;

            return (
              <div className="mb-20">
           
                <h2 className="h-lg">
                  {group.name}
                </h2>
                <Table
                  size="middle"
                  locale={{ emptyText: "Brak elementów do wyświetlenia" }}
                  rowKey="uuid"
                  columns={tableData.columns}
                  bordered={false}
                  dataSource={documents}
                  pagination={false}
                  loading={tableData.loading}
                  className="custom-ant-table"
                />
              </div>
            );
          }
        );
        return categorySections;
      } else {
          const group = groups.find((item) => {
              return item.id === category;
          })
        return (
          <div className="mb-20">
            <h2 className="primary h-lg mb-10">
              {group?.name}
            </h2>
            <Table
              size="middle"
              locale={{ emptyText: "Brak elementów do wyświetlenia" }}
              rowKey="uuid"
              columns={tableData.columns}
              bordered={false}
              dataSource={group?.documents}
              pagination={false}
              loading={tableData.loading}
              className="custom-ant-table"
            />
          </div>
        );
      }
    }
  };

  const renderFilterButtons = () => {
    const groups = documentsState.data?.document_groups;
    if (groups) {
      const result = groups.sort((first,second) => first.position - second.position).map((key) => {
        return (
          <Button
            color={key.key === category ? "primary" : "white"}
            title={key.name}
            inline={true}
            onClick={() => filterDocuments(key.key)}
            className="mr-10"
          />
        );
      });
      result.unshift(
        <Button
          color={category === "ALL" ? "primary" : "white"}
          title="Wszystkie"
          inline={true}
          onClick={() => filterDocuments("ALL")}
          className="mr-10"
        />
      );
      return result;
    }
  };

  const renderColumns = () => {
    return [
      {
        title: "Dokument",
        align: "left",
        width: "45%",
        render: (field: any, row: ISimpleDocument) => (
          <div className="t-500 black">{row.name}</div>
        ),
      },
      {
        title: "Opis dokumentu",
        align: "left",
        width: "30%", 
        render: (field: any, row: ISimpleDocument) => (
          <div className="">{row.description}</div>
        ),
      },
      // {
      //   title: "Kategoria",
      //   align: "left",
      //   render: (field: any, row: ISimpleDocument) => (
      //     <div className="no-wrap">{row.}</div>
      //   ),
      // },
      {
        align: "right",
        // width: 400,
        render: (field: any, row: ISimpleDocument) => {
          return row.files?.map((item) => {
            return (
              <div className="flex" >
                <div className="flex">
                <InsertDriveFileOutlinedIcon className="grey mr-5" />
                <span className="black mr-10 attachment-file-name">
                  {item.file_name}
                </span>
                </div>
                <a className="no-wrap grow-1" target="_blank" href={`${item.url}`} rel="noreferrer">
                  Pobierz
                </a>
              </div>
            );
          });
        },
      },
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };
  const timoutRef = useRef();
  timoutRef.current = timeoutVar;

  const onSubmit = (values: any) => {};

  return (
    <Formik
      initialValues={{
        invoice_number: "",
        issue_date: "",
      }}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        return (
          <div className="page-content">
            {documentsState.loading && <Spinner size="large" />}
            <div className="breadcrumbs">
             {renderBreadcrumbs(['Dokumenty'])}
            </div>
            <header className="page-header">
              <h1>Dokumenty</h1>
            </header>
            <div className="mb-20">
              <span>Wyświetl: </span>
              {renderFilterButtons()}
            </div>
            <TableWrapper>{renderCategorySections()}</TableWrapper>
          </div>
        );
      }}
    </Formik>
  );
};

export default withAuthSync(withLayout(Documents));
