import React, { useEffect, useState } from "react";
import WarningOutlinedIcon from "@material-ui/icons/WarningOutlined";
import Button from "components/buttons/form-button";
import CarDataForm from "components/my-vehicles/car-data-form";
import DraftCarForm from "components/my-vehicles/draft-car-form";
import CloseIcon from "@material-ui/icons/Close";
import {   IListing } from "types/async-types";


import Spinner from "components/core/spinner";
import FormButton from "components/buttons/form-button";
import StatCounter from "components/common/stat-counter";
import AntdTableWrapper from "components/common/antd-table-wrapper";
import {
  IVehicle,
  
  IVehicleBasicFormValues,
} from "types/vehicle-types";

import { ColumnProps } from "antd/lib/table";
import { LPColumn } from "utils/columns";
import FormButtons from "components/common/form-buttons";
import { useGraphForm } from "./graph-form-utils";
import { formatDateTime } from "utils/date";


const BDOYearReportStep1 = (props: { setNavState: any }) => {
  const { setNavState } = props;
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");

  const {
    formData,
    fetchFormData,
    initializeStep,
    saveStep,
    goToStep,
    stepNavData,
    state,
  } = useGraphForm<IListing<IVehicle>>(id);
  const [carFormCollapsed, setCarFormCollapsed] = useState(true);
  const [draftCarFormCollapsed, setDraftCarFOrmCollapsed] = useState(true);
  const [resetting, setResetting] = useState(false);
  const [vehicleFormValues, setVehicleFormValues] = useState<
    IVehicleBasicFormValues
  >();

  useEffect(() => {
    if (resetting) {
      setCarFormCollapsed(false);
      setResetting(false);
    }
  }, [resetting]);

  useEffect(() => {
    
    initializeStep("vehicle_list");
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setNavState({
      goToStep,
      stepNavData,
    });
  }, [stepNavData]);

  const setPage = (page: number) => {
    if (id) {
      fetchFormData(id, "vehicle_list", { page });
    }
  };

  const openDraftForm = (values: IVehicleBasicFormValues) => {
    setVehicleFormValues(values);
    toggleFilters();
    setDraftCarFOrmCollapsed(false);
  };

  const reset = () => {
    setResetting(true);
    setCarFormCollapsed(true);
  };

  const resetDraft = () => {
    setResetting(true);
    setDraftCarFOrmCollapsed(true);
  };

  const toggleFilters = () => {
    setCarFormCollapsed(!carFormCollapsed);
  };

  const renderColumns = () => {
    return [
      LPColumn(formData.data?.items_from),
      {
        title: "Pojazd",
        dataIndex: "make",
        align: "left",
        render: (make: any, row: IVehicle) => (
          <div className="flex-left">
   
            <div className="vehicle-basic-info pt-0">
              <div className="t-500">
                {row.brand} {row.model}
              </div>
              <div className="year-vin">{row.production_year}</div>
            </div>
          </div>
        ),
      },

      {
        title: "Dodano",
        align: "center",
        width: 90,
        render: (make: any, row: IVehicle) => (
          <div className="action no-wrap">{formatDateTime(row.created_at)}</div>
        ),
      },
      {
        title: "Import",
        align: "center",
        width: 90,
        render: (make: any, row: IVehicle) => (
          <div className="action no-wrap">{row.import_date}</div>
        ),
      },
      {
        title: "Typ pojazdu",
        align: "right",
        width: 100,
        render: (make: any, row: IVehicle) => (
          <div className="action no-wrap">{row.vehicle_type}</div>
        ),
      },
    ] as ColumnProps<any>[];
  };

  const submitForm = async () => {
    saveStep("vehicle_list");
  };

  return (
    <>
      {state.isLoading && <Spinner size="large" fixed={true} />}
      <div className="block">
        <p className="large mb-0">
          Rozpocząłeś proces przygotowania sprawozdania rocznego do BDO. Sprawdź
          czy wszystkie przygotowane dane są poprawne.
        </p>
      </div>
      <div className="block">
        <div className="flex mb-15">
          <h2 className="h-lg grow-1 mb-0">
            Pojazdy osobowe i dostawcze do 3,5 tony wprowadzone w 2023 roku
          </h2>
          <div>
            <Button
              title="Dodaj pojazd"
              onClick={() => toggleFilters()}
              color="white"
              inline={true}
            />
          </div>
        </div>
        <p className="md-t flex-top-left">
          <span className="warning-label">
            <WarningOutlinedIcon />
          </span>
          Jeżeli w 2023 roku wprowadziłeś więcej pojazdów osobowych i
          dostawczych do 3,5 tony, które to pojazdy podlegają obowiązkowi
          zapewnienia sieci koniecznie dodaj je do systemu. Liczba pojazdów
          zgłoszonych w sprawozdaniu musi się zgadzać ze stanem faktycznym.
        </p>
        <StatCounter
          className="mb-0"
          amount={formData.data?.total_items.toString() || null}
          color="heading"
          title="Liczba pojazdów"
          suffix="podlegających obowiązkowi zapewnienia sieci."
        />
      </div>
      <AntdTableWrapper
        noTotal={true}
        setPage={setPage}
        data={formData.data}
        columns={renderColumns()}
        tableProps={{
          rowKey: "uuid",
          className: "custom-ant-table mb-10",
        }}
      />

      <FormButtons>
        <FormButton
          width={220}
          color="primary"
          onClick={submitForm}
          title="Kontynuuj"
        />
      </FormButtons>

      {!carFormCollapsed && (
        <div className="sider fixed my-vehicles__filters">
          <CarDataForm
            mode="vin_step2"
            isLoading={false}
            toggleFilters={toggleFilters}
            openDraftForm={openDraftForm}
            execFetchVehicles={() => id && fetchFormData(id, "vehicle_list")}
            reset={reset}
          />
          <CloseIcon className="sider-close" onClick={toggleFilters} />
        </div>
      )}
      {!draftCarFormCollapsed && (
        <div className="sider fixed my-vehicles__filters">
          <DraftCarForm
            mode="vin_step2"
            isLoading={false}
            toggleFilters={() => setDraftCarFOrmCollapsed(true)}
            execFetchVehicles={() => id && fetchFormData(id, "vehicle_list")}
            reset={resetDraft}
            copiedValues={vehicleFormValues}
          />
          <CloseIcon
            className="sider-close"
            onClick={() => setDraftCarFOrmCollapsed(true)}
          />
        </div>
      )}
    </>
  );
};

export default BDOYearReportStep1;
