import React, { useState } from "react";
import Dropdown from "antd/lib/dropdown/dropdown";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { PAGE } from "paths";
import { Link } from "react-router-dom";
import {
  moveDocumentGroupDown,
  moveDocumentGroupUp,
  addDocumentGroup,
} from "actions/accounting-documents";
import { IActionState } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
interface IProps {
  groupID?: string;
  deleteGroup: (id: string) => any;
  execFetchGroup : (id : string) => any;
}
const DocumentsMenu = (props: IProps) => {
  const { groupID, deleteGroup , execFetchGroup} = props;

  const [moveDownState, execMoveDown]: readonly [
    IActionState<any>,
    typeof moveDocumentGroupDown
  ] = useAsyncAction(moveDocumentGroupDown);

  const [moveUpState, execMoveUp]: readonly [
    IActionState<any>,
    typeof moveDocumentGroupUp
  ] = useAsyncAction(moveDocumentGroupUp);

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const onVisibleChange = (e: boolean) => {
    setDropdownVisible(e);
  };

  return (
    <Dropdown
      visible={dropdownVisible}
      onVisibleChange={onVisibleChange}
      trigger={["click"]}
      overlay={
        <div className="menu_dropdown menu_dropdown--actions">
          <section
            onClick={async () => {
              if (groupID) {
               await execMoveUp(groupID);
               execFetchGroup(groupID);
              }
            }}
          >
            Przesuń w górę
          </section>
          <section
            onClick={async() => {
              if (groupID) {
               await execMoveDown(groupID);
               execFetchGroup(groupID);
              }
            }}
          >
            Przesuń w dół
          </section>

          <section>
            <Link to={`${PAGE.ADMIN_DOCUMENTS_GROUP_EDIT}/${groupID}`}>
              Edytuj{" "}
            </Link>
          </section>
          {groupID && (
            <section onClick={() => deleteGroup(groupID)}>Usuń</section>
          )}
        </div>
      }
    >
      <div className="flex">
        <MoreVertIcon className="hover" />
      </div>
    </Dropdown>
  );
};

export default DocumentsMenu;
