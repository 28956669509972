import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";
import {   ActionResponse } from "types/async-types";
import { requestHeaders, workshopRequestHeaders } from "utils/auth";
import { IBDOKey } from "types/BDO-keys-types";
import { IBDONumberReq } from "types/BDO-report-types";

const BDO_NUMBER_URL = `${API_BASE_URL}/bdo_numbers`;

export const fetchBDONumber = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_NUMBER_URL}/bdo_number`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const updateBDONumber = async (data: IBDONumberReq) => {
  const options: AxiosRequestConfig = {
    url: `${BDO_NUMBER_URL}/bdo_number`,
    headers: requestHeaders().headers,
    method: "PUT",
    data: { bdo_number: data.bdo_number },
  };
  let res:   ActionResponse<any> = await axios(options);


  if (res) {
    res = {
      ...res,
      successMessage: "Zapisano numer BDO",
    };
    return res;
  }
};

export const fetchBDONumberHistory = async () => {
  const options: AxiosRequestConfig = {
    url: `${BDO_NUMBER_URL}/history`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};
