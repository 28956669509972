import React, { useEffect, useState, useRef } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { Formik, FormikProps } from "formik";
import TextField from "components/form-fields/formik-text-field";
import CheckFormField from "components/form-fields/check-form-field";
import AddressSection from "components/common/address-section";
import SelectField from "components/form-fields/select-field";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupRequired, phone, isPhoneCorrect } from "validators";
import { IActionState } from "types/async-types";
import {
  IOSOP1FormValues,
  IOSOP1FormData,
  IOSOP1,
} from "types/BDO-report-types";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import {
  fetchOSOP1FormData,
  fetchOSOP1PriceById,
  commitOSOP1,
  fetchOSOP1Report,
} from "actions/BDO-reports";
import { renderJsonOptions } from "utils/select-options";
import Price from "components/common/price";

import { userFields } from "config/user-fields";
import FormButton from "components/buttons/form-button";
import RadioGroup from "components/form-fields/radio-group";
import FormStatusScreen from "components/common/form-status-screen";
import Radio from "antd/lib/radio";
import { numToCurrency } from "utils/helpers";
import { IRadioGroupProps } from "components/form-fields/radio-group";
import { IPrice } from "types/common-types";
import FormButtons from 'components/common/form-buttons';

const BDONumber = (props: any) => {
  const history = useHistory();
  const [id, setId] = useState<string | null>(null);

  const [formDataState, execFetchFormData]: readonly [
    IActionState<IOSOP1FormData>,
    typeof fetchOSOP1FormData
  ] = useAsyncAction(fetchOSOP1FormData);

  const [OSOP1ReportState, execFetchOSOP1Report]: readonly [
    IActionState<IOSOP1>,
    typeof fetchOSOP1Report
  ] = useAsyncAction(fetchOSOP1Report);

  const formRef: any = useRef();
  const formikRef: FormikProps<IOSOP1FormValues> = formRef.current;
  const [initialValues, setInitialValues] = useState<
    IOSOP1FormValues | undefined
  >();

  const [priceState, execFetchPrice]: readonly [
    IActionState<IPrice>,
    typeof fetchOSOP1PriceById
  ] = useAsyncAction(fetchOSOP1PriceById);

  const [commitOSOP1State, execCommitOSOP1]: readonly [
    IActionState<{ payment_address: string }>,
    typeof commitOSOP1
  ] = useAsyncAction(commitOSOP1);

  useEffect(() => {
    if (id) {
      execFetchOSOP1Report(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    execFetchFormData();

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id) {
      setId(id);
      execFetchPrice(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async (values: IOSOP1FormValues) => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id) {
      execCommitOSOP1(values, id);
    }

    // history.push(PAGE.STEP_2);
  };

  useEffect(() => {
    if (
      formikRef &&
      formikRef.values.payment_type === "ONLINE" &&
      commitOSOP1State.data?.payment_address
    ) {
      const urlParams = new URLSearchParams(window.location.search);
      const id = urlParams.get("id");
      if (id) {
        // history.push(`${PAGE.OS_OP1_PAYMENT_STATUS}?id=${id}`);
        window.location.href = commitOSOP1State.data?.payment_address;
      }
    }
  }, [commitOSOP1State.data]);

  useEffect(() => {
    execFetchFormData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialValues) {
      formikRef.setValues(initialValues);
    }

    // reset(initialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    if (formDataState.data && !OSOP1ReportState.data) {
      setInitialValues({
        ...formDataState.data.os_op1_report,
        address_attributes: formDataState.data.os_op1_report.address,
      });
    }

    if (OSOP1ReportState.data) {
      setInitialValues({
        name: OSOP1ReportState.data.name,
        surname: OSOP1ReportState.data.surname,
        phone_number: OSOP1ReportState.data.phone_number,
        email: OSOP1ReportState.data.email,
        is_terms_accepted: OSOP1ReportState.data.is_terms_accepted,
        is_rodo_accepted: OSOP1ReportState.data.is_rodo_accepted,
        payment_type: undefined,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataState.data, OSOP1ReportState.data]);

  let validationSchema = Yup.object().shape({
    name: Yup.string().concat(yupRequired).nullable(),
    surname: Yup.string().concat(yupRequired).nullable(),
    phone_number: Yup.string().concat(yupRequired).nullable(),
    email: Yup.string().concat(yupRequired).nullable(),
    is_terms_accepted: Yup.boolean()
      .oneOf([true], "Pole jest wymagane")
      .nullable(),
    is_rodo_accepted: Yup.boolean()
      .oneOf([true], "Pole jest wymagane")
      .nullable(),
    payment_type: Yup.string()
      .required("Należy wybrać rodzaj platności")
      .nullable(),
  });

  const termsField = {
    name: "is_terms_accepted",
    required: true,
    cbxLabel:
      "Zapoznałem się z zasadami ochrony danych osobowych oraz z regulaminem serwisu na stronie www.integrator.org.pl: Polityka Prywatności, Regulamin.",
    value: true,
    isFlag: true,
  };
  const rodoField = {
    name: "is_rodo_accepted",
    value: true,
    isFlag: true,
    required: true,
    cbxLabel:
      "Wyrażam zgodę na przetwarzanie moich danych osobowych w podanym wyżej zakresie przez Administratora Danych w celu wykonania usługi przez Integrator sp. z o.o. Informujemy, że Państwa zgoda może zostać cofnięta w dowolnym momencie przez wysłanie wiadomości e-mail na adres naszej firmy spod adresu, którego zgoda dotyczy.",
  };

  const paymentTypeField: IRadioGroupProps<"ONLINE" | "PRO_FO_IV"> = {
    orientation: "vertical",
    name: "payment_type",
    label: "",
    options: [
      {
        value: "ONLINE",
        label: (
          <div>
            <p className="">
              <span className="mr-5">Przelew on-line </span>{" "}
              <span className="recommended">Zalecane</span>
            </p>
            <p className="sm-t grey">
              Przelew on-line jest najszybszą formą płatności. Zaksięgowanie
              środków trwa kilka sekund. Dzięki czemu otrzymasz sprawozdanie
              natychmiast po ukończeniu płatności.
            </p>
          </div>
        ),
      },
      {
        value: "PRO_FO_IV",
        label: (
          <div>
            <p className="">Przelew na podstawie faktury pro-forma</p>
            <p className="sm-t grey">
              Faktura pro-forma zostanie Ci wysłana na wskazany adres mailowy.
              Po zaksięgowaniu płatności wyślemy Ci fakturę VAT i druki
              sprawozdań.
            </p>
          </div>
        ),
      },
    ],
  };

  return (
    <div className="">
      {(formDataState.loading ||
        commitOSOP1State.loading ||
        priceState.loading) && <Spinner size="large" fixed={true} />}
      <Formik
        innerRef={formRef}
        validationSchema={validationSchema}
        // isInitialValid={BDONumberState.data?.data ? true : false}
        enableReinitialize={true}
        initialValues={{}}
        onSubmit={submitForm}
      >
        {(formikProps: FormikProps<IOSOP1FormValues>) => {
          if (
            commitOSOP1State.data &&
            formikProps.values.payment_type === "PRO_FO_IV"
          ) {
            // setTimeout(() => {
            //   history.push(PAGE.OS_OP1);
            // }, 4000);
            return (
              <FormStatusScreen
                title="Złożono zlecenie"
                description="W najbliższym czasie zostanie Ci wysłana faktura pro-forma. Gdy zaksięgujemy płatność druki sprawozdań oraz fakturę VAT wyślemy do Ciebie na adres mailowy wskazany we wniosku."
                type="success"
                aditionalContent={
                  <div className="t-center">
                    <Button
                      link={`${PAGE.OS_OP1}`}
                      width={300}
                      className="i-b"
                      title="Wróć do listy wniosków"
                      color="primary"
                    />
                  </div>
                }
              />
            );
          }

          // ("errors: ", formikProps.errors);
          return (
            <>
              {/* <FormStatusScreen
                title="Weryfikacja płatności"
                description="Trwa oczekiwanie na potwierdzenie realizacji płatności w serwisie przelewy24"
                type="loading"
              />{" "}
              
              <FormStatusScreen
                title="Płatność powiodła się. Dziękujemy."
                description="W najbliższym czasie faktura VAT oraz druki sprawozdań zostaną do Ciebie wysłane na adres mailowy wskazany we wniosku."
                type="success"
              />{" "}
                <FormStatusScreen
                title="Płatność nie powiodła się.."
                description="Nie otrzymaliśmy potwierdzenia wykonania płatności od Przelewy 24.."
                type="error"
                aditionalContent={<div className="t-center"><Button onClick={() => {}} width={300} className="i-b" title="Ponów płatność" color="primary"/> <Button onClick={() => {}} width={300} className="i-b"  title="Wróć do wyboru sposobu płaności" color="white"/></div>}
              />{" "} */}

              <div className="block">
                <h2 className="h-lg">Zgody</h2>
                <p className="md-t">
                  Wymagane zgody które służą wyłącznie możliwości przekazania
                  twoich danych w celu wygenerowania wniosku. Twoje dane
                  teleadresowe są elementem wniosku które są wymagane przez
                  podległy sprawie urząd.
                </p>
                <Row gutter={15} className="mb-20">
                  <Col md={6}>
                    <TextField label="Imię" name="name" required={true} />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="Nazwisko"
                      name="surname"
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      {...userFields.phone}
                      validate={isPhoneCorrect}
                      name="phone_number"
                    />
                  </Col>
                  <Col md={6}>
                    <TextField {...userFields.email} />
                  </Col>
                </Row>
                <div className={`check-form-fields vertical`}>
                  <CheckFormField {...termsField} />
                  <CheckFormField {...rodoField} />
                </div>
                {/* <div className="md-t mb-30">
                  <b className="red">*</b> - pola oznaczone gwiazdką są
                  obowiązkowe
                </div> */}

                {/* <FormButton
                    color="primary"
                    onClick={formikprops.submitForm}
                    title="Wyślij wniosek"
                    width={250}
                    disabled={!formikprops.isValid}
                  /> */}
              </div>
              <div className="block">
                <h2 className="h-lg">Płatność</h2>
                <RadioGroup {...paymentTypeField} />
                {/* <Radio.Group size="large">
                  <Radio
                    data-testid="standard-opt"
                    className="md-t"
                    value={"standard"}
                    onChange={() =>
                      formikProps.setFieldValue("payment_type", "ONLINE")
                    }
                  >
                    <div>
                      <p className="lg-t">
                        <span className="mr-5">Przelew on-line </span>{" "}
                        <span className="recommended">Zalecane</span>
                      </p>
                      <p className="md-t grey">
                        Przelew on-line jest najszybszą formą płatności.
                        Zaksięgowanie środków trwa kilka sekund. Dzięki czemu
                        otrzymasz sprawozdanie natychmiast po ukończeniu
                        płatności.
                      </p>
                    </div>
                  </Radio>
                  <Radio
                    data-testid="tel-opt"
                    className="md-t"
                    value={"phone"}
                    onChange={() =>
                      formikProps.setFieldValue("payment_type", "PRO_FO_IV")
                    }
                  >
                    <div> 
                      <p className="lg-t">
                        Przelew na podstawie faktury pro-forma
                      </p>
                      <p className="md-t grey">
                        Podajesz numer telefonu i logujesz sie za pomocą
                        jednorazowego tokena/hasła. Łatwiejsze i przydatne w
                        aplikacji mobilnej..
                      </p>
                    </div>
                  </Radio>
                </Radio.Group>
                {( error ) &&
                  <span data-testid="text-field-error" className="input-error">{formikProps.errors.payment_type} </span>
                } */}
                <Price
                  title="Łącznie do zapłaty"
                  price={priceState.data?.price.netto}
                  suffix="netto za rok sprawozdawczy. Po zapłaceniu faktury proforma
                  otrzymasz (na e-mail wskazany powyżej) - fakturę wraz z
                  drukami sprawozdań."
                />
                <FormButtons>
                <FormButton
                  width={250}
                  onClick={formikProps.submitForm}
                  title="Potwierdzam i składam zamówienie"
                  color="primary"
                />
                </FormButtons>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default BDONumber;
