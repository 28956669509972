import React, { useState, useEffect } from "react";
import Pagination from "@material-ui/lab/Pagination";
import "styles/components/common/table.scss";
import "styles/pages/my-vehicles.scss";
import { IListing } from "types/async-types";

interface IProps {
  data?: IListing<any> | null;
  className?: string;
  children: React.ReactNode;
  addPagination?: boolean;
  fetchData?: {
    current_page: number;
    total_pages: number;
  } | null;
  setPage?: (page: number) => void;
  ref?: any;
}
const TableWrapper = (props: IProps) => {
  const { className, setPage, fetchData, addPagination, ref, data } = props;
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  useEffect(() => {
    if (fetchData?.total_pages) {
      setPageCount(fetchData?.total_pages);
    }
    if (fetchData?.current_page) {
      setPageNo(fetchData?.current_page);
    }
  }, [fetchData]);
  return (
    <div ref={ref} className={`table_wrapper ${className || ""}`}>
      {data &&
             <div className="md-t mb-10">
             <span className="grey-2">
               Znaleziono:{" "}
               <span className="t-500 black">{data?.total_items || 0}</span>
             </span>
           </div>
      }
    {props.children}
      {addPagination && (
        <div className="mb-20 i-b">
          <div className={`pagination`}>
            <Pagination
              shape="rounded"
              onChange={
                setPage
                  ? (e, page) => {
                      setPageNo(page);
                      setPage(page);
                    }
                  : undefined
              }
              page={pageNo}
              count={pageCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TableWrapper;
