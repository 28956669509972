import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "components/buttons/button";
import FormButton from "components/buttons/form-button";
import { IActionState } from "types/async-types";
import { IBDOServices, IDashboardProduct } from "types/dashboard-types";
import { fetchBDOServices } from "actions/dashboard";
import { useAsyncAction } from "utils/async-action";
import { formatDateTime } from "utils/date";
import Spinner from "components/core/spinner";
import { numToCurrency } from "utils/helpers";
import ProductsList from "../services-list";
import { PAGE } from "paths";
import { IBDOReportButton } from "types/BDO-report-types";
import { fetchBDOReports, createBDOReport } from "actions/BDO-reports";
import { useGraphForm } from "components/bdo-year-report-form/graph-form-utils";
export interface ITileProps {
  isAlert?: boolean;
}
const BDOReportTile = (props: ITileProps) => {
  const [BDOServicesState, execFetchBDOServices]: readonly [
    IActionState<IDashboardProduct & IBDOReportButton>,
    typeof fetchBDOServices
  ] = useAsyncAction(fetchBDOServices);
  const generateButton = BDOServicesState.data?.generate_bdo_button;

  const [createReportState, execCreateReport]: readonly [
    IActionState<any>,
    typeof fetchBDOReports
  ] = useAsyncAction(createBDOReport);

  const id = generateButton?.id || createReportState.data?.id;
  const { execCheckGraph, goToNext, graphState } = useGraphForm(id);

  useEffect(() => {
    execFetchBDOServices();
  }, []);

  useEffect(() => {
    if (generateButton?.id) {
      execCheckGraph(generateButton.id);
    }
  }, [generateButton]);

  const goToReport = async () => {
    if (!generateButton?.id) {
      const reportRes = await execCreateReport();
      let graphRes;
      if (reportRes.status === 201) {
        graphRes = await execCheckGraph(reportRes.data?.id);
      }

      if (graphRes?.status === 200 && reportRes.status === 201) {
        goToNext({
          graph_data: graphRes.data?.graph_data,
          id: reportRes.data?.id,
        });
      }
    } else if (graphState.data) {
      goToNext({ graph_data: graphState.data?.graph_data });
    }
  };

  return (
    <div className={`block ${props.isAlert ? "alert-block" : ""}`}>
      {(BDOServicesState.loading ||
        createReportState.loading ||
        graphState.loading) && <Spinner />}
      <h2 className="primary h-lg">Sprawozdanie roczne BDO</h2>
      <p className="md-t mt-5 mb-10">
        Zapraszamy do skorzystania z nowej usługi automatycznego składania
        rocznych sprawozdań do systemu BDO. Aby uruchomić usługę zaakceptuj
        warunki składania sprawozdań oraz zapisz klucz API (BDO).
      </p>
      <div className="mb-20">
        <ProductsList products={BDOServicesState.data?.products} />
      </div>
      {BDOServicesState.data?.agreement_button &&
        !BDOServicesState.data?.ws_accepted && (
          <Button
            width={200}
            color="primary"
            className="mr-10"
            inline={true}
            title="Uzyskaj dostęp do usługi"
            outerLink={true}
            link={BDOServicesState.data?.agreement_url}
          />
        )}
      {BDOServicesState.data?.agreement_button &&
        BDOServicesState.data?.ws_accepted && (
          <Button
            width={200}
            color="primary"
            className="mr-10"
            inline={true}
            title="Kontynuuj"
            outerLink={true}
            link={BDOServicesState.data?.agreement_url}
          />
        )}
      {!generateButton?.id && generateButton?.show && (
        <FormButton
          color="primary"
          onClick={() => goToReport()}
          disabled={!BDOServicesState.data || !graphState}
          width={250}
          title="Generuj sprawozdanie"
          className="mb-20"
        />
      )}
      {generateButton?.id && generateButton?.show && (
        <FormButton
          color="primary"
          onClick={() => goToReport()}
          // disabled={true}
          width={250}
          title="Kontynuuj generowanie sprawozdania"
          className="mb-20"
        />
      )}

      {/* <div className="check-list">
        <div className="check-list__element">
          {BDOServicesState.data?.bdo_key ? (
            <CheckIcon className="green" />
          ) : (
            <CloseIcon className="red" />
          )}
          <span className="md-t bold">Klucz API BDO</span>
        </div>
        <ProductsList products={BDOServicesState.data?.products} />

      </div> */}
    </div>
  );
};

export default BDOReportTile;
