import { combineReducers } from 'redux';
import notificationReducer from 'redux/reducers/notifications-reducer';
import vehiclesReducer from 'redux/reducers/vehicles-reducer';
import userReducer from 'redux/reducers/user-reducer';
import wasteReducer from 'redux/reducers/waste-reducer';
import loaderReducer from 'redux/reducers/loader-reducer';
import documentsReducer from 'redux/reducers/documents-reducer';
import translationsReducer from 'redux/reducers/translations-reducer';
// import { reducer as form } from 'redux-form'
import errorReducer from 'redux/reducers/error-reducer'
import asyncReducer from 'redux/reducers/async-reducer'

const rootReducer = combineReducers({
  notifications: notificationReducer,
  translations: translationsReducer,
  vehicles: vehiclesReducer,
  waste: wasteReducer,
  // form: form,
  user: userReducer,
  loader : loaderReducer,
  error : errorReducer,
  documents : documentsReducer,
  async: asyncReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
