import React from "react";
import { Field } from "formik";
import AntdCheckbox from "antd/lib/checkbox";
import ToolTip from 'antd/lib/tooltip'
//@ts-ignore
import "styles/components/input.scss";
import { IInquiryFormValues } from "types/insurance-types";
import { getNested } from "utils/helpers";
import ErrorMsg from "components/common/error-message";

//
// import style from "./check-form-field.module.scss";
export interface ICheckFormFieldProps<T> {
  id?: string;
  name: string;
  value: T | boolean;
  checked?: boolean;
  onCheckChange?: any;
  cbxLabel?: string | JSX.Element | JSX.Element[];
  cbxDetails? : string;
  keyValues?: boolean;
  disabled?: boolean;
  required? : boolean;
  isFlag?: boolean;
  type? : 'checkbox';
  className? :  string;
  additionalAttributes? : any
  icon ? : any
  validate? : any
  disableMessage? : boolean;
}
function Checkbox(props: ICheckFormFieldProps<any>) {

  return (
    <>
      <Field name={props.name} validate={props.validate}>
        {({ field, form, values }: any) => {

                       
      const touched = getNested(form.touched, props.name);
        const error = getNested(form.errors, props.name)
 
            
          return (
            <>
            <AntdCheckbox
              
              data-testid={props.id}
              onChange={(e) => {
                if (props.isFlag) {
                  form.setFieldValue(props.name,field.value  ? false :  e.target.value);
                 
                } else { //* in case its checkbox group field
                  if (field.value.includes(props.value)) {
                    const nextValue = field.value.filter(
                      (value: string) => value !== props.value
                    );
                    form.setFieldValue(props.name, nextValue);
                  } else {
                    const nextValue = field.value.concat(props.value);
                    form.setFieldValue(props.name, nextValue);
                  }
                }

                if (props.onCheckChange) {
                  props.onCheckChange(e, props.additionalAttributes);
                }
              }}
              className={`checkbox ${props.className}`}
              {...props}
              checked={props.checked !== undefined ? props.checked : (props.isFlag ? field.value : field.value.includes(props.value))}
            >
              <div className={`cbx-title`}>{props.icon && <ToolTip title="Brak umowy"><props.icon className="red sm-t"   /> </ToolTip>}{props.required ? "* " : "" }{props.cbxLabel}</div>
              {props.cbxDetails ? <p className="mt-5">{props.cbxDetails}</p> : ""}
              {!props.disableMessage ?  (
                [
              //  <div className="absolute-msg"> <span data-testid="text-field-error" className="input-error">
              //     {error}{" "}
              //   </span>
              //   </div>,
                   <ErrorMsg
                   name={props.name} 
                 />
                ]
              ) : <> </>}
            </AntdCheckbox>
       
            </>
          );
        }}
      </Field>
      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </>
  );
}

export default Checkbox;
