

import TextFormField from "components/form-fields/formik-text-field";
import { isPasswordCorrect, isEmailCorrect, isPESELCorrect, phone } from "validators";
type TextProps = React.ComponentProps<typeof TextFormField>;

export const userFields : {
  phone : TextProps,
  email : TextProps,
  password: TextProps,
  birthDate : TextProps,
  PESELNumber: TextProps;
  firstName : TextProps;
  lastName : TextProps;
  
//   password : TextProps,
}  = {
  firstName : {
    name :'first_name',
    label : "Imię",
    type : 'text',
  },
  lastName : {
    name :'lastName',
    label : "Nazwisko",
    type : 'text',
  },
    phone: {
        required: true,
        placeholder: "XXX-XXX-XXX",
        name: "phone",
        label: "Nr telefonu",
        validate : phone
      },
      email: {
        name: "email",
        type: "email",
        label: "Adres email",
        required: true,
        validate : isEmailCorrect
        
      },
      password: {   
        name: "password",
        type: "password",
        label: "Hasło",
        required: true,
        validate : isPasswordCorrect
      },
      birthDate : {
          name : 'birth_date',
          label : 'Data urodzenia',
          type : 'date',
      },
      PESELNumber: {
        name: "pesel",
        label: "PESEL",
        type: "text",
        validate : isPESELCorrect
      },
      
}