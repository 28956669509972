import { useCallback, useEffect, useRef } from "react";
import { IActionState, ActionResponse } from "types/async-types";
import { IDocumentPdf } from "types/document-types";
import { fetchAccountingDocument } from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";

export const useTable = () => {

    const [documentFileState, execFetchAccountingDocument]: readonly [
        IActionState<IDocumentPdf>,
        typeof fetchAccountingDocument
      ] = useAsyncAction(fetchAccountingDocument);

    const downloadFile = async (id: string) => {
        const res:
          | ActionResponse<IDocumentPdf>
          | undefined = await execFetchAccountingDocument(id);
        if (res) {
          const linkSource = `data:application/pdf;base64,${res.data.data.pdf_base64}`;
          const downloadLink = document.createElement("a");
          const fileName = "dokument.pdf";
    
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      };

      
      return {downloadFile};



}
