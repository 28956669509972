import React, { useState, useEffect, useRef } from "react";
import Table from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import withLayout from "components/layout/MyLayout";
import Modal from "react-modal";
import { withAuthSync } from "utils/auth";
import DocumentEditForm from "components/admin/document-edit-form";

import {
  IDocuments,
  IDocumentFile,
  IDocumentsGroup,
  ISimpleDocument,
} from "types/document-types";
import Button from "components/buttons/button";
import { IActionState, ActionResponse } from "types/async-types";
import { useAsyncAction } from "utils/async-action";
import {
  fetchDocuments,
  fetchDocument,
  fetchDocumentsGroup,
  saveGroup,
} from "actions/accounting-documents";
import Spinner from "components/core/spinner";
import Menu from "components/admin/documents-group-menu";
import { useForm } from "react-hook-form";
import { group } from "console";
import { useParams, Link } from "react-router-dom";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import FormButton from "components/buttons/form-button";
import { Row, Col } from "antd";
import { PAGE } from "paths";

interface IFormValues {
  invoice_number: string;
  issue_date: string;
}

const DocumentsGroupEdit = (props: any) => {
  const { id }: { id: string } = useParams();

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const formMethods = useForm({
    mode: "onChange",
  });

  const [groupState, execFetchGroup]: readonly [
    IActionState<IDocumentsGroup>,
    typeof fetchDocumentsGroup
  ] = useAsyncAction(fetchDocumentsGroup);

  const [documentsState, setDocumentsState] = useState<ISimpleDocument[]>([]);

  const [saveState, execSave]: readonly [
    IActionState<IDocumentsGroup>,
    typeof saveGroup
  ] = useAsyncAction(saveGroup);

  useEffect(() => {
    execFetchGroup(id);
  }, []);

  useEffect(() => {
    if (saveState.data) {
      execFetchGroup(id);
    }
  }, [saveState.data]);

  useEffect(() => {
    if (groupState.data) {
      formMethods.setValue("name", groupState.data.name);
      setDocumentsState(groupState.data.documents);
    }
  }, [groupState.data]);

  const NewDocumentModal = () => (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setModalIsOpen(false)}
      className="modal"
    >
      {groupState.loading && <Spinner />}
      <DocumentEditForm execFetchGroup={execFetchGroup} />
    </Modal>
  );

  const renderDocumentForms = () => {
    return documentsState.map((item, index) => {
      return (
        <div className="b-bottom mb-20 block">
          <DocumentEditForm document={item} execFetchGroup={execFetchGroup} />{" "}
        </div>
      );
    });
  };

  const onSubmit = (values: any) => {
    execSave(id, values);
  };

  return (
    <div className="page-content">
      <NewDocumentModal />
      {groupState.loading && <Spinner size="large" />} 
      <div className="breadcrumbs">
        Integrator <span className="arrow">»</span>
        <Link to={PAGE.ADMIN_DOCUMENTS}>Dokumenty</Link> <span className="arrow">»</span>
        <a href="#">{groupState.data?.name}</a>
      </div>
      <header className="page-header">
        <h1>{groupState.data?.name}</h1>
      </header>
      <div className="block mb-20">
        <Row gutter={15}>
          <Col md={20}>
            <TextField formMethods={formMethods} label="" name="name" />{" "}
          </Col>
          <Col md={4}>
            <FormButton
              title="Zapisz nazwę grupy"
              color="primary"
              onClick={formMethods.handleSubmit(onSubmit)}
            />{" "}
          </Col>{" "}
        </Row>
      </div>
      {renderDocumentForms()}
      <div className="t-right">
      <Button
      inline={true}
      className=" mb-20"
        title="Dodaj dokument"
        onClick={() => {
          setDocumentsState((e) => [...e, {}]);
        }}
        color="primary"
      />
      </div>
    </div>
  );
};

export default withAuthSync(withLayout(DocumentsGroupEdit));
