import React, { useState, useEffect } from "react";
import AddressModal from "./waste-address-modal";
import SelectField from "components/form-fields/select-field"
import _ from "lodash";
import Row from "antd/lib/grid/row";
import Col from "antd/lib/grid/col";

interface IWasteAddressComponent {
  adding_disabled?: boolean;
  input_disabled?: boolean;
  wasteCardMode?: boolean;
  empty_allowed?: boolean;
  setFieldValue: (a1: string, a2: any) => void;
  onChange?: (a: string) => void;
  values: any;
  addressesState: any;
  addressField: any;
}

function WasteAddress(props: IWasteAddressComponent) {
  const {
    values,
    addressesState,
    addressField,
    adding_disabled,
    onChange,
    input_disabled,
    empty_allowed,
  } = props;
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [addressOpts, setAddressOpts] = useState([]);

  let field = {
    ...addressField,
    options: addressOpts,
    disabled: input_disabled,
    
  };
  if (onChange) {
    field.onChange = onChange;
  }
  const {
    street,
    locum_number,
    building_number,
    city,
    postal_code,
  }: {
    street?: string;
    locum_number?: string;
    building_number?: string;
    city?: string;
    postal_code?: string;
  } = selectedAddress;

  // useEffect(() => {
  //   
  //   execFetchAddresses();
  // }, [selectedAddress]);

  /** set selectedAddress OBJECT prop on every address dropdown (id) change */
  useEffect(() => {
    const addressId = values[field.WASTE_ADDRESS];
    if (addressId) {
      const selectedAddress = _.find(addressesState.data, (item) => {
        return item.id == addressId;
      });
      setSelectedAddress(selectedAddress);
    }
  }, [values[field.WASTE_ADDRESS]]);

  useEffect(() => {
    const addresses = addressesState.data;
    if (addresses) {
      if (!addresses.length) {
        setAddressOpts([]);
      } else {
        let opts = addresses.map((item: any, index: number) => {
          return {
            label: `${item.street} ${item.building_number}${
              item.locum_number ? `/${item.locum_number}` : ""
            } ${item.postal_code} ${item.city}`,
            value: item.id,
          };
        });

        if (empty_allowed && opts.length > 1) {
          opts.unshift({ label: `Wszystkie`, value: "", default: true });
        }else{
          opts[0].default = true;
        }

        setAddressOpts(opts);
      }
    }
  }, [addressesState.data]);

  const addressModalProps = {
    values,
    addressesState,
    setSelectedAddress,
    modalIsOpen: addressModalIsOpen,
    setModalIsOpen: setAddressModalIsOpen,
    // execFetchAddresses
  };
  
  return (
    <>
      <AddressModal {...addressModalProps} />

      <Row>
        <Col md={14}>
          <SelectField {...field} />
        </Col>
        {!adding_disabled && (
          <Col>
            <a
              className="primary-link"
              onClick={() => setAddressModalIsOpen(true)}
            >
              dodaj adres
            </a>
          </Col>
        )}
      </Row>
    </>
  );
}

export default WasteAddress;
