import React from 'react';
import SimpleLayout from 'components/layout/simple-layout';

const NotFound = () => {


    return (<div className="bdo-report-form">
        <div className="form-content">
            <div className="block block--full-height flex-center">
                <div>
                    <h1 className={`primary t-center t-500 mb-30`} style={{ fontSize: "60px" }}> 404 </h1>
                    <h1 className="primary t-center"> Strona o podanym adresie nie istnieje </h1>

                </div>
            </div>
        </div>
    </div>)

}

export default SimpleLayout(NotFound);