import React from "react";
//@ts-ignore
import "styles/layout/logo.scss";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { PAGE } from "paths";
import ReactGA from "react-ga";
interface IProps {
  onClick?: () => void;
  link? :string;
}
const Logo = (props: IProps) => {
  const handleClick = () => {
    let match = false;
    let actionText = "Kliknięto logo w: ";
    switch (window.location.pathname) {
      case PAGE.BDO_INFO:
        actionText = `${actionText}ekran powitalny`;
        match = true;
        break;
      case PAGE.STEP_1:
        actionText = `${actionText}krok 1`;
        match = true;
        break;
      case PAGE.STEP_2:
        actionText = `${actionText}krok 2`;
        match = true;
        break;
      case PAGE.STEP_3:
        actionText = `${actionText}WS krok 3`;
        match = true;
        break;
    }
    if (match) {
      ReactGA.initialize("UA-212968889-1");
      ReactGA.event({
        category: "Zamknięcia WS",
        action: actionText,
      });
    }
  };

  return (
    <div className="nav-logo">
      <Link onClick={handleClick} to={props.link || "/"}>
        <h1>
          {!isMobile && (
            <img
              alt="ecar-logo"
              onClick={props.onClick}
              src="/static/img/integrator.ico"
            ></img>
          )}
          integrator
          {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
        </h1>
      </Link>
    </div>
  );
};

export default Logo;
