import {
  FETCH_NOTIFICATIONS
} from 'types/redux-types'
import _ from 'lodash'

export default function (state = {}, action: any) {

  switch (action.type) {
    case FETCH_NOTIFICATIONS:
      return action.payload.notifications
  default:
    return state;
}
}
