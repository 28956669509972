import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL } from "config";
import { requestHeaders } from "utils/auth";
import { IElectronicsSecondStepFormValues, IElectronicsThirdStepFormValues } from "types/products/electronics-types";


const PRODUCT_GROUPS_URL = `${API_BASE_URL}/product_groups/`
const ELECTRONIC_DECLARATIONS_URL = `${API_BASE_URL}/electronic_declarations`


export const fetchProductGroups = async () => {
    const options: AxiosRequestConfig = {
      url: `${PRODUCT_GROUPS_URL}`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<{}> = await axios(options);
    if (!res) {
      return undefined;
    }
    return res;
  };

  export const fetchEelectronicsDeclaration = async (id:string) => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/${id}`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };

  export const saveElectronicsFirstStep = async (data : any) => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/first_step`,
      method: "POST",
      headers: requestHeaders().headers,
      data
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };


  export const getCostEstimation = async (data : any ) => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/form_data/first_step`,
      method: "POST",
      headers: requestHeaders().headers,
      data
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };

  export const fetchElectronicsFirstStepFormData = async () => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/form_data/first_step`,
      method: "POST",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };

  export const fetchElectronicsSecondStepFormData = async (id:string) => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/${id}/form_data/second_step`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };

  export const fetchElectronicsThirdStepFormData = async (id:string) => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/${id}/form_data/third_step`,
      method: "GET",
      headers: requestHeaders().headers,
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };

  export const saveElectronicsSecondStep = async (id:string, data : IElectronicsSecondStepFormValues) => { 
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/${id}/second_step`,
      method: "POST",
      headers: requestHeaders().headers,
      data
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };


  export const saveElectronicsThirdStep = async (id:string, data : IElectronicsThirdStepFormValues) => {
    const options: AxiosRequestConfig = {
      url: `${ELECTRONIC_DECLARATIONS_URL}/${id}/third_step`,
      method: "POST",
      headers: requestHeaders().headers,
      data
    };
  
    let res: AxiosResponse<any> = await axios(options);
   return res;
  };


