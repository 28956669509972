import React from "react";
import style from "./alert.module.scss";

interface IProps {
  message: string;
  errorsList?: string[];
  type?: "error";
}

export const AlertWrapper = (props: any) => {
  return <div className={`${style.top_message}`}>{props.children}</div>;
};

const Alert = (props: IProps) => {
  const { message, errorsList, type } = props;
  const renderErrorList = (list: any) => {
    return list.map((item: String, index: Number) => {
      return <div className={style.error}>{item}</div>;
    });
  };
  return (
    <div
      className={`${style.top_message} ${
        type === "error" && style.error_message
      }`}
    >
      {/* <div className={style.header} >
          
      </div> */}
      {message ? message : renderErrorList(errorsList)}
    </div>
  );
};

export default Alert;
