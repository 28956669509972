import React, { useState, useEffect, useRef } from "react";
import Table from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import TextField from "components/form-fields/formik-text-field";
import OverdueInvoices from './overdue-invoices';
import PaidInvoices from './paid-invoices';
import PendingInvoices from './pending-invoices';
import { mockDocuments } from "./mock-documents"
import TableWrapper from "components/common/table-wrapper";
import { Row, Col } from "antd";
import TestTable from './antd-table'
import {
  IAccountingDocument,
  IAccountingDocuments,
  IDocumentPdf,
  documentTypes,
  ISeller,
} from "types/document-types";
import SelectField from "components/form-fields/select-field";
import { IActionState, ActionResponse } from "types/async-types";
import {
  fetchAccountingDocument,
  fetchAccountingDocuments,
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { renderOptions, renderJsonOptions } from "utils/select-options";
import Button from "components/buttons/button";
import { renderBreadcrumbs } from "utils/helpers";

interface IFormValues {
  number?: string;

  seller?: string;
  draw_date_from?: string;
  draw_date_to?: string;
}

const fieldNames = {
  invoice_number: "number",
  issue_date: "draw_date",
  seller: "seller",
  draw_date_from: "draw_date_from",
  draw_date_to: "draw_date_to",
};

const Settlements = (props: any) => {
  const [timeoutVar, setTimeoutVar] = useState<undefined | any>(undefined);
  const [tableData, setTableData] = useState<any>({});

  const [documentsState, execFetchAccountingDocuments]: readonly [
    IActionState<IAccountingDocuments>,
    typeof fetchAccountingDocuments
  ] = useAsyncAction(fetchAccountingDocuments);

  const [documentFileState, execFetchAccountingDocument]: readonly [
    IActionState<IDocumentPdf>,
    typeof fetchAccountingDocument
  ] = useAsyncAction(fetchAccountingDocument);

  const downloadFile = async (id: string) => {
    const res:
      | ActionResponse<IDocumentPdf>
      | undefined = await execFetchAccountingDocument(id);
    if (res) {
      const linkSource = `data:application/pdf;base64,${res.data.data.pdf_base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "dokument.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  useEffect(() => {
    execFetchAccountingDocuments();
    setTableData(getTableData());
  }, []);

  const renderSellerOpts = (sellers: { [key: string]: ISeller } | undefined) => {
    if (sellers) {
      const result = Object.keys(sellers).map(key => {
        return {
          value: key,
          label: sellers[key].name
        }
      })
      result.unshift({
        value: "ALL",
        label: "Wszyscy"
      })
      return result;
    } else {
      return []
    }

  }

  const renderColumns = () => {
    return [
      {
        title: "Data",
        align: "left",
        width: 50,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.draw_date}</div>
        ),
      },
      {
        title: "Typ",
        align: "left",
        width: 70,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap column-upper">{documentTypes[row.type]}</div>
        ),
      },
      {
        title: "Numer",
        align: "left",
        width: 100,
        render: (field: any, row: IAccountingDocument) => (
          <a onClick={() => downloadFile(row.id)}>{row.number}</a>

        ),
      },
      {
        title: "Sprzedający",
        align: "left",
        width: 150,
        render: (field: any, row: IAccountingDocument) => (
          <div>{row.seller}</div>
        ),
      },
      {
        title: "Termin płatności",
        align: "left",
        width: 150,
        render: (field: any, row: IAccountingDocument) => (
          <div className="no-wrap">{row.payment_due_date}</div>
        ),
      },
      {
        title: "Dni po terminie",
        align: "left",
        width: 80,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap`}
          >
            1
          </div>
        ),
      },
      {
        title: "Kwota do zapłaty",
        align: "left",
        width: 120,
        render: (field: any, row: IAccountingDocument) => (
          <div
            className={`no-wrap t-500 ${row.payment_date ? "green" : "red"}`}
          >
            {" "}
            {row.brutto} zł
          </div>
        ),
      },
      // {
      //   title: "Płatność",
      //   align: "left",
      //   width: 130,
      //   render: (field: any, row: IAccountingDocument) => (
      //     <div className="no-wrap">
      //       {row.payment_date ? (
      //         <>
      //           <span className="green">Opłacono </span>
      //           <span>({row.payment_date})</span>
      //         </>
      //       ) : (
      //         <>
      //           <span className="red">Nie opłacono </span>
      //         </>
      //       )}
      //     </div>
      //   ),
      // },
      {

        align: "left",
        width: 120,

        render: (field: any, row: IAccountingDocument, index: any) => ({
          children: index === 0 ? <div className={`absolute-btn`}>
            <Button width={150} title="Przelew on-line" color="primary" onClick={() => { }} />
          </div> : <>  </>,
          props: {
            style: { position: "static" }
          },

        }),
      },
    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };
  const timoutRef = useRef();
  timoutRef.current = timeoutVar;

  const onSubmit = (values: IFormValues) => {


    execFetchAccountingDocuments(values);
  };




  return (
    <Formik
      initialValues={{
      }}
      onSubmit={onSubmit}
    >
      {(formikProps: FormikProps<IFormValues>) => {
        const handleValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          // formikProps.setFieldValue("search_term", e.target.value);

          if (e.target.value === "Enter") {
            e.preventDefault();
          }

          clearTimeout(timoutRef.current);

          setTimeoutVar(setTimeout(formikProps.submitForm, 500));
        };

        const handleSelectChange = (e: string) => {
          clearTimeout(timoutRef.current);
          formikProps.submitForm();
        };

        return (
          <div className="page-content">
            {documentsState.loading && <Spinner size="large" />}
            <div className="breadcrumbs">
             {renderBreadcrumbs(['Rozliczenia'])}
            </div>
            <header className="page-header">
              <h1>Rozliczenia</h1>
            </header>
            <div className="list-filters mb-20">
              <Row gutter={4} type="flex" align="bottom">
                <Col xxl={17}>
                  <Row gutter={4}>
                  <Col md={6}>
                  <TextField
                    placeholder="Nr faktury"
                    type="text"
                    name={fieldNames.invoice_number}
                    label="Nr faktury"
                    onChange={(e: any) => {
                      formikProps.setFieldValue(
                        fieldNames.invoice_number,
                        e.target.value
                      );
                      // handleValChange(e);
                    }}
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    data-test="name"
                    type="date"
                    name={fieldNames.draw_date_from}
                    label="Data wystawienia (od)"
                    onChange={(e) => {
                      formikProps.setFieldValue(fieldNames.draw_date_from, e);
                      // handleSelectChange(e);
                    }}
                  />
                </Col>
                <Col md={4}>
                  <TextField
                    data-test="name"
                    type="date"
                    name={fieldNames.draw_date_to}
                    label="Data wystawienia (do)"
                    onChange={(e) => {
                      formikProps.setFieldValue(fieldNames.draw_date_to, e);
                      // handleSelectChange(e);
                    }}
                  />
                </Col>
                <Col md={10}>
                  <SelectField
                    name="seller"
                    label="Sprzedający"
                    options={
                      renderSellerOpts(documentsState.data?.sellers)
                    }
                    // onChange={handleSelectChange}
                  />
                </Col>
                  </Row>
                </Col>
             
                <Col xxl={7} className="t-right">
              
                
                  <Button
                    title="Filtruj"
                    color="primary"
                    className="mr-5 mb-20"
                    width={150}
                    inline={true}
                    onClick={formikProps.submitForm}
                  />
             
                
                  <Button
                    title="Wyczyść"
                    className="mb-20"
                    color="white"
                    width={150}
                    inline={true}
                    onClick={() => {formikProps.resetForm(); execFetchAccountingDocuments(); }}
                  />
                     
                
              
            </Col>
              </Row>
          
            </div>
            <span className="grow-1 i-b mb-20">
              {/* Wszystkie dokumenty:{" "} */}
              <span className="bold">
                {/* {documentsState.data ? documentsState.data.data.length : 0}{" "} */}
              </span>
            </span>

            {/* <div className="block mb-20">
            
                <h2 className="primary h-lg mb-20">Przeterminowane</h2>
                {(documentsState.data && documentsState.data.expired_total > 0)  &&
                <>
                     <p className="lg-t grey">Odnotowaliśmy brakujące rozliczenie na Twoim koncie. Łącznie pozostaje do zapłaty <span className="price red bold">{documentsState.data?.expired_total} zł</span> brutto</p>
                </>
                }
                 
                    <OverdueInvoices invoicesState={documentsState.data?.expired} />
            </div> */}
            <div className="mb-20">

              <h2 className="primary h-lg mb-10">Do zapłaty</h2>
              <p className="lg-t grey">Lista dokumentów księgowych oczekujących na zapłatę.</p>
              <PendingInvoices invoicesState={documentsState.data?.current} />
            </div>
            <div className="">

              <h2 className="primary h-lg mb-10">Zapłacone</h2>
              <p className="lg-t grey">Lista opłaconych dokumentów księgowych.</p>
              <PaidInvoices invoicesState={documentsState} />
            </div>
            {/* <TestTable /> */}
          </div>
        );
      }}
    </Formik>
  );
};

export default withAuthSync(withLayout(Settlements));
