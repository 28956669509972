import React, { useState, useRef, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import LoopIcon from "@material-ui/icons/Loop";
import { withAuthSync } from "utils/auth";
import OOOrderForm from "../../components/oo-order/oo-order-form";
import CloseIcon from "@material-ui/icons/Close";
import CheckFormField from "components/form-fields/check-form-field";
import Button from "components/buttons/button";
import {
  IOOSuborderItem,
  IOOSummaryFormValue,
  OOSubordersList,
  IOOSuborderFormValues,
  IListFormValues,
} from "types/recovery-organization-types";
import Table, { ColumnProps } from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import { IActionState } from "types/async-types";
import {
  submitOOOrderSearch,
  filterOOSummary,
  submitSubordersList,
  filterOOSuborders,
} from "actions/oo-orders";
import { useAsyncAction } from "utils/async-action";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import { useForm } from "react-hook-form";
import FormButton from "components/buttons/form-button";
import TableWrapper from "components/common/table-wrapper";
import { Link } from "react-router-dom";

const OOOrders = () => {
  const formRef = useRef<FormikProps<any>>(null);
  const [modalVisible, setModalVisible] = useState(false);
  const formMethods = useForm<IOOSuborderFormValues>({
    mode: "onChange",
  });

  const [filterResultState, execFilterResult]: readonly [
    IActionState<OOSubordersList>,
    typeof filterOOSuborders
  ] = useAsyncAction(filterOOSuborders);

  const [selectedState, execSubmitSelected]: readonly [
    IActionState<any>,
    typeof submitSubordersList
  ] = useAsyncAction(submitSubordersList);

  const onFiltersFormSubmit = (values: IOOSuborderFormValues) => {
    execFilterResult(values);
  };

  const onListSubmit = (values: IListFormValues) => {
    execSubmitSelected(values);
  };

  useEffect(() => {
    // wstępne wybranie elementów
    execFilterResult({});
  }, []);

  const tableData = {
    filtersCollapsed: true,
    // pagination: false,
    loading: false,
    showHeader: false,

    title: () => (
      <div className="table_title">
        <a onClick={selectAll}>Zaznacz wszystko</a>
      </div>
    ),

    columns: [
      {
        width: 40,
        render: (field: any, row: IOOSuborderItem) => (
          <div className="checkbox">
            <CheckFormField
              name={`selected_ids`}
              id={`selected_items_${row.id}`}
              value={`${field.id}`}
              keyValues={true}
            />
          </div>
        ),
      },
      {
        width: 150,
        title: "CBK_ID",

        render: (field: any, row: IOOSuborderItem) => row.cbk_id,
      },
      {
        title: "Nazwa klienta",
        render: (field: any, row: IOOSuborderItem) => row.customer_name,
      },
      {
        title: "Numer umowy",
        render: (field: any, row: IOOSuborderItem) => row.agreement_number,
      },
      {
        title: "Produkt",
        render: (field: any, row: IOOSuborderItem) => row.product,
      },
      {
        title: "Ilość sprzętu",
        width: 100,
        render: (field: any, row: IOOSuborderItem) => row.item_count,
      },
      {
        title: "Masa sprzętu",
        render: (field: any, row: IOOSuborderItem) => row.item_mass + " kg",
      },
    ] as ColumnProps<IOOSuborderItem>[] | undefined,
  };

  const selectAll = () => {
    let ids: Array<string> = [];
    if (filterResultState.data?.list) {
      filterResultState.data.list.forEach((item, index: number) => {
        ids[index] = item.id;
      });
    }

    formRef.current?.setFieldValue("selected_ids", ids);
    formRef.current?.setFieldValue("select_all", "Y");
  };

  const setPage = (page: number) => {
    const values = {
      ...formMethods.getValues(),
      page,
    };
    execFilterResult(values);
  };
  return (
    <div className="sider-page">
      <main className="page-content">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span>
          <a href="#">
            {" "}
            Lista wygenerowanych zamówień dla Organizacji Odzysku{" "}
          </a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">
            Lista wygenerowanych zestawień dla Organizacji Odzysku
          </h1>
        </header>
        <div className="list-filters">
          <Row type="flex" align="top" gutter={15}>
            <Col md={6}>
              <TextField
                name="year"
                formMethods={formMethods}
                label="Rok rozliczenia"
              />
            </Col>
            <Col md={6}>
              <SelectField
                options={[]}
                name="agreement_id"
                formMethods={formMethods}
                label="Rodzaj umowy"
              />
            </Col>
            <Col md={6}>
              <TextField
                name="create_date"
                formMethods={formMethods}
                label="Data dodania"
                type="date"
                maxDate={new Date()}
              />
            </Col>
            <Col md={6}>
              <Row className="mt-15" gutter={10}>
                <Col md={12}>
                  <Button
                    title="Filtruj"
                    color="primary"
                    onClick={formMethods.handleSubmit(onFiltersFormSubmit)}
                  />
                </Col>
                <Col md={12}>
                  <Button
                    title="Wyczyść"
                    color="white"
                    onClick={() => formMethods.reset()}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <Formik
          innerRef={formRef}
          onSubmit={onListSubmit}
          initialValues={{ selected_ids: [] }}
        >
          {(formikProps: FormikProps<IListFormValues>) => {
            return (
              <>
                <TableWrapper
                  className="grow-1"
                  fetchData={
                    filterResultState.data && {
                      current_page: filterResultState.data?.current_page,
                      total_pages: filterResultState.data?.total_pages,
                    }
                  }
                  setPage={setPage}
                  addPagination={true}
                >
                  <Table
                    size="middle"
                    title={tableData.title}
                    locale={{ emptyText: "Brak rekordów" }}
                    rowKey="uuid"
                    columns={tableData.columns}
                    bordered={false}
                    dataSource={filterResultState.data?.list}
                    pagination={false}
                    loading={tableData.loading}
                    className="custom-ant-table"
                  />
                </TableWrapper>
                <div className="t-right">
                  <FormButton
                    color="primary"
                    width={200}
                    className="i-b"
                    onClick={formikProps.handleSubmit}
                    title="Wyślij zaznaczone"
                    // disabled={!isValid}
                  />
                </div>
              </>
            );
          }}
        </Formik>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(OOOrders));
