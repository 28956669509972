import React from "react";
import { Field } from "formik";
import CheckFormField from "components/form-fields/check-form-field";
import { BooleanSchema } from "yup";
import { ICheckFormFieldProps } from "components/form-fields/check-form-field";
//@ts-ignore
import "styles/components/input.scss";
import { IVehicleProductAttribute } from "types/vehicle-types";
import { numToCurrency } from "utils/helpers";

type CheckProps = React.ComponentProps<typeof CheckFormField>;

export interface ICheckGroupProps<T> {
  name: string;
  label: string | false;
  orientation: "horizontal" | "vertical";
  testId?: string;
  options: Array<Omit<ICheckFormFieldProps<T>, "name">>;
  type?: "checkbox-group";
  required?: boolean;
}
const units: { [key: string]: string } = {
  KG: "kg",
  T: "t",
  ITEM: "Sztuk",
  UNDEFINED: "",
};

const CheckFormFields = (props: ICheckGroupProps<any>) => {
  const { options, name, orientation, label, required } = props;
  const result = options.map((item, index) => {
    return (
      <tr key={index + "_check"}>
        <td>
          <div>
            <CheckFormField  {...item} name={props.name} disableMessage={true} />{" "}
          </div>
        </td>
        <td className="t-right pr-8">
          <div>
            <span className="no-wrap md-t">
              {
                item.additionalAttributes.weight &&
                  numToCurrency(item.additionalAttributes.weight, {
                    noCurrency: true,
                  })
                // `${
                //   item.additionalAttributes.unit === "T" ||
                //   item.additionalAttributes.unit === "KG"
                //     ? item.additionalAttributes.weight.toFixed(2)
                //     : item.additionalAttributes.weight
                // } ${units[item.additionalAttributes.unit]}`
              }
            </span>{" "}
          </div>
        </td>
        <td className="t-right md-t">
          <div>
            {item.additionalAttributes.hasSubscription ? (
              <div >Abonament</div>
            ) : !item.additionalAttributes.hasAgreement ? (
              <div className="grey">Brak umowy</div>
            ) : (
              item.additionalAttributes.price && (
                <div>
                  {numToCurrency(item.additionalAttributes.price, {
                    noCurrency: true,
                  })}
                </div>
              )
            )}
            {/* {!item.additionalAttributes.hasAgreement ? <div className="grey md-t">Brak umowy</div> :
              item.additionalAttributes.price &&
              numToCurrency(item.additionalAttributes.price, { noCurrency: true })
            } */}
            <span className="md-t t-500 no-wrap black price"> </span>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <div className={`check-form-fields product-attributes ${orientation}`}>
      {label && (
        <div
          className={`ant-form-item-label mb-15 ${
            required ? "ant-form-item-required" : ""
          }`}
        >
          <label>{label}</label>
        </div>
      )}
      <table>
        <thead>
          <tr>
            <th className="sm-t grey product">Produkt</th>
            <th className="sm-t grey pr-8 t-right">Waga [kg]</th>
            <th className="sm-t grey t-right">Opłata [zł]</th>
          </tr>
        </thead>
        <tbody>{result}</tbody>
      </table>
      {/* {process.env.NODE_ENV !== "test" && (
        <style jsx global>
          {s1}
        </style>
      )} */}
    </div>
  );
};

export default CheckFormFields;
