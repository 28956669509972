import React, { useEffect, useState } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import { ColumnProps } from "antd/lib/table";
import AntdTableWrapper from "components/common/antd-table-wrapper";
import { IActionState, IListing } from "types/async-types";
import Spinner from "components/core/spinner";
import { useAsyncAction } from "utils/async-action";
import Button from "components/buttons/button";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import { useForm } from "react-hook-form";
import {
  IAdminBDOPayment,
  IAdminPaymentsList,
} from "types/BDO-report-types";
import { renderBreadcrumbs, numToCurrency } from "utils/helpers";
import {
  fetchAdminBDOReportPayments,
  confirmBDOReportPayment,
} from "actions/admin";
import { renderJsonOptions } from "utils/select-options";
import { confirmTransfer, generateInvoice } from "actions/BDO-reports";


export interface IOSOP1FiltersFormValues {
  cbk_id?: string;
  date_from?: string;
  page?: number;
  date_to?: string;
}
const TransferList = () => {
  const [paymentsState, execFetchPayments]: readonly [
    IActionState<IListing<IAdminBDOPayment> & IAdminPaymentsList>,
    typeof fetchAdminBDOReportPayments
  ] = useAsyncAction(fetchAdminBDOReportPayments, { no_reset: true });

  const [confirmState, execConfirm]: readonly [
    IActionState<any>,
    typeof confirmBDOReportPayment
  ] = useAsyncAction(confirmBDOReportPayment);

  const formMethods = useForm<IOSOP1FiltersFormValues>({
    mode: "onChange",
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [generateConfirmState, execGenerateConfirm]: readonly [
    IActionState<any>,
    typeof confirmTransfer
  ] = useAsyncAction(confirmTransfer);

  const [generateInvoiceState, execGenerateInvoice]: readonly [
    IActionState<any>,
    typeof generateInvoice
  ] = useAsyncAction(generateInvoice);

  useEffect(() => {
    if (confirmState.data || generateInvoiceState.data) {
      execFetchPayments({ ...formMethods.getValues(), page: currentPage });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmState.data, generateInvoiceState.data]);

  useEffect(() => {
    execFetchPayments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderColumns = (): ColumnProps<any>[] => {
    return [
      {
        title: "CBK ID",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">
            {row.cbk_id}
          </div>
        ),
      },
      {
        title: "Tytuł płatności",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">{row.description}</div>
        ),
      },
      {
        title: "Wartość",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">{numToCurrency(row.amount)}</div>
        ),
      },
      {
        title: "Sposób płatności",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">{row.payment_type}</div>
        ),
      },
      {
        title: "Id zdarzenia bilingowego",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">{row.billing_id || "N/D"}</div>
        ),
      },
      {
        title: "Zapłacono",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">{row.payment_status === "FIN" ? <span className="green">TAK </span> : "NIE"}</div>
        ),
      },
      {
        title: "Dokument księgowy",
        align: "left",

        render: (field: any, row: IAdminBDOPayment) => (
          <div className="no-wrap">{row.document_number}</div>
        ),
      },
      {
        title: "Akcje",

        render: (field: any, row: IAdminBDOPayment) => (
          <>
            <div className="no-wrap">{
              row.generate_payment && <Button
                title="Wygeneruj billing"
                color="white"
                inline={true}
                width={150}
                onClick={() => {
                  execGenerateConfirm([], row.report_id, row.seller_id);
                }}
              />
            }
              {
                row.create_document && <Button
                  title="FV"
                  color="white"
                  className="mr-10"
                  inline={true}
                  width={70}
                  onClick={() => {
                    execGenerateInvoice(row.id, false);
                  }}
                />
              }
              {
                row.create_document && <Button
                  title="FV +"
                  color="white"
                  width={70}
                  inline={true}
                  onClick={() => {
                    execGenerateInvoice(row.id, true);
                  }}
                />
              }</div>
          </>
        ),
      },
    ];
  };

  const setPage = (page: number) => {
    setCurrentPage(page);
    execFetchPayments({ ...formMethods.getValues(), page: page });
  };

  const onSubmit = (values: any) => {
    execFetchPayments({ ...values, page: 1 });
  };

  return (
    <div className="sider-page">
      {(paymentsState.loading || confirmState.loading || generateConfirmState.loading || generateInvoiceState.loading) && (
        <Spinner size="large" />
      )}
      <main className="page-content">
        <div className="breadcrumbs">
          {renderBreadcrumbs(["Administrator", "Płatności"])}
        </div>
        <header className="page-header">
          <h1 className="grow-1">Płatności</h1>
        </header>
        <div className="list-filters">
          <Row type="flex" align="middle" gutter={15}>
            <Col md={3}>
              <TextField
                name="cbk_id"
                formMethods={formMethods}
                label="CBK ID"
              />
            </Col>
            <Col md={8}>
              <TextField
                name="title"
                formMethods={formMethods}
                label="Tytuł"
              />
            </Col>
            <Col md={6}>
              <SelectField
                name="group"
                formMethods={formMethods}
                onChange={formMethods.handleSubmit(onSubmit)}
                label="Status faktury"
                options={renderJsonOptions(paymentsState.data?.groups || [])}
              />
            </Col>
            <Col md={7} className="t-right">
              <Button
                title="Filtruj"
                color="primary"
                inline={true}
                width={150}
                onClick={formMethods.handleSubmit(onSubmit)}
                className="mr-5"
              />
              <Button
                title="Wyczyść"
                color="white"
                inline={true}
                width={150}
                onClick={() => {
                  formMethods.reset();
                  execFetchPayments();
                }}
              />
            </Col>
          </Row>
        </div>

        <AntdTableWrapper
          setPage={setPage}
          data={paymentsState.data}
          columns={renderColumns()}
          tableProps={{
            rowKey: "uuid",
            className: "custom-ant-table mb-10",
          }}
        />
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(TransferList));
