import React, { useState, useEffect, useRef } from "react";
import Table, { ColumnProps } from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import TextField from "components/form-fields/formik-text-field";
import TableWrapper from "components/common/table-wrapper";
import { Row, Col } from "antd";
import {
  IAccountingDocument,
  IAccountingDocuments,
  IDocumentPdf,
  documentTypes,
  ISellerDocumentsDebt,
} from "types/document-types";
import SelectField from "components/form-fields/select-field";
import { IActionState, ActionResponse } from "types/async-types";
import {
  fetchAccountingDocument,
  fetchAccountingDocuments,
} from "actions/accounting-documents";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import { renderOptions, renderJsonOptions } from "utils/select-options";
import Button from "components/buttons/button";
import _ from "lodash";
import {mockDocuments} from './mock-documents';
import { PAGE } from "paths";
import { numToCurrency } from "utils/helpers";



export function compareUnpaid( a : IAccountingDocument, b: IAccountingDocument ) {
    if ( a.draw_date < b.draw_date ){
      return -1;
    }
    if ( a.draw_date > b.draw_date ){
      return 1;
    }
    return 0;
  }

  export function comparePaid( a : IAccountingDocument, b: IAccountingDocument ) {
    if ( a.draw_date > b.draw_date ){
      return -1;
    }
    if ( a.draw_date < b.draw_date ){
      return 1;
    }
    return 0;
  }

const OverdueInvoices = (props: {
  invoicesState?: ISellerDocumentsDebt[];
}) => {
  const { invoicesState } = props;
  const [tableData, setTableData] = useState<any>({});
  const [documentFileState, execFetchAccountingDocument]: readonly [
    IActionState<IDocumentPdf>,
    typeof fetchAccountingDocument
  ] = useAsyncAction(fetchAccountingDocument);

  const downloadFile = async (id: string) => {
    const res:
      | ActionResponse<IDocumentPdf>
      | undefined = await execFetchAccountingDocument(id);
    if (res) {
      const linkSource = `data:application/pdf;base64,${res.data.data.pdf_base64}`;
      const downloadLink = document.createElement("a");
      const fileName = "dokument.pdf";

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    }
  };

  useEffect(() => {
    setTableData(getTableData());
  }, []);


  const expandedRowRender = (parent: ISellerDocumentsDebt) => {
    
  const columns : ColumnProps<IAccountingDocument>[] = [
    {
      title: "Data",
      align: "left",
      width: 100,
      render: (field: any, row: IAccountingDocument) => (
        <div className="no-wrap">{row.draw_date}</div>
      ),
    },
    {
      title: "Typ",
      align: "left",
      width: 200,
      render: (field: any, row: IAccountingDocument) => (
        <div className="no-wrap column-upper">{documentTypes[row.type]}</div>
      ),
    },
    {
      title: "Numer",
      align: "left",
      width: 140,
      render: (field: any, row: IAccountingDocument) => (
        <a onClick={() => downloadFile(row.id)}>{row.number}</a>
      ),
    },
    {
      title: "Sprzedający",
      align: "left",
      width: 450,
      render: (field: any, row: IAccountingDocument) => (
        <div>{row.seller_string}</div>
      ),
    },
    {
      title: "Termin płatności",
      align: "left",
      width: 120,
      render: (field: any, row: IAccountingDocument) => (
        <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{row.payment_due_date}</div>
      ),
    },
    {
      title: "Dni do terminu płatności",
      align: "left",
      width: 160,
      render: (field: any, row: IAccountingDocument) => (
        <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{row.days_due_date}</div>
      ),
    },
    {
      title: "Kwota do zapłaty",
      align: "left",
      width: 120,
      render: (field: any, row: IAccountingDocument) => (
        <div className={`no-wrap t-500 black`}>{row.brutto} zł</div> 
      ),
    },
  ];


  return <div className="p-10"><Table className="custom-ant-table" columns={columns} dataSource={parent.items} pagination={false} /> </div>;
};



  const renderColumns = () => {
    return [
      {
        title: "Sprzedawca",
        align: "left",
        width: 100,
        render: (field: any, row: ISellerDocumentsDebt) => (
          <div className="no-wrap">{row.seller_string}</div>
        ),
      },
      {
        title: "Łączna kwota",
        align: "left",
        width: 140,
        render: (field: any, row: ISellerDocumentsDebt) => (
          <div className={`no-wrap column-upper ${row.is_overdue ? "red" : ""}`}>{numToCurrency(row.brutto)}</div>
        ),
      },
      {
       
        align: "right",
        width: 120,
        render: (field: any, row: IAccountingDocument) => 
          row.online_payment  ? (
            <div >
            <Button
              width={170}
              title="Przelew on-line"
              color="primary"
              inline={true}
              link={`${PAGE.DOCUMENT_PAYMENT_SUMMARY}?seller_id=${row.seller_id}`}
         
            />
          </div>
        ) : (
          <div>
            <Button
              width={170}
              title="Przelew tradycyjny"
              color="white"
              inline={true}
              link={`${PAGE.DOCUMENT_TRANSFER_SUMMARY}?seller_id=${row.seller_id}`}
            />
          </div>
        ),
      },

    ];
  };

  const getTableData = () => {
    const data = {
      filtersCollapsed: true,
      pagination: {},
      loading: false,
      columns: renderColumns(),
    };

    return data;
  };





  return (
    <TableWrapper>
      {/* @ts-ignore */}
       <Table
          size="middle"
          locale={{ emptyText: "Brak elementów do wyświetlenia" }}
          columns={tableData.columns}
          bordered={false}
                  
          dataSource={invoicesState} 
          pagination={false}
          loading={tableData.loading}
          className={`custom-ant-table`}
          expandedRowRender={expandedRowRender}
        />
    </TableWrapper>
  );
};

export default OverdueInvoices;
