import { Col, Row } from "antd";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import React from "react";
import { useHistory } from "react-router-dom";

const OoOrderTile = (props: any) => {
  const history = useHistory();

  const goToSoOrderForm = () => {
    history.push({
      pathname: PAGE.SO_ORDER_APPROVAL,
      search: `?year=${props.year}`,
    }
    );
  };

  return (
    <div className={`block ${props.isAlert ? "alert-block" : ""}`}>
      <h2 className="primary h-lg">Zatwierdź deklarację mas rzeczywistych za {props.year}</h2>
      <p className="md-t mt-5 mb-10">
        Jeśli dodałeś już wszystkie importowane pojazdy w 2023 roku, kliknij “Zatwierdź” i wyślij masy do Organizacji Odzysku.
      </p>
      <Row className="mb-20">
        <Col span={12}>
          <Button
            width={220}
            color="primary"
            title={props.button_string || 'Zatwierdź'}
            onClick={goToSoOrderForm}
            disabled= {!props.order_button_enabled}
          />
        </Col>
      </Row>
    </div>
  );
};

export default OoOrderTile;
