import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { requestHeaders } from "utils/auth";
import { IBDOApplicationFirstStepValues, IBDOApplicationSecondStepValues, IBDOApplicationThirdStepValues } from "types/BDO-report-types";

const NOTIFICATIONS_URL = `${API_BASE_URL}/notifications`;

export const fetchNotifications = async () => {
    const options: AxiosRequestConfig = {
      url: `${NOTIFICATIONS_URL}`,
      headers: requestHeaders().headers,
      method: "GET",
    };
    let res = await axios(options);
    if(res){
      return res;
    }
    
  };
