import React from "react";
import Spin from "antd/lib/spin";
//@ts-ignore
import "styles/core/spinner.scss";

interface IProps {
  bgColor?: string;
  fixed?: boolean;
  size?: "small" | "default" | "large";
  noTransparent?: boolean;
}

const Spinner = (props: IProps) => {
  const { bgColor, fixed, noTransparent } = props;
  return (
    <div className={`spin-container ${fixed ? "fixed" : ""}`}>
      <div className={`overlay ${bgColor && "grey"} ${noTransparent ? "no-transparent" : ""}`}></div>
      <Spin size={props.size}></Spin> 
      {/* {process.env.NODE_ENV !== 'test' &&
          // <style jsx global>{s1}</style>
          } */}
    </div>
  );
};

export default Spinner;
