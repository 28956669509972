import React, { Component, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import Badge from "antd/lib/badge";
import Card from "components/layout/notifications/notification-card";

//@ts-ignore
import "styles/layout/notifications.scss";
import { INotificationsRes, INotification } from "types/notification-types";
import { IActionState } from "types/async-types";
import { fetchNotifications } from "actions/notifications";
import { useAsyncAction } from "utils/async-action";
import { RootState } from "redux/reducers";
import CloseIcon from '@material-ui/icons/Close';

interface IProps {
  setNotesCollapsed : any
}
const Notifications = (props: IProps) => {
  const [notificationsState, execFetchNotifications]: readonly [
    IActionState<INotificationsRes>,
    typeof fetchNotifications
  ] = useAsyncAction(fetchNotifications);

  const sessionState = useSelector((state: RootState) => state.user.session);

  const compareByDate = (a: INotification, b: INotification) => {
    if (
      !a.published_date ||
      a.published_date === null ||
      a.published_date === "" ||
      !b.published_date ||
      b.published_date === null ||
      b.published_date === ""
    ) {
      return 0;
    }

    const dateA = new Date(a.published_date);
    const dateB = new Date(b.published_date);

    return dateB.getTime() - dateA.getTime();
  };

  const renderCards = (items: INotification[], type: "ANN" | "NOT") => {
    if (!items || !items.length) {
      return <div></div>;
    }

    return items.map((item: any, index: number) => {
      return (
        <li key={index}>
          <Card data={item} />
        </li>
      );
    });
  };

  useEffect(() => {
    execFetchNotifications();
  }, []);

  return (
    <section id="notifications">
      <h3 className="relative notifications-header flex-left">
        <span className="mr-5">Komunikaty </span> <Badge count={sessionState?.notifications_count} className="white" /> <CloseIcon onClick={() => props.setNotesCollapsed(true)}  className="close-button" />
      </h3>
      <ul>
        {renderCards(
          notificationsState.data?.announcements.sort(compareByDate) || [],
          "ANN"
        )}
      </ul>

      <ul>
        {renderCards(
          notificationsState.data?.notifications.sort(compareByDate) || [],
          "NOT"
        )}
      </ul>
      {/* <style jsx global>{s1}</style> */}
    </section>
  );
};

export default Notifications;
