import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import CloseIcon from '@material-ui/icons/Close';
//@ts-ignore
import 'styles/components/common/modal.scss';

export interface IModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  children :JSX.Element
  width? : string
  height? : string
  hideClose? : boolean
}



const ModalWrapper = (props: IModalProps) => {
    const { modalIsOpen, closeModal, children, height,width, hideClose } = props;
    const customStyles = {
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: width ? width : "600px",
          height : height ? height : "auto"
        },
      };

  

  const updateChildrenWithProps = React.Children.map(
    children,
    (child, i) => {
      return React.cloneElement(child, {
        ...props
      });
    }
  );

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
      style={customStyles}
      className="modal"
      shouldCloseOnOverlayClick={false}
    >
      {!hideClose &&
           <CloseIcon onClick={() => closeModal()} className="close" />
      }
     
      {updateChildrenWithProps}
    </Modal>
  );
};

// const VehicleModalForm = (props: IProps) => {
//   const  {translationState,setVehicleData, vehicleData, closeModal} = props;
//   const onSubmit = (formValues: IVehicleEditFormValues) => {
//       const vehicle = formValues as IVehicle;
//       setVehicleData(vehicle);
//       closeModal();
//   };
//   const initialValues = vehicleData as IVehicleEditFormValues;

//   return (
//     <div>

//       <style jsx>{``}</style>
//     </div>
//   );
// };

export default ModalWrapper;
