import React, { useEffect } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Spinner from "components/core/spinner";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import NotInterestedOutlinedIcon from "@material-ui/icons/NotInterestedOutlined";

interface IProps {
  title: string;
  description?: string;
  loading? : boolean;
  type: "loading" | "success" | "error";
  aditionalContent?: JSX.Element;
}

const APIKeyLoading = (props: IProps) => {
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        color: "#4a90e2",
        display: "flex",
      },
    })
  );

  const renderIcon = () => {
    switch (props.type) {
      case "loading":
        return <CircularProgress className="mb-30" size={50} color="inherit" />;
      case "success":
        return <CheckCircleOutlineOutlinedIcon className="success" />;
      case "error":
        return <NotInterestedOutlinedIcon className="error" />;
    }
  };
  const classes = useStyles();
  return (
    <div className="block flex-center grow-1 flex-column">
      {props.loading &&
        <Spinner size="large" />
      }
      <div className="flex-center">
        <div className={classes.root}></div>
        {renderIcon()}
      </div>
      <h1 className="t-center">{props.title}</h1>
      {props.description && (
        <p className="lg-t t-center grey mb-20">{props.description}</p>
      )}

      {props.aditionalContent && props.aditionalContent}
    </div>
  );
};

export default APIKeyLoading;
