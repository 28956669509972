import React, { useState, useEffect, useRef } from "react";
import { connect, useSelector } from "react-redux";
import withLayout from "components/layout/MyLayout";
import Table from "antd/lib/table";
import { Link } from "react-router-dom";
import Button from "components/buttons/button";
import { fetchVehicleFormData, fetchVehicles } from "actions/vehicles";
import { PAGE } from "paths";
import { useAsyncAction } from "utils/async-action";
import TextFormField from "components/form-fields/formik-text-field";
import FormField from "components/form-fields/form-field";
import { ConfirmModal as ConfirmAlert } from "components/common/confirm-modal";
import {
  IVehicle,
  IVehicleFormData,
  IVehicleBasicFormValues,
} from "types/vehicle-types";
import { Formik, FormikProps } from "formik";
import { IActionState } from "types/async-types";
import Spinner from "components/core/spinner";
import { withAuthSync } from "utils/auth";
import CloseIcon from "@material-ui/icons/Close";
import { RootState } from "redux/reducers";
import { Row, Col } from "antd";
import SelectFormField from "components/form-fields/select-field";
import { renderJsonOptions, getYears } from "utils/select-options";
import TableWrapper from "components/common/table-wrapper";
import { numToCurrency } from "utils/helpers";
import { filterAdminVehicles, deleteVehicle } from "actions/admin";

export interface myVehiclesFormValues {
  page?: number;
  vehicles: string[];
  name?: string;
  vehicle_type?: string;
  drive_type?: string;
  created_date_min?: string;
  created_date_max?: string;
  import_date_min?: string;
  import_date_max?: string;
  import_date?: [string, string];
  production_year_min?: string;
  production_year_max?: string;
  production_date?: [string, string];
  sort_key?: "created_at" | "import_date";
  cbk_id?: string;
}

interface IVehicleFilters {
  vehicles: string[];
  name: string;
  vehicle_type: string;
  drive_type: string;
  import_date_min: string;
  import_date_max: string;
  production_year_min: string;
  production_year_max: string;
}

const MyVehicles = (props: any) => {
  const formikRef : any = useRef();

  const urlParams = new URLSearchParams(window.location.search);
  const showForm = urlParams.get("show_form");
  const [carFormCollapsed, setCarFormCollapsed] = useState(
    showForm ? false : true
  );

  const [tableData, setTableData] = useState<any>({});
  const [resetting, setResetting] = useState(false);
  const [timeoutVar, setTimeoutVar] = useState<undefined | any>(undefined);
  const timoutRef = useRef();

  //layout-related refs

  const tableRef = useRef<any>(null);
  const tableCurrent = tableRef.current;
  let tableMaxHeight: number | undefined = undefined;
  if (tableCurrent !== null) {
    // 138 - pagination height with bottom margin, 20 - table top margin
    tableMaxHeight = window.innerHeight - tableCurrent.offsetTop - 138 - 20;
  }

  timoutRef.current = timeoutVar;
  const [filteredVehicles, execFilterVehicles]: readonly [
    IActionState<{
      data: IVehicle[];
      current_page: number;
      total_pages: number;
      total_items: number;
      product_columns: { [key: string]: string };
      csv_url: string;
    }>,
    typeof filterAdminVehicles
  ] = useAsyncAction(filterAdminVehicles, { no_reset: true });

  const [formDataState, execFetchVehicleFormData]: readonly [
    IActionState<{ data: IVehicleFormData }>,
    typeof fetchVehicleFormData
  ] = useAsyncAction(fetchVehicleFormData);

  const [deleteState, execDelete]: readonly [
    IActionState<{ data: any }>,
    typeof deleteVehicle
  ] = useAsyncAction(deleteVehicle);

  const formData = formDataState.data?.data;

  useEffect(() => {
    if (resetting) {
      setCarFormCollapsed(false);
      setResetting(false);
    }
  }, [resetting]);

  useEffect(() => {
    const formRef: FormikProps<myVehiclesFormValues>  = formikRef.current;
    if (deleteState.data && formRef) {
      
        
      execFilterVehicles(formRef.values);
    }
  }, [deleteState.data]);

  useEffect(() => {
    execFetchVehicleFormData();
    execFilterVehicles(null);
  }, []);

  const handleDelete = (id: string) => {
    ConfirmAlert({
      action: () => execDelete(id),
      header: "Usuwanie pojazdu",
      text: "Czy chcesz usunąć pojazd?",
      yesText: "Tak",
      noText: "Nie",
      btnColor: "primary",
    });
  };

  const onSubmit = (values: myVehiclesFormValues) => {
    execFilterVehicles(values);
  };

  return (
    <div className="sider-page my-vehicles">
      <main
        className={`page-content ${
          !carFormCollapsed ? "sider-visible" : ""
        }`}
      >
        <Formik
         innerRef={formikRef}
          initialValues={{
            vehicles: [],
          }}
          onSubmit={onSubmit}
        >
          {(formikProps: FormikProps<myVehiclesFormValues>) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useEffect(() => {
              setTableData(getTableData());
            }, [filteredVehicles.data]);

            const orderBy = (orderKey: "created_at" | "import_date") => {
              formikProps.setFieldValue("sort_key", orderKey);
              formikProps.submitForm();
            };

            const setPage = (page: number) => {
              formikProps.setFieldValue("page", page);
              formikProps.submitForm();
            };

            const renderColumns = () => {
              if (filteredVehicles.data && filteredVehicles.data != null) {
                let columns: any[] = [];
                Object.keys(filteredVehicles.data.product_columns).map(
                  (columnId, index) => {
                    columns.push({
                      title:
                        filteredVehicles.data &&
                        filteredVehicles.data.product_columns[columnId],
                      // width: "10%",
                      align: "center",

                      render: (make: any, row: IVehicle) => {
                        return (
                          <div
                            className={`action no-wrap ${
                              columnId === "sum" ? "green" : ""
                            }`}
                          >
                            {" "}
                            {row.products[columnId] ? (
                              columnId.toString().endsWith("_id") ? row.products[columnId].sum : numToCurrency(row.products[columnId].sum)
                            ) : (
                              <CloseIcon className="no-product" />
                            )}
                          </div>
                        );
                      },
                    }
                    
                    );
                  }
                );
                columns.unshift(
                  {
                    title: "Pojazd",
                    dataIndex: "make",
                    width: 240,
                    align: "left",
                    fixed: "left",

                    render: (make: any, row: IVehicle) => (
                      <div>
                        <div className="vehicle-img">
                          <img src="/static/img/car-front.svg" />
                        </div>
                        <div className="vehicle-basic-info">
                          <div className="make-model">
                            {row.brand} {row.model}
                          </div>
                          <div className="year-vin">{row.production_year}</div>
                        </div>
                      </div>
                    ),
                  },
                  {
                    title: "CBK ID",
                    align: "center",
                    width: 90,
                    render: (make: any, row: IVehicle) => (
                      <div className="action no-wrap">{row.cbk_id}</div>
                    ),
                  },
                  {
                    title: "Dodano",
                    align: "center",
                    width: 90,
                    render: (make: any, row: IVehicle) => (
                      <div className="action no-wrap">{row.created_at}</div>
                    ),
                  },
                  {
                    title: "Import",
                    align: "center",
                    width: 90,
                    render: (make: any, row: IVehicle) => (
                      <div className="action no-wrap">{row.import_date}</div>
                    ),
                  },
                  {
                    title: "Wymiary opon",
                    align: "center",
                    width: 115,
                    render: (make: any, row: IVehicle) => (
                      <div className="action">{row.tire_info}</div>
                    ),
                  }
                );
                
                columns.push({
              
                  width: 100,
                  align: "center",

                  render: (make: any, row: IVehicle) => {
                    return (
                      <div
                        className="no-wrap"
                      >
                        {row.status === 'active' &&
                          <Button color="white" title="usuń" onClick={() => handleDelete(row.id)} />
                        }
                       
                      </div>
                    );
                  },
                })

                return columns;
              }
            };

            const getTableData = () => {
              const data = {
                filtersCollapsed: true,
                pagination: {},
                loading: false,
                rowClassName: (row: IVehicle, index: number) => {
                  return row.status === "draft" ? "disabled" : "";
                },
                columns: renderColumns(),
              };

              return data;
            };

            const { values } = formikProps;

            return (
              <>
                {(filteredVehicles.loading || deleteState.loading) && <Spinner size="large" />}
                <div className="breadcrumbs">
                  <Link to={PAGE.HOME}>
                    Integrator <span className="arrow">»</span>{" "}
                  </Link>
                  <span> administrator</span>
                  <span className="arrow">»</span>
                  <span className="primary-text">
                    {" "}
                    Lista wszystkich pojazdów
                  </span>
                </div>
                <header className="page-header">
                  <h1 className="grow-1">Lista wszystkich pojazdów</h1>
                </header>

                <div className="list-filters mb-20">
                  <Row gutter={4}>
                    <Col md={8}>
                      <TextFormField
                        clearVisible={false}
                        type="text"
                        name="cbk_id"
                        label="CBK ID"
                      />
                    </Col>

                    <Col md={4}>
                      <FormField
                        name="created_date_min"
                        type="date"
                        label="Dodano (od)"
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        name="created_date_max"
                        type="date"
                        label="Dodano (do)"
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        name="import_date_min"
                        type="date"
                        label="Data importu (od)"
                      />
                    </Col>
                    <Col md={4}>
                      <FormField
                        name="import_date_max"
                        type="date"
                        label="Data importu (do)"
                      />
                    </Col>
                  </Row>
                  <Row gutter={4} align="bottom" type="flex">
                    <Col xxl={17}>
                      <Row gutter={4} className="mb-0">
                        <Col md={6}>
                          <TextFormField
                            data-test="name"
                            clearVisible={false}
                            placeholder="Marka pojazdu"
                            type="text"
                            name="name"
                            label="Marka pojazdu"
                            // onChange={(e) => {
                            //   formikProps.setFieldValue("name", e.target.value);
                            //   handleValChange(e);
                            // }}
                          />
                        </Col>

                        <Col md={5}>
                          <SelectFormField
                            data-test="type"
                            options={
                              formData
                                ? renderJsonOptions(formData.vehicle_types)
                                : []
                            }
                            name="vehicle_type"
                            label="Rodzaj pojazdu"
                          />
                        </Col>
                        <Col md={5}>
                          <SelectFormField
                            data-test="gear"
                            name="drive_type"
                            label="Skrzynia biegów"
                            options={
                              formData
                                ? renderJsonOptions(formData.transmission_type)
                                : []
                            }
                          />
                        </Col>
                        <Col md={4}>
                          <SelectFormField
                            name="production_year_min"
                            options={renderJsonOptions(getYears())}
                            label="Rok produkcji (od)"
                          />
                        </Col>
                        <Col md={4}>
                          <SelectFormField
                            name="production_year_max"
                            options={renderJsonOptions(getYears())}
                            label="Rok produkcji (do)"
                          />
                        </Col>
                      </Row>
                    </Col>

                    <Col xxl={7} className="t-right">
                      <Button
                        title="Filtruj"
                        inline={true}
                        width={150}
                        color="primary"
                        onClick={formikProps.submitForm}
                        className="mr-5  mb-20"
                      />

                      <Button
                        title="Wyczyść"
                        color="white"
                        className="mb-20 mr-5"
                        inline={true}
                        width={150}
                        onClick={() => {
                          formikProps.resetForm();
                          execFilterVehicles(null);
                        }}
                      />
                      {
                        filteredVehicles.data?.csv_url &&
                        <div className="green button inline mr-5">
                          <Link to={{pathname: filteredVehicles.data?.csv_url}} target="_blank">CSV</Link>
                        </div>
                      }
                    </Col>
                  </Row>
                </div>
                <span className="md-t flex i-b mb-10">
                  <span className="grow-1 grey-2">
                    Znaleziono:{" "}
                    <span className="t-500 black">
                      {filteredVehicles.data?.total_items || 0}
                    </span>
                  </span>

                  <div>
                    <span className="mr-10 grey-2">Sortowanie: </span>{" "}
                    <label
                      className={
                        values.sort_key === "created_at"
                          ? "primary t-500 mr-10 hover"
                          : "black t-500 mr-10 hover"
                      }
                      onClick={() => orderBy("created_at")}
                    >
                      Ostatnio dodane
                    </label>
                    <label
                      title="Ostatnio importowane"
                      className={
                        values.sort_key === "import_date"
                          ? "primary t-500 hover"
                          : "black t-500 hover"
                      }
                      onClick={() => orderBy("import_date")}
                    >
                      Ostatnio importowane
                    </label>
                  </div>
                </span>

                {/* {filtersCollapsed && (
                  <a className="f-right" onClick={toggleFilters}>
                    {" "}
                    Filtrowanie{" "}
                  </a>
                )} */}
                {filteredVehicles.data && (
                  <div ref={tableRef}>
                    <TableWrapper
                      className="grow-1"
                      fetchData={
                        filteredVehicles.data && {
                          current_page: filteredVehicles.data?.current_page,
                          total_pages: filteredVehicles.data?.total_pages,
                        }
                      }
                      setPage={setPage}
                      addPagination={true}
                    >
                      <Table
                        scroll={{ x: 1300 }}
                        size="middle"
                        locale={{ emptyText: "Brak elementów do wyświetlenia" }}
                        rowKey="item => item.uuid"
                        columns={tableData.columns}
                        bordered={false}
                        dataSource={filteredVehicles.data.data}
                        pagination={false}
                        loading={tableData.loading}
                        rowClassName={tableData.rowClassName}
                        className={`custom-ant-table`}
                      />
                    </TableWrapper>
                  </div>
                )}
              </>
            );
          }}
        </Formik>
      </main>

      {/* <style jsx global>
        {s1}
      </style> */}
    </div>
  );
};

function mapStateToProps(state: any) {
  return { 
    myCars: state.vehicles ? state.vehicles : null,
  };
}

export default withAuthSync(
  withLayout(
    connect(mapStateToProps, { fetchVehicles, fetchVehicleFormData })(
      MyVehicles
    )
  )
);
