import React, { useEffect, useState } from "react";
import SimpleLayout from "components/layout/simple-layout";
import "styles/components/bdo-report/bdo-report-form.scss";
import { Switch, Route, useHistory } from "react-router-dom";
import { PAGE } from "paths";
import TransferSummary from "components/bdo-year-report-form/step-2/transfer-summary";
import PaymentStatus from "components/bdo-year-report-form/step-2/bdo-report-payment-status";
import Step1 from "components/bdo-year-report-form/step-1";
import CampaignLeaflet from "components/bdo-year-report-form/campaign-leaflet";
import Step2 from "components/bdo-year-report-form/step-2";
import Step3 from "components/bdo-year-report-form/step-3";
import Step4 from "components/bdo-year-report-form/step-4";
import Step5 from "components/bdo-year-report-form/step-5";
import Step6 from "components/bdo-year-report-form/submit";
import { BDOGraphNode } from "types/BDO-report-types";
import { IBDOFormNavItem } from "components/bdo-year-report-form/graph-form-utils";
import withFormLayout from "components/layout/form-layout";
import { withAuthSync } from "utils/auth";
 
export interface IGraphNavParams {
  goToStep?: (node: BDOGraphNode) => void;
}

export const GraphFormContext = React.createContext<any>(null);

const BDOReportForm = (props: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const history = useHistory();
  const [navState, setNavState] = useState<{
    stepNavData?: IBDOFormNavItem[];
    goToStep?: (a: any) => any;
  }>({});
  const { goToStep, stepNavData } = navState;
  const [navData, setNavData] = useState<IBDOFormNavItem[] | undefined>([]);

  useEffect(() => {
    if (!id) {
      history.push(PAGE.BDO_REPORT);
    }
  }, [id, history]);
  useEffect(() => {
    // update navData only if stepNavData is not empty (prevents rendering empty navigation during transition)
    if (stepNavData && stepNavData?.length > 0) {
      setNavData(stepNavData);
    }
  }, [stepNavData]);

  let currentStep: BDOGraphNode = "vehicle_list";
  switch (history.location.pathname) {
    case PAGE.BDO_REPORT_STEP_1:
      currentStep = "vehicle_list";
      break;
    case PAGE.BDO_REPORT_CAMPAIGN_LEAFLET:
      currentStep = "campaign_leaflet"
      break;
    case PAGE.BDO_REPORT_STEP_2:
      currentStep = "payments";
      break;
    case PAGE.BDO_REPORT_STEP_2_SUMMARY:
      currentStep = "payments";
      break;
    case PAGE.BDO_REPORT_STEP_2_PAYMENT_STATUS:
      currentStep = "payments";
      break;
    case PAGE.BDO_REPORT_STEP_3:
      currentStep = "network";
      break;
    case PAGE.BDO_REPORT_STEP_4:
      currentStep = "campaign";
      break;
    case PAGE.BDO_REPORT_STEP_5:
      currentStep = "summary";
      break;
    default:
      break;
  }

  const renderFormNav = () => {
    return navData?.map((item, index) => {
      
      return (
        <li
          onClick={() => item.isTraversable && goToStep && goToStep(item.name)}
          className={`${currentStep === item.name && "active"} ${
            item.isTraversable ? "hover" : "forbidden"
          }`}
        >
          <div>
            <label className={`${item.isTraversable ? "hover" : "forbidden"}`}>
              {item.title}{" "}
            </label>
          </div>
        </li>
      );
    });
  };

  return (
    <>
      <h1 className="primary">Sprawozdanie roczne BDO za 2023 rok</h1>
      {/* <div className="close-page">
        <CloseIcon className="close-button" id="ws-close" />
      </div> */}
      <div className="steps-nav">
        <ul className="request-order">{renderFormNav()}</ul>
      </div>

      <Switch>
        <Route
          path={PAGE.BDO_REPORT_STEP_1}
          render={() => <Step1 setNavState={setNavState} />}
        />
        <Route
          path={PAGE.BDO_REPORT_CAMPAIGN_LEAFLET}
          render={() => <CampaignLeaflet setNavState={setNavState} />}
        />
        <Route
          exact
          path={PAGE.BDO_REPORT_STEP_2}
          render={() => <Step2 setNavState={setNavState} />}
        />
        <Route
          exact
          path={PAGE.BDO_REPORT_STEP_2_SUMMARY}
          render={() => <TransferSummary setNavState={setNavState} />}
        />
        <Route
          exact
          path={PAGE.BDO_REPORT_STEP_2_PAYMENT_STATUS}
          render={() => <PaymentStatus setNavState={setNavState} />}
        />
        <Route
          path={PAGE.BDO_REPORT_STEP_3}
          render={() => <Step3 setNavState={setNavState} />}
        />
        <Route
          path={PAGE.BDO_REPORT_STEP_4}
          render={() => <Step4 setNavState={setNavState} />}
        />
        <Route
          path={PAGE.BDO_REPORT_STEP_5}
          render={() => <Step5 setNavState={setNavState} />}
        />
        <Route path={PAGE.BDO_YEAR_REPORT_SUBMIT} render={() => <Step6 />} />
      </Switch>
    </>
  );
};

export default withAuthSync(SimpleLayout(withFormLayout(BDOReportForm)));
