import * as Yup from "yup";

export const yupRequired = Yup.string().required("pole jest wymagane");
export const dateRequired = Yup.date().required("pole jest wymagane");




export const numRequired = Yup.number().required("pole jest wymagane");
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const yupPhone = Yup.string().matches(
  phoneRegExp,
  "Numer telefonu jest niepoprawny"
);
// export const requiredSubField = value => value ? undefined : tr('validationError.you-need-to')

export const required = (value: any) =>
  value ? undefined : "Pole jest wymagane";

  
export const phone = (value: string) => {
  var re = /^[0-9-+].{8,}$/;

  if (re.test(value)) {
    return undefined;
  } else {
    return "Musisz podać prawidłowy numer telefonu";
  }
};

export const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const email = Yup.string().matches(
  emailRegExp,
  "Adres email jest nieprawidłowy"
);

export const passwordRegExp = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
export const newPassword = Yup.string().matches(
  passwordRegExp,
  "Hasło jest nieprawidłowe (wymagane minimum 8 znaków i jedna cyfra)"
);

export const zipCodeRegExp = /\d{2}-\d{3}/
export const zipCode =  Yup.string().matches(zipCodeRegExp, 'Kod pocztowy jest niepoprawny')

export const PESELRegExp = /^[0-9]{11}$/;
export const PESEL =  Yup.string().matches(PESELRegExp, 'Numer PESEL jest niepoprawny')

export const VINRegexp = /^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$/;
export const VIN = Yup.string().matches(
  VINRegexp,
  "Numer VIN jest nieprawidłowy"
).required('Pole jest wymagane');

export const nipRegExp = /[0-9]{10}/

export const isPostalCodeCorrect = () => (value : string) => {
  
  if (zipCodeRegExp.test(value)){
    return undefined;
    
  }else{
    return "Nieprawidłowy kod pocztowy"
  }

}

export const isEmailCorrect = () => (value : string) => {
  
  if (emailRegExp.test(value)){
    return undefined;
    
  }else{
    return "Nieprawidłowy email"
  }

}


export const isPESELCorrect = () => (value : string) => {
  
  if (PESELRegExp.test(value)){
    return undefined;
    
  }else{
    return "Nieprawidłowy numer PESEL"
  }

}

export const isNIPCorrect = () => (value : string) => {
  
  if (nipRegExp.test(value)){
    return undefined;
    
  }else{
    
    return "Nieprawidłowy numer NIP"
  } 

}

export const isPhoneCorrect = () => (value : string) => {
  
  if (matRegex.test(value)){
    return undefined;
    
  }else{
    
    return 'Numer telefonu jest niepoprawny (wymagana liczba znaków: 9, dopuszczalne cyfry 0-9 i "+")'
  }

}

export const isNIPCorrectHook =  (value : string) => {
  
  if (nipRegExp.test(value)){
    return undefined;
    
  }else{
    return "Nieprawidłowy numer NIP"
  }

}



export const isPESELCorrectHook =  (value : string) => {
  
  if (PESELRegExp.test(value)){
    return undefined;
    
  }else{
    return "Nieprawidłowy PESEL"
  }

}

export const isREGONCorrectHook =  (value : string) => {
  
  if (regonRegExp.test(value)){
    return undefined;
    
  }else{
    return 'REGON jest nieprawidłowy (jedynie cyfry, wymagana ilość znaków - 9)'
  }

}

export const isEmailHookCorrect =  (value : string) => {
  
  if (emailRegExp.test(value)){
    return undefined;
    
  }else{
    return "Nieprawidłowy email"
  }

}


export const isPhoneCorrectHook =  (value : string) => {
  
  if (phoneRegExp.test(value)){
    return undefined;
    
  }else{
    return 'Numer telefonu jest niepoprawny (wymagana liczba znaków: 9, dopuszczalne cyfry 0-9 i "+")'
  }

}

export const isMinCorrect = (min : number) => (value : string) => {
  
  
  if (parseInt(value) >= 0){
    return undefined;
    
  }else{
    return `wartość nie może być mniejsza od ${min}`
  }

}

export const isPasswordCorrect = () => (value : string) => {
  
  if (passwordRegExp.test(value)){
    return undefined;
    
  }else{
    return "Hasło jest nieprawidłowe (wymagane minimum 8 znaków i jedna cyfra)"
  }

}


//* schemas *//
export const addressValidation = {
  postal_code: Yup.string().concat(yupRequired).concat(zipCode) as Yup.StringSchema<string>,
    aup_type: Yup.string().concat(yupRequired) as Yup.StringSchema<string>,
    post_office : Yup.string().concat(yupRequired) as Yup.StringSchema<string>,
    street: Yup.string().when("aup_type", {
      is: "-",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().concat(yupRequired),
    }) as Yup.StringSchema<string>,
    city: Yup.string().concat(yupRequired) as Yup.StringSchema<string>,
    voivodeship: Yup.string().concat(yupRequired) as Yup.StringSchema<string>,
    building_number: Yup.string().concat(yupRequired) as Yup.StringSchema<string>,
};


const matRegex = /^(\+48)?\d{9}$/;
export const phoneNumber =  Yup.string().matches(matRegex, 'Numer telefonu jest niepoprawny (wymagana liczba znaków: 9, dopuszczalne cyfry 0-9 i "+")')

export const nipNumber =  Yup.string().matches(nipRegExp, 'NIP jest nieprawidłowy (jedynie cyfry, wymagana ilość znaków - 10)')


export const regonRegExp = /[0-9]{9}/
export const regonNumber =  Yup.string().matches(regonRegExp, 'REGON jest nieprawidłowy (jedynie cyfry, wymagana ilość znaków - 9)')