import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { API_BASE_URL, FRONT_DATE } from "config";
import { ActionResponse } from "types/async-types";
import {
  IPreloadByVin,
  IVehicle,
  IVehicleFormValues,
  IFetchVehicle,
  PreloadByVinRes,
  ICreateVehicleRequestData,
  IVehicleBasicFormValues,
} from "types/vehicle-types";
import { requestHeaders } from "utils/auth";
import {
  FORM_DATA_URL,
  FILTER_URL,
  VEHICLE_REQUESTS_URL,
} from "redux/actions/vehicles";
import { myVehiclesFormValues } from "pages/my-vehicles";
// import { insurancesUrl } from "actions/insurances";
const VEHICLES_URL = `${API_BASE_URL}/vehicles`;
export const TIRE_VALID_VALUES_URL = `${API_BASE_URL}/vehicles/form_data/tire_valid_values`;

export const PRELOAD_VEHICLE_URL = `${VEHICLES_URL}/new?vin_number=`;
//
// const FORM_DATA_URL = `${API_BASE_URL}/vehicles/default_form_data`;
// const FILTER_URL = `${API_BASE_URL}/vehicles/filter_vehicles`;
// const filterQuery = `?&search[make_cont]=Ford&search[model_cont]=Omni`;

export const fetchVehicles = async () => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}`,
    method: "GET",
    headers: requestHeaders().headers,
  };

  let res: AxiosResponse<IVehicle[]> = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

export const filterVehicles = async (params?: myVehiclesFormValues | null) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}`,
    params,
    method: "GET",
    headers: requestHeaders().headers,
  };

  let res: AxiosResponse<IVehicle[]> = await axios(options);
  if (!res) {
    return undefined;
  }
  return res;
};

// export function fetchVehicle({ ctx, id} : any) {
//   return async function (dispatch: any) {
//     await axios
//       .get(`${VEHICLES_URL}/${id}`, requestHeaders(ctx))
//       .then((response) => {
//         dispatch({
//           type: FETCH_VEHICLE,
//           payload: {
//             vehicle: response.data,
//           },
//         });
//       })
//       .catch((error) => handleError(error));
//   };
// }

export const fetchVehicle: IFetchVehicle = async ({ ctx, id }: any) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/${id}`,
    method: "GET",
    headers: requestHeaders().headers,
  };

  let res: AxiosResponse<any> = await axios(options);
  return res;
};

export const fetchTireValidValuesByWidth = async (queryString: string) => {
  const options: AxiosRequestConfig = {
    url: `${TIRE_VALID_VALUES_URL}?${queryString}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchTireValidValuesByProfileHeight = async (height: number) => {
  const options: AxiosRequestConfig = {
    url: `${TIRE_VALID_VALUES_URL}?tire_profile_height=${height}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchTireValidValuesByDiameter = async (diameter: string) => {
  const options: AxiosRequestConfig = {
    url: `${TIRE_VALID_VALUES_URL}?tire_rim_diameter=${diameter}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchVehicleFormData = async (ctx?: any) => {
  const options: AxiosRequestConfig = {
    url: FORM_DATA_URL,
    method: "GET",
    headers: requestHeaders(ctx).headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchVehicleRequestFormData = async () => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLE_REQUESTS_URL}/form_data`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const submitVehicleRequest = async (
  data: IVehicleBasicFormValues,
  id?: string
) => {
  const formData = new FormData();
  const submitData: { [key: string]: any } = data;

  Object.keys(submitData).map((key, index) => {
    if (key === "attachments" && data.attachments) {
      
        for (let i = 0; i < data.attachments.length; i++) {
          
            const fileObj  = data.attachments[i].originFileObj;
            if(fileObj){
              formData.append(
                "vehicle_request[attachments][]",
                fileObj
              );
            }
        
          
       
        }
      
    } else {
      if (key === "tires_attributes" && data.tires_attributes) {
        data.tires_attributes.forEach( (tire, index) => {
          formData.append(`vehicle_request[tires_attributes][${index}][count]`, tire.count!.toString());
          formData.append(`vehicle_request[tires_attributes][${index}][width]`, tire.width!.toString());
          formData.append(`vehicle_request[tires_attributes][${index}][profile_height]`, tire.profile_height!.toString());
          formData.append(`vehicle_request[tires_attributes][${index}][rim_diameter]`, tire.rim_diameter!.toString());
        })
      } else {
        formData.append(`vehicle_request[${key}]`, submitData[key]);
      }
    }
  });

  

  const options: AxiosRequestConfig = {
    url: VEHICLE_REQUESTS_URL,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      "content-type": "multipart/form-data",
    },
    data: formData,
    method: "POST",
  };

  const res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchVehicleDataSetById = async (datasetId: string) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_datasets`,

    params: {
      ID: datasetId,
    },
    method: "GET",
    headers: requestHeaders().headers,
  };

  let res: AxiosResponse<any> = await axios(options);
  return res;
};

export const fetchVehicleDataSet = async (
  data: IVehicleBasicFormValues,
  datasetId?: string
) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_datasets${
      datasetId ? `/${datasetId}` : ""
    }`,

    data: {
      selected_products: data.vehicle_products_attributes,
      vehicle_type: data.vehicle_type || undefined,
      vehicle_brand: data.brand || undefined,
      vehicle_model: data.model || undefined,
      engine_type: data.fuel_type || undefined,
      engine_capacity: data.engine_capacity || undefined,
      engine_power: data.engine_power_kw || undefined,
      drive_type: data.drive_type || undefined,
      tires_attributes: data.tires_attributes,
      ev_battery_capacity: data.ev_battery_capacity
      // tire_count: data.tire_count || undefined,
      // tire_rim_diameter:
      //   data.tire_rim_diameter && data.tire_width && data.tire_profile_height
      //     ? data.tire_rim_diameter
      //     : undefined,
      // tire_width:
      //   data.tire_rim_diameter && data.tire_width && data.tire_profile_height
      //     ? data.tire_width
      //     : undefined,
      // tire_profile_height:
      //   data.tire_rim_diameter && data.tire_width && data.tire_profile_height
      //     ? data.tire_profile_height
      //     : undefined,
    },
    method: "POST",
    headers: requestHeaders().headers,
  };

  let res: AxiosResponse<any> = await axios(options);
  return res;
};

export const fetchBrands = async (vehicleType: string) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_brands?vehicle_type=${vehicleType}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);

  return res;
};

export const fetchModels = async (make: string, vehicleType: string) => {
  const options: AxiosRequestConfig = {
    url: `${FILTER_URL}?vehicle_brand=${make}&vehicle_type=${vehicleType}`,
    method: "GET",
    headers: requestHeaders().headers,
  };

  const res = await axios(options);

  return res;
};

export const fetchEngines = async (
  vehicleType: string,
  make: string,
  model: string
) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_engines?vehicle_type=${vehicleType}&vehicle_brand=${make}&vehicle_model=${model}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchCapacities = async (
  vehicleType: string,
  make: string,
  model: string,
  engineType: string
) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_capacities?vehicle_type=${vehicleType}&vehicle_brand=${make}&vehicle_model=${model}&engine_type=${engineType}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchPowers = async (
  vehicleType: string,
  make: string,
  model: string,
  engineType: string,
  engineCapacity: string
) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_powers?vehicle_type=${vehicleType}&vehicle_brand=${make}&vehicle_model=${model}&engine_type=${engineType}&engine_capacity=${engineCapacity}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchPowerCapacities = async (
  vehicleType: string,
  make: string,
  model: string,
  engineType: string
) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/vehicle_capacities_powers?vehicle_type=${vehicleType}&vehicle_brand=${make}&vehicle_model=${model}&engine_type=${engineType}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const fetchBatteryCapacities = async (
  vehicleDatasetId: string
) => {
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}/form_data/ev_batteries?vehicle_dataset_id=${vehicleDatasetId}`,
    method: "GET",
    headers: requestHeaders().headers,
  };
  const res = await axios(options);
  return res;
};

export const preloadByVIN = async (VIN: string, failedUrl?: string) => {
  const options: AxiosRequestConfig = {
    url: `${PRELOAD_VEHICLE_URL}${VIN}`,
    method: "GET",
    headers: requestHeaders().headers,
  };

  let res: ActionResponse<{ data: PreloadByVinRes }> = await axios(options);
  if (res) {
    return res;
  }
};

export const createVehicle = async (data: ICreateVehicleRequestData) => {
  data.front_date = FRONT_DATE;
  const options: AxiosRequestConfig = {
    url: `${VEHICLES_URL}`,
    method: "POST",
    headers: requestHeaders().headers,
    data: data,
  };

  let res: ActionResponse<{ id: string }> = await axios(options);
  if (!res) {
    return undefined;
  }
  res = {
    ...res,
    successMessage: "Pojazd został dodany",
  };
  return res;
};
