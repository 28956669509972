import axios, {AxiosRequestConfig} from "axios";
import { WORKSHOP_API_URL } from "config";
import {workshopRequestHeaders} from 'utils/auth'
import {IReportFormValues, ISubmitReport, IGetReport} from 'types/report-types'
import _ from 'lodash'


export const getReportList = async () => {

  const options: AxiosRequestConfig = {
      url: `${WORKSHOP_API_URL}/year_reports/report_list`,
      method: "GET",
      headers: {
        Authorization: workshopRequestHeaders(),
      },
    };
    let res = await axios(options);
    return res;
}


export const getReport : IGetReport =  async (year : string) => {

    const options: AxiosRequestConfig = {
        url: `${WORKSHOP_API_URL}/year_reports/report_data?year=${year}`,
        method: "GET",
        headers: {
          Authorization: workshopRequestHeaders(),
        },
      };
      let res = await axios(options);
      return res;
}


export const submitReport =  async (data: IReportFormValues) => {
  const options: AxiosRequestConfig = {
      url: `${WORKSHOP_API_URL}/year_reports`,
      method: "POST",
      data: data,
      headers: {
        Authorization: workshopRequestHeaders(),
      },
    };
    let res = await axios(options);
    const result = {...res , successMessage : 'Sprawozdanie zostało wysłane'}
    return res;
}