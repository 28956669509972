import React from 'react';

export default function FormButtons({ children }: any) {
  return (
    <div className="buttons-panel">
      <div className="form-content">
          {children}
      </div>
    </div>
  );
}
