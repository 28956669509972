import React from "react";
// import PropTypes from "prop-types";

import { Field, ErrorMessage } from "formik";
// import inputStyle from "./table-fields.module.scss";

interface IProps {
  disabled?: boolean;
  name: string;
  disableErrorMessage?: boolean;
  type? : string
  min? : string | number
  max? : string | number
  onChange? : (a: any) => void
  validate? : (a: any) => void
}

const ErrorMsg = (msg: string) => (
  <span >{msg} </span>
);

function TextField(props: IProps) {
  const { disabled, name, disableErrorMessage } = props;

  return (
    <>
      <Field data-testid="textfield" className={`text ant-input`} {...props} disabled={disabled}></Field>
      {!disableErrorMessage && <ErrorMessage name={name} render={ErrorMsg} />}
    </>
  );
}

export default TextField;
