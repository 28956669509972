import React, { useEffect, useState } from "react";
import FormStatusScreen from "components/common/form-status-screen";
import Button from "components/buttons/button";
import { IActionState } from "types/async-types";
import {
  checkOSOP1PaymentStatus,
  retryOSOP1Payment,
} from "actions/BDO-reports";
import { useAsyncAction } from "utils/async-action";
import { PAGE } from "paths";
import { useHistory } from "react-router-dom";

const PaymentStatus = (props: any) => {
  const history = useHistory();
  const [paymentStatus, setPaymentStatus] = useState<"PEN" | "FIN" | "ERR">(
    "PEN"
  );
  const [myTimeout, setMyTimeout] = useState<NodeJS.Timeout>();

  const [id, setId] = useState<string | undefined>();

  const [paymentStatusState, execCheckPaymentStatus]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof checkOSOP1PaymentStatus
  ] = useAsyncAction(checkOSOP1PaymentStatus);

  const [retryPaymentState, execRetry]: readonly [
    IActionState<{
      payment_status: "FIN" | "PEN" | "ERR";
      payment_address: string;
    }>,
    typeof retryOSOP1Payment
  ] = useAsyncAction(retryOSOP1Payment);

  //    ------- REGION paymentStatus  ------------
  const checkPaymentStatus = () => {
    if (id) {
      execCheckPaymentStatus(id);
      const timer = setTimeout(checkPaymentStatus, 5000);

      setMyTimeout(timer);
    }
  };

  useEffect(() => {
    if (paymentStatusState.data?.payment_status === "FIN") {
      // setTimeout(() => {
      //   history.push(PAGE.OS_OP1);
      // }, 7000);
      setPaymentStatus("FIN");
    }
  }, [paymentStatusState.data]);

  useEffect(() => {
    if (paymentStatusState.response?.status === 422) {
      setPaymentStatus("ERR");

      //testing comment
      //   if (myTimeout) {
      //     clearTimeout(myTimeout);
      //   }
    }
  }, [paymentStatusState.response]);

  //testing comment
  //   useEffect(() => {
  //     if (paymentStatus === "PEN") {
  //       checkPaymentStatus();
  //     }
  //   }, [id]);

  useEffect(() => {
    if (paymentStatus === "PEN") {
      if (id) {
        checkPaymentStatus();
      }
    } else if (myTimeout) {
      clearTimeout(myTimeout);
    }
  }, [paymentStatus, id]);

  //    ------- ENDREGION paymentStatus  -------------

  // ---------- REGION RetryPayment ------------------
  const retryPayment = async () => {
    if (id) {
      await execRetry(id);
    }
  };

  useEffect(() => {
    if (retryPaymentState.data) {
      window.location.href = retryPaymentState.data.payment_address;
      setPaymentStatus("PEN");
    }
  }, [retryPaymentState.data]);

  // ---------- ENDREGION RetryPayment ------------------

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (id) {
      setId(id);
    }
    if (paymentStatus !== "PEN" && myTimeout) {
      clearTimeout(myTimeout);
    }

    return function cleanup() {
      if (myTimeout) {
        clearTimeout(myTimeout);
      }
    };
  }, []);

  switch (paymentStatus) {
    case "PEN":
      return (
        <FormStatusScreen
          title="Weryfikacja płatności"
          description="Trwa oczekiwanie na potwierdzenie realizacji płatności w serwisie przelewy24"
          type="loading"
        />
      );
    case "FIN":
      return (
        <FormStatusScreen
          title="Płatność powiodła się. Dziękujemy."
          description="W najbliższym czasie faktura VAT oraz druki sprawozdań zostaną do Ciebie wysłane na adres mailowy wskazany we wniosku."
          type="success"
          aditionalContent={
            <div className="t-center">
              <Button
                link={`${PAGE.OS_OP1}`}
                width={300}
                className="i-b"
                title="Wróć do listy wniosków"
                color="primary"
              />
            </div>
          }
        />
      );
    case "ERR":
      return (
        <FormStatusScreen
          title="Płatność nie powiodła się.."
          description="Nie otrzymaliśmy potwierdzenia wykonania płatności od Przelewy 24.."
          type="error"
          aditionalContent={
            <div className="t-center">
              <Button
                onClick={() => retryPayment()}
                width={300}
                className="i-b"
                title="Ponów płatność"
                color="primary"
              />{" "}
              <Button
                link={`${PAGE.OS_OP1_STEP_3}?id=${id}`}
                width={300}
                className="i-b"
                title="Wróć do wyboru sposobu płaności"
                color="white"
              />
            </div>
          }
        />
      );
  }
};

export default PaymentStatus;
