import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "antd";
import Button from "components/buttons/button";
import { PAGE } from "paths";
import { IActionState } from "types/async-types";
import { IWasteAdmin, IProduct } from "types/dashboard-types";
import { fetchWasteAdministration } from "actions/dashboard";
import { useAsyncAction } from "utils/async-action";
import { formatDateTime } from "utils/date";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from 'components/core/spinner';
import { numToCurrency } from "utils/helpers";
import ProductsList from '../services-list';
import Price from 'components/common/price';

const WasteAdmin = (props: any) => {
  const [wasteAdminState, execFetchWasteAdministration]: readonly [
    IActionState<IWasteAdmin>,
    typeof fetchWasteAdministration
  ] = useAsyncAction(fetchWasteAdministration);

  useEffect(() => {
    execFetchWasteAdministration();
  }, []);


  return (
    <div className="block relative">
      {wasteAdminState.loading && <Spinner />}
      <h2 className="primary h-lg">Administracja odpadów</h2>
      <p className="md-t mb-20">
        Oferujemy narzędzia systemowe ułatwiające prowadzenie administracji oraz sprawozdawczości firmom, które są wytwórcami odpadów. Współpraca z Integratorem pomoże zestawić dane o odpadach, przygotować sprawozdania, stanowi wsparcie w wywiązywaniu się z obowiązków nałożonych przepisami prawa.
      </p>
      {wasteAdminState.data?.product_available ? (
        <Row className="mb-20">
          <Col>
            <h3>Łącznie odpadów</h3>
            <div className="text-42">
              <span className="green">
                {wasteAdminState.data?.stats.produced} kg
              </span>
            </div>
            <p className="sm-t mb-0">
              Pozostałych:{" "}
              <span className="black">
                {wasteAdminState.data?.stats.remained} kg
              </span>{" "}
              </p>
              <p className="sm-t mb-20">
              Przekazanych z kartą:{" "}
              <span className="black">
                {wasteAdminState.data?.stats.transfered} kg
              </span>{" "}
              </p>
          
            <Button
              width={200}
              color="primary"
              className="mr-10"
              inline={true}
              title="Dodaj z kartą"
              link={PAGE.WASTE}
            />
            <Button
              width={200}
              inline={true}
              color="primary"
              title="Dodaj bez karty"
              link={PAGE.WASTE}
            />
          </Col>
        </Row>
      ) :
      <Price title="Zamów teraz" price={wasteAdminState.data?.product_price} suffix={false} />
        // <div>
        //   <h3>Zamów teraz</h3>
        //   <div className="text-42">
        //     <span className="green">
        //       {numToCurrency(wasteAdminState.data?.product_price)}
        //     </span>
        //   </div>
        // </div>
      }


      <div className="check-list mb-20">
        <ProductsList products={wasteAdminState.data?.products} />

      </div>
      {wasteAdminState.data?.agreement_button &&
        <Button
          width={200}
          color="primary"
          className="mr-10"
          inline={true}
          title="Uzyskaj dostęp do usług"
          outerLink={true}
          newCard={true}
          link={wasteAdminState.data.agreement_url}
        />
      }
    </div>
  );
};

export default WasteAdmin;
