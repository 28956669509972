import React, { useEffect, useState } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import CheckCircle from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import Button from "components/buttons/button";
import TableList from "components/common/table-list";
import Help from "@material-ui/icons/HelpOutline";
import "styles/components/products/product-charges.scss";
import { IActionState } from "types/async-types";
import { IAgreementsRes, IAgreement } from "types/agreement-types";
import { fetchProductAgreements } from "actions/agreements";
import { useAsyncAction } from "utils/async-action";
import CloseIcon from "@material-ui/icons/Close";
import Spinner from "components/core/spinner";
import CheckBox from "components/form-fields/check-form-field";
import { Formik, FormikProps } from "formik";
import { formatDateTime } from "utils/date";
import { renderAgreementDates, renderBreadcrumbs } from "utils/helpers";
import {
  IWasteRaports,
  IWasteReportItem,
  IWasteRaport,
} from "types/waste-types";
import { fetchWasteRaports } from "actions/waste-raports";

const ProductCharges = () => {
  const [productAgreements, execFetchProductAgreements]: readonly [
    IActionState<IAgreementsRes>,
    typeof fetchProductAgreements
  ] = useAsyncAction(fetchProductAgreements);

  const [wasteRaportsState, execFetchWasteRaports]: readonly [
    IActionState<IWasteRaports>,
    typeof fetchWasteRaports
  ] = useAsyncAction(fetchWasteRaports);

  const [siderCollapsed, setSiderCollapsed] = useState(false);

  const toggleSider = () => {
    setSiderCollapsed(!siderCollapsed);
  };

  useEffect(() => {
    execFetchProductAgreements();
    execFetchWasteRaports();
  }, []);

  const renderReports = (
    type: "current" | "confirmed",
    raport?: IWasteRaport[]
  ) => {
    const sorted = raport?.sort(
      (first, second) => parseInt(second.year) - parseInt(first.year)
    );
    return sorted?.map((item, index) => {
      return renderWasteRaportTable(item, type);
    });
  };

  const renderWasteRaportTable = (
    item: IWasteRaport,
    type: "current" | "confirmed"
  ) => {
    const result = item.items.map((reportItem, index) => {
      return (
        <tr>
          <td>{index === 0 && item.year}</td>
          <td >{reportItem.label}</td>
          <td style={{textAlign: "end" }} >
            {reportItem.value === null ? (
              <span className="red">Brak umowy</span>
            ) : (
              reportItem.value
            )}
          </td>
          <td className="t-right" >
            {reportItem.url && (
              <a target="_blank" href={reportItem.url} rel="noreferrer">
                {type === "current" ? "Podgląd" : "Pobierz"}
              </a>
            )}
          </td>
        </tr>
      );
    }); 

    return (
      <table  className="simple-table md-t mb-30 w-100" style={{maxWidth: "700px"}}>
        <tbody>
          <tr>
            <th></th>
            <th style={{ width: "60%" }}></th>
            <th style={{ width: "20%"}}></th>
            <th  style={{ minWidth: "100px" }}></th>
          </tr>{" "}
          {result}{" "}
        </tbody>{" "}
      </table>
    );
  };

  const renderItems = (agreementItems?: IAgreement[]) => {
    if (agreementItems) {
      // const items = productAgreements.data.data.electronic_group;
      const sorted = agreementItems.sort(
        (first, second) => first.position - second.position
      );
      return sorted.map((item, index) => {
        return (
          <li className="product-item">
            <div className="agreement-status">
              {item.product_attributes?.trade_agreement ? (
                <CheckCircle className="green circle" />
              ) : (
                <BlockIcon className="red circle" />
              )}
            </div>
            <div className="grow-1 ">
              <h2
                className={`${
                  item.product_attributes?.trade_agreement ? "green" : "red"
                } mb-5`}
              >
                {item.name}
              </h2>
              {/* {!item.product_attributes.trade_agreement && (
                <div className="md-t">
                  Nie masz jeszcze podpisanej umowy na ten produkt recyklingowy.
                  Zrób to czym prędzej i rozliczaj wszystkie opłaty dla
                  wszystkich pojazdów w Integratorze.
                </div>
              )} */}
              {/* <div className="md-t">{item.description}</div> */}
            </div>

            <p className="md-t t-right">
              {item.product_attributes?.trade_agreement ? (
                renderAgreementDates(
                  item.agreement.start_date,
                  item.agreement.end_date,
                  item.agreement.indefinite
                )
              ) : (
                <span>Brak umowy</span>
              )}
            </p>
            {/* <div className="help">
          <Help />
        </div> */}
          </li>
        );
      });
    }

    // return packages.map((item, index) => {
    //   return (
    //     <li className="flex">
    //       <div className="agreement-status">
    //         {item.has_agreement ? (
    //           <CheckCircle className="green circle" />
    //         ) : (
    //           <BlockIcon className="red circle" />
    //         )}
    //       </div>
    //       <div className="grow-1 ">
    //         <h3 className="mb-5">{item.title}</h3>
    //         <div className="md-t">{item.description}</div>
    //       </div>
    //       {item.has_agreement && (
    //         <>
    //           <div className="date">
    //             <div className="md-t ">Ważna od: </div>
    //             <div className="md-t bold black">{item.valid_from}</div>
    //           </div>
    //           <div className="date">
    //             <div className="md-t ">Ważna do: </div>
    //             <div className="md-t bold black">{item.valid_to}</div>
    //           </div>
    //           <div>
    //             <Button color="white" title="Umowa" />
    //           </div>
    //         </>
    //       )}
    //       {!item.has_agreement && (
    //         <div>
    //           <Button width={200} color="white" title="Dodaj do umowy" />
    //         </div>
    //       )}
    //       <div className="help">
    //         <Help />
    //       </div>
    //     </li>
    //   );
    // });
  };
  if (productAgreements.loading || wasteRaportsState.loading) {
    return <Spinner size="large" />;
  }
  return (
    <Formik initialValues={{ products: [] }} onSubmit={() => {}}>
      {(formikprops: FormikProps<any>) => {
        return (
          <div className="product-charges sider-page network-provision">
            <main className="page-content">
              <div className="breadcrumbs">
                {renderBreadcrumbs([
                  "Usługi recyklingowe",
                  "Opłaty produktowe",
                ])}
                {siderCollapsed && (
                  <div className="help-icon">
                    {" "}
                    <Help onClick={() => toggleSider()} />{" "}
                  </div>
                )}
              </div>
              <header className="page-header">
                <h1 className="grow-1">Opłaty produktowe</h1>{" "}
              </header>
              {/* <h3 className="mb-10">Umowy produktowe - opakowania</h3> */}
              <div className="block mb-20">
                <p className="md-t mb-30">
                  Aktualnie obowiązujące umowy produktowe.
                </p>
                <div className="mb-15">
                  {renderItems(productAgreements.data?.data.packaging_group)}
                </div>
                {productAgreements.data?.data.agreement_button && (
                  <Button
                    color="primary"
                    title="Podpisz umowę"
                    newCard={true}
                    outerLink={true}
                    link={productAgreements.data?.data.agreement_url}
                    width={200}
                  />
                )}
              </div>
              <div className="block">
                {/* <p className="md-t mb-30">
                  Generowanie dokumentów z ewidencją mas. Aktywne w powiązaniu z
                  istniejącymi umowami produktowymi
                </p> */}
                <div className="mb-25">
                  {renderItems(
                    wasteRaportsState.data?.agreement
                      ? [wasteRaportsState.data?.agreement]
                      : undefined
                  )}
                </div>
                <section>
                  {wasteRaportsState.data?.current && (
                    <>
                      <h2>Bieżące ewidencje mas</h2>
                      {renderReports(
                        "current",
                        wasteRaportsState.data?.current
                      )}
                    </>
                  )}
                  {wasteRaportsState.data?.confirmed && (
                    <>
                      <h2>Zatwierdzone ewidencje mas</h2>
                      {renderReports(
                        "confirmed",
                        wasteRaportsState.data?.confirmed
                      )}
                    </>
                  )}
                </section>
                {/* <Button color="primary" title="Zamów" width={200} /> */}
              </div>
            </main>
            {!siderCollapsed && (
              <div
                className="sider"
                // collapsedWidth="0"
              >
                <div className="sm-l-t mb-20">
                  Opłaty produktowe
                  <CloseIcon className="sider-close" onClick={toggleSider} />
                </div>
                <h2 className="mb-15 black">Czym są opłaty produktowe?</h2>
                <p className="black mb-15">
                  Przedsiębiorcy wprowadzający na rynek wymienione w ustawie
                  produkty są obowiązani do sporządzania i składania w formie
                  elektronicznej (za pośrednictwem BDO) rocznego sprawozdania
                  oraz w przypadku niezapewnienia odzysku do wniesienia na
                  rachunek Urzędu Marszałkowskiego należnej opłaty produktowej.
                </p>

                <h2 className="mb-15 black">Obowiązek ustawowy</h2>
                <p className="black">
                  Regulacje prawne dotyczące opłaty produktowej zostały
                  wprowadzone z myślą o ochronie środowiska naturalnego. W
                  Polsce obowiązek jej uiszczenia został wprowadzony wraz z
                  początkiem 2014 roku zgodnie z Ustawą z dnia 11 maja 2001 roku
                  o obowiązkach przedsiębiorców w zakresie gospodarowania
                  niektórymi odpadami oraz o opłacie produktowej.
                </p>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};
// export default withLayout(Index);
export default withAuthSync(withLayout(ProductCharges));
// export default Index;
