import React from "react";
import Input from "antd/lib/input";
import Form from "antd/lib/form";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { Field, ErrorMessage, FormikProps, FastField } from "formik";
import ErrorMsg from 'components/common/error-message';
import DatePicker from "antd/lib/date-picker";
import { PasswordInput } from "antd-password-input-strength";

// @ts-ignore
import "styles/components/input.scss";
import moment from "moment";
import { getNested } from "utils/helpers";
import { ErrorLabel } from "components/common/error-message";
const { RangePicker } = DatePicker;
// import { DatePicker } from "antd";

// const ErrorMsg = (msg: string) => (
//   <span data-testid="text-field-error" className="input-error">
//     {msg}{" "}
//   </span>
// );

export interface ITextProps {
  yearOnly?: boolean;
  disableErrorMessage?: boolean;
  disabled?: boolean;
  label: string;
  size?: string;
  name: string;
  search?: boolean;
  clearVisible?: boolean;
  clearSearch?: () => void;
  optFixedWidth?: boolean;
  placeholder?: string;
  onChange?: (a: any) => void;
  type?:
    | "text"
    | "password"
    | "new-password"
    | "tel"
    | "email"
    | "number"
    | "date"
    | "date-range"
    | "file";
  testId?: string;
  required?: boolean;
  suffix?: string | JSX.Element;
  max?: number;
  min?: number;
  maxlength?: number;
  validate?: any;
  className?: string;
  value?: any;
  paste?: boolean;
  onKeyPress?: any;
}

function disabledDate(current: any) {
  // Can not select days before today and today
  return current && current < moment().date(1900);
}

function TextField(props: ITextProps) {
  const {
    disabled,
    label,
    size,
    name,
    search,
    clearVisible,
    clearSearch,
    testId,
    required,
    validate,
    placeholder,
    disableErrorMessage,
    paste,
    className,
  } = props;

  return (
    <div className={`${search && "search-field"} ${className || ""}`}>
      <Form.Item required={required} label={label} className={className}>
        {search && (
          <SearchIcon data-test="search-icon" className="search-glass" />
        )}
        {clearVisible && (
          <CloseIcon
            data-test="clear-icon"
            onClick={clearSearch}
            className="clear-search"
          />
        )}
        <Field
          autoComplete="off"
          className="text"
          {...props}
          disabled={disabled}
          validate={validate ? validate() : undefined}
        >
          {({ field, form }: { field: any; form: FormikProps<any> }) => {
            const pasteFromClipBoard = () => {
              navigator.clipboard
                .readText()
                .then((clipText) => form.setFieldValue(name, clipText));
            };

            const touched = getNested(form.touched, name);
            const error = getNested(form.errors, name);

            let input: any = null;
            if (props.type === "date") {
              const dateVal = form.values[name]
                ? moment(form.values[name])
                : undefined;
              input = (
                <DatePicker
                  value={dateVal}
                  disabledDate={(d: any) =>
                    !d ||
                    d.isAfter("2030-12-31") ||
                    d.isSameOrBefore("1900-01-01")
                  }
                  data-testid={testId}
                  name={props.name}
                  placeholder={props.placeholder}
                  onChange={(date: any, dateString: any) => {
                    form.setFieldValue(name, dateString);
                    if (props.onChange) {
                      props.onChange(dateString);
                    }
                  }}
                />
              );
            } else if (props.type === "date-range") {
              input = (
                <RangePicker
                  // value={dateVal ?[dateVal] : [undefined]}
                  mode={"year"}
                  // picker={props.yearOnly ? "year" : undefined}
                  onChange={(date: any, dateString: any) => {
                    form.setFieldValue(name, dateString);
                    if (props.onChange) {
                      props.onChange(dateString);
                    }
                  }}
                  placeholder={["od", "do"]}
                  disabledDate={(d: any) =>
                    !d ||
                    d.isAfter("2030-12-31") ||
                    d.isSameOrBefore("1900-01-01")
                  }
                  data-testid={testId}
                  name={props.name}
                />
              );
            } else if (props.type === "password") {
              input = (
                <Input.Password data-testid={testId} {...field} {...props} />
              );
            } else if (props.type === "new-password") {
              input = (
                <PasswordInput
                  autoComplete="off"
                  {...field}
                  {...props}
                  settings={{
                    height: 5,
                    alwaysVisible: true,
                    colorScheme: {
                      levels: [
                        "#ff4033",
                        "#fe940d",
                        "#ffd908",
                        "#cbe11d",
                        "#6ecc3a",
                      ],
                      noLevel: "lightgrey",
                    },
                  }}
                />
              );
            } else if (props.type === "number") {
              const blockInvalidChar = (e: any) =>
                ["+", "-"].includes(e.key) && e.preventDefault();
              input = (
                <Input
                  autoComplete="off"
                  data-testid={testId}
                  {...field}
                  {...props}
                  min={0}
                  onKeyDown={blockInvalidChar}

                  // suffix={
                  //   paste ? (
                  //     <a onClick={pasteFromClipBoard}>wklej</a>
                  //   ) : undefined
                  // }
                />
              );
            } else {
              input = (
                <Input
                  autoComplete="off"
                  data-testid={testId}
                  maxLength={props.maxlength}
                  {...field}
                  {...props}
                  // suffix={
                  //   paste ? (
                  //     <a onClick={pasteFromClipBoard}>wklej</a>
                  //   ) : undefined
                  // }
                />
              );
            }
            return (
              <>
                {input}
                 <ErrorMsg name={props.name} />
              </>
            );
          }}
        </Field>
        {/* <ErrorMessage name={name} render={ErrorMsg} /> */}
      </Form.Item>
      {/* {process.env.NODE_ENV !== 'test' &&
          <style jsx global>{s1}</style>
      } */}
    </div>
  );
}

export default TextField;
