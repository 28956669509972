import React, { useState, useRef, useEffect } from "react";
import withLayout from "components/layout/MyLayout";
import { withAuthSync } from "utils/auth";
import OOOrderForm from "../../components/oo-order/oo-order-form";
import CloseIcon from "@material-ui/icons/Close";
import CheckFormField from "components/form-fields/check-form-field";
import Button from "components/buttons/button";
import {
  IOOOrderItem,
  IOOOrderFormValues,
  IListFormValues,
  OOOrdersList,
  IOOOrderZeroFormValues,
} from "types/recovery-organization-types";
import Table from "antd/lib/table";
import { FormikProps, Formik } from "formik";
import { IActionState } from "types/async-types";
import { submitOOOrderSearch, submitOOOrderList, submitOOOrderZero } from "actions/oo-orders";
import { useAsyncAction } from "utils/async-action";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import SelectField from "components/form-fields/hook-form-fields/hook-select-field";
import { useForm } from "react-hook-form";
import FormButton from "components/buttons/form-button";
import TableWrapper from "components/common/table-wrapper";
import "components/oo-order/oo-order-form.scss";

const OOOrders = () => {
  const formRef = useRef<FormikProps<any>>(null);

  const [submitFormData, execSubmitForm]: readonly [
    IActionState<any>,
    typeof submitOOOrderZero
  ] = useAsyncAction(submitOOOrderZero);



  const onSubmit = (values: IOOOrderZeroFormValues) => {
    execSubmitForm(values);
  };


  const formMethods = useForm<IOOOrderZeroFormValues>({
    mode: "onChange",

  });

  return (
    <div className="sider-page">
      <main className="page-content">
        <div className="breadcrumbs">
          Integrator <span className="arrow">»</span>
          <a href="#">  Zamówienia w organizacjach odzysku / Deklaracje-zamówienia </a>
        </div>
        <header className="page-header">
          <h1 className="grow-1">
            Zamówienia w organizacjach odzysku / Deklaracje-zamówienia
          </h1>
        </header>
        <div className="block">
          <Row gutter={15}>
            <Col md={8}>
              <TextField
                name="year"
                formMethods={formMethods}
                label="Rok rozliczenia"
              />
            </Col>
            <Col md={8}>
              <SelectField
                options={[]}
                name="agreement_id"
                formMethods={formMethods}
                label="Kategoria produktów"
              />
            </Col>
        
          </Row>
          <Row>
            <Col>
            <TextField
                name="cbk_list"
                formMethods={formMethods}
                label="Lista identyfikatorów produktów"
                type="textarea"
              />
              <p>Deklaracje zerowe zostaną wygenerowane dla w/w CBK_ID klientów. Klienci którzy mają wygenerowane jakiekolwiek inne deklaracje (np. abonamentowe, z zamówień itp.) zostaną pominięci w przetwarzaniu</p>
            </Col>
            </Row>
          <Row gutter={15} className="mb-10">
           <FormButton
                color="primary"
                width={200}
                className="f-right"
                onClick={formMethods.handleSubmit(onSubmit)}
                title="Wygeneruj deklaracje"
                // disabled={!isValid}
              />
          </Row>
        </div>
      </main>
    </div>
  );
};

export default withAuthSync(withLayout(OOOrders));
