import { AUTH_USER, SEND_EMAIL, ADD_TO_CART, WasteActionTypes, FETCH_SESSION } from "types/redux-types";
import { ISessionData } from "types/session-types";

export interface IPasswordInfoPayload {}

export interface IWasteState {
  toggleWasteEdit : (cardId : string) => any 
}

const initialState: IWasteState = {
  toggleWasteEdit : () => {}
};

export default function Reducer(
  state = initialState,
  action: WasteActionTypes
): IWasteState {
  switch (action.type) {
    case 'SET_TOGGLE_WASTE_EDIT':
        return {...state, toggleWasteEdit : action.payload.toggleWasteEdit}
    default:
      return state;
  }
}
