import React, { useEffect, useState, useRef } from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import "styles/components/bdo-report/bdo-report-popup.scss";
import { Formik, FormikProps } from "formik";
import TextField from "components/form-fields/formik-text-field";
// import AddressSection from "components/common/address-section";
import AddressSection from "components/common/hook-form-address-section";
import SelectField from "components/form-fields/select-field";
import Button from "components/buttons/form-button";
import { PAGE } from "paths";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";
import {
  yupRequired,
  isNIPCorrect,
  isNIPCorrectHook,
  isREGONCorrectHook,
} from "validators";
import { IActionState, ActionResponse } from "types/async-types";
import {
  IOSOP1FormValues,
  IOSOP1FormData,
  IOSOP1,
} from "types/BDO-report-types";
import { useAsyncAction } from "utils/async-action";
import Spinner from "components/core/spinner";
import {
  fetchOSOP1FormData,
  saveOSOP1,
  fetchOSOP1Report,
} from "actions/BDO-reports";
import { renderJsonOptions } from "utils/select-options";
import HookTextField from "components/form-fields/hook-form-fields/hook-text-field";
import HookSelectField from "components/form-fields/hook-form-fields/hook-select-field";

import FormButton from "components/buttons/form-button";
import { useForm, FormProvider } from "react-hook-form";
import FormButtons from 'components/common/form-buttons';

const BDONumber = (props: any) => {
  const history = useHistory();
  const formRef: any = useRef();
  const [id, setId] = useState<string | null>(null);
  const errors = {
    name: "regon",
    config: { message: "Brak regonu w bazie", type: "manual" },
  };
  const formikRef: FormikProps<IOSOP1FormValues> = formRef.current;

  const [formDataState, execFetchFormData]: readonly [
    IActionState<IOSOP1FormData>,
    typeof fetchOSOP1FormData
  ] = useAsyncAction(fetchOSOP1FormData);

  const [OSOP1ReportState, execFetchOSOP1Report]: readonly [
    IActionState<IOSOP1>,
    typeof fetchOSOP1Report
  ] = useAsyncAction(fetchOSOP1Report);

  const [saveOSOP1State, execSaveOSOP1]: readonly [
    IActionState<any>,
    typeof saveOSOP1
  ] = useAsyncAction(saveOSOP1);

  // ("formState, ", formState.errors);

  const [initialValues, setInitialValues] = useState<IOSOP1FormValues>();



  const formMethods = useForm<IOSOP1FormValues>({
    mode: "onTouched",
    defaultValues: initialValues
  });

  
  
  const { formState, control, reset, getValues   } = formMethods;
  
  const submitForm = async (values: IOSOP1FormValues) => {
    const submitData: IOSOP1FormValues = {
      ...values,
      step: 1,
    };
    if (id) {
      execSaveOSOP1(submitData, id);
    } else {
      execSaveOSOP1(submitData);
    }
  };



  useEffect(() => {
    if (saveOSOP1State.data) {
      history.push(`${PAGE.OS_OP1_STEP_2}?id=${saveOSOP1State.data.id}`);
    }
  }, [saveOSOP1State.data]);

  // const validationSchema = Yup.object().shape({
  //   regon: Yup.string().concat(yupRequired),
  // });

  useEffect(() => {
    if (id) {
      execFetchOSOP1Report(id);
    }
    setId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    execFetchFormData();

    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    setId(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    
    formMethods.setValue('full_company_name', initialValues?.full_company_name )
    formMethods.setValue('nip', initialValues?.nip )
    formMethods.setValue('regon', initialValues?.regon )
    formMethods.setValue('bdo_number', initialValues?.bdo_number )
    formMethods.setValue('voivodship_marshal', initialValues?.voivodship_marshal )
    // reset({
    //   full_company_name: initialValues?.full_company_name,
    //   nip: initialValues?.nip,
    //   regon: initialValues?.regon,
    //   bdo_number: initialValues?.bdo_number,
    //   // address_attributes: initialValues?.address_attributes,
    //   voivodship_marshal: initialValues?.voivodship_marshal,
    // });

    formMethods.setValue('address_attributes',  initialValues?.address_attributes) 
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    
    if (formDataState.data && !OSOP1ReportState.data) {
      setInitialValues({
        ...formDataState.data.os_op1_report,
        address_attributes: formDataState.data.os_op1_report.address, 
      });
    }

    if (OSOP1ReportState.data && formDataState.data) {
      setInitialValues({
        full_company_name: OSOP1ReportState.data.full_company_name,
        nip: OSOP1ReportState.data.nip || formDataState.data.os_op1_report.nip,
        regon: OSOP1ReportState.data.regon,
        bdo_number: OSOP1ReportState.data.bdo_number,
        address_attributes: formDataState.data.os_op1_report.address,
        voivodship_marshal: OSOP1ReportState.data.voivodship_marshal,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formDataState.data, OSOP1ReportState.data]);

  
  return (
    <FormProvider {...formMethods}>
    <div>
  
      {(saveOSOP1State.loading || formDataState.loading) && (
        <Spinner size="large" fixed={true} />
      )}

      <div className="block">
        <p className="large mb-0">
          Rozpocząłeś właśnie proces zamawiania sprawozdania OŚ-OP1. Prosimy
          uważnie wypełnić formularz.
        </p>
      </div>
      <div className="block">
        <h2 className="h-lg" >Dane działalności</h2>
        <p className="md-t">
          W tej sekcji wypełnij podstawowe dane twojej działalności
          gospodarczej.
        </p>
        <Row gutter={10}>
          <Col md={3}>
            <HookTextField
              label="NIP firmy"
              name="nip"
              maxlength={13}
              required={true}
              formMethods={formMethods}
              rules={{
                required: "Pole jest wymagane",
                validate: isNIPCorrectHook,
              }}
            />
          </Col>
          <Col md={5}>
            <HookTextField
              label="REGON"
              name="regon"
              required={true}
              formMethods={formMethods}
              rules={{
                required: "Pole jest wymagane",
                validate: isREGONCorrectHook,
              }}
            />
          </Col>
          <Col md={10}>
            <HookTextField
              label="Pełna nazwa firmy"
              name="full_company_name"
              required={true}
              formMethods={formMethods}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
          <Col md={6}>
            <HookTextField
              label="Numer rejestrowy BDO"
              name="bdo_number"
              required={true}
              formMethods={formMethods}
              rules={{
                required: "Pole jest wymagane",
              }}
            />
          </Col>
        </Row>
      </div>
      <div className="block">
        <h2 className="h-lg" >Dane adresowe</h2>
        <p className="md-t">
          Możesz podać adres zamieszkania lub adres siedziby firmy. Nie musi to
          być adres w którym wytwarzasz lub przechowujesz odpady - o te
          zostaniesz poproszony ww kolejnych krokach wniosku rejestracyjnego
          BDO.
        </p>
        <h2 className="mb-15">Adres zamieszkania lub siedziby</h2>
        <section>
          <AddressSection
            formMethods={formMethods}
            extended={true}
            parentName="address_attributes"
          />
        </section>
      </div>
      <div className="block">
        <h2  className="h-lg">Adresat sprawozdania</h2>
        <p className="md-t">
          Adresatem sprawozdania powinien być odpowiedni Marszałek Województwa
          dla adresu siedziby firmy. Uzupełniliśmy te informacje - sprawdź czy
          są poprawne i możesz je zmienić na inne.
        </p>

        <section>
          <HookSelectField
            name="voivodship_marshal"
            label="Marszałek Województwa"
            formMethods={formMethods}
            rules={{
              required: "Pole jest wymagane",
            }}
            required={true}
            options={
              formDataState.data
                ? renderJsonOptions(
                    formDataState.data?.voivodeship_with_marhals
                  )
                : []
            }
          />
        </section>
      </div>
      <FormButtons>
          <FormButton
            width={220}
            color="primary"
            onClick={formMethods.handleSubmit(submitForm)}
            title="Zapisz i kontynuuj"
          />
     </FormButtons>
    </div>
    </FormProvider>
  );
};

export default BDONumber;
