import axios, { AxiosRequestConfig } from "axios";
import { API_BASE_URL } from "config";
import { requestHeaders } from "utils/auth";
import { IDocumentFormValues } from "types/document-types";
import { ActionResponse } from "types/async-types";

const ACCOUNTING_DOCUMENTS_URL = `${API_BASE_URL}/accounting_documents`;
const DOCUMENTS_URL = `${API_BASE_URL}/document_groups`;

export const fetchAccountingDocuments = async (queryData?: any) => {
  const options: AxiosRequestConfig = {
    url: `${ACCOUNTING_DOCUMENTS_URL}`,
    headers: requestHeaders().headers,
    method: "GET",
    params: queryData,
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchAccountingDocument = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${ACCOUNTING_DOCUMENTS_URL}/${id}/document_pdf`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchDocuments = async () => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const saveGroup = async (id: string, values: any) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${id}`,
    headers: requestHeaders().headers,
    method: "PATCH",
    data : values,

  };
  let res : ActionResponse<any> = await axios(options);
  res = {
    ...res,
    successMessage : "Zapisano zmiany"
  }
  if (res) {
    return res;
  }
};

export const fetchDocumentsGroup = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${id}`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const addDocumentGroup = async (data : any) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}`,
    headers: requestHeaders().headers,
    method: "POST",
    data
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};


export const deleteDocumentGroup = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${id}`,
    headers: requestHeaders().headers,
    method: "DELETE",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const moveDocumentGroupUp = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${id}/move_up`,
    headers: requestHeaders().headers,
    method: "POST",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const moveDocumentGroupDown = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${id}/move_down`,
    headers: requestHeaders().headers,
    method: "POST",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const createDocument = async (
  groupId: string,
  data: IDocumentFormValues
) => {
  const formData = new FormData();
  data.document_attachments?.forEach((item) => {
    formData.append("document[attachments][]", item);
  });

  formData.append("document[name]", data.name);
  formData.append("document[description]", data.description);

  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${groupId}/documents`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      Accept: "multipart/form-data",
    },
    method: "POST",
    data: formData,
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const deleteDocument = async (id: string, documentId :string) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${id}/documents/${documentId}`,
    headers: requestHeaders().headers,
    method: "DELETE",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};



export const saveDocument = async (
  groupId: string,
  documentId: string,
  data: IDocumentFormValues
) => {
  const formData = new FormData();
  data.document_attachments.forEach((item) => {
    formData.append("document[attachments][]", item);
  });

  formData.append("document[name]", data.name);
  formData.append("document[description]", data.description);

  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${groupId}/documents/${documentId}`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
      Accept: "multipart/form-data",
    },
    method: "PATCH",
    data: formData,
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const deleteDocumentAttachment = async (
  groupId: string,
  documentId: string,
  attachmentId: string
) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${groupId}/documents/${documentId}/destroy_attachment`,
    headers: {
      authorization: requestHeaders().headers?.authorization,
    },
    method: "DELETE",
    data: {
      attachment_id: attachmentId,
    },
  };
  let res : ActionResponse<any> = await axios(options); 
  res = {
    ...res,
    successMessage : "Zapisano zmiany"
  }
  if (res) {
    return res;
  }
};

export const fetchDocument = async (docId: string, fileId: number) => {
  const options: AxiosRequestConfig = {
    url: `${DOCUMENTS_URL}/${docId}/attachments/${fileId}`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const fetchPaymentSummary = async (id: string) => {
  const options: AxiosRequestConfig = {
    url: `${ACCOUNTING_DOCUMENTS_URL}/payment_summary?seller_id=${id}`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const createPayment = async (sellerId: string, document_ids: string) => {
  const options: AxiosRequestConfig = {
    url: `${ACCOUNTING_DOCUMENTS_URL}/create_payment`,
    headers: requestHeaders().headers,
    method: "POST",
    data: {
      seller_id: sellerId,
      document_ids: document_ids,
    },
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const checkDocumentsPaymentStatus = async (paymentId: string) => {
  const options: AxiosRequestConfig = {
    url: `${ACCOUNTING_DOCUMENTS_URL}/payment_status?id=${paymentId}`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};

export const retryDocumentsPayment = async (paymentId: string) => {
  const options: AxiosRequestConfig = {
    url: `${ACCOUNTING_DOCUMENTS_URL}/retry_payment?id=${paymentId}`,
    headers: requestHeaders().headers,
    method: "GET",
  };
  let res = await axios(options);
  if (res) {
    return res;
  }
};
