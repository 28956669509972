import React from "react";
import { Field } from "formik";
import CheckFormField from "./check-form-field";
import { BooleanSchema } from "yup";
import { ICheckFormFieldProps } from "components/form-fields/check-form-field";
//@ts-ignore
import "styles/components/input.scss";

type CheckProps = React.ComponentProps<typeof CheckFormField>;

export interface ICheckGroupProps<T> {
  name: string;
  label: string | false;
  orientation: "horizontal" | "vertical";
  testId?: string;
  options: Array<Omit<ICheckFormFieldProps<T>, "name">>;
  type?: "checkbox-group";
  required? : boolean;
}

const CheckFormFields = (props: ICheckGroupProps<any>) => {
  const { options, name, orientation, label, required } = props;
  const result = options.map((item, index) => {
    return <CheckFormField {...item} key={'check_' + index} name={name} />;
  });

  return (
    <div className={`check-form-fields ${orientation}`}>
      {label && (
        <div className={`ant-form-item-label mb-15 ${required ? "ant-form-item-required" : ""}`}>
          <label>{label}</label>
        </div>
      )}
      <div>{result}</div>
      {/* {process.env.NODE_ENV !== "test" && (
        <style jsx global>
          {s1}
        </style>
      )} */}
    </div>
  );
};

export default CheckFormFields;
