import React from "react";
import { Row, Col } from "antd";
import TextField from "components/form-fields/hook-form-fields/hook-text-field";
import { UseFormReturn } from "react-hook-form";

export default function ImportedVehiclesSection(props: {
  formMethods: UseFormReturn<any>;
  vehicle_types?: { [key: string]: string };
}) {
  const { vehicle_types } = props;
  const renderVehicleFields = () => {
    if (vehicle_types) {
      return Object.keys(vehicle_types).map((key, index) => {
        return (
          <Col md={3}>
            <TextField
              label={vehicle_types[key] || ""}
              formMethods={formMethods}
              name={`imported_vehicle_number.${key}`}
              type="number"
            />
          </Col>
        );
      });
    }
  };
  const { formMethods } = props;

  return (
    <Row gutter={10} className="mb-20">
      {renderVehicleFields()}
    </Row>
  );
}
