import React from "react";
// @ts-ignore
import { IRadioGroupProps } from "components/form-fields/radio-group";

import SelectFormField from "./select-field";
import TextFormField from "./formik-text-field";
import CheckFormField from "./check-form-field";
import CheckBoxGroup from "./checkbox-group";
import RadioGroup from "./radio-group";

type SelectProps = React.ComponentProps<typeof SelectFormField>;
type TextProps = React.ComponentProps<typeof TextFormField>;
type CheckProps = React.ComponentProps<typeof CheckFormField>;
type CheckBoxGroupProps = React.ComponentProps<typeof CheckBoxGroup>;
type RadioProps = React.ComponentProps<typeof RadioGroup>;

 type types = SelectProps | TextProps | CheckProps | IRadioGroupProps<any> | CheckBoxGroupProps;





function FormField(props: (SelectProps | TextProps | CheckProps | IRadioGroupProps<any> | CheckBoxGroupProps)  ) {
    

    const {type} = props;
    switch(type){
        case 'select' : {
           return <SelectFormField {...props as SelectProps} />
           
        }
        case 'checkbox' : {
           return <CheckFormField {...props as CheckProps}  />
        }
        case 'checkbox-group' : {
            return <CheckBoxGroup {...props as CheckBoxGroupProps}  />
        }
        case 'radio' : {
           return <RadioGroup {...props as RadioProps}  />
        }
       default : {
           return <TextFormField {...props as TextProps} />
        }
    }
  

  
}

export default FormField;
