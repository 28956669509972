import React from "react";
import TextField from "components/form-fields/formik-text-field";
import FormButton from "components/buttons/form-button";
import Button from "components/buttons/button";
import { Formik, FormikProps } from "formik";
import { Row, Col } from "antd";
import { IActionState } from "types/async-types";
import { IBDONumberReq, IBDORegistryData } from "types/BDO-report-types";
import { numToCurrency } from "utils/helpers";
import Price from "components/common/price";

interface IProps {
  BDONumberState: IActionState<IBDORegistryData>;
  onSubmit: (values: { bdo_number: string }) => any;
}
const WithBDONumber = (props: IProps) => {
  const { BDONumberState, onSubmit } = props;
  return (
    <>
      <Row gutter={100} className="mb-50">
        <Col md={12}>
          <p className="md-t">
            Numer BDO to unikalny 9-cyfrowy numer rejestrowy przypisany do
            Twojej działalności gospodarczej. Jest nadawany przez Urząd
            Marszałkowski. Potwierdź wyświetlony numer BDO.
          </p>
          <Formik
            enableReinitialize={true}
            initialValues={{
              bdo_number: BDONumberState.data?.bdo_number || "",
            }}
            onSubmit={onSubmit}
          >
            {(formikProps: FormikProps<{ bdo_number: string }>) => (
              <div style={{ width: "220px" }}>
                <TextField
                  name="bdo_number"
                  label="Numer BDO"
                  disabled={true}
                />
              </div>
            )}
          </Formik>
        </Col>
        <Col md={12} className="bor-dash-left">
          <h2 className="mb-20 black">Błędnie wpisany numer BDO?</h2>
          <ol className="numbered-list md-t">
            <li>
              <div>
                <label>
                  Nie można edytować tego numeru BDO. Jeżeli nie jest to Twój
                  poprawny numer BDO{" "}
                  <a
                    target="_blank"
                    href="https://integrator.org.pl/?Contact/Main"
                  >
                    skontaktuj się z nami.{" "}
                  </a>
                </label>
              </div>
            </li>
            <li>
              <div>
                <label>
                  {" "}
                  Jeżeli prowadzisz więcej niż jedną działalność gospodarczą i
                  numer BDO, który widzisz należy do innej działalności
                  przeloguj się na właściwe konto Integratora.
                </label>
              </div>
            </li>
          </ol>
          <p>
            Jeżeli w dalszym ciągu potrzebujesz pomocy,{" "}
            <a target="_blank" href="https://integrator.org.pl/?Contact/Main">
              skontaktuj się z nami{" "}
            </a>
          </p>
        </Col>
      </Row>
      <Row gutter={100}>
        <Col md={12}>
          <h2 className="mb-20 black">
            Zmieniły się dane Twojej działalności lub dane dotyczące umów
            recyklingowych?
          </h2>
          <p className="md-t">
            Jeżeli jesteś zobowiązany do zmiany danych w systemie BDO wypełnij i
            złóż wniosek aktualizacyjny
          </p>
          <Price
            price={BDONumberState.data?.product_price.netto}
            suffix="netto za wniosek"
          />


          <Button
            title="Wniosek aktualizacyjny BDO"
            inline={true}
            color="primary"
            newCard={true}
            link="https://sandbox.integrator.org.pl/?BDO/Browse"
            outerLink={true}
            width={220}
          />
        </Col>
        <Col md={12} className="bor-dash-left">
          <h2 className="mb-15 black">Jak złożyć aktualizację do BDO?</h2>
          <ol className="request-order mb-20">
            <li>
              <div>
                <label>Wypełnij wniosek</label>
              </div>
            </li>
            <li>
              <div>
                <label>Opłać fakturę pro-forma</label>
              </div>
            </li>
            <li>
              <div>
                <label>
                  Po zaksięgowaniu wpłaty wyślemy Ci gotowy druk wniosku wraz z
                  fakturą VAT
                </label>
                <p>
                  Jeśli chcesz przyspieszyć proces, wybierz płatność online lub
                  prześlij potwierdzenie wpłaty na adres biuro@integrator.org.pl
                </p>
              </div>
            </li>
            <li>
              <div>
                <label>
                  Złóż wniosek przez{" "}
                  <a
                    target="_blank"
                    href="https://rejestr-bdo.mos.gov.pl"
                    rel="noreferrer"
                  >
                    moduł elektroniczny BDO
                  </a>
                </label>
              </div>
            </li>
          </ol>
        </Col>
      </Row>
    </>
  );
};

export default WithBDONumber;
